import React from "react";
import { FormGroup, Tab, Tabs } from "react-bootstrap";
import { CmsCardContainer } from "components/cards";
import {
  ChannelPicker,
  CollectionPicker,
  MediaContentPicker,
  PageSectionRadio,
  ProviderPicker,
} from "components/pickers";
import ImportCoverImageCard from "./ImportCoverImageCard";
import { Promo, PromoOnChangeHandlers } from "./types";

interface Props {
  item: Promo;
  onChangeHandlers: PromoOnChangeHandlers;
  readOnly: boolean;
}

function PromoLinkToComponent({ item, onChangeHandlers, readOnly }: Props) {
  return (
    <>
      <h2>Link promotion to</h2>
      <Tabs
        id="promotion-tabs"
        className="mb-3"
        activeKey={item.linkPromoTo}
        onSelect={onChangeHandlers.linkPromoTo}
        mountOnEnter
      >
        <Tab eventKey="content" title="Content" disabled={readOnly}>
          <CmsCardContainer>
            <MediaContentPicker
              readOnly={readOnly}
              selectedId={item.content.id}
              isAsset={item.content.isAsset}
              onChange={onChangeHandlers.content}
              excludeKinds={["episode"]}
            />
          </CmsCardContainer>
          {item.content.id && item.promotionType === "cover" && (
            <ImportCoverImageCard
              className="mt-3"
              contentId={item.content.id}
              onChange={onChangeHandlers.cover}
            />
          )}
        </Tab>
        <Tab eventKey="collection" title="Collection" disabled={readOnly}>
          <CmsCardContainer>
            <CollectionPicker
              readOnly={readOnly}
              selectedId={item.collection}
              onChange={onChangeHandlers.collection}
              collectionType="page"
            />
          </CmsCardContainer>
        </Tab>
        <Tab eventKey="channel" title="Channel" disabled={readOnly}>
          <ChannelPicker
            disabled={readOnly}
            value={item.channel}
            onChange={onChangeHandlers.channel}
          />
        </Tab>
        <Tab eventKey="provider" title="Provider" disabled={readOnly}>
          <ProviderPicker
            disabled={readOnly}
            value={item.provider ? parseInt(item.provider) : undefined}
            onChange={onChangeHandlers.provider}
          />
        </Tab>
        <Tab eventKey="pageSection" title="Page Section" disabled={readOnly}>
          <FormGroup>
            <PageSectionRadio
              idPrefix="linkTo"
              disabled={readOnly}
              selected={item.pageSection}
              onChange={onChangeHandlers.pageSection}
            />
          </FormGroup>
        </Tab>
      </Tabs>
    </>
  );
}

export default PromoLinkToComponent;
