import { PropTypes } from "prop-types";
import React from "react";
import { useRequest, STATUS } from "client/hooks";
import { formatFootballEventCard } from "client/views/Sports/utils";
import {
  EmptyCmsCard,
  FootballEventCard,
  CmsCardContainer,
} from "components/cards";
import FootballEventDialog from "./FootballEventDialog";

function formatData(data) {
  return {
    item: formatFootballEventCard(data),
    id: data.id,
    leagueId: data.competitionId,
  };
}
function useOptaCalenderCard(id) {
  // supress the error toast
  const onError = React.useCallback((err, endpoint) => {
    console.error("Failed to fetch:", endpoint, err);
  }, []);
  const [data, setData] = React.useState({ id: id || 0, leagueId: 0 });

  const [status] = useRequest("sports/seasons/" + id, {
    blockRequests: !id,
    onSuccess: (res) => setData(formatData(res)),
    onError,
  });
  return [status, data];
}

export function FootballEventPicker(props) {
  const { selectedId = null, readOnly } = props;
  const [showSearchModal, setShowSearchModal] = React.useState(false);

  const [status, cardProps] = useOptaCalenderCard(selectedId);

  const onSelect = React.useCallback(
    (footballEvent) => {
      props.onChange(footballEvent, false);
      setShowSearchModal(false);
    },
    [props.onChange]
  );

  const onClick = () => setShowSearchModal(true);

  return (
    <>
      <FootballEventDialog
        show={showSearchModal}
        size="lg"
        multiple={false}
        onSelect={onSelect}
        onClose={() => setShowSearchModal(false)}
      />
      <CmsCardContainer>
        {selectedId ? (
          <FootballEventCard
            item={cardProps.item}
            seasonId={cardProps.id}
            leagueId={cardProps.leagueId}
            isLoading={status === STATUS.FETCHING}
            isValid={status !== STATUS.ERROR}
            onClick={onClick}
            canClick={!readOnly}
            showLink
          />
        ) : (
          <EmptyCmsCard
            onClick={onClick}
            canClick={!readOnly}
            variant="landscape"
          />
        )}
      </CmsCardContainer>
    </>
  );
}

FootballEventPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
};
