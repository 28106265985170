import React from "react";
import { FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { CmsPure } from "client/utils";
import ToggleControl from "components/controls/ToggleControl";
import CloudImageControl from "components/image/CloudImageControl";

class TopPromoGraphicsComponent extends CmsPure {
  render() {
    const { item, onChangeHandlers, readOnly } = this.props;
    return (
      <FormGroup>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <FormLabel className="lead">Top Banner</FormLabel>
              <CloudImageControl
                disabled={readOnly}
                type="promotion"
                width={2 * 1920}
                height={2 * 600}
                value={item.topBannerLarge}
                onChange={onChangeHandlers.topBannerLarge}
              />
            </FormGroup>
          </Col>
          <Col xs={5}>
            <FormGroup>
              <FormLabel className="lead">Overlay</FormLabel>
              <CloudImageControl
                disabled={readOnly}
                type="promotion"
                width={2 * 800}
                height={2 * 600}
                value={item.topBannerMedium}
                onChange={onChangeHandlers.topBannerMedium}
              />
            </FormGroup>
          </Col>
          <Col xs={7}>
            <FormGroup>
              <FormLabel className="lead">Overlay alignment</FormLabel>
              <ToggleControl
                disabled={readOnly}
                allowed={["left", "center", "right"]}
                value={item.overlayAlignment}
                onChange={onChangeHandlers.overlayAlignment}
              />
            </FormGroup>
            <FormGroup className="my-3">
              <FormLabel className="lead">Overlay color</FormLabel>
              <ToggleControl
                disabled={readOnly}
                allowed={["dark", "light"]}
                value={item.overlayColor}
                onChange={onChangeHandlers.overlayColor}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <FormLabel className="lead">Small Overlay</FormLabel>
              <CloudImageControl
                disabled={readOnly}
                type="promotion"
                width={666}
                height={500}
                value={item.topBannerSmall}
                onChange={onChangeHandlers.topBannerSmall}
              />
            </FormGroup>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

export default TopPromoGraphicsComponent;
