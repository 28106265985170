import React, { useState } from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { useRequest, STATUS } from "client/hooks";
import AssetSummaryLink from "./AssetSummaryLink";

const AssetLinksCard = ({ id }) => {
  const [assets, setAssets] = useState([]);

  const [status] = useRequest(`assets/by-content/${id}`, {
    onSuccess: setAssets,
  });

  return (
    <Card className="mt-4">
      <Card.Header>Assets</Card.Header>
      <ListGroup variant="flush">
        {assets.map(({ asset }) => (
          <AssetSummaryLink key={asset.id} asset={asset} />
        ))}
        {assets.length === 0 && status === STATUS.OK && (
          <ListGroupItem>No assets available</ListGroupItem>
        )}
      </ListGroup>
    </Card>
  );
};

export default AssetLinksCard;
