import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import KeyValuePair, { EditableKeyValuePair } from "components/KeyValuePair";
import TitleFormControl from "components/controls/TitleFormControl";
import { SingleSelect } from "components/pickers";

function ProviderView({
  provider,
  mode,
  onChange,
  canEdit,
  isSubmitting,
  providerStatuses,
  isLoadingProviderStatuses,
  transcodeServices,
  isLoadingTranscodeServices,
  onSubmit,
}) {
  return (
    <>
      <Row>
        <TitleFormControl
          title={provider.name}
          placeholder="Provider Name"
          onChange={(e) => onChange({ name: e.target.value })}
        />
      </Row>
      <Row className="mt-2">
        <Col className="mt-2">
          <Form onSubmit={onSubmit}>
            <KeyValuePair id="AMS ID" value={provider.id} />
            <EditableKeyValuePair
              id="Max Asset Import Concurrency"
              value={provider.asset_import_concurrency_max}
              onChange={(rawValue) => {
                const asset_import_concurrency_max =
                  rawValue !== "" ? parseInt(rawValue, 10) : null;
                onChange({ asset_import_concurrency_max });
              }}
              disabled={!canEdit}
            />
            <EditableKeyValuePair
              id="Play ID"
              value={provider.play_id}
              onChange={(play_id) => onChange({ play_id })}
              disabled={!canEdit}
            />
            <KeyValuePair
              id="Play Status"
              value={
                <SingleSelect
                  value={provider.play_status}
                  options={providerStatuses}
                  onChange={(play_status) => onChange({ play_status })}
                  disabled={!canEdit}
                  isLoading={isLoadingProviderStatuses}
                />
              }
            />
            <KeyValuePair
              id="Transcode Service"
              value={
                <SingleSelect
                  value={provider.transcode_service}
                  options={transcodeServices}
                  onChange={(transcode_service) =>
                    onChange({ transcode_service })
                  }
                  disabled={!canEdit}
                  isLoading={isLoadingTranscodeServices}
                />
              }
            />
            {canEdit && (
              <Button
                className="pull-right"
                variant="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {mode === "create" ? "Create" : "Save changes"}
              </Button>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default ProviderView;
