import autobind from "autobind-decorator";
import { PropTypes } from "prop-types";
import React from "react";
import {
  Button,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CmsPure, request } from "client/utils";
import { onEnterPress } from "client/utils/Helpers";

class SearchAssetDialog extends CmsPure {
  constructor(props) {
    super(props);

    this.state = {
      assetid: "",
      byCatalog: false,
    };
  }

  @autobind
  onSubmit() {
    if (this.state.byCatalog) {
      request(`assets/by-catalog/${this.state.assetid}`).then((assetId) => {
        console.log(assetId);
        if (assetId) {
          this.props.onSubmit();
          this.props.history.push(`/enrich/asset/${assetId}`);
        } else {
          console.log("Bad response received:", assetId);
          toast.error("No asset found for the requested catalog id");
        }
      });
    } else {
      this.props.onSubmit();
      this.props.history.push(`/enrich/asset/${this.state.assetid}`);
    }
  }

  @autobind
  onToggleByCatalog() {
    this.setState({ byCatalog: !this.state.byCatalog, assetid: "" });
  }

  @autobind
  onChange(e) {
    if (!this.state.byCatalog)
      this.setState({ assetid: Number(e.target.value) || "" });
    else this.setState({ assetid: e.target.value });
  }

  @autobind
  onCloseModal() {
    this.setState({ byCatalog: false, assetid: "" });
    this.props.onClose();
  }

  render() {
    return (
      <Modal
        animation={true}
        autoFocus
        show={this.props.show}
        onHide={this.onCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Goto asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormCheck
            type="checkbox"
            checked={this.state.byCatalog}
            onChange={this.onToggleByCatalog}
            label="By Catalog?"
          />
          <FormGroup variant={!this.state.assetid ? "error" : null}>
            <FormLabel htmlFor="id-input">
              {this.state.byCatalog ? "Catalog ID" : "Asset ID"}
            </FormLabel>
            <FormControl
              id="id-input"
              autoFocus
              type="text"
              placeholder={
                this.state.byCatalog ? "Catalog id..." : "Asset id... "
              }
              style={{ maxWidth: "485px" }}
              value={this.state.assetid}
              onChange={this.onChange}
              onKeyDown={(e) =>
                onEnterPress(e, this.state.assetid, this.onSubmit)
              }
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={this.onSubmit}
            disabled={!this.state.assetid}
          >
            Goto
          </Button>
          <div className="divider" />
          <Button variant="default" onClick={this.onCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

SearchAssetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default withRouter(SearchAssetDialog);
