import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { ChannelsContext } from "client/App";
import { IChannels } from "client/types";
import { duplicateSafeLabelKey } from "client/utils";

interface Props {
  id?: string;
  onChange: (selected: number[]) => void;
  placeholder?: string;
  value: number[] | string[];
  multiple?: boolean;
  exclude?: number[];
  className?: string;
  disabled?: boolean;
}

export const ChannelMultiPicker = ({
  id = "channels-multi",
  onChange,
  placeholder = "Channel filter",
  value,
  multiple = true,
  exclude = [],
  className,
  disabled,
  ...rest
}: Props) => (
  <ChannelsContext.Consumer>
    {(channels) => {
      // if channels hasn't been fetched yet, or an id is invalid,
      // find will return undefined and needs to be filtered
      const channelObjs = value
        .map((val) => channels.find((channel) => channel.id === val.toString()))
        .filter((ch): ch is IChannels => !!ch);
      return (
        <Typeahead
          id={id}
          multiple={multiple}
          selected={channelObjs}
          options={channels.filter((ch) => !exclude.includes(Number(ch.id)))}
          labelKey={duplicateSafeLabelKey(channels)}
          placeholder={placeholder}
          onChange={(selectedChannels) => {
            onChange(selectedChannels.map((ch) => Number(ch.id)));
          }}
          align="left"
          className={className}
          disabled={disabled}
          {...rest}
        />
      );
    }}
  </ChannelsContext.Consumer>
);
