import React from "react";
import { STATUS } from "client/hooks";
import AssetSelectorDialog from "components/AssetSelectorDialog";
import MediaContentDialog from "components/MediaContentDialog";
import { EmptyCmsCard, ContentCard } from "components/cards";
import { useContentCard } from "components/cards/ContentCard";

export function MediaContentPicker({
  selectedId = null,
  isAsset = null,
  readOnly,
  excludeKinds,
  onChange,
}: {
  selectedId?: string | number | null;
  isAsset?: boolean | null;
  readOnly?: boolean;
  excludeKinds?: string[];
  onChange: (id?: string | number, isAsset?: boolean) => void;
}) {
  const [showSearchModal, setShowSearchModal] = React.useState(false);
  const [showAssetModal, setShowAssetModal] = React.useState(false);

  const { status, card } = useContentCard(selectedId, isAsset);

  const onSelect = React.useMemo(
    () => (assetId: string | undefined) => {
      setShowAssetModal(false);
      if (assetId) onChange(assetId, true);
      else onChange(card.mcId, false);
    },
    [onChange, card.mcId]
  );

  const onClick = () => {
    if (selectedId) setShowAssetModal(true);
    else setShowSearchModal(true);
  };

  const onDelete = () => {
    onChange();
    setShowAssetModal(false);
  };

  return (
    <>
      <MediaContentDialog
        show={showSearchModal}
        size="lg"
        multiple={false}
        onSelect={(array) => {
          onChange(array[0], false);
          setShowSearchModal(false);
        }}
        onClose={() => setShowSearchModal(false)}
        initialState={{ excludeKinds: excludeKinds }}
      />
      <AssetSelectorDialog
        show={showAssetModal}
        selectedId={isAsset ? selectedId : null}
        mcId={card.mcId}
        onChange={onSelect}
        onDelete={onDelete}
        onClose={() => setShowAssetModal(false)}
      />
      {selectedId ? (
        <ContentCard
          id={selectedId}
          item={card.card}
          isAsset={isAsset}
          isLoading={status === STATUS.FETCHING}
          isValid={status !== STATUS.ERROR}
          onClick={onClick}
          canClick={!readOnly}
          showLink
        />
      ) : (
        <EmptyCmsCard
          onClick={() => setShowSearchModal(true)}
          canClick={!readOnly}
          variant="landscape"
        />
      )}
    </>
  );
}
