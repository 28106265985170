import React from "react";
import { GenresContext } from "client/App";
import { GenreWithHitCount } from "./types";

export const useGenreOptions = (
  language: string,
  genreCounts: { [genre: string]: number } | undefined
) => {
  const genres = React.useContext(GenresContext).filter(
    (genre) => genre.language === language
  );
  if (!genreCounts) {
    return genres;
  }

  const genresWithHitCounts: GenreWithHitCount[] = [];
  const genresWithoutHitCounts: GenreWithHitCount[] = [];
  genres.forEach((genre) => {
    const count = genreCounts[genre.value];
    if (count) {
      genresWithHitCounts.push({ ...genre, count });
    } else {
      genresWithoutHitCounts.push(genre);
    }
  });
  return [...genresWithHitCounts, ...genresWithoutHitCounts];
};

export const useSelectedGenres = (
  genreValue: string[],
  genreOptions: GenreWithHitCount[],
  language: string
) => {
  return genreValue.map(
    (genreString) =>
      genreOptions.find((genre) => genre.value === genreString) || {
        value: genreString,
        language,
        label: genreString,
      }
  );
};
