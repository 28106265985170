import React from "react";
import { FormControl } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

export const TitleFilter = () => {
  const [title = "", setTitle] = useQueryParam("title", StringParam);
  const resetPage = useResetPage();
  const selectTitle = (newTitle: string | null) => {
    setTitle(newTitle);
    resetPage();
  };

  return (
    <FormControl
      type="text"
      placeholder={"Title"}
      value={title || ""}
      onChange={(e) => selectTitle(e.target.value || null)}
    />
  );
};
