import { PropTypes } from "prop-types";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

export const KeywordsMultiPicker = ({
  onChange,
  placeholder = "Add keywords",
  value,
  ...rest
}) => {
  return (
    <Typeahead
      allowNew
      multiple
      selected={value}
      options={[]}
      labelKey="value"
      newSelectionPrefix="Add new keyword: "
      emptyLabel="Start typing to add a keyword"
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  );
};

const itemShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
});

KeywordsMultiPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(itemShape).isRequired,
};
