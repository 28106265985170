import React from "react";
import { Alert, Button } from "react-bootstrap";
import IMDbTitleCard from "./IMDbTitleCard";

const UnlinkConfirmationCard = ({ match, unlinkReason, dispatch }) => {
  const unlinkReasonCustomizations = {
    unlinked: {
      preHeading: "Current IMDb match",
      missingText: "No IMDb title",
      alertText: (
        <>
          Are you sure you want to unlink this media content from the IMDb title
          that it is currently associated with?
        </>
      ),
      confirmButtonVariant: "danger",
      confirmButtonText: "Yes, unlink the current match",
    },
    ignored: {
      preHeading: undefined,
      missingText: "Not on IMDb?",
      alertText: (
        <>
          Is this content not available on IMDb? Click the button below to move
          it to the <strong>Not on IMDb</strong> tab and keep it out of the list
          of remaining unmatched content.
        </>
      ),
      confirmButtonVariant: "primary",
      confirmButtonText: "It's not on IMDb!",
    },
  };

  const {
    preHeading,
    alertText,
    missingText,
    confirmButtonVariant,
    confirmButtonText,
  } = unlinkReasonCustomizations[unlinkReason] || {};

  return (
    <IMDbTitleCard
      imdb={match}
      preHeading={preHeading}
      missingText={missingText}
    >
      <Alert variant="warning">{alertText}</Alert>
      <div className="d-flex">
        <Button
          variant="secondary"
          className="me-auto"
          onClick={() =>
            dispatch({
              type: "editor.imdbMatch.unlinkCancelled",
            })
          }
        >
          Cancel
        </Button>
        <Button
          variant={confirmButtonVariant}
          onClick={() =>
            dispatch({
              type: "editor.imdbMatch.unlinkConfirmed",
              payload: { reason: unlinkReason },
            })
          }
        >
          {confirmButtonText}
        </Button>
      </div>
    </IMDbTitleCard>
  );
};

export default UnlinkConfirmationCard;
