import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { request } from "client/utils";
import DiffView from "components/DiffView";
import { Spinner } from "components/Spinner";
import { GlyphButton } from "components/buttons";

const ConfigDraftDiff = ({ match }) => {
  const [draft, setDraft] = React.useState();
  const [currentDraft, setCurrentDraft] = React.useState();
  const [fetching, setFetching] = React.useState();
  const [error, setError] = React.useState();
  const { id } = match.params;

  React.useEffect(() => {
    setFetching(true);

    const updateDrafts = ({ draft, currentDraft }) => {
      setDraft(draft);
      setCurrentDraft(currentDraft);
      setFetching(false);
      setError(null);
    };

    const handleError = ({ message, error }) => {
      const defaultMessage = "Could not load config draft";
      const errorDetails =
        (error && error.userMessage) || message || defaultMessage;
      setError(errorDetails);
      setFetching(false);
    };

    const abortController = new AbortController();
    request(`provisioning/configs/drafts/${id}/diff`, {
      signal: abortController.signal,
    })
      .then(updateDrafts)
      .catch(handleError);

    return () => abortController.abort();
  }, [id]);

  const oldValue = currentDraft && JSON.stringify(currentDraft, null, 2);
  const newValue = draft && JSON.stringify(draft, null, 2);

  return (
    <div className="container-fluid">
      <div className="flex-row my-4">
        <h4 className="flex-fill">Changes in config draft {match.params.id}</h4>
        <Link exact to="/provisioning/configs/drafts">
          <GlyphButton
            glyph="backward"
            variant="primary"
            title="Back to config drafts"
          />
        </Link>
      </div>
      {fetching && (
        <div className="d-flex">
          <Spinner className="m-auto" />
        </div>
      )}
      {oldValue && newValue && (
        <DiffView
          oldValue={oldValue}
          newValue={newValue}
          leftTitle="Currently deployed config"
          rightTitle={`Config draft ${id}`}
        />
      )}
      {error && (
        <Alert variant="danger">
          <Alert.Heading>Could not load config draft</Alert.Heading>
          {error}
        </Alert>
      )}
    </div>
  );
};

export default ConfigDraftDiff;
