import { capitalize } from "@telia-company/tv.no-play-cms-common/api/util";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table-v7";
import "react-table/react-table.css";
import ReactTooltip from "react-tooltip";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import {
  formatRelativeTimestamp,
  parseDateToDatetimeString,
} from "client/utils/TableCommon";
import { KindBadge } from "components/badges";
import Table, { ReactTablePagination } from "components/tables";
import { ManageWarningStatus } from "./ManageWarningStatus";
import { ContentWarningRow } from "./types";

const DEFAULT_PAGE_SIZE = 50;

interface Props {
  channelOptions: { value: number; label: string }[];
  mutate: () => void;
  data: ContentWarningRow[];
  totalWarnings?: number;
  loading: boolean;
}

const ResultList = ({
  channelOptions,
  mutate,
  data,
  totalWarnings,
  loading,
}: Props) => {
  const channelLabel = (chId: string) => {
    const entry = channelOptions.find((ch) => ch.value.toString() === chId);
    return entry ? entry.label : "N/A";
  };
  const [pageSize = DEFAULT_PAGE_SIZE, setPageSize] = useQueryParam(
    "pageSize",
    NumberParam
  );
  const [page = 0, setPage] = useQueryParam("page", NumberParam);
  const [type] = useQueryParam("type", StringParam);

  /**
   * Columns definition for reactTable
   */
  const columns = useMemo((): ReadonlyArray<Column<ContentWarningRow>> => {
    return [
      {
        Header: "Title",
        accessor: "titleField",
        Cell: ({ value }) => (
          <Link
            to={`/enrich/mediacontent/editor/${value.urlParam}`}
            style={{ textDecoration: "underline" }}
          >
            {value.label}
          </Link>
        ),
        width: 50,
      },
      {
        Header: "Top parent title",
        accessor: "topParentTitleField",
        Cell: ({ value }) => (
          <Link
            to={`/enrich/mediacontent/editor/${value.urlParam}`}
            style={{ textDecoration: "underline" }}
          >
            {value.label}
          </Link>
        ),
        width: 50,
      },
      {
        Header: "CRID",
        accessor: "crid",
        width: 50,
      },
      {
        Header: "Warning",
        accessor: (row) => (
          <span>
            <span data-tip={row.description}>
              {row.type}
              <br />
            </span>
          </span>
        ),
        width: 50,
      },
      {
        id: "broadcast",
        Header: type === "catchup" ? "Prev broadcast" : "Next broadcast",
        accessor: (record) =>
          capitalize(formatRelativeTimestamp(record.broadcast)),
        width: 50,
      },
      {
        id: "catchup-end",
        Header: "Catchup end",
        accessor: (record) =>
          capitalize(formatRelativeTimestamp(record.catchupEnd)),
        width: 50,
      },
      {
        id: "kind",
        Header: "Kind",
        accessor: (record) => <KindBadge kind={record?.data?.kind} />,
        width: 30,
      },
      {
        id: "channel",
        Header: "Channel",
        accessor: (record) => {
          const ch = record.channel;
          return channelLabel(ch);
        },
        width: 50,
      },
      {
        id: "Updated",
        Header: "Updated",
        accessor: (record) => parseDateToDatetimeString(record.updated),
        width: 50,
      },
      {
        id: "Manage",
        Header: "Manage",
        accessor: (record) => (
          <ManageWarningStatus
            topMediaContentId={record.topParentId}
            assetId={record.asset_id}
            CWSAStatus={record.CWSAStatus}
            CWSMCStatus={record.CWSMCStatus}
            warningType={record.type}
            mutate={mutate}
          />
        ),
        width: 50,
      },
    ];
  }, [type, channelOptions]);

  return (
    <>
      <Table
        data={data}
        columns={columns}
        initialState={{ pageSize: 50 }}
        loading={loading}
      />
      <ReactTablePagination
        gotoPage={setPage}
        pageIndex={page}
        totalPages={Math.ceil(
          (totalWarnings || 0) / (pageSize || DEFAULT_PAGE_SIZE)
        )}
        pageSize={pageSize}
        setPageSize={(newPageSize: number | null) => {
          setPageSize(newPageSize);
          setPage(null);
        }}
      />
      <ReactTooltip />
    </>
  );
};

export default ResultList;
