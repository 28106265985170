import { DateTime } from "luxon";
import { QueryParamConfig } from "use-query-params";

export const DateParam: QueryParamConfig<
  DateTime | undefined,
  DateTime | undefined
> = {
  encode: (date?: DateTime) => (date ? date.toFormat("yyyy-MM-dd") : undefined),
  decode: (date: string | null | undefined | (string | null)[]) => {
    if (!date) {
      return undefined;
    }
    const m = DateTime.fromFormat(date as string, "yyyy-MM-dd");
    if (!m.isValid) {
      throw new Error(`Invalid timestamp value: '${date}'`);
    }
    return m;
  },
};
