import React from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import { MediaContentSearch } from "client/hooks/useMediaContents";
import { ToggleMultiControl } from "components/controls/ToggleControl";
import {
  ChannelMultiPicker,
  KindMultiPicker,
  ProviderMultiPicker,
} from "components/pickers";

const SearchForm: React.FC<{
  onChange: (query: MediaContentSearch) => void;
  excludeKinds?: string[];
  value: MediaContentSearch;
}> = ({ onChange, excludeKinds, value }) => {
  return (
    <form>
      <Row className="mb-2">
        <Col>
          <FormControl
            autoFocus
            type="text"
            placeholder="Title of content"
            value={value.title}
            onChange={(e) => onChange({ title: e.target.value })}
          />
        </Col>
        <Col xs="auto">
          <Button
            variant={value.activeOnly ? "info" : "light"}
            onClick={() => onChange({ activeOnly: !value.activeOnly })}
          >
            Active only
          </Button>
        </Col>
        <Col md="auto">
          <KindMultiPicker
            value={value.kinds}
            excludeKinds={excludeKinds}
            onChange={(newValue: string[]) => onChange({ kinds: newValue })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ProviderMultiPicker
            value={value.providers || []}
            onChange={(newValue: number[]) => onChange({ providers: newValue })}
          />
        </Col>
        <Col>
          <ChannelMultiPicker
            value={value.channels || []}
            onChange={(newValue: number[]) => onChange({ channels: newValue })}
          />
        </Col>
        <Col md="auto">
          <ToggleMultiControl
            options={["EXTERNAL", "SVOD", "TVOD", "NPVR"]}
            value={value.types}
            onChange={(newValue: string[]) => onChange({ types: newValue })}
          />
        </Col>
      </Row>
    </form>
  );
};

export default SearchForm;
