import { DateTime } from "luxon";
import useSWR from "swr";
import { StringParam, useQueryParam } from "use-query-params";
import { useFrom, useTo } from "../hooks/useFromAndTo";
import {
  DataPoint,
  StatsData,
  StatsHistory,
  StatsHistoryResponse,
} from "./types";

export const useWarningStatsHistory = (): StatsHistory => {
  const { fromDateString } = useFrom();
  const { toDateString } = useTo();
  const [resolution] = useQueryParam("resolution", StringParam);
  const { data } = useSWR<StatsHistoryResponse>(
    `enrichment/stats-history?from=${fromDateString}&to=${toDateString}&resolution=${
      resolution || "day"
    }`
  );
  if (!data) {
    return {};
  }
  const parsedData = parseStatsHistoryResponse(data);
  const statsData = selectFirstDataPointPerDay(parsedData);
  const reversedData = reverseDataPoints(statsData);
  return { firstData: DateTime.fromISO(data.firstData), data: reversedData };
};

const parseStatsHistoryResponse = (data: StatsHistoryResponse) => {
  const parsedData: StatsData = {};
  for (const [warningType, dataPoints] of Object.entries(data.data)) {
    parsedData[warningType] = dataPoints.map((dataPoint) => ({
      count: dataPoint.count,
      created: DateTime.fromISO(dataPoint.created),
      average_of: dataPoint.average_of,
      incomplete: dataPoint.incomplete,
    }));
  }
  return parsedData;
};

const selectFirstDataPointPerDay = (parsedData: StatsData) => {
  const statsData: StatsData = {};
  for (const [warningType, dataPoints] of Object.entries(parsedData)) {
    statsData[warningType] = extractDailyDataPoints(dataPoints);
  }
  return statsData;
};

const extractDailyDataPoints = (dataPoints: DataPoint[]) => {
  const dailyDataPoints: DataPoint[] = [dataPoints[0]];
  let lastDay = dataPoints[0].created.startOf("day");
  for (const dataPoint of dataPoints.slice(1)) {
    const candidateDay = dataPoint.created.startOf("day");
    if (candidateDay > lastDay) {
      dailyDataPoints.push(dataPoint);
      lastDay = dataPoint.created.startOf("day");
    }
  }
  return dailyDataPoints;
};

const reverseDataPoints = (statsData: StatsData) => {
  const reversedData: StatsData = {};
  for (const [warningType, dataPoints] of Object.entries(statsData)) {
    reversedData[warningType] = dataPoints.reverse();
  }
  return reversedData;
};
