import React from "react";
import { Button } from "react-bootstrap";

export function EditorHeaderToolbar(props) {
  return (
    <div className="secondary btn-toolbar flex-row">
      <h1 className="flex-fill">{props.title}</h1>

      <Button variant="secondary my-auto" onClick={props.onCancel}>
        Cancel
      </Button>
      <Button
        disabled={!props.isValid}
        variant="primary"
        onClick={props.onSave}
      >
        Save
      </Button>
    </div>
  );
}
