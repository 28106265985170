import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

function TabNavigation() {
  const { activeTab, setActiveTab } = useTabs();

  return (
    <Container className="pt-4">
      <Tabs id="mc-tabs" activeKey={activeTab} onSelect={setActiveTab}>
        <Tab eventKey="editor" title="Editor" />
        <Tab eventKey="history" title="History" />
      </Tabs>
    </Container>
  );
}

export default TabNavigation;

function useTabs() {
  const navigate = useNavigate();
  const { tab: activeTab, id } = useParams();
  const setActiveTab = (tab: string | null) => {
    let path;
    if (tab) {
      path = `/enrich/mediacontent/${tab}/${id}`;
    } else {
      path = `/enrich/mediacontent/editor/${id}`;
    }
    navigate(path);
  };

  return { activeTab, setActiveTab };
}
