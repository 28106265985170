import autobind from "autobind-decorator";
import { PropTypes } from "prop-types";
import React from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { GLYPHS } from "components/buttons/glyphs";
import { ProviderMultiPicker, ChannelMultiPicker } from "components/pickers";

export default class AddProviderDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  @autobind
  onChange(valueArray) {
    if (valueArray && valueArray.length > 0) {
      const value = valueArray[0];
      // Make sure IDs are numbers for prop types to not complain
      this.setState({ value: value ? Number(value) : null });
    }
  }

  @autobind
  onAdd() {
    this.props.onAdd(this.state.value);
    this.props.onHide();
  }

  render() {
    const Picker =
      this.props.mode == "provider" ? ProviderMultiPicker : ChannelMultiPicker;
    return (
      <Modal
        animation={true}
        autoFocus
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h1 style={{ margin: "0 0 14px 26pt" }}>Add {this.props.mode}</h1>
            </Col>
          </Row>
          <Row>
            <Col cs={12}>
              <div className="flex-center-row">
                <span
                  className={GLYPHS.SEARCH}
                  style={{
                    fontSize: "16pt",
                    margin: "8px 8px 8px 0",
                    width: "20pt",
                  }}
                />

                <Picker
                  multiple={false}
                  value={this.state.value === null ? [] : [this.state.value]}
                  onChange={this.onChange}
                  style={{ width: "100%" }}
                  exclude={this.props.exclude}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-right"
            variant="primary"
            type="submit"
            disabled={false}
            onClick={this.onAdd}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddProviderDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
};
