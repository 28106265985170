import { DateTime } from "luxon";
import React from "react";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";

export const LinkToGemCacheViewer = () => {
  const [oneappChannel] = useQueryParam("oneappChannel", StringParam);
  const [dayOffset] = useQueryParam("dayOffset", NumberParam);
  if (!oneappChannel) {
    return null;
  }
  const date = DateTime.now()
    .plus({ days: dayOffset || 0 })
    .toFormat("yyyy-MM-dd");
  const url = `https://gemcacheviewer-prod.tvm.telia.com/telia-no/channel/${oneappChannel}?date=${date}`;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      Show channel in gemcacheviewer
    </a>
  );
};
