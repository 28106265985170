import React from "react";
import { Button, Form } from "react-bootstrap";
import { CmsPure } from "client/utils";
import ProvisionerIdMultiPicker from "./ProvisionerIdMultiPicker";
import ProvisionerIdPicker from "./ProvisionerIdPicker";

const LABEL = "Publish Config";

class DVBCablePlansComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      configDraftId: "",
      lineupIds: [],
    };
  }

  create = async (event) => {
    event.preventDefault();
    const { lineupIds, configDraftId } = this.state;
    if (
      !confirm(
        `This will publish ${lineupIds.length} new config(s). Are you sure?`
      )
    )
      return;

    for (let i = 0; i < lineupIds.length; i++) {
      const payload = { lineupId: lineupIds[i], configDraftId };
      const doNotRefreshTables = i !== lineupIds.length - 1;
      await this.props.post(payload, LABEL, true, doNotRefreshTables);
    }
  };

  render() {
    console.log(LABEL, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{LABEL}</h1>
        <ProvisionerIdPicker
          contentType="Config Draft"
          selectedId={this.state.configDraftId}
          onSelect={(val) => this.setState({ configDraftId: val })}
        />
        <ProvisionerIdMultiPicker
          contentType="Lineup"
          selectedIds={this.state.lineupIds}
          onSelect={(lineupIds) => this.setState({ lineupIds })}
        />
        <Button
          className="pull-right mt-4"
          variant="primary"
          type="submit"
          disabled={!this.state.configDraftId || !this.state.lineupIds.length}
        >
          {LABEL}
        </Button>
      </Form>
    );
  }
}

export default DVBCablePlansComposer;
