import { NumericArrayParam, useQueryParam } from "use-query-params";
import { useSessionStorage } from "usehooks-ts";

export const useChannelSelection = () => {
  const [sessionStorageChannels, setSessionStorageChannels] = useSessionStorage<
    number[] | null
  >("linear-metadata-channels", null);

  const [selectedChannels = sessionStorageChannels, setChannels] =
    useQueryParam("channel", NumericArrayParam);
  return {
    selectedChannels,
    setChannels: (newChannels: number[] | null) => {
      setSessionStorageChannels(newChannels);
      setChannels(newChannels);
    },
  };
};
