import React from "react";
import { FormLabel, Alert } from "react-bootstrap";
import { CmsPure } from "client/utils";
import { GLYPHS } from "components/buttons/glyphs";
import { ToggleControlWithLabels } from "components/controls/ToggleControl";

const StatusAlert = ({ type }) => (
  <Alert variant="danger" className="mt-2">
    <span className={GLYPHS.WARNING} /> This {type} has unavailable content.
  </Alert>
);

class StatusComponent extends CmsPure {
  static OPTIONS = [
    { label: "Active", value: true, variant: "info" },
    { label: "Inactive", value: false, variant: "warning" },
  ];

  render() {
    const { enabled, activeContent, type, onChange } = this.props;
    return (
      <div className="text-end">
        <FormLabel hidden>Status</FormLabel>
        <ToggleControlWithLabels
          value={enabled}
          options={StatusComponent.OPTIONS}
          onChange={onChange}
        />
        {!enabled && (
          <div className="help-block">Only active {type}s can be scheduled</div>
        )}
        {!activeContent && <StatusAlert type={type} />}
      </div>
    );
  }
}

export default StatusComponent;
