import { AssetInfo } from "client/types/AssetInfo";
import { PRIORITIZED_LOCALES } from "client/views/Epg/utils";

const compareLocales = (a: string, b: string): number => {
  const aPrioritized = PRIORITIZED_LOCALES.includes(a);
  const bPrioritized = PRIORITIZED_LOCALES.includes(b);

  if (aPrioritized && bPrioritized) {
    return PRIORITIZED_LOCALES.indexOf(a) - PRIORITIZED_LOCALES.indexOf(b);
  } else if (aPrioritized) {
    return -1;
  } else if (bPrioritized) {
    return 1;
  } else if (a < b) {
    return -1;
  } else if (b < a) {
    return 1;
  }

  return 0;
};

const reduceTitles = (
  titles: Record<string, string> | undefined,
  filter?: (v: string) => boolean
): string | undefined => {
  if (!titles) return undefined;
  const pairs = Object.entries(titles);
  return (filter ? pairs.filter(([_, v]) => filter(v)) : pairs).reduce(
    (a, b) => (compareLocales(a[0], b[0]) > 0 ? b : a)
  )[1];
};

export const getPrimaryTitle = (asset: AssetInfo): string | undefined =>
  reduceTitles(asset.titles);

export const getPrimaryTopTitle = (asset: AssetInfo): string | undefined =>
  asset.originalTitle ||
  reduceTitles(asset.grandParentTitles) ||
  reduceTitles(asset.parentTitles, (t) => !t.match(/^[0-9]+$/));
