import React from "react";
import { BadgeProps } from "react-bootstrap";
import { StatusBadge } from "components/badges";

interface Props extends BadgeProps {
  kind: string;
  colorAs?: string;
  inline?: boolean;
}

function kindToVariant(kind: string) {
  switch (kind) {
    case "EPISODE":
      return "info";
    case "SERIES":
      return "secondary";
    case "PROGRAM":
      return "danger";
    case "MOVIE":
      return "warning";
    default:
      return "light";
  }
}

export function KindBadge({ kind, colorAs, inline, ...rest }: Props) {
  return (
    <StatusBadge
      status={kind.toUpperCase()}
      statusToVariant={(kind) =>
        kindToVariant(colorAs ? colorAs.toUpperCase() : kind)
      }
      inline={inline}
      {...rest}
    />
  );
}
