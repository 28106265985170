import React from "react";
import { Col, Row } from "react-bootstrap";

interface Props {
  autoOrientation: boolean;
}

export const LayoutOptionsExplainer = ({ autoOrientation }: Props) => {
  return (
    <Row className="mb-4">
      <Col>
        {autoOrientation ? (
          <div className="help-block">
            When layout mode is set to <i>Auto</i>, movie collections are shown
            as vertical cards and all other collections are shown as horizontal
            cards. Set layout mode to <i>Manual</i> to pick a custom row type
            for the collection.
          </div>
        ) : (
          <div className="help-block text-danger">
            <b>Note</b>: The new collection row types are not yet supported by
            the backend nor the clients. Until support has been added, the new
            row types will be mapped to{" "}
            <i>
              <b>Card Orientation: Landscape</b>
            </i>{" "}
            or
            <i>
              <b>Card Orientation: Portrait</b>
            </i>{" "}
            as appropriate.
          </div>
        )}
      </Col>
    </Row>
  );
};
