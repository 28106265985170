import classNames from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Column,
  FilterTypes,
  PluginHook,
  TableState,
  useFilters,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table-v7";
import ControlledReactTable, {
  ReactTablePagination,
} from "./ControlledReactTable";

interface Props<T extends object> {
  columns: ReadonlyArray<Column<T>>;
  data: ReadonlyArray<T>;
  flex?: boolean;
  onClick?: (row: T) => void;
  initialState?: Record<string, unknown>;
  manualSortBy?: boolean;
  disableMultiSort?: boolean;
  disableSortRemove?: boolean;
  selectedItem?: T;
  onChange?: (state: TableState<T>) => void;
  getRowId?: (row: T) => string;
  loading?: boolean;
  filter?: boolean;
  paginate?: boolean;
  className?: string;
}

interface RowBase {
  id?: string | number;
}

function Table<T extends RowBase>({
  columns,
  data,
  flex = false,
  onClick = undefined,
  initialState = {},
  manualSortBy = false,
  disableMultiSort = false,
  disableSortRemove = false,
  selectedItem = undefined,
  onChange = undefined,
  getRowId = undefined,
  loading = false,
  filter = false,
  paginate = false,
  className = undefined,
}: Props<T>) {
  const memoizedGetRowId = useCallback(
    (row: T) => {
      if (getRowId) {
        return getRowId(row);
      } else if (row.id !== undefined) {
        return row.id.toString();
      }
      return "";
    },
    [getRowId]
  );
  const selectedRowIds: Record<string, boolean> = useMemo(() => {
    const rowIds = new Set(data.map(memoizedGetRowId));

    if (selectedItem) {
      const selectedItemId = memoizedGetRowId(selectedItem);

      if (rowIds.has(selectedItemId)) {
        return { [selectedItemId]: true };
      }
    }
    return {};
  }, [data, selectedItem, memoizedGetRowId]);

  const preparedData = useMemo(
    () => data.map((d) => (onClick ? { ...d, onClick } : d)),
    // Include selectedRowIds in dependencies to force the table to be
    // re-rendered if the selected row changes.
    [data, selectedRowIds]
  );

  const pluginCandidates: (PluginHook<T> | undefined)[] = [
    flex ? useFlexLayout : undefined,
    filter ? useFilters : undefined,
    initialState.sortBy ? useSortBy : undefined,
    paginate ? usePagination : undefined,
    useRowSelect,
  ];

  const plugins: PluginHook<T>[] = [];

  for (const c of pluginCandidates) {
    if (c !== undefined) {
      plugins.push(c);
    }
  }

  const filterTypes: FilterTypes<T> = React.useMemo(
    () => ({
      notEqualsString: (rows, id, filterValue) => {
        if (!id || id.length < 1) {
          return rows;
        }
        return rows.filter((row) => {
          const rowValue = row.values[id[0]];
          return rowValue.indexOf(filterValue) === -1;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    getRowProps,
    headerGroups,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    rows,
    page,
    state,
  } = useTable(
    {
      columns,
      data: preparedData,
      getRowId: memoizedGetRowId,
      initialState: { selectedRowIds, ...initialState },
      manualSortBy,
      disableMultiSort,
      disableSortRemove,
      filterTypes,
    },
    ...plugins
  );

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state]);

  return (
    <>
      <ControlledReactTable
        className={classNames(loading && "loading", className)}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        getRowProps={getRowProps}
        rows={page || rows}
        placeholderProps={{ colSpan: columns.length }}
        placeholder={loading ? "Loading…" : "There are no matching results"}
      />
      {paginate && (
        <ReactTablePagination
          pageIndex={state.pageIndex}
          pageSize={state.pageSize}
          totalPages={pageCount}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
}

export default Table;
