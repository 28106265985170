import React from "react";
import { Button, Form } from "react-bootstrap";
import { CmsPure } from "client/utils";

const LABEL = "Config Draft";

class ConfigDraftComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {};
  }

  create = (event) => {
    event.preventDefault();
    const payload = this.state;
    this.props.post(payload, LABEL);
  };

  render() {
    console.log(LABEL, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{"Generate " + LABEL}</h1>
        This will generate a new config
        <Button className="pull-right mt-4" variant="primary" type="submit">
          {"Generate " + LABEL}
        </Button>
      </Form>
    );
  }
}

export default ConfigDraftComposer;
