import { LogLevel } from "@azure/msal-browser";
import { CLIENT_CONFIG } from "client/config";

export const msalConfig = {
  auth: {
    // The client ID is registered as an application in Telia's Azure AD
    // tenant and is provided by MSAL during authentication. We have two
    // registered IDs, one for QA and dev environments and one for prod.
    clientId: CLIENT_CONFIG.AZURE_CLIENT_ID,

    // The authority is the root URL for Telia's Azure AD tenant (user
    // directory). This is the same for all environments, as we
    // authenticate against the same pool of user identities regardless
    // of whether we're logging in to prod or QA.
    authority:
      "https://login.microsoftonline.com/05764a73-8c6f-4538-83cd-413f1e1b5665",

    // Azure AD redirects to this URL after successful authentication
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: unknown,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
    },
  },
};
