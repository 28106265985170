import { DateTime } from "luxon";
import React, { useCallback, useState } from "react";
import { Column } from "react-table-v7";
import useSWR from "swr";
import Table from "components/tables";

interface UsageStatsResponse {
  stats: UsageStatDTO[];
}

interface UsageStats {
  stats: UsageStat[];
}

interface UsageStatBase<T> {
  id: number;
  path: string;
  path_hits: number;
  search_hits: number;
  latest_hit: T;
}

type UsageStatDTO = UsageStatBase<string>;

type UsageStat = UsageStatBase<DateTime>;

const columns: Column<UsageStat>[] = [
  {
    Header: "Path",
    accessor: "path",
  },
  {
    Header: "Path hits",
    accessor: "path_hits",
  },
  {
    Header: "Search hits",
    accessor: "search_hits",
  },
  {
    Header: "Latest hit",
    id: "latest_hit",
    accessor: (row) => row.latest_hit.toFormat("yyyy-MM-dd HH:mm"),
  },
];

type SortBy = { id: string; desc: boolean }[];

export const UsageStats = () => {
  const { data: rawData } = useSWR<UsageStatsResponse>("usagestats");
  const [sortState, setSortState] = useState<SortBy>([
    { id: "path", desc: false },
  ]);

  const onChange = useCallback(
    (params: unknown) => {
      const { sortBy } = params as { sortBy: SortBy };
      setSortState(sortBy);
    },
    [setSortState]
  );

  const data = convertData(rawData);

  if (!data) {
    return <div>Loading...</div>;
  }

  const sortedData = sortData(data, sortState);

  return (
    <Table
      columns={columns}
      data={sortedData.stats}
      manualSortBy={true}
      initialState={{ sortBy: sortState }}
      onChange={onChange}
    />
  );
};

const convertData = (data?: UsageStatsResponse): UsageStats | undefined => {
  if (!data) {
    return undefined;
  }
  return {
    stats: data.stats.map((stat) => {
      const { latest_hit, ...rest } = stat;
      return { latest_hit: DateTime.fromISO(latest_hit), ...rest };
    }),
  };
};

const sortData = (data: UsageStats, sortBy: SortBy): UsageStats => {
  console.log(sortBy);
  let compareFn: (a: UsageStat, b: UsageStat) => number = () => 0;
  if (sortBy.length === 0) {
    return data;
  }

  const multiplier = sortBy[0].desc ? -1 : 1;
  const sortKey = sortBy[0].id;
  switch (sortKey) {
    case "path":
      compareFn = (a, b) => a.path.localeCompare(b.path) * multiplier;
      break;
    case "path_hits":
      compareFn = (a, b) => (b.path_hits - a.path_hits) * multiplier;
      break;
    case "search_hits":
      compareFn = (a, b) => (b.search_hits - a.search_hits) * multiplier;
      break;
    case "latest_hit":
      compareFn = (a, b) => (b.latest_hit > a.latest_hit ? 1 : -1) * multiplier;
      break;
  }
  return { stats: [...data.stats].sort(compareFn) };
};
