import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { request } from "client/utils";

interface Props {
  id: string;
  path: string;
}

export const NewMatch = ({ id, path }: Props) => {
  const [newMcId, setNewMcId] = useState<string>("");
  const navigate = useNavigate();
  const addMatch = async () => {
    await request("epg/match", {
      method: "POST",
      body: JSON.stringify({ to: newMcId, from: id, isActive: true }),
    });
    navigate(`${path}/from/${id}/to/${newMcId}`);
    toast(`Added match from ${id} to ${newMcId}`);
  };

  return (
    <>
      <h3 className="lead border-bottom">New match</h3>
      <Row>
        <Col xs={6}>
          <Form.Label htmlFor="media-content-id">Media content id</Form.Label>
          <Form.Group className="flex-row">
            <Form.Control
              type="text"
              id="media-content-id"
              value={newMcId}
              onChange={(e) => setNewMcId(e.target.value)}
            />
            <Button onClick={addMatch} className="ms-2">
              New match
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
