import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { NewMatch } from "./NewMatch";
import { ProgramChildren } from "./ProgramChildren";
import { ProgramDetails } from "./ProgramDetails";

interface Props {
  id: string;
  onClose: () => void;
  path: string;
  showNewMatch?: boolean;
}

export const ProgramDetailsModal = ({
  id,
  onClose,
  path,
  showNewMatch,
}: Props) => {
  return (
    <Modal size="xl" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <h2 className="m-0">Program details</h2>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col xs={12}>
              <ProgramDetails id={id} database="METADB" />
              {showNewMatch !== false && <NewMatch id={id} path={path} />}
              <ProgramChildren id={id} database="METADB" />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
