import update from "immutability-helper";
import { throttle } from "lodash";
import moment from "moment";
import { PublicationTypes } from "client/types/PublicationTypes";
import { formatModalScheduleToSchedule } from "./utils";

function getDefaultState() {
  return {
    [PublicationTypes.TOP_PROMO]: [],
    [PublicationTypes.COVER_PROMO]: [],
    [PublicationTypes.COLLECTION]: [],

    indexRange: {
      [PublicationTypes.TOP_PROMO]: [1],
      [PublicationTypes.COVER_PROMO]: [0, 1, 2, 3, 4],
      [PublicationTypes.COLLECTION]: [1, 2, 3, 4, 5, 6],
    },
    now: moment().unix(),
    itemModal: DEFAULT_ITEM_MODAL,
    hoverItem: null,
    fetching: true,
  };
}

export const ItemModalModes = {
  VIEW: "view",
  EDIT: "edit",
  COPY: "copy",
  CREATE: "create",
};

const NULL_SCHEDULE = {
  title: "",
  publish: null,
  unpublish: null,
  collectionId: undefined,
  promotionId: undefined,
};
const NULL_ITEM = {
  active: true,
  title: "",
  id: undefined,
  contentType: "content",
  content: {},
};
const DEFAULT_ITEM_MODAL = {
  schedule: NULL_SCHEDULE,
  item: NULL_ITEM,
  itemType: null,
  mode: ItemModalModes.VIEW,
  show: false,
};

export default class SchedulerStateHandler {
  constructor(self) {
    self.state = getDefaultState();

    // bind self so that 'this' refers to the Scheduler component
    this.setFetching = this.setFetching.bind(self);
    this.setSchedule = this.setSchedule.bind(self);
    this.setHoverItem = this.setHoverItem.bind(self);
    this.updateTimeline = this.updateTimeline.bind(self);

    this.addIndex = this.addIndex.bind(self);
    this.addItem = this.addItem.bind(self);
    this.updateItem = this.updateItem.bind(self);
    this.throttledUpdateItem = throttle(this.updateItem, 25);
    this.deleteItem = this.deleteItem.bind(self);

    this.initItemModal = this.initItemModal.bind(self);
    this.getItemModal = this.getItemModal.bind(self);
    this.getItemBeingManipulated = this.getItemBeingManipulated.bind(self);
    this.updateCreateItemProps = this.updateCreateItemProps.bind(self);
    this.hideItemModal = this.hideItemModal.bind(self);
    this.setModalEditMode = this.setModalEditMode.bind(self);
  }

  setFetching(bool) {
    this.setState({ fetching: bool });
  }
  setSchedule(schedule) {
    this.setState({ ...schedule, fetching: false });
  }
  setHoverItem(hoverItem) {
    this.setState({ hoverItem });
  }
  updateTimeline() {
    this.setState({ now: moment().unix() });
  }

  addIndex(itemType, index) {
    this.setState((state) => ({
      ...state,
      indexRange: {
        ...state.indexRange,
        [itemType]: [...state.indexRange[itemType], index],
      },
    }));
  }
  /** Item functions */
  addItem(entry) {
    const itemType = entry.itemType;
    this.setState((state) => ({
      [itemType]: [...state[itemType], entry],
    }));
  }
  updateItem(newEntry, isDragged) {
    const scheduleId = newEntry.schedule.id;
    const itemType = newEntry.itemType;
    const container = this.state[itemType];

    this.setState({
      [itemType]: container.map((entry) =>
        scheduleId === entry.schedule.id
          ? {
              ...entry,
              ...newEntry,
              isDragged,
            }
          : entry
      ),
    });
  }
  deleteItem(scheduleId, itemType) {
    this.setState((state) => {
      const container = state[itemType];
      return {
        [itemType]: container.filter(
          (other) => other.schedule.id !== scheduleId
        ),
      };
    });
  }

  /** Item Modal functions */
  initItemModal(schedule, item, itemType, mode) {
    const { id, title, unixStart, unixEnd, index } = schedule;
    const formattedSchedule = {
      id,
      title: title || "",
      publish: moment.unix(unixStart),
      unpublish: moment.unix(unixEnd),
      index,
    };

    const formattedItem = { ...(item || NULL_ITEM) };

    if (itemType === PublicationTypes.COVER_PROMO) {
      formattedItem.linkPromoTo = "content";
      formattedItem.content = {};
      formattedItem.pageSection = "home";
    } else if (itemType === PublicationTypes.TOP_PROMO) {
      formattedItem.linkPromoTo = "content";
      formattedItem.content = {};
      formattedItem.pageSection = "home";
      formattedItem.backgroundColor = "";
      formattedItem.overlayAlignment = "left";
      formattedItem.overlayColor = "dark";
    } else if (itemType === PublicationTypes.COLLECTION) {
      formattedItem.description = "";
      formattedItem.contentType = "media";
      formattedItem.assets = [];
      formattedItem.mediaContent = [];
      formattedItem.promos = [];
    }
    this.setState({
      itemModal: {
        item: formattedItem,
        schedule: formattedSchedule,
        itemType,
        mode,
        show: true,
      },
    });
  }
  /** @returns {{show, mode, schedule, itemType, item}} */
  getItemModal() {
    return this.state.itemModal;
  }
  /** @returns {{schedule, item, itemType}} Returns null if modal isn't visible or mode != create or copy */
  getItemBeingManipulated() {
    const { show, mode, schedule, itemType, item } = this.state.itemModal;
    if (!show || (mode != ItemModalModes.CREATE && mode != ItemModalModes.COPY))
      return null;
    return {
      schedule: formatModalScheduleToSchedule(schedule),
      itemType,
      item,
    };
  }
  updateCreateItemProps(newProps) {
    this.setState((state) => {
      return update(state, { itemModal: newProps });
    });
  }

  hideItemModal() {
    this.setState((state) => ({
      itemModal: { ...state.itemModal, show: false },
    }));
  }
  setModalEditMode() {
    this.setState((state) => ({
      itemModal: { ...state.itemModal, mode: ItemModalModes.EDIT },
    }));
  }
}
