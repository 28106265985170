import React from "react";
import { CmsCardContainer, CloudImageCard } from "components/cards";
import { AnnotatedImage } from "./types";

interface Props {
  images: AnnotatedImage[];
  onChange: (path: string, type: string) => void;
}

function ImageList({ images, onChange }: Props) {
  return (
    <CmsCardContainer className="cover">
      {images.map((image) => (
        <div
          key={image.value.path}
          className="cover-image-suggestion"
          onClick={() => onChange(image.value.path, "cover")}
        >
          <CloudImageCard value={image.value.path} width={576} height={864} />
        </div>
      ))}
    </CmsCardContainer>
  );
}

export default ImageList;
