import React, { useCallback, useEffect, useReducer } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { STATUS, useRequest } from "client/hooks";
import { request } from "client/utils";
import AssetLinksCard from "./AssetLinksCard";
import ContentLinks from "./ContentLinks";
import ContentWarningsCard from "./ContentWarningsCard";
import ImagesCard from "./ImagesCard";
import LocalizedMetadataCard from "./LocalizedMetadataCard";
import MediaContentHeader from "./MediaContentHeader";
import NonlocalizedMetadataCard from "./NonlocalizedMetadataCard";
import { PurgeEditorialChangesCard } from "./PurgeEditorialChangesCard";
import { DispatchContext } from "./context";
import { initialState, reducer } from "./reducer";

const MediaContentEditor = ({ id }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    mc,
    selectedLanguage,
    applyGenresToChildren,
    status,
    saving,
    changes,
    metadataApiChanges,
    dirty,
  } = state;

  const [, refreshMediaContent] = useRequest(`mediacontent/${id}/annotated`, {
    beforeRequest() {
      dispatch({ type: "mc.load.init" });
    },
    onSuccess(mc) {
      dispatch({ type: "mc.load.success", payload: mc });
    },
    onError(err) {
      dispatch({ type: "mc.load.error", payload: err });
    },
  });

  useEffect(() => {
    if (saving) {
      const { data, options } = changes;
      request(`mediacontent/editor/${id}`, {
        method: "PUT",
        body: JSON.stringify({ data, options }),
      })
        .then(() => {
          if (metadataApiChanges) {
            request(`epg/reconciliation/media-content`, {
              method: "PATCH",
              body: JSON.stringify(metadataApiChanges),
            })
              .then(() => {
                dispatch({ type: "mc.save.success" });
                // Ugly hack; the metadata-cms-api responds to our request
                // before the reconciliation has been done, and there's no way
                // to know when it's done. It *should* be done after 2 seconds,
                // so just wait for 2 seconds before refreshing the media
                // content.
                setTimeout(refreshMediaContent, 2000);
              })
              .catch((err) =>
                dispatch({ type: "mc.save.error", payload: err })
              );
          } else {
            dispatch({ type: "mc.save.success" });
            refreshMediaContent();
          }
        })
        .catch((err) => dispatch({ type: "mc.save.error", payload: err }));
    }
  }, [saving, changes]);

  const save = useCallback(
    () => dispatch({ type: "mc.save.init" }),
    [dispatch]
  );
  const isLoading = mc === undefined || status === STATUS.FETCHING;
  const kind = !isLoading && mc.data.kind.value;
  const linkParentId =
    kind === "episode"
      ? mc.parentId
      : kind === "season"
        ? id
        : kind === "series"
          ? id
          : kind === "show"
            ? id
            : undefined;

  const shouldDisplayAssets =
    kind === "episode" || kind === "program" || kind === "movie";

  const images = mc?.data?.images || [];

  return (
    <DispatchContext.Provider value={dispatch}>
      <Container className="pt-1">
        <Row>
          <Col xs={8}>
            <MediaContentHeader
              id={id}
              isLoading={isLoading}
              parentId={mc?.parentId}
              grandParentId={mc?.grandParentId}
            />
            {linkParentId && <ContentLinks parentId={linkParentId} />}
            <LocalizedMetadataCard
              applyGenresToChildren={applyGenresToChildren}
              isLoading={isLoading}
              selectedLanguage={selectedLanguage}
              data={mc?.data}
              dispatch={dispatch}
              dirty={dirty}
            />
          </Col>
          <Col>
            <ContentWarningsCard id={id} />
            <NonlocalizedMetadataCard
              isLoading={isLoading}
              data={mc?.data}
              dispatch={dispatch}
              dirty={dirty}
            />
            <ImagesCard id={id} images={images} isLoading={isLoading} />
            {shouldDisplayAssets && <AssetLinksCard id={id} />}
            <PurgeEditorialChangesCard
              id={id}
              refreshMediaContent={refreshMediaContent}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert
              variant="secondary"
              className="mt-4 d-flex justify-content-end"
            >
              <Button variant="primary" disabled={!dirty} onClick={save}>
                {dirty ? "Save" : "All changes saved"}
              </Button>
            </Alert>
          </Col>
        </Row>
      </Container>
    </DispatchContext.Provider>
  );
};

export default MediaContentEditor;
