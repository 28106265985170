import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { Truncate } from "components/Truncate";
import { RelativeTime } from "components/time/RelativeTime";
import {
  MetadataAsset,
  MetadataProgramResponse,
} from "../types/MetadataProgramResponse";

export function getNextBroadcast(assets: MetadataAsset[]) {
  const now = DateTime.now();
  const futureAssets = _.sortBy(
    assets.filter((a) => a.linearStart > now),
    "linearStart"
  );
  return futureAssets[0];
}

interface Props {
  program?: MetadataProgramResponse;
}

export function NextBroadcast({ program }: Props) {
  //const { program, error } = useProgram(programId);

  if (!program) {
    return <div className="text-muted">...</div>;
  }

  const { assets } = program;
  const nextBroadcast = getNextBroadcast(assets);

  if (nextBroadcast) {
    return <RelativeTime timestamp={nextBroadcast.linearStart.toISO()} />;
  } else {
    return <Truncate className="text-warning">No upcoming broadcast</Truncate>;
  }
}
