import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { request } from "client/utils";
import { ConfirmButton } from "components/buttons";

interface Props {
  fromId: string;
  toId: string;
  matchId: number;
  path: string;
}

export const DeleteMatchButton = ({ fromId, toId, matchId, path }: Props) => {
  const navigate = useNavigate();
  const onClick = async () => {
    await request(`epg/match/${matchId}`, { method: "DELETE" });
    navigate(`${path}/details/${fromId}`);
    toast(`Match with id ${matchId} from ${fromId} to ${toId} deleted`);
  };
  return (
    <ConfirmButton
      variant="outline-danger"
      confirmVariant="danger"
      confirmChildren="Confirm delete"
      aria-label="Delete match"
      onClick={onClick}
    >
      Delete match
    </ConfirmButton>
  );
};
