import classNames from "classnames";
import React from "react";

const IMDbLink = ({ imdb, children, className }) => (
  <a
    href={imdb.imdbUrl}
    target="_blank"
    rel="noopener noreferrer"
    title="View on IMDb"
    className={classNames(className, "underline")}
  >
    {children ? (
      children
    ) : (
      <>
        {imdb.originalTitle}
        {imdb.year && (
          <>
            {" "}
            <span className="text-muted">({imdb.year})</span>
          </>
        )}
      </>
    )}
  </a>
);

export default IMDbLink;
