import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

export const ShowMarkedWarningsOnlyToggle = () => {
  const [showMarkedOnly, setShowMarkedOnly] = useQueryParam(
    "showMarkedOnly",
    BooleanParam
  );
  const resetPage = useResetPage();
  const selectShowMarkedOnly = (newShowMarkedOnly: true | null) => {
    setShowMarkedOnly(newShowMarkedOnly);
    resetPage();
  };

  return (
    <InputGroup>
      <Form.Check
        type="switch"
        id="search-filter-show-marked-only"
        checked={showMarkedOnly || false}
        onChange={(e) => selectShowMarkedOnly(e.target.checked || null)}
      />
      <Form.Label htmlFor="search-filter-show-marked-only">
        Show marked only
      </Form.Label>
    </InputGroup>
  );
};
