import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CellProps, Filters } from "react-table-v7";
import ReactTooltip from "react-tooltip";
import useSWR, { KeyedMutator } from "swr";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { useMetadataChannels } from "client/hooks/useMetadataChannels";
import { ColumnWithHeaderTooltip } from "client/types/ColumnWithHeaderTooltip";
import { request } from "client/utils";
import { MetadataSource } from "client/views/Epg/types";
import Table, { OptionCell } from "components/tables";
import { ChannelLogo } from "./ChannelLogo";
import { ChannelNameFilter } from "./ChannelNameFilter";
import { ContentWarningsLink } from "./ContentWarningsLink";
import { DescriptionLanguage } from "./DescriptionLanguage";
import { EditChannelModal } from "./EditChannelModal";
import { GNChannel } from "./GNChannel";
import { LinearLink } from "./LinearLink";
import { ManageCell } from "./ManageCell";
import { PropertyFilter } from "./PropertyFilter";
import { ShowDebugColumnsToggle } from "./ShowDebugColumnsToggle";
import { StatusFilter } from "./StatusFilter";
import {
  CatchupCoverage,
  ChannelWithAdditionalInfo,
  ContentWarningStatsPerChannel,
  ParentalRatingCoverage,
  WarningCount,
} from "./types";

const STATUS = [
  { value: true, label: "Active", variant: "light" },
  { value: false, label: "Inactive", variant: "secondary" },
];

const PROPERTIES = [
  { value: "catchup", label: "Catchup", variant: "warning" },
  { value: "httpStream", label: "HTTP Stream", variant: "primary" },
  { value: "recordable", label: "Recordable", variant: "light" },
  { value: "externalCatchup", label: "External Catchup", variant: "secondary" },
];

export const ChannelAdditionalInfo = () => {
  const [showDebugColumns] = useQueryParam("debug", BooleanParam);
  const [editingChannel, setEditingChannel] = useState<string | undefined>(
    undefined
  );
  const { channels: metadataChannels } = useMetadataChannels();
  const prgServIdToChannelId = useMemo(
    () => getPrgServIdToChannelIdMap(metadataChannels),
    [metadataChannels]
  );
  const [propertyNegated] = useQueryParam("propertyNegated", BooleanParam);
  const columns = useMemo(
    () =>
      getColumns(
        showDebugColumns || false,
        setEditingChannel,
        prgServIdToChannelId,
        propertyNegated || false
      ),
    [setEditingChannel, showDebugColumns, metadataChannels, propertyNegated]
  );
  const { data, mutate } = useChannelsWithAdditionalInfo(
    showDebugColumns || false
  );
  const filters = useFilters();

  if (!data) {
    return (
      <Container fluid className="my-4">
        <div>Loading...</div>
      </Container>
    );
  }
  return (
    <Container fluid className="my-4">
      <Row className="mb-2">
        <Col xs={3}>
          <StatusFilter />
        </Col>
        <Col xs={3}>
          <ChannelNameFilter />
        </Col>
        <Col xs={3}>
          <PropertyFilter />
        </Col>
        <Col xs={3}>
          <div className="d-flex justify-content-end">
            <ShowDebugColumnsToggle />
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        data={data}
        paginate
        initialState={{
          pageSize: 50,
          filters,
        }}
        filter
      />
      <ReactTooltip />
      {editingChannel && (
        <EditChannelModal
          savedChannelInfo={
            data.filter(
              (channel: ChannelWithAdditionalInfo) =>
                channel.id === editingChannel
            )[0]
          }
          save={async (body: unknown) => {
            await request(`channels/info/${editingChannel}`, {
              method: "PUT",
              body: JSON.stringify(body),
            });
            setEditingChannel(undefined);
            await mutate();
          }}
          close={() => setEditingChannel(undefined)}
        />
      )}
    </Container>
  );
};

const useFilters = () => {
  const [status = "active"] = useQueryParam("status", StringParam);
  const [channelName] = useQueryParam("channelName", StringParam);
  const [property] = useQueryParam("property", StringParam);
  const filters: Filters<TableRow> = [];
  if (status && status !== "all") {
    filters.push({ id: "status", value: status === "active" });
  }
  if (channelName) {
    filters.push({ id: "name", value: channelName });
  }
  if (property && property !== "any") {
    filters.push({ id: "properties", value: property });
  }
  return filters;
};

type TableRow = ChannelWithAdditionalInfo & {
  counts: WarningCount[];
  parentalRatingCoverage?: number;
  prCoverageLoading: boolean;
  catchupTrue?: number;
  catchupFalse?: number;
  catchupNull?: number;
  catchupCoverageLoading: boolean;
};
const useChannelsWithAdditionalInfo = (
  showDebugColumns: boolean
): {
  data?: TableRow[];
  mutate: KeyedMutator<ChannelWithAdditionalInfo[]>;
} => {
  const { data: stats } = useSWR<ContentWarningStatsPerChannel>(
    "enrichment/stats-per-channel"
  );
  const { data: channels, mutate } =
    useSWR<ChannelWithAdditionalInfo[]>("channels/info");
  const startDate = DateTime.now().toFormat("yyyy-MM-dd");
  const endDate = DateTime.now().plus({ weeks: 2 }).toFormat("yyyy-MM-dd");
  const { data: parentalRatings } = useSWR<ParentalRatingCoverage>(
    showDebugColumns
      ? `epg/coverage/parentalrating?startDate=${startDate}&endDate=${endDate}`
      : null
  );
  const { data: catchup } = useSWR<CatchupCoverage>(
    showDebugColumns
      ? `epg/coverage/catchup?startDate=${startDate}&endDate=${endDate}`
      : null
  );
  if (!channels || !stats) {
    return { mutate };
  }
  const returnedData = channels.map((channel) => {
    const catchupCoverage = catchup?.find(
      (c) => c.channelId === parseInt(channel.id)
    );
    return {
      ...channel,
      counts:
        stats[parseInt(channel.id)]?.sort((a, b) => b.count - a.count) || [],
      parentalRatingCoverage: parentalRatings?.find(
        (parentalRating) => parentalRating.channelId === parseInt(channel.id)
      )?.prCoverage,
      prCoverageLoading: !parentalRatings,
      catchupTrue: catchupCoverage?.truePercent,
      catchupFalse: catchupCoverage?.falsePercent,
      catchupNull: catchupCoverage?.nullPercent,
      catchupCoverageLoading: !catchup,
    };
  });
  return {
    data: returnedData,
    mutate,
  };
};

const getPrgServIdToChannelIdMap = (
  metadataChannels: MetadataSource[] | null
) => {
  if (!metadataChannels) {
    return {};
  }
  const prgServIdToChannelId: { [prgServId: string]: number } = {};
  for (const channel of metadataChannels) {
    prgServIdToChannelId[channel.metadataProviderUniqueId] = channel.id;
  }
  return prgServIdToChannelId;
};

const getColumns = (
  showDebugColumns: boolean,
  setEditingChannel: (channelId: string | undefined) => void,
  prgServIdToChannelId: Record<number, number>,
  propertyNegated: boolean
) => {
  const columns: ColumnWithHeaderTooltip<TableRow>[] = [
    {
      Header: "Id",
      accessor: (row) => row.id,
    },
    {
      id: "status",
      Header: "Status",
      accessor: (row) => row.data.active,
      Cell: (cell: CellProps<TableRow>) => (
        <OptionCell key={cell.value} options={STATUS} value={cell.value} />
      ),
    },
    {
      Header: "Logo",
      accessor: (row) => (
        <ChannelLogo logos={row.data.logos} name={row.data.name} />
      ),
    },
    {
      id: "name",
      Header: "Name",
      accessor: (row) => row.data.name,
      filter: "text",
    },
    {
      Header: "GN Channel & LV",
      headerTooltip: "GN Channel & Linear View",
      accessor: (row) => {
        const prgServId = row["additionalInfo.prg_serv_id"];
        const channelId = prgServId
          ? prgServIdToChannelId[prgServId]
          : undefined;
        return (
          <>
            <div>
              <GNChannel prgServId={prgServId} />
            </div>
            <div>
              <LinearLink channelId={channelId} />
            </div>
          </>
        );
      },
    },
    {
      Header: "Play EPG",
      accessor: (row) => {
        const prodHref = `https://teliaplay.no/kanaler/${row.id}/epg/0`;
        const qaHref = `https://qa.teliaplay.no/kanaler/${row.id}/epg/0`;
        return (
          <>
            <a href={prodHref} style={{ textDecoration: "underline" }}>
              Prod
            </a>{" "}
            <a href={qaHref} style={{ textDecoration: "underline" }}>
              QA
            </a>
          </>
        );
      },
    },
    {
      id: "properties",
      Header: "Properties",
      accessor: (row) => {
        const badges: string[] = [];
        if (
          row.data.restrictions?.catchupArchivePeriod !== undefined ||
          row.data.restrictions?.catchupRestrictedToStb !== undefined
        ) {
          badges.push("catchup");
        }
        if (row.data.httpStreamOrigin !== undefined) {
          badges.push("httpStream");
        }
        if (row.data.restrictions?.allowNpvr) {
          badges.push("recordable");
        }
        if (row.data.externalCatchup) {
          badges.push("externalCatchup");
        }
        return badges;
      },
      Cell: (cell: CellProps<TableRow>) =>
        cell.value.map((val: string) => (
          <OptionCell key={val} options={PROPERTIES} value={val} />
        )),
      filter: propertyNegated ? "notEqualsString" : "equalsString",
    },
    {
      Header: "SLA (Historic)",
      accessor: (row) => row["additionalInfo.sla"],
    },
    {
      Header: "GN Class",
      accessor: (row) => row["additionalInfo.gn_class"],
    },
    {
      Header: "Content Warnings",
      accessor: (row) => (
        <ContentWarningsLink channel={row.id} counts={row.counts} />
      ),
    },
    {
      Header: "DL",
      headerTooltip: "Description Language",
      accessor: (row) => {
        const languageCode = row["additionalInfo.description_language"];
        if (languageCode) {
          return <DescriptionLanguage languageCode={languageCode} />;
        }
        return null;
      },
    },
    {
      Header: "Type",
      accessor: (row) => {
        const type = row["additionalInfo.type"];
        if (type) {
          return type;
        }
        return null;
      },
    },
    {
      Header: "Note on catchup",
      accessor: (row) => {
        const noteOnCatchup = row["additionalInfo.note_on_catchup"];
        if (noteOnCatchup) {
          return <div style={{ whiteSpace: "pre-line" }}>{noteOnCatchup}</div>;
        }
        return null;
      },
    },
    {
      Header: "Comment",
      accessor: (row) => {
        const comment = row["additionalInfo.comment"];
        if (comment) {
          return <div style={{ whiteSpace: "pre-line" }}>{comment}</div>;
        }
        return null;
      },
    },
  ];

  if (showDebugColumns) {
    columns.push(
      {
        Header: (
          <span data-tip="Calculated for 2 weeks, starting today">
            PR Coverage
          </span>
        ),
        id: "pr-coverage",
        accessor: (row) => {
          if (row.prCoverageLoading) {
            return "Loading...";
          }
          if (row.parentalRatingCoverage === undefined) {
            return "";
          }
          return (row.parentalRatingCoverage * 100).toFixed(1) + " %";
        },
      },
      {
        Header: (
          <span data-tip="Calculated for 2 weeks, starting today">
            Catchup Coverage
          </span>
        ),
        id: "catchup-coverage",
        accessor: (row) => {
          if (row.catchupCoverageLoading) {
            return "Loading...";
          }
          if (
            row.catchupTrue === undefined ||
            row.catchupFalse === undefined ||
            row.catchupNull === undefined
          ) {
            return null;
          }
          const truePercent = (row.catchupTrue * 100).toFixed(1) + " %";
          const falsePercent = (row.catchupFalse * 100).toFixed(1) + " %";
          const nullPercent = (row.catchupNull * 100).toFixed(1) + " %";
          return (
            <>
              <div>True: {truePercent}</div>
              <div>False: {falsePercent}</div>
              <div>Null: {nullPercent}</div>
            </>
          );
        },
      }
    );
  }

  columns.push({
    Header: "Manage",
    accessor: (row) => {
      return (
        <ManageCell channelId={row.id} setEditingChannel={setEditingChannel} />
      );
    },
  });

  return columns;
};
