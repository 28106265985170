import _ from "lodash";
import { ProgramMediaData } from "./types";

export const PRIORITIZED_LOCALES = ["no", "en", "da", "sv"];

export function getTitle(mediaData: ProgramMediaData) {
  const { titles, originalTitle } = mediaData;

  // Attempt to return a title for any of the prioritized languages first
  for (const locale of PRIORITIZED_LOCALES) {
    const title = titles[locale];
    if (title) {
      return title;
    }
  }

  // No prioritized languages available - get whatever title we can find
  return _.values(titles)[0] || originalTitle || mediaData.index;
}
