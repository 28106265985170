import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom-v5-compat";
import StatusBoundary from "../StatusBoundary";
import { useAsset } from "../hooks";
import AssetRevisionsTab from "./AssetRevisionsTab";
import AssetSummaryTab from "./AssetSummaryTab";
import CompareRevisionsTab from "./CompareRevisionsTab";
import DataQualityWarningsTab from "./DataQualityWarningsTab";
import IssueReportsTab from "./IssueReportsTab";
import LogsTab from "./LogsTab";
import ProviderImagesTab from "./ProviderImagesTab";
import { pickDataModel } from "./toNode";

function getValidTab(tab) {
  switch (tab) {
    case "revision":
    case "compare-yaafx":
    case "compare-releases":
    case "data-quality-warnings":
    case "issue-reports":
    case "logs":
    case "provider-images":
      return tab;
    default:
      return "revision";
  }
}

function AssetView() {
  const { id, tab, first, second, third, fourth } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const getLink = (targetTab, first, second, third, fourth) => {
    let path = `/ams/assets/${id}/${targetTab}`;
    if (first) {
      path += `/${first}`;
    }
    if (second) {
      path += `/${second}`;
    }
    if (third) {
      path += `/${third}`;
    }
    if (fourth) {
      path += `/${fourth}`;
    }
    return path + search;
  };

  const getLinkTo = (first, second, third, fourth) => {
    return getLink(tab, first, second, third, fourth);
  };
  const compareTab = "compare-yaafx";
  const navigateToCompareLink = (first, second, third, fourth) => {
    navigate(getLink(compareTab, first, second, third, fourth));
  };
  const gotoTab = (tab) =>
    tab !== activeTab && navigate(`/ams/assets/${id}/${tab}`);
  const navigateTo = ({ first, second, third, fourth }) =>
    navigate(getLinkTo(first, second, third, fourth), { replace: true });
  const activeTab = getValidTab(tab);

  const { asset, error, isLoading } = useAsset(id);

  const singleRevision = React.useMemo(
    () => (asset.revisions.length === 1 ? asset.revisions[0] : null),
    [asset.revisions]
  );

  const singleReleaseId = React.useMemo(
    () =>
      asset.revisions.length === 1 &&
      asset.revisions[0].uhe_releases.length === 1
        ? asset.revisions[0].uhe_releases[0].id
        : null,
    [asset.revisions]
  );

  React.useEffect(() => {
    if (
      singleRevision &&
      (activeTab === "revision" || activeTab === "provider-images")
    )
      navigateTo({ first: singleRevision.id });
    else if (singleRevision && activeTab === "compare-releases") {
      navigateTo({
        first: singleRevision.id,
        second: singleRevision.id,
        third: singleReleaseId,
        fourth: singleReleaseId,
      });
    } else if (singleRevision && activeTab === "compare-yaafx") {
      navigateTo({
        first: singleRevision.id,
        second: singleRevision.id,
        third: third ? third : pickDataModel([singleRevision]),
        fourth: fourth ? fourth : pickDataModel([singleRevision]),
      });
    }
  }, [activeTab, singleRevision, third, fourth]);

  return (
    <>
      <StatusBoundary isLoading={isLoading} error={error}>
        <AssetSummaryTab {...asset} />
        <Tabs activeKey={activeTab} onSelect={gotoTab} unmountOnExit>
          <Tab eventKey="revision" title="Revision Details & History">
            <AssetRevisionsTab
              getLinkTo={getLinkTo}
              navigateToCompareLink={navigateToCompareLink}
              revisions={asset.revisions}
              first={Number(first || 0)}
            />
          </Tab>
          <Tab eventKey="compare-yaafx" title="Compare YAAFx">
            <CompareRevisionsTab
              getLinkTo={getLinkTo}
              navigateToCompareLink={navigateToCompareLink}
              navigateTo={navigateTo}
              revisions={asset.revisions}
              first={Number(first || 0)}
              second={Number(second || 0)}
              third={third}
              fourth={fourth}
            />
          </Tab>
          <Tab eventKey="data-quality-warnings" title="Data Quality Warnings">
            <DataQualityWarningsTab
              assetId={asset.id}
              dataQualityWarnings={asset.data_quality_warnings}
            />
          </Tab>
          <Tab eventKey="issue-reports" title="Issue Reports">
            <IssueReportsTab reports={asset.issue_reports} />
          </Tab>
          <Tab eventKey="logs" title="Logs">
            <LogsTab id={id} />
          </Tab>
          <Tab eventKey="provider-images" title="Provider Images">
            <ProviderImagesTab
              first={Number(first || 0)}
              getLinkTo={getLinkTo}
              revisions={asset.revisions}
              navigateToCompareLink={navigateToCompareLink}
            />
          </Tab>
        </Tabs>
      </StatusBoundary>
    </>
  );
}

export default AssetView;
