import React from "react";
import { request } from "client/utils";
import { parseResponseContent } from "client/utils/request";
import { Asset, AssetRevision, AssetRevisionLocator } from "../types";

interface ActionResult {
  status?: number;
  detail?: string;
  message?: string;
  error?: unknown;
  skipped?: boolean;
}

interface ActionOptions {
  asset: Asset;
  revision: AssetRevision;
}

interface AssetActionOptions {
  asset: Asset;
}

interface RevisionActionOptions {
  revision: AssetRevision;
}

export abstract class RequestAction {
  public actionName: string;
  protected defaultRequestOptions: RequestInit;

  constructor(
    actionName: string,
    requestOptions: RequestInit = { method: "POST" }
  ) {
    this.actionName = actionName;
    this.defaultRequestOptions = requestOptions;
  }

  public abstract message(): React.ReactNode;

  protected abstract url(options: Partial<ActionOptions>): string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected body(options: Partial<ActionOptions>): string | undefined {
    return undefined;
  }

  protected getRequestOptions(
    options: Partial<ActionOptions> = {}
  ): RequestInit {
    return {
      ...this.defaultRequestOptions,
      body: this.body(options),
    };
  }

  public async run(options: Partial<ActionOptions>): Promise<ActionResult> {
    const url = this.url(options);
    const requestOptions = this.getRequestOptions(options);

    try {
      const response = await request(url, requestOptions, false);
      const json = await parseResponseContent(
        response.headers,
        await response.text()
      );
      const detail = json?.detail;
      const message = json?.message;

      return {
        status: response.status,
        detail,
        message,
      };
    } catch (error) {
      return {
        error,
      };
    }
  }
}

export class RevisionAction extends RequestAction {
  public message() {
    return (
      <>
        This will trigger <i>{this.actionName}</i> on the latest revision of the
        following assets:
      </>
    );
  }

  protected url({ revision }: RevisionActionOptions) {
    return `ams/revisions/${revision.id}/${this.actionName}/`;
  }
}

export class TaskAction extends RequestAction {
  private locator: AssetRevisionLocator;

  constructor(
    actionName: string,
    locator: AssetRevisionLocator,
    requestOptions?: RequestInit
  ) {
    super(actionName, requestOptions);
    this.locator = locator;
  }

  public message() {
    return (
      <>
        This will trigger <i>{this.actionName}</i> with locator
        <i>{this.locator}</i> on the latest revision of the following assets:
      </>
    );
  }

  protected url() {
    return `ams/tasks/${this.actionName}/`;
  }

  protected body({ revision }: RevisionActionOptions) {
    const locator = revision[this.locator];
    return JSON.stringify({ locator });
  }

  private shouldSkip(revision: AssetRevision) {
    return !revision[this.locator];
  }

  public async run({ revision }: RevisionActionOptions): Promise<ActionResult> {
    if (this.shouldSkip(revision)) {
      return { skipped: true };
    }

    return super.run({ revision });
  }
}

export class AssetAction extends RequestAction {
  public message() {
    return (
      <>
        This will trigger <i>{this.actionName}</i> on the following assets:
      </>
    );
  }

  protected url({ asset }: AssetActionOptions) {
    return `ams/assets/${asset.id}/${this.actionName}`;
  }
}
