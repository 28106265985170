import React from "react";
import Select from "react-select";
import { SelectOption } from "./types";

function SingleSelect<T>({
  value,
  options,
  isLoading,
  placeholder = "Please select",
  onChange,
  compareValue = (option) => option.value === value,
  ...rest
}: {
  value?: T;
  options: SelectOption<T>[];
  isLoading?: boolean;
  placeholder?: string;
  onChange: (newValue: T) => void;
  compareValue?: (option: SelectOption<T>) => boolean;
}) {
  return (
    <Select
      value={options.find(compareValue) || null}
      isLoading={isLoading}
      options={options}
      placeholder={placeholder}
      onChange={(selected) => {
        selected && onChange(selected.value);
      }}
      {...rest}
    />
  );
}

export default SingleSelect;
