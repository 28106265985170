import { InteractionStatus } from "@azure/msal-browser";
import React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useUser, useMsal } from "client/hooks";
import Login from "client/views/Login";
import UnassignedUser from "client/views/UnassignedUser";
import UnauthorizedUser from "client/views/UnauthorizedUser";

/**
 * Render child components only if the user has permission to do so.
 *
 * If the user is authenticated and has permission to access the current
 * location, render the child components.  Otherwise, display a message
 * indicating why the component could not be accessed.
 */
const Authenticated = ({ children }) => {
  const location = useLocation();
  const { token, inProgress } = useMsal();
  const user = useUser();
  const isUserTokenOutdated = user.token !== token;
  const isInProgress = inProgress !== InteractionStatus.None;

  if (user.hasAccessTo(location.pathname)) {
    return children;
  } else if (isUserTokenOutdated || isInProgress) {
    return <Container>Loading...</Container>;
  } else if (!user.isLoggedIn) {
    return <Login />;
  } else if (!user.role || user.role === "unassigned") {
    return <UnassignedUser />;
  } else {
    return <UnauthorizedUser />;
  }
};

export default Authenticated;
