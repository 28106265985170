import { Dict } from "@telia-company/tv.no-play-cms-common/types";
import _ from "lodash";
import moment from "moment/moment";
import { useQueryParams } from "use-query-params";
import { QueryParamConfigMapWithInherit } from "use-query-params/src/types";
import { PRIORITIZED_LOCALES } from "client/views/Epg/utils";

export const getTitle = (titles?: Dict<string>) => {
  if (!titles || _.values(titles).length === 0) {
    return undefined;
  }
  // Attempt to return a title for any of the prioritized languages first
  for (const locale of PRIORITIZED_LOCALES) {
    const title = titles[locale];
    if (title) {
      return title;
    }
  }

  // No prioritized languages available - get whatever title we can find
  return _.values(titles)[0];
};

export const prettyPrintDateTime = (date: string) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const useSearchParams = <QPCMap extends QueryParamConfigMapWithInherit>(
  paramConfigMap: QPCMap
): URLSearchParams => {
  const [params] = useQueryParams(paramConfigMap);
  const searchParams = new URLSearchParams();
  for (const key in paramConfigMap) {
    if (params[key]) {
      searchParams.append(key, params[key] as string);
    }
  }
  return searchParams;
};
