import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

export const TitleFilter = () => {
  const [title, setTitle] = useQueryParam("title", StringParam);
  return (
    <>
      <Form.Label>Title</Form.Label>
      <Form.Control
        type="text"
        value={title || ""}
        onChange={(e) => setTitle(e.target.value || null)}
      />
    </>
  );
};
