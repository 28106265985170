import autobind from "autobind-decorator";
import _ from "lodash";
import React from "react";
import {
  ButtonToolbar,
  Col,
  Form,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "client/App";
import { CmsPure, request } from "client/utils";
import DVBCableAESPlansComposer from "client/views/Provisioning/Composers/DVBCableAESPlansComposer";
import DVBIPTVAESPlansComposer from "client/views/Provisioning/Composers/DVBIPTVAESPlansComposer";
import JsonLoader from "components/JsonLoader";
import { GlyphButton } from "components/buttons";
import {
  ConfigDeploymentComposer,
  ConfigDraftComposer,
  ConfigPublicationComposer,
  DVBCablePlansComposer,
  DVBCableStreamComposer,
  DVBChannelComposer,
  DVBIPTVFCCComposer,
  DVBIPTVPlansComposer,
  DVBIPTVStreamComposer,
  LineupComposer,
  TVProviderComposer,
  TVProviderPlacementComposer,
  VODProviderComposer,
} from "./Composers";
import ProvisionerTable from "./ProvisionerTable";
import RawProvisionerJSON from "./RawProvisionerJSON";

const DEFAULT_COLUMN_KEYS = ["id", "name", "updated"];

class ProvisionerComponent extends CmsPure {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      isFetchingTable: true,
      channelNumber1: undefined,
      channelNumber2: undefined,
      placementColumnGroups: [],
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const endpointUrl = this.getEndpointUrl();
    if (endpointUrl === this.endpointUrl) return;
    this.endpointUrl = endpointUrl;
    this.fetchTableData();
  }

  getProvisionerTableProps() {
    const url = this.getClientUrl();

    switch (url) {
      case "/provisioning/lineups":
        return {
          columnKeys: ["name", "dvbBouquetId", "updated"],
          title: "Regional Lineups",
        };
      // content-providers
      case "/provisioning/content-providers/tv":
        return {
          columnKeys: [
            "status",
            "videoMetadataId",
            "logo",
            "name",
            "properties",
            "updated",
          ],
          title: "TV Providers",
          canDelete: false,
          defaultFiltered: [{ id: "status", value: "true" }],
        };
      case "/provisioning/content-providers/tv/placements": {
        const { placementColumnGroups } = this.state;
        const columnKeys = [
          "lineupName",
          "channelNumber",
          "tvProviderName",
          "serviceType",
        ];

        if (placementColumnGroups.includes("triplets")) {
          columnKeys.push("originalNetworkId");
          columnKeys.push("transportStreamId");
          columnKeys.push("serviceId");
        }

        if (placementColumnGroups.includes("iptv")) {
          columnKeys.push("protocol");
          columnKeys.push("ipAddress");
          columnKeys.push("port");
        }

        if (placementColumnGroups.includes("iptv-aes")) {
          columnKeys.push("protocolAes");
          columnKeys.push("ipAddressAes");
          columnKeys.push("portAes");
        }

        if (placementColumnGroups.includes("cable")) {
          columnKeys.push("modulation");
          columnKeys.push("symbolRate");
          columnKeys.push("frequencyInKHz");
        }

        if (placementColumnGroups.includes("cable-aes")) {
          columnKeys.push("modulationAes");
          columnKeys.push("symbolRateAes");
          columnKeys.push("frequencyInKHzAes");
        }

        columnKeys.push("updated");

        return {
          columnKeys,
          title: "TV Provider Placements",
        };
      }
      case "/provisioning/content-providers/vod":
        return {
          columnKeys: [
            "vodStatus",
            "sortOrder",
            "videoMetadataId",
            "logo",
            "name",
            "properties",
            "updated",
          ],
          title: "VOD Providers",
          canDelete: false,
          defaultFiltered: [{ id: "status", value: "active" }],
        };
      // dvb-channel
      case "/provisioning/dvb/triplets":
        return {
          title: "DVB Triplets",
          columnKeys: [
            "serviceType",
            "originalNetworkId",
            "transportStreamId",
            "serviceId",
            "updated",
          ],
        };
      // dvb-cable
      case "/provisioning/dvb/cable/streams":
        return {
          title: "DVB-Cable Streams",
          columnKeys: ["modulation", "symbolRate", "frequencyInKHz", "updated"],
        };
      case "/provisioning/dvb/cable/tuning-plans":
        return {
          title: "DVB-Cable Plans",
          columnKeys: [
            // from dvb triplets
            "serviceType",
            "originalNetworkId",
            "transportStreamId",
            "serviceId",
            // from cable streams
            "modulation",
            "symbolRate",
            "frequencyInKHz",
            "caDescriptorPrivateData",
            "updated",
          ],
        };
      case "/provisioning/dvb/cable/tuning-plans-aes":
        return {
          title: "DVB-Cable AES Plans",
          columnKeys: [
            // from dvb triplets
            "serviceType",
            "originalNetworkId",
            "transportStreamId",
            "serviceId",
            // from cable streams
            "modulation",
            "symbolRate",
            "frequencyInKHz",
            "caDescriptorPrivateData",
            "updated",
          ],
        };
      // dvb-iptv streams
      case "/provisioning/dvb/iptv/streams":
        return {
          title: "DVB-IPTV Streams",
          columnKeys: ["protocol", "ipAddress", "port", "updated"],
        };
      // dvb-iptv FCC
      case "/provisioning/dvb/iptv/fcc":
        return {
          title: "DVB-IPTV FCC Servers",
          columnKeys: ["protocol", "ipAddress", "port", "updated"],
        };
      case "/provisioning/dvb/iptv/tuning-plans":
        return {
          title: "DVB-IPTV Plans",
          columnKeys: [
            // from dvb-triplets
            "serviceType",
            "originalNetworkId",
            "transportStreamId",
            "serviceId",
            // from iptv-streams
            "protocol",
            "ipAddress",
            "port",
            "fccProtocol",
            "fccIpAddress",
            "fccPort",

            // from tuning plan
            "ssrcId",
            "caDescriptorPrivateData",

            "updated",
          ],
        };
      case "/provisioning/dvb/iptv/tuning-plans-aes":
        return {
          title: "DVB-IPTV AES Plans",
          columnKeys: [
            // from dvb-triplets
            "serviceType",
            "originalNetworkId",
            "transportStreamId",
            "serviceId",
            // from iptv-aes-streams
            "protocol",
            "ipAddress",
            "port",
            "fccProtocol",
            "fccIpAddress",
            "fccPort",

            // from tuning plan
            "ssrcId",
            "caDescriptorPrivateData",

            "updated",
          ],
        };

      // configs
      case "/provisioning/configs/deployments":
        return {
          title: "Deployed Configs",
          columnKeys: ["id", "configDraftId", "created"],
          canEdit: false, // configs are immutable; override canEdit to hide the edit & delete buttons
        };
      case "/provisioning/configs/drafts":
        return {
          title: "Config Drafts",
          columnKeys: ["id", "created", "deployed"],
          canEdit: false,
        };
      case "/provisioning/configs/publications":
        return {
          title: "Published Configs",
          columnKeys: ["id", "lineupName", "configDraftId", "created"],
          canEdit: false,
        };
      default:
        return {
          columnKeys: DEFAULT_COLUMN_KEYS,
          title: url,
        };
    }
  }

  fetchTableData = () => {
    const endpointUrl = this.endpointUrl;
    this.setState({ isFetchingTable: true });
    request(endpointUrl)
      .then((response) => {
        // ignore response if endpoint has changed
        if (endpointUrl !== this.endpointUrl) return;
        this.setState({
          tableData: response.data.items,
          isFetchingTable: false,
        });
        console.log(response.data.items);
      })
      .catch((err) => {
        console.error("Failed to connect to provisioner", err);
        toast.error("Failed to connect to provisioner: " + err.message);
        this.setState({ isFetchingTable: false, tableData: [] });
      });
  };

  async changeChannelNumber(chFrom, chTo) {
    const tableData = [...this.state.tableData];
    // check so that all destination slots are free
    for (let placementData of tableData) {
      if (placementData.channelNumber == chFrom) {
        const collission = tableData.find(
          (pd) =>
            pd.channelNumber == chTo && pd.lineupId == placementData.lineupId
        );
        if (collission) {
          console.log(placementData);
          console.log(collission);
          toast.error("Colliding destination, see console log");
          return;
        }
      }
    }
    // do the actual move
    for (let placementData of tableData) {
      if (placementData.channelNumber == chFrom) {
        placementData.channelNumber = chTo;
      }
    }
    const updatedTableData = tableData.filter(
      (placementData) =>
        placementData.channelNumber == chFrom ||
        placementData.channelNumber == chTo
    );
    console.log("updatedTableData", updatedTableData);
    if (
      !confirm(
        `This will modify ${updatedTableData.length} placement(s). Continue?`
      )
    ) {
      return;
    }

    const endpoint = this.getEndpointUrl();
    this.setState({ isFetchingTable: true });

    const failed = [];
    for (let data of updatedTableData) {
      const payload = { channelNumber: data.channelNumber };
      try {
        await request(endpoint + "/" + data.id + "?patch=1", {
          method: "PUT",
          body: JSON.stringify(payload),
        });
      } catch (err) {
        failed.push(data);
        console.error("Failed to update channel placement", data, err);
      }
    }

    if (failed.length > 0) {
      const succeeded = updatedTableData.length - failed.length;
      console.log(failed);
      toast.error(
        `${failed.length} items failed to update (${succeeded} succeeded), you should retry.`
      );
    }

    this.fetchTableData();
  }

  @autobind
  onSwapChannelNumbers() {
    const { channelNumber1, channelNumber2 } = this.state;
    this.changeChannelNumber(Number(channelNumber1), Number(channelNumber2));
  }

  @autobind
  updatePlacementColumnGroups(placementColumnGroups) {
    this.setState({ placementColumnGroups });
  }

  // only configs use posts
  post = async (payload, label, alreadyConfirmed, doNotRefresh) => {
    if (
      !alreadyConfirmed &&
      !confirm(`This will create a new ${label}. Are you sure?`)
    )
      return;

    return request(this.endpointUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        toast.info("Success! " + label + ": " + res.id);
        this.hideModal();
        if (!doNotRefresh) this.fetchTableData();
      })
      .catch((err) => {
        console.error(err);
        toast.error(`Failed to create ${label}: ${err.message}`);
      });
  };

  // all other models use put and need a generated uuid
  put = async (
    payload,
    id,
    label,
    {
      hideConfirm,
      confirmPrompt = undefined,
      doNotRefresh = false,
      hideSuccessFeedback = false,
    } = {}
  ) => {
    const modeStr =
      this.props.match.params.mode === "create" ? "create a new" : "update the";
    if (!hideConfirm) {
      const prompt =
        confirmPrompt || `This will ${modeStr} ${label}. Are you sure?`;
      if (!confirm(prompt)) {
        return;
      }
    }

    const endpoint = this.getEndpointUrl();
    const url = `${endpoint}/${id}`;

    console.log("Provisioner payload sent to " + url, payload);

    return request(url, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then(() => {
        if (!hideSuccessFeedback) toast.info("Success! " + label + ": " + id);
        this.hideModal();
        !doNotRefresh && this.fetchTableData();
        return true;
      })
      .catch((err) => {
        console.error(err);
        const message =
          err?.error?.userMessage || err?.message || "Unknown error";
        toast.error(`Failed to ${modeStr} ${label}: ${message}`);
        return false;
      });
  };

  get = (idOrParams, callback, errorCallback = console.error) => {
    // children are mounted before parents, so this.endpointUrl isn't initalised yet
    // TODO: extract state from composer components, sigh
    const params =
      typeof idOrParams === "string" ? { id: idOrParams } : idOrParams;

    const { id, path, ...rest } = params;
    let url;

    if (path) {
      url = `provisioning/${path}`;
    } else {
      url = `${this.getEndpointUrl()}/${id}`;
    }

    return request(url, { ...rest })
      .then((res) => {
        if (res.data) {
          callback(res.data);
        } else {
          callback(res);
        }
      })
      .catch(errorCallback);
  };

  delete = ({ id }) => {
    const endpoint = this.endpointUrl;
    request(endpoint + "/" + id, { method: "DELETE" })
      .then(() => {
        toast.info("Deleted " + id + ": " + id);
        this.fetchTableData();
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to delete " + id + ": " + err.message);
      });
  };

  getEndpointUrl = () => {
    const { mode } = this.props.match.params;
    let chunks = this.props.match.url.split("/").filter((val) => val !== "");

    if (chunks.includes(mode)) {
      chunks = _.takeWhile(chunks, (chunk) => chunk != mode);
    }

    return chunks.join("/");
  };

  getClientUrl = () => "/" + this.getEndpointUrl();

  getComposerForm = (clientUrl, params) => {
    let ComposerForm;
    const props = {
      url: clientUrl,
      put: this.put,
      post: this.post,
      get: this.get,
      mode: params.mode,
      id: params.mode === "edit" ? params.id : uuidv4(),
    };
    switch (clientUrl) {
      case "/provisioning/dvb/iptv/tuning-plans-aes":
        ComposerForm = DVBIPTVAESPlansComposer;
        break;
      case "/provisioning/dvb/iptv/tuning-plans":
        ComposerForm = DVBIPTVPlansComposer;
        break;
      case "/provisioning/dvb/iptv/streams":
        ComposerForm = DVBIPTVStreamComposer;
        break;
      case "/provisioning/dvb/iptv/fcc":
        ComposerForm = DVBIPTVFCCComposer;
        break;
      case "/provisioning/dvb/cable/tuning-plans-aes":
        ComposerForm = DVBCableAESPlansComposer;
        break;
      case "/provisioning/dvb/cable/tuning-plans":
        ComposerForm = DVBCablePlansComposer;
        break;
      case "/provisioning/dvb/cable/streams":
        ComposerForm = DVBCableStreamComposer;
        break;
      case "/provisioning/content-providers/tv":
        ComposerForm = TVProviderComposer;
        break;
      case "/provisioning/content-providers/tv/placements":
        props.isFetchingTable = this.state.isFetchingTable;
        props.tableData = this.state.tableData;
        ComposerForm = TVProviderPlacementComposer;
        break;
      case "/provisioning/content-providers/vod":
        ComposerForm = VODProviderComposer;
        break;
      case "/provisioning/lineups":
        ComposerForm = LineupComposer;
        break;
      case "/provisioning/configs/deployments":
        ComposerForm = ConfigDeploymentComposer;
        break;
      case "/provisioning/configs/drafts":
        ComposerForm = ConfigDraftComposer;
        break;
      case "/provisioning/configs/publications":
        ComposerForm = ConfigPublicationComposer;
        break;
      case "/provisioning/dvb/triplets":
      default:
        ComposerForm = DVBChannelComposer;
    }
    return <ComposerForm {...props} />;
  };

  hideModal = () => {
    this.props.history.push(this.getClientUrl());
  };

  onEditItem = (item) =>
    this.props.history.push(this.getClientUrl() + "/edit/" + item.id);

  modalPopup = (popupPath) => this.setState({ popupPath });

  render() {
    const params = this.props.match.params;
    const clientUrl = this.getClientUrl();
    return (
      <div className="container-fluid">
        <Modal
          autoFocus
          centered
          show={!!this.state.popupPath}
          onHide={() => this.setState({ popupPath: undefined })}
          size="lg"
        >
          <JsonLoader fetchData={this.get} path={this.state.popupPath} />
        </Modal>
        <Modal
          autoFocus
          centered
          show={!!params.mode}
          onHide={this.hideModal}
          size="xl"
        >
          {!params.mode ? null : params.mode === "raw" ? (
            <RawProvisionerJSON fetchData={this.get} id={params.id} />
          ) : (
            this.getComposerForm(clientUrl, params)
          )}
        </Modal>
        <UserContext.Consumer>
          {({ hasPermissionTo }) => {
            const canModify = hasPermissionTo("edit", "provision");
            return (
              <>
                {clientUrl ===
                  "/provisioning/content-providers/tv/placements" && (
                  <Row as={Form.Group} className="my-2">
                    <Col xs={12} as={Form.Label}>
                      Move channel placement
                    </Col>
                    <Col xs={2}>
                      <Form.Control
                        type="number"
                        value={this.state.channelNumber1}
                        placeholder="From channel number"
                        onChange={(e) =>
                          this.setState({
                            channelNumber1: Number(e.target.value),
                          })
                        }
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Control
                        type="number"
                        value={this.state.channelNumber2}
                        placeholder="To channel number"
                        onChange={(e) =>
                          this.setState({
                            channelNumber2: Number(e.target.value),
                          })
                        }
                      />
                    </Col>
                    <Col xs={2}>
                      <GlyphButton
                        className="ms-2"
                        glyph="refresh"
                        variant="primary"
                        onClick={this.onSwapChannelNumbers}
                        disabled={
                          this.state.channelNumber1 === undefined ||
                          this.state.channelNumber2 === undefined
                        }
                      >
                        MOVE AND SAVE
                      </GlyphButton>
                    </Col>
                    <Col
                      xs={6}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <ButtonToolbar>
                        <ToggleButtonGroup
                          type="checkbox"
                          value={this.state.placementColumnGroups}
                          onChange={this.updatePlacementColumnGroups}
                        >
                          <ToggleButton id="triplets" value="triplets">
                            DVB triplets
                          </ToggleButton>
                          <ToggleButton id="iptv" value="iptv">
                            IPTV plans
                          </ToggleButton>
                          <ToggleButton id="iptv-aes" value="iptv-aes">
                            IPTV AES plans
                          </ToggleButton>
                          <ToggleButton id="cable" value="cable">
                            Cable plans
                          </ToggleButton>
                          <ToggleButton id="cable-aes" value="cable-aes">
                            Cable AES plans
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                )}
                <ProvisionerTable
                  data={this.state.tableData}
                  fetching={this.state.isFetchingTable}
                  fetchData={this.fetchTableData}
                  clientUrl={clientUrl}
                  canDelete={canModify}
                  canEdit={canModify}
                  {...this.getProvisionerTableProps()}
                  onDelete={this.delete}
                  onEdit={this.onEditItem}
                  modalPopup={this.modalPopup}
                />
              </>
            );
          }}
        </UserContext.Consumer>
      </div>
    );
  }
}

export default withRouter(ProvisionerComponent);
