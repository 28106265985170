import {
  EventType,
  EventMessageUtils,
  InteractionStatus,
} from "@azure/msal-browser";
import React, { useState, useEffect, useCallback } from "react";
import {
  accountArraysAreEqual,
  accountsAreEqual,
} from "client/utils/activedirectory";

// Silently refresh tokens that are about to expire within this interval
// (time to expiration in seconds)
const TOKEN_REFRESH_THRESHOLD = 600;

// Check if tokens should be refreshed with this interval (ms)
const TOKEN_REFRESH_CHECK_INTERVAL = 60000;

const MsalContext = React.createContext();

/**
 * Provide access to MSAL's authentication state.
 *
 * MsalProvider provides access to the authentication state of MSAL
 * (Microsoft Authentication Library), along with some helper functions
 * for logging in and out.
 *
 * For functional components, the useMsal() hook from client/hooks is
 * the easiest way to access the MsalContext.  Class components that
 * need access to the context can use MsalContext directly.
 *
 * The MsalProvider provides an object with the following attributes:
 *
 *   instance    The MSAL PublicClientApplication instance that performs
 *               the authentication.  MsalProvider handles most of the
 *               direct interaction with the PCA instance, so regular
 *               views and components will probably not need direct
 *               access to this object except in special cases.
 *
 *   token       The authentication token received from Azure AD after
 *               successful authentication.  This token is also stored
 *               in localStorage using the key "auth.token".
 *
 *   accounts    An array of currently authenticated Microsoft accounts.
 *               These accounts are taken directly from MSAL and don't
 *               contain any information about CMS roles or permissions.
 *               To access the current CMS user, use UserProvider.
 *
 *   account     The currently active Microsoft account.
 *
 *   inProgress  The current state of the MSAL authentication flow.
 *               Possible values are defined in the InteractionStatus
 *               object from @azure/msal-browser.
 *
 *   login       A helper function to trigger a login reattempt.
 *               MsalProvider attempts to login when the component is
 *               first loaded, but if the initial login attempt fails,
 *               any retries must be triggered by calling login().  The
 *               login() function accepts an options object as a single
 *               argument.  The only option currently supported is
 *               'redirect'.  If the redirect option is set to false,
 *               the MSAL library will attempt to log in silently
 *               without any user interaction, and will abort silently
 *               if the login attempt fails.  If set to true (the
 *               default), MSAL will first attempt to authenticate
 *               silently, but will fallback to redirect to Microsoft's
 *               login page if silent authentication fails.
 *
 *   logout      Sign out from the current session.
 *
 * MsalProvider is loosely based on the corresponding component in
 * @azure/msal-react.  We are using our own implementation as msal-react
 * is only available as an alpha release at the time of writing, but we
 * should consider using msal-react instead of this component once it is
 * released in a stable version.
 */
export const MsalProvider = ({ instance, children }) => {
  const authTokenExp = localStorage.getItem("auth.token.exp");
  let cachedExpiryTime = authTokenExp ? parseInt(authTokenExp) : undefined;
  let cachedToken = localStorage.getItem("auth.token");

  const now = Math.floor(Date.now() / 1000);
  const cachedTokenExpiresIn = cachedExpiryTime ? cachedExpiryTime - now : 0;

  if (
    !cachedExpiryTime ||
    !cachedToken ||
    cachedTokenExpiresIn <= TOKEN_REFRESH_THRESHOLD
  ) {
    cachedToken = undefined;
    cachedExpiryTime = undefined;
  }

  const disableAutoLogin =
    localStorage.getItem("auth.disableAutoLogin") === "true";

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [token, setToken] = useState(cachedToken);
  const [tokenExpiryTime, setTokenExpiryTime] = useState(cachedExpiryTime);
  const [hasBeenCalled, setHasBeenCalled] = useState(disableAutoLogin);
  const [inProgress, setInProgress] = useState(InteractionStatus.Startup);

  const login = useCallback(
    async ({ redirect = true } = {}) => {
      const username = localStorage.getItem("auth.username");

      await instance.ssoSilent({ loginHint: username }).catch(() => {
        if (redirect) {
          instance.loginRedirect();
        }
      });
    },
    [instance]
  );

  const refreshToken = useCallback(() => login({ redirect: false }), [login]);

  const logout = useCallback(() => {
    if (account) {
      localStorage.setItem("auth.disableAutoLogin", "true");
      localStorage.removeItem("auth.username");
      localStorage.removeItem("auth.token");
      localStorage.removeItem("auth.token.exp");

      setAccounts([]);
      setToken(undefined);
      setTokenExpiryTime(undefined);
      instance.logout({ account });
    }
  }, [instance, account]);

  const expiresIn = useCallback(() => {
    if (tokenExpiryTime) {
      const now = Math.floor(Date.now() / 1000);
      return tokenExpiryTime - now;
    } else {
      return 0;
    }
  }, [tokenExpiryTime]);

  useEffect(() => {
    // Ensure that the token state is always kept in sync with external
    // changes to auth.token in localStorage, which can happen when the
    // user logs in to the CMS in a new tab on the same computer.
    //
    // This is needed since the <Authenticated> component expects the
    // token from MsalContext to equal the token received from the
    // /api/user endpoint, which implicitly depends on the token in
    // localStorage that is used for making the API request.
    const syncTokenState = () => {
      const newToken = localStorage.getItem("auth.token");
      const authTokenExp = localStorage.getItem("auth.token.exp");
      const newTokenExpiryTime = authTokenExp
        ? parseInt(authTokenExp)
        : undefined;

      if (newToken !== token || newTokenExpiryTime !== tokenExpiryTime) {
        setToken(newToken);
        setTokenExpiryTime(newTokenExpiryTime);
      }
    };
    window.addEventListener("storage", syncTokenState);

    return () => window.removeEventListener("storage", syncTokenState);
  }, [token, setToken, tokenExpiryTime, setTokenExpiryTime]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_FAILURE:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE: {
          const currentAccounts = instance.getAllAccounts();
          if (!accountArraysAreEqual(currentAccounts, accounts)) {
            setAccounts(currentAccounts);
          }
          break;
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, accounts]);

  useEffect(() => {
    const currentAccount = accounts[0];
    if (!accountsAreEqual(currentAccount, account)) {
      setAccount(currentAccount);

      if (currentAccount) {
        localStorage.setItem("auth.username", currentAccount.username);
      } else {
        localStorage.removeItem("auth.username");
      }
    }
  }, [accounts, account]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          if (message.payload) {
            const { idToken, idTokenClaims } = message.payload;
            const { exp } = idTokenClaims;

            localStorage.removeItem("auth.disableAutoLogin");
            localStorage.setItem("auth.token", idToken);
            setToken(idToken);

            if (exp) {
              setTokenExpiryTime(exp);
              localStorage.setItem("auth.token.exp", exp);
            }
          }
          break;
        }
        case EventType.LOGIN_FAILURE:
        case EventType.ACQUIRE_TOKEN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
          // Don't remove non-expired tokens in case of authentication
          // failures.  These failures might happen when attempting to
          // refresh a token that's about to expire.  If such a refresh
          // attempt fails it's better to keep the old token around and
          // try again instead of discarding the old token before it's
          // actually expired.
          if (expiresIn() <= 0) {
            localStorage.removeItem("auth.token");
            localStorage.removeItem("auth.token.exp");
            setToken(undefined);
            setTokenExpiryTime(undefined);
          }
          break;
        case EventType.LOGOUT_SUCCESS:
          localStorage.removeItem("auth.token");
          localStorage.removeItem("auth.token.exp");
          setToken(undefined);
          setTokenExpiryTime(undefined);
          break;
      }

      if (message.error) {
        setError(message.error);
      } else {
        setError(undefined);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, token, setToken, setError, expiresIn, setTokenExpiryTime]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      const status = EventMessageUtils.getInteractionStatusFromEvent(message);
      if (status !== null) {
        setInProgress(status);
      }
    });

    instance.handleRedirectPromise().catch(() => {});

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  useEffect(() => {
    if (
      !hasBeenCalled &&
      !error &&
      !token &&
      inProgress === InteractionStatus.None
    ) {
      setHasBeenCalled(true);
      login().catch(() => {});
    }
  }, [hasBeenCalled, error, token, inProgress, login]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tokenExpiryTime && expiresIn() < TOKEN_REFRESH_THRESHOLD) {
        refreshToken();
      }
    }, TOKEN_REFRESH_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [tokenExpiryTime, expiresIn, refreshToken]);

  const contextValue = {
    instance,
    login,
    logout,
    accounts,
    account,
    token,
    inProgress,
  };

  return (
    <MsalContext.Provider value={contextValue}>{children}</MsalContext.Provider>
  );
};

export default MsalContext;
