import {
  ContentType,
  ProductionYearRange,
} from "@telia-company/tv.no-play-cms-common/collection/types";
import React, { ChangeEvent } from "react";
import { Col, Form, Row } from "react-bootstrap";

const getStartValue = (productionYearRange?: ProductionYearRange) => {
  if (!productionYearRange) {
    return "";
  }
  const start = productionYearRange.start;
  if (!start) {
    return "";
  }
  return start;
};

const getEndValue = (productionYearRange?: ProductionYearRange) => {
  if (!productionYearRange) {
    return "";
  }
  const end = productionYearRange.end;
  if (!end) {
    return "";
  }
  return end;
};

interface Props {
  productionYearRange?: ProductionYearRange;
  selectedContentTypes: ContentType[];
  onChange: (productionYearRange?: ProductionYearRange) => void;
  readOnly: boolean;
}

export const ProductionYearRangePicker = ({
  productionYearRange,
  selectedContentTypes,
  onChange,
  readOnly,
}: Props) => {
  if (selectedContentTypes.length === 0) {
    return null;
  }
  if (selectedContentTypes.indexOf("program") !== -1) {
    return null;
  }

  const onStartChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange({
      start: value ? Number(value) : undefined,
      end: productionYearRange ? productionYearRange.end : undefined,
    });
  };
  const onEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange({
      start: productionYearRange ? productionYearRange.start : undefined,
      end: value ? Number(value) : undefined,
    });
  };
  const startValue = getStartValue(productionYearRange);
  const endValue = getEndValue(productionYearRange);
  return (
    <Form.Group className="mb-3">
      <Form.Label>Filter by production year range</Form.Label>
      <Row>
        <Col xs={6}>
          <Form.Control
            type="text"
            value={startValue}
            onChange={onStartChange}
            placeholder="Start"
            disabled={readOnly}
          />
        </Col>
        <Col xs={6}>
          <Form.Control
            type="text"
            value={endValue}
            onChange={onEndChange}
            placeholder="End"
            disabled={readOnly}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};
