import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { ToggleControlWithLabels } from "components/controls/ToggleControl";

const LAYOUT_MODE_OPTIONS = [
  { label: "Auto", value: "auto" },
  { label: "Manual", value: "manual" },
];

interface Props {
  autoOrientation: boolean;
  onChange: (newValue: boolean) => void;
}

export const LayoutModeToggle = ({ autoOrientation, onChange }: Props) => {
  const setLayoutMode = useCallback(
    (mode: "auto" | "manual") => {
      const autoOrientation = mode === "auto";
      onChange(autoOrientation);
    },
    [onChange]
  );

  return (
    <>
      <Form.Label>Layout mode</Form.Label>
      <ToggleControlWithLabels
        value={autoOrientation ? "auto" : "manual"}
        options={LAYOUT_MODE_OPTIONS}
        onChange={setLayoutMode}
      />
    </>
  );
};
