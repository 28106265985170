import { EnrichmentWarningAttributes } from "@telia-company/tv.no-play-cms-common/db/definitions/EnrichmentWarning";
import { Dict } from "@telia-company/tv.no-play-cms-common/types";
import React, { useEffect } from "react";
import { Column } from "react-table-v7";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import useSWR from "swr";
import { NumberParam } from "use-query-params";
import { request } from "client/utils";
import { KindBadge } from "components/badges";
import { ConfirmDeleteButton } from "components/buttons";
import Table from "components/tables";
import { Pagination } from "components/tables/Pagination";
import { getTitle, prettyPrintDateTime, useSearchParams } from "../utils";

type ReportedWarningColumns = ReadonlyArray<Column<ContentWarningStatus>>;
const getColumns = (
  status: "REPORTED" | "ACCEPTED",
  refreshTable: () => void
): ReportedWarningColumns => {
  const deleteStatus = async (id: number) => {
    try {
      await request(`warningstatus/${id}`, { method: "DELETE" });
      refreshTable();
    } catch (e) {
      toast.error("Failed to delete content warning status: " + e);
    }
  };
  return [
    {
      Header: "Title",
      accessor: (row) => getTitle(row.titles),
    },
    {
      Header: "Kind",
      accessor: (row) => <KindBadge kind={row.kind} />,
    },
    {
      Header: "Warning Type",
      accessor: (row) => (
        <div>
          <span data-tip={row.warnings[0]?.data?.description}>
            {row.warning_type}
          </span>
        </div>
      ),
    },
    {
      Header: "Count",
      accessor: (row) => row.warnings.length,
    },
    {
      Header:
        status === "REPORTED" ? "Marked as reported" : "Marked as accepted",
      accessor: (row) => prettyPrintDateTime(row.created),
    },
    {
      Header: "Manage",
      accessor: (row) => (
        <ConfirmDeleteButton
          className="p-1 ms-1"
          onClick={() => deleteStatus(row.id)}
        />
      ),
    },
  ];
};

interface ContentWarningStatus {
  id: number;
  mediacontent_id?: number | null;
  asset_id?: number | null;
  status: string;
  created: string;
  updated: string;
  titles: Dict<string>;
  kind: string;
  warning_type: string;
  warnings: EnrichmentWarningAttributes[];
}

interface WarningStatusTableProps {
  status: "REPORTED" | "ACCEPTED";
  type: "ASSET" | "MEDIACONTENT";
}

export const WarningStatusTable = ({
  status,
  type,
}: WarningStatusTableProps) => {
  const searchParams = useSearchParams({
    page: NumberParam,
    size: NumberParam,
  });
  const { data, mutate } = useSWR(
    `warningstatus/${status}/${type}?${searchParams.toString()}`
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Table columns={getColumns(status, mutate)} data={data.warnings} />
      <Pagination totalResults={data.totalCount} />
      <ReactTooltip />
    </>
  );
};
