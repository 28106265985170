import { dateToString } from "@telia-company/tv.no-play-cms-common/api/Date";
import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { ConfirmDeleteButton, GlyphButton } from "components/buttons";
import { OptionCell } from "./Cells";
import { OptionsFilter, TextFilter } from "./Filters";

export function getStatusColumn(columnDefinition) {
  const options = [
    { value: true, label: "Active", variant: "light" },
    { value: false, label: "Inactive", variant: "secondary" },
  ];
  return {
    id: "status",
    Header: "Status",
    accessor: "status",
    width: 85,
    canResize: false,
    Cell: ({ value }) => <OptionCell options={options} value={value} />,
    Filter: ({ column }) => <OptionsFilter options={options} column={column} />,
    // filters the values as text
    filter: "text",
    ...columnDefinition,
  };
}

export function getDateColumn(columnDefinition) {
  return {
    Cell: ({ value }) => dateToString(value),
    filter: (rows, ids, filterValue) => {
      const lowercaseValue = filterValue.toLocaleLowerCase();
      return rows.filter((row) =>
        ids.some((id) => {
          const rowValue = String(
            dateToString(row.values[id]).toLocaleLowerCase()
          );
          return rowValue.includes(lowercaseValue);
        })
      );
    },
    Filter: TextFilter,
    ...columnDefinition,
  };
}

/**
 * @param {function} onEdit
 * @param {function} onDelete
 */
export function getManageColumn(onEdit, onDelete) {
  return {
    Header: "Manage",
    id: "manage",
    width: 70,
    disableFilters: true,
    canResize: false,
    Cell: ({ row: { original: item } }) => (
      <ButtonGroup className="mx-n1">
        {onEdit && (
          <GlyphButton
            glyph="edit"
            className="p-1"
            variant="link"
            onClick={() => onEdit(item)}
          />
        )}
        {onDelete && (
          <ConfirmDeleteButton
            className="p-1 ms-1"
            onClick={() => onDelete(item)}
          />
        )}
      </ButtonGroup>
    ),
  };
}
