import { DateTime } from "luxon";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import InsightToolbar from "../InsightToolbar";
import { LoadMoreButton } from "./LoadMoreButton";
import { TableHead } from "./TableHead";
import { TableSection } from "./TableSection";
import { TotalRow } from "./TotalRow";
import { MAX_WIDTH } from "./constants";

export const ContentReportStats = () => {
  const [cutoff, setCutoff] = useState(DateTime.now().minus({ days: 29 }));
  const intervals = getIntervals(cutoff);
  const tableSections = intervals.map((interval, index) => (
    <TableSection key={index} from={interval.from} to={interval.to} />
  ));

  return (
    <>
      <InsightToolbar />
      <div className="container-fluid">
        <h1>Content reports</h1>
        <Table
          striped
          hover
          className="mt-4 bg-light condensed"
          style={{ maxWidth: MAX_WIDTH }}
        >
          <TableHead />
          <tbody>
            <TotalRow />
            {tableSections}
          </tbody>
        </Table>
        <LoadMoreButton cutoff={cutoff} setCutoff={setCutoff} />
      </div>
    </>
  );
};

type Interval = { from: string; to: string };

export const getIntervals = (cutoff: DateTime): Interval[] => {
  const intervals: Interval[] = [];
  for (let i = 0; i < 3; i++) {
    const candidateFrom = DateTime.now()
      .minus({ days: 29 + 30 * i })
      .startOf("day");
    const toString = DateTime.now()
      .minus({ days: 30 * i })
      .toFormat("yyyy-MM-dd");
    if (candidateFrom <= cutoff.startOf("day")) {
      intervals.push({
        from: cutoff.toFormat("yyyy-MM-dd"),
        to: toString,
      });
      break;
    }
    intervals.push({
      from: candidateFrom.toFormat("yyyy-MM-dd"),
      to: toString,
    });
  }
  return intervals;
};
