import React from "react";
import { Container } from "react-bootstrap";
import Toolbar from "./Toolbar";

function AMS({ children }) {
  return (
    <>
      <Toolbar />
      <Container fluid className="my-4">
        {children}
      </Container>
    </>
  );
}

export default AMS;
