import React from "react";
import MatchCard from "./MatchCard";
import SeasonCard from "./SeasonCard";
import TeamCard from "./TeamCard";

const SeasonSidebar = ({
  season,
  match,
  team,
  seasonCard,
  matchCard,
  readOnly,
  loading,
  dispatch,
}) => (
  <div className="opta-sidebar">
    <SeasonCard
      season={season}
      seasonCard={seasonCard}
      readOnly={readOnly}
      loading={loading}
      dispatch={dispatch}
    />
    {team.id && (
      <TeamCard
        team={team}
        readOnly={readOnly}
        onClose={() => dispatch({ type: "team.deselected" })}
        onChange={(team) => dispatch({ type: "team.reloaded", payload: team })}
        dispatch={dispatch}
      />
    )}
    {!team.id && match.id && (
      <MatchCard
        match={match}
        season={season}
        matchCard={matchCard}
        readOnly={readOnly}
        dispatch={dispatch}
      />
    )}
  </div>
);

export default SeasonSidebar;
