import React, { useCallback, useContext, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { Prompt } from "react-router-dom";
import useSWR from "swr";
import { StringParam, useQueryParam } from "use-query-params";
import { ChannelsContext } from "client/App";
import { request } from "client/utils";
import { ChannelRuleSelector } from "./ChannelRuleSelector";

const ChannelRulesSettings = () => {
  const channelsAry = useContext(ChannelsContext);
  const { data: channelRules, mutate } = useSWR("channels/rules");
  const [isSaving, setIsSaving] = useState(false);
  const [channelNameFilter, setChannelNameFilter] = useQueryParam(
    "channelNameFilter",
    StringParam
  );
  const onChange = useCallback(
    (channelId, rule) => {
      const nextChannelRules = { ...channelRules };
      let oldChannelRules = nextChannelRules[channelId] || [];
      // flip the value
      const hasRule = oldChannelRules.includes(rule);
      if (hasRule) {
        oldChannelRules = oldChannelRules.filter((r) => r !== rule);
      } else {
        oldChannelRules = [...oldChannelRules, rule];
      }
      nextChannelRules[channelId] = oldChannelRules;
      // Just update the local state, do not trigger revalidation yet
      mutate(nextChannelRules, { revalidate: false });
      setIsSaving(true);
      request("channels/rules", {
        method: "POST",
        body: JSON.stringify(nextChannelRules),
      }).then(() => {
        // trigger revalidation when we know that the new value has been saved
        mutate();
        setIsSaving(false);
      });
    },
    [channelRules]
  );

  const channels = {};

  for (const { id, value } of channelsAry) {
    if (
      !channelNameFilter ||
      value.toLowerCase().includes(channelNameFilter.toLowerCase())
    ) {
      channels[id] = value;
    }
  }
  return (
    <div className="container-fluid">
      <Prompt
        when={isSaving}
        message="Channel rules might not have finished saving. Continuing can result in lost changes."
      />
      <h2 className="mt-2">Warnings</h2>
      <Alert variant="info">
        Note that channel rules are saved automatically
      </Alert>
      {isSaving && "Saving..."}
      {!isSaving && "Saved"}
      <br />
      <Form.Label htmlFor="channel-name-filter" style={{ marginTop: "40px" }}>
        Channel filter
      </Form.Label>
      <Form.Control
        id="channel-name-filter"
        value={channelNameFilter}
        onChange={(e) => setChannelNameFilter(e.target.value || null)}
        style={{ width: "200px", marginBottom: "-120px" }}
      />
      {channelRules && (
        <ChannelRuleSelector
          channels={channels}
          channelRules={channelRules}
          onChange={onChange}
        />
      )}
      {!channelRules && <div>Loading...</div>}
    </div>
  );
};

export default ChannelRulesSettings;
