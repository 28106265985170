import { useContext } from "react";
import { UserContext } from "client/contexts";

/**
 * Provide access to the currently logged in user.
 *
 * See the documentation of UserProvider for details.
 */
const useUser = () => useContext(UserContext);

export { useUser };
