import _ from "lodash";
import React from "react";
import { Card, CardProps } from "react-bootstrap";
import { useAnnotatedMediaContent } from "client/hooks/useAnnotatedMediaContent";
import ImageList from "./ImageList";

interface Props extends CardProps {
  contentId: number;
  onChange: () => void;
}

function ImportCoverImage({ contentId, onChange, ...rest }: Props) {
  const { annotatedMediaContent } = useAnnotatedMediaContent(contentId);

  const images = annotatedMediaContent?.data?.images || [];
  const covers =
    _.groupBy(images, ({ value, currentValue }) => {
      const img = currentValue || value;
      return img.imageType;
    })["cover"] || [];

  return _.isEmpty(covers) ? null : (
    <Card {...rest}>
      <Card.Header>Suggested Promotion Cover(s)</Card.Header>
      <Card.Body>
        <ImageList images={covers} onChange={onChange} />
      </Card.Body>
    </Card>
  );
}

export default ImportCoverImage;
