import { Moment } from "moment";
import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useQueryParam } from "use-query-params";
import { MomentParam } from "client/types/MomentParam";
import DateTimeControl from "components/controls/datetime/DateTimeControl";
import { KindPicker } from "components/pickers/KindPicker";
import InsightToolbar from "../InsightToolbar";
import { ContentReportTable } from "./ContentReportTable";
import { ReportTypePicker } from "./ReportTypePicker";

export const ContentReport = () => {
  return (
    <>
      <InsightToolbar />
      <Container fluid className="my-4">
        <Row className="mb-2">
          <Col xs={3}>
            <ReportTypePicker />
          </Col>
          <Col xs={3}>
            <KindPicker includeSubKinds={true} />
          </Col>
          <Col xs={3}>
            <FromDateTimePicker />
          </Col>
          <Col xs={3}>
            <ToDateTimePicker />
          </Col>
        </Row>
        <ContentReportTable />
      </Container>
    </>
  );
};

const FromDateTimePicker = () => {
  const [from, setFrom] = useQueryParam("from", MomentParam);
  const [to, setTo] = useQueryParam("to", MomentParam);
  const changeFrom = (newFrom: Moment) => {
    setFrom(newFrom);
    if (to && newFrom && newFrom.isAfter(to)) {
      setTo(newFrom);
    }
  };
  return (
    <>
      <Form.Label htmlFor={"content-report-from"}>From</Form.Label>
      <DateTimeControl
        inputId={"content-report-from"}
        onChange={changeFrom}
        value={from}
        acceptEmpty={true}
      />
    </>
  );
};

const ToDateTimePicker = () => {
  const [to, setTo] = useQueryParam("to", MomentParam);
  const [from, setFrom] = useQueryParam("from", MomentParam);
  const changeTo = (newTo: Moment) => {
    setTo(newTo);
    if (from && newTo && newTo.isBefore(from)) {
      setFrom(newTo);
    }
  };
  return (
    <>
      <Form.Label htmlFor={"content-report-to"}>To</Form.Label>
      <DateTimeControl
        inputId={"content-report-to"}
        onChange={changeTo}
        value={to}
        acceptEmpty={true}
      />
    </>
  );
};
