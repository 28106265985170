import { DateTime } from "luxon";
import { useQueryParam } from "use-query-params";
import { DateParam } from "client/types/DateParam";

export const useFrom = () => {
  const { from, setFrom } = useFromParam();
  const { to, setTo } = useToParam();
  const fromDateString = from.toFormat("yyyy-MM-dd");
  const setFromBounded = (newFrom: DateTime) => {
    setFrom(newFrom);
    if (to < newFrom) {
      setTo(newFrom);
    }
    if (to.minus({ days: 30 }) > newFrom) {
      setTo(newFrom.plus({ days: 30 }));
    }
  };
  return { from, setFrom, setFromBounded, fromDateString };
};

export const useTo = () => {
  const { to, setTo } = useToParam();
  const { from, setFrom } = useFromParam();
  const toDateString = to.toFormat("yyyy-MM-dd");
  const setToBounded = (newTo: DateTime) => {
    setTo(newTo);
    if (from > newTo) {
      setFrom(newTo);
    } else if (from.plus({ days: 30 }) < newTo) {
      setFrom(newTo.minus({ days: 30 }));
    }
  };
  return { to, setTo, setToBounded, toDateString };
};

const useFromParam = () => {
  const [from = DateTime.now().minus({ days: 9 }).startOf("day"), setFrom] =
    useQueryParam("from", DateParam);
  return { from, setFrom };
};

const useToParam = () => {
  const [to = DateTime.now().startOf("day"), setTo] = useQueryParam(
    "to",
    DateParam
  );
  return { to, setTo };
};
