import _ from "lodash";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { STATUS, useRequest } from "client/hooks";

const ContentWarningsCard = ({ id, ...rest }) => {
  const [warnings, setWarnings] = useState([]);
  const [status] = useRequest(`enrichment/warnings/${id}`, {
    onSuccess: setWarnings,
  });

  return (
    <Card {...rest}>
      <Card.Header>Content warnings</Card.Header>
      <Card.Body>
        {status === STATUS.OK && !_.isEmpty(warnings) && (
          <div className="mediacontent-editor-warnings">
            {warnings.map((warning) => {
              return (
                <span key={warning.type} data-tip={warning.data.description}>
                  {warning.type}
                  <br />
                </span>
              );
            })}
            <ReactTooltip html={true} />
          </div>
        )}
        {status === STATUS.OK && _.isEmpty(warnings) && "No warnings"}
        {status === STATUS.FETCHING && "Loading..."}
        {status === STATUS.ERROR && "Could not fetch content warnings"}
      </Card.Body>
    </Card>
  );
};

export default ContentWarningsCard;
