import { PropTypes } from "prop-types";
import React from "react";
import { FormCheck } from "react-bootstrap";

const RadioItem = ({ children, onChange, selected, title, value, ...rest }) => {
  const checked = selected === value;
  return (
    <>
      <FormCheck
        className={checked && "checked"}
        type="radio"
        checked={checked}
        onChange={() => onChange(value)}
        label={title}
        id={title}
        {...rest}
      />
      {children && (
        <div className={"radio-item-content" + (checked ? " checked" : "")}>
          {children}
        </div>
      )}
    </>
  );
};

RadioItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default RadioItem;
