import { debounce } from "lodash";
import queryString from "query-string";
import React from "react";
import { Row, Col, Modal, Form, Button, Tabs, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import { mergeReducer } from "client/hooks";
import { request } from "client/utils";
import { CmsCardContainer, PromotionCard } from "components/cards";
import { OrderControl } from "components/controls/ToggleControl";
import QuickCreatePromo from "./QuickCreatePromo";

export function PromotionSearchModal({
  show,
  size = "lg",
  onSelectPromotion,
  allowMultiple,
  selectedIds,
  onAdd,
  onHide,
  promotionType,
  ...rest
}) {
  const [promotions, setPromotions] = React.useState([]);
  const [query, setQuery] = React.useReducer(mergeReducer, {
    title: "",
    promotion_type: promotionType,
    sortBy: "date",
    activeOnly: true,
  });
  const [activeTab, setActiveTab] = React.useState("select");

  const fetchData = React.useCallback(
    (query) =>
      request("promotions/card?" + queryString.stringify(query))
        .then((result) => {
          setPromotions(result);
        })
        .catch((err) => {
          toast.error("Failed to load promotions: " + err.message);
          console.error(err);
        }),
    []
  );

  const fetchDataDebounced = React.useMemo(
    () => debounce(fetchData, 250),
    [fetchData]
  );

  React.useEffect(() => {
    if (!show) return;
    fetchDataDebounced(query);
  }, [query.title, query.sortBy, query.activeOnly, show]);

  const onSubmit = React.useMemo(
    () => (e) => {
      e.preventDefault();
      fetchData(query);
    },
    [query.title, query.sortBy, query.activeOnly]
  );

  return (
    <Modal show={show} size={size} onHide={onHide} {...rest}>
      <Modal.Body>
        <Tabs
          id="promo-tabs"
          activeKey={activeTab}
          onSelect={setActiveTab}
          mountOnEnter
        >
          <Tab eventKey="select" title="Select a promotion">
            <Row as={Form} onSubmit={onSubmit} className="mt-4 mb-3">
              <Col>
                <Form.Group>
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder="Filter promotions by title"
                    value={query.title}
                    onChange={(e) => setQuery({ title: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col xs="auto">
                <Button
                  variant={query.activeOnly ? "info" : "light"}
                  onClick={() => setQuery({ activeOnly: !query.activeOnly })}
                >
                  Active only
                </Button>
              </Col>
              <Col xs="auto">
                <Form.Group>
                  <OrderControl
                    value={query.sortBy}
                    onChange={(sortBy) => setQuery({ sortBy })}
                    className="my-auto"
                  />
                </Form.Group>
              </Col>
            </Row>
            <CmsCardContainer className={promotionType}>
              {promotions.map((promotion) => (
                <PromotionCard
                  id={promotion.id}
                  key={promotion.id}
                  item={promotion}
                  promotionType={promotionType}
                  onClick={() => onSelectPromotion(promotion.id)}
                  selected={allowMultiple && selectedIds.includes(promotion.id)}
                  canClick
                  zoomOnHover
                />
              ))}
            </CmsCardContainer>
          </Tab>
          <Tab eventKey="create" title="Create new promotion">
            <div className="mt-4">
              <QuickCreatePromo
                onCreate={onSelectPromotion}
                promotionType={promotionType}
              />
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      {allowMultiple && (
        <Modal.Footer>
          <Button variant="primary" onClick={onAdd}>
            Add
          </Button>
          <div className="divider" />
          <Button variant="default" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
