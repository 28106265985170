import classNames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";

/** Wraps children with a loading-overlay which fades when show is true */
const LoadingOverlayWrapper = ({
  show,
  className = undefined,
  children,
  ...rest
}) => (
  <div
    className={classNames("loading-overlay", show.toString(), className)}
    {...rest}
  >
    {children}
  </div>
);

LoadingOverlayWrapper.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

export default LoadingOverlayWrapper;
