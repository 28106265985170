import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

export const ChannelNameFilter = () => {
  const [channelName, setChannelName] = useQueryParam(
    "channelName",
    StringParam
  );

  return (
    <>
      <Form.Label htmlFor="channel-name-filter">Channel name</Form.Label>
      <Form.Control
        id="channel-name-filter"
        value={channelName || ""}
        onChange={(e) => setChannelName(e.target.value || null)}
      />
    </>
  );
};
