import React from "react";
import ReactSpinner, { SpinnerProps } from "react-bootstrap/Spinner";

type Props = Omit<SpinnerProps, "animation">;

export function Spinner(props: Props) {
  return (
    <ReactSpinner animation="border" {...props}>
      <span className="visually-hidden">Loading...</span>
    </ReactSpinner>
  );
}
