import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

export interface Option {
  label: string;
  value: string;
}

const OPTIONS: Option[] = [
  { label: "All", value: "all" },
  { label: "Manual only", value: "manual" },
  { label: "Automatic only", value: "automatic" },
];

export const ManualMatchesPicker = () => {
  const [manualMatches, setManualMatches] = useQueryParam(
    "manualMatches",
    StringParam
  );
  const changeManualMatches = (newManualMatches: string) => {
    if (newManualMatches === "all") {
      setManualMatches(null);
    } else {
      setManualMatches(newManualMatches);
    }
  };
  return (
    <>
      <Form.Label htmlFor="program-matches-manual-matches-picker">
        Manual matches
      </Form.Label>
      <Form.Select
        id="program-matches-manual-matches-picker"
        value={manualMatches || ""}
        onChange={(e) => changeManualMatches(e.target.value)}
      >
        {OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
