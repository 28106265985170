import _ from "lodash";
import React from "react";
import { Button, Container, Col, Row, Form, InputGroup } from "react-bootstrap";
import { CmsPure } from "client/utils";
import { getComposerTitle } from "../provisioningUtils";
import ProvisionerIdPicker from "./ProvisionerIdPicker";

const LABEL = "DVB IPTV Plan";

class DVBIPTVPlansComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      dvbIpTvStreamId: "",
      dvbTripletId: "",
      fccId: "",
      ssrcId: "",
      caDescriptorPrivateData: "",
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) return;

    this.props.get(id, (state) => {
      const rawSsrcId = state.ssrcId || "";
      const ssrcId = rawSsrcId.replace("0x", "");

      this.setState({ ...state, ssrcId });
    });
  }

  create = (event) => {
    event.preventDefault();
    const payload = _.pick(this.state, ["dvbIpTvStreamId", "dvbTripletId"]);

    if (this.state.fccId) {
      payload.fccId = this.state.fccId;

      if (this.state.ssrcId) {
        let ssrcId = this.state.ssrcId;
        if (!ssrcId.startsWith("0x")) {
          ssrcId = "0x" + ssrcId;
        }
        payload.ssrcId = ssrcId;
      }
    }

    if (this.state.caDescriptorPrivateData) {
      payload.caDescriptorPrivateData = this.state.caDescriptorPrivateData;
    }

    this.props.put(payload, this.props.id, LABEL);
  };

  isValid = () => {
    const { dvbIpTvStreamId, dvbTripletId } = this.state;
    return dvbIpTvStreamId && dvbTripletId;
  };

  render() {
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <Container fluid>
          <Row>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <ProvisionerIdPicker
                contentType="DVB Triplet"
                selectedId={this.state.dvbTripletId}
                onSelect={(val) => this.setState({ dvbTripletId: val })}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProvisionerIdPicker
                contentType="DVB IPTV Stream"
                selectedId={this.state.dvbIpTvStreamId}
                onSelect={(val) => this.setState({ dvbIpTvStreamId: val })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={9} className="d-flex align-items-start">
              <div className="w-100">
                <ProvisionerIdPicker
                  contentType="DVB IPTV FCC"
                  selectedId={this.state.fccId}
                  onSelect={(val) => this.setState({ fccId: val })}
                  isClearable
                />
              </div>
            </Col>
            <Col xs={3}>
              <Form.Group className="my-2">
                <Form.Label>SSRC ID</Form.Label>
                <InputGroup>
                  <InputGroup.Text>0x</InputGroup.Text>
                  <Form.Control
                    type="string"
                    value={this.state.fccId ? this.state.ssrcId : ""}
                    placeholder="00000000"
                    onChange={(e) => this.setState({ ssrcId: e.target.value })}
                    disabled={!this.state.fccId}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="my-2">
                <Form.Label>CA descriptor private data</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="string"
                    value={this.state.caDescriptorPrivateData}
                    placeholder="CA-system specific bytes"
                    onChange={(e) =>
                      this.setState({ caDescriptorPrivateData: e.target.value })
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="pull-right mt-4"
                variant="primary"
                type="submit"
                disabled={!this.isValid()}
              >
                {this.props.mode}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}

export default DVBIPTVPlansComposer;
