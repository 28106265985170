import { DateTime, Duration } from "luxon";
import React, { ReactNode } from "react";
import { Badge } from "react-bootstrap";
import { AssetInfo } from "client/types/AssetInfo";

type Props = Pick<AssetInfo, "availabilities">;

export const AvailabilitiesBadges = ({ availabilities }: Props) => {
  const badges: ReactNode[] = [];
  if (availabilities.npvr && availabilities.npvr.valid) {
    badges.push(
      <Badge key="npvr" bg="light" text="dark" className="me-1">
        NPVR
      </Badge>
    );
  }
  if (availabilities.catchUp && availabilities.catchUp.valid) {
    const { start, duration } = availabilities.catchUp;
    const validFrom = DateTime.fromISO(start);
    const validUntil = validFrom.plus(Duration.fromISO(duration));
    if (validFrom < DateTime.now() && validUntil > DateTime.now()) {
      badges.push(
        <Badge key="catchUp" bg="warning">
          Catchup
        </Badge>
      );
    }
  }
  return <div>{badges}</div>;
};
