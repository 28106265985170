import { DateTime } from "luxon";
import React, { useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import { Column } from "react-table-v7";
import { toast } from "react-toastify";
import useSWR from "swr";
import { request } from "client/utils";
import Table from "components/tables";

interface NpvrListRow {
  id: string;
  customer_id: string;
  asset_id: string;
  created: string;
}

interface Props {
  assetId: string;
}

export const Npvr = ({ assetId }: Props) => {
  const { data, mutate } = useSWR<NpvrListRow[]>(`npvr/asset/list/${assetId}`);
  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <NpvrTable data={data} />
      <AddCustomerId assetId={assetId} mutate={mutate} />
    </>
  );
};

const columns: Column<NpvrListRow>[] = [
  {
    Header: "Last 20 customers with NPVR access to asset",
    accessor: (row) => row.customer_id,
  },
  {
    Header: "Created",
    accessor: (row) =>
      DateTime.fromISO(row.created).toFormat("yyyy-MM-dd HH:mm"),
  },
];

interface NpvrTableProps {
  data: NpvrListRow[];
}

const NpvrTable = ({ data }: NpvrTableProps) => {
  return <Table data={data} columns={columns} />;
};

interface AddCustomerIdProps {
  assetId: string;
  mutate: () => void;
}

const AddCustomerId = ({ assetId, mutate }: AddCustomerIdProps) => {
  const [customerId, setCustomerId] = useState<string>();
  const addCustomer = async () => {
    try {
      await request(`npvr/asset/add-customer/${assetId}/${customerId}`, {
        method: "POST",
      });
      mutate();
      setCustomerId(undefined);
    } catch (e: unknown) {
      const error = e as { data?: string };
      const data = error.data ? JSON.parse(error.data) : undefined;
      if (data && data.error) {
        toast.error(data.error);
      } else {
        toast.error(JSON.stringify(e));
      }
    }
  };
  return (
    <FormGroup>
      <Form.Label htmlFor="customer-id">Customer Id</Form.Label>
      <InputGroup style={{ maxWidth: "300px" }}>
        <FormControl
          id="customer-id"
          type="number"
          min={1}
          className="no-round-border-right"
          value={customerId || ""}
          onChange={(e) => setCustomerId(e.target.value)}
        />
        <Button
          variant="primary"
          style={{ height: "auto", width: "auto" }}
          disabled={!customerId}
          onClick={addCustomer}
        >
          Add customer id
        </Button>
      </InputGroup>
    </FormGroup>
  );
};
