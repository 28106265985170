import React, { useState, useCallback } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import MediaContentDialog from "components/MediaContentDialog";
import IMDbToolbar from "./IMDbToolbar";
import IgnoredIMDbList from "./IgnoredIMDbList";
import MatchEditor from "./MatchEditor";
import MatchedIMDbList from "./MatchedIMDbList";
import UnmatchedIMDbList from "./UnmatchedIMDbList";

const IMDbPage = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [searchDialogVisible, setSearchDialogVisible] = useState(false);

  const showSearchDialog = useCallback(() => {
    setSearchDialogVisible(true);
  }, [setSearchDialogVisible]);

  const hideSearchDialog = useCallback(() => {
    setSearchDialogVisible(false);
  }, [setSearchDialogVisible]);

  const openMediaContentMatch = useCallback(
    (mediaContentIds) => {
      hideSearchDialog();
      if (mediaContentIds.length === 1) {
        history.push(`/imdb/match/${mediaContentIds[0]}`);
      }
    },
    [hideSearchDialog, history]
  );

  return (
    <div>
      <MediaContentDialog
        title="Find content"
        show={searchDialogVisible}
        onSelect={openMediaContentMatch}
        selectText="Go"
        onClose={hideSearchDialog}
      />
      <IMDbToolbar showSearchDialog={showSearchDialog} />
      <Switch>
        <Route path={`${path}/unmatched`} component={UnmatchedIMDbList} />
        <Route path={`${path}/matched`} component={MatchedIMDbList} />
        <Route path={`${path}/ignored`} component={IgnoredIMDbList} />
        <Route path={`${path}/match/:id`} component={MatchEditor} />
      </Switch>
    </div>
  );
};

export default IMDbPage;
