import React, { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { request } from "client/utils";
import { displayName } from "./utils";

const SeasonLink = ({ season }) => {
  let to = "/sports/leagues";
  let text = "All leagues";
  if (season) {
    to = `${to}/${season.competition.id}/seasons/${season.id}`;
    text = `${displayName(season.competition)} ${displayName(season)}`;
  }
  return (
    <NavLink
      to={to}
      exact
      activeClassName="active"
      className="publish-toolbar-item"
    >
      {text}
    </NavLink>
  );
};

const SportsToolbar = () => {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    request("sports/seasons/favorites").then(setFavorites);
  }, []);

  return (
    <div className="publish-toolbar">
      <ButtonGroup className="me-auto">
        <SeasonLink />
        {favorites.map((season) => (
          <SeasonLink key={season.id} season={season} />
        ))}
      </ButtonGroup>
    </div>
  );
};

export default SportsToolbar;
