import React from "react";
import { Button, Form } from "react-bootstrap";
import { CmsPure } from "client/utils";
import ProvisionerIdPicker from "./ProvisionerIdPicker";

const LABEL = "Deploy Config";

class ConfigDeploymentComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      configDraftId: "",
    };
  }

  create = (event) => {
    event.preventDefault();
    const payload = this.state;
    this.props.post(payload, LABEL);
  };

  render() {
    console.log(LABEL, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{LABEL}</h1>
        <ProvisionerIdPicker
          contentType="Config Draft"
          selectedId={this.state.configDraftId}
          onSelect={(val) => this.setState({ configDraftId: val })}
        />
        <Button
          className="pull-right mt-4"
          variant="primary"
          type="submit"
          disabled={!this.state.configDraftId}
        >
          {LABEL}
        </Button>
      </Form>
    );
  }
}

export default ConfigDeploymentComposer;
