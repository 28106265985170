import { PropTypes } from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { request } from "client/utils";
import CloudImageCard from "../cards/CloudImageCard";

function isValidResolution(image, width, height, allowHigherResolution) {
  if (allowHigherResolution) {
    if (
      image.width < width ||
      image.height < height ||
      // constrain aspect ratio
      width / height !== image.width / image.height
    ) {
      toast.warn(
        `Image is not of minimum size ${width}x${height} or incorrect aspect ratio`
      );
      return false;
    }
  } else {
    if (image.width !== width || image.height !== height) {
      toast.warn(`Image is not of size ${width}x${height}`);
      return false;
    }
  }
  return true;
}

function CloudImageControl({
  value,
  onChange,
  disabled,
  type,
  width,
  height,
  description = undefined,
  validateResolution = true,
  allowHigherResolution = false,
  accept = "image/jpeg, image/png",
  ...rest
}) {
  const [isProcessing, setProcessing] = React.useState(false);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length < 1) return;

    const winURL = window.URL || window.webkitURL;
    const file = acceptedFiles[0];
    const image = new Image();

    image.onload = function () {
      winURL.revokeObjectURL(file);
      // this = the image
      if (
        validateResolution &&
        !isValidResolution(this, width, height, allowHigherResolution)
      ) {
        return;
      }

      setProcessing(true);

      const data = new FormData();
      data.append("file", file);

      request(`images/upload-${type}`, {
        method: "POST",
        body: data,
      })
        .then((json) => {
          onChange(json.location, type);
        })
        .catch((err) => {
          toast.error(`Image upload failed: ${err.message}`);
        })
        .finally(() => {
          setProcessing(false);
        });
    };
    image.onerror = () => winURL.revokeObjectURL(file);
    image.src = winURL.createObjectURL(file);
  };

  const onDelete = React.useCallback(
    (e) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange, type]
  );

  return (
    <Dropzone
      accept={accept}
      onDrop={disabled ? undefined : onDrop}
      multiple={false}
      disabled={disabled}
      {...rest}
    >
      {({ getRootProps, getInputProps }) => (
        <section {...getRootProps()}>
          <input {...getInputProps()} disabled={disabled} />
          <CloudImageCard
            value={value}
            height={height}
            width={width}
            isProcessing={isProcessing}
            disabled={disabled}
            description={
              description ||
              `${width}x${height}${allowHigherResolution ? "+" : ""}`
            }
            onDelete={onDelete}
            allowHigherResolution={allowHigherResolution}
          />
        </section>
      )}
    </Dropzone>
  );
}

CloudImageControl.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  validateResolution: PropTypes.bool,
  allowHigherResolution: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // should match the image api
  type: PropTypes.oneOf([
    "cover",
    "poster",
    "metadata-image",
    "promotion",
    "collection",
    "sports-logo",
    "channel-logo",
  ]).isRequired,
  description: PropTypes.string,
  value: PropTypes.string, // relative path, eg: editorial/promotion/filename.jpg
};

export default CloudImageControl;
