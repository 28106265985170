import React from "react";
import { GlyphButton } from "components/buttons";

const MappingDialogLink = ({ glyph, onClick, title, readOnly, children }) =>
  readOnly ? (
    children
  ) : (
    <GlyphButton
      glyph={glyph}
      variant="link"
      className="p-0"
      title={title}
      onClick={onClick}
      iconLast
    >
      {children}
    </GlyphButton>
  );

export default MappingDialogLink;
