import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { Link } from "react-router-dom-v5-compat";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import { Truncate } from "components/Truncate";
import {
  DataQualityWarning,
  DataQualityWarningDuplicateData,
  DataQualityWarningEpisodeGapBeforeData,
  DataQualityWarningEvaluationFailedData,
  DataQualityWarnings,
} from "../types";
import ClearDataQualityWarnings from "./ClearDataQualityWarnings";
import { toDataQualityWarnings } from "./toNode";

interface Props {
  dataQualityWarnings: DataQualityWarning[];
  assetId: number;
}

function DataQualityWarningsTab({ dataQualityWarnings, assetId }: Props) {
  return (
    <>
      {_.isEmpty(dataQualityWarnings) ? (
        <h3>No data quality warnings found for this asset.</h3>
      ) : (
        <>
          <Row className="mt-3">
            <DataQualityWarningHeaders />
            {dataQualityWarnings?.map((warning) => (
              <DataQualityWarningRow key={warning.id} warning={warning} />
            ))}
          </Row>
          <Row className="mt-3">
            <Col>
              <ClearDataQualityWarnings assetId={assetId} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

const DataQualityWarningHeaders = () => (
  <Row className="px-3 gx-2">
    <Col xs={2} className="text-secondary">
      Created
    </Col>
    <Col xs={2} className="text-secondary">
      Type
    </Col>
    <Col className="text-secondary">Data</Col>
  </Row>
);

interface DataQualityWarningProps {
  warning: DataQualityWarning;
}

const DataQualityWarningRow = ({ warning }: DataQualityWarningProps) => {
  return (
    <Row className="px-3 gx-2 align-items-start bg-light mb-1">
      <Truncate as={Col} xs={2}>
        {parseDateToDatetimeString(warning.created)}
      </Truncate>
      <Truncate as={Col} xs={2}>
        {toDataQualityWarnings([warning.type], false)}
      </Truncate>
      <Col>
        <DataQualityWarningData warning={warning} />
      </Col>
    </Row>
  );
};

const DataQualityWarningData = ({ warning }: DataQualityWarningProps) => {
  switch (warning.type) {
    case DataQualityWarnings.Duplicate:
      return <DuplicateWarning {...warning.data} />;
    case DataQualityWarnings.EpisodeGapBefore:
      return <EpisodeGapBeforeWarning {...warning.data} />;
    case DataQualityWarnings.EvaluationFailed:
      return <EvaluationFailedWarning {...warning.data} />;
    default:
      return null;
  }
};

const DuplicateWarning = ({
  duplicate_assets_ids,
}: DataQualityWarningDuplicateData) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(
    () => setExpanded((e) => !e),
    [setExpanded]
  );
  const assetCount = duplicate_assets_ids.length;
  const previewIds =
    assetCount > 3 ? duplicate_assets_ids.slice(0, 3) : duplicate_assets_ids;
  const truncatedIdCount = assetCount - previewIds.length;

  return (
    <div onClick={toggleExpanded} className="expand-row">
      <span className={!expanded ? "collapsed" : undefined}>
        Duplicate asset IDs: {!expanded && previewIds.join(", ")}
        {truncatedIdCount > 0 && !expanded && (
          <span className="text-muted"> and {truncatedIdCount} more</span>
        )}
      </span>
      <Collapse in={expanded}>
        <ul>
          {duplicate_assets_ids.map((id) => (
            <li key={id}>
              <Link to={`/ams/assets/${id}`}>{id}</Link>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};

const EpisodeGapBeforeWarning = ({
  missing_count,
}: DataQualityWarningEpisodeGapBeforeData) => {
  return <div>Missing count: {missing_count}</div>;
};

const EvaluationFailedWarning = ({
  exception_message,
}: DataQualityWarningEvaluationFailedData) => {
  const [firstLine, ...expandedLines] = exception_message.trim().split("\n");
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(
    () => setExpanded((e) => !e),
    [setExpanded]
  );

  return (
    <div onClick={toggleExpanded} className="expand-row">
      <div className={!expanded ? "collapsed" : undefined}>{firstLine}</div>
      <Collapse in={expanded}>
        <pre>{expandedLines.join("\n")}</pre>
      </Collapse>
    </div>
  );
};

export default DataQualityWarningsTab;
