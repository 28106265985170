import { Warnings } from "@telia-company/tv.no-play-cms-common/api/Warning";
import React, { useCallback, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { NumberParam, useQueryParam } from "use-query-params";
import { Pagination } from "components/tables/Pagination";
import "./channelRuleSelector.css";

const HIGHLIGHT_STYLE = {
  backgroundColor: "#CCC",
};

interface HighlightedCell {
  row: number;
  column: number;
}

const TableHighlightContext = React.createContext<HighlightedCell>({
  row: -1,
  column: -1,
});

interface Props {
  channels: { [id: number]: string };
  channelRules: { [id: number]: string[] } | null;
  onChange: (channelId: number, rule: string) => void;
}

export const ChannelRuleSelector = ({
  channels,
  channelRules,
  onChange,
}: Props) => {
  const [highlightedCell, setHighlightedCell] = useState({
    row: -1,
    column: -1,
  });
  return (
    <>
      <TableHighlightContext.Provider value={highlightedCell}>
        <table className="channel-rule-selector-table mb-4">
          <TableHead />
          <TableBody
            channels={channels}
            channelRules={channelRules}
            onChange={onChange}
            setHighlightedCell={setHighlightedCell}
          />
        </table>
      </TableHighlightContext.Provider>
      <Pagination totalResults={Object.keys(channels).length} />
    </>
  );
};

const TableHead = () => {
  const highlightedCell = React.useContext(TableHighlightContext);
  return (
    <thead>
      <tr>
        <th className="channel-header ps-2">Channel name</th>
        {Warnings.map((warning, columnIndex) => (
          <th key={warning} className="warning-header">
            <div
              style={
                columnIndex === highlightedCell.column ? HIGHLIGHT_STYLE : {}
              }
            >
              {warning}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface TableBodyProps {
  channels: { [id: string]: string };
  channelRules: { [id: string]: string[] } | null;
  onChange: (channelId: number, rule: string) => void;
  setHighlightedCell: ({
    row,
    column,
  }: {
    row: number;
    column: number;
  }) => void;
}

const TableBody = ({
  channels,
  channelRules,
  onChange,
  setHighlightedCell,
}: TableBodyProps) => {
  const [pageIndex] = useQueryParam("page", NumberParam);
  const [pageSize] = useQueryParam("size", NumberParam);
  const page = pageIndex || 0;
  const size = pageSize || 20;

  return (
    <tbody>
      {Object.entries(channels)
        .slice(page * size, (page + 1) * size)
        .map(([channelId, channelName], rowIndex) => (
          <TableRow
            key={channelId}
            channelName={channelName}
            channelId={channelId}
            channelRules={channelRules}
            onChange={onChange}
            rowIndex={rowIndex}
            setHighlightedCell={setHighlightedCell}
          />
        ))}
    </tbody>
  );
};

interface TableRowProps {
  channelName: string;
  channelId: string;
  channelRules: { [id: string]: string[] } | null;
  onChange: (channelId: number, rule: string) => void;
  rowIndex: number;
  setHighlightedCell: ({
    row,
    column,
  }: {
    row: number;
    column: number;
  }) => void;
}

const TableRow = ({
  channelName,
  channelId,
  channelRules,
  onChange,
  rowIndex,
  setHighlightedCell,
}: TableRowProps) => {
  return (
    <tr>
      <ChannelNameCell
        channelName={channelName}
        rowIndex={rowIndex}
        setHighlightedCell={setHighlightedCell}
      />
      {Warnings.map((warning, columnIndex) => (
        <TableCell
          key={warning}
          channelRules={channelRules}
          channelId={channelId}
          warning={warning}
          onChange={onChange}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          setHighlightedCell={setHighlightedCell}
        />
      ))}
    </tr>
  );
};

interface ChannelNameCellProps {
  channelName: string;
  rowIndex: number;
  setHighlightedCell: ({
    row,
    column,
  }: {
    row: number;
    column: number;
  }) => void;
}

const ChannelNameCell = ({
  channelName,
  rowIndex,
  setHighlightedCell,
}: ChannelNameCellProps) => {
  const highlightedCell = useContext(TableHighlightContext);
  const isRowHighlighted = highlightedCell.row === rowIndex;

  return (
    <td
      style={isRowHighlighted ? HIGHLIGHT_STYLE : {}}
      className="channel-cell ps-2"
      onMouseOver={() =>
        setHighlightedCell({ row: rowIndex, column: highlightedCell.column })
      }
    >
      {channelName}
    </td>
  );
};

interface TableCellProps {
  channelRules: { [id: string]: string[] } | null;
  channelId: string;
  warning: string;
  onChange: (channelId: number, rule: string) => void;
  rowIndex: number;
  columnIndex: number;
  setHighlightedCell: ({
    row,
    column,
  }: {
    row: number;
    column: number;
  }) => void;
}

const TableCell = ({
  channelRules,
  channelId,
  warning,
  onChange,
  rowIndex,
  columnIndex,
  setHighlightedCell,
}: TableCellProps) => {
  const highlightedCell = useContext(TableHighlightContext);
  const isHighlighted =
    rowIndex === highlightedCell.row || columnIndex === highlightedCell.column;
  const isChecked =
    channelRules && channelRules[channelId]
      ? channelRules[channelId].includes(warning)
      : false;
  const onCheckboxChange = useCallback(() => {
    onChange(parseInt(channelId), warning);
  }, [channelId, warning, onChange]);
  return (
    <td
      className="warning-cell"
      style={isHighlighted ? HIGHLIGHT_STYLE : {}}
      onMouseEnter={() =>
        setHighlightedCell({ row: rowIndex, column: columnIndex })
      }
    >
      <Form.Check checked={isChecked} onChange={onCheckboxChange} />
    </td>
  );
};
