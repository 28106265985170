import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import SlackLink from "components/SlackLink";

const UnassignedUser = () => {
  return (
    <Container>
      <Row style={{ marginTop: "10%" }}>
        <Col className="h3">
          <strong>Role unassigned!</strong>
          <div className="mt-2">
            Please contact us in <SlackLink /> to assign you a role.
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UnassignedUser;
