import classNames from "classnames";
import React from "react";

const IMDbRating = ({ rating, numberOfVotes, className }) => (
  <div className={classNames("d-flex", className)}>
    <div className="imdb-star pe-1 mt-1">⭐</div>
    <div className="imdb-rating ps-0 mt-1">
      <div>
        <span className="rating">{rating}</span>
        <span className="text-muted">{"/"}10</span>
      </div>
      <div>
        <div className="votes">{numberOfVotes}</div>
      </div>
    </div>
  </div>
);

export default IMDbRating;
