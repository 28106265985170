import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LeagueSeason from "./LeagueSeason";
import Leagues from "./Leagues";
import SportsToolbar from "./SportsToolbar";
import Teams from "./Teams";
import TeamsToolbar from "./TeamsToolbar";

const SportsPage = () => (
  <div>
    <Switch>
      <Route path="/sports/leagues">
        <SportsToolbar />
      </Route>
      <Route path="/sports/teams">
        <TeamsToolbar />
      </Route>
    </Switch>
    <Switch>
      <Route
        exact
        path="/sports/leagues/:leagueId/seasons/:seasonId"
        component={LeagueSeason}
      />
      <Route exact path="/sports/leagues" component={Leagues} />
      <Route exact path="/sports/teams" component={Teams} />
      <Redirect exact from="/sports" to="/sports/leagues" />
    </Switch>
  </div>
);

export default SportsPage;
