import React from "react";
import { Link } from "react-router-dom";
import { WarningCount } from "./types";

const MAX_WARNING_TYPES_TO_SHOW = 3;

interface Props {
  channel: string;
  counts: WarningCount[];
}

export const ContentWarningsLink = ({ channel, counts }: Props) => {
  const href = `/enrich/mediacontent/list?type=upcoming&channels=${channel}`;

  let total = 0;
  for (const warningCount of counts) {
    total += warningCount.count;
  }
  const expandedCounts =
    counts.length > MAX_WARNING_TYPES_TO_SHOW
      ? counts.slice(0, MAX_WARNING_TYPES_TO_SHOW - 1)
      : counts;
  return (
    <>
      {expandedCounts.map((warningCount) => (
        <div key={warningCount.type}>
          <Link
            to={href + `&warnings=${warningCount.type}`}
            style={{ textDecoration: "underline" }}
          >
            {warningCount.type}: {warningCount.count.toLocaleString("NO")}
          </Link>
        </div>
      ))}
      {counts.length > MAX_WARNING_TYPES_TO_SHOW && (
        <OtherLink href={href} counts={counts} />
      )}
      <div>
        <Link to={href} style={{ textDecoration: "underline" }}>
          Total: {total.toLocaleString("NO")}
        </Link>
      </div>
    </>
  );
};

interface OtherLinkProps {
  href: string;
  counts: WarningCount[];
}

const OtherLink = ({ href, counts }: OtherLinkProps) => {
  let other = 0;
  for (const warningCount of counts.slice(MAX_WARNING_TYPES_TO_SHOW - 1)) {
    other += warningCount.count;
  }
  return (
    <div>
      <Link
        to={
          href +
          counts
            .slice(2)
            .map((warningCount) => `&warnings=${warningCount.type}`)
            .join("")
        }
        style={{ textDecoration: "underline" }}
      >
        Other: {other}
      </Link>
    </div>
  );
};
