import { PropTypes } from "prop-types";
import React from "react";
import { FormGroup } from "react-bootstrap";

const RadioControl = ({ children }) => <FormGroup> {children} </FormGroup>;

RadioControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default RadioControl;
