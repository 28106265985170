import React, { useReducer, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from "client/utils";
import { ContentCardWrapper } from "components/cards";
import CandidateMatches from "./CandidateMatches";
import IMDbMatchCard from "./IMDbMatchCard";
import IgnoredTitleCard from "./IgnoredTitleCard";
import QueryCard from "./QueryCard";
import UnlinkConfirmationCard from "./UnlinkConfirmationCard";
import { reducer, initialState } from "./match-reducer.js";

const MatchEditor = ({ match, history }) => {
  const { id } = match.params;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    request(`imdb/match/${id}`)
      .then((res) => {
        dispatch({ type: "editor.matchRequest.success", payload: res });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: "editor.matchRequest.error" });
      });
  }, [id]);

  useEffect(() => {
    const { imdbMatchUpdate } = state.editor;
    if (imdbMatchUpdate) {
      request(`imdb/match/${id}/${imdbMatchUpdate}`, { method: "PUT" })
        .then((res) => {
          dispatch({ type: "editor.matchUpdate.success", payload: res });
          toast.success("IMDb ID updated!");
        })
        .catch((err) => {
          console.error(err);
          dispatch({ type: "editor.matchUpdate.error" });
        });
    }
  }, [state.editor.imdbMatchUpdate]);

  useEffect(() => {
    const { match } = state.editor;

    if (state.editor.unlinkConfirmed && match) {
      let url = `imdb/match/${id}`;
      if (match.imdbId) {
        url = `${url}/${match.imdbId}`;
      }

      request(url, { method: "DELETE" })
        .then((res) => {
          dispatch({ type: "editor.matchUpdate.success", payload: res });
          toast.success("Removed the IMDb link!");
        })
        .catch((err) => {
          console.error(err);
          toast.error("Could not remove the IMDb mapping");
          dispatch({ type: "editor.matchUpdate.error" });
        });
    }
  }, [state.editor.unlinkConfirmed, state.editor.match]);

  const openMediaContentEditor = () => {
    const mediaContentEditorUrl = `/enrich/mediacontent/editor/${id}`;
    history.push(mediaContentEditorUrl);
  };

  return (
    <Container fluid className="p-4">
      {state.editor.loading ? (
        <Row>
          <Col>Loading...</Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <ContentCardWrapper
                id={id}
                isAsset={false}
                showLink={false}
                onClick={openMediaContentEditor}
                canClick
              />
            </Col>
            <Col>
              {state.editor.confirmUnlink ? (
                <UnlinkConfirmationCard
                  match={state.editor.match}
                  unlinkReason={state.editor.unlinkReason}
                  dispatch={dispatch}
                />
              ) : state.editor.match.emptyReason === "ignored" ? (
                <IgnoredTitleCard
                  match={state.editor.match}
                  dispatch={dispatch}
                />
              ) : (
                <IMDbMatchCard match={state.editor.match} dispatch={dispatch} />
              )}
            </Col>
            <Col>
              <QueryCard query={state.query} dispatch={dispatch} />
            </Col>
          </Row>
          <CandidateMatches
            mediaContentId={id}
            imdbId={state.editor.match.imdbId}
            dispatch={dispatch}
            query={state.query}
            {...state.candidateMatchList}
          />
        </>
      )}
    </Container>
  );
};

export default withRouter(MatchEditor);
