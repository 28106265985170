import React from "react";

export function useOnScreen(ref: React.MutableRefObject<null>, active = true) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current || !active) return;
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [active]);

  return isIntersecting;
}
