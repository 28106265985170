import React from "react";

const SlackLink = () => (
  <a
    href="https://teliacompanypapaytv.slack.com/channels/C04L6GPU4MP"
    target="_blank"
    rel="noopener noreferrer"
  >
    #no-play-cms on Slack
  </a>
);

export default SlackLink;
