import React, { ChangeEventHandler } from "react";
import { Form } from "react-bootstrap";

interface Props {
  value?: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  readOnly: boolean;
}

interface AvailabilityOption {
  name: string;
  value: "SHOW_ALL" | "AVAILABLE_FIRST" | "AVAILABLE_ONLY" | "UNAVAILABLE_ONLY";
}

const options: AvailabilityOption[] = [
  { name: "Show all", value: "SHOW_ALL" },
  { name: "Available first", value: "AVAILABLE_FIRST" },
  { name: "Available only", value: "AVAILABLE_ONLY" },
  { name: "Unavailable only", value: "UNAVAILABLE_ONLY" },
];

export const AvailableContentPicker = ({
  value,
  onChange,
  readOnly,
}: Props) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor="collection-show-available-content-only">
        Available content
      </Form.Label>
      <Form.Select
        style={{ width: "auto" }}
        className="mb-1"
        id="collection-show-available-content-only"
        disabled={readOnly}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </Form.Select>
      <div className="help-block">
        When show available content only is enabled, content from channels and
        providers that the user doesn&apos;t have access to is hidden. Note that
        the preview below doesn&apos;t necessarily reflect what the user sees.
      </div>
    </Form.Group>
  );
};
