import { sleep } from "@telia-company/tv.no-play-cms-common/api/util";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { StringParam, useQueryParam } from "use-query-params";
import { useDebouncedValue } from "client/hooks";
import { request } from "client/utils";
import { RequestError } from "client/utils/request";
import { ProgramChildren } from "../../ProgramDetails/ProgramChildren";
import { ProgramDetails } from "../../ProgramDetails/ProgramDetails";
import { useProgram } from "../../hooks";

export const MergeContent = () => {
  const [duplicateId, setDuplicateId] = useQueryParam(
    "duplicateId",
    StringParam
  );
  const [targetId, setTargetId] = useQueryParam("targetId", StringParam);
  const debouncedDuplicateId = useDebouncedValue(duplicateId);
  const debouncedTargetId = useDebouncedValue(targetId);

  return (
    <>
      <Row>
        <Col xs={6}>
          <Form.Label htmlFor="duplicate-id">Duplicate id</Form.Label>
        </Col>
        <Col xs={5}>
          <Form.Label htmlFor="target-id">Target id</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Control
            id="duplicate-id"
            value={duplicateId || ""}
            placeholder="Duplicate id"
            onChange={(e) => setDuplicateId(e.target.value || null)}
          />
        </Col>
        <Col xs={5}>
          <Form.Control
            id="target-id"
            value={targetId || ""}
            placeholder="Target id"
            onChange={(e) => setTargetId(e.target.value || null)}
          />
        </Col>
        <Col xs={1}>
          <MergeButton duplicateId={duplicateId} targetId={targetId} />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col xs={6}>
          <DuplicateInfo duplicateId={debouncedDuplicateId} />
        </Col>
        <Col xs={6}>
          <TargetInfo targetId={debouncedTargetId} />
        </Col>
      </Row>
    </>
  );
};

interface DuplicateInfoProps {
  duplicateId?: string | null;
}

const DuplicateInfo = ({ duplicateId }: DuplicateInfoProps) => {
  if (!duplicateId) {
    return <div>No duplicate id set</div>;
  }

  return (
    <>
      <ProgramDetails id={duplicateId} database="TELIADB" />
      <ProgramChildren id={duplicateId} database="TELIADB" />
    </>
  );
};

interface TargetInfoProps {
  targetId?: string | null;
}

const TargetInfo = ({ targetId }: TargetInfoProps) => {
  if (!targetId) {
    return <div>No target id set</div>;
  }

  return (
    <>
      <ProgramDetails id={targetId} database="TELIADB" />
      <ProgramChildren id={targetId} database="TELIADB" />
    </>
  );
};

interface MergeButtonProps {
  duplicateId?: string | null;
  targetId?: string | null;
}

const MergeButton = ({ duplicateId, targetId }: MergeButtonProps) => {
  const isDuplicateIdInvalid = useIsProgramIdInvalidOrLoading(duplicateId);
  const isTargetIdInvalid = useIsProgramIdInvalidOrLoading(targetId);
  const revalidate = useRevalidatePrograms(duplicateId, targetId);
  const disabled = isDuplicateIdInvalid || isTargetIdInvalid;

  const merge = async () => {
    try {
      await request(`epg/merge/${duplicateId}/${targetId}`, {
        method: "POST",
      });
    } catch (e) {
      if (e instanceof RequestError) {
        toast(
          `metadata-cms-api error: ${e.status} - ${
            e.statusText
          }: ${JSON.stringify(e.data)}`
        );
      }
    }
    await sleep(2);
    await revalidate();
  };

  return (
    <Button disabled={disabled} onClick={merge}>
      Merge
    </Button>
  );
};

const useIsProgramIdInvalidOrLoading = (id?: string | null) => {
  const { error, isLoading } = useProgram(id || "", "TELIADB");

  return error || isLoading;
};

const useRevalidatePrograms = (
  duplicateId?: string | null,
  targetId?: string | null
) => {
  const { mutate: mutateDuplicate } = useProgram(duplicateId || "", "TELIADB");
  const { mutate: mutateTarget } = useProgram(targetId || "", "TELIADB");
  return async () => {
    const duplicatePromise = mutateDuplicate();
    await mutateTarget();
    await duplicatePromise;
  };
};
