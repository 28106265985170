import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, InputGroup } from "react-bootstrap";
import { CellProps, Column } from "react-table-v7";
import { ArrayParam, NumericArrayParam } from "use-query-params";
import { CLIENT_CONFIG } from "client/config";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import { PlayLogo } from "components/PlayLogo";
import { Truncate } from "components/Truncate";
import { StatusBadge } from "components/badges";
import { GlyphButton } from "components/buttons";
import { toDataQualityWarnings } from "../AssetView/toNode";
import { useOpenApiEnum, useProviders } from "../hooks";
import {
  getAssetAvailabilityStatusVariant,
  getAssetLicenseStatusVariant,
  getAssetPlayStatusVariant,
  getRevisionStatusVariant,
} from "../status";
import { Asset } from "../types";
import { ColumnPicker } from "./ColumnPicker";
import {
  getStatusBadgeMultiValue,
  getStatusBadgeOption,
  StatusBadgeMultiValue,
  StatusBadgeOption,
} from "./FilterSelect";
import {
  DateTimeRangeFilter,
  MultiSelectCheckboxFilter,
  SelectFilter,
  TextInputFilter,
} from "./Filters";
import { getActiveProviders } from "./getActiveProviders";

function assetRevisionUrl(assetId: number) {
  return `/ams/assets/${assetId}/revision`;
}

function metadataCollectorCridUrl(yaafAssetId: string) {
  return `${CLIENT_CONFIG.METADATA_COLLECTOR_ROOT}/crid/${yaafAssetId}`;
}

function playSearchUrl(asset: Asset) {
  const title = asset.seriesTitle || asset.productionTitle;
  return `${CLIENT_CONFIG.PLAY_DOMAIN}/sok?q=${title}`;
}

const localStorageKey = "ams.columns";

function getLocalStorageColumns(): string[] | undefined {
  const localStorageColumnsJson = localStorage.getItem(localStorageKey);

  if (localStorageColumnsJson) {
    try {
      const localStorageColumns = JSON.parse(localStorageColumnsJson);
      if (_.isArray(localStorageColumns)) {
        return localStorageColumns.filter(_.isString);
      }
    } catch (e) {
      console.error("Could not parse AMS asset columns from localStorage");
      console.error(e);
    }
  }

  return undefined;
}

function getColumnId(column: Column<Asset>) {
  if (column.id !== undefined) {
    return column.id;
  }
  if (_.isString(column.accessor)) {
    return column.accessor;
  }
  return undefined;
}

function getHiddenColumnIds(
  columns: ReadonlyArray<Column<Asset>>,
  selectedColumns: string[] | undefined
) {
  if (!selectedColumns) {
    // All columns are visible by default if no column settings exist
    return [];
  }

  const hiddenColumnIds: string[] = [];

  for (const column of columns) {
    const id = getColumnId(column);
    if (!id) {
      continue;
    }

    if (!column.permanent && !selectedColumns.includes(id)) {
      hiddenColumnIds.push(id);
    }
  }

  return hiddenColumnIds;
}

function saveSelectedColumns(columns: string[]) {
  localStorage.setItem(localStorageKey, JSON.stringify(columns));
}

function useSelectedColumns() {
  const [selectedColumns, setSelectedColumns] = useState(
    getLocalStorageColumns()
  );

  useEffect(() => {
    if (selectedColumns) {
      saveSelectedColumns(selectedColumns);
    }
  }, [selectedColumns]);

  return { selectedColumns, setSelectedColumns };
}

interface UseColumnsResult {
  columns: ReadonlyArray<Column<Asset>>;
  hiddenColumns: ReadonlyArray<string>;
}

export function useColumns(
  type: string,
  showActiveOnly: boolean
): UseColumnsResult {
  const { providers, isLoading: loadingProviders } = useProviders();
  const visibleProviders = showActiveOnly
    ? getActiveProviders(providers)
    : providers;
  const assetPlayStatuses = useOpenApiEnum("AssetPlayStatus");
  const assetLicenseStatuses = useOpenApiEnum("AssetLicenseStatus");
  const revisionPlayStatuses = useOpenApiEnum("RevisionPlayStatus");
  const dataQualityWarnings = useOpenApiEnum("DataQualityWarningType");
  const availabilities = useOpenApiEnum("AssetAvailability");

  const { selectedColumns, setSelectedColumns } = useSelectedColumns();
  const [isColumnPickerOpen, setIsColumnPickerOpen] = useState(false);

  const columns: ReadonlyArray<Column<Asset>> = [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }: CellProps<Asset>) => (
        <input type="checkbox" {...row.getToggleRowSelectedProps()} />
      ),
      width: 24,
      disableResizing: true,
      className: "align-self-end",
      permanent: true,
    },
    {
      Header: "Provider",
      accessor: "providerId",
      Cell: ({ cell: { value } }) => {
        const provider = visibleProviders.find((p) => p.id === value);
        return <Truncate>{provider?.name || value}</Truncate>;
      },
      disableSortBy: true,
      width: 30,
      Filter: () => (
        <MultiSelectCheckboxFilter
          filterKey="providerId"
          type={NumericArrayParam}
          options={visibleProviders.map((p) => ({
            value: p.id,
            label: p.name,
          }))}
          isLoading={loadingProviders}
        />
      ),
    },
    {
      Header: "Asset Play Status",
      accessor: "assetPlayStatus",
      Cell: ({ cell: { value } }) => (
        <StatusBadge
          status={value}
          className="mt-1"
          statusToVariant={getAssetPlayStatusVariant}
        />
      ),
      disableSortBy: true,
      width: 50,
      Filter: () => (
        <MultiSelectCheckboxFilter
          filterKey="assetPlayStatus"
          type={ArrayParam}
          options={assetPlayStatuses.schema}
          isLoading={assetPlayStatuses.isLoading}
          components={{
            Option: getStatusBadgeOption(getAssetPlayStatusVariant),
            MultiValue: getStatusBadgeMultiValue(getAssetPlayStatusVariant),
          }}
        />
      ),
    },
    {
      Header: "License Status",
      accessor: "assetLicenseStatus",
      Cell: ({ cell: { value } }) => (
        <StatusBadge
          status={value}
          className="mt-1"
          statusToVariant={getAssetLicenseStatusVariant}
        />
      ),
      disableSortBy: true,
      width: 50,
      Filter: () => (
        <MultiSelectCheckboxFilter
          filterKey="assetLicenseStatus"
          type={ArrayParam}
          options={assetLicenseStatuses.schema}
          isLoading={assetLicenseStatuses.isLoading}
          components={{
            Option: getStatusBadgeOption(getAssetLicenseStatusVariant),
            MultiValue: getStatusBadgeMultiValue(getAssetLicenseStatusVariant),
          }}
        />
      ),
    },
    {
      Header: "Revision Play Status",
      accessor: "revisionPlayStatus",
      Cell: ({ cell: { value } }) => (
        <StatusBadge
          status={value}
          className="mt-1"
          statusToVariant={getRevisionStatusVariant}
        />
      ),
      disableSortBy: true,
      width: 50,
      Filter: () => (
        <MultiSelectCheckboxFilter
          filterKey="revisionPlayStatus"
          type={ArrayParam}
          options={revisionPlayStatuses.schema}
          isLoading={revisionPlayStatuses.isLoading}
          components={{
            Option: StatusBadgeOption,
            MultiValue: StatusBadgeMultiValue,
          }}
        />
      ),
    },
    {
      Header: "YAAF ID",
      accessor: "yaafAssetId",
      Cell: ({ cell: { value } }) => <Truncate>{value}</Truncate>,
      width: 40,
      Filter: () => <TextInputFilter filterKey="yaafAssetId" />,
    },
    {
      Header: () => <abbr title="Provider Asset ID">PAID</abbr>,
      abbreviation: "PAID",
      accessor: "providerAssetId",
      Cell: ({ cell: { value } }) => <Truncate>{value}</Truncate>,
      width: 25,
      Filter: () => <TextInputFilter filterKey="providerAssetId" />,
    },
    {
      // For sorting purposes, the 'title' field is mapped server-side to
      // [episodicNotation, seriesTitle, productionTitle].
      accessor: "title",
      Header: type === "titles" ? "Title" : "Episode - Series - Title",
      Cell: ({ cell: { row } }) => {
        const { productionTitle, seriesTitle, episodicNotation } = row.original;
        const prefix = _.compact([episodicNotation, seriesTitle]).join(" - ");
        const value =
          prefix !== "" ? (
            <>
              {prefix} - <span className="text-muted">{productionTitle}</span>
            </>
          ) : (
            productionTitle
          );
        return <Truncate>{value}</Truncate>;
      },
      width: 150,
      Filter: () => (
        <InputGroup className="flex-nowrap">
          <TextInputFilter filterKey="title" style={{ width: "max-content" }} />
          <SelectFilter
            filterKey="type"
            options={[
              {
                value: "",
                label: "All assets",
              },
              {
                value: "episodes",
                label: "Episodes",
              },
              {
                value: "titles",
                label: "Titles",
              },
            ]}
            style={{ width: "min-content", flexGrow: 0 }}
          />
        </InputGroup>
      ),
    },
    {
      id: "revisionUpdated",
      accessor: ({ revisionUpdated }) =>
        parseDateToDatetimeString(revisionUpdated),
      Header: "Revision Updated",
      Cell: ({ cell: { value } }: CellProps<Asset>) => (
        <Truncate>{value}</Truncate>
      ),
      width: 40,
      Filter: () => <DateTimeRangeFilter />,
    },
    {
      Header: "Availability",
      accessor: "availability",
      Cell: ({ cell: { value } }) => (
        <StatusBadge
          status={value}
          className="mt-1"
          statusToVariant={getAssetAvailabilityStatusVariant}
        />
      ),
      disableSortBy: true,
      width: 50,
      Filter: () => (
        <MultiSelectCheckboxFilter
          filterKey="availability"
          type={ArrayParam}
          options={availabilities.schema}
          isLoading={availabilities.isLoading}
          components={{
            Option: getStatusBadgeOption(getAssetAvailabilityStatusVariant),
            MultiValue: getStatusBadgeMultiValue(
              getAssetAvailabilityStatusVariant
            ),
          }}
        />
      ),
    },
    {
      Header: "Warnings",
      accessor: "dataQualityWarnings",
      width: 30,
      Cell: ({ cell: { value } }) => toDataQualityWarnings(value) || "",
      Filter: () => (
        <MultiSelectCheckboxFilter
          includeSelectAll
          filterKey="dataQualityWarnings"
          type={ArrayParam}
          options={dataQualityWarnings.schema}
          isLoading={dataQualityWarnings.isLoading}
        />
      ),
    },
    {
      Header: "Reports",
      accessor: "issueReportCount",
      width: 90,
      maxWidth: 90,
      disableResizing: true,
      Cell: ({ cell: { value } }) => {
        const bg = value === 0 ? "success" : "warning";
        return (
          <Badge style={{ fontSize: 11 }} bg={bg}>
            {value}
          </Badge>
        );
      },
      Filter: () => (
        <SelectFilter
          filterKey="issueReports"
          options={[
            {
              value: "",
              label: "Any",
            },
            {
              value: "no",
              label: "No",
            },
            {
              value: "yes",
              label: "Yes",
            },
          ]}
        />
      ),
    },
    {
      id: "manage",
      accessor: "id",
      Filter: ({ columns }) => (
        <ColumnPicker
          columns={columns}
          selectedColumns={selectedColumns}
          onChange={setSelectedColumns}
          isOpen={isColumnPickerOpen}
          onChangeOpen={setIsColumnPickerOpen}
        />
      ),
      Cell: ({ cell: { value, row } }) => (
        <div>
          <GlyphButton
            glyph="open"
            to={assetRevisionUrl(value)}
            variant="link"
            className="p-0 me-2"
            title="Open asset"
          />
          <a
            href={metadataCollectorCridUrl(row.original.yaafAssetId)}
            target="_blank"
            rel="noreferrer"
            title="Open asset in Metadata Collector"
            className="me-2"
          >
            <GlyphButton glyph="external" variant="link" className="p-0" />
          </a>
          <a
            href={playSearchUrl(row.original)}
            target="_blank"
            rel="noreferrer"
            title="Search for asset in Telia Play"
          >
            <PlayLogo style={{ width: "1rem" }} />
          </a>
        </div>
      ),
      className: "align-self-end",
      permanent: true,
      disableSortBy: true,
      disableResizing: true,
      width: 90,
      maxWidth: 90,
    },
  ];

  const hiddenColumns = getHiddenColumnIds(columns, selectedColumns);

  return useMemo(
    () => ({ columns, hiddenColumns }),
    [
      type,
      providers,
      loadingProviders,
      assetPlayStatuses.schema,
      assetPlayStatuses.isLoading,
      assetLicenseStatuses.schema,
      assetLicenseStatuses.isLoading,
      revisionPlayStatuses.schema,
      revisionPlayStatuses.isLoading,
      dataQualityWarnings.schema,
      dataQualityWarnings.isLoading,
      selectedColumns,
      setSelectedColumns,
      isColumnPickerOpen,
      setIsColumnPickerOpen,
      showActiveOnly,
    ]
  );
}
