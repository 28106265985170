import React from "react";
import { useChannels } from "client/hooks";

interface Props {
  id: string;
}

export const ChannelName = ({ id }: Props) => {
  const { channelMap } = useChannels();
  return <span>{channelMap ? channelMap[id] || id : id}</span>;
};
