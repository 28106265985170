import React from "react";
import { Container } from "react-bootstrap";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";
import { useMediaContentHistory } from "client/hooks/useMediaContentHistory";
import DataViewCard from "./DataViewCard";
import VersionSelectionCard from "./VersionSelectionCard";
import { MediaContentHistory } from "./types";

function History({ id }: { id: string }) {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 10),
  });
  const { data, isLoading, totalHits, rollback } = useMediaContentHistory(id, {
    page: query.page.toString(),
    limit: query.limit.toString(),
  });
  const [selected, setSelected] = React.useState<MediaContentHistory>();
  const [compared, setCompared] = React.useState<MediaContentHistory>();

  return (
    <Container>
      <VersionSelectionCard
        data={data}
        params={query}
        setParams={setQuery}
        isLoading={isLoading}
        totalHits={totalHits}
        compared={compared}
        selected={selected}
        setSelected={setSelected}
        setCompared={setCompared}
      />
      <DataViewCard
        leftValue={selected}
        rightValue={compared}
        rollback={rollback}
      />
    </Container>
  );
}

export default History;
