import { parseOsloDateString } from "@telia-company/tv.no-play-cms-common/api/Date";
import { getActiveAvailabilityTypes } from "@telia-company/tv.no-play-cms-common/utils/availabilities";
import classNames from "classnames";
import React from "react";
import { Badge, ListGroupItem } from "react-bootstrap";
import { useChannels, useProviders } from "client/hooks";

const AssetSummaryLink = ({ asset }) => {
  if (!asset.channel && !asset.provider) {
    return null;
  }

  return (
    <ListGroupItem action href={`/enrich/asset/${asset.id}`}>
      <AssetSummary asset={asset} />
    </ListGroupItem>
  );
};

const AssetSummary = ({ asset }) => {
  if (asset.channel) {
    return <LinearAssetSummary asset={asset} />;
  } else if (asset.provider) {
    return <ProviderAssetSummary asset={asset} />;
  }

  return null;
};

const LinearAssetSummary = ({ asset }) => {
  const { channelMap } = useChannels();
  const { availabilities, channel } = asset;
  const channelName = channelMap[channel];
  const start = parseOsloDateString(availabilities.linear.start);
  const valid = availabilities.linear.valid;

  return (
    <div
      className={classNames({
        "d-flex justify-content-between": true,
        "text-muted": !valid,
      })}
    >
      <div className="fw-bold">{channelName}</div>
      <div>{start.format("YYYY-MM-DD HH:mm")}</div>
    </div>
  );
};

const ProviderAssetSummary = ({ asset }) => {
  const { providerMap } = useProviders();
  const { availabilities, provider } = asset;
  const providerName = providerMap[provider];
  const availabilityTypes = getActiveAvailabilityTypes(availabilities);

  return (
    <div className="d-flex justify-content-between">
      <div className="fw-bold">{providerName}</div>
      <div>
        {availabilityTypes.map((type) => (
          <Badge bg="warning" className="ms-1" key={type}>
            {type}
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default AssetSummaryLink;
