import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { stringify } from "client/utils";
import { useAsset } from "client/views/Ams/hooks";
import { ConfirmActionButton } from "components/buttons";
import { AssetAction } from "../BulkActions";

interface Props {
  assetId: number;
}

export default function ClearDataQualityWarnings({ assetId }: Props) {
  const { asset, isLoading, mutate: refreshAsset } = useAsset(assetId);
  const onClick = useCallback(async () => {
    try {
      const action = new AssetAction("data-quality-warnings", {
        method: "DELETE",
      });
      const { status, detail, message, error } = await action.run({ asset });
      if (status && status < 300) {
        toast.success("Cleared data quality warnings");
      } else {
        const msg = message || stringify(detail) || stringify(error);
        toast.error("Could not clear quality warnings: " + msg);
      }
    } catch (error) {
      toast.error("Could not clear quality warnings: " + stringify(error));
    }
    refreshAsset();
  }, [asset, refreshAsset]);

  return (
    <ConfirmActionButton variant="light" onClick={onClick} disabled={isLoading}>
      Clear data quality warnings
    </ConfirmActionButton>
  );
}
