import { Warnings } from "@telia-company/tv.no-play-cms-common/api/Warning";
import React from "react";
import Select from "react-select";
import { ArrayParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

const warningOptions = Warnings.map((warn) => ({ value: warn, label: warn }));
export const WarningFilter = () => {
  const [warnings = [], setWarnings] = useQueryParam("warnings", ArrayParam);
  const resetPage = useResetPage();
  const selectWarnings = (newWarnings: string[] | null) => {
    setWarnings(newWarnings);
    resetPage();
  };

  const warningValues = (warnings || []).map((w) =>
    warningOptions.find((option) => option.value === w)
  );
  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      onChange={(options) =>
        selectWarnings(options.map((option) => option?.value || ""))
      }
      options={warningOptions}
      placeholder="Select warnings"
      hideSelectedOptions={true}
      value={warningValues}
    />
  );
};
