import _ from "lodash";
import React from "react";
import Select from "react-select";
import { StringParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

const typeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Upcoming broadcasts",
    value: "upcoming",
  },
  {
    label: "Active catchup",
    value: "catchup",
  },
];
const typeOptionsMap = _.fromPairs(
  typeOptions.map((option) => [option.value, option.label])
);
export const TypeFilter = () => {
  const [type, setType] = useQueryParam("type", StringParam);
  const resetPage = useResetPage();
  const selectType = (newType: string | null) => {
    setType(newType);
    resetPage();
  };
  const selectedType = type || "all";

  return (
    <Select
      onChange={(selected) =>
        selectType(selected && selected.value !== "all" ? selected.value : null)
      }
      options={typeOptions}
      value={{
        label: typeOptionsMap[selectedType],
        value: selectedType,
      }}
    />
  );
};
