import { PropTypes } from "prop-types";
import React from "react";
import { CmsCard } from "./CmsCard";

/**
 * Renders an empty card with a placeholder add icon
 * @param {*} props
 */
export const EmptyCmsCard = ({ roundedCorners, ...props }) => (
  <CmsCard.Base roundedCorners={roundedCorners} {...props}>
    <CmsCard.Add roundedCorners={roundedCorners} />
  </CmsCard.Base>
);
EmptyCmsCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  canClick: PropTypes.bool.isRequired,
  roundedCorners: PropTypes.bool,
  variant: PropTypes.string,
};

export default EmptyCmsCard;
