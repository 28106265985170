import React from "react";
import { Link } from "react-router-dom";
import { CLIENT_CONFIG } from "client/config";

interface Props {
  id: string;
  linkText: string;
}

export const LinkToMediaContentEditor = ({ id, linkText }: Props) => {
  const rootUrl =
    CLIENT_CONFIG.NAME === "prod"
      ? "https://cms.teliaplay.no"
      : "https://cms.qa.teliaplay.no";
  const tooltip = `${rootUrl}/enrich/mediacontent/editor/${id}`;
  return (
    <Link to={`/enrich/mediacontent/editor/${id}`} data-tip={tooltip}>
      {linkText}
    </Link>
  );
};
