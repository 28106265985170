import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { request } from "client/utils";
import { displayName } from "./utils";

const SeasonFilterLink = ({ season, activeSeasonId, children }) => {
  const active = season ? season.id === activeSeasonId : !activeSeasonId;
  const baseUrl = "/sports/teams";
  const to = season ? `${baseUrl}?seasonId=${season.id}` : baseUrl;
  const className = classNames("publish-toolbar-item", active && "active");
  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
};

const TeamsToolbar = () => {
  const [favorites, setFavorites] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const seasonId = params.has("seasonId")
    ? parseInt(params.get("seasonId"))
    : undefined;

  useEffect(() => {
    request("sports/seasons/favorites").then(setFavorites);
  }, []);

  return (
    <div className="publish-toolbar">
      <ButtonGroup className="me-auto">
        <SeasonFilterLink activeSeasonId={seasonId}>All teams</SeasonFilterLink>
        {favorites.map((season) => (
          <SeasonFilterLink
            key={season.id}
            activeSeasonId={seasonId}
            season={season}
          >
            {`${displayName(season.competition)} ${displayName(season)}`}
          </SeasonFilterLink>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default TeamsToolbar;
