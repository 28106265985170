import React from "react";
import useSWR from "swr";
import Table from "components/tables";
import { ProgramMediaData } from "../types";
import { getTitle } from "../utils";

interface MediaContentChild {
  data: ProgramMediaData;
  id: string;
  parent_id: string;
}

const columns = [
  {
    Header: "Id",
    accessor: (row: MediaContentChild) => row.id,
    width: 50,
  },
  {
    Header: "Crid",
    accessor: (row: MediaContentChild) => row.data.crid,
    width: 100,
  },
  {
    Header: "Title",
    accessor: (row: MediaContentChild) => getTitle(row.data),
    width: 100,
  },
];

interface Props {
  id: string;
}

export const ProgramContentChildren = ({ id }: Props) => {
  const children = useMediaContentChildren(id);
  if (!children) {
    return <div>Loading...</div>;
  }
  return <Table data={children} columns={columns} flex />;
};

const useMediaContentChildren = (id: string) => {
  const { data } = useSWR<MediaContentChild[]>(
    `mediacontent/children-of/${id}`
  );
  return data;
};
