import React, { useCallback, useMemo } from "react";
import CloudImage from "components/image/CloudImage";
import Table from "components/tables";

const TeamsTable = ({ teams, selectedTeam, highlight, dispatch, loading }) => {
  const highlightWords = useCallback(
    (s) => {
      if (highlight) {
        const cleanedHighlight = highlight.replace(
          /[.*+\-?^${}()|[\]\\ ]+/g,
          " "
        );
        const words = cleanedHighlight.split(" ").filter((w) => w);
        const pattern = words.join("|");
        const re = new RegExp(pattern, "gi");
        let prevIndex = 0;
        const segments = [];
        let key = 0;

        for (const m of s.matchAll(re)) {
          if (m.index > prevIndex) {
            segments.push(
              <span key={key++}>{s.substring(prevIndex, m.index)}</span>
            );
          }
          segments.push(<b key={key++}>{m[0]}</b>);
          prevIndex = m.index + m[0].length;
        }

        if (prevIndex < s.length) {
          segments.push(<span key={key}>{s.substring(prevIndex)}</span>);
        }

        return <span>{segments}</span>;
      }
      return s;
    },
    [highlight]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "editorial.logo",
        Cell: ({ value }) => <CloudImage path={value} width={20} height={20} />,
      },
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ value }) => highlightWords(value),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => highlightWords(value),
      },
      {
        Header: "Official name",
        accessor: "officialName",
        Cell: ({ value }) => highlightWords(value),
      },
      {
        Header: "Short name",
        accessor: "shortName",
        Cell: ({ value }) => highlightWords(value),
      },
      {
        Header: "Country",
        accessor: "country.name",
      },
    ],
    [highlightWords]
  );

  const onClick = useCallback(
    (team) => dispatch({ type: "team.selected", payload: team }),
    []
  );

  return (
    <Table
      columns={columns}
      data={teams}
      selectedItem={selectedTeam}
      onClick={onClick}
      loading={loading}
    />
  );
};

export default TeamsTable;
