import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { NumberParam, useQueryParam } from "use-query-params";
import { useChannelSelection, useMetadataChannels } from "../hooks";
import { MetadataSource } from "../types";

export const ChannelPicker = () => {
  const { selectedChannels, setChannels } = useChannelSelection();
  const { channels, channelMap, isLoading } = useMetadataChannels();

  const [, setPage] = useQueryParam("page", NumberParam);

  if (isLoading) {
    return <div>Loading channels</div>;
  }

  const selected = selectedChannels
    ? selectedChannels
        .filter((selectedChannel) => selectedChannel !== null)
        .map((selectedChannel) => channelMap[selectedChannel as number])
    : [];
  return (
    <>
      <Form.Label>Channel</Form.Label>
      <Typeahead
        id="linear-channel"
        placeholder="Select channels"
        multiple
        options={channels}
        labelKey={(channel: MetadataSource) =>
          channel.name || channel.id.toString()
        }
        selected={selected}
        onChange={(newSources) => {
          const newChannelIds = newSources.map((source) => source.id);
          setChannels(newChannelIds);
          setPage(null);
        }}
      />
    </>
  );
};
