import { CLIENT_CONFIG } from "client/config";

export async function parseResponseContent(headers: Headers, text: string) {
  const contentType = headers.get("content-type");
  const isJson = contentType && contentType.includes("application/json");
  try {
    if (text.length == 0 && isJson) {
      return null;
    }
    if (isJson) {
      return JSON.parse(text);
    }
  } catch (err) {
    console.error("Failed to parse response", text, err);
  }
  return text;
}

export class RequestError extends Error {
  status: number;
  statusText: string;
  data?: object | string;

  constructor(status: number, statusText: string, data?: object | string) {
    super(statusText);
    this.status = status;
    this.statusText = statusText;
    this.data = data;
  }
}

/**
 * Sends a request to the backend for the given relative path resource
 * and with the given options. Returns json interpreted result.
 * Will set content type to json automatically if request is POST or PUT
 */
export const request = async (
  resource: string,
  options: RequestInit = {},
  parseResult = true
) => {
  const token = localStorage.getItem("auth.token");

  options.credentials = "include";
  options.mode = "cors";
  options.method = options.method || "GET";
  options.cache = "no-store"; // Disable cache.
  options.headers = new Headers(options.headers);

  // Default to application/json as content type for string request
  // bodies, in order to avoid having to specify the content type
  // explicitly for standard requests
  if (
    ["POST", "PUT", "PATCH", "DELETE"].includes(options.method.toUpperCase()) &&
    typeof options.body === "string" &&
    options.body.length > 0
  ) {
    options.headers.set("Content-Type", "application/json");
  }

  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  const url = `${CLIENT_CONFIG.API_ROOT}/api/${resource}`;
  const response = await fetch(url, options);
  if (!parseResult) {
    return response;
  }

  const responseText = await response.text();
  const parsedContent = await parseResponseContent(
    response.headers,
    responseText
  );
  if (response.ok) {
    return parsedContent;
  } else if (response.status === 401 && !resource.startsWith("usagestats")) {
    localStorage.setItem("auth.disableAutoLogin", "true");
    localStorage.removeItem("auth.token");
    localStorage.removeItem("auth.token.exp");
    window.location.reload();
  } else {
    console.error(`${response.status} ${response.statusText} (${url})`);
    console.error(response);
    throw new RequestError(response.status, response.statusText, responseText);
  }
};
