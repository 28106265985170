/**
 * Additional convenience hooks based on useQueryParams() / useQueryParam().
 *
 * This module provides hooks with sensible defaults for commonly used
 * query parameters.
 */
import { isEqual } from "lodash";
import { useCallback } from "react";
import { ArrayParam, useQueryParam, withDefault } from "use-query-params";

const toSort = ({ id, desc }) => `${id}-${desc ? "DESC" : "ASC"}`;

export function useReactTableSort(rtDefaultValue) {
  const [sort, setSort] = useQueryParam(
    "sort",
    withDefault(ArrayParam, rtDefaultValue.map(toSort))
  );
  const setter = useCallback(
    (rtSort) => {
      const nextSort = rtSort.map(toSort);
      if (!isEqual(sort, nextSort)) {
        setSort(nextSort);
      }
    },
    [sort, setSort]
  );
  return [sort, setter];
}
