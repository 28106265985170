import _ from "lodash";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { ConfirmActionButton } from "components/buttons";
import { ActionOnClickHandler, AssetRevisionLocator } from "../types";
import { RevisionAction, TaskAction } from "./actions";

interface Props {
  onClick: ActionOnClickHandler;
  disabled?: boolean | ((key: string) => boolean);
  mustConfirm?: boolean;
}

export function RevisionActionButtons({
  onClick,
  disabled = false,
  mustConfirm = false,
}: Props) {
  const Component = mustConfirm ? ConfirmActionButton : Button;

  const isDisabled = (key: string): boolean => {
    if (_.isBoolean(disabled)) {
      return disabled;
    }

    if (_.isFunction(disabled)) {
      return disabled(key);
    }

    return false;
  };

  const revisionActionHandler = (actionName: string) => () =>
    onClick(new RevisionAction(actionName));

  const taskActionHandler =
    (actionName: string, locator: AssetRevisionLocator) => () =>
      onClick(new TaskAction(actionName, locator));

  return (
    <ButtonGroup>
      <Component
        variant="light"
        onClick={taskActionHandler("receive_yaafd", "yaafo_locator")}
        disabled={isDisabled("yaafo_locator")}
      >
        Receive YAAFO
      </Component>
      <Component
        variant="light"
        onClick={taskActionHandler("receive_yaafd", "yaafd_locator")}
        disabled={isDisabled("yaafd_locator")}
      >
        Receive YAAFD
      </Component>
      <Component
        variant="light"
        onClick={revisionActionHandler("play-ignore")}
        disabled={isDisabled("play-ignore")}
      >
        Ignore: Play
      </Component>
      <Component
        variant="light"
        onClick={revisionActionHandler("submit-api-import-job")}
        disabled={isDisabled("submit-api-import-job")}
      >
        Run api-import
      </Component>
      <Component
        variant="light"
        onClick={revisionActionHandler("submit-asset-import-job")}
        disabled={isDisabled("submit-asset-import-job")}
      >
        Run asset-import
      </Component>
      <Component
        variant="light"
        onClick={taskActionHandler("receive_yaaf", "yaaf_locator")}
        disabled={isDisabled("yaaf_locator")}
      >
        Receive YAAF
      </Component>
    </ButtonGroup>
  );
}
