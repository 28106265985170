import { CollectionFilters } from "@telia-company/tv.no-play-cms-common/collection/types";
import React from "react";
import { Accordion } from "react-bootstrap";
import { CollectionOnChangeHandlers } from "client/views/CollectionEditor/utils";
import { ContentIdsManager } from "./ContentIdsManager";

interface Props {
  filters: CollectionFilters;
  onChangeHandlers: CollectionOnChangeHandlers;
}

export const ManualOverrides = ({ filters, onChangeHandlers }: Props) => {
  return (
    <Accordion className="mt-2">
      <Accordion.Item eventKey="A">
        <Accordion.Header>Manual overrides</Accordion.Header>
        <Accordion.Body className="p-2">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Manual prio</Accordion.Header>
              <Accordion.Body>
                <ContentIdsManager
                  ids={filters.manualPrio}
                  setIds={onChangeHandlers.manualPrio}
                  htmlId="add-manual-prio"
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Manual hide</Accordion.Header>
              <Accordion.Body>
                <ContentIdsManager
                  ids={filters.manualHide}
                  setIds={onChangeHandlers.manualHide}
                  htmlId="add-manual-hide"
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
