import { ReportTypes } from "@telia-company/tv.no-play-cms-common/api/ReportType";
import React from "react";
import useSWR from "swr";

export const TotalRow = () => {
  const data = useContentReportStats();

  if (!data) {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }

  return (
    <tr>
      <td>Total</td>
      {ReportTypes.map((reportType) => (
        <td key={reportType} style={{ textAlign: "end" }}>
          {data[reportType].toLocaleString("NO")}
        </td>
      ))}
    </tr>
  );
};

export const useContentReportStats = () => {
  const { data } = useSWR<{ [reportType: string]: number }>(`insight/stats`);

  return data;
};
