import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Genres } from "client/types";
import { useGenreOptions, useSelectedGenres } from "./hooks";
import { GenreWithHitCount } from "./types";
import { getGenreOptionLabel } from "./utils";

interface Props {
  onChange: (selected: Genres, genreOperatorValue: "or" | "and") => void;
  genreValue: string[];
  genreOperatorValue: "or" | "and";
  readOnly: boolean;
  language: string;
  genreCounts?: { [genre: string]: number };
}

export const FilterGenrePicker = ({
  onChange,
  genreValue,
  genreOperatorValue,
  readOnly,
  language,
  genreCounts,
}: Props) => {
  const genreOptions = useGenreOptions(language, genreCounts);
  const selected = useSelectedGenres(genreValue, genreOptions, language);
  const onGenresChange = (genres: GenreWithHitCount[]) => {
    onChange(
      genres.map((genre) => ({
        value: genre.value,
        language: genre.language,
      })),
      genreOperatorValue
    );
  };
  return (
    <>
      <Form.Label>Filter by genre</Form.Label>
      <Typeahead
        multiple
        id="collection-genre-picker"
        selected={selected}
        options={genreOptions}
        labelKey={getGenreOptionLabel}
        placeholder="Genre filter"
        onChange={onGenresChange}
        className="mb-3"
        disabled={readOnly}
      />
    </>
  );
};
