import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateRangePicker } from "client/views/Epg/DateRangePicker";
import { TitlePicker } from "../TitlePicker";
import { useMetadataChannels } from "../hooks";
import { ChannelPicker } from "./ChannelPicker";
import { ClearButton } from "./ClearButton";

export const LinearPickers = () => {
  const { channels } = useMetadataChannels();

  if (!channels) {
    return <div>Loading</div>;
  }

  return (
    <Row>
      <Col xs={3}>
        <TitlePicker />
      </Col>
      <Col xs={3}>
        <ChannelPicker />
      </Col>
      <Col xs={5}>
        <DateRangePicker />
      </Col>
      <Col xs={1}>
        <ClearButton />
      </Col>
    </Row>
  );
};
