import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { GenresContext } from "client/App";
import { Genres } from "client/types";

interface Props {
  id: string;
  language: string;
  onChange: (selected: Genres) => void;
  placeholder?: string;
  value: Genres;
  className: string;
  disabled: boolean;
}

export const GenreMultiPicker = ({
  id,
  language,
  onChange,
  placeholder = "Genre filter",
  value,
  className,
  disabled,
  ...rest
}: Props) => {
  return (
    <GenresContext.Consumer>
      {(genres) => {
        const options = genres.filter((g) => g.language === language);
        return (
          <Typeahead
            multiple
            id={id}
            selected={value}
            options={options}
            labelKey="value"
            placeholder={placeholder}
            onChange={onChange}
            className={className}
            disabled={disabled}
            {...rest}
          />
        );
      }}
    </GenresContext.Consumer>
  );
};
