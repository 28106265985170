import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { ActionOnClickHandler } from "../types";
import { AssetAction } from "./actions";

interface Props {
  onClick: ActionOnClickHandler;
  disabled: boolean;
}

export function AssetActionButtons({ onClick, disabled = false }: Props) {
  const assetActionHandler =
    (actionName: string, requestOptions?: RequestInit) => () =>
      onClick(new AssetAction(actionName, requestOptions));

  return (
    <ButtonGroup>
      <Button
        variant="light"
        onClick={assetActionHandler("evaluate-status")}
        disabled={disabled}
      >
        Evaluate status
      </Button>
      <Button
        variant="light"
        onClick={assetActionHandler("data-quality-warnings", {
          method: "DELETE",
        })}
        disabled={disabled}
      >
        Clear data quality warnings
      </Button>
    </ButtonGroup>
  );
}
