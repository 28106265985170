import React, { useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useMediaContents } from "client/hooks/useMediaContents";
import { CmsCardContainer, ContentCard } from "components/cards";

function ContentDialog({ season, dialog, onSelect, onClose }) {
  const initialSearchParams = useMemo(
    () => ({
      kinds: ["season", "show", "series"],
      title: dialog.initialQuery,
      activeOnly: season.active,
    }),
    [dialog.initialQuery, season.active]
  );

  const { mediaContents, queryParams, onChangeQueryParams } =
    useMediaContents(initialSearchParams);

  return (
    <Modal size="xl" autoFocus show={dialog.isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Find media content for season</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row as={Form}>
          <Col>
            <Form.Label hidden>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Type something to search"
              value={queryParams.title}
              onChange={(e) => onChangeQueryParams({ title: e.target.value })}
            />
          </Col>
          <Col xs="auto">
            <Button
              variant={queryParams.activeOnly ? "info" : "light"}
              onClick={() =>
                onChangeQueryParams({ activeOnly: !queryParams.activeOnly })
              }
            >
              Active only
            </Button>
          </Col>
          {queryParams.parentId && <Col xs="auto">Hi I have a parent</Col>}
        </Row>
        <CmsCardContainer className="mt-4">
          {mediaContents.map((card) => {
            return (
              <ContentCard
                item={card}
                key={card.id}
                id={card.id}
                isAsset={false}
                onClick={() => onSelect(card.id)}
                showLink={false}
                canClick
                zoomOnHover
              />
            );
          })}
        </CmsCardContainer>
      </Modal.Body>
    </Modal>
  );
}

export default ContentDialog;
