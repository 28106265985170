import React from "react";
import { Form } from "react-bootstrap";
import { NumberParam, useQueryParam } from "use-query-params";
import { SingleSelect } from "client/components/pickers";
import { LoadingOverlayWrapper } from "client/utils";
import { IssueReportsTable } from "./IssueReportsTable";
import { useIssueReports } from "./useIssueReports";

export default function IssueReports() {
  const [limitParam = 100, setLimit] = useQueryParam("limit", NumberParam);
  const { reports, isLoading, error } = useIssueReports({
    limit: limitParam ?? undefined,
  });

  return (
    <LoadingOverlayWrapper show={isLoading}>
      <div className="d-flex justify-content-end">
        <Form.Label className="m-0">
          Limit
          <SingleSelect
            onChange={setLimit}
            value={limitParam}
            options={[
              { label: "100", value: 100 },
              { label: "250", value: 250 },
              { label: "500", value: 500 },
              { label: "1000", value: 1000 },
            ]}
          />
        </Form.Label>
      </div>

      {error ? (
        <div className="text-danger">Could not load issue reports</div>
      ) : (
        <IssueReportsTable reports={reports} showAsset />
      )}
    </LoadingOverlayWrapper>
  );
}
