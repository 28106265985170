type BootstrapColorVariant =
  | "primary"
  | "secondary"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "light"
  | "dark";

export function getAssetPlayStatusVariant(
  status: string
): BootstrapColorVariant {
  switch (status) {
    case "UNEVALUATED":
      return "info";
    case "PROCESSING":
      return "info";
    case "UNRELEASED":
      return "danger";
    case "OUTDATED":
      return "warning";
    case "UP_TO_DATE":
      return "success";
    case "FAILED_EVALUATION":
      return "danger";

    default:
      return "light";
  }
}

export function getAssetLicenseStatusVariant(
  status: string
): BootstrapColorVariant {
  switch (status) {
    case "UNEVALUATED":
      return "info";
    case "MISSING":
      return "danger";
    case "UPCOMING":
      return "light";
    case "ACTIVE":
      return "success";
    case "EXPIRED":
      return "dark";
    case "FAILED_EVALUATION":
      return "danger";

    default:
      return "light";
  }
}

export function getAssetAvailabilityStatusVariant(
  status: string
): BootstrapColorVariant {
  switch (status) {
    case "UNEVALUATED":
      return "info";
    case "UPCOMING":
      return "light";
    case "ACTIVE":
      return "success";
    case "N/A":
      return "dark";

    default:
      return "light";
  }
}

export function getRevisionStatusVariant(
  status: string
): BootstrapColorVariant {
  switch (status) {
    case "ASSET_IMPORT_JOB_SUBMITTED":
      return "info";
    case "ASSET_IMPORT_JOB_RUNNING":
      return "info";
    case "FAILED_ASSET_IMPORT_JOB":
      return "danger";
    case "RECEIVING_YAAF":
      return "info";
    case "RECEIVED_YAAF_D":
      return "light";
    case "FAILED_RECEIVING_YAAF":
      return "danger";
    case "FAILED_RECEIVING_YAAF_D":
      return "danger";
    case "INACTIVE_PROVIDER":
      return "warning";
    case "IGNORED":
      return "light";
    case "TRANSCODING":
      return "info";
    case "FAILED_TRANSCODING":
      return "danger";
    case "RETRANSCODING":
      return "info";
    case "RELEASABLE":
      return "light";
    case "UPDATING":
      return "info";
    case "FAILED_TO_UPDATE":
      return "danger";
    case "UP_TO_DATE":
      return "success";
    case "UNKNOWN":
      return "light";

    default:
      return "light";
  }
}
