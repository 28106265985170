import { Genres } from "client/types";
import { GenreWithHitCount } from "client/views/CollectionEditor/CollectionFiltersComponent/types";

export const onGenresChangeFactory = (onChange: (selected: Genres) => void) => {
  return (genres: GenreWithHitCount[]) =>
    onChange(
      genres.map((genre) => ({
        value: genre.value,
        language: genre.language,
      }))
    );
};

export const getGenreOptionLabel = (value: GenreWithHitCount) => {
  let label = value.value;
  if (value.count) {
    label = `${label}: ${value.count}`;
  }
  return label;
};
