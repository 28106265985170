import { extractTitle } from "@telia-company/tv.no-play-cms-common/api/MediaContentUtils";
import { capitalize } from "@telia-company/tv.no-play-cms-common/api/util";
import { MediaContentData } from "@telia-company/tv.no-play-cms-common/db/definitions/MediaContent";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom-v5-compat";
import { AssetDetailsResponse } from "./types";

interface MediaContentTitlesProps {
  data: AssetDetailsResponse;
}

export const MediaContentTitles = ({ data }: MediaContentTitlesProps) => {
  return (
    <Row style={{ fontSize: 18 }}>
      <Title mc={data.MC} />
      {data.PMC && <Title mc={data.PMC} />}
      {data.GPMC && <Title mc={data.GPMC} />}
    </Row>
  );
};

const Title = ({ mc }: { mc: MediaContentData }): JSX.Element => (
  <Col xs={4}>
    <Link to={`/enrich/mediacontent/editor/${mc.id}`}>
      <b>{`${capitalize(mc.kind)} title:`}</b> {extractTitle(mc)}
      {mc.index && (
        <>
          {" "}
          [{mc.kind === "episode" ? "E" : mc.kind === "season" ? "S" : ""}
          {mc.index.toFixed(0).padStart(2, "0")}]
        </>
      )}
    </Link>
  </Col>
);
