import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { permissionTypes } from "client/ACL";
import { ACTION, defaultActionReducer } from "client/hooks";
import { request } from "client/utils";
import { ConfirmDeleteButton, GlyphButton } from "components/buttons";
import Table from "components/tables";
import RoleModal from "./RoleModal";

const DEFAULT_ROLE = {
  id: "",
  role: "",
  permission: [],
  default_route: "",
};

function Roles({ roles, setRoles }) {
  const onDeleteRole = React.useCallback(
    ({ id }) => {
      request("settings/admin/role-permission/" + id, {
        method: "DELETE",
      }).then(() => {
        const newRoles = roles.filter((role) => role.id != id);
        setRoles(newRoles);
      });
    },
    [roles, setRoles]
  );

  const onEditRole = React.useCallback(
    (role) => {
      setShowDialog(true);
      dispatch({ type: ACTION.SET, value: role });
    },
    [setShowDialog, dispatch]
  );

  const onCreateRole = React.useCallback(() => {
    setShowDialog(true);
    dispatch({ type: ACTION.SET, value: DEFAULT_ROLE });
  }, [setShowDialog, dispatch]);

  const onSubmit = React.useCallback((e) => {
    e.preventDefault();
    const { id, role, permission, default_route } = dialogRole;
    const payload = { role, permission, default_route };
    // if there is an id, the role was edited
    const method = id ? "PUT" : "POST";
    request("settings/admin/role-permission/" + id, {
      method,
      body: JSON.stringify(payload),
    })
      .then((newRole) => {
        const newRoles = id
          ? roles.map((role) => (role.id != id ? role : newRole))
          : [...roles, newRole];
        setRoles(newRoles);
        setShowDialog(false);
      })
      .catch(console.error);
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Permissions",
        accessor: "permission",
        Cell: ({ cell: { value } }) =>
          value.map((val) => (
            <Badge
              key={val.resource + "." + val.permission}
              bg="light"
              text="dark"
            >
              {
                permissionTypes?.find((permission) =>
                  isEqual(permission.value, val)
                )?.label
              }
            </Badge>
          )),
      },
      {
        Header: "Default Route",
        accessor: "default_route",
        Cell: ({ cell: { value } }) => <Link to={value}>{value}</Link>,
      },
      {
        Header: "Manage",
        id: "manage",
        accessor: "id",
        Cell: ({ row: { original } }) => (
          <div className="position-relative">
            <GlyphButton
              glyph="edit"
              variant="link"
              size="sm"
              onClick={() => onEditRole(original)}
            />
            <ConfirmDeleteButton
              size="sm"
              onClick={() => onDeleteRole(original)}
            />
          </div>
        ),
        canResize: false,
      },
    ],
    [onEditRole, onDeleteRole]
  );
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogRole, dispatch] = React.useReducer(
    defaultActionReducer,
    DEFAULT_ROLE
  );

  return (
    <div>
      <RoleModal
        role={dialogRole}
        onChange={(value) => dispatch({ type: ACTION.MERGE, value })}
        show={showDialog}
        onHide={() => setShowDialog(false)}
        onSave={onSubmit}
      />

      <Row>
        <Col xs>
          <h1>Manage Roles</h1>
        </Col>
        <Col xs="auto" className="my-auto">
          <Button variant="outline-secondary" onClick={onCreateRole}>
            Create new role
          </Button>
        </Col>
      </Row>

      <Table columns={columns} data={roles} />
    </div>
  );
}

Roles.propTypes = {
  roles: PropTypes.array,
  setRoles: PropTypes.func,
};

export default Roles;
