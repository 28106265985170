import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

export interface Option {
  label: string;
  value: string;
}

const OPTIONS: Option[] = [
  { label: "Matched first", value: "matchedFirst" },
  { label: "Matched only", value: "matchedOnly" },
  { label: "Unmatched only", value: "unmatchedOnly" },
];

export const MatchedOrUnmatchedPicker = () => {
  const [matched, setMatched] = useQueryParam("matched", StringParam);
  return (
    <>
      <Form.Label htmlFor="program-matches-matched-or-unmatched">
        Matched / unmatched
      </Form.Label>
      <Form.Select
        id="program-matches-matched-or-unmatched"
        value={matched || ""}
        onChange={(e) => setMatched(e.target.value)}
      >
        {OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
