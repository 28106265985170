import classNames from "classnames";
import React, { useState, useCallback } from "react";
import { Button } from "react-bootstrap";

/**
 * Wraps any number of CMS Card components in a responsive grid view
 */
function CmsCardContainer({
  children,
  className,
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={classNames("cms-card-container", className)} {...rest}>
      {children}
    </div>
  );
}

export default CmsCardContainer;

/**
 * Lazy-loading version of CmsCardContainer.
 *
 * Render maxCards CMS cards in a CmsCardContainer with a "Show more"
 * button below the container to load more cards.
 *
 * Each card is rendered using the renderCard() prop which is passed the
 * card ID as a single argument and should return a rendered CMS card.
 */
export const LazyCmsCardContainer = ({
  className,
  maxCards = 48,
  ids,
  renderCard,
  ...rest
}: {
  className?: string;
  maxCards?: number;
  ids: number[] | string[];
  renderCard: (id: string | number) => React.ReactNode;
}) => {
  const [lastCardIndex, setLastCardIndex] = useState(
    Math.min(maxCards, ids.length)
  );
  const visibleCardIds = ids.slice(0, lastCardIndex);
  const isShowMoreButtonVisible = lastCardIndex < ids.length;
  const showMore = useCallback(
    () => setLastCardIndex((prev) => Math.min(ids.length, prev + maxCards)),
    [ids, maxCards]
  );

  return (
    <>
      <CmsCardContainer className={className} {...rest}>
        {visibleCardIds.map(renderCard)}
      </CmsCardContainer>
      {isShowMoreButtonVisible && (
        <div className="d-flex justify-content-center">
          <Button onClick={showMore} className="m-2 w-50">
            Show more
          </Button>
        </div>
      )}
    </>
  );
};
