import React, { useState } from "react";
import { InputGroup, Form, Button, Spinner } from "react-bootstrap";

function StringConfigurationValue({ value, submit, isLoading }) {
  const [currentValue, setCurrentValue] = useState(value);
  const isDirty = currentValue !== value;

  return (
    <InputGroup>
      <Form.Control
        type="text"
        size="sm"
        value={value}
        onChange={(e) => setCurrentValue(e.target.value)}
        disabled={isLoading}
      />
      <InputGroup.Append>
        <Button
          variant="primary"
          size="sm"
          onClick={() => submit(currentValue)}
          disabled={!isDirty || isLoading}
        >
          {isLoading ? (
            <Spinner
              animation="border"
              size="sm"
              role="loading"
              aria-hidden="true"
            />
          ) : (
            "Submit"
          )}
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default StringConfigurationValue;
