import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Genres } from "client/types";
import { useGenreOptions, useSelectedGenres } from "./hooks";
import { getGenreOptionLabel, onGenresChangeFactory } from "./utils";

interface Props {
  onChange: (selected: Genres) => void;
  excludedGenresValue: string[];
  language: string;
  readOnly: boolean;
  genreCounts?: { [genre: string]: number };
}

export const ExcludedGenresPicker = ({
  onChange,
  excludedGenresValue,
  language,
  readOnly,
  genreCounts,
}: Props) => {
  const genreOptions = useGenreOptions(language, genreCounts);
  const selected = useSelectedGenres(
    excludedGenresValue,
    genreOptions,
    language
  );
  const onExcludedGenresChange = onGenresChangeFactory(onChange);
  return (
    <>
      <Form.Label>Exclude genres</Form.Label>
      <Typeahead
        multiple
        id="collection-excluded-genre-picker"
        selected={selected}
        options={genreOptions}
        labelKey={getGenreOptionLabel}
        placeholder="Excluded genre filter"
        onChange={onExcludedGenresChange}
        className="mb-3"
        disabled={readOnly}
      />
    </>
  );
};
