import React, { ChangeEventHandler } from "react";
import { Form } from "react-bootstrap";

interface AssetCountSelectorProps {
  value: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  readOnly: boolean;
}

export const AssetCountSelector = ({
  value,
  onChange,
  readOnly,
}: AssetCountSelectorProps) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor="collection-filters-asset-count-selector">
        Number of assets in collection
      </Form.Label>
      <Form.Control
        type="number"
        id="collection-filters-asset-count-selector"
        value={value || ""}
        onChange={onChange}
        disabled={readOnly}
      />
    </Form.Group>
  );
};
