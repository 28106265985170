import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { useUser } from "client/hooks";
import { humanize } from "client/utils";
import MediaContentDialog from "components/MediaContentDialog";
import { Spinner } from "components/Spinner";
import { EditorHeaderToolbar } from "components/toolbar/EditorHeaderToolbar";
import FooterToolbar from "components/toolbar/FooterToolbar";
import { CollectionView } from "./CollectionView";
import { useCollection } from "./hooks";
import { Collection } from "./types";
import { CollectionOnChangeHandlers, isValidCollection } from "./utils";

interface CollectionEditorInnerProps {
  collection: Collection;
  save: () => Promise<unknown>;
  onChangeHandlers: CollectionOnChangeHandlers;
  isLoading: boolean;
  mode: string | undefined;
  id: string | undefined;
  isEditor: boolean;
}

// Exported for testing only
export function CollectionEditorInner({
  collection,
  save,
  onChangeHandlers,
  isLoading,
  mode,
  id,
  isEditor,
}: CollectionEditorInnerProps) {
  const navigate = useNavigate();
  const [isAssetDialogOpen, setIsAssetDialogOpen] = useState(false);

  const cancel = useCallback(() => navigate(-1), [navigate]);

  const goToListingPage = useCallback(() => {
    navigate("/collections");
  }, [navigate]);

  const saveCollection = useCallback(() => {
    save()
      .then(() => {
        const action = id ? "updated" : "created";
        toast.success(`Collection was ${action}`);
        goToListingPage();
      })
      .catch((err) => {
        const action = id ? "update" : "create";
        toast.error(`Failed to ${action} collection: ${err.message}`);
      });
  }, [save]);

  const showAssetDialog = useCallback(
    () => setIsAssetDialogOpen(true),
    [setIsAssetDialogOpen]
  );
  const hideAssetDialog = useCallback(
    () => setIsAssetDialogOpen(false),
    [setIsAssetDialogOpen]
  );

  const onAddCollectionContent = useCallback(
    (addedIds: number[]) => {
      const existingIds = collection.mediaContents.map((mc) => mc.id);
      const addedMcs = addedIds
        .filter((id) => !existingIds.includes(id))
        .map((id) => ({ id }));
      const mediaContents = [...collection.mediaContents, ...addedMcs];
      onChangeHandlers.mediaContents(mediaContents);
      hideAssetDialog();
    },
    [collection.mediaContents, onChangeHandlers.mediaContents, hideAssetDialog]
  );

  if (isLoading) {
    return (
      <>
        <EditorHeaderToolbar
          title={`Edit collection (id: ${id}) | Loading...`}
          isValid={false}
          onCancel={cancel}
        />
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <Spinner className="align-middle" />
        </div>
      </>
    );
  }

  const isValid = isValidCollection(collection);
  const headerTitle =
    (id === undefined
      ? "Create new collection"
      : `${humanize(mode || "")} collection (id: ${id})`) +
    (collection.title ? ` | ${collection.title}` : "");

  return (
    <>
      <EditorHeaderToolbar
        title={headerTitle}
        isValid={isEditor && isValid}
        onCancel={cancel}
        onSave={saveCollection}
      />
      <div className="container justify-content-around">
        <CollectionView
          collection={collection}
          onChangeHandlers={onChangeHandlers}
          showAssetDialog={showAssetDialog}
          readOnly={!isEditor}
        />
        {isAssetDialogOpen && (
          <MediaContentDialog
            show={isAssetDialogOpen}
            multiple={true}
            onSelect={onAddCollectionContent}
            onClose={hideAssetDialog}
            initialState={{ excludeKinds: ["season", "episode"] }}
          />
        )}
      </div>
      <FooterToolbar
        isValid={isEditor && isValid}
        onSave={saveCollection}
        onCancel={cancel}
      />
    </>
  );
}

export default function CollectionEditor() {
  const { mode, id } = useParams();
  const user = useUser();
  const isEditor = user.hasPermissionTo("edit", "publication");
  return (
    <CollectionEditorInner
      {...useCollection(id)}
      mode={mode}
      id={id}
      isEditor={isEditor}
    />
  );
}
