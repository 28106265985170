import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { ColProps } from "react-bootstrap";

interface Props extends ColProps {
  children: React.ReactNode;
  as?: React.ElementType;
  title?: string;
}

export const Truncate = ({
  children,
  as,
  title,
  className,
  ...rest
}: Props) => {
  if (!title && _.isString(children)) {
    title = children;
  }
  const Component: React.ElementType = as ? as : "div";

  return (
    <Component
      className={classNames("text-truncate", className)}
      title={title}
      {...rest}
    >
      {children}
    </Component>
  );
};
