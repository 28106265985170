import classNames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { CmsPure } from "client/utils";
import { getGlyphClassName, GLYPHS } from "components/buttons/glyphs";

class SideMenuLink extends CmsPure {
  render() {
    const { children, className, glyph, selected, ...rest } = this.props;
    return (
      <Link className={classNames(className, selected && "selected")} {...rest}>
        <div className="side-menu-item my-1 py-1 px-2">
          {glyph && (
            <span
              className={getGlyphClassName(glyph) + " ms-n3 me-1 help-block"}
            />
          )}
          {children}
        </div>
      </Link>
    );
  }
}
SideMenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  glyph: PropTypes.string,
};

class SideMenuNavLink extends CmsPure {
  render() {
    const { children, glyph, ...rest } = this.props;
    return (
      <NavLink activeClassName="selected" {...rest}>
        <div className="side-menu-item my-1 py-1 px-4">
          {glyph && (
            <span
              className={getGlyphClassName(glyph) + " ms-n3 me-1 help-block"}
            />
          )}
          {children}
        </div>
      </NavLink>
    );
  }
}
SideMenuNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  glyph: PropTypes.string,
};

class SideMenuHeader extends CmsPure {
  render() {
    const { children, className, ...rest } = this.props;
    return (
      <div className={classNames("fw-bold", className)} {...rest}>
        {children}
      </div>
    );
  }
}
SideMenuHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

class SideMenuAddItem extends CmsPure {
  render() {
    const { children, className, ...rest } = this.props;
    return (
      <div
        className={classNames(
          "side-menu-item my-1 py-1 ps-0 pe-4 cursor-pointer",
          className
        )}
        {...rest}
      >
        <div
          className={GLYPHS.ADD + " text-center help-block"}
          style={{ width: "1.5rem" }}
        />
        {children}
      </div>
    );
  }
}
SideMenuAddItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

class SideMenu extends CmsPure {
  static Link = SideMenuLink;
  static NavLink = SideMenuNavLink;
  static Header = SideMenuHeader;
  static AddItem = SideMenuAddItem;

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <div
        className={classNames("col-md-auto user-select-none mx-4", className)}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

export default SideMenu;
