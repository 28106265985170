import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { GenreMultiPicker } from "components/pickers/GenreMultiPicker";
import AnnotatedControl from "./AnnotatedControl";
import { KeywordsMultiPicker } from "./KeywordsMultiPicker";
import { useArrayField } from "./hooks";

const LocalizedMetadataEditor = ({
  isLoading,
  applyGenresToChildren,
  language,
  data,
  dispatch,
}) => {
  const setTitle = useCallback(
    (title) =>
      dispatch({
        type: "mc.title.update",
        payload: {
          language,
          value: title,
        },
      }),
    [language, data, dispatch]
  );

  const setStoryline = useCallback(
    (value, key) =>
      dispatch({
        type: "mc.storyline.update",
        payload: { language, value, key },
      }),
    [language, data, dispatch]
  );

  const setApplyGenresToChildren = useCallback(
    (e) =>
      dispatch({
        type: "editor.applyGenresToChildren.updated",
        payload: { applyGenresToChildren: e.target.checked },
      }),
    [dispatch]
  );

  const genres = useArrayField(data, "genres", language, dispatch);
  const keywords = useArrayField(data, "keywords", language, dispatch);

  return (
    <>
      <AnnotatedControl
        label="Title"
        type="text"
        field={data.titles[language]}
        onChange={setTitle}
        disabled={isLoading}
        placeholder="No entry for this language"
      />
      <AnnotatedControl
        label="Storyline (medium)"
        as="textarea"
        field={(data.storylines ? data.storylines[language] : {})?.medium}
        onChange={(value) => setStoryline(value, "medium")}
        disabled={isLoading}
        placeholder="No entry for this language"
      />
      <AnnotatedControl
        label="Storyline (long)"
        as="textarea"
        field={(data.storylines ? data.storylines[language] : {})?.long}
        onChange={(value) => setStoryline(value, "long")}
        disabled={isLoading}
        placeholder="No entry for this language"
      />
      <AnnotatedControl
        label="Genres"
        id={`genre-picker-${language}`}
        field={genres.field}
        component={GenreMultiPicker}
        language={language}
        placeholder="No genres for this language"
        onChange={genres.onChange}
        disabled={isLoading}
        className="mb-1"
      />
      <div>
        <Form.Label className="d-flex mb-0">
          <Form.Check
            checked={applyGenresToChildren}
            onChange={setApplyGenresToChildren}
            className="me-2"
          />
          Also apply genres to all children?
        </Form.Label>
        {applyGenresToChildren && (
          <div className="text-danger">
            <strong>Note:</strong> Applying genres to all children will
            overwrite any existing genres for underlying seasons and/or
            episodes.
          </div>
        )}
      </div>
      <AnnotatedControl
        label="Keywords"
        id={`keywords-picker-${language}`}
        field={keywords.field}
        component={KeywordsMultiPicker}
        placeholder="No keywords for this language"
        onChange={keywords.onChange}
        disabled={isLoading}
      />
    </>
  );
};

LocalizedMetadataEditor.defaultProps = {
  data: {
    titles: {},
    storylines: {},
  },
};

export default LocalizedMetadataEditor;
