import React, { useCallback, useReducer, useState } from "react";
import { Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { mergeReducer } from "client/hooks";
import AssetSelectorDialog from "components/AssetSelectorDialog";
import Rearrangable from "components/Rearrangable";
import { CmsCardContainer, EmptyCmsCard } from "components/cards";
import { ToggleControlWithLabels } from "components/controls/ToggleControl";
import { AnnotatedContentCard } from "./AnnotatedContentCard";
import { LayoutModeToggle } from "./LayoutModeToggle";
import { LayoutOptionsExplainer } from "./LayoutOptionsExplainer";
import { RowTypeToggle } from "./RowTypeToggle";
import {
  Collection,
  CollectionOnChangeHandlers,
  MediaCollectionCardContentType,
  MediaCollectionItem,
} from "./types";

const PERSONALIZE_OPTIONS = [
  { label: "Personalized", value: true },
  { label: "Fixed", value: false },
];

interface CollectionContentCardsProps {
  collection: Collection;
  onChangeHandlers: CollectionOnChangeHandlers;
  readOnly: boolean;
  showAssetDialog: () => void;
}

export function CollectionContentCards({
  collection,
  onChangeHandlers,
  readOnly,
  showAssetDialog,
}: CollectionContentCardsProps) {
  const [assetModal, setAssetModal] = useReducer(mergeReducer, {
    show: false,
    id: "",
    mcId: "",
    isAsset: false,
  });

  const onClickMcOrAsset = useCallback(
    (id: number, isAsset: boolean, mcId: number) =>
      setAssetModal({ show: true, id, isAsset, mcId }),
    []
  );

  const onDeleteMediaContentOrAsset = () => {
    const id = assetModal.id || assetModal.mcId;
    setAssetModal({ show: false });
    if (assetModal.isAsset) {
      onChangeHandlers.assets(
        collection.assets.filter((asset) => asset.id != id)
      );
    } else {
      onChangeHandlers.mediaContents(
        collection.mediaContents.filter((mc) => mc.id != id)
      );
    }
  };

  const onChangeAsset = (newId: number | string) => {
    if (newId && assetModal.isAsset) {
      // from asset to another asset
      const oldAssetId = assetModal.id;
      onChangeHandlers.assets(
        collection.assets.map((asset) =>
          asset == oldAssetId ? { id: Number(newId) } : asset
        )
      );
    } else if (!newId && !assetModal.isAsset) {
      // from MC to MC, no change
    } else if (newId && !assetModal.isAsset) {
      // from MC to asset
      const oldMcId = assetModal.id;

      onChangeHandlers.mediaContents(
        collection.mediaContents.filter((mc) => mc.id != oldMcId)
      );

      const assetIds = collection.assets.map((asset) => asset.id);
      const newAssetId = Number(newId);
      const newAssets = [...collection.assets];

      if (!assetIds.includes(newAssetId)) {
        newAssets.push({ id: newAssetId });
      }
      onChangeHandlers.assets(newAssets);
    } else if (!newId && assetModal.isAsset) {
      // from asset to MC
      const oldAssetId = assetModal.id;

      onChangeHandlers.assets(
        collection.assets.filter((asset) => asset.id != oldAssetId)
      );

      const mcId = Number(assetModal.mcId);
      const mediaContentIds = collection.mediaContents.map((mc) => mc.id);
      const newMediaContents = [...collection.mediaContents];

      if (!mediaContentIds.includes(mcId)) {
        newMediaContents.push({ id: mcId });
      }
      onChangeHandlers.mediaContents(newMediaContents);
    } else {
      console.warn("Unknown onChangeAsset event", newId, assetModal);
    }
    setAssetModal({ show: false });
  };

  const getMediaCollectionItems = useCallback(
    (contentType: MediaCollectionCardContentType) => {
      switch (contentType) {
        case "asset":
          return [...collection.assets];
        case "mediaContent":
          return [...collection.mediaContents];
      }
    },
    [collection.assets, collection.mediaContents]
  );

  const getMediaCollectionOnChangeHandler = useCallback(
    (contentType: MediaCollectionCardContentType) => {
      switch (contentType) {
        case "asset":
          return onChangeHandlers.assets;
        case "mediaContent":
          return onChangeHandlers.mediaContents;
      }
    },
    [onChangeHandlers.assets, onChangeHandlers.mediaContents]
  );

  const onChangeContentMetadata = useCallback(
    (
      id: number,
      contentType: MediaCollectionCardContentType,
      fields: Omit<MediaCollectionItem, "id">
    ) => {
      const items = getMediaCollectionItems(contentType);
      const index = items.findIndex((item) => item.id === id);
      const item = items[index];

      if (item) {
        const newItem = { ...item, ...fields };
        if (newItem.title === "") {
          delete newItem.title;
        }
        if (newItem.storyline === "") {
          delete newItem.storyline;
        }

        items[index] = newItem;

        const onChangeHandler = getMediaCollectionOnChangeHandler(contentType);
        onChangeHandler(items);
      }
    },
    [getMediaCollectionItems, getMediaCollectionOnChangeHandler]
  );

  const [editContentMetadata, setEditContentMetadata] = useState(false);

  const onChangeEditContentMetadata = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setEditContentMetadata(e.target.checked),
    [setEditContentMetadata]
  );

  return (
    <>
      <Row className="mb-1">
        <Col xs="auto" as={FormGroup}>
          <LayoutModeToggle
            autoOrientation={collection.autoOrientation}
            onChange={onChangeHandlers.autoOrientation}
          />
        </Col>
        <Col>
          <RowTypeToggle
            rowType={collection.rowType}
            disabled={collection.autoOrientation}
            onChange={onChangeHandlers.rowType}
          />
        </Col>
        <Col xs="auto" as={FormGroup}>
          <Form.Label>Card order</Form.Label>
          <ToggleControlWithLabels
            value={collection.personalizeOrder}
            options={PERSONALIZE_OPTIONS}
            onChange={onChangeHandlers.personalizeOrder}
          />
        </Col>
      </Row>
      <LayoutOptionsExplainer autoOrientation={collection.autoOrientation} />
      <AssetSelectorDialog
        show={assetModal.show}
        selectedId={assetModal.isAsset ? assetModal.id : null}
        mcId={assetModal.mcId}
        onChange={onChangeAsset}
        onDelete={onDeleteMediaContentOrAsset}
        onClose={() => setAssetModal({ show: false })}
      />
      <div className="d-flex justify-content-between">
        <FormLabel className="lead">Media Content in Collection</FormLabel>
        <div className="d-flex">
          <Form.Label htmlFor="edit-content-metadata" className="me-2">
            Edit titles and storylines?
          </Form.Label>
          <Form.Switch
            id="edit-content-metadata"
            checked={editContentMetadata}
            onChange={onChangeEditContentMetadata}
          />
        </div>
      </div>
      <CmsCardContainer>
        {collection.mediaContents.map((mc, index) => (
          <Rearrangable
            key={mc.id}
            index={index}
            type="mediaContent"
            onMove={onChangeHandlers.moveMediaContents}
          >
            <AnnotatedContentCard
              key={mc.id}
              id={mc.id}
              editContentMetadata={editContentMetadata}
              editorialTitle={mc.title}
              editorialStoryline={mc.storyline}
              contentType="mediaContent"
              onChange={onChangeContentMetadata}
              onClick={onClickMcOrAsset}
              readOnly={readOnly}
            />
          </Rearrangable>
        ))}
        {!readOnly && (
          <EmptyCmsCard
            onClick={showAssetDialog}
            canClick
            variant="landscape"
          />
        )}
      </CmsCardContainer>
      <FormLabel className="lead mt-4">Assets in Collection</FormLabel>

      <CmsCardContainer>
        {collection.assets.map((asset, index) => (
          <Rearrangable
            key={asset.id}
            index={index}
            type="asset"
            onMove={onChangeHandlers.moveAssets}
          >
            <AnnotatedContentCard
              key={asset.id}
              id={asset.id}
              editContentMetadata={editContentMetadata}
              editorialTitle={asset.title}
              editorialStoryline={asset.storyline}
              contentType="asset"
              onChange={onChangeContentMetadata}
              onClick={onClickMcOrAsset}
              readOnly={readOnly}
            />
          </Rearrangable>
        ))}
      </CmsCardContainer>

      <div className="help-block">
        Click on media content and select an asset to refer to specific assets
        in a collection
      </div>
    </>
  );
}
