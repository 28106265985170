import { PropTypes } from "prop-types";
import React from "react";
import { FormLabel, FormGroup } from "react-bootstrap";
import Select from "react-select";
import { CmsPure, request } from "client/utils";
import {
  getDownloadPrefix,
  getProvisionerPickerEndpoint,
  getOptionId,
  formatOptionLabel,
  formatGroupLabel,
  filterOption,
} from "../provisioningUtils";
import ProvisionerAccordion from "./ProvisionerAccordion";

class ProvisionerIdPicker extends CmsPure {
  state = { allOptions: [], fetching: true };

  componentDidMount() {
    this.componentDidUpdate({});
  }

  componentDidUpdate(prevProps) {
    const { contentType } = this.props;
    if (prevProps.contentType === contentType) return;

    // update options and the label function when passed a new contentType
    this.fetchAllOptions();
    this.formatOptionLabel = formatOptionLabel(contentType);
    this.formatGroupLabel = formatGroupLabel(contentType);
    this.filterOption = filterOption(contentType);
  }

  fetchAllOptions() {
    const contentType = this.props.contentType;
    const endpoint = getProvisionerPickerEndpoint(contentType);

    request(endpoint)
      .then((res) =>
        this.setState({
          allOptions: [{ contentType, options: res.data.items }],
          fetching: false,
        })
      )
      .catch((err) => {
        this.setState({ fetching: false });
        console.error(endpoint, err);
      });
  }

  fetchById = async (id) => {
    const endpoint = getProvisionerPickerEndpoint(this.props.contentType);

    return request(endpoint + "/" + id)
      .then((res) => res.data)
      .catch((err) => console.error(endpoint, err));
  };

  onSelect = (selection) => {
    const { onSelect } = this.props;

    const id = getOptionId(selection);
    onSelect(id);
  };

  getSelection = () => {
    const { selectedId } = this.props;
    if (!this.state.allOptions.length) return [];
    const options = this.state.allOptions[0].options;
    // if selectedItem is undefined, the accordion will render a disabled row
    return [options.find((val) => getOptionId(val) === selectedId)];
  };

  render() {
    const { contentType, isMulti, isClearable } = this.props;
    const { allOptions, fetching } = this.state;
    const selection = this.getSelection();
    const multiSuffix = isMulti ? "s" : "";
    return (
      <FormGroup className="my-2">
        <FormLabel>{contentType + multiSuffix}</FormLabel>
        <Select
          closeMenuOnSelect
          isDisabled={fetching}
          isClearable={isClearable}
          isMulti={false}
          components={{
            SingleValueLabel: this.formatOptionLabel,
          }}
          placeholder={
            fetching ? "Loading..." : "Select " + contentType + multiSuffix
          }
          options={allOptions}
          value={selection}
          getOptionValue={getOptionId}
          formatOptionLabel={this.formatOptionLabel}
          formatGroupLabel={this.formatGroupLabel}
          filterOption={this.filterOption}
          onChange={this.onSelect}
        />
        <ProvisionerAccordion
          selections={selection}
          fetchById={this.fetchById}
          downloadPrefix={getDownloadPrefix(contentType)}
          labelFunction={this.getOptionLabel}
          isMulti={false}
        />
      </FormGroup>
    );
  }
}

ProvisionerIdPicker.propTypes = {
  contentType: PropTypes.oneOf([
    "Config Deployment",
    "Config Draft",
    "Config Publication",
    "DVB Cable Tuning Plan",
    "DVB Cable AES Tuning Plan",
    "DVB Cable Stream",
    "DVB IPTV FCC",
    "DVB IPTV Tuning Plan",
    "DVB IPTV AES Tuning Plan",
    "DVB IPTV Stream",
    "DVB Triplet",
    "Lineup",
    "TV Provider",
    "TV Provider Placement",
    "VOD Provider",
  ]).isRequired,
  selectedId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default ProvisionerIdPicker;
