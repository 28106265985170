// exported for tests
import { DateTime, Duration } from "luxon";
import React, { ReactNode } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom-v5-compat";
import { useChannels } from "client/hooks";
import { AssetDetailsResponse, Availabilities } from "./types";

const AVAILABILITIES_ORDER = [
  "linear",
  "catchUp",
  "npvr",
  "external",
  "subscription",
  "rent",
  "buy",
];

const Row = ({ field, value }: { field: string; value: ReactNode }) => (
  <tr>
    <td>{field}</td>
    <td>{value}</td>
  </tr>
);

interface Props {
  data: AssetDetailsResponse;
}

export const AssetTable = ({ data }: Props) => {
  const { channelMap } = useChannels();

  const fields: [string, ReactNode][] = [
    ["id", data.id],
    ["programUrl", data.external_metadata.programUrl],
    [
      "content id",
      <Link
        key={0}
        to={`/enrich/mediacontent/editor/${data.MC.id}`}
        style={{ textDecoration: "underline" }}
      >
        {data.MC.id}
      </Link>,
    ],
    [
      "channel",
      `${channelMap[data.external_metadata.channel]} (${data.external_metadata.channel})`,
    ],
    ["updateId", data.external_metadata.updateId],
    ["sourceCrid / broadcastId", data.external_metadata.broadcastId],
    ...availabilityRows(data.external_metadata.availabilities),
    [
      "catchup",
      data.external_metadata.restrictions?.allowCatchup !== undefined
        ? "true"
        : "false",
    ],
    ["live", data.external_metadata.live ? "true" : "false"],
    ["repeat", data.external_metadata.repeat ? "true" : "false"],
    ["premiere", data.external_metadata.premiere ? "true" : "false"],
  ];

  if (data.external_metadata.availabilities?.linear)
    fields.push(["recordings", data.number_of_recordings]);

  if (data.recording_id) fields.push(["recordingId", data.recording_id]);

  return (
    <Table striped hover className="condensed" style={{ marginBottom: 0 }}>
      <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(([field, value], index) => (
          <Row key={index} field={field} value={value} />
        ))}
      </tbody>
    </Table>
  );
};

const availabilityRows = (
  availabilities: Availabilities
): [string, ReactNode][] => {
  const availabilityRows = Object.entries(availabilities)
    .sort(([keyA], [keyB]) => {
      return (
        AVAILABILITIES_ORDER.indexOf(keyA) - AVAILABILITIES_ORDER.indexOf(keyB)
      );
    })
    .flatMap(([availabilityKey, availabilityValue]): [string, ReactNode][] => {
      const { start, duration, valid } = availabilityValue;
      return [
        [
          `${availabilityKey} availability`,
          `${DateTime.fromISO(start).toFormat(
            "yyyy-MM-dd HH:mm:ss"
          )} -> ${DateTime.fromISO(start)
            .plus(Duration.fromISO(duration))
            .toFormat("yyyy-MM-dd HH:mm:ss")} ${
            typeof valid === "boolean" ? (valid ? "VALID" : "INVALID") : ""
          }`,
        ],
        [`${availabilityKey} availability duration`, duration],
      ];
    });

  return availabilityRows;
};
