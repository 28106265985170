import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

const TYPE_OPTIONS: { value: string; label: string }[] = [
  { value: "all", label: "All" },
  { value: "gap", label: "Gap" },
  { value: "overlap", label: "Overlap" },
];
export const TypeFilter = () => {
  const [type, setType] = useQueryParam("type", StringParam);
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "all") {
      setType(null);
    } else {
      setType(e.target.value);
    }
  };
  return (
    <>
      <Form.Label htmlFor="type">Gap / overlap</Form.Label>
      <Form.Select id="type" value={type || "all"} onChange={onChange}>
        {TYPE_OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
