import React from "react";
import useSWR from "swr";
import { ProgramDetails } from "../ProgramDetails/ProgramDetails";
import { DetailsModal } from "../ProgramMatch/DetailsModal";
import { OneToOneProgramMatch } from "../ProgramMatch/OneToOneProgramMatch";
import { PaginatedResponse, ProgramMatchDTO } from "../types";
import { ManyToOneProgramMatch } from "./ManyToOneProgramMatch";

interface Props {
  fromId?: string;
  toId: string;
  onClose: () => void;
}

export const ProgramMatch = ({ fromId, toId, onClose }: Props) => {
  return (
    <DetailsModal onClose={onClose}>
      <ProgramMatchModalContent fromId={fromId} toId={toId} />
    </DetailsModal>
  );
};

interface ProgramMatchModalContentProps {
  fromId?: string;
  toId: string;
}

const ProgramMatchModalContent = ({
  fromId,
  toId,
}: ProgramMatchModalContentProps) => {
  const { data, mutate, error } = useSWR<PaginatedResponse<ProgramMatchDTO>>(
    `epg/match/list?ids=${toId}`
  );
  const path = "/epg/matches";

  if (!data && error) {
    return (
      <>
        <h2>Error</h2>
        <div>Status: {error.status}</div>
        <div>Status text: {error.statusText}</div>
        <div>
          Data:{" "}
          {typeof error.data === "object"
            ? JSON.stringify(error.data)
            : error.data}
        </div>
      </>
    );
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  if (!data.results) {
    return (
      <div>
        Error: <span>{data.toString()}</span>
      </div>
    );
  }

  if (fromId && data.results.length === 0) {
    return <ProgramDetails id={fromId} database={"METADB"} />;
  }

  if (fromId && data.results.length < 2) {
    return (
      <OneToOneProgramMatch
        matchId={data.results[0].id}
        fromId={fromId}
        toId={toId}
        path={path}
      />
    );
  }

  const fromMatches = data.results
    .map((result) => ({
      from: result.from,
      matchId: result.id,
      isDominant: result.isDominant,
    }))
    .sort((a, b) => b.from.localeCompare(a.from));
  return (
    <ManyToOneProgramMatch
      fromMatches={fromMatches}
      selectedFromId={fromId}
      toId={toId}
      mutate={mutate}
      path={path}
    />
  );
};
