import React, { useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { Asset } from "../types";
import ActionModal from "./ActionModal";
import { AssetActionButtons } from "./AssetActionButtons";
import { RevisionActionButtons } from "./RevisionActionButtons";
import { RequestAction } from "./actions";

interface Props {
  assets: Asset[];
}

export default function BulkActions({ assets }: Props) {
  const [action, setAction] = useState<RequestAction | null>(null);
  const closeModal = useCallback(() => setAction(null), [setAction]);

  return (
    <div>
      <Row>
        <Col>
          <h3 className="lead">Revision actions</h3>
          <RevisionActionButtons
            onClick={setAction}
            disabled={assets.length === 0}
          />
        </Col>
        <Col>
          <h3 className="lead">Asset actions</h3>
          <AssetActionButtons
            onClick={setAction}
            disabled={assets.length === 0}
          />
        </Col>
      </Row>
      {action && (
        <ActionModal assets={assets} action={action} onHide={closeModal} />
      )}
    </div>
  );
}
