import { DateTime } from "luxon";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Column } from "react-table-v7";
import useSWR from "swr";
import { StringParam, useQueryParam } from "use-query-params";
import Table from "components/tables";
import { DateBasedPagination } from "../DateBasedPagination";
import InsightToolbar from "../InsightToolbar";
import { useFrom, useTo } from "../hooks/useFromAndTo";
import { TypeFilter } from "./TypeFilter";

interface GapStats {
  firstData: string;
  counts: GapCount[];
}

interface GapCount {
  date_trunc: string;
  open: number;
  closed: number;
  total: number;
}

interface GapCountWithId extends GapCount {
  id: string;
}

const columns: Column<GapCountWithId>[] = [
  {
    Header: "Date",
    accessor: (row) => DateTime.fromISO(row.date_trunc).toFormat("yyyy-MM-dd"),
  },
  {
    Header: "Open count",
    accessor: (row) => row.open.toLocaleString("NO"),
  },
  {
    Header: "Closed count",
    accessor: (row) => row.closed.toLocaleString("NO"),
  },
  {
    Header: "Total count",
    accessor: (row) => row.total.toLocaleString("NO"),
  },
];

export const GapOverlapStats = () => {
  const searchParams = useSearchParams();
  const { data } = useSWR<GapStats>(
    `insight/gap-stats?${searchParams.toString()}`
  );
  const gapCountWithId = data?.counts
    ? data.counts.map(
        (count): GapCountWithId => ({ ...count, id: count.date_trunc })
      )
    : undefined;
  return (
    <>
      <InsightToolbar />
      <div className="container-fluid">
        <Filters />
        <Table columns={columns} data={gapCountWithId || []} />
        <DateBasedPagination
          firstData={data ? DateTime.fromISO(data.firstData) : undefined}
        />
      </div>
    </>
  );
};

const useSearchParams = () => {
  const { from } = useFrom();
  const { to } = useTo();
  const [type] = useQueryParam("type", StringParam);

  const searchParams = new URLSearchParams();
  searchParams.set("from", from.toFormat("yyyy-MM-dd"));
  searchParams.set("to", to.toFormat("yyyy-MM-dd"));
  if (type) {
    searchParams.set("type", type);
  }
  return searchParams;
};

const Filters = () => {
  return (
    <Row className="my-2">
      <Col xs={3}>
        <TypeFilter />
      </Col>
    </Row>
  );
};
