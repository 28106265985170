import {
  ContentType,
  DurationInterval,
  DurationMax,
  DurationMin,
} from "@telia-company/tv.no-play-cms-common/collection/types";
import React, { ChangeEvent } from "react";
import { Col, Form, Row } from "react-bootstrap";

const minOptions: DurationMin[] = ["0h", "1h", "1h30", "2h", "2h30", "3h"];

const maxOptions: DurationMax[] = ["1h", "1h30", "2h", "2h30", "3h", ">3h"];

const disabledMinOptionsForEachMaxValue: { [max: string]: DurationMin[] } = {
  "1h": ["1h", "1h30", "2h", "2h30", "3h"],
  "1h30": ["1h30", "2h", "2h30", "3h"],
  "2h": ["2h", "2h30", "3h"],
  "2h30": ["2h30", "3h"],
  "3h": ["3h"],
  ">3h": [],
};

const disabledMaxOptionsForEachMinValue: { [min: string]: DurationMax[] } = {
  "0h": [],
  "1h": ["1h"],
  "1h30": ["1h", "1h30"],
  "2h": ["1h", "1h30", "2h"],
  "2h30": ["1h", "1h30", "2h", "2h30"],
  "3h": ["1h", "1h30", "2h", "2h30", "3h"],
};

interface Props {
  durationInterval?: DurationInterval;
  selectedContentTypes: ContentType[];
  onChange: (durationInterval: DurationInterval) => void;
  readOnly: boolean;
}

export const ContentLengthPicker = ({
  durationInterval,
  selectedContentTypes,
  onChange,
  readOnly,
}: Props) => {
  if (
    selectedContentTypes.length !== 1 ||
    selectedContentTypes[0] !== "movie"
  ) {
    return null;
  }

  const min: DurationMin = durationInterval ? durationInterval.min : "0h";
  const max: DurationMax = durationInterval ? durationInterval.max : ">3h";
  const onMinChange = (event: ChangeEvent<HTMLSelectElement>) =>
    onChange({
      min: event.target.value as DurationMin,
      max,
    });
  const onMaxChange = (event: ChangeEvent<HTMLSelectElement>) =>
    onChange({
      min,
      max: event.target.value as DurationMax,
    });

  return (
    <Form.Group>
      <Row>
        <Col>
          <Form.Label htmlFor="collection-filters-min-duration">
            Min duration
          </Form.Label>
          <Form.Select
            id="collection-filters-min-duration"
            value={min}
            onChange={onMinChange}
            disabled={readOnly}
          >
            {minOptions.map((option) => (
              <option
                key={option}
                disabled={
                  disabledMinOptionsForEachMaxValue[max].indexOf(option) >= 0
                }
              >
                {option}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Label htmlFor="collection-filters-max-duration">
            Max duration
          </Form.Label>
          <Form.Select
            id="collection-filters-max-duration"
            value={max}
            onChange={onMaxChange}
            disabled={readOnly}
          >
            {maxOptions.map((option) => (
              <option
                key={option}
                disabled={
                  disabledMaxOptionsForEachMinValue[min].indexOf(option) >= 0
                }
              >
                {option}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
    </Form.Group>
  );
};
