import classNames from "classnames";
import { startCase } from "lodash";
import { PropTypes } from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const toOptionObject = (val) => ({ value: val, label: startCase(val) });

const ToggleControl = ({ allowed, onChange, value, ...rest }) => {
  const isSelected = useCallback((item) => value === item, [value]);
  const objects = useMemo(() => allowed.map(toOptionObject), [allowed]);

  return (
    <RenderControls
      {...rest}
      options={objects}
      isSelected={isSelected}
      onClick={onChange}
    />
  );
};

function RenderControls({ options, isSelected, onClick, className, ...rest }) {
  return (
    <ButtonGroup className={classNames("d-block", className)}>
      {options.map(({ value: curr, variant, label, ...other }) => (
        <Button
          key={curr}
          variant={isSelected(curr) ? variant || "info" : "light"}
          onClick={() => onClick(curr)}
          {...rest}
          {...other}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export const ToggleMultiControl = ({
  options,
  value = [],
  onChange,
  ...rest
}) => {
  const isSelected = useCallback((item) => value.indexOf(item) > -1, [value]);
  const onToggle = useCallback(
    (item) => {
      if (isSelected(item)) onChange(value.filter((e) => item !== e));
      else onChange([...value, item]);
    },
    [value, isSelected]
  );

  const objects = useMemo(() => options.map(toOptionObject), [options]);

  return (
    <RenderControls
      {...rest}
      options={objects}
      isSelected={isSelected}
      onClick={onToggle}
    />
  );
};

ToggleMultiControl.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export const ToggleControlWithLabels = ({
  options,
  onChange,
  value,
  ...rest
}) => {
  const isSelected = useCallback((item) => value === item, [value]);
  return (
    <RenderControls
      {...rest}
      options={options}
      isSelected={isSelected}
      onClick={onChange}
    />
  );
};

ToggleControl.propTypes = {
  allowed: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ToggleControlWithLabels.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired,
      variant: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

export const OrderControl = (props) => (
  <ToggleControlWithLabels
    options={[
      { value: "title", label: "A-Z" },
      { value: "date", label: "Jan-Dec" },
    ]}
    {...props}
  />
);
OrderControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(["date", "title"]).isRequired,
};

export default ToggleControl;
