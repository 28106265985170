import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { QueryParams } from "client/hooks/useContentEdits";
import {
  SingleSelectParamFilter,
  TextParamFilter,
} from "components/tables/Filters";

const LAST_CHANGE_OPTIONS = [
  {
    value: "",
    label: "Any",
  },
  {
    value: "editorialChanges",
    label: "Editorial",
  },
  {
    value: "upstreamChanges",
    label: "Provider",
  },
];

const SearchBar: React.FC<{
  params: QueryParams;
  setParams: (newParams: Partial<QueryParams>) => void;
}> = ({ params, setParams }) => {
  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col>
          <Form.Label>Editor</Form.Label>
          <TextParamFilter
            param={params["editor"]}
            setParam={(param: string) => setParams({ editor: param })}
          />
        </Col>
        <Col>
          <Form.Label>Last version</Form.Label>
          <SingleSelectParamFilter
            options={LAST_CHANGE_OPTIONS}
            param={params["status"]}
            setParam={(param: string) => setParams({ status: param })}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SearchBar;
