import _ from "lodash";
import { PropTypes } from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { CmsPure, request } from "client/utils";
import { GLYPHS } from "components/buttons/glyphs";
import {
  getDownloadPrefix,
  getProvisionerPickerEndpoint,
  getOptionId,
  formatOptionLabel,
  formatGroupLabel,
  filterOption,
} from "../provisioningUtils";
import ProvisionerAccordion from "./ProvisionerAccordion";

class ProvisionerIdMultiPicker extends CmsPure {
  state = {
    allOptions: [{ contentType: "", options: [] }],
    fetching: true,
    optionsTotal: 0,
  };

  componentDidMount() {
    this.componentDidUpdate({});
  }

  componentDidUpdate(prevProps) {
    const { contentType, allowedOptions } = this.props;
    if (
      prevProps.contentType === contentType &&
      _.isEqual(prevProps.allowedOptions, allowedOptions)
    )
      return;

    this.fetchAllOptions();
    this.formatOptionLabel = formatOptionLabel(contentType);
    this.formatGroupLabel = formatGroupLabel(contentType);
    this.filterOption = filterOption(contentType);
  }

  fetchAllOptions() {
    const contentType = this.props.contentType;
    const endpoint = getProvisionerPickerEndpoint(contentType);

    request(endpoint)
      .then((res) => {
        this.setState({
          allOptions: [
            {
              contentType,
              options: this.getOptions(res.data.items),
            },
          ],
          fetching: false,
          optionsTotal: res.data.items.length,
        });
      })
      .catch((err) => {
        this.setState({ fetching: false });
        console.error(endpoint, err);
      });
  }

  fetchById = async (id) => {
    const endpoint = getProvisionerPickerEndpoint(this.props.contentType);

    return request(endpoint + "/" + id)
      .then((res) => res.data)
      .catch((err) => console.error(endpoint, err));
  };

  onSelect = (selection) => {
    const { onSelect } = this.props;
    if (!selection) onSelect([]);
    else {
      const ids = selection.map(getOptionId);
      onSelect(ids);
    }
  };

  getOptions = (items) => {
    const { allowedOptions } = this.props;
    return allowedOptions
      ? items.filter((item) => this.props.allowedOptions.includes(item.id))
      : items;
  };

  getSelection = () => {
    const { selectedIds } = this.props;
    if (!this.state.allOptions.length) return [];
    const options = this.state.allOptions[0].options;
    return options.filter((val) => selectedIds.includes(getOptionId(val)));
  };

  render() {
    const {
      contentType,
      label,
      optionsFilterLabel,
      optionsFilterEnabled,
      toggleOptionsFilter,
      isLoading,
    } = this.props;
    const { allOptions, fetching } = this.state;
    const selection = this.getSelection();
    const multiSuffix = "s";
    return (
      <Form.Group className="my-2">
        <div className="d-flex justify-content-between">
          <Form.Label className="d-flex justify-content-between">
            {label ? label : contentType + multiSuffix}
          </Form.Label>
          {optionsFilterLabel && (
            <Form.Check
              id="toggle-options-filter"
              checked={optionsFilterEnabled}
              label={optionsFilterLabel}
              onChange={toggleOptionsFilter}
            />
          )}
        </div>
        <Select
          isDisabled={fetching}
          components={{
            IndicatorSeparator,
            MultiValueLabel,
          }}
          isMulti={true}
          closeMenuOnSelect={false}
          placeholder={
            fetching || isLoading
              ? "Loading..."
              : "Select " + contentType + multiSuffix
          }
          options={allOptions}
          value={selection}
          getOptionValue={getOptionId}
          formatOptionLabel={this.formatOptionLabel}
          formatGroupLabel={this.formatGroupLabel}
          filterOption={this.filterOption}
          onChange={this.onSelect}
        />
        <ProvisionerAccordion
          selections={selection}
          fetchById={this.fetchById}
          downloadPrefix={getDownloadPrefix(contentType)}
          labelFunction={this.formatOptionLabel}
          isMulti={true}
        />
      </Form.Group>
    );
  }
}

const IndicatorSeparator = (props) => (
  <>
    <span
      onClick={() => props.setValue(props.options[0].options)}
      style={{ marginRight: 10 }}
      className={GLYPHS.EVERYTHING}
    />
    <span
      {...props.innerProps}
      style={props.getStyles("indicatorSeparator", props)}
    />
  </>
);

const MultiValueLabel = (props) => props.data.name;

ProvisionerIdMultiPicker.propTypes = {
  contentType: PropTypes.oneOf([
    "Config Deployment",
    "Config Draft",
    "Config Publication",
    "DVB Cable Tuning Plan",
    "DVB Cable Stream",
    "DVB IPTV Tuning Plan",
    "DVB IPTV Stream",
    "DVB Triplet",
    "Lineup",
    "TV Provider",
    "TV Provider Placement",
    "VOD Provider",
  ]).isRequired,
  selectedIds: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  optionsFilterLabel: PropTypes.string,
  optionsFilterEnabled: PropTypes.bool,
  toggleOptionsFilter: PropTypes.func,
  allowedOptions: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default ProvisionerIdMultiPicker;
