import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { AssetRevision, AssetRevisionProviderImage } from "../types";
import ProviderImageCard from "./ProviderImageCard";
import SelectRevision from "./SelectRevision";

function ProviderImagesTab({
  first,
  revisions,
  getLinkTo,
  navigateToCompareLink,
}: {
  first: number;
  revisions: AssetRevision[];
  getLinkTo: (newParam: number) => string;
  navigateToCompareLink: (
    first: number,
    second: number,
    third: number,
    fourth: number
  ) => void;
}) {
  const selectedRevision = revisions.find((revision) => revision.id === first);
  const selectedImages = React.useMemo(
    () =>
      selectedRevision?.yaafd?.asset?.imageDownloads ||
      selectedRevision?.yaafo?.asset?.imageDownloads,
    [selectedRevision]
  );

  return (
    <>
      <SelectRevision
        revisions={revisions}
        selectedRevisionId={first}
        getLinkTo={getLinkTo}
        navigateToCompareLink={(first: number, third: number) =>
          navigateToCompareLink(first, first, third, third)
        }
      />
      {selectedImages && !_.isEmpty(selectedImages) && (
        <h3 className="lead">Provider image(s)</h3>
      )}
      <Row xs="auto" className="mt-3">
        {selectedImages &&
          (_.isEmpty(selectedImages) ? (
            <h3>No provider image in YAAFD or YAAFO.</h3>
          ) : (
            selectedImages.map((image: AssetRevisionProviderImage, i) => (
              <Col key={i}>
                <ProviderImageCard image={image} />
              </Col>
            ))
          ))}
      </Row>
    </>
  );
}

export default ProviderImagesTab;
