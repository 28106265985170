import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Card, Form, Nav, Tab } from "react-bootstrap";
import ImageDownload from "components/image/ImageDownload";
import ImageGrabberPane from "./ImageGrabberPane";
import ImageList from "./ImageList";

const imageTypes = ["cover", "poster", "profile", "backdrop", "screencap"];

const ImagesCard = ({ id, images: allImages, isLoading }) => {
  const [type, setType] = useState(imageTypes[0]);
  const [showDeleted, setShowDeleted] = useState(false);
  const onChangeShowDeleted = useCallback(
    (e) => setShowDeleted(e.target.checked),
    [setShowDeleted]
  );

  const images = allImages.filter(
    ({ source, dirty }) => showDeleted || dirty || source !== "deleted"
  );
  const imagesByType = _.groupBy(images, ({ value, currentValue }) => {
    const img = currentValue || value;
    return img.imageType;
  });

  return (
    <Tab.Container id="images-card" activeKey={type} onSelect={setType}>
      <Card className="mt-4">
        <Card.Header className="d-flex justify-content-between">
          <Nav variant="tabs">
            {imageTypes.map((t) => (
              <Nav.Item key={t}>
                <Nav.Link eventKey={t}>{_.startCase(t)}s</Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item>
              <Nav.Link eventKey="screencapture">Grab</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="d-flex align-items-end">
            <Form.Label htmlFor="show-deleted-images" className="mb-0 me-2">
              Show deleted?
            </Form.Label>
            <Form.Switch
              id="show-deleted-images"
              checked={showDeleted}
              onChange={onChangeShowDeleted}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Tab.Content>
            {imageTypes.map((type) => (
              <Tab.Pane key={type} eventKey={type}>
                {isLoading ? (
                  "Loading..."
                ) : (
                  <ImageList type={type} images={imagesByType[type] || []} />
                )}
              </Tab.Pane>
            ))}
            <Tab.Pane eventKey="screencapture">
              <ImageGrabberPane
                id={id}
                isLoading={isLoading}
                setActivePane={setType}
              />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
        <Card.Footer>
          <div className="text-end py-1">
            <ImageDownload
              images={images.map((image) => image.value.path)}
              zip
            />
          </div>
        </Card.Footer>
      </Card>
    </Tab.Container>
  );
};

export default ImagesCard;
