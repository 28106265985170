import classNames from "classnames";
import React from "react";
import { ButtonGroup, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { UserContext } from "client/contexts";
import { AssetModal } from "client/views/Enrich/MediaContent/AssetModal";
import { MediaContentModal } from "client/views/Enrich/MediaContent/MediaContentModal";
import { ACLMenuItem } from "components/toolbar/ACLMenu";
import { ArrayACL, MainMenuItem } from "components/toolbar/Nav";

const InsightToolbar = () => {
  const pathname = useLocation().pathname;
  const contentWarningsActive = [
    "/enrich/mediacontent/list",
    "/insight/warningstatus/reported/list",
    "/insight/warningstatus/accepted/list",
    "/insight/warningstatus/spam/list",
  ].includes(pathname);

  return (
    <div className="secondary publish-toolbar">
      <UserContext.Consumer>
        {({ hasAccessTo }) => (
          <ButtonGroup className="me-auto">
            <ArrayACL paths={["/enrich", "/insight"]}>
              <NavDropdown
                title="Content Warnings"
                id="content-warning-statuses-dropdown"
                className={classNames(
                  "publish-toolbar-item",
                  contentWarningsActive && "active"
                )}
              >
                <MainMenuItem to="/enrich/mediacontent/list">
                  Content Warnings
                </MainMenuItem>
                <MainMenuItem to="/insight/warningstatus/reported/list">
                  Reported Warnings
                </MainMenuItem>
                <MainMenuItem to="/insight/warningstatus/accepted/list">
                  Accepted Warnings
                </MainMenuItem>
                <MainMenuItem to="/insight/warningstatus/spam/list">
                  Spam Warnings
                </MainMenuItem>
              </NavDropdown>
            </ArrayACL>
            <ACLMenuItem to="/insight/report/list" hasAccessTo={hasAccessTo}>
              Content Reports
            </ACLMenuItem>
            <ACLMenuItem to="/insight/warning/stats" hasAccessTo={hasAccessTo}>
              Warning Stats
            </ACLMenuItem>
            <ACLMenuItem to="/insight/report/stats" hasAccessTo={hasAccessTo}>
              Report Stats
            </ACLMenuItem>
            <ACLMenuItem
              to="/insight/gap-overlap-log"
              hasAccessTo={hasAccessTo}
            >
              Gap/overlap log
            </ACLMenuItem>
            <ACLMenuItem
              to="/insight/gap-overlap-stats"
              hasAccessTo={hasAccessTo}
            >
              Gap/overlap stats
            </ACLMenuItem>
          </ButtonGroup>
        )}
      </UserContext.Consumer>
      <div className="justify-content-end">
        <MediaContentModal />
        <AssetModal />
      </div>
    </div>
  );
};

export default InsightToolbar;
