import React, { useCallback } from "react";
import { Card, Form } from "react-bootstrap";
import AnnotatedControl from "./AnnotatedControl";

const dateToYear = (date) => date?.year || "";
const yearToDate = (year) => ({ year, month: 1, day: 1 });

const Switch = ({ value, ...rest }) => {
  const legend = value ? "Yes" : "No";
  return (
    <div className="d-flex justify-content-start">
      <div className="me-2">{legend}</div>
      <Form.Switch checked={value} value="on" {...rest} />
    </div>
  );
};

const NonlocalizedMetadataCard = ({ isLoading, data, dispatch }) => {
  const setProductionDate = useCallback(
    (productionDate) => {
      dispatch({ type: "mc.metadata.update", payload: { productionDate } });
    },
    [dispatch]
  );
  const setCrid = useCallback(
    (crid) => dispatch({ type: "mc.metadata.update", payload: { crid } }),
    [dispatch]
  );
  const setIndex = useCallback(
    (index) => dispatch({ type: "mc.metadata.update", payload: { index } }),
    [dispatch]
  );
  const setAdultContent = useCallback(
    (adultContent) => {
      dispatch({
        type: "mc.metadata.update",
        payload: { adultContent },
      });
    },
    [dispatch]
  );
  const setParentMediaContentId = useCallback((parent) => {
    dispatch({
      type: "parent.update",
      payload: { parent },
    });
  });

  const parseBooleanEvent = useCallback((e) => e.target.checked, []);

  return (
    <Card className="mt-4">
      <Card.Body>
        <AnnotatedControl
          label="Production year"
          type="number"
          field={data?.productionDate}
          formatValue={dateToYear}
          parseValue={yearToDate}
          onChange={setProductionDate}
          disabled={isLoading}
        />
        {data?.kind?.value === "episode" && (
          <AnnotatedControl
            label="Episode"
            type="number"
            field={data?.index}
            onChange={setIndex}
            disabled={isLoading}
          />
        )}
        {data?.kind?.value === "season" && (
          <AnnotatedControl
            label="Season"
            type="number"
            field={data?.index}
            onChange={setIndex}
            disabled={isLoading}
          />
        )}
        <AnnotatedControl
          label="CRID"
          type="text"
          field={data?.crid}
          onChange={setCrid}
          disabled={isLoading}
        />
        <AnnotatedControl
          id="adult-content"
          label="Adult content?"
          component={Switch}
          parseEvent={parseBooleanEvent}
          field={data?.adultContent}
          onChange={setAdultContent}
          disabled={isLoading}
        />
        <AnnotatedControl
          label="Parent ID"
          type="text"
          field={data?.parent}
          onChange={setParentMediaContentId}
          disabled={isLoading}
        />
      </Card.Body>
    </Card>
  );
};

export default NonlocalizedMetadataCard;
