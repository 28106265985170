import _ from "lodash";
import React from "react";
import {
  clickOnDownloadLink,
  downloadFromUrls,
  createZip,
} from "client/utils/Helpers";
import { GlyphButton } from "components/buttons";
import { getCloudImagePath } from "components/image";

const endWithJPEG = (filename: string) => {
  const ending = filename.substring(filename.lastIndexOf(".") + 1);
  if (ending === "jpg" || ending === "jpeg") return filename;
  return filename + ".jpeg";
};

const ImageDownload: React.FunctionComponent<{
  images: string[];
  zip?: boolean;
}> = ({ images, zip, ...rest }) => {
  const [downloading, setDownloading] = React.useState(false);
  const files = React.useMemo(
    () =>
      images.map((image) => {
        return { url: getCloudImagePath(image), filename: endWithJPEG(image) };
      }),
    [images]
  );

  const downloadClick = React.useCallback(async () => {
    try {
      setDownloading(true);
      const urls = files.map((file) => (file.url ? file.url : ""));
      const filenames = files.map((file) => file.filename);
      const blobs = await downloadFromUrls(urls);
      if (zip) {
        const zip = await createZip(
          files.map((file, i) => {
            return { blob: blobs[i], filename: file.filename };
          })
        );
        clickOnDownloadLink(zip, "images.zip");
      } else if (blobs.length === 1 && filenames.length === 1) {
        clickOnDownloadLink(blobs[0], filenames[0]);
      }
    } finally {
      setDownloading(false);
    }
  }, [files, clickOnDownloadLink, downloading, setDownloading, createZip]);

  return (
    <GlyphButton
      glyph="DOWNLOAD"
      variant={zip ? "success" : "light"}
      size="sm"
      onClick={downloadClick}
      disabled={_.isEmpty(files) || downloading}
      {...rest}
    >
      {zip && "Download all images"}
    </GlyphButton>
  );
};

export default ImageDownload;
