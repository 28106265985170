import React from "react";
import { Route } from "react-router-dom";
import { Maintenance } from "client/views/Epg/Maintenance";

export const maintenanceRoutes = [
  <Route
    key="maintenance"
    path={"/epg/maintenance"}
    exact
    component={Maintenance}
  />,
];
