import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { SingleSelect } from "components/pickers";

const UserModal: React.FC<{
  roles: { id: number; role: string }[];
  user: { id: string; role_id: string; email: string };
  onChange: (newValue: object) => void;
  onSave: () => void;
}> = ({ roles, user, onChange, onSave, ...rest }) => {
  const { id, role_id, email } = user;
  return (
    <Modal centered {...rest}>
      <Form onSubmit={onSave}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label hidden>Email</Form.Label>
            <Form.Control
              placeholder="Email"
              value={email}
              disabled={!!id}
              onChange={(e) => onChange({ email: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label hidden>Role</Form.Label>
            <SingleSelect
              placeholder="Select role"
              value={Number(role_id)}
              options={roles.map((role) => ({
                value: role.id,
                label: role.role,
              }))}
              onChange={(role_id) => onChange({ role_id })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={!role_id || !email}>
            Confirm
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserModal;
