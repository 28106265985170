import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { CLIENT_CONFIG } from "client/config";

const TOAST_DURATION = 1000;

function EnvironmentToast() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const toggle = () => {
      setShow(true);
      setTimeout(() => setShow(false), TOAST_DURATION);
    };
    toggle();
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") toggle();
    });
  }, []);

  const bgClassName = `bg-${CLIENT_CONFIG.BACKGROUND}`;
  const className = classNames(
    "environment-popup",
    bgClassName,
    show ? "fade in" : "fade out"
  );
  return <div className={className}>{CLIENT_CONFIG.NAME}</div>;
}

export default EnvironmentToast;
