import React from "react";
import { Badge, Collapse } from "react-bootstrap";

function CollapsedList({ listItems }: { listItems: string[] }) {
  const [open, setOpen] = React.useState(false);
  const alwaysDisplayItem = listItems[0];
  const showOnDemandItems = listItems.slice(1);

  const itemsToExpand = React.useMemo(
    () => showOnDemandItems.length > 0,
    [showOnDemandItems]
  );

  return (
    <div
      className={itemsToExpand ? "expand-row" : ""}
      onClick={() => setOpen(!open)}
      aria-controls="collapse-text"
      aria-expanded={open}
    >
      <div className="d-inline-block pe-2">{alwaysDisplayItem}</div>
      {itemsToExpand && !open && (
        <Badge pill bg="light" text="dark" className="d-inline-block">
          +{showOnDemandItems.length}
        </Badge>
      )}
      <Collapse in={open}>
        <div id="collapse-text">
          {showOnDemandItems.map((onDemandItem, index) => (
            <div
              key={index}
              className="overflow-hidden d-flex align-items-center mb-1"
            >
              {onDemandItem}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
}

export default CollapsedList;
