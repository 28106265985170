import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import useSWR from "swr";
import { useKeptResult } from "client/hooks/useKeptResult";
import useDebounce from "client/views/CollectionEditor/hooks/useDebounce";
import { PartyResponse } from "client/views/CollectionEditor/types";

const usePartyNames = (
  query: string
): { partyNames: string[]; isDataLoading: boolean } => {
  const { data } = useSWR<PartyResponse>("party/" + query);

  const response = useKeptResult(data);

  if (!response || !response.value) {
    return { partyNames: [], isDataLoading: false };
  }
  return {
    partyNames: response.value.map(
      (party) => party.given_name + " " + party.last_name
    ),
    isDataLoading: data === undefined,
  };
};

interface Props {
  id: string;
  parties?: string[];
  onChange: (selected: string[]) => void;
  readOnly: boolean;
  label: string;
  placeholder: string;
}

export const PartyPicker = ({
  id,
  parties,
  onChange,
  readOnly,
  label,
  placeholder,
}: Props) => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 250);
  const { partyNames, isDataLoading } = usePartyNames(debouncedQuery);
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Typeahead
        id={id}
        multiple
        isLoading={isDataLoading || query !== debouncedQuery}
        placeholder={placeholder}
        onInputChange={(input) => setQuery(input)}
        selected={parties || []}
        options={partyNames}
        onChange={onChange}
        disabled={readOnly}
      />
    </Form.Group>
  );
};
