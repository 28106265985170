import React, { HTMLProps } from "react";
import { AmsRequestError } from "./AmsRequestError";

interface Props extends HTMLProps<HTMLDivElement> {
  error: AmsRequestError;
}

export function AmsErrorDetails({ error, ...rest }: Props) {
  let httpStatusMessage;
  if (error.status && error.statusText) {
    httpStatusMessage = `${error.status} ${error.statusText}`;
  } else if (error.status) {
    httpStatusMessage = `${error.status}`;
  }

  return (
    <code {...rest}>
      {httpStatusMessage ? (
        <>
          <span>{httpStatusMessage}</span>
          {error.data?.detail && <>: {error.data.detail}</>}
        </>
      ) : error.data?.detail ? (
        <>{error.data?.detail}</>
      ) : (
        "Unknown error"
      )}
    </code>
  );
}
