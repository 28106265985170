import React from "react";
import { Card } from "react-bootstrap";
import useSWR from "swr";
import { request } from "client/utils";
import { ConfirmButton } from "components/buttons";

interface Props {
  id: number;
  refreshMediaContent: () => void;
}

export const PurgeEditorialChangesCard = ({
  id,
  refreshMediaContent,
}: Props) => {
  const { data } = useSWR(`mediacontent/editor/${id}/has-editorial-changes`);
  const hasEditorialChanges = data?.hasEditorialChanges;
  return (
    <Card className="mt-4">
      <Card.Header>Purge editorial edits</Card.Header>
      <Card.Body>
        <ConfirmButton
          disabled={!hasEditorialChanges}
          confirmVariant="danger"
          variant="danger"
          onClick={async () => {
            await request(`mediacontent/editor/${id}/editorial`, {
              method: "DELETE",
            });
            refreshMediaContent();
          }}
          confirmChildren="Confirm purge"
        >
          Purge
        </ConfirmButton>
        {!hasEditorialChanges && (
          <div className="text-muted">No editorial changes to purge.</div>
        )}
        <div>
          Removes all editorial changes to this media content, including the
          editorial change history.
        </div>
        <div className="text-danger">Note: this cannot be undone!</div>
      </Card.Body>
    </Card>
  );
};
