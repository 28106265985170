import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom-v5-compat";
import useSWR from "swr";
import { AssetModal } from "../MediaContent/AssetModal";
import { MediaContentModal } from "../MediaContent/MediaContentModal";
import { AssetTable } from "./AssetTable";
import { EntityLog } from "./EntityLog";
import { MediaContentTitles } from "./MediaContentTitles";
import { Npvr } from "./Npvr";
import { UpdatableMediaContentId } from "./UpdatableMediaContentId";
import { AssetDetailsResponse } from "./types";

export const AssetDetails = () => {
  const { id } = useParams();
  const { data, mutate } = useSWR<AssetDetailsResponse>(
    id ? `assets/${id}` : null
  );

  if (!data) {
    return <div>Loading...</div>;
  }

  if (!data.MC) {
    return <div>There is no asset with id {id}</div>;
  }

  return (
    <>
      <div className="secondary publish-toolbar" style={{ display: "block" }}>
        <div className="d-flex justify-content-end">
          <MediaContentModal />
          <AssetModal />
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col>
            <Card body className="mt-4">
              <MediaContentTitles data={data} />
            </Card>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col>
            <Card body className="mt-4">
              <Row>
                <Col>
                  <UpdatableMediaContentId data={data} mutate={mutate} />
                </Col>
                <Col style={{ display: "flex", alignItems: "end" }}>
                  <span>
                    <b>Sports event:</b>{" "}
                    {data.MC.sportsEvent ? "true" : "false"}
                  </span>
                </Col>
              </Row>
            </Card>
            <Card body className="mt-4">
              <AssetTable data={data} />
            </Card>
            <Card className="mt-4">
              <Card.Body>
                {data.external_metadata.availabilities?.npvr ? (
                  <Npvr assetId={data.id} />
                ) : (
                  <p>This asset does not have NPVR availability</p>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-4">
              <Card.Header>
                <h1 className="mt-3">Entity Log</h1>
              </Card.Header>
              <Card.Body>
                <EntityLog assetId={data.id} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
