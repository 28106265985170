import _, { Dictionary } from "lodash";
import React from "react";
import { Badge } from "react-bootstrap";
import {
  CellProps,
  Column,
  useFlexLayout,
  useRowSelect,
  useTable,
} from "react-table-v7";
import LoadingOverlayWrapper from "components/LoadingOverlayWrapper";
import { ControlledReactTable, ReactTablePagination } from "components/tables";
import { RelativeTime } from "components/time/RelativeTime";
import { MediaContentHistory } from "./types";

function VersionSelectionTable({
  data,
  params,
  setParams,
  isLoading,
  totalHits,
  selected,
  compared,
  setSelected,
  setCompared,
}: {
  data: MediaContentHistory[];
  params: Dictionary<number>;
  setParams: (params: Dictionary<number>) => void;
  isLoading: boolean;
  totalHits: number;
  selected?: MediaContentHistory;
  compared?: MediaContentHistory;
  setSelected: (selected?: MediaContentHistory) => void;
  setCompared: (compared?: MediaContentHistory) => void;
}) {
  const columns: ReadonlyArray<Column<MediaContentHistory>> = React.useMemo(
    () => [
      {
        accessor: "version",
        Header: "Version",
        disableSortBy: true,
        width: 60,
      },
      {
        accessor: "created",
        Header: "Edited",
        Cell: ({ cell: { value } }: CellProps<MediaContentHistory>) => (
          <RelativeTime timestamp={value} />
        ),
        disableSortBy: true,
        width: 60,
      },
      {
        accessor: "editor",
        Header: "Editor",
        disableSortBy: true,
        width: 60,
      },
      {
        Header: "Compare",
        Cell: ({ row }: CellProps<MediaContentHistory>) => {
          const selected = row.original.version === compared?.version;
          return row.isSelected ? null : (
            <Badge
              onClick={(e) => {
                e.stopPropagation();
                selected ? setCompared(undefined) : setCompared(row.original);
              }}
              bg={selected ? "success" : "primary"}
            >
              {selected ? "Compared" : "Compare"}
            </Badge>
          );
        },
        disableSortBy: true,
        width: 20,
      },
    ],
    [compared, setCompared]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    toggleAllRowsSelected,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data,
      autoResetSelectedRows: false,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case "toggleAllRowsSelected":
            return {
              ...newState,
              selectedRowIds: {},
            };
          default:
            return newState;
        }
      },
    },
    useFlexLayout,
    useRowSelect
  );

  React.useEffect(() => {
    if (data.length > 0) {
      toggleAllRowsSelected(false);
      if (selected !== undefined) {
        const rowIndex = _.findIndex(data, ["version", selected?.version]);
        if (rowIndex >= 0) toggleRowSelected(rowIndex.toString(), true);
      }
    }
  }, [data, params.page, params.limit, selected, toggleAllRowsSelected]);

  return (
    <LoadingOverlayWrapper show={isLoading}>
      <ControlledReactTable
        className="mt-3"
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        getRowProps={(row: { original?: MediaContentHistory }) => ({
          onClick: () => {
            setSelected(row.original);
            if (row.original?.version === compared?.version) {
              setCompared(undefined);
            }
          },
          style: {
            cursor: "pointer",
          },
        })}
        rows={rows}
        placeholderProps={{ colSpan: columns.length }}
      />
      <ReactTablePagination
        pageIndex={params.page}
        pageSize={params.limit}
        gotoPage={(page: number) => setParams({ page })}
        setPageSize={(limit: number) => setParams({ limit })}
        totalPages={Math.ceil(totalHits / params.limit)}
      />
    </LoadingOverlayWrapper>
  );
}

export default VersionSelectionTable;
