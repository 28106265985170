import React, { useCallback, useEffect } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import useSWR from "swr";
import { StringParam, useQueryParam } from "use-query-params";
import { useKeptResult } from "client/hooks/useKeptResult";
import { request } from "client/utils";
import { RequestError } from "client/utils/request";
import { useProgram } from "client/views/Epg/hooks";
import { GLYPHS } from "components/buttons/glyphs";
import { ProgramAssets } from "../../ProgramDetails/ProgramAssets";
import { ProgramContentChildren } from "../../ProgramDetails/ProgramContentChildren";
import { ProgramDetails } from "../../ProgramDetails/ProgramDetails";

export const BulkCreateSeEp = () => {
  const [mediaContentId, setMediaContentId] = useQueryParam(
    "mediaContentId",
    StringParam
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.Label htmlFor="media-content-id">Media content id</Form.Label>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12}>
          <Form.Control
            id="media-content-id"
            value={mediaContentId || ""}
            placeholder="Media content id"
            onChange={(e) => setMediaContentId(e.target.value || null)}
          />
        </Col>
      </Row>
      {mediaContentId && <BulkCreateView mediaContentId={mediaContentId} />}
    </>
  );
};

interface BulkCreateViewProps {
  mediaContentId: string;
}

const BulkCreateView = ({ mediaContentId }: BulkCreateViewProps) => {
  const { program, error, mutate } = useProgram(mediaContentId, "TELIADB");

  const keptProgram = useKeptResult(program);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [keptProgram]);

  const { mutate: mutateMediaContentChildren } = useSWR(
    `mediacontent/children-of/${mediaContentId}`
  );

  const redoProgramAssets = useCallback(async () => {
    try {
      await request(`sync/redo/program_assets/${mediaContentId}`, {
        method: "PATCH",
      });
      await mutate();
      await mutateMediaContentChildren();
    } catch (e) {
      if (e instanceof RequestError) {
        toast(
          `metadata-sync-api error: ${e.status} - ${
            e.statusText
          }: ${JSON.stringify(e.data)}`
        );
      } else {
        toast(`metadata-sync-api error: ${e}`);
      }
    }
  }, [mediaContentId, mutate]);

  if (error) {
    return (
      <Alert variant="danger">Could not load program ID {mediaContentId}</Alert>
    );
  }

  if (!keptProgram) {
    return <div>Loading...</div>;
  }

  const { data, assets } = keptProgram;

  const kindIsSeriesOrShow =
    data && (data.kind === "series" || data.kind === "show");
  const mediaContentHasAssets = assets.length > 0;

  return (
    <>
      <Row>
        <Col xs={6}>
          <ConditionalAlert conditionHasBeenMet={kindIsSeriesOrShow}>
            Media content must have kind series or show
          </ConditionalAlert>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <ConditionalAlert conditionHasBeenMet={mediaContentHasAssets}>
            Media content must have assets
          </ConditionalAlert>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Button
            disabled={!kindIsSeriesOrShow || !mediaContentHasAssets}
            onClick={redoProgramAssets}
          >
            Create SeEp media contents
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>
          {mediaContentId && (
            <>
              <ProgramDetails id={mediaContentId} database="TELIADB" />
              <h3 className="lead border-bottom">Media content children</h3>
              <ProgramContentChildren id={mediaContentId} />
              <h2>Assets</h2>
              <ProgramAssets assets={assets} database="TELIADB" />
            </>
          )}
          {!mediaContentId && "Enter a media content id above"}
        </Col>
      </Row>
      <ReactTooltip />
    </>
  );
};

interface ConditionalAlertProps {
  conditionHasBeenMet: boolean;
  children: React.ReactNode;
}

const ConditionalAlert = ({
  conditionHasBeenMet,
  children,
}: ConditionalAlertProps) => {
  const variant = conditionHasBeenMet ? "success" : "danger";
  const glyph = conditionHasBeenMet ? GLYPHS.CHECK : GLYPHS.CROSS;
  return (
    <Alert variant={variant}>
      <i className={glyph} style={{ width: "16px" }}></i> {children}
    </Alert>
  );
};
