import React from "react";
import { useTeliaPlayProgram } from "../hooks/useProgram";
import { MetadataCollectorCridLink } from "./MetadataCollectorCridLink";

interface Props {
  mediaContentId: string;
}

export const MediaContentCrid = ({ mediaContentId }: Props) => {
  const { program } = useTeliaPlayProgram(mediaContentId);

  const crid = program && program.crid ? program.crid : "null";
  if (crid !== "null") {
    return <MetadataCollectorCridLink crid={crid} />;
  }
  return <div>{crid}</div>;
};
