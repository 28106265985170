import React from "react";
import { toast } from "react-toastify";
import { PublicationTypes } from "client/types/PublicationTypes";
import { request } from "client/utils";
import { EmptyCmsCard, CollectionCard } from "components/cards";
import { CollectionSearchModal } from "./CollectionSearchModal";

export function CollectionPicker({
  selectedId,
  readOnly,
  initialValue,
  onChange,
  collectionType,
}: {
  selectedId?: number;
  readOnly?: boolean;
  initialValue?: { id: number; title: string };
  onChange: (collectionId: number) => void;
  collectionType?: string;
}) {
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [selectedCollection, setSelectedCollection] = React.useState(
    // pre-render only if passed a title
    initialValue && initialValue.title ? initialValue : null
  );

  const onSelectCollection = React.useMemo(
    () => (collectionId: number) => {
      setModalVisible(false);
      onChange(collectionId);
    },
    [onChange]
  );

  React.useEffect(() => {
    if (!selectedId) {
      if (selectedCollection) setSelectedCollection(null);
      return;
    }
    request("collections/card/" + selectedId)
      .then((result) => {
        setSelectedCollection(result);
      })
      .catch((err) => {
        toast.error("Failed to load collections: " + err.message);
        console.error(err);
      });
  }, [selectedId]);

  return (
    <>
      <CollectionSearchModal
        show={isModalVisible}
        size="lg"
        onHide={() => setModalVisible(false)}
        onSelectCollection={onSelectCollection}
        collectionType={collectionType}
      />
      {selectedCollection ? (
        <CollectionCard
          id={selectedCollection.id}
          item={selectedCollection}
          onClick={() => setModalVisible(true)}
          canClick={!readOnly}
          showLink
        />
      ) : (
        <EmptyCmsCard
          onClick={() => setModalVisible(true)}
          canClick={!readOnly}
          variant={PublicationTypes.COLLECTION}
        />
      )}
    </>
  );
}
