import _ from "lodash";
import { useContext, useMemo } from "react";
import { ChannelsContext } from "client/App";

export const useChannels = () => {
  const channels = useContext(ChannelsContext);
  const channelMap = useMemo(
    () => _.fromPairs(channels.map(({ id, value }) => [id, value])),
    [channels]
  );

  return { channels, channelMap };
};
