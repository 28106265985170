import classNames from "classnames";
import moment from "moment";
import React from "react";
import { parseDateToDatetimeString } from "client/utils/TableCommon";

interface Props {
  timestamp: moment.MomentInput;
  as?: React.ElementType;
  title?: string;
  className?: string;
}

export const RelativeTime = ({
  title,
  as,
  className,
  timestamp,
  ...rest
}: Props) => {
  if (!title) {
    title = parseDateToDatetimeString(timestamp);
  }
  const Component: React.ElementType = as ? as : "div";

  return (
    <Component
      className={classNames("text-truncate", className)}
      title={title}
      {...rest}
    >
      {formatRelativeTimestamp(timestamp)}
    </Component>
  );
};

const formatRelativeTimestamp = (date: moment.MomentInput) => {
  if (!date) {
    return "N/A";
  }

  const m = moment(date);
  const now = moment();
  const from = m.from(now);
  const fromDays = moment(m).startOf("day").from(moment(now).startOf("day"));

  if (m.isSame(now, "day")) {
    return from;
  }

  return fromDays;
};
