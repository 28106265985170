import React, { useCallback, useState } from "react";
import { Button, Alert, Row, Col, Form, ListGroup } from "react-bootstrap";
import { request } from "client/utils";
import { GlyphButton } from "components/buttons";
import CloudImageControl from "components/image/CloudImageControl";
import { displayName } from "./utils";

const TeamCard = ({ team, readOnly, onClose, onChange }) => {
  const [newAlias, setNewAlias] = useState("");
  const [helpVisible, setHelpVisible] = useState(false);

  const saveTeam = useCallback(
    (body) =>
      request(`sports/teams/${team.id}`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (onChange) {
            onChange(res);
          }
        })
        .catch(console.error),
    [onChange]
  );
  const removeAlias = useCallback(
    (alias) => {
      const aliases = team.editorial?.aliases || [];
      saveTeam({ aliases: aliases.filter((a) => a !== alias) });
    },
    [team.editorial, saveTeam]
  );
  const addAlias = useCallback(() => {
    const aliases = team.editorial?.aliases || [];
    saveTeam({ aliases: [...aliases, newAlias] }).then(() => setNewAlias(""));
  }, [team.editorial, saveTeam, newAlias]);
  const maybeAddAlias = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        addAlias();
      }
    },
    [addAlias]
  );
  const updateLogo = useCallback((logo) => saveTeam({ logo }), [saveTeam]);
  const showHelp = useCallback(() => setHelpVisible(true));
  const hideHelp = useCallback(() => setHelpVisible(false));

  return (
    <Alert variant="light" className="pb-4">
      <Alert.Heading className="d-flex justify-content-between">
        <div>{displayName(team)}</div>
        {onClose && (
          <div>
            <GlyphButton
              glyph="close"
              variant="link"
              className="p-0 text-muted"
              onClick={onClose}
            />
          </div>
        )}
      </Alert.Heading>
      <div className="d-flex">
        <div className="w-50 me-2">
          <CloudImageControl
            disabled={readOnly}
            type="sports-logo"
            width={256}
            height={256}
            allowHigherResolution
            value={team.editorial?.logo}
            onChange={updateLogo}
          />
        </div>
        <div>
          <div>
            <div>
              <b>Name</b>
            </div>
            <div>{team.name}</div>
          </div>
          <div>
            <div>
              <b>Short name</b>
            </div>
            <div>{team.shortName}</div>
          </div>
          <div>
            <div>
              <b>Official name</b>
            </div>
            <div>{team.officialName}</div>
          </div>
          <div>
            <div>
              <b>Country</b>
            </div>
            <div>{team.country.name}</div>
          </div>
        </div>
      </div>
      {(!readOnly || (team.editorial && team.editorial.aliases.length > 0)) && (
        <Alert.Heading className="pt-2 mt-2">Aliases</Alert.Heading>
      )}
      {team.editorial && (
        <ListGroup variant="flush">
          {team.editorial.aliases.map((alias) => (
            <ListGroup.Item
              key={alias}
              className="d-flex justify-content-between align-items-center px-0 py-1 border-bottom-0"
            >
              {alias}
              {!readOnly && (
                <GlyphButton
                  glyph="delete"
                  variant="link"
                  className="text-danger"
                  onClick={() => removeAlias(alias)}
                />
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      {!readOnly && (
        <>
          <Row>
            <Form.Group as={Col} className="mb-0">
              <Form.Control
                value={newAlias}
                onChange={(e) => setNewAlias(e.target.value)}
                onKeyUp={maybeAddAlias}
                placeholder="New alias"
              />
              {newAlias !== "" && (
                <div className="d-flex justify-content-end">
                  <Form.Text className="text-muted fw-light">
                    Press <b>return</b> to add the new alias
                  </Form.Text>
                </div>
              )}
            </Form.Group>
          </Row>
          {helpVisible ? (
            <>
              <div className="help-block mt-2">
                Team aliases are used to improve automatic channel detection for
                matches. If the matches for a certain team cannot b
                automatically mapped to channels, it&apos;s often because the
                team&apos;s name variants listed above (name, short name,
                official name) do not match the way the team name is written in
                the media content titles for the team&apos;s matches. In these
                situations, the automatic channel detection can be improved by
                adding a team alias that corresponds to the team name variant
                used in media content titles.
              </div>
              <div className="d-flex justify-content-end">
                <Button size="sm" variant="primary" onClick={hideHelp}>
                  OK, got it!
                </Button>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-end">
              <Button
                className="pb-0"
                size="sm"
                variant="link"
                onClick={showHelp}
              >
                What&apos;s this?
              </Button>
            </div>
          )}
        </>
      )}
    </Alert>
  );
};

export default TeamCard;
