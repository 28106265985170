import { DateTime } from "luxon";
import React from "react";
import { Table } from "react-bootstrap";
import useSWR from "swr";

interface EntityLog {
  op: "I" | "U";
  updated: string;
  data: Record<string, unknown>;
}

interface Props {
  assetId: string;
}

export const EntityLog = ({ assetId }: Props) => {
  const { data } = useSWR<EntityLog[]>(`assets/entity-log/${assetId}`);

  if (!data) {
    return <div>Loading...</div>;
  }

  const tableRows = data.map((row, index) => (
    <EntityLogRow entityLog={row} key={index} />
  ));

  return (
    <Table striped hover className="condensed">
      <thead>
        <tr>
          <th>updated</th>
          <th>op</th>
          <th>data</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </Table>
  );
};

interface EntityLogRowProps {
  entityLog: EntityLog;
}

const EntityLogRow = ({ entityLog }: EntityLogRowProps) => {
  return (
    <tr>
      <td>
        <pre>
          {DateTime.fromISO(entityLog.updated).toFormat(
            "yyyy-MM-dd HH:mm:ss.SSS"
          )}
        </pre>
      </td>
      <td>
        <pre>{entityLog.op}</pre>
      </td>
      <td>
        <pre>{JSON.stringify(entityLog.data, undefined, 4)}</pre>
      </td>
    </tr>
  );
};
