import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { SLAField } from "client/views/Epg/ChannelAdditionalInfo/EditChannelModal/SLAField";
import { ChannelWithAdditionalInfo } from "../types";
import { CommentField } from "./CommentField";
import { DescriptionLanguageField } from "./DescriptionLanguageField";
import { GNClassField } from "./GNClassField";
import { NoteOnCatchupField } from "./NoteOnCatchupField";
import { PrgServIDField } from "./PrgServIDField";
import { SaveButton } from "./SaveButton";
import { TypeField } from "./TypeField";
import { State } from "./types";

interface Props {
  savedChannelInfo: ChannelWithAdditionalInfo;
  save: (body: unknown) => void;
  close: () => void;
}

export const EditChannelModal = ({ savedChannelInfo, save, close }: Props) => {
  const { state, applyPatch } = useChannelModalState(savedChannelInfo);

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>Edit channel additional info</Modal.Header>
      <Modal.Body>
        <h1>{savedChannelInfo.data.name}</h1>
        <PrgServIDField state={state} applyPatch={applyPatch} />
        <DescriptionLanguageField state={state} applyPatch={applyPatch} />
        <TypeField state={state} applyPatch={applyPatch} />
        <SLAField state={state} applyPatch={applyPatch} />
        <GNClassField state={state} applyPatch={applyPatch} />
        <NoteOnCatchupField state={state} applyPatch={applyPatch} />
        <CommentField state={state} applyPatch={applyPatch} />
      </Modal.Body>
      <Modal.Footer>
        <SaveButton save={save} state={state} />
      </Modal.Footer>
    </Modal>
  );
};

const useChannelModalState = (savedChannelInfo: ChannelWithAdditionalInfo) => {
  const createInitialState = () => ({
    prgServId: savedChannelInfo["additionalInfo.prg_serv_id"],
    descriptionLanguage:
      savedChannelInfo["additionalInfo.description_language"],
    comment: savedChannelInfo["additionalInfo.comment"],
    type: savedChannelInfo["additionalInfo.type"],
    sla: savedChannelInfo["additionalInfo.sla"],
    gnClass: savedChannelInfo["additionalInfo.gn_class"],
    noteOnCatchup: savedChannelInfo["additionalInfo.note_on_catchup"],
  });

  const [state, setState] = useState<State>(createInitialState);

  const applyPatch = (patch: Partial<State>) =>
    setState((state) => ({ ...state, ...patch }));

  return { state, applyPatch };
};
