import { formatDuration } from "client/utils";

const getEndpointPath = (asset, pathSuffix) => {
  let path = asset.channel_id ? "catchup" : "svod";

  if (pathSuffix) {
    path = `${path}/${pathSuffix}`;
  }

  return path;
};

const getQueryParams = (asset, params = {}) => {
  const queryParams = new URLSearchParams(params);

  if (asset.channel_id) {
    queryParams.append("asset_id", asset.id);
    queryParams.append("channel_id", asset.channel_id);
  } else {
    queryParams.append("external_asset_id", asset.program_url);
  }

  return queryParams;
};

const getFrameCaptureEndpointUrl = (
  asset,
  { path = undefined, queryParams = {} } = {}
) => {
  const endpointPath = getEndpointPath(asset, path);
  const query = getQueryParams(asset, queryParams);
  return `frames/${endpointPath}?${query.toString()}`;
};

export const getRuntimeUrl = (asset) => {
  return getFrameCaptureEndpointUrl(asset, { path: "duration" });
};

export const getFrameCaptureUrl = (asset, offset, size) => {
  return getFrameCaptureEndpointUrl(asset, {
    queryParams: {
      size,
      time_offset: formatDuration(offset, { milliseconds: true }),
    },
  });
};
