import { CollectionFilters } from "@telia-company/tv.no-play-cms-common/collection/types";
import React from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { AnnotatedContentCard } from "client/views/CollectionEditor/AnnotatedContentCard";
import {
  CollectionOnChangeHandlers,
  CollectionPreview,
} from "client/views/CollectionEditor/types";
import CmsCardContainer from "components/cards/CmsCardContainer";

interface Props {
  preview?: CollectionPreview;
  showTooFewItemsWarning: boolean;
  filters: CollectionFilters;
  onChangeHandlers: CollectionOnChangeHandlers;
}

export const DynamicCollectionPreview = ({
  preview,
  showTooFewItemsWarning,
  filters,
  onChangeHandlers,
}: Props) => {
  if (!preview) {
    return (
      <>
        <h3 className="lead">Preview</h3>
        <div>Loading&hellip;</div>
      </>
    );
  }

  const tooFewItemsWarning = (
    <Alert variant="warning">
      <ExclamationTriangleFill />
      The collection contains too few items, and will be hidden for all users.
      Change &quot;Available content&quot; to &quot;Show all&quot; or change
      filters so more items are included.
    </Alert>
  );

  const toggleManualPrio = (contentId: number, checked: boolean) =>
    toggleContentId(
      contentId,
      checked,
      onChangeHandlers.manualPrio,
      filters.manualPrio
    );

  const toggleManualHide = (contentId: number, checked: boolean) =>
    toggleContentId(
      contentId,
      checked,
      onChangeHandlers.manualHide,
      filters.manualHide
    );

  return (
    <>
      <h3 className="lead">Preview</h3>
      {showTooFewItemsWarning ? tooFewItemsWarning : null}
      <div className="mb-3">
        Total matches:{" "}
        <span>
          {preview.totalHits === 10000 ? "10000+" : preview.totalHits}
        </span>
      </div>
      <CmsCardContainer>
        {preview.contents
          ? preview.contents.map((content) => {
              return (
                <div key={content.id}>
                  <div
                    style={
                      filters.manualHide?.includes(content.id)
                        ? { opacity: "50%" }
                        : {}
                    }
                  >
                    <AnnotatedContentCard
                      id={content.id}
                      editContentMetadata={false}
                      contentType="mediaContent"
                      onChange={() => {}}
                      onClick={() => {}}
                      readOnly={true}
                    />
                  </div>
                  <div className="mt-1">
                    <Row>
                      <Col xs={6}>
                        <Form.Check
                          id={"prioritize-" + content.id}
                          type="switch"
                          label="prioritize"
                          checked={
                            filters.manualPrio?.includes(content.id) || false
                          }
                          onChange={(e) =>
                            toggleManualPrio(content.id, e.target.checked)
                          }
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Check
                          id={"hide-" + content.id}
                          type="switch"
                          label="hide"
                          checked={
                            filters.manualHide?.includes(content.id) || false
                          }
                          onChange={(e) =>
                            toggleManualHide(content.id, e.target.checked)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })
          : null}
      </CmsCardContainer>
    </>
  );
};

const toggleContentId = (
  contentId: number,
  checked: boolean,
  setContentIds: (newManualHide: number[]) => void,
  contentIds?: number[]
) => {
  if (checked) {
    if (!contentIds) {
      setContentIds([contentId]);
    } else {
      setContentIds([...contentIds, contentId]);
    }
  } else {
    if (!contentIds) {
      // should never happen
      return;
    }
    setContentIds(contentIds.filter((id) => id !== contentId));
  }
};
