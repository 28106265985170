import moment from "moment";

export function reducer(state, action) {
  switch (action.type) {
    case "image.loaded": {
      const { thumbnail, image } = action.payload;
      let error;

      // The frame capture API sometimes returns an error as a 200 OK
      // response with an error message in the response body.  Detect
      // these errors by trying to decode the responses as base64 and
      // interpreting the response as an error if the decoding fails.
      try {
        if (thumbnail) {
          atob(thumbnail);
        }
      } catch {
        error = thumbnail;
      }

      try {
        if (image) {
          atob(image);
        }
      } catch {
        error = image;
      }

      const failed = !!error;

      return {
        ...state,
        failed,
        error,
        thumbnailUrl:
          !failed && thumbnail && `data:image/jpeg;base64,${thumbnail}`,
        imageUrl: !failed && image && `data:image/jpeg;base64,${image}`,
      };
    }
    case "image.requested": {
      const { thumbnail, image } = action.payload;
      return {
        ...state,
        thumbnailEndpointUrl: thumbnail,
        imageEndpointUrl: image,
      };
    }
    case "image.failed":
      return {
        ...state,
        failed: true,
        error: action.payload,
      };
    case "runtime.loaded":
      return {
        ...state,
        runtime: Math.floor(moment.duration(action.payload).asSeconds()),
      };
    case "progress.scrubEnd":
      return {
        ...state,
        offset: action.payload,
      };
    case "reset":
      return {
        ...state,
        initialOffset: action.payload,
        offset: action.payload,
      };
    default:
      return state;
  }
}

export const initState = ({ asset: { running_time } = {}, offset = 0.0 }) => ({
  imageEndpointUrl: undefined,
  thumbnailEndpointUrl: undefined,
  imageUrl: undefined,
  thumbnailUrl: undefined,
  runtime: Math.floor(moment.duration(running_time).asSeconds()),
  initialOffset: offset,
  offset,
});
