import PropTypes from "prop-types";
import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { ACTION, defaultActionReducer } from "client/hooks";
import { request } from "client/utils";
import { ConfirmDeleteButton, GlyphButton } from "components/buttons";
import Table from "components/tables";
import UserModal from "./UserModal";

const DEFAULT_USER = {
  id: "",
  role_id: "",
  username: "",
  email: "",
};

function Users({ roles }) {
  const [users, setUsers] = React.useState([]);

  const onDeleteUser = React.useCallback(
    ({ id }) => {
      request("settings/admin/access-control/" + id, {
        method: "DELETE",
      }).then(() => {
        const newUsers = users.filter((user) => user.id != id);
        setUsers(newUsers);
      });
    },
    [users, setUsers]
  );

  const onEditUser = React.useCallback(
    (user) => {
      setShowDialog(true);
      dispatch({ type: ACTION.SET, value: user });
    },
    [setShowDialog, dispatch]
  );

  const onAddUser = React.useCallback(() => {
    setShowDialog(true);
    dispatch({ type: ACTION.SET, value: DEFAULT_USER });
  }, [setShowDialog, dispatch]);

  const onSubmit = React.useCallback((e) => {
    e.preventDefault();
    const { id, role_id, username, email } = dialogUser;
    const payload = { role_id, username, email };
    // if there is an id, the role was edited
    const method = id ? "PUT" : "POST";
    request("settings/admin/access-control/" + id, {
      method,
      body: JSON.stringify(payload),
    })
      .then((newUser) => {
        const newUsers = id
          ? users.map((role) => (role.id != id ? role : newUser))
          : [...users, newUser];
        setUsers(newUsers);
        setShowDialog(false);
      })
      .catch(console.error);
  });

  React.useEffect(() => {
    request("settings/admin/access-control")
      .then((users) => setUsers(users))
      .catch(console.error);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Email address",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role_id",
        Cell: ({ cell: { value } }) => {
          const role = roles.find((role) => role.id == value);
          return role ? role.role : null;
        },
      },
      {
        Header: "Manage",
        id: "manage",
        Cell: ({ row: { original } }) => (
          <div className="position-relative">
            <GlyphButton
              glyph="edit"
              variant="link"
              size="sm"
              onClick={() => onEditUser(original)}
            />
            <ConfirmDeleteButton
              size="sm"
              onClick={() => onDeleteUser(original)}
            />
          </div>
        ),
        width: 80,
        canResize: false,
      },
    ],
    [onDeleteUser, onEditUser, roles]
  );
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogUser, dispatch] = React.useReducer(
    defaultActionReducer,
    DEFAULT_USER
  );

  return (
    <div>
      <UserModal
        user={dialogUser}
        roles={roles}
        onChange={(value) => dispatch({ type: ACTION.MERGE, value })}
        show={showDialog}
        onHide={() => setShowDialog(false)}
        onSave={onSubmit}
      />

      <Row>
        <Col xs>
          <h1>Access Control Management</h1>
        </Col>
        <Col xs="auto" className="my-auto">
          <Button variant="outline-secondary" onClick={onAddUser}>
            Add user
          </Button>
        </Col>
      </Row>

      <Table columns={columns} data={users} flex />
    </div>
  );
}

Users.propTypes = {
  roles: PropTypes.array,
};

export default Users;
