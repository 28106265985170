import React from "react";
import { Button } from "react-bootstrap";
import { request } from "client/utils";

interface Props {
  matchId: number;
  disabled?: boolean;
  mutate: () => void;
}

export const MarkAsDominantButton = ({ matchId, disabled, mutate }: Props) => {
  const onClick = async () => {
    await request(`epg/match/dominant/${matchId}`, { method: "POST" });
    mutate();
  };
  return (
    <Button
      variant="outline-primary"
      className="ms-2"
      disabled={disabled}
      onClick={onClick}
    >
      Mark as dominant
    </Button>
  );
};
