import { Dictionary } from "lodash";
import useSWR from "swr";
import { getQueryString, useDebouncedValue } from "client/hooks";

export interface ContentEditsRow {
  id: string;
  title: string;
  topTitle?: string;
  topParentId?: string;
  crid: string;
  kind: string;
  channels: string[];
  providers: string[];
  originalUpdated: string;
  editorialUpdated: string;
  changedFields: string[];
  editor: string;
  types: string[];
}

interface ContentEditsResponse {
  data: ContentEditsRow[];
  limit: number;
  page: number;
  total: number;
}

export interface QueryParams {
  editor: string;
  status: string;
  page: number;
  limit: number;
  activeOnly: boolean;
}

export function useContentEdits(
  values: Dictionary<string | number | boolean>,
  sort: string[]
) {
  const queryString = getQueryString({ ...values, sort });
  const debouncedUrl = useDebouncedValue(`enrichment/edits?${queryString}`);

  const { data, isValidating, error } =
    useSWR<ContentEditsResponse>(debouncedUrl);

  return {
    data: data ? data.data : [],
    totalHits: data ? data?.total : 0,
    isLoading: isValidating || (!data && !error),
  };
}
