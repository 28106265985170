import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { NumberParam, useQueryParam } from "use-query-params";
import { useChannels } from "client/hooks";

export const ChannelSelector = () => {
  const channels = useChannels();
  const sortedChannels = [...channels.channels].sort(
    (a, b) => parseInt(a.id) - parseInt(b.id)
  );
  const [teliaNoChannel, setTeliaNoChannel] = useQueryParam(
    "teliaNoChannel",
    NumberParam
  );

  return (
    <>
      <Typeahead
        id="tpno-channel-selector"
        selected={sortedChannels.filter(
          (channel) => parseInt(channel.id) === teliaNoChannel
        )}
        options={sortedChannels}
        labelKey="value"
        placeholder="Select channel"
        onChange={(selected) => {
          if (selected.length > 0) {
            setTeliaNoChannel(parseInt(selected[0].id));
          } else {
            setTeliaNoChannel(null);
          }
        }}
      />
    </>
  );
};
