import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

const MatchCardActions = ({ match, dispatch }) => {
  const [newImdbId, setNewImdbId] = useState("");

  useEffect(() => {
    setNewImdbId(match.imdbId || "");
  }, [match.imdbId]);

  const updateImdbId = React.useCallback(() => {
    dispatch({
      type: "editor.imdbMatch.updated",
      payload: newImdbId,
    });
  }, [newImdbId]);

  return (
    <div className="d-flex">
      <InputGroup className="w-auto">
        <Form.Control
          type="text"
          value={newImdbId}
          onChange={(e) => setNewImdbId(e.target.value)}
          onKeyUp={(e) => {
            if (e.keyCode === 13) updateImdbId();
          }}
          id="new-imdb-id"
        />
        <Button
          onClick={updateImdbId}
          disabled={newImdbId === "" || newImdbId === match.imdbId}
        >
          Update IMDb ID
        </Button>
      </InputGroup>
      <div className="ms-auto">
        {match.imdbId && (
          <Button
            variant="danger"
            title="Remove the association between this media content and its currently associated IMDb ID"
            onClick={() =>
              dispatch({
                type: "editor.imdbMatch.unlink",
                payload: { reason: "unlinked" },
              })
            }
          >
            Unlink
          </Button>
        )}
        {!match.imdbId && match.emptyReason !== "ignored" && (
          <Button
            variant="link"
            title="Indicate that this content is not available on IMDb"
            onClick={() =>
              dispatch({
                type: "editor.imdbMatch.unlink",
                payload: { reason: "ignored" },
              })
            }
          >
            Not on IMDb?
          </Button>
        )}
      </div>
    </div>
  );
};

export default MatchCardActions;
