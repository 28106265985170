import useSWR from "swr";

const INITIAL_STATE = {
  name: "",
  play_id: "",
  play_status: "",
  transcode_service: "",
  asset_import_concurrency_max: null,
};

export function useProvider(id: number, mode: "view" | "edit" | "create") {
  const url = mode === "edit" || mode === "view" ? "ams/provider/" + id : null;
  const fallbackData = mode === "create" ? INITIAL_STATE : undefined;
  const { data, error, isValidating, mutate } = useSWR(url, { fallbackData });

  return {
    provider: data || {},
    error,
    isLoading: isValidating || (!data && !error),
    mutate,
  };
}
