import React from "react";
import { Form } from "react-bootstrap";
import { FieldProps } from "./types";

const OPTIONS = ["None", "Sports", "News", "Kids", "Local", "Other"];

export const TypeField = ({ state, applyPatch }: FieldProps) => {
  return (
    <>
      <Form.Label htmlFor="type">Type</Form.Label>
      <Form.Select
        id="type"
        value={state.type}
        onChange={(e) => applyPatch({ type: e.target.value })}
      >
        {OPTIONS.map((typeOption) => (
          <option key={typeOption} value={typeOption}>
            {typeOption}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
