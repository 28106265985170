export function reducer(state, action) {
  switch (action.type) {
    case "editor.imdbMatch.updated":
      return {
        ...state,
        editor: {
          ...state.editor,
          imdbMatchUpdate: action.payload,
        },
      };
    case "editor.imdbMatch.unlink":
      return {
        ...state,
        editor: {
          ...state.editor,
          confirmUnlink: true,
          unlinkReason: action.payload.reason,
        },
      };
    case "editor.imdbMatch.unlinkCancelled":
      return {
        ...state,
        editor: {
          ...state.editor,
          confirmUnlink: false,
          unlinkReason: undefined,
        },
      };
    case "editor.imdbMatch.unlinkConfirmed":
      return {
        ...state,
        editor: {
          ...state.editor,
          unlinkConfirmed: true,
          unlinkReason: action.payload.reason,
        },
      };
    case "editor.matchRequest.success":
    case "editor.matchUpdate.success":
      return {
        ...state,
        editor: {
          ...state.editor,
          loading: false,
          match: action.payload,
          imdbMatchUpdate: undefined,
          unlinkConfirmed: false,
          confirmUnlink: false,
          unlinkReason: undefined,
        },
      };
    case "editor.matchRequest.error":
    case "editor.matchUpdate.error":
      return {
        ...state,
        editor: {
          ...state.editor,
          loading: false,
          unlinkConfirmed: false,
          confirmUnlink: false,
          unlinkReason: undefined,
        },
      };
    case "query.customTitles.added":
      return {
        ...state,
        query: {
          ...state.query,
          customTitles: [...state.query.customTitles, action.payload.trim()],
        },
      };
    case "query.customTitles.removed":
      return {
        ...state,
        query: {
          ...state.query,
          customTitles: state.query.customTitles.filter(
            (t) => t !== action.payload
          ),
        },
      };
    case "candidateMatchList.searchRequest.success":
      return {
        ...state,
        query: {
          ...state.query,
          titles: action.payload.query.titles,
        },
        candidateMatchList: {
          ...state.candidateMatchList,
          matches: action.payload.results,
          loading: false,
        },
      };
    case "candidateMatchList.searchRequest.error":
      return {
        ...state,
        candidateMatchList: {
          ...state.candidateMatchList,
          loading: false,
        },
      };
    case "candidateMatchList.candidateMatchSelected":
      return {
        ...state,
        candidateMatchList: {
          ...state.candidateMatchList,
          selectedMatch: action.payload,
        },
      };
    default:
      return state;
  }
}

export const initialState = {
  editor: {
    loading: true,
    match: {},
    imdbMatchUpdate: undefined,
  },
  query: {
    titles: [],
    customTitles: [],
  },
  candidateMatchList: {
    loading: true,
    selectedMatch: undefined,
    matches: [],
  },
};
