import { SecondSorting } from "@telia-company/tv.no-play-cms-common/collection/types";
import React from "react";
import { Form } from "react-bootstrap";

interface SecondSortingOption {
  name: string;
  value: SecondSorting | "none";
}

interface Props {
  selected?: SecondSorting;
  onChange: (newValue: SecondSorting) => void;
  readOnly: boolean;
}

export const SecondSortPicker = ({ selected, onChange, readOnly }: Props) => {
  const options: SecondSortingOption[] = [
    { name: "None", value: "none" },
    { name: "Alphabetical", value: "title-asc" },
    { name: "Reverse alphabetical (Å-A)", value: "title-desc" },
    { name: "Lowest IMDb rating", value: "imdb-rating-asc" },
    { name: "Highest IMDb rating", value: "imdb-rating-desc" },
    {
      name: "Production year, oldest first",
      value: "productionDate-asc",
    },
    {
      name: "Production year, newest first",
      value: "productionDate-desc",
    },
    {
      name: "Random",
      value: "random",
    },
  ];
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor="collection-filters-second-sort-by-selector">
        Second sort by
      </Form.Label>
      <Form.Select
        style={{ width: "auto" }}
        id="collection-filters-second-sort-by-selector"
        value={selected}
        onChange={(event) => onChange(event.target.value as SecondSorting)}
        disabled={readOnly}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};
