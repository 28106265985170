import { DateTime } from "luxon";
import useSWR from "swr";

type DataPointResponse = { date: string; count: number };

interface StatsResponse {
  [report_type: string]: DataPointResponse[];
}

type DataPoint = { date: DateTime; count: number };

interface Stats {
  [report_type: string]: DataPoint[];
}

export const useContentReportStatsHistory = (
  from: string,
  to: string
): Stats | undefined => {
  const { data } = useSWR<StatsResponse>(
    `insight/stats-history?from=${from}&to=${to}`
  );

  if (!data) {
    return undefined;
  }

  const parsedData: Stats = {};
  for (const [reportType, reportStats] of Object.entries(data)) {
    parsedData[reportType] = parseStats(reportStats);
  }
  return parsedData;
};

const parseStats = (stats: DataPointResponse[]): DataPoint[] =>
  stats.map((dataPoint) => ({
    date: DateTime.fromISO(dataPoint.date),
    count: dataPoint.count,
  }));
