import { debounce, uniq } from "lodash";
import queryString from "query-string";
import { useState, useCallback, useMemo, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { mergeReducer } from "client/hooks";
import { request } from "client/utils";
import {
  CLIENT_TYPE_OPTIONS,
  getCollectionType,
  getContentType,
} from "client/views/CollectionEditor/utils";

function getAnyTypeFilters(allowed) {
  if (!allowed) return { collection_type: undefined, content_type: undefined };
  const collection_type = [];
  const content_type = [];
  for (const type of allowed) {
    collection_type.push(getCollectionType(type));
    content_type.push(getContentType(type));
  }
  return {
    collection_type: uniq(collection_type),
    content_type: uniq(content_type),
  };
}

export function useCollections(show, query, allowedClientTypes) {
  const [collections, setCollections] = useState([]);

  const fetchData = useCallback(
    ({ clientType, ...rest }) => {
      const typeFilter = !clientType
        ? getAnyTypeFilters(allowedClientTypes)
        : {
            collection_type: getCollectionType(clientType),
            content_type: getContentType(clientType),
          };
      const params = { ...typeFilter, ...rest };
      request("collections/card?" + queryString.stringify(params))
        .then((result) => {
          setCollections(result);
        })
        .catch((err) => {
          toast.error("Failed to load collections: " + err.message);
          console.error(err);
        });
    },
    [allowedClientTypes]
  );

  const fetchDataDebounced = useMemo(
    () => debounce(fetchData, 250),
    [fetchData]
  );

  useEffect(() => {
    if (!show) return;
    fetchDataDebounced(query);
  }, [query, show]);
  return collections;
}

export function useQuery() {
  return useReducer(mergeReducer, {
    title: "",
    sortBy: "date",
    activeOnly: true,
    clientType: undefined,
  });
}

export function useCollectionTypeOptions(allowedCollectionTypes) {
  return useMemo(() => {
    const options = [{ value: undefined, label: "Any" }];
    if (!allowedCollectionTypes) {
      options.push(...CLIENT_TYPE_OPTIONS);
    } else {
      options.push(
        ...allowedCollectionTypes.map((type) =>
          CLIENT_TYPE_OPTIONS.find((option) => option.value === type)
        )
      );
    }
    return options;
  }, [allowedCollectionTypes]);
}
