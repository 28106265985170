import { sleep } from "@telia-company/tv.no-play-cms-common/api/util";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { request } from "client/utils";
import { AssetDetailsResponse } from "./types";

interface UpdatableMediaContentIdProps {
  data: AssetDetailsResponse;
  mutate: () => Promise<unknown>;
}

export const UpdatableMediaContentId = ({
  data,
  mutate,
}: UpdatableMediaContentIdProps) => {
  const [mediaContentId, setMediaContentId] = useState<number>();
  useEffect(() => {
    setMediaContentId(data?.MC?.id);
  }, [data]);

  const updateMediaContentId = async () => {
    request("epg/reconciliation/asset", {
      method: "PATCH",
      body: JSON.stringify({
        assetId: parseInt(data.id),
        mediaContentId: mediaContentId,
      }),
    })
      .then(async () => {
        await sleep(2);
        await mutate();
      })
      .catch((reason) => toast.error(`Save failed: ${reason}`));
  };

  return (
    <>
      <Form.Label htmlFor="media-content-id">
        (Parent) media content id
      </Form.Label>
      <InputGroup style={{ maxWidth: "300px" }}>
        <Form.Control
          id="media-content-id"
          value={mediaContentId || ""}
          onChange={(e) =>
            setMediaContentId(
              e.target.value ? parseInt(e.target.value) : undefined
            )
          }
          className="no-round-border-right"
        />
        <Button
          onClick={updateMediaContentId}
          disabled={mediaContentId === data.MC.id}
        >
          Save
        </Button>
      </InputGroup>
    </>
  );
};
