import { CollectionFilters } from "@telia-company/tv.no-play-cms-common/collection/types";

export interface CollectionPreview {
  contents: MediaCollectionItem[];
  totalHits: number;
  genres: { [genre: string]: number };
  providers: { [providerId: number]: number };
  channels: { [channelId: number]: number };
}

export const CollectionClientType = {
  Row: "row",
  RowOfThemes: "rowOfThemes",
  RowWithGraphics: "rowWithGraphics",
  HubWithGraphics: "hubWithGraphics",
  FootballEvent: "footballEvent",
};

export type CollectionClientType =
  (typeof CollectionClientType)[keyof typeof CollectionClientType];

export const CollectionType = {
  Simple: "simple",
  Page: "page",
} as const;

export type CollectionType =
  (typeof CollectionType)[keyof typeof CollectionType];

export const CollectionContentType = {
  Media: "media",
  Collections: "collections",
  Matches: "matches",
} as const;

export type CollectionContentType =
  (typeof CollectionContentType)[keyof typeof CollectionContentType];

export const CollectionRowType = {
  VerticalCards: "verticalCards",
  HorizontalCards: "horizontalCards",
  HorizontalStoryline: "horizontalStoryline",
  FullWidth: "fullWidth",
  SmallWidescreen: "small16:9",
  LargeWidescreen: "large16:9",
  VerticalPitch: "verticalPitch",
  HorizontalPitch: "horizontalPitch",
} as const;

export type CollectionRowType =
  (typeof CollectionRowType)[keyof typeof CollectionRowType];

export const CardOrientation = {
  Landscape: "landscape",
  Portrait: "portrait",
  Auto: "auto",
} as const;

export type CardOrientation =
  (typeof CardOrientation)[keyof typeof CardOrientation];

export interface MediaCollectionItem {
  id: number;
  title?: string;
  storyline?: string;
}

export type MediaCollectionCardContentType = "asset" | "mediaContent";

export interface Collection {
  // Common properties for all collections
  title: string;
  description: string;
  notes: string;
  collectionType: CollectionType;
  contentType: CollectionContentType;
  rowType: CollectionRowType;
  autoOrientation: boolean;
  personalizeOrder: boolean;
  enabled: boolean;
  activeContent: boolean;
  dynamic: boolean;

  // Collection contents. Depends on the contentType and dynamic properties.
  assets: MediaCollectionItem[];
  mediaContents: MediaCollectionItem[];
  collections: number[];
  footballEvent?: number;
  filters: CollectionFilters;

  // Collection graphics. Only available for the "page" collectionType.
  pageHeader?: string;
  pageHeaderMobile?: string;
  collectionCard?: string;
  pageBackground?: string;
  backgroundColor?: string;
  backgroundGradient: boolean;
}

export type { CollectionOnChangeHandlers } from "./utils";

export interface Party {
  given_name: string;
  last_name: string;
}

export interface PartyResponse {
  value: Party[];
}
