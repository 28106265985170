import React from "react";
import { Col, Row } from "react-bootstrap";
import { parseDateToDatetimeString } from "client/utils/TableCommon";

export function getComposerTitle(mode, id, label) {
  switch (mode) {
    case "create":
      return "Create " + label;
    case "edit":
      return `Edit ${label} (${id})`;
    default:
      return "Unknown " + mode;
  }
}

//#region Provisioner picker functions
/**
 * Returns the id of an option. Passed to the Select component
 * used by provisioner pickers
 * @param {object} option
 * @returns {string} id
 */
export const getOptionId = (option) => {
  if (option) {
    return option.id;
  }
  return undefined;
};

const PICKER_LABELS = {
  "DVB Triplet": {
    header: "serviceType",
    keys: ["originalNetworkId", "transportStreamId", "serviceId"],
    date: "updated",
  },
  "DVB Cable Stream": {
    header: "modulation",
    keys: ["symbolRate", "frequencyInKHz"],
    date: "updated",
  },
  "DVB Cable Tuning Plan": {
    header: "serviceType",
    keys: [
      "originalNetworkId",
      "transportStreamId",
      "serviceId",
      "modulation",
      "symbolRate",
      "frequencyInKHz",
    ],
    date: "updated",
  },
  "DVB Cable AES Tuning Plan": {
    header: "serviceType",
    keys: [
      "originalNetworkId",
      "transportStreamId",
      "serviceId",
      "modulation",
      "symbolRate",
      "frequencyInKHz",
    ],
    date: "updated",
  },
  "DVB IPTV Stream": {
    header: "protocol",
    keys: ["ipAddress", "port"],
    date: "updated",
  },
  "DVB IPTV FCC": {
    header: "protocol",
    keys: ["ipAddress", "port"],
    date: "updated",
  },
  "DVB IPTV Tuning Plan": {
    header: "serviceType",
    keys: [
      "originalNetworkId",
      "transportStreamId",
      "serviceId",
      "protocol",
      "ipAddress",
      "port",
      "ssrcId",
    ],
    date: "updated",
  },
  "DVB IPTV AES Tuning Plan": {
    header: "serviceType",
    keys: [
      "originalNetworkId",
      "transportStreamId",
      "serviceId",
      "protocol",
      "ipAddress",
      "port",
      "ssrcId",
    ],
    date: "updated",
  },
  "TV Provider": {
    header: "name",
    keys: ["videoMetadataId"],
    date: "updated",
  },
  Lineup: {
    header: "name",
    keys: ["dvbBouquetId"],
    date: "updated",
  },
  "Config Draft": {
    header: "id",
    keys: [],
    date: "created",
  },
  default: {
    header: "",
    keys: [],
    date: "updated",
  },
};

/**
 * Generates a function based on content type, eg TV Provider,
 * which in turn returns a formatted label for a given option
 * @param {string} contentType
 * @returns {function}
 */
export function formatOptionLabel(contentType) {
  const label = PICKER_LABELS[contentType] || PICKER_LABELS.default;
  return (option) => (
    <Row className="mx-0">
      {label.header && <Col as="strong">{option[label.header]}</Col>}
      {label.keys.map((key) => (
        <Col key={key}>{option[key]}</Col>
      ))}
      <Col>{parseDateToDatetimeString(option[label.date])}</Col>
    </Row>
  );
}

export function formatGroupLabel(contentType) {
  const label = PICKER_LABELS[contentType] || PICKER_LABELS.default;
  return () => (
    <Row className="mx-0">
      {label.header && <Col>{label.header}</Col>}
      {label.keys.map((key) => (
        <Col key={key}>{key}</Col>
      ))}
      <Col>{label.date}</Col>
    </Row>
  );
}

export function filterOption(contentType) {
  const label = PICKER_LABELS[contentType] || PICKER_LABELS.default;
  return ({ data }, search) => {
    const content = [
      data[label.header],
      ...label.keys.map((key) => data[key]),
      parseDateToDatetimeString(data[label.date]),
    ];
    const filters = search.split(" ");
    for (const filter of filters) {
      if (
        !content.some((val) =>
          (val || "").toString().toLowerCase().includes(filter.toLowerCase())
        )
      )
        return false;
    }
    return true;
  };
}

/**
 * Returns the endpoint associated with a given type of content, eg TV Provider
 * @param {string} contentType
 * @returns {string}
 */
export function getProvisionerPickerEndpoint(contentType) {
  switch (contentType) {
    case "Config Deployment":
      return "provisioning/configs/deployments";
    case "Config Draft":
      return "provisioning/configs/drafts";
    case "Config Publication":
      return "provisioning/configs/publications";
    case "DVB Cable Tuning Plan":
      return "provisioning/dvb/cable/tuning-plans";
    case "DVB Cable AES Tuning Plan":
      return "provisioning/dvb/cable/tuning-plans-aes";
    case "DVB Cable Stream":
      return "provisioning/dvb/cable/streams";
    case "DVB IPTV Tuning Plan":
      return "provisioning/dvb/iptv/tuning-plans";
    case "DVB IPTV AES Tuning Plan":
      return "provisioning/dvb/iptv/tuning-plans-aes";
    case "DVB IPTV Stream":
      return "provisioning/dvb/iptv/streams";
    case "DVB IPTV FCC":
      return "provisioning/dvb/iptv/fcc";
    case "DVB Triplet":
      return "provisioning/dvb/triplets";
    case "Lineup":
      return "provisioning/lineups";
    case "TV Provider":
      return "provisioning/content-providers/tv";
    case "TV Provider Placement":
      return "provisioning/content-providers/tv/placements";
    case "VOD Provider":
      return "provisioning/content-providers/vod";
    default:
      return null;
  }
}

/**
 * Returns the file name prefix for a given type, eg TV Provider
 * @param {string} contentType
 * @returns {string}
 */
export function getDownloadPrefix(contentType) {
  return contentType.replace(/\s/g, "");
}
//#endregion
