import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { request } from "client/utils";
import SeasonsTable from "./SeasonsTable";

const Leagues = () => {
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request("sports/seasons")
      .then((result) => {
        setLoading(false);
        setSeasons(result);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  const currentSeasons = seasons.filter((s) => s.active);
  const previousSeasons = seasons.filter((s) => !s.active);

  return (
    <Container fluid className="p-4">
      <Row>
        <Col>
          <h1 className="mt-0">Current seasons</h1>
          <SeasonsTable seasons={currentSeasons} loading={loading} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Previous seasons</h1>
          <SeasonsTable seasons={previousSeasons} loading={loading} />
        </Col>
      </Row>
    </Container>
  );
};

export default Leagues;
