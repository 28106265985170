import { isNumeric } from "@telia-company/tv.no-play-cms-common/api/util";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { withChannels } from "client/App";
import Table from "components/tables";
import { displayName, formatMatchDate } from "./utils";

const MatchTable = ({
  matches,
  selectedMatch,
  dispatch,
  loading,
  channels,
}) => {
  const groupCount = _.uniqBy(matches, "group.id").length;
  const showGroups = groupCount > 1;

  const setSelectedMatch = useCallback((match) => {
    dispatch({ type: "match.selected", payload: match });
  }, []);

  const columns = useMemo(() => {
    let cols = [
      {
        Header: "Round",
        accessor: "week",
      },
      {
        Header: "Group",
        accessor: "group.name",
        Cell: ({ value }) => value || "N/A",
      },
      {
        Header: "Home",
        accessor: ({ homeTeam }) => displayName(homeTeam),
        id: "homeTeam",
      },
      {
        Header: "Away",
        accessor: ({ awayTeam }) => displayName(awayTeam),
        id: "awayTeam",
      },
      {
        Header: "Start",
        accessor: ({ date, time }) => formatMatchDate(date, time),
        id: "start",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Result",
        accessor: ({ fulltimeHomeScore, fulltimeAwayScore }) => {
          if (isNumeric(fulltimeHomeScore) && isNumeric(fulltimeAwayScore)) {
            return `${fulltimeHomeScore} - ${fulltimeAwayScore}`;
          }
          return "-";
        },
        id: "result",
      },
      {
        Header: "Channel",
        accessor: "mapping.asset.channel_id",
        Cell: ({ value }) => {
          if (!value) return "";
          const channelMap = _.fromPairs(channels.map((c) => [c.id, c.value]));
          return channelMap[value] || "";
        },
      },
    ];
    if (!showGroups) {
      cols = cols.filter((c) => c.Header !== "Group");
    }
    return cols;
  }, [showGroups, channels]);

  return (
    <Table
      loading={loading}
      className="mb-0"
      columns={columns}
      data={matches}
      selectedItem={selectedMatch}
      onClick={setSelectedMatch}
    />
  );
};

export default withChannels(MatchTable);
