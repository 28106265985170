import _ from "lodash";
import React, { useMemo, useCallback } from "react";
import Select from "react-select";
import { ColumnInstance } from "react-table-v7";
import { GlyphButton } from "client/components/buttons";
import { Asset } from "../types";
import { SelectOption, Option } from "./FilterSelect";

interface Props {
  columns: ColumnInstance<Asset>[];
  selectedColumns?: string[];
  onChange: (columns: string[]) => void;
  isOpen: boolean;
  onChangeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ColumnPicker({
  columns,
  selectedColumns,
  onChange,
  isOpen,
  onChangeOpen,
}: Props) {
  // The available options are all columns that can be toggled on or off,
  // i.e all columns that aren't flagged as permanent
  const options = useMemo(
    () =>
      columns
        .filter((c) => !c.permanent)
        .map((c) => {
          const value = c.id;
          const label =
            c.abbreviation || (_.isString(c.Header) ? c.Header : c.id);
          return { value, label };
        }),
    [columns]
  );
  const selectedOptions: readonly SelectOption[] = options.filter(
    (o) => !selectedColumns || selectedColumns.includes(o.value)
  );

  const updateSelection = useCallback(
    (options: readonly SelectOption[]) => {
      const columnIds = options.map((o) => o.value);
      onChange(columnIds);
    },
    [onChange]
  );

  const toggleMenu = useCallback(
    () => onChangeOpen((isOpen) => !isOpen),
    [onChangeOpen]
  );
  const closeMenu = useCallback(() => onChangeOpen(false), [onChangeOpen]);

  return (
    <div className="position-relative">
      <GlyphButton
        glyph="settings"
        variant="light"
        className="w-100"
        onClick={toggleMenu}
        title="Show/hide columns"
      />
      {isOpen ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
          }}
          onClick={closeMenu}
        />
      ) : null}
      <Select
        isMulti
        menuIsOpen={isOpen}
        options={options}
        value={selectedOptions}
        onChange={updateSelection}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        components={{
          Option,
          Control: () => null,
        }}
        styles={{
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
            position: "absolute",
            top: 0,
            right: 0,
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: "100%",
          }),
          option: (base) => ({
            ...base,
            color: "#333",
            backgroundColor: "white",
          }),
        }}
      />
    </div>
  );
}
