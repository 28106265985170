import queryString from "query-string";
import { CLIENT_CONFIG } from "client/config";

export function getCloudImagePath(
  src: string,
  width?: number,
  height?: number
) {
  if (!src) return null;
  const imagePath = `${CLIENT_CONFIG.CDN_DOMAIN}${src}`;
  if (!width && !height) return imagePath;
  return `${imagePath}?${queryString.stringify(
    {
      h: height,
      w: width,
    },
    { skipNull: true }
  )}`;
}

export function getRequestImageSize(
  componentWidth: number,
  width: number,
  height: number
) {
  // assume that only the width of the element determines the size of the image
  return {
    width: componentWidth ? Math.round(componentWidth) : "original",
    height:
      componentWidth && width && height
        ? Math.round(componentWidth * (height / width))
        : "original",
  };
}
