import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { CmsPure } from "client/utils";
import { getComposerTitle } from "../provisioningUtils";

const LABEL = "DVB IPTV Stream";

class DVBIPTVStreamComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      protocol: "",
      ipAddress: "",
      port: 0,
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) return;

    this.props.get(id, (state) => this.setState(state));
  }

  create = (event) => {
    event.preventDefault();
    const payload = this.state;
    this.props.put(payload, this.props.id, LABEL);
  };

  render() {
    const { protocol, ipAddress, port } = this.state;
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{title}</h1>
        <Row>
          <Col as={Form.Group} xs={3}>
            <Form.Label>Protocol</Form.Label>
            <Form.Control
              type="text"
              value={protocol}
              placeholder="Eg. udp"
              onChange={(e) => this.setState({ protocol: e.target.value })}
            />
          </Col>
          <Col as={Form.Group} xs={6}>
            <Form.Label>IP Address</Form.Label>
            <Form.Control
              type="text"
              value={ipAddress}
              placeholder="255.255.255.255"
              onChange={(e) => this.setState({ ipAddress: e.target.value })}
            />
          </Col>
          <Col as={Form.Group} xs={3}>
            <Form.Label>Port</Form.Label>
            <Form.Control
              type="number"
              value={port}
              onChange={(e) => this.setState({ port: Number(e.target.value) })}
            />
          </Col>
        </Row>
        <Button className="pull-right mt-4" variant="primary" type="submit">
          {this.props.mode}
        </Button>
      </Form>
    );
  }
}

export default DVBIPTVStreamComposer;
