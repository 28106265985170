import React from "react";
import Select from "react-select";
import { ArrayParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

const kindOptions = [
  { label: "Episode", value: "episode" },
  { label: "Season", value: "season" },
  { label: "Series", value: "series" },
  { label: "Movie", value: "movie" },
  { label: "Show", value: "show" },
  { label: "Program", value: "program" },
];
export const KindFilter = () => {
  const [kind, setKind] = useQueryParam("kind", ArrayParam);
  const resetPage = useResetPage();
  const selectKind = (newKind: string[]) => {
    setKind(newKind);
    resetPage();
  };
  const selectedKinds = (kind || []).map((k) =>
    kindOptions.find((option) => option.value === k)
  );

  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      onChange={(selected) =>
        selectKind(
          selected.map(
            (option) => (option as { label: string; value: string }).value
          )
        )
      }
      options={kindOptions}
      placeholder="Select kinds"
      hideSelectedOptions={true}
      value={selectedKinds}
    />
  );
};
