import autobind from "autobind-decorator";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import "react-table/react-table.css";
import { ProvidersContext } from "client/App";
import { CmsPure, request } from "client/utils";
import EditPriceOverrideDialog from "client/views/TVOD/EditPriceOverrideDialog";
import DateTimeControl from "components/controls/datetime/DateTimeControl";
import Table, { getManageColumn } from "components/tables";

class TvodPriceOverride extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      priceOverrides: [],
      fromDate: moment().hour(0).minute(0).second(0).millisecond(0),
      toDate: null,
      showEditDialog: false,
      editDialogOverrideId: null,
    };
  }

  componentDidMount() {
    this.onFetch();
  }

  @autobind
  async onFetch() {
    const encodeDate = (d) => {
      if (!d) return undefined;
      return d.toISOString();
    };
    const params = {
      fromDate: encodeDate(this.state.fromDate),
      toDate: encodeDate(this.state.toDate),
    };
    const priceOverrides = await request(
      `tvod/price-overrides?${queryString.stringify(params)}`
    );
    console.log(priceOverrides);
    this.setState({ priceOverrides });
  }

  @autobind
  onChangeFromDate(d) {
    this.setState({ fromDate: d });
  }

  @autobind
  onChangeToDate(d) {
    this.setState({ toDate: d });
  }

  @autobind
  onAddPriceOverride() {
    this.setState({ editDialogOverride: null, showEditDialog: true });
  }

  @autobind
  onClosePriceOverrideDialog() {
    this.setState({ showEditDialog: false });
    this.onFetch();
  }

  @autobind
  onEdit(override) {
    this.setState({ editDialogOverride: override, showEditDialog: true });
  }

  @autobind
  onDelete(override) {
    const id = override.override_id;
    request(`tvod/price-overrides/${id}`, {
      method: "DELETE",
    }).then(() => {
      const overrides = this.state.priceOverrides.filter(
        (override) => override.override_id != id
      );
      this.setState({ priceOverrides: overrides });
    });
  }

  render() {
    const columns = [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Provider",
        accessor: "provider_id",
        Cell: ({ value }) => {
          return (
            <ProvidersContext.Consumer>
              {(providers) => {
                const p = providers.find((p) => p.id == value);
                return p ? p.value : value;
              }}
            </ProvidersContext.Consumer>
          );
        },
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Start date",
        accessor: "override_start",
        Cell: ({ value }) => moment(value).toLocaleString(),
      },
      {
        Header: "End date",
        accessor: "override_end",
        Cell: ({ value }) => moment(value).toLocaleString(),
      },
      getManageColumn(this.onEdit, this.onDelete),
    ];

    return (
      <>
        <EditPriceOverrideDialog
          key={Math.random()}
          show={this.state.showEditDialog}
          onClose={this.onClosePriceOverrideDialog}
          override={this.state.editDialogOverride}
        />
        <div className="container-fluid">
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col xs={12}>
              <Row>
                <Col xs={2}>
                  <DateTimeControl
                    placeholder="From"
                    closeOnSelect
                    value={this.state.fromDate}
                    onChange={this.onChangeFromDate}
                  />
                </Col>
                <Col xs={2}>
                  <DateTimeControl
                    placeholder="To"
                    closeOnSelect
                    inputProps={{ placeholder: "To" }}
                    value={this.state.toDate}
                    onChange={this.onChangeToDate}
                    acceptEmpty
                  />
                </Col>
                <Col xs={5}>
                  <Button style={{ width: "30%" }} onClick={this.onFetch}>
                    FETCH
                  </Button>
                </Col>
                <Col xs={3}>
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={this.onAddPriceOverride}
                  >
                    Add Price Override
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Table
          data={this.state.priceOverrides}
          columns={columns}
          loading={this.props.loading}
          getRowId={(row) => row.override_id}
          paginate
        />
      </>
    );
  }
}

export default TvodPriceOverride;
