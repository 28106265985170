import { MediaContentData } from "@telia-company/tv.no-play-cms-common/db/definitions/MediaContent";
import { Dictionary } from "lodash";
import React from "react";
import useSWR from "swr";
import { getQueryString } from "client/hooks";
import { request } from "client/utils";

export function useMediaContentHistory(
  id: string,
  values: Dictionary<string> = {}
) {
  const { data, isValidating, error, mutate } = useSWR(
    `mediacontent/v2/history/${id}?${getQueryString(values)}`
  );

  const rollback = React.useCallback(
    async (data: MediaContentData) => {
      await request(`mediacontent/editor/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          data: data,
          user: "get",
          extraData: {},
        }),
      });
      mutate();
    },
    [mutate]
  );

  return {
    data: data ? data.data : [],
    totalHits: data ? data?.total : 0,
    isLoading: isValidating || (!data && !error),
    rollback,
  };
}
