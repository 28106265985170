import { PropTypes } from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import { PublicationTypes } from "client/types/PublicationTypes";
import { request } from "client/utils";
import { EmptyCmsCard, PromotionCard } from "components/cards";
import { PromotionSearchModal } from "./PromotionSearchModal";

export function PromotionPicker(props) {
  const { selectedId, readOnly, initialValue, promotionType } = props;
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [selectedPromotion, setSelectedPromotion] = React.useState(
    // pre-render only if passed a title
    initialValue && initialValue.title ? initialValue : null
  );

  const onSelectPromotion = React.useCallback(
    (collectionId) => {
      setModalVisible(false);
      props.onChange(collectionId);
    },
    [props.onChange]
  );

  React.useEffect(() => {
    if (!selectedId) {
      if (selectedPromotion) setSelectedPromotion(null);
      return;
    }
    request("promotions/card/" + selectedId)
      .then((result) => {
        setSelectedPromotion(result);
      })
      .catch((err) => {
        toast.error("Failed to load promotions: " + err.message);
        console.error(err);
      });
  }, [selectedId]);

  return (
    <>
      <PromotionSearchModal
        show={isModalVisible}
        onHide={() => setModalVisible(false)}
        onSelectPromotion={onSelectPromotion}
        promotionType={promotionType}
      />
      {selectedPromotion ? (
        <PromotionCard
          id={selectedPromotion.id}
          item={selectedPromotion}
          promotionType={promotionType}
          onClick={() => setModalVisible(true)}
          canClick={!readOnly}
          showLink
        />
      ) : (
        <EmptyCmsCard
          onClick={() => setModalVisible(true)}
          canClick={!readOnly}
          variant={promotionType}
        />
      )}
    </>
  );
}

PromotionPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
  initialValue: PropTypes.shape({
    title: PropTypes.string,
  }),
  promotionType: PropTypes.oneOf([
    PublicationTypes.TOP_PROMO,
    PublicationTypes.COVER_PROMO,
  ]).isRequired,
};
