import queryString from "query-string";
import React, { useContext, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import "react-table/react-table.css";
import { ProvidersContext } from "client/App";
import { request } from "client/utils";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import Table from "components/tables";
import TvodSearchFilter from "./TvodSearchFilter";

function TvodReport() {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const getData = async (filter) => {
    setFetching(true);
    const getParams = queryString.stringify(filter);
    const results = await request(`tvod/search?${getParams}`);
    setData(results);
    setFetching(false);
    return results ? results.length : null;
  };

  const columns = [
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => (
        <Badge bg="light" text="dark">
          {value.toUpperCase()}
        </Badge>
      ),
      minWidth: 12,
    },
    {
      Header: "Transaction Id",
      accessor: "transaction_id",
      minWidth: 55,
    },
    {
      Header: "Title",
      accessor: "title",
      minWidth: 40,
    },
    {
      Header: "Provider Name",
      accessor: "provider_name",
      minWidth: 20,
    },
    {
      Header: "Price",
      accessor: "price",
      minWidth: 12,
    },
    {
      id: "start",
      Header: "Start",
      accessor: ({ start }) => parseDateToDatetimeString(start),
      minWidth: 30,
    },
    {
      id: "end",
      Header: "End",
      accessor: ({ end }) => parseDateToDatetimeString(end),
      minWidth: 30,
    },
    {
      Header: "Icc Import Status",
      accessor: "icc_import_status",
      Cell: ({ value }) => (
        <Badge bg="light" text="dark">
          {value}
        </Badge>
      ),
      minWidth: 25,
    },
  ];

  const providers = useContext(ProvidersContext);

  console.log({ data });

  return (
    <Container fluid>
      <Row style={{ marginTop: 10 }}>
        <Col xs={12}>
          <TvodSearchFilter
            getData={getData}
            providerOptions={providers.map(({ id, value }) => ({
              value: Number(id),
              label: value,
            }))}
          />
          <Table
            data={data}
            columns={columns}
            initialState={{ pageSize: 50 }}
            getRowId={(row) => row.transaction_id}
            loading={fetching}
            paginate
          />
        </Col>
      </Row>
    </Container>
  );
}

export default TvodReport;
