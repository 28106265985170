import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";

const options = ["or", "and"];

interface Props {
  genreValue: string[];
  genreOperatorValue: "or" | "and";
  onChange: (
    event: ChangeEvent<HTMLSelectElement>,
    genreValue: string[]
  ) => void;
}

export const FilterGenreOperatorPicker = ({
  genreValue,
  genreOperatorValue,
  onChange,
}: Props) => {
  return (
    <>
      <Form.Label htmlFor="collection-genre-operator-selector">
        Operator
      </Form.Label>
      <Form.Select
        id="collection-genre-operator-selector"
        value={genreOperatorValue}
        onChange={(event) => onChange(event, genreValue)}
        className="mb-3"
      >
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </Form.Select>
    </>
  );
};
