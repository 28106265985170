import { orderBy } from "lodash";
import React, { useCallback, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import KeyValuePair from "components/KeyValuePair";
import { StatusBadge } from "components/badges";
import { GlyphButton } from "components/buttons";
import {
  getAssetAvailabilityStatusVariant,
  getAssetLicenseStatusVariant,
  getAssetPlayStatusVariant,
  getRevisionStatusVariant,
} from "../status";
import { AssetEditForm } from "./AssetEditForm";
import ClearDataQualityWarnings from "./ClearDataQualityWarnings";
import EvaluateStatus from "./EvaluateStatus";
import { toDate } from "./toNode";

function getLatest(array, property) {
  const ordered = orderBy(array, property, "desc");
  return ordered.length ? ordered[0] : null;
}

function AssetSummaryTab({
  id,
  created,
  updated,
  revisions,
  play_status,
  license_status,
  availability,
}) {
  const lastRevision = getLatest(revisions, "updated") || {};
  const [isAssetEditorOpen, setIsAssetEditorOpen] = useState(false);
  const openAssetEditor = useCallback(
    () => setIsAssetEditorOpen(true),
    [setIsAssetEditorOpen]
  );
  const closeAssetEditor = useCallback(
    () => setIsAssetEditorOpen(false),
    [setIsAssetEditorOpen]
  );

  return (
    <>
      <Row>
        <Col className="lead my-2">
          Asset Summary
          {!isAssetEditorOpen && (
            <GlyphButton
              variant="link"
              glyph="EDIT"
              title="Edit Provider Asset ID and YAAF ID"
              onClick={openAssetEditor}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <AssetEditForm
            id={id}
            disabled={!isAssetEditorOpen}
            onClose={closeAssetEditor}
          />
        </Col>
        {!isAssetEditorOpen && (
          <>
            <Col>
              <KeyValuePair
                id="Play status"
                value={
                  <StatusBadge
                    status={play_status}
                    statusToVariant={getAssetPlayStatusVariant}
                  />
                }
              />
              <KeyValuePair
                id="License status"
                value={
                  <StatusBadge
                    status={license_status}
                    statusToVariant={getAssetLicenseStatusVariant}
                  />
                }
              />
            </Col>
            <Col>
              <KeyValuePair id="Created" value={toDate(created)} />
              <KeyValuePair id="Updated" value={toDate(updated)} />
              <KeyValuePair
                id="Availability"
                value={
                  <StatusBadge
                    status={availability}
                    statusToVariant={getAssetAvailabilityStatusVariant}
                  />
                }
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <h3 className="lead">Manage asset</h3>
          <ButtonGroup>
            <ClearDataQualityWarnings assetId={id} />
            <EvaluateStatus assetId={id} />
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h3 className="lead">Last Revision Summary</h3>
        </Col>
        <Col xs={4}>
          <KeyValuePair
            id="Last Play Status"
            value={
              <StatusBadge
                status={lastRevision.play_status}
                statusToVariant={getRevisionStatusVariant}
              />
            }
          />
        </Col>
      </Row>
    </>
  );
}

export default AssetSummaryTab;
