import React from "react";
import { Card, Button } from "react-bootstrap";
import IMDbTitleCard from "./IMDbTitleCard";

const CandidateTitleCard = ({ imdb, imdbMatchId, dispatch }) => (
  <IMDbTitleCard imdb={imdb} className="candidate-imdb-title">
    {imdbMatchId === imdb.imdbId ? (
      <Card.Text>
        {"\u2705"} This IMDb title is currently associated with the media
        content.
      </Card.Text>
    ) : (
      <>
        <Card.Text>
          Should this IMDb title be associated with the media content?
        </Card.Text>
        <Button
          onClick={() =>
            dispatch({
              type: "editor.imdbMatch.updated",
              payload: imdb.imdbId,
            })
          }
        >
          Yes, use this title
        </Button>
      </>
    )}
  </IMDbTitleCard>
);

export default CandidateTitleCard;
