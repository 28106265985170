import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withChannels } from "client/App";
import Table from "client/components/tables";
import { formatRelativeTimestamp } from "client/utils/TableCommon";

const CatchupListResults = ({
  assets,
  channels,
  loading,
  sortBy,
  setSortBy,
}) => {
  const channelMap = useMemo(
    () => _.fromPairs(channels.map(({ id, value }) => [id, value])),
    [channels]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ row, value }) => (
          <Link
            to={`/enrich/mediacontent/editor/${row.original.contentId}`}
            style={{ textDecoration: "underline" }}
          >
            {value}
          </Link>
        ),
        disableSortBy: true,
      },
      {
        Header: "Top title",
        accessor: "topTitle",
        Cell: ({ row, value }) => (
          <Link
            to={`/enrich/mediacontent/editor/${row.original.topId}`}
            style={{ textDecoration: "underline" }}
          >
            {value}
          </Link>
        ),
        disableSortBy: true,
      },
      {
        Header: "CRID",
        accessor: "crid",
        disableSortBy: true,
      },
      {
        Header: "Channel",
        accessor: "channelId",
        Cell: ({ value }) => channelMap[value] || "N/A",
      },
      {
        Header: "Broadcast",
        accessor: "broadcast",
        Cell: ({ value }) => formatRelativeTimestamp(new Date(value)),
      },
      {
        Header: "Catchup end",
        accessor: "catchupEnd",
        Cell: ({ value }) => formatRelativeTimestamp(new Date(value)),
      },
    ],
    [channelMap]
  );

  const onChange = useCallback(
    ({ sortBy }) => setSortBy(sortBy[0]),
    [setSortBy]
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Table
            columns={columns}
            data={assets}
            loading={loading}
            manualSortBy
            disableMultiSort
            disableSortRemove
            initialState={{ sortBy }}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withChannels(CatchupListResults);
