import React from "react";
import { Accordion, Badge } from "react-bootstrap";
import { ProgramChildren } from "../ProgramDetails/ProgramChildren";
import { ProgramDetails } from "../ProgramDetails/ProgramDetails";
import { DeleteMatchButton } from "../ProgramMatch/DeleteMatchButton";
import { UpdateMatch } from "../ProgramMatch/UpdateMatch";
import { MarkAsDominantButton } from "./MarkAsDominantButton";

interface Props {
  fromId: string;
  toId: string;
  selectedFromId?: string;
  matchId: number;
  isDominant: boolean;
  mutate: () => void;
  path: string;
}

export const MatchDetails = ({
  fromId,
  toId,
  selectedFromId,
  matchId,
  isDominant,
  mutate,
  path,
}: Props) => {
  return (
    <Accordion.Item eventKey={fromId} key={fromId}>
      <Accordion.Header>
        {isDominant ? <DominantMatchHeader id={fromId} /> : fromId}
      </Accordion.Header>
      {/* setting minHeight here is done so that the visual "jump"
                    when ProgramDetails finishes loading isn't as noticeable.
                    Without it, the accordion first expands to the height of
                    "Loading...", then jumps to the full height. */}
      <Accordion.Body style={{ minHeight: "420px" }}>
        {fromId === selectedFromId && (
          <>
            <ProgramDetails id={fromId} database="METADB" />
            <UpdateMatch
              path={path}
              fromId={fromId}
              toId={toId}
              matchId={matchId}
            />
            <div className="clearfix">
              <div className="float-end">
                <DeleteMatchButton
                  fromId={fromId}
                  toId={toId}
                  matchId={matchId}
                  path={path}
                />
                <MarkAsDominantButton
                  matchId={matchId}
                  disabled={isDominant}
                  mutate={mutate}
                />
              </div>
            </div>
            <ProgramChildren id={fromId} database="METADB" />
          </>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

interface DominantMatchHeaderProps {
  id: string;
}

const DominantMatchHeader = ({ id }: DominantMatchHeaderProps) => {
  return (
    <div
      style={{
        width: "calc(100% - var(--bs-accordion-btn-icon-width) - 8px)",
      }}
    >
      <strong>{id}</strong>
      <Badge bg="info" className="float-end mt-1 mr-2">
        DOMINANT
      </Badge>
    </div>
  );
};
