import _ from "lodash";
import { useMemo } from "react";

/**
 * Get a "virtual" field for language-specific elements of an array field.
 *
 * useArrayField() splits an array field with language-tagged elements
 * (such as genres or keywords) into partitions organized by language.
 * This makes it possible to treat, say, Norwegian genres for a media
 * content as a separate field that can be modified independently from
 * genres for other languages.
 */
export function useArrayField(data, fieldName, language, dispatch) {
  return useMemo(() => {
    const filterItems = (items) =>
      (items || []).filter((item) => item.language === language);

    const field = data[fieldName];
    const currentValue = field?.currentValue && filterItems(field.currentValue);
    const value = filterItems(field?.value);
    const original = filterItems(field?.original);

    const isPatched = !_.isEqual(
      _.sortBy(original, ["value"]),
      _.sortBy(value, ["value"])
    );
    const source = isPatched ? "patched" : "original";

    const onChange = (items) => {
      const normalizedItems = items.map(({ value }) => ({ value, language }));
      dispatch({
        type: `mc.${fieldName}.update`,
        payload: { language, [fieldName]: normalizedItems },
      });
    };

    return {
      field: {
        source,
        value,
        currentValue,
        original,
      },
      checkIsModified() {
        // Don't take item order into consideration when deciding whether
        // the field is modified
        return (
          currentValue !== undefined &&
          !_.isEqual(
            _.sortBy(currentValue, ["value"]),
            _.sortBy(value, ["value"])
          )
        );
      },
      onChange,
    };
  }, [
    data[fieldName]?.currentValue,
    data[fieldName]?.value,
    language,
    dispatch,
  ]);
}
