import _ from "lodash";
import React, { useState, useCallback, useMemo } from "react";
import { Row, Col, Alert, Button, Form } from "react-bootstrap";
import { request } from "client/utils";
import { EmptyCmsCard } from "components/cards";
import ContentDialog from "./ContentDialog";
import SeasonMediaContentCard from "./SeasonMediaContentCard";
import SeasonMediaContentsHelp from "./SeasonMediaContentsHelp";
import { displayName } from "./utils";

const SeasonCard = ({ season, seasonCard, readOnly, loading, dispatch }) => {
  const initialContentIds = season.editorial?.contentIds || [];
  const selectedContentIds = seasonCard.selectedContentIds || initialContentIds;

  const initialLeagueName = displayName(season.competition);
  const [leagueName, setLeagueName] = useState(initialLeagueName);

  const initialSeasonName = displayName(season);
  const [seasonName, setSeasonName] = useState(initialSeasonName);

  const canEdit = useMemo(
    () => !readOnly && !seasonCard.saving && seasonCard.editing,
    [readOnly, seasonCard.saving, seasonCard.editing]
  );

  const enableEditing = useCallback(
    () =>
      dispatch({
        type: "seasonCard.editing.enabled",
      }),
    []
  );

  const cancelChanges = useCallback(
    () =>
      dispatch({
        type: "seasonCard.editing.cancel",
      }),
    []
  );

  const addContentId = useCallback(
    (id) => {
      const contentIds = [...selectedContentIds];
      if (!contentIds.includes(id)) {
        contentIds.push(id);
      }
      dispatch({
        type: "seasonCard.selectedContentIds.changed",
        payload: contentIds,
      });
    },
    [selectedContentIds]
  );

  const removeContentId = useCallback(
    (id) => {
      const contentIds = selectedContentIds.filter((s) => s !== id);
      dispatch({
        type: "seasonCard.selectedContentIds.changed",
        payload: contentIds,
      });
    },
    [selectedContentIds]
  );

  const updateContentId = useCallback(
    (id) => {
      const oldId = seasonCard.mediaContentDialog.contentId;
      if (!oldId) {
        addContentId(id);
      } else {
        const contentIds = selectedContentIds.map((s) =>
          s === oldId ? id : s
        );
        dispatch({
          type: "seasonCard.selectedContentIds.changed",
          payload: contentIds,
        });
      }
    },
    [selectedContentIds, seasonCard.mediaContentDialog.contentId]
  );

  const closeDialog = useCallback(() => {
    dispatch({ type: "seasonCard.mediaContentDialog.close" });
  }, []);

  const saveChanges = useCallback(() => {
    request(`sports/leagues/${season.competitionId}/seasons/${season.id}`, {
      method: "POST",
      body: JSON.stringify({
        leagueName,
        seasonName,
        contentIds: selectedContentIds,
      }),
    })
      .then(() => dispatch({ type: "season.updateRequest.success" }))
      .catch((err) =>
        dispatch({ type: "season.updateRequest.error", payload: err })
      );
  }, [
    season.competitionId,
    season.id,
    selectedContentIds,
    leagueName,
    seasonName,
  ]);

  const showMediaContentDialog = useCallback(
    (contentId) => {
      dispatch({
        type: "seasonCard.mediaContentDialog.open",
        payload: {
          initialQuery: `${leagueName} ${seasonName}`,
          contentId,
        },
      });
    },
    [leagueName, seasonName]
  );

  return (
    <Alert variant="light" className="pb-4">
      {seasonCard.mediaContentDialog.isOpen && (
        <ContentDialog
          season={season}
          dialog={seasonCard.mediaContentDialog}
          onSelect={updateContentId}
          onClose={closeDialog}
        />
      )}
      <Form>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>League name</Form.Label>
            <Form.Control
              type="text"
              value={leagueName}
              onChange={(e) => setLeagueName(e.target.value)}
              readOnly={!canEdit}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Season name</Form.Label>
            <Form.Control
              type="text"
              value={seasonName}
              onChange={(e) => setSeasonName(e.target.value)}
              readOnly={!canEdit}
            />
          </Form.Group>
        </Row>
        {canEdit &&
          selectedContentIds.map((contentId, i) => {
            const className = i > 0 ? "pt-2" : undefined;
            return (
              <Row key={contentId} className={className}>
                <Col>
                  <SeasonMediaContentCard
                    contentId={contentId}
                    onClick={showMediaContentDialog}
                    onRemove={removeContentId}
                  />
                </Col>
              </Row>
            );
          })}
        {(canEdit || seasonCard.saving) && (
          <>
            <Row className="pt-2">
              <Col>
                <EmptyCmsCard
                  variant="landscape"
                  onClick={() => showMediaContentDialog()}
                  canClick={canEdit}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SeasonMediaContentsHelp contentIds={selectedContentIds} />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={cancelChanges}
                  disabled={loading || seasonCard.saving}
                  className="me-2"
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={saveChanges}
                  disabled={
                    (leagueName === initialLeagueName &&
                      seasonName === initialSeasonName &&
                      (!selectedContentIds ||
                        _.isEqual(selectedContentIds, initialContentIds))) ||
                    loading ||
                    !canEdit
                  }
                >
                  Save changes
                </Button>
              </Col>
            </Row>
          </>
        )}
        {!readOnly && !seasonCard.saving && !seasonCard.editing && (
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button size="sm" onClick={enableEditing} className="mt-3">
                Edit season details
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Alert>
  );
};

export default SeasonCard;
