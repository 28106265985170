import React from "react";
import { ScheduleEventRow, Title } from "../types/ScheduleEvent";

interface props {
  row: ScheduleEventRow;
}

export const EpisodeSeriesTitleCell = ({ row }: props) => {
  return (
    <>
      <EpisodeTitle prefix="GN" {...row.titles.gracenoteTitle} />
      {row.titles.teliaTitle ? (
        <EpisodeTitle prefix="Telia" {...row.titles.teliaTitle} />
      ) : (
        <div>&nbsp;</div>
      )}
    </>
  );
};

const EpisodeTitle = ({
  title,
  episode,
  series,
  prefix,
}: Title & { prefix: string }) => {
  if (series === null && episode === null) {
    return (
      <div>
        {prefix}: <span>{title}</span>
      </div>
    );
  }
  if (title === null) {
    return (
      <div>
        {prefix}: <span>{series}</span> - <span>{episode}</span>
      </div>
    );
  }
  return (
    <div>
      {prefix}: <span>{series}</span> - <span>{episode}</span> -{" "}
      <span className="text-muted">{title}</span>
    </div>
  );
};
