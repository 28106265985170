import React from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { CmsPure } from "client/utils";

class ResizableEdge extends CmsPure {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    const { direction, connectDragSource } = this.props;
    return connectDragSource(
      <div className={"scheduler-drag-edge " + direction} />
    );
  }
}

const ResizableEdgeWithDrag = (props) => {
  const [, drag, dragPreview] = useDrag({
    type: props.itemType,
    item: () => ({
      direction: props.direction,
      item: props.item,
      schedule: props.schedule,
      itemType: props.itemType,
      position: props.position,
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) return;
      props.updateItem(monitor.getItem(), true, false);
    },
  });

  return (
    <ResizableEdge
      {...props}
      connectDragSource={drag}
      connectDragPreview={dragPreview}
    />
  );
};

export default ResizableEdgeWithDrag;
