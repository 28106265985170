import { useMemo } from "react";
import useSWR from "swr";
import { Provider } from "../types";

interface ProvidersResponse {
  data: Provider[];
  limit: number | null;
  offset: number | null;
}

export function useProviders() {
  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useSWR<ProvidersResponse>("ams/provider");

  const providers = useMemo(() => response?.data || [], [response?.data]);

  return {
    providers,
    error,
    isLoading: isValidating || (!response && !error),
    mutate,
  };
}
