import { DateTime } from "luxon";
import React from "react";
import { Button } from "react-bootstrap";
import { MAX_WIDTH } from "./constants";

interface Props {
  cutoff: DateTime;
  setCutoff: (newCutoff: DateTime) => void;
}

export const LoadMoreButton = ({ cutoff, setCutoff }: Props) => {
  const onClick = () => {
    setCutoff(cutoff.minus({ days: 30 }));
  };
  const disabled =
    cutoff.startOf("day") <= DateTime.now().minus({ days: 89 }).startOf("day");

  return (
    <div style={{ maxWidth: MAX_WIDTH }} className="text-center">
      <Button variant="outline-primary" onClick={onClick} disabled={disabled}>
        Load more
      </Button>
    </div>
  );
};
