import useSWR from "swr";

export function useAnnotatedMediaContent(id: number) {
  const { data, isValidating, error } = useSWR(`mediacontent/${id}/annotated`);

  return {
    annotatedMediaContent: data || {},
    isLoading: isValidating || (!data && !error),
  };
}
