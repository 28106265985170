import PropTypes from "prop-types";
import React from "react";
import AssetRevision from "./AssetRevision";
import SelectRevision from "./SelectRevision";

function AssetRevisionsTab({
  first,
  getLinkTo,
  navigateToCompareLink,
  revisions,
}) {
  const selectedRevision = revisions.find((revision) => revision.id === first);
  return (
    <>
      <SelectRevision
        revisions={revisions}
        getLinkTo={getLinkTo}
        navigateToCompareLink={(first, third) =>
          navigateToCompareLink(first, first, third, third)
        }
        selectedRevisionId={first}
      />
      {selectedRevision ? (
        <AssetRevision {...selectedRevision} />
      ) : (
        <div className="lead text-center p-4">
          Select a revision to view its details
        </div>
      )}
    </>
  );
}

AssetRevisionsTab.propTypes = {
  first: PropTypes.number.isRequired,
  getLinkTo: PropTypes.func.isRequired,
  revisions: PropTypes.array.isRequired,
};

export default AssetRevisionsTab;
