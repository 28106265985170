import { NumericArrayParam, useQueryParam } from "use-query-params";
import { useSessionStorage } from "usehooks-ts";

export const useChannelSelection = () => {
  const [sessionStorageChannels, setSessionStorageChannels] = useSessionStorage<
    number[] | null
  >("linear-video-channels", null);

  const [selectedChannels = sessionStorageChannels, setSelectedChannels] =
    useQueryParam("channels", NumericArrayParam);

  return {
    selectedChannels,
    setChannels: (channels: number[] | null) => {
      setSelectedChannels(channels);
      setSessionStorageChannels(channels);
    },
  };
};
