import React from "react";

/**
 * Shallowly compare two objects, returns true if they are equal
 */
const shallowCompare = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }
  const k1 = Object.keys(obj1);
  const k2 = Object.keys(obj2);
  return k1.length === k2.length && k1.every((key) => obj1[key] === obj2[key]);
};

/**
 * A prop is considered updated if its value has changed,
 * or if its type has changed to function from something else
 */
function isPropUpdated(nextProp, prevProp) {
  if (nextProp === prevProp) return false;
  if (typeof nextProp === "function" && typeof prevProp === "function")
    return false;
  return true;
}
/**
 * Base class for pure components. Implements shouldComponentUpdate,
 * that does a shallow compare on props, excluding functions, since
 * anonymous functions will change each redraw.
 */
class CmsPure extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) {
      const pList = Object.entries(nextProps);
      if (Object.keys(this.props).length !== pList.length) {
        return true;
      }
      for (const [key, val] of pList) {
        const other = this.props[key];
        if (isPropUpdated(val, other)) {
          return true;
        }
      }
    }
    return !shallowCompare(nextState || {}, this.state || {});
  }
}

export default CmsPure;
