/**
 * Parameter type definitions for useQueryParams() and useQueryParam().
 *
 * Support for new parameter types can be implemented by adding new type
 * definitions to the default export below.  A type definition for a new
 * type T should consist of an object with the following properties:
 *
 *  - parse(s: string): T
 *    Parse a string into an instance of type T.  If the string cannot
 *    be parsed as a valid T instance, throw an exception.
 *
 *  - normalize(value: T, options?: any): T
 *    Validate and/or normalize the parsed value according to any
 *    type-specific options passed to useQueryParams()/useQueryParam(),
 *    for instance to ensure that the valid is within an allowed range
 *    or set of allowed values.  The normalize() function should:
 *
 *     - return the unmodified value if no normalization is needed
 *     - return a modified value if normalization is required
 *     - or throw an exception if the value failed validation and
 *       could not be normalized into a valid value
 *
 *    For types that don't require normalization/validation, the
 *    normalize() function can be as simple as normalize: v => v.
 *
 *  - serialize(value: T): string
 *    Serialize a value of type T back into a string suitable for
 *    inclusion in a query string.  The returned string should be
 *    parseable by the parse() function so that the original value is
 *    returned, i.e. parse(serialize(v)) === v.
 */
import moment from "moment";

export default {
  // Minimal parsing/serialization for unknown types.  Primarily
  // intended for internal use.
  raw: {
    parse: (s) => s,
    normalize: (s) => s,
    serialize: String,
  },
  string: {
    parse: (s) => s,
    normalize: (s) => s,
    serialize: (s) => s,
  },
  integer: {
    parse(s) {
      const value = parseInt(s);
      if (Number.isNaN(value)) {
        throw new Error(`Invalid integer value '${s}`);
      }
      return value;
    },
    normalize(value, { min, max }) {
      if (min !== undefined && value < min) {
        throw new Error(`Value ${value} outside allowed range (min: ${min})`);
      }

      if (max !== undefined && value > max) {
        throw new Error(`Value ${value} outside allowed range (max: ${max})`);
      }

      return value;
    },
    serialize: String,
  },
  boolean: {
    parse: (s) => s === "true" || s === "",
    normalize: (s) => s,
    serialize: String,
  },
  moment: {
    parse(s) {
      const m = moment(s);
      if (!m.isValid()) {
        throw new Error(`Invalid timestamp value: '${s}'`);
      }
      return m;
    },
    normalize: (d) => d,
    serialize: (d) => d.toISOString(),
  },
};
