import React from "react";
import { Form } from "react-bootstrap";
import { FieldProps } from "./types";

export const CommentField = ({ state, applyPatch }: FieldProps) => {
  return (
    <>
      <Form.Label htmlFor="comment" className="mt-4">
        Comment
      </Form.Label>
      <Form.Control
        id="comment"
        as="textarea"
        rows={5}
        value={state.comment || ""}
        onChange={(event) => applyPatch({ comment: event.target.value })}
      />
    </>
  );
};
