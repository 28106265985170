import _ from "lodash";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { request } from "client/utils";

function toSortedUheReleases(revision: { uhe_releases: object }) {
  return {
    ...revision,
    uhe_releases: _.orderBy(revision.uhe_releases, "created", "desc"),
  };
}

export function useAsset(assetId: number) {
  const assetUrl = assetId ? `ams/assets/${assetId}` : null;
  const { data, error, isValidating, mutate } = useSWR(assetUrl);
  const asset = useMemo(() => {
    if (data === undefined) {
      return { revisions: [] };
    }

    return {
      ...data,
      revisions: _.orderBy(
        data.revisions.map(toSortedUheReleases),
        "created",
        "desc"
      ),
    };
  }, [data]);

  const updateAsset = useCallback(
    async ({
      provider_asset_id,
      yaaf_asset_id,
    }: {
      provider_asset_id: string;
      yaaf_asset_id: string;
    }) => {
      if (assetUrl) {
        // The AMS API expects blank fields to be represented as null rather
        // than the empty string
        const payload = _.mapValues(
          { provider_asset_id, yaaf_asset_id },
          (v) => (v === "" ? null : v)
        );
        const body = JSON.stringify(payload);
        return mutate(await request(assetUrl, { method: "PUT", body }));
      }
    },
    [assetUrl, mutate]
  );

  return {
    asset,
    error,
    isLoading: isValidating || (!error && !data),
    updateAsset,
    mutate,
  };
}
