function isBooleanValue(string) {
  switch (string.toLowerCase()) {
    case "true":
    case "false":
      return true;
    default:
      return false;
  }
}

export function getValue(string) {
  if (isBooleanValue(string)) {
    return string.toLowerCase() === "true";
  }
  return string;
}
