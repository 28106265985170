import { Dictionary } from "lodash";
import React from "react";
import { Card } from "react-bootstrap";
import VersionSelectionTable from "./VersionSelectionTable";
import { MediaContentHistory } from "./types";

const VersionSelectionCard = ({
  data,
  params,
  setParams,
  isLoading,
  totalHits,
  selected,
  compared,
  setSelected,
  setCompared,
}: {
  data: MediaContentHistory[];
  params: Dictionary<number>;
  setParams: (params: Dictionary<number>) => void;
  isLoading: boolean;
  totalHits: number;
  selected?: MediaContentHistory;
  compared?: MediaContentHistory;
  setSelected: (selected?: MediaContentHistory) => void;
  setCompared: (compared?: MediaContentHistory) => void;
}) => {
  return (
    <Card className="mt-4">
      <Card.Header>Versions</Card.Header>
      <VersionSelectionTable
        data={data}
        params={params}
        setParams={setParams}
        isLoading={isLoading}
        totalHits={totalHits}
        compared={compared}
        selected={selected}
        setSelected={setSelected}
        setCompared={setCompared}
      />
    </Card>
  );
};

export default VersionSelectionCard;
