import useSWR from "swr";
import { IssueReport } from "../types";

interface IssueReportsResponse {
  data: IssueReport[];
  limit?: number;
  offset?: number;
}

interface UseIssueReportOptions {
  limit?: number;
}

export function useIssueReports({ limit = 100 }: UseIssueReportOptions) {
  const url = `ams/issue-reports/?limit=${limit}`;
  const { data, isValidating, error } = useSWR<IssueReportsResponse>(url);

  return {
    reports: data?.data || [],
    isLoading: isValidating || (!error && !data),
    error,
  };
}
