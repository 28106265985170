/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { PublicClientApplication } from "@azure/msal-browser";
import Promise from "bluebird";
import { createBrowserHistory } from "history";
import { Settings } from "luxon";
import moment from "moment";
import React from "react";
import { createRoot } from "react-dom/client";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import {
  CompatRoute,
  CompatRouter,
  Navigate,
} from "react-router-dom-v5-compat";

/** set active colors of the react-select component */
// @ts-ignore
import { defaultTheme } from "react-select";
import App from "client/App";
import { CLIENT_CONFIG } from "client/config";
import { useUser } from "client/hooks";
import { msalConfig } from "client/msalconfig";
import Admin from "client/views/Admin";
import AMS from "client/views/Ams";
import AssetView from "client/views/Ams/AssetView";
import Assets from "client/views/Ams/Assets";
import Configurations from "client/views/Ams/Configurations";
import IssueReports from "client/views/Ams/IssueReports";
import Providers from "client/views/Ams/Providers";
import CollectionEditor from "client/views/CollectionEditor";
import Collections from "client/views/Collections";
import { AssetDetails } from "client/views/Enrich/AssetDetails";
import { ContentReport } from "client/views/Enrich/Insight/ContentReport";
import { ContentReportStats } from "client/views/Enrich/Insight/ContentReportStats";
import { ContentWarningStats } from "client/views/Enrich/Insight/ContentWarningStats";
import { GapOverlapLog } from "client/views/Enrich/Insight/GapLog/GapOverlapLog";
import { GapOverlapStats } from "client/views/Enrich/Insight/GapLog/GapOverlapStats";
import { WarningStatuses } from "client/views/Enrich/Insight/WarningStatuses";
import MediaContentEnrich from "client/views/Enrich/MediaContent";
import { Epg } from "client/views/Epg";
import IMDbPage from "client/views/IMDb";
import AssetMonitor from "client/views/Monitor/AssetMonitor";
import PromoEditor from "client/views/PromoEditor";
import Promos from "client/views/Promos";
import ProvisionerComponent from "client/views/Provisioning";
import ConfigDraftDiff from "client/views/Provisioning/ConfigDraftDiff";
import Schedule from "client/views/Schedule";
import SportsPage from "client/views/Sports";
import TvodPriceOverride from "client/views/TVOD/PriceOverrides";
import TvodReport from "client/views/TVOD/TvodReport";
import { TvGuideComparator } from "./views/OneApp/TvGuideComparator";
import { UsageStats } from "./views/UsageStats";
import "/public/stylesheets/bootstrap.scss";
import "/public/stylesheets/main.css";
import "/public/stylesheets/react-datetime.css";

const browserHistory = createBrowserHistory();

defaultTheme.colors.primary = "var(--bs-info)";
defaultTheme.colors.primary25 = "var(--info-light)";

Promise.config({
  // Enables all warnings except forgotten return statements.
  warnings: {
    wForgottenReturn: false,
  },
});

const pca: PublicClientApplication = new PublicClientApplication(msalConfig);
const accounts = pca.getAllAccounts();

if (accounts.length > 0) {
  pca.setActiveAccount(accounts[0]);
}

const RerouteToDefault = () => {
  const user = useUser();
  if (user && user.defaultRoute) {
    return <Redirect to={user.defaultRoute} />;
  }
  return null;
};

const client = new ApolloClient({
  uri: CLIENT_CONFIG.ONEAPP_GRAPH_API,
  cache: new InMemoryCache(),
  headers: {
    "tv-client-name": "service_no_epg_checker",
    "tv-client-boot-id": "telia-norway-cms-epg-checker",
    "x-country": "NO",
  },
});

/* prettier-ignore */
const routes = (
  <ApolloProvider client={client}>
  <Router history={browserHistory}>
    <CompatRouter>
      <App pca={pca}>
        <Switch>
          <CompatRoute exact path="/collections" component={Collections} />
          <CompatRoute exact path="/collections/create" component={CollectionEditor} />
          <CompatRoute exact path="/collections/:mode/:id" component={CollectionEditor} />

          <CompatRoute exact path="/promos" component={() => <Navigate to="/promos/card" replace={true} />} />
          <CompatRoute exact path="/promos/top" component={() => <Promos promotionType="topBanner" />} />
          <CompatRoute exact path="/promos/card" component={() => <Promos promotionType="cover" />} />
          <CompatRoute exact path="/promos/create" component={PromoEditor} />
          <CompatRoute exact path="/promos/:mode/:id" component={PromoEditor} />

          <CompatRoute exact path="/schedule" component={Schedule} />

          <CompatRoute exact path="/enrich" component={() => <Navigate to="/enrich/mediacontent/list" replace={true} />} />
          <CompatRoute exact path="/enrich/mediacontent" component={() => <Navigate to="/enrich/mediacontent/list" replace={true} />} />

          <CompatRoute exact path="/enrich/mediacontent/stats" component={() => <Navigate to="/insight/warning/stats" replace={true} />} />
          <CompatRoute exact path="/enrich/mediacontent/:tab/:id?/:version_id?" component={MediaContentEnrich} />
          <CompatRoute exact path="/enrich/asset/:id" component={AssetDetails} />

          <CompatRoute exact path="/insight/report/list" component={ContentReport} />
          <CompatRoute exact path="/insight/report/stats" component={ContentReportStats} />
          <CompatRoute exact path="/insight/warning/stats" component={ContentWarningStats} />
          <CompatRoute exact path="/insight/warningstatus/reported/list">
            <WarningStatuses status="REPORTED" type="ASSET" />
          </CompatRoute>
          <CompatRoute exact path="/insight/warningstatus/accepted/list">
            <WarningStatuses status="ACCEPTED" type="ASSET" />
          </CompatRoute>
          <CompatRoute exact path="/insight/warningstatus/spam/list">
            <WarningStatuses status="ACCEPTED" type="MEDIACONTENT" />
          </CompatRoute>
          <CompatRoute exact path="/insight/gap-overlap-log">
            <GapOverlapLog />
          </CompatRoute>
          <CompatRoute exact path="/insight/gap-overlap-stats">
            <GapOverlapStats />
          </CompatRoute>

          {
            // TODO(alvin): try to use nested routes here
          }
          <CompatRoute path={`/ams/assets/:id/:tab?/:first?/:second?/:third?/:fourth?`}>
            <AMS>
              <AssetView />
            </AMS>
          </CompatRoute>
          <CompatRoute path={`/ams/configurations/:tab?`}>
            <AMS>
              <Configurations />
            </AMS>
          </CompatRoute>
          <CompatRoute path={`/ams/assets`}>
            <AMS>
              <Assets />
            </AMS>
          </CompatRoute>
          <CompatRoute path={`/ams/issue-reports`}>
            <AMS>
              <IssueReports />
            </AMS>
          </CompatRoute>
          <CompatRoute path={`/ams/providers/:mode?/:providerId?`}>
            <AMS>
              <Providers />
            </AMS>
          </CompatRoute>

          <CompatRoute exact path="/monitor" component={() => <Navigate to="/monitor/asset" replace={true} />} />
          <CompatRoute exact path="/monitor/asset" component={AssetMonitor} />
          <CompatRoute exact path="/tvod/report" component={TvodReport} />
          <CompatRoute exact path="/tvod/price-overrides" component={TvodPriceOverride} />

          <Route exact path="/provisioning" component={() => <Redirect to="/provisioning/dvb/iptv" />} />
          <Route exact path="/provisioning/dvb/triplets/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/dvb/iptv" component={() => <Redirect to="/provisioning/dvb/iptv/streams" />} />
          <Route exact path="/provisioning/dvb/iptv/streams/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/dvb/iptv/fcc/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/dvb/iptv/tuning-plans/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/dvb/iptv/tuning-plans-aes/:mode?/:id?" component={ProvisionerComponent} />

          <Route exact path="/provisioning/dvb/cable" component={() => <Redirect to="/provisioning/dvb/cable/streams" />} />
          <Route exact path="/provisioning/dvb/cable/streams/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/dvb/cable/tuning-plans/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/dvb/cable/tuning-plans-aes/:mode?/:id?" component={ProvisionerComponent} />

          <Route exact path="/provisioning/content-providers/tv/placements/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/content-providers/tv/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/content-providers/vod/:mode?/:id?" component={ProvisionerComponent} />

          <Route exact path="/provisioning/lineups/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/configs/deployments/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/configs/drafts/:id/diff" component={ConfigDraftDiff} />
          <Route exact path="/provisioning/configs/drafts/:mode?/:id?" component={ProvisionerComponent} />
          <Route exact path="/provisioning/configs/publications/:mode?/:id?" component={ProvisionerComponent} />

          <Route path="/imdb" component={IMDbPage} />
          <Route path="/sports" component={SportsPage} />

          <Route exact path="/admin" component={() => <Redirect to="/admin/access-control" />} />
          <Route exact path="/admin/access-control" component={Admin} />
          <Route exact path="/admin/usagestats" component={UsageStats} />

          <Route path="/epg" component={Epg} />

          <Route path="/oneapp/tv-guide-comparator" component={TvGuideComparator} />

          <Route path="*" component={RerouteToDefault} />
        </Switch>
      </App>
    </CompatRouter>
  </Router>
  </ApolloProvider>
);

moment.locale("no");
Settings.defaultZone = "Europe/Oslo";

function main() {
  const container = document.getElementById("render-target");
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(routes);
}

main();
