import React from "react";
import {
  Menu,
  MenuItem,
  Token,
  TokenProps,
  Typeahead,
  TypeaheadMenuProps,
  TypeaheadResult,
} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { ProvidersContext } from "client/App";
import { IProviders, Providers } from "client/types";

const renderProviderToken = (
  option: IProviders,
  props: TokenProps,
  index: number
) => {
  const style = option.active ? {} : { textDecoration: "line-through" };
  return (
    <Token key={index} option={option} onRemove={props.onRemove} style={style}>
      {option.value}
    </Token>
  );
};

const renderProviderMenu = (
  results: TypeaheadResult<IProviders>[],
  menuProps: TypeaheadMenuProps<IProviders>
) => {
  return (
    <Menu {...menuProps}>
      {results.map((result, index) => (
        <MenuItem
          key={index}
          option={result}
          style={result.active ? {} : { textDecoration: "line-through" }}
          position={index}
        >
          {result.value}
        </MenuItem>
      ))}
    </Menu>
  );
};

interface Props {
  id?: string;
  onChange: (providerIds: number[]) => void;
  placeholder?: string;
  value: (string | number)[];
  multiple?: boolean;
  exclude?: number[];
  disabled?: boolean;
}

export const ProviderMultiPicker = ({
  id = "providers-multi",
  onChange,
  placeholder = "Provider filter",
  value,
  multiple = true,
  exclude = [],
  disabled = false,
  ...rest
}: Props) => (
  <ProvidersContext.Consumer>
    {(providers) => {
      // if providers hasn't been fetched yet, or an id is invalid,
      // find will return undefined and needs to be filtered
      const providerObjs = value
        .map((val) => providers.find((provider) => provider.id == val))
        .filter((pr) => !!pr) as Providers;
      return (
        <Typeahead
          id={id}
          multiple={multiple}
          selected={providerObjs}
          options={providers.filter((p) => !exclude.includes(Number(p.id)))}
          labelKey="value"
          placeholder={placeholder}
          onChange={(selectedProviders) => {
            onChange(selectedProviders.map((p): number => parseInt(p.id)));
          }}
          align="left"
          renderToken={renderProviderToken}
          renderMenu={renderProviderMenu}
          disabled={disabled}
          {...rest}
        />
      );
    }}
  </ProvidersContext.Consumer>
);
