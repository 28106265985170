import _ from "lodash";
import React from "react";
import { Badge } from "react-bootstrap";
import { parseDateToDatetimeString } from "client/utils/TableCommon";

export function getVariantFromLogLevel(level) {
  switch (level) {
    case "ERROR":
      return "danger";
    case "WARNING":
      return "warning";
    default:
      return "light";
  }
}

function getVariantFromWarningType(type) {
  switch (type) {
    default:
      return "warning";
  }
}

function shortenWarningType(warning) {
  return warning
    .split("_")
    .map((word) => word[0])
    .join("");
}

export const toDataQualityWarnings = (values, shorten = true) => {
  return values?.map((value) => {
    return (
      <Badge
        className="me-1"
        bg={getVariantFromWarningType(value)}
        key={value}
        title={value}
      >
        {shorten ? shortenWarningType(value) : value}
      </Badge>
    );
  });
};

export function printChecksum(checksum, length = 6) {
  return checksum.substr(0, length);
}

export const toDate = parseDateToDatetimeString;

export function getAvailableModelKeys(revisions) {
  const dataModels = ["yaaf", "yaafd", "yaafo"];
  const keysWithData = _.flatMap(revisions, (revision) =>
    _.keysIn(_.pickBy(_.pick(revision, dataModels), _.identity))
  );
  return [...new Set(keysWithData)];
}

export function pickDataModel(revisions) {
  const options = getAvailableModelKeys(revisions);
  return _.isEmpty(options) ? null : options[0];
}
