import React, { useState } from "react";
import SearchAssetDialog from "client/views/Enrich/MediaContent/SearchAssetDialog";
import { GlyphButton } from "components/buttons";

export const AssetModal = () => {
  const [showSearchAssetDialog, setShowSearchAssetDialog] = useState(false);
  const onCloseSearchAssetDialog = () => {
    setShowSearchAssetDialog(false);
  };
  return (
    <>
      <GlyphButton
        glyph="search"
        variant="primary"
        onClick={() => setShowSearchAssetDialog(true)}
      >
        Find Asset
      </GlyphButton>
      <SearchAssetDialog
        show={showSearchAssetDialog}
        onSubmit={onCloseSearchAssetDialog}
        onClose={onCloseSearchAssetDialog}
      />
    </>
  );
};
