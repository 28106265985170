import React from "react";
import Select from "react-select";
import { NumericArrayParam, useQueryParam } from "use-query-params";
import { useChannelOptions, useResetPage } from "./hooks";
import { channelLabel } from "./utils";

export const ChannelFilter = () => {
  const channelOptions = useChannelOptions();
  const [channels, setChannels] = useQueryParam("channels", NumericArrayParam);
  const [excludedChannels = []] = useQueryParam(
    "excludedChannels",
    NumericArrayParam
  );
  const resetPage = useResetPage();
  const selectChannels = (newChannels: number[]) => {
    setChannels(newChannels);
    resetPage();
  };
  const selectedChannels = (channels || [])
    .filter((chId) => chId !== null)
    .map((chId) => ({
      value: Number(chId),
      label: channelLabel(chId as number, channelOptions),
    }));
  const isDisabled = !!excludedChannels && excludedChannels.length > 0;

  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      onChange={(selected) =>
        selectChannels(selected.map((option) => option.value))
      }
      options={channelOptions}
      placeholder="Specify channels?"
      hideSelectedOptions={true}
      value={selectedChannels}
      isDisabled={isDisabled}
    />
  );
};
