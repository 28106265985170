import React from "react";
import { withRouter } from "react-router-dom";
import InsightToolbar from "client/views/Enrich/Insight/InsightToolbar";
import CatchupList from "./CatchupList";
import ChannelRulesSettings from "./ChannelRulesSettings";
import EditList from "./EditList/index";
import { EnrichmentList } from "./EnrichmentList";
import EnrichmentToolbar from "./EnrichmentToolbar";
import History from "./History";
import MediaContentEditor from "./MediaContentEditor";
import TabNavigation from "./TabNavigation";

/**
 Super-component responsible for handling the navigation between the different enrich
 subcomponents: EnrichmentList, MediaContentEditor, and History.
 Also displays and handles the search functionality by the use of the SearchMediaContentDialog component.
*/
const MediaContentEnrich = (props) => {
  const id = props.match.params.id || "";
  const activeTab = props.match.params.tab || "list";

  const renderTab = (activeTab) => {
    switch (activeTab) {
      case "list":
        return <EnrichmentList />;
      case "edits":
        return <EditList />;
      case "catchup":
        return <CatchupList />;
      case "editor":
        return <MediaContentEditor id={props.match.params.id} />;
      case "channelrules":
        return <ChannelRulesSettings />;
      case "history":
        return <History id={props.match.params.id} />;
    }
  };

  return (
    <>
      <Toolbar activeTab={activeTab} />
      {id !== "" && <TabNavigation />}
      <div className="container-fluid">{renderTab(activeTab)}</div>
    </>
  );
};

const Toolbar = ({ activeTab }) => {
  if (activeTab === "list" || activeTab === "stats") {
    return <InsightToolbar />;
  }
  if (activeTab === "channelrules") {
    return null;
  }
  return <EnrichmentToolbar />;
};

export default withRouter(MediaContentEnrich);
