import React, { useCallback, useState } from "react";
import {
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { getQueryString, STATUS, useRequest } from "client/hooks";
import { MomentParam } from "client/types/MomentParam";
import { ReactTablePagination } from "components/tables";
import CatchupListFilter from "./CatchupListFilter";
import CatchupListResults from "./CatchupListResults";

const sortKeys = {
  channelId: "channel_id",
  broadcast: "linear_start",
  catchupEnd: "catchup_end",
};

const getCatchupEndpoint = (query, page, pageSize) => {
  const offset = page * pageSize;
  const queryString = getQueryString({ ...query, offset, limit: pageSize });
  return `enrichment/catchup?${queryString}`;
};

const CatchupList = () => {
  const [page = 0, setPage] = useQueryParam("page", NumberParam);
  const [pageSize = 50, setPageSize] = useQueryParam("pageSize", NumberParam);
  const [query, setQuery] = useQueryParams({
    channels: withDefault(NumericArrayParam, []),
    broadcastFrom: MomentParam,
    broadcastTo: MomentParam,
    catchupEndFrom: MomentParam,
    catchupEndTo: MomentParam,
    sortKey: withDefault(StringParam, "catchup_end"),
    sortDirection: withDefault(StringParam, "asc"),
  });
  const endpoint = getCatchupEndpoint(query, page, pageSize);
  const [{ assets, totalCount }, setResponse] = useState({
    assets: [],
    totalCount: 0,
  });
  const [status] = useRequest(endpoint, { onSuccess: setResponse });
  const loading = status === STATUS.FETCHING;
  const totalPages = Math.ceil(totalCount / pageSize);
  const sortBy = [{ id: query.sortKey, desc: query.sortDirection === "desc" }];
  const setSortBy = useCallback(
    ({ id, desc }) => {
      const sortKey = sortKeys[id];
      const sortDirection = desc ? "desc" : "asc";
      if (sortKey) {
        setQuery({ sortKey, sortDirection });
      }
    },
    [setQuery]
  );

  return (
    <div>
      <CatchupListFilter filters={query} setFilters={setQuery} />
      <CatchupListResults
        assets={assets}
        loading={loading}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      <ReactTablePagination
        pageIndex={page}
        gotoPage={(p) => setPage(p)}
        totalPages={totalPages}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default CatchupList;
