import { dateToString } from "@telia-company/tv.no-play-cms-common/api/Date";
import moment from "moment";
import { PropTypes } from "prop-types";
import React, { useMemo } from "react";
import Table, {
  getManageColumn,
  getStatusColumn,
  OptionCell,
  OptionsFilter,
  TextFilter,
} from "components/tables";

const SCHEDULE_OPTIONS = [
  { value: "published", label: "Published", variant: "info" },
  { value: "upcoming", label: "Upcoming", variant: "success" },
  { value: "passed", label: "Passed", variant: "secondary" },
  { value: "unknown", label: "Unknown", variant: "danger" },
];

function ScheduleTable({
  defaultSorted = [{ id: "publish", desc: true }],
  defaultPageSize,
  data,
  placeholder = "Nothing in this category",
  showManageColumn,
  onEdit,
  now,
  ...rest
}) {
  const columns = useMemo(
    () =>
      [
        getStatusColumn({ accessor: (collection) => collection.item.active }),
        {
          Header: "Title",
          id: "title",
          accessor: (collection) =>
            collection.schedule.title || collection.item.title,
          Filter: TextFilter,
          type: "text",
          width: 140,
        },
        {
          Header: "Schedule",
          id: "schedule",
          accessor: (record) => {
            const { unixStart, unixEnd } = record.schedule;
            if (now < unixStart) return "upcoming";
            else if (unixEnd < now) return "passed";
            else if (unixStart <= now && now <= unixEnd) return "published";
            else return "unknown";
          },
          Cell: ({ value }) => (
            <OptionCell options={SCHEDULE_OPTIONS} value={value} />
          ),
          filter: "equals",
          Filter: ({ column }) => (
            <OptionsFilter options={SCHEDULE_OPTIONS} column={column} />
          ),
          width: 30,
        },
        {
          Header: "Publish",
          id: "publish",
          accessor: (collection) => collection.schedule.unixStart,
          Cell: ({ value }) => dateToString(moment.unix(value)),
          width: 80,
        },
        {
          Header: "Depublish",
          id: "depublish",
          accessor: (collection) => collection.schedule.unixEnd,
          Cell: ({ value }) => dateToString(moment.unix(value)),
          width: 80,
        },
        showManageColumn && getManageColumn(onEdit),
      ].filter((v) => !!v),
    [now]
  );

  return (
    <Table
      data={data}
      columns={columns}
      placeholder={placeholder}
      initialState={{ sortBy: defaultSorted, pageSize: defaultPageSize }}
      getRowId={(row) => row.schedule.id}
      paginate
      filter
      flex
      {...rest}
    />
  );
}

ScheduleTable.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  showManageColumn: PropTypes.bool.isRequired,
  now: PropTypes.number.isRequired,
};

export default ScheduleTable;
