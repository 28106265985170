import React, { useCallback } from "react";
import { GlyphButton } from "components/buttons";
import { displayName } from "./utils";

const TeamInfo = ({ team, label, dispatch }) => {
  const selectTeam = useCallback(() => {
    dispatch({ type: "team.selected", payload: team });
  }, [team]);

  return (
    <div className="w-100">
      <div>
        <b>{label}</b>
      </div>
      {team ? (
        <GlyphButton
          glyph="info"
          variant="link"
          className="p-0"
          onClick={selectTeam}
          iconLast
        >
          {displayName(team)}
        </GlyphButton>
      ) : (
        "N/A"
      )}
    </div>
  );
};

export default TeamInfo;
