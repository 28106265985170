import React from "react";
import { numberOrStringPropType } from "client/utils";
import DefaultCmsCard from "./DefaultCmsCard";

const PromotionCard = ({ id, promotionType, ...props }) => (
  <DefaultCmsCard
    {...props}
    variant={promotionType}
    linkTo={`/promos/edit/${id}`}
  />
);

PromotionCard.propTypes = { id: numberOrStringPropType.isRequired };

export default PromotionCard;
