import React, { ReactNode, useCallback, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Cell, Column } from "react-table-v7";
import ReactTooltip from "react-tooltip";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { CLIENT_CONFIG } from "client/config";
import { useDebouncedValue } from "client/hooks";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import { LinkToMediaContentEditor } from "client/views/Epg/LinkToMediaContentEditor";
import { Truncate } from "components/Truncate";
import { KindBadge } from "components/badges";
import { KindPicker } from "components/pickers/KindPicker";
import Table, { ReactTablePagination } from "components/tables";
import { LinkToProgramMatch } from "../LinkToProgramMatch";
import { DatabaseBadge } from "../ProgramDetails/DatabaseBadge";
import { ProgramDetailsModal } from "../ProgramDetails/ProgramDetailsModal";
import { TeliaDBKindBadge } from "../TeliaDBKindBadge";
import { TitlePicker } from "../TitlePicker";
import { useProgramMatches } from "../hooks";
import { ProgramMatchEntity } from "../types";
import { MatchParams } from "../types/MatchParams";
import { ChannelSummary } from "./ChannelSummary";
import { IdPicker } from "./IdPicker";
import { ManualMatchesPicker } from "./ManualMatchesPicker";
import { MatchedOrUnmatchedPicker } from "./MatchedOrUnmatchedPicker";
import { NextBroadcast } from "./NextBroadcast";
import { ProgramMatch } from "./ProgramMatch";

function TruncatedCell({ cell: { value } }: { cell: { value: ReactNode } }) {
  return <Truncate>{value}</Truncate>;
}

const useQueryParamString = () => {
  const [query] = useQueryParams({
    page: NumberParam,
    size: NumberParam,
    title: StringParam,
    kind: StringParam,
    manualMatches: StringParam,
    id: StringParam,
    matched: StringParam,
    debugColumns: BooleanParam,
  });
  const searchParams = new URLSearchParams();
  if (query.page) {
    searchParams.append("page", query.page.toString());
  }
  if (query.size) {
    searchParams.append("size", query.size.toString());
  }
  if (query.title) {
    searchParams.append("title", query.title);
  }
  if (query.kind) {
    searchParams.append("kind", query.kind);
  }
  if (query.manualMatches) {
    searchParams.append("manualMatches", query.manualMatches);
  }
  if (query.id) {
    searchParams.append("id", query.id);
  }
  if (query.matched) {
    searchParams.append("matched", query.matched);
  }
  if (query.debugColumns) {
    searchParams.append("debugColumns", query.debugColumns ? "1" : "0");
  }
  return "?" + searchParams.toString();
};

const useProgramMatchOnClick = () => {
  const history = useHistory();
  const queryParamString = useQueryParamString();

  return (match: ProgramMatchEntity) => {
    const selection = window.getSelection()?.toString();
    if (selection && selection.length > 0) {
      // User has selected text, do not handle the click event
      return;
    }
    history.push(
      `/epg/matches/from/${match.from}/to/${match.to}` + queryParamString
    );
  };
};

const useProgramMatchOnClose = () => {
  const history = useHistory();
  const queryParamString = useQueryParamString();
  return () => history.push("/epg/matches" + queryParamString);
};

interface LinkToProgramMatchesMatchProps {
  content: ReactNode;
  tmsId: string;
  mediaContentId: string | null;
  onClick?: () => void;
}

const LinkToProgramMatchesMatch = ({
  content,
  tmsId,
  mediaContentId,
  onClick,
}: LinkToProgramMatchesMatchProps) => {
  const rootUrl =
    CLIENT_CONFIG.NAME === "prod"
      ? "https://cms.teliaplay.no"
      : "https://cms.qa.teliaplay.no";
  const tooltip = `${rootUrl}/epg/matches/from/${tmsId}/to/${mediaContentId}`;
  return (
    <LinkToProgramMatch
      content={content}
      urlPrefix="/epg/matches/"
      tmsId={tmsId}
      mediaContentId={mediaContentId}
      onClick={onClick}
      data-tip={tooltip}
    />
  );
};

type ProgramMatchColumns = ReadonlyArray<Column<ProgramMatchEntity>>;

export function ProgramMatches() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 20),
    title: StringParam,
    kind: StringParam,
    manualMatches: StringParam,
    id: StringParam,
    matched: StringParam,
  });
  const {
    programMatches,
    programsByTmsId,
    isLoading,
    totalPages,
    totalMatches,
  } = useProgramMatches({
    page: query.page,
    size: query.size,
    title: useDebouncedValue(query.title),
    kind: query.kind,
    manualMatches: query.manualMatches,
    ids: query.id,
    matched: query.matched || "matchedFirst",
  });
  const [showDebugColumns = false, setShowDebugColumns] = useQueryParam(
    "debugColumns",
    BooleanParam
  );
  const toggleDebugColumns = useCallback(
    () => setShowDebugColumns((visible) => !visible),
    [setShowDebugColumns]
  );
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isLoading]);
  const openProgramMatch = useProgramMatchOnClick();
  const defaultColumns: ProgramMatchColumns = [
    {
      Header: "From kind",
      accessor: "kind",
      width: 20,
      Cell: ({ cell: { value } }) => {
        if (value) {
          return <KindBadge className="mt-1" kind={value} />;
        } else {
          return null;
        }
      },
      className: "me-1",
    },
    {
      Header: "From Id",
      accessor: (row) => {
        const gracenoteUrl = `https://gracenoteview.com/programs/${row.from}`;
        return (
          <a
            href={gracenoteUrl}
            target="_blank"
            rel="noreferrer"
            data-tip={gracenoteUrl}
          >
            {row.from}
          </a>
        );
      },
      width: 30,
    },
    {
      Header: "Database",
      accessor: "fromDatabase",
      width: 20,
      Cell: ({ cell: { value } }) => (
        <DatabaseBadge className="mt-1" db={value} />
      ),
    },
    {
      Header: "Title",
      id: "fromTitle",
      accessor: (row) => (
        <LinkToProgramMatchesMatch
          content={row.fromTitle}
          tmsId={row.from}
          mediaContentId={row.to}
          onClick={() => openProgramMatch(row)}
        />
      ),
      Cell: TruncatedCell,
      width: 50,
    },
    {
      Header: "To kind",
      accessor: (row) => <TeliaDBKindBadge mediaContentId={row.to} />,
    },
    {
      Header: "To Id",
      accessor: (row) => {
        const mdcUrl = `${CLIENT_CONFIG.METADATA_COLLECTOR_ROOT}/content/${row.to}`;
        return (
          <a href={mdcUrl} target="_blank" rel="noreferrer" data-tip={mdcUrl}>
            {row.to}
          </a>
        );
      },
      width: 30,
    },
    {
      Header: "Database",
      accessor: "toDatabase",
      width: 20,
      Cell: ({ cell: { value } }) =>
        value ? <DatabaseBadge className="mt-1" db={value} /> : null,
    },
    {
      Header: "Title",
      id: "toTitle",
      accessor: (row) => (
        <LinkToMediaContentEditor id={row.to} linkText={row.toTitle} />
      ),
      Cell: TruncatedCell,
      width: 50,
    },
    {
      Header: "Channel",
      id: "match-channel",
      width: 40,
      accessor: (row) => (
        <LinkToProgramMatchesMatch
          content={<ChannelSummary program={programsByTmsId.get(row.from)} />}
          tmsId={row.from}
          mediaContentId={row.to}
          onClick={() => openProgramMatch(row)}
        />
      ),
    },
    {
      Header: "Next broadcast",
      id: "next-broadcast",
      width: 40,
      Cell: ({ cell: { row } }: { cell: Cell<ProgramMatchEntity> }) => (
        <NextBroadcast program={programsByTmsId.get(row.original.from)} />
      ),
    },
  ];
  const debugColumns: ProgramMatchColumns = [
    {
      Header: "ID",
      accessor: (row) => (
        <LinkToProgramMatchesMatch
          content={row.id}
          tmsId={row.from}
          mediaContentId={row.to}
          onClick={() => openProgramMatch(row)}
        />
      ),
      width: 20,
    },
    {
      Header: "Match updated at",
      accessor: (row) => (
        <LinkToProgramMatchesMatch
          content={
            <Truncate>{parseDateToDatetimeString(row.matchUpdatedAt)}</Truncate>
          }
          tmsId={row.from}
          mediaContentId={row.to}
          onClick={() => openProgramMatch(row)}
        />
      ),
      width: 40,
    },
  ];
  const columns = showDebugColumns
    ? [...defaultColumns, ...debugColumns]
    : defaultColumns;

  const onClose = useProgramMatchOnClose();
  const { fromId, toId, id } = useParams<MatchParams>();

  return (
    <>
      {toId && <ProgramMatch fromId={fromId} toId={toId} onClose={onClose} />}
      {id && (
        <ProgramDetailsModal id={id} onClose={onClose} path="/epg/matches" />
      )}
      <Row className="mb-2">
        <Col xs={6}>
          <TitlePicker />
        </Col>
        <Col xs={3}>
          <KindPicker />
        </Col>
        <Col xs={3}>
          <ManualMatchesPicker />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={6}>
          <IdPicker />
        </Col>
        <Col xs={3}>{/* empty slot */}</Col>
        <Col xs={3}>
          <MatchedOrUnmatchedPicker />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          Total matches:{" "}
          {totalMatches ? totalMatches.toLocaleString("nb-NO") : totalMatches}
        </Col>
        <Col xs={6}>
          <div className="d-flex justify-content-end">
            <Form.Label htmlFor="toggle-debug-columns">
              Show debug columns
            </Form.Label>
            <Form.Switch
              id="toggle-debug-columns"
              className="ms-2"
              checked={showDebugColumns || undefined}
              onChange={toggleDebugColumns}
            />
          </div>
        </Col>
      </Row>
      <Table data={programMatches} columns={columns} loading={isLoading} />
      <ReactTablePagination
        pageIndex={query.page}
        pageSize={query.size}
        totalPages={totalPages}
        gotoPage={(page: number) => setQuery({ page })}
        setPageSize={(size: number) => setQuery({ size })}
      />
      <ReactTooltip multiline={true} />
    </>
  );
}
