import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

export const TitlePicker = () => {
  const [title, setTitle] = useQueryParam("title", StringParam);

  return (
    <>
      <Form.Label htmlFor="linear-title">Title</Form.Label>
      <Form.Control
        type="text"
        id="linear-title"
        value={title || ""}
        onChange={(e) => {
          const newValue = e.target.value;
          setTitle(newValue.length > 0 ? newValue : null);
        }}
      />
    </>
  );
};
