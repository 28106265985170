import React from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import { ReactTablePagination } from "components/tables/ControlledReactTable";

interface Props {
  totalPages?: number;
  totalResults?: number;
}

export const Pagination = ({ totalResults, totalPages }: Props) => {
  const [pageIndex = 0, setPageIndex] = useQueryParam("page", NumberParam);
  const [pageSize = 20, setPageSize] = useQueryParam("size", NumberParam);
  if (!totalPages && totalResults) {
    totalPages = Math.ceil(totalResults / (pageSize as number));
  }
  return (
    <ReactTablePagination
      pageIndex={pageIndex}
      gotoPage={setPageIndex}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalPages={totalPages || 0}
    />
  );
};
