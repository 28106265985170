import React from "react";
import { Col, Row } from "react-bootstrap";
import KeyValuePair from "components/KeyValuePair";
import StyledJSONTree from "components/StyledJSONTree";

interface Props {
  data: object;
  checksum: string;
  locator: string;
  title: string;
}

function AssetRevisionDataView({ data, checksum, locator, title }: Props) {
  return (
    <>
      <Row>
        <Col>
          <h3 className="lead">{title}</h3>
        </Col>
      </Row>
      {data ? (
        <>
          <Row>
            <Col>
              <KeyValuePair id="Checksum" labelWidth={1} value={checksum} />
              <KeyValuePair id="Locator" labelWidth={1} value={locator} />
            </Col>
          </Row>
          <Row>
            <Col xs={{ offset: 1, span: 10 }}>
              <StyledJSONTree data={data} />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>No {title} in revision</Col>
        </Row>
      )}
    </>
  );
}

export default AssetRevisionDataView;
