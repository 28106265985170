import _ from "lodash";
import queryString from "query-string";
import { useReducer } from "react";
import { toast } from "react-toastify";
import useSWRInfinite from "swr/infinite";
import { useSessionStorage } from "usehooks-ts";
import { mergeReducer, useDebouncedValue } from "client/hooks";
import { useKeptResult } from "./useKeptResult";

export interface MediaContentSearch {
  title?: string;
  kinds?: string[];
  providers?: number[];
  channels?: number[];
  types?: string[];
  activeOnly?: boolean;
  excludeKinds?: string[];
  includeAssets?: boolean;
  parentId?: number;
}

export interface MediaContent {
  id: string;
  title: string;
  topTitle: string;
  parentTitle?: string;
  active: boolean;
  image_src: string;
  description: string;
  assets?: unknown[];
}

const DEFAULT_SEARCH_STATE = {
  title: "",
  kinds: [],
  providers: [],
  channels: [],
  types: [],
  activeOnly: true,

  excludeKinds: [],
};

const LIMIT = 50;

function formatQueryString(search: MediaContentSearch) {
  return queryString.stringify({
    searchQuery: search.title,
    providers: search.providers,
    channels: search.channels,
    types: search.types,
    kinds: search.kinds,
    excludeKinds: search.excludeKinds,
    activeOnly: search.activeOnly,
    includeAssets: search.includeAssets,
    parentId: search.parentId,
  });
}

function useQueryParams(defaultState: MediaContentSearch) {
  return useReducer(mergeReducer, {
    ...DEFAULT_SEARCH_STATE,
    ...defaultState,
  });
}

const getKey = (
  pageIndex: number,
  previousPageData: MediaContent[] | null,
  debouncedUrl: string
) => {
  if (previousPageData && !previousPageData.length) return null;
  return debouncedUrl + `&offset=${pageIndex * LIMIT}`;
};

export const removeDuplicates = (data: MediaContent[][]): MediaContent[][] => {
  const seenIds = new Set<string>();
  const newData: MediaContent[][] = [];
  for (const subarray of data) {
    const newSubArray: MediaContent[] = [];
    for (const mediaContent of subarray) {
      if (!seenIds.has(mediaContent.id)) {
        newSubArray.push(mediaContent);
        seenIds.add(mediaContent.id);
      }
    }
    newData.push(newSubArray);
  }
  return newData;
};

export function useMediaContents(initialState: MediaContentSearch) {
  const [queryParams, onChangeQueryParams] = useQueryParams(initialState);
  return useMediaContentsInner(queryParams, onChangeQueryParams);
}

export function useMediaContentsWithSessionStorage(
  initialState: MediaContentSearch,
  sessionStorageKey: string
) {
  const [sessionStorage, setSessionStorage] = useSessionStorage(
    sessionStorageKey,
    initialState
  );
  const [queryParams, onChangeQueryParams] = useQueryParams(sessionStorage);
  const dispatchAndSet = (patch: Partial<MediaContentSearch>) => {
    onChangeQueryParams(patch);
    setSessionStorage({ ...sessionStorage, ...patch });
  };
  return useMediaContentsInner(queryParams, dispatchAndSet);
}

function useMediaContentsInner(
  queryParams: MediaContentSearch,
  onChangeQueryParams: (patch: Partial<MediaContentSearch>) => void
) {
  const debouncedUrl = useDebouncedValue(
    `mediacontent/search?${formatQueryString(queryParams)}`
  );
  const { data, isValidating, error, size, setSize } = useSWRInfinite<
    MediaContent[]
  >((...args) => getKey(...args, debouncedUrl));

  if (error) toast.error("Failed to search content: " + error.message);
  const mediaContents = useKeptResult(data);
  const isLoading = isValidating || (!error && !data);

  return {
    mediaContents: mediaContents ? removeDuplicates(mediaContents).flat() : [],
    isLoading: isLoading,
    isLoadingMore:
      isLoading || (size > 0 && data && typeof data[size - 1] === "undefined"),
    isEmpty: data?.length === 1 && _.isEmpty(data[0]),
    isMoreAvailable: data && data[data.length - 1].length === LIMIT,
    size,
    setSize,
    queryParams,
    onChangeQueryParams,
  };
}
