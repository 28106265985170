import { ReportTypes } from "@telia-company/tv.no-play-cms-common/api/ReportType";
import React from "react";
import { Link } from "react-router-dom";

export const TableHead = () => {
  return (
    <thead>
      <tr>
        <th className="sticky">Date</th>
        {ReportTypes.map((reportType) => (
          <th key={reportType} className="sticky">
            <Link to={`/insight/report/list?report_type=${reportType}`}>
              {reportType}
            </Link>
          </th>
        ))}
      </tr>
    </thead>
  );
};
