import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { request } from "client/utils";
import Table from "components/tables";
import CandidateTitleCard from "./CandidateTitleCard";

const CandidateMatches = ({
  mediaContentId,
  imdbId,
  loading,
  matches,
  query,
  selectedMatch,
  dispatch,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "IMDb ID",
        accessor: "imdbId",
        className: "my-imdb-id",
      },
      {
        Header: "Original title",
        accessor: "originalTitle",
      },
      {
        Header: "Norwegian title",
        accessor: "norwegianTitle",
      },
      {
        Header: "Year",
        accessor: "year",
      },
    ],
    []
  );

  // Search for matching IMDb titles
  useEffect(() => {
    var url = `imdb/candidate-matches/${mediaContentId}`;

    if (query.customTitles.length > 0) {
      const titlePairs = query.customTitles.map((t) => ["title", t]);
      const params = new URLSearchParams(titlePairs);
      url = `${url}?${params.toString()}`;
    }

    request(url)
      .then((res) => {
        dispatch({
          type: "candidateMatchList.searchRequest.success",
          payload: res,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: "candidateMatchList.searchRequest.error" });
      });
  }, [mediaContentId, query.customTitles]);

  // Update the currently selected match candidate when IMDb ID or match search
  // results change
  useEffect(() => {
    for (const m of matches) {
      // If an IMDb title has already been matched, select it by default when
      // the page loads (if it's in the search results)
      if (m.imdbId === imdbId && !selectedMatch) {
        dispatch({
          type: "candidateMatchList.candidateMatchSelected",
          payload: m,
        });
        return;
      }

      // Refresh the currently selected match when the search results change, as
      // match warnings may have changed in the new results
      if (selectedMatch && m.imdbId === selectedMatch.imdbId) {
        dispatch({
          type: "candidateMatchList.candidateMatchSelected",
          payload: m,
        });
        return;
      }
    }

    // Deselect the previously selected match if it's no longer in the match
    // search results
    if (selectedMatch) {
      dispatch({
        type: "candidateMatchList.candidateMatchSelected",
        payload: undefined,
      });
    }
  }, [imdbId, selectedMatch, matches]);

  const selectedItem = React.useMemo(() => {
    const id = (selectedMatch && selectedMatch.imdbId) || imdbId;
    return id ? { imdbId: id } : {};
  }, [selectedMatch, imdbId]);

  const onClick = React.useCallback(
    (match) =>
      dispatch({
        type: "candidateMatchList.candidateMatchSelected",
        payload: match,
      }),
    []
  );
  const getRowId = React.useCallback(({ imdbId }) => imdbId, []);

  return (
    <>
      <Row className="mt-2">
        <Col>
          <h2 className="mt-0">Candidate IMDb matches</h2>
        </Col>
      </Row>
      <Row>
        <Col className="imdb-list">
          <Table
            loading={loading}
            columns={columns}
            data={matches}
            onClick={onClick}
            getRowId={getRowId}
            selectedItem={selectedItem}
          />
        </Col>
        {selectedMatch && (
          <Col xs={6} lg={5} xl={4}>
            <CandidateTitleCard
              imdb={selectedMatch}
              imdbMatchId={imdbId}
              dispatch={dispatch}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default CandidateMatches;
