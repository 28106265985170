import React from "react";
import { Form } from "react-bootstrap";
import { FieldProps } from "./types";

const OPTIONS = ["None", "A", "B"];

export const GNClassField = ({ state, applyPatch }: FieldProps) => {
  return (
    <>
      <Form.Label htmlFor="gn-class">GN Class</Form.Label>
      <Form.Select
        id="gn-class"
        value={state.gnClass}
        onChange={(e) => applyPatch({ gnClass: e.target.value })}
      >
        {OPTIONS.map((gnClassOption) => (
          <option key={gnClassOption} value={gnClassOption}>
            {gnClassOption}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
