import { uniq } from "lodash";
import memoizeOne from "memoize-one";
import { PropTypes } from "prop-types";
import React, { useRef } from "react";
import { useDrop } from "react-dnd";
import { CmsPure } from "client/utils";
import { GlyphButton } from "components/buttons";
import {
  getContainerY,
  getDefaultDuration,
  ITEM_HEIGHT,
  SNAP_INTERVAL,
  snapTime,
} from "../utils";
import AddContent from "./AddContent";
import DraggableContent from "./DraggableContent";

class Container extends CmsPure {
  render() {
    const {
      start,
      items: entries,
      placeholder = "There are no scheduled events in this category",
      itemType,
      updateItem,
      toPixels,
      connectDropTarget,
      isOver,
      canDrop,
      onClickItem,
      now,
      hoverItem,
      onCreateItem,
      onCreateRow,
      canEdit,
      indexMinLimit = -Infinity,
      indexMaxLimit = Infinity,
      defaultIndexRange = [],
      containerRef,
    } = this.props;
    const isDragging = isOver && canDrop;

    const indexArray = getIndexArray(entries, defaultIndexRange);

    const minIndex = getMinIndex(indexArray, indexMinLimit);
    const maxIndex = getMaxIndex(indexArray, indexMaxLimit);

    const setHoverItem = (event) => {
      const { start, itemType, setHoverItem, toTime, canEdit } = this.props;
      if (!canEdit) return;

      const unixMouse = start + event.nativeEvent.layerX * toTime;
      const index = getContainerIndex(
        minIndex,
        maxIndex,
        event.nativeEvent.layerY
      );
      const dur = getDefaultDuration(itemType);

      const newSchedule = getValidPosition(
        this.props.items,
        unixMouse - dur / 2,
        unixMouse + dur / 2,
        unixMouse,
        index
      );

      setHoverItem({
        itemType,
        schedule: newSchedule,
      });
    };

    const unsetHoverItem = (event) => {
      event.stopPropagation();
      this.props.setHoverItem(null);
    };

    return (
      <div className="scheduler-container">
        {entries.length === 0 && (
          <div className="scheduler-container-placeholder">{placeholder}</div>
        )}
        {minIndex !== indexMinLimit && (
          <GlyphButton
            size="sm"
            variant="link"
            glyph="add"
            className="scheduler-container-row-index p-0"
            style={{ top: -ITEM_HEIGHT / 2, fontSize: "1.25rem" }}
            onClick={() => onCreateRow(itemType, minIndex - 1)}
          />
        )}
        {indexArray.map((index) => (
          <div
            key={index}
            className="scheduler-container-row-index"
            style={{
              top: getContainerY(index - minIndex),
            }}
          >
            {index > 0 ? index : ""}
          </div>
        ))}
        {maxIndex !== indexMaxLimit && (
          <GlyphButton
            variant="link"
            glyph="add"
            className="scheduler-container-row-index p-0"
            style={{ bottom: -ITEM_HEIGHT / 2, fontSize: "1.25rem" }}
            onClick={() => onCreateRow(itemType, maxIndex + 1)}
          />
        )}
        {connectDropTarget(
          <div
            ref={containerRef}
            className="scheduler-container-target"
            style={{
              height: getContainerY(maxIndex - minIndex + 1),
            }}
            onMouseMove={setHoverItem}
            onMouseLeave={unsetHoverItem}
          >
            {entries.map(({ schedule, ...rest }) => {
              const position = {
                left: (schedule.unixStart - start) * toPixels,
                top: getContainerY(schedule.index - minIndex),
                right: (schedule.unixEnd - start) * toPixels,
              };
              return (
                <DraggableContent
                  key={schedule.id}
                  updateItem={updateItem}
                  {...rest}
                  schedule={schedule}
                  active={schedule.unixStart <= now && now <= schedule.unixEnd}
                  position={position}
                  itemType={itemType}
                  onClickItem={onClickItem}
                  onMouseMove={unsetHoverItem}
                  canEdit={canEdit}
                />
              );
            })}
            {!isDragging && hoverItem && (
              <AddContent
                schedule={hoverItem.schedule}
                itemType={itemType}
                left={(hoverItem.schedule.unixStart - start) * toPixels}
                top={getContainerY(hoverItem.schedule.index - minIndex)}
                right={(hoverItem.schedule.unixEnd - start) * toPixels}
                onClick={onCreateItem}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function calculateNextState(props, monitor, containerRef) {
  const { direction, schedule, item, itemType } = monitor.getItem();
  const { x: dx } = monitor.getDifferenceFromInitialOffset();

  const newSchedule = { ...schedule };
  const snapStart = snapTime(schedule.unixStart + dx * props.toTime);
  const snapEnd = snapTime(schedule.unixEnd + dx * props.toTime);

  switch (direction) {
    case "left": {
      const startLimit = getEndOfPrevious(
        props.items,
        schedule.unixEnd,
        schedule.index,
        schedule.id
      );
      newSchedule.unixStart = Math.max(startLimit, snapStart);
      newSchedule.unixStart = Math.min(
        schedule.unixEnd - SNAP_INTERVAL,
        newSchedule.unixStart
      );

      break;
    }
    case "right": {
      const endLimit = getStartOfNext(
        props.items,
        schedule.unixStart,
        schedule.index,
        schedule.id
      );
      newSchedule.unixEnd = Math.min(endLimit, snapEnd);
      newSchedule.unixEnd = Math.max(
        schedule.unixStart + SNAP_INTERVAL,
        newSchedule.unixEnd
      );
      break;
    }
    case "both": {
      // get the mouse position in the container
      const { x: containerViewportX, y: containerViewportY } =
        containerRef.current.getBoundingClientRect();
      const { x: mouseViewportX, y: mouseViewportY } =
        monitor.getClientOffset();
      const mouseX = mouseViewportX - containerViewportX;
      const mouseY = mouseViewportY - containerViewportY;
      const indexArray = getIndexArray(props.items, props.defaultIndexRange);
      const minIndex = getMinIndex(
        indexArray,
        props.indexMinLimit || -Infinity
      );
      const maxIndex = getMaxIndex(indexArray, props.indexMaxLimit || Infinity);
      const index = getContainerIndex(minIndex, maxIndex, mouseY);
      const unixMouse = props.start + mouseX * props.toTime;

      if (isPositionOccupied(props.items, unixMouse, index, schedule.id)) {
        return { schedule, item, itemType };
      }

      const { unixStart, unixEnd } = getValidPosition(
        props.items,
        snapStart,
        snapEnd,
        unixMouse,
        index,
        schedule.id
      );
      newSchedule.unixStart = unixStart;
      newSchedule.unixEnd = unixEnd;
      newSchedule.index = index;
    }
  }

  return { schedule: newSchedule, item, itemType };
}

const getValidPosition = (
  items,
  unixStart,
  unixEnd,
  unixMouse,
  index,
  excludeId
) => {
  const nextStart = getStartOfNext(items, unixMouse, index, excludeId);
  const prevEnd = getEndOfPrevious(items, unixMouse, index, excludeId);
  const duration = unixEnd - unixStart;
  if (nextStart - prevEnd < duration) {
    // contained between between two scheduled entries
    unixStart = prevEnd;
    unixEnd = nextStart;
  } else if (unixStart <= prevEnd) {
    // snap to previous scheduled entry
    unixStart = prevEnd;
    unixEnd = prevEnd + duration;
  } else if (nextStart <= unixEnd) {
    // snap to next scheduled entry
    unixStart = nextStart - duration;
    unixEnd = nextStart;
  } else {
    // open space
    unixStart = snapTime(unixStart);
    unixEnd = snapTime(unixEnd);
  }

  return { unixStart, unixEnd, index };
};

const getStartOfNext = (items, unixTime, index, excludeId) => {
  return items.reduce((acc, curr) => {
    if (curr.schedule.index !== index || curr.schedule.id === excludeId)
      return acc;

    const otherStart = curr.schedule.unixStart;
    if (unixTime < otherStart && otherStart < acc) return otherStart;
    else return acc;
  }, Infinity);
};

const getEndOfPrevious = (items, unixTime, index, excludeId) => {
  return items.reduce((acc, curr) => {
    if (curr.schedule.index !== index || curr.schedule.id === excludeId)
      return acc;

    const otherEnd = curr.schedule.unixEnd;
    if (unixTime > otherEnd && otherEnd > acc) return otherEnd;
    else return acc;
  }, 0);
};

const getContainerIndex = (minIndex, maxIndex, posY) => {
  const index = Math.floor(posY / ITEM_HEIGHT) + minIndex;
  return Math.min(Math.max(index, minIndex), maxIndex);
};

const getIndexArray = memoizeOne((entries, defaultIndexRange) =>
  uniq(entries.map((entry) => entry.schedule.index).concat(defaultIndexRange))
);

const getMinIndex = memoizeOne((indexArray, indexMinLimit) =>
  Math.max(Math.min(...indexArray), indexMinLimit)
);

const getMaxIndex = memoizeOne((indexArray, indexMaxLimit) =>
  Math.min(Math.max(...indexArray), indexMaxLimit)
);

const isPositionOccupied = (items, unixPos, index, excludeId) => {
  return (
    items.find(
      ({ schedule }) =>
        schedule.index === index &&
        schedule.id !== excludeId &&
        schedule.unixStart <= unixPos &&
        unixPos <= schedule.unixEnd
    ) != null
  );
};

Container.propTypes = {
  start: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  itemType: PropTypes.string.isRequired,
  updateItem: PropTypes.func.isRequired,
  toPixels: PropTypes.number.isRequired,
  toTime: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  onClickItem: PropTypes.func.isRequired,
  now: PropTypes.number.isRequired,
  hoverItem: PropTypes.shape(),
  onCreateItem: PropTypes.func.isRequired,
  onCreateRow: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  indexMinLimit: PropTypes.number,
  indexMaxLimit: PropTypes.number,
  defaultIndexRange: PropTypes.arrayOf(PropTypes.number.isRequired),
};

const ContainerWithDrop = (props) => {
  // used to calculate the mouse position inside the container during drag
  const containerRef = useRef(null);

  const [collected, drop] = useDrop({
    accept: props.itemType,
    hover: (item, monitor) => {
      props.updateItem(
        calculateNextState(props, monitor, containerRef),
        false,
        false
      );
    },
    drop: (item, monitor) => {
      props.updateItem(
        calculateNextState(props, monitor, containerRef),
        true,
        true
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Container
      {...props}
      containerRef={containerRef}
      connectDropTarget={drop}
      isOver={collected.isOver}
      canDrop={collected.canDrop}
    />
  );
};

export default ContainerWithDrop;
