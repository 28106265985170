import React from "react";
import { Nav as ReactNav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { UserContext } from "client/App";
import { CLIENT_CONFIG } from "client/config";
import { useMsal, useUser } from "client/hooks";
import { GLYPHS } from "components/buttons/glyphs";
import logo from "/public/images/logo.svg";

const Nav = () => {
  const { logout } = useMsal();
  const user = useUser();
  const homeRoute = user && user.defaultRoute ? user.defaultRoute : "/schedule";
  return (
    <Navbar
      bg={CLIENT_CONFIG.BACKGROUND}
      className="header justify-content-between"
    >
      <ReactNav>
        <LinkContainer to={homeRoute}>
          <Navbar.Brand>
            <img src={logo} style={{ height: 32 }} />
            <span className="lead text-white mx-2">Play CMS</span>
          </Navbar.Brand>
        </LinkContainer>
        {/* Publish Dropdown Menu */}
        <ArrayACL paths={["/schedule", "/collections", "/promos", "/sports"]}>
          <NavDropdown title="Publish" id="publish-dropdown">
            <MainMenuItem to="/schedule">Schedule</MainMenuItem>
            <MainSideControl>
              <MainMenuItem to="/collections">Collections</MainMenuItem>
              <MainControlAdd to="/collections/create" />
            </MainSideControl>

            <ACL path="/promos">
              <NavDropdown.Header>Promotions</NavDropdown.Header>
              <MainSubMenu>
                <MainSideControl>
                  <MainMenuItem to="/promos/top">Top Banners</MainMenuItem>
                  <MainControlAdd to="/promos/create" />
                </MainSideControl>
                <MainSideControl>
                  <MainMenuItem to="/promos/card">Promo Cards</MainMenuItem>
                  <MainControlAdd to="/promos/create" />
                </MainSideControl>
              </MainSubMenu>
            </ACL>

            <ACL path="/sports">
              <NavDropdown.Header>Sports</NavDropdown.Header>
              <MainSubMenu>
                <MainMenuItem to="/sports/leagues">
                  Leagues &amp; seasons
                </MainMenuItem>
                <MainMenuItem to="/sports/teams">Teams</MainMenuItem>
              </MainSubMenu>
            </ACL>
          </NavDropdown>
        </ArrayACL>

        {/* Content Dropdown Menu */}
        <ArrayACL paths={["/enrich", "/insight", "/tvod", "/imdb"]}>
          <NavDropdown title="Content" id="content-dropdown">
            {/* Content insight */}
            <ACL path="/insight">
              <NavDropdown.Header>Content Insight</NavDropdown.Header>
              <MainSubMenu>
                <MainMenuItem to="/enrich/mediacontent/list">
                  Content Warnings
                </MainMenuItem>
                <MainMenuItem to="/insight/report/list">
                  Content Reports
                </MainMenuItem>
                <MainMenuItem to="/insight/warningstatus/reported/list">
                  Reported Warnings
                </MainMenuItem>
                <MainMenuItem to="/insight/warningstatus/accepted/list">
                  Accepted Warnings
                </MainMenuItem>
                <MainMenuItem to="/insight/warningstatus/spam/list">
                  Spam Warnings
                </MainMenuItem>
                <MainMenuItem to="/insight/warning/stats">
                  Warning Stats
                </MainMenuItem>
                <MainMenuItem to="/insight/report/stats">
                  Report Stats
                </MainMenuItem>
                <MainMenuItem to="/insight/gap-overlap-log">
                  Gap/overlap log
                </MainMenuItem>
                <MainMenuItem to="/insight/gap-overlap-stats">
                  Gap/overlap stats
                </MainMenuItem>
              </MainSubMenu>
              <NavDropdown.Header>Content Insight Settings</NavDropdown.Header>
              <MainSubMenu>
                <MainMenuItem to="/enrich/mediacontent/channelrules">
                  Channel Rules
                </MainMenuItem>
              </MainSubMenu>
            </ACL>
            {/* Enrichment Menu */}
            <ACL path="/enrich">
              <NavDropdown.Header>Enrichment</NavDropdown.Header>
              <MainSubMenu>
                <MainMenuItem to="/enrich/mediacontent/edits">
                  Content Edits
                </MainMenuItem>
                <MainMenuItem to="/enrich/mediacontent/catchup">
                  Catchup (TV-Arkiv)
                </MainMenuItem>
              </MainSubMenu>
            </ACL>
            {/* TVOD Menu */}
            <ACL path="/tvod">
              <NavDropdown.Header>TVOD</NavDropdown.Header>
              <MainSubMenu>
                <MainMenuItem to="/tvod/price-overrides">
                  Price overrides
                </MainMenuItem>
                <MainMenuItem to="/tvod/report">TVOD Report</MainMenuItem>
              </MainSubMenu>
            </ACL>
            {/* IMDb Menu */}
            <ACL path="/imdb">
              <NavDropdown.Header>IMDb</NavDropdown.Header>
              <MainSubMenu>
                <MainMenuItem to="/imdb/unmatched">
                  Unmatched content
                </MainMenuItem>
                <MainMenuItem to="/imdb/matched">Matched content</MainMenuItem>
                <MainMenuItem to="/imdb/ignored">Not on IMDb</MainMenuItem>
              </MainSubMenu>
            </ACL>
          </NavDropdown>
        </ArrayACL>

        {/* AMS Dropdown Menu */}
        <ACL path="/ams">
          <NavDropdown title="AMS" id="ams-dropdown">
            <NavDropdown.Header>Monitor</NavDropdown.Header>
            <MainSubMenu>
              <MainMenuItem to="/ams/assets">Assets</MainMenuItem>
              <MainMenuItem to="/ams/issue-reports">Issue Reports</MainMenuItem>
              <MainSideControl>
                <MainMenuItem to="/ams/providers">Providers</MainMenuItem>
                <MainControlAdd to="/ams/providers/create" />
              </MainSideControl>
              <MainMenuItem to="/ams/configurations">
                Configurations
              </MainMenuItem>
            </MainSubMenu>
          </NavDropdown>
        </ACL>

        {/* Report Dropdown Menu */}
        <ACL path="/monitor">
          <NavDropdown title="Ingestion" id="report-dropdown">
            <MainMenuItem to="/monitor/asset">Asset Monitor</MainMenuItem>
          </NavDropdown>
        </ACL>

        {/* Provisioning Dropdown Menu */}
        <ACL path="/provisioning">
          <NavDropdown title="Provisioning" id="provisioning-dropdown">
            <NavDropdown.Header>DVB</NavDropdown.Header>
            <MainSideControl>
              <MainMenuItem to="/provisioning/dvb/triplets">
                Triplets
              </MainMenuItem>
              <MainControlAdd to="/provisioning/dvb/triplets/create" />
            </MainSideControl>

            <MainMenuItem to="/provisioning/dvb/iptv">IPTV</MainMenuItem>
            <MainSubMenu>
              <MainSideControl>
                <MainMenuItem to="/provisioning/dvb/iptv/fcc">FCC</MainMenuItem>
                <MainControlAdd to="/provisioning/dvb/iptv/fcc/create" />
              </MainSideControl>
              <MainSideControl>
                <MainMenuItem to="/provisioning/dvb/iptv/streams">
                  Streams
                </MainMenuItem>
                <MainControlAdd to="/provisioning/dvb/iptv/streams/create" />
              </MainSideControl>
              <MainSideControl>
                <MainMenuItem to="/provisioning/dvb/iptv/tuning-plans">
                  Plans
                </MainMenuItem>
                <MainControlAdd to="/provisioning/dvb/iptv/tuning-plans/create" />
              </MainSideControl>
              <MainSideControl>
                <MainMenuItem to="/provisioning/dvb/iptv/tuning-plans-aes">
                  Plans AES
                </MainMenuItem>
                <MainControlAdd to="/provisioning/dvb/iptv/tuning-plans-aes/create" />
              </MainSideControl>
            </MainSubMenu>

            <MainMenuItem to="/provisioning/dvb/cable">Cable</MainMenuItem>
            <MainSubMenu>
              <MainSideControl>
                <MainMenuItem to="/provisioning/dvb/cable/streams">
                  Streams
                </MainMenuItem>
                <MainControlAdd to="/provisioning/dvb/cable/streams/create" />
              </MainSideControl>
              <MainSideControl>
                <MainMenuItem to="/provisioning/dvb/cable/tuning-plans">
                  Plans
                </MainMenuItem>
                <MainControlAdd to="/provisioning/dvb/cable/tuning-plans/create" />
              </MainSideControl>
              <MainSideControl>
                <MainMenuItem to="/provisioning/dvb/cable/tuning-plans-aes">
                  Plans AES
                </MainMenuItem>
                <MainControlAdd to="/provisioning/dvb/cable/tuning-plans-aes/create" />
              </MainSideControl>
            </MainSubMenu>

            <NavDropdown.Header>Content Providers</NavDropdown.Header>
            <MainSideControl>
              <MainMenuItem to="/provisioning/content-providers/tv">
                TV
              </MainMenuItem>
              <MainControlAdd to="/provisioning/content-providers/tv/create" />
            </MainSideControl>
            <MainSubMenu>
              <MainSideControl>
                <MainMenuItem to="/provisioning/content-providers/tv/placements">
                  Placements
                </MainMenuItem>
                <MainControlAdd to="/provisioning/content-providers/tv/placements/create" />
              </MainSideControl>
            </MainSubMenu>
            <MainSideControl>
              <MainMenuItem to="/provisioning/content-providers/vod">
                VOD
              </MainMenuItem>
              <MainControlAdd to="/provisioning/content-providers/vod/create" />
            </MainSideControl>

            <NavDropdown.Divider />
            <MainSideControl>
              <MainMenuItem to="/provisioning/lineups">Lineups</MainMenuItem>
              <MainControlAdd to="/provisioning/lineups/create" />
            </MainSideControl>

            <NavDropdown.Header>Configs</NavDropdown.Header>
            <MainSideControl>
              <MainMenuItem to="/provisioning/configs/drafts">
                Drafts
              </MainMenuItem>
              <MainControlAdd to="/provisioning/configs/drafts/create" />
            </MainSideControl>
            <MainSideControl>
              <MainMenuItem to="/provisioning/configs/deployments">
                Deployments
              </MainMenuItem>
              <MainControlAdd to="/provisioning/configs/deployments/create" />
            </MainSideControl>
            <MainSideControl>
              <MainMenuItem to="/provisioning/configs/publications">
                Publications
              </MainMenuItem>
              <MainControlAdd to="/provisioning/configs/publications/create" />
            </MainSideControl>
          </NavDropdown>
        </ACL>

        <ACL path="/epg">
          <NavDropdown title="EPG Ingest" id="epg">
            <MainSideControl>
              <MainMenuItem to="/epg/matches">Programs</MainMenuItem>
            </MainSideControl>
            <MainSideControl>
              <MainMenuItem to="/epg/linear-metadata-db">
                Linear MetadataDB
              </MainMenuItem>
            </MainSideControl>
            <MainSideControl>
              <MainMenuItem to="/epg/linear-video-db">
                Linear VideoDB
              </MainMenuItem>
            </MainSideControl>

            <MainSideControl>
              <MainMenuItem to="/epg/maintenance">Maintenance</MainMenuItem>
            </MainSideControl>
            <MainSideControl>
              <MainMenuItem to="/epg/channel/info">Channels</MainMenuItem>
            </MainSideControl>
          </NavDropdown>
        </ACL>
        <ACL path="/oneapp">
          <NavDropdown title="OneApp" id="oneapp">
            <MainSideControl>
              <MainMenuItem to="/oneapp/tv-guide-comparator">
                TV guide comparator
              </MainMenuItem>
            </MainSideControl>
          </NavDropdown>
        </ACL>
      </ReactNav>

      {/* End of menu; items pulled to the right */}
      <ReactNav>
        <Navbar.Text className="header-version text-muted fw-light opacity-50">
          <span className="me-1">{CLIENT_CONFIG.NAME.toUpperCase()}</span>
        </Navbar.Text>
        <UserContext.Consumer>
          {({ displayName, isLoggedIn }) => {
            if (!isLoggedIn) return null;
            return (
              <>
                <NavDropdown
                  id="user"
                  title={
                    <>
                      <span className="header-username">{displayName}</span>
                      &nbsp;&nbsp;&nbsp;
                      <span className={GLYPHS.USER} />
                    </>
                  }
                  className="no-caret"
                  align="end"
                >
                  <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                  <ACL path="/admin">
                    <NavDropdown.Divider />
                    <NavDropdown.Header>Admin Tools</NavDropdown.Header>
                    <MainMenuItem to="/admin/access-control">
                      Access Control
                    </MainMenuItem>
                    <MainMenuItem to="/admin/usagestats">
                      Usage Stats
                    </MainMenuItem>
                  </ACL>
                </NavDropdown>
              </>
            );
          }}
        </UserContext.Consumer>
      </ReactNav>
    </Navbar>
  );
};

const MainSubMenu = ({ children }) => {
  return <div className="main-sub-menu">{children}</div>;
};

export const ArrayACL = ({ paths, children }) => {
  return (
    <UserContext.Consumer>
      {({ hasAccessTo }) => (paths.some(hasAccessTo) ? children : null)}
    </UserContext.Consumer>
  );
};

const ACL = ({ path, children }) => {
  return (
    <UserContext.Consumer>
      {({ hasAccessTo }) => (hasAccessTo(path) ? children : null)}
    </UserContext.Consumer>
  );
};

export const MainMenuItem = ({ to, children }) => {
  return (
    <ACL path={to}>
      <LinkContainer to={to}>
        <NavDropdown.Item className="main-left-menu-item">
          {children}
        </NavDropdown.Item>
      </LinkContainer>
    </ACL>
  );
};

const MainSideControl = ({ children }) => {
  return (
    <div className="justify-content-between main-side-control">{children}</div>
  );
};

const MainControlAdd = ({ to }) => {
  return (
    <ACL path={to}>
      <LinkContainer to={to}>
        <NavDropdown.Item className="main-right-menu-item">
          <span className={GLYPHS.CREATE} />
        </NavDropdown.Item>
      </LinkContainer>
    </ACL>
  );
};

export default Nav;
