import { PropTypes } from "prop-types";
import React from "react";
import momentPropTypes from "react-moment-proptypes";
import { useComponentSize } from "react-use-size";
import { PublicationTypes } from "client/types/PublicationTypes";
import Container from "./Container";
import ScheduleColumns from "./ScheduleColumns";
import SchedulerOverlays from "./SchedulerOverlays";

export default function SchedulerView({
  now,
  start,
  end,
  collections,
  topPromos,
  otherPromos,
  indexRange,
  loading,
  draggedItem,
  updateItem,
  onClickItem,
  onCreateItem,
  onCreateRow,
  hoverItem,
  setHoverItem,
  canEdit,
  showTopPromos,
  getLinkTo,
}) {
  const { ref, width } = useComponentSize();
  const unixStart = start.unix();
  const unixEnd = end.unix();
  const toPixels = width / (unixEnd - unixStart);
  const toTime = 1 / toPixels;
  const indicatorPosition =
    start.unix() <= now && now <= end.unix()
      ? (now - start.unix()) * toPixels
      : null;

  return (
    <div className="mt-4" ref={ref}>
      <ScheduleColumns start={start} end={end} getLinkTo={getLinkTo} />
      <SchedulerOverlays
        loading={loading}
        indicatorPosition={indicatorPosition}
      />

      <strong>Top Promo</strong>
      <Container
        items={topPromos || []}
        placeholder={
          !showTopPromos
            ? "Top promos can only be scheduled on the front page"
            : undefined
        }
        hoverItem={
          hoverItem && hoverItem.itemType === PublicationTypes.TOP_PROMO
            ? hoverItem
            : null
        }
        start={unixStart}
        end={unixEnd}
        now={now}
        toTime={toTime}
        toPixels={toPixels}
        updateItem={updateItem}
        draggedItem={draggedItem}
        onClickItem={onClickItem}
        onCreateItem={onCreateItem}
        itemType={PublicationTypes.TOP_PROMO}
        setHoverItem={setHoverItem}
        canEdit={canEdit && showTopPromos}
        indexMaxLimit={1}
        defaultIndexRange={indexRange[PublicationTypes.TOP_PROMO]}
        onCreateRow={onCreateRow}
      />

      <strong>Promo Cards</strong>
      <Container
        items={otherPromos}
        hoverItem={
          hoverItem && hoverItem.itemType === PublicationTypes.COVER_PROMO
            ? hoverItem
            : null
        }
        start={unixStart}
        end={unixEnd}
        now={now}
        toTime={toTime}
        toPixels={toPixels}
        updateItem={updateItem}
        draggedItem={draggedItem}
        onClickItem={onClickItem}
        onCreateItem={onCreateItem}
        itemType={PublicationTypes.COVER_PROMO}
        setHoverItem={setHoverItem}
        canEdit={canEdit}
        indexMaxLimit={4}
        defaultIndexRange={indexRange[PublicationTypes.COVER_PROMO]}
        onCreateRow={onCreateRow}
      />

      <strong>Collections</strong>
      <Container
        items={collections}
        hoverItem={
          hoverItem && hoverItem.itemType === PublicationTypes.COLLECTION
            ? hoverItem
            : null
        }
        start={unixStart}
        end={unixEnd}
        now={now}
        toTime={toTime}
        toPixels={toPixels}
        updateItem={updateItem}
        draggedItem={draggedItem}
        onClickItem={onClickItem}
        onCreateItem={onCreateItem}
        itemType={PublicationTypes.COLLECTION}
        setHoverItem={setHoverItem}
        canEdit={canEdit}
        indexMinLimit={0}
        defaultIndexRange={indexRange[PublicationTypes.COLLECTION]}
        onCreateRow={onCreateRow}
      />
    </div>
  );
}

SchedulerView.propTypes = {
  now: PropTypes.number.isRequired,
  start: momentPropTypes.momentObj.isRequired,
  end: momentPropTypes.momentObj.isRequired,
  collections: PropTypes.array.isRequired,
  topPromos: PropTypes.array.isRequired,
  otherPromos: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  draggedItem: PropTypes.shape(),
  updateItem: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  onCreateItem: PropTypes.func.isRequired,
  hoverItem: PropTypes.shape(),
  setHoverItem: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  showTopPromos: PropTypes.bool.isRequired,
  getLinkTo: PropTypes.func.isRequired,
};
