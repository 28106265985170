import React, { useCallback } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useEsindexerStatus } from "client/hooks";
import { formatRelativeTimestamp } from "client/utils/TableCommon";
import KeyValuePair from "components/KeyValuePair";

export function AssetIndexingTab() {
  const { indexes, reindex } = useEsindexerStatus();
  const { reindexingRequested, status } = indexes.ams;
  const reindexingDisabled = !!reindexingRequested || status !== "up-to-date";
  const reindexAmsAssets = useCallback(() => reindex("ams-assets"), [reindex]);

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <KeyValuePair
            id="AMS asset index"
            value={indexes.ams.status}
            labelWidth={2}
          />
          <KeyValuePair
            id="Reindexing requested at"
            value={
              reindexingRequested
                ? formatRelativeTimestamp(reindexingRequested)
                : "N/A"
            }
            labelWidth={2}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ offset: 2 }}>
          <Button
            variant="primary"
            onClick={reindexAmsAssets}
            disabled={reindexingDisabled}
          >
            Reindex AMS assets
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
