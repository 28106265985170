import _ from "lodash";
import { useMemo, useCallback } from "react";
import useSWR from "swr";
import { useUser } from "client/hooks";
import { request } from "client/utils";

type EsindexerStatusValue = "reindexing" | "up-to-date";

interface EsindexerIndexStatusDTO {
  status: EsindexerStatusValue;
  reindexingRequested?: string;
}

interface EsindexerStatusDTO {
  mc: EsindexerIndexStatusDTO;
  assets: EsindexerIndexStatusDTO;
  ams: EsindexerIndexStatusDTO;
}

interface EsindexerIndexStatus {
  status: EsindexerStatusValue;
  reindexingRequested?: Date;
}

interface EsindexerStatus {
  indexes: {
    mc: EsindexerIndexStatus;
    assets: EsindexerIndexStatus;
    ams: EsindexerIndexStatus;
  };
  reindex: (indexName: string) => Promise<void>;
}

export function useEsindexerStatus(): EsindexerStatus {
  const { isLoggedIn } = useUser();
  const statusUrl = isLoggedIn ? "esindexer/status" : null;
  const { data: rawStatus, mutate } = useSWR<EsindexerStatusDTO>(statusUrl, {
    refreshInterval: 60000,
    fallbackData: {
      mc: { status: "up-to-date" },
      assets: { status: "up-to-date" },
      ams: { status: "up-to-date" },
    },
  });
  const indexes = useMemo(
    () =>
      _.mapValues(rawStatus, ({ status, reindexingRequested }) => ({
        status,
        reindexingRequested: reindexingRequested
          ? new Date(reindexingRequested)
          : undefined,
      })),
    [rawStatus]
  );
  const reindex = useCallback(
    async (indexName: string) => {
      await request(`esindexer/${indexName}/reindex`, {
        method: "POST",
      });
      mutate();
    },
    [mutate]
  );

  return { indexes, reindex };
}
