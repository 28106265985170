import _ from "lodash";

export function stringify(value: unknown): string | undefined | null {
  if (_.isString(value)) {
    return value;
  } else if (value instanceof Error) {
    return value.message;
  } else if (_.isObject(value)) {
    return JSON.stringify(value, null, 2);
  } else if (value === undefined || value === null) {
    return value;
  } else {
    return String(value);
  }
}
