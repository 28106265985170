import _ from "lodash";
import React from "react";
import { Badge, Col, Collapse, Form, Row } from "react-bootstrap";
import useSWRImmutable from "swr/immutable";
import { NumberParam, useQueryParam } from "use-query-params";
import { useDebouncedValue } from "client/hooks";
import { useProgress } from "client/hooks/useProgress";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import ProgressBarWrapper from "components/ProgressBarWrapper";
import { Truncate } from "components/Truncate";
import { StatusBadge } from "components/badges";
import { Log, LogsResponse } from "../types";
import { getVariantFromLogLevel } from "./toNode";

const loadingEstimationInSeconds = (days: number) => {
  return 5 + 1 * days;
};

const progressBarUpdateIntervalSeconds = 0.75;

interface Props {
  id: number;
}

function LogsTab({ id }: Props) {
  const [days = 1] = useQueryParam("days", NumberParam);
  const [limit = 100] = useQueryParam("limit", NumberParam);
  const url = React.useMemo(() => {
    if (!id || limit === 0 || days === 0) {
      return undefined;
    }
    return `ams/assets/${id}/logs?days=${days}&limit=${limit}`;
  }, [id, limit, days]);
  const debouncedUrl = useDebouncedValue(url);
  const { data: res, isValidating } =
    useSWRImmutable<LogsResponse>(debouncedUrl);
  const { data: logs } = res || {};

  const { progress, clearProgress } = useProgress(
    loadingEstimationInSeconds(days || 1),
    progressBarUpdateIntervalSeconds,
    !isValidating
  );

  React.useEffect(() => {
    if (isValidating) clearProgress();
  }, [limit, days, isValidating, clearProgress]);

  return (
    <>
      <Row className="mx-3 mt-3">
        <LogsSearchBar />
        {!isValidating && _.isEmpty(logs) ? (
          <h3 className="mt-3">No logs available.</h3>
        ) : (
          <>
            <LogsHeader className="mt-3" />
            {!isValidating &&
              logs?.map((log, index) => (
                <React.Fragment key={index}>
                  <LogRow
                    timestamp={log.timestamp}
                    service={log.service}
                    level={log.level}
                    message={log.message}
                    exception={log.exception}
                  />
                </React.Fragment>
              ))}
          </>
        )}
      </Row>
      {isValidating && (
        <ProgressBarWrapper
          className="mt-3"
          variant="primary"
          now={progress}
          animated
        />
      )}
    </>
  );
}

const LogsHeader = ({ ...rest }) => (
  <Row {...rest}>
    <Truncate className="text-secondary" style={{ width: 150 }}>
      Timestamp
    </Truncate>
    <Truncate className="text-secondary" style={{ width: 150 }}>
      Service
    </Truncate>
    <Truncate className="text-secondary" style={{ width: 100 }}>
      Level
    </Truncate>
    <Truncate as={Col} className="text-secondary">
      Message
    </Truncate>
    <div className="text-secondary" style={{ width: 100 }}>
      Exception
    </div>
  </Row>
);

const LogRow = React.memo(function ({
  timestamp,
  service,
  level,
  message,
  exception,
}: Omit<Log, "yaaf_asset_id">) {
  const [showExpandedView, setShowExpandedView] =
    React.useState<boolean>(false);

  return (
    <Row className="gx-2 bg-light mb-1 expand-row">
      <Row
        className="align-items-center"
        onClick={() => setShowExpandedView(!showExpandedView)}
      >
        <Truncate style={{ width: 150 }}>
          {parseDateToDatetimeString(timestamp)}
        </Truncate>
        <Truncate style={{ width: 150 }}>{service}</Truncate>
        <Truncate style={{ width: 100 }}>
          <StatusBadge
            status={level}
            statusToVariant={getVariantFromLogLevel}
          />
        </Truncate>
        <Truncate as={Col} className="collapsed">
          {message}
        </Truncate>
        <div style={{ width: 92 }}>
          {exception ? <Badge bg="danger">Exception</Badge> : null}
        </div>
      </Row>

      <Collapse in={showExpandedView}>
        <Row>
          {message && (
            <Col>
              <ExpandedLogRow title="Message:" content={message} />
            </Col>
          )}
          {exception && (
            <Col>
              <ExpandedLogRow title="Exception:" content={exception} />
            </Col>
          )}
        </Row>
      </Collapse>
    </Row>
  );
});

const ExpandedLogRow = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  return (
    <>
      <span className="fw-bold">{title}</span>
      <p
        id="collapse-exception"
        className="px-5"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {content}
      </p>
    </>
  );
};

const LogsSearchBar = () => {
  const [days, setDays] = useQueryParam("days", NumberParam);
  const [limit, setLimit] = useQueryParam("limit", NumberParam);
  return (
    <Row>
      <Col as={Form.Group} xs={2}>
        <Form.Label>Days</Form.Label>
        <Form.Control
          type="number"
          value={days || ""}
          onChange={(e) => setDays(Number(e.target.value))}
        />
      </Col>
      <Col as={Form.Group} xs={2}>
        <Form.Label>Limit</Form.Label>
        <Form.Control
          type="number"
          min={1}
          value={limit || ""}
          onChange={(e) => setLimit(Number(e.target.value))}
        />
      </Col>
    </Row>
  );
};

export default LogsTab;
