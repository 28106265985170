import React from "react";
import { Row, Col } from "react-bootstrap";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import ConfigurationValue from "./ConfigurationValue";

function ConfigurationRow({ config, updateConfig }) {
  const { key, value, updated } = config;

  return (
    <Row className="my-2 align-items-center">
      <Col xs={2}>{parseDateToDatetimeString(updated)}</Col>
      <Col xs={3}>{key}</Col>
      <Col xs={7} className="d-flex align-items-center">
        <ConfigurationValue
          configKey={key}
          value={value}
          updateConfig={updateConfig}
        />
      </Col>
    </Row>
  );
}

export default ConfigurationRow;
