import React from "react";
import { Form } from "react-bootstrap";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";

const OPTIONS = [
  { value: "any", label: "Any" },
  { value: "catchup", label: "Has Catchup" },
  { value: "httpStream", label: "Has HTTP Stream" },
  { value: "recordable", label: "Has Recordable" },
  { value: "externalCatchup", label: "Has External Catchup" },
  { value: "catchup-negated", label: "Doesn't have Catchup" },
  { value: "httpStream-negated", label: "Doesn't have HTTP Stream" },
  { value: "recordable-negated", label: "Doesn't have Recordable" },
  { value: "externalCatchup-negated", label: "Doesn't have External Catchup" },
];

export const PropertyFilter = () => {
  const [property = "any", setProperty] = useQueryParam(
    "property",
    StringParam
  );
  const [propertyNegated, setPropertyNegated] = useQueryParam(
    "propertyNegated",
    BooleanParam
  );

  const selectedValue = property + (propertyNegated ? "-negated" : "");

  return (
    <>
      <Form.Label htmlFor="property-filter">Property</Form.Label>
      <Form.Select
        value={selectedValue}
        onChange={(e) => {
          const propertyAndNegated = e.target.value.split("-");
          setProperty(propertyAndNegated[0]);
          setPropertyNegated(propertyAndNegated[1] === "negated");
        }}
      >
        {OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
