import React from "react";
import { Link } from "react-router-dom";
import { GLYPHS } from "components/buttons/glyphs";

interface Props {
  channelId: string;
  setEditingChannel: (channelId: string | undefined) => void;
}

export const ManageCell = ({ channelId, setEditingChannel }: Props) => {
  return (
    <Link
      to="#"
      onClick={(event) => {
        event.preventDefault();
        setEditingChannel(channelId);
      }}
      aria-label="Edit"
    >
      <i className={GLYPHS.EDIT}></i>
    </Link>
  );
};
