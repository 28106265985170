import { capitalize } from "@telia-company/tv.no-play-cms-common/api/util";
import { PropTypes } from "prop-types";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const AVAILABLE_LANGUAGES = [
  "no",
  "en",
  "sv",
  "da",
  "fi",
  "es",
  "de",
  "it",
  "tr",
  "gr",
  "pl",
  "ar",
];

export const LanguageMultiPicker = ({
  onChange,
  value,
  languages = AVAILABLE_LANGUAGES,
  placeholder = "Add languages here...",
  disabled,
}) => (
  <Typeahead
    id="language-multi"
    multiple
    selected={value}
    options={languages}
    disabled={disabled}
    labelKey={capitalize}
    placeholder={placeholder}
    onChange={onChange}
  />
);

const itemShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
});

LanguageMultiPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(itemShape).isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
