import { PropTypes } from "prop-types";
import React from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { CmsPure } from "client/utils";

/**
 * Stylised Title FormControl component. Children float to
 * the right of the control.
 */
class TitleFormControl extends CmsPure {
  render() {
    const {
      title = "",
      readOnly,
      children,
      onChangeTitle,
      ...rest
    } = this.props;
    return (
      <FormGroup className="position-relative">
        <FormLabel hidden>Title</FormLabel>
        <FormControl
          readOnly={readOnly}
          className="title"
          type="text"
          autoFocus
          value={title || ""}
          onChange={onChangeTitle}
          {...rest}
        />
        {children && (
          <div className="title d-flex align-items-center position-absolute-right z-3 px-2 ">
            {children}
          </div>
        )}
      </FormGroup>
    );
  }
}

TitleFormControl.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onChangeTitle: PropTypes.func.isRequired,
};

export default TitleFormControl;
