import React, { useState, useCallback } from "react";
import { Form } from "react-bootstrap";

function BooleanConfigurationValue({ value, configKey, submit, isLoading }) {
  const [currentValue, setCurrentValue] = useState(value);
  const toggle = useCallback(() => {
    const toggledValue = !currentValue;
    submit(toggledValue);
    setCurrentValue(toggledValue);
  }, [submit, currentValue, setCurrentValue]);

  return (
    <Form.Switch
      id={configKey}
      checked={currentValue}
      onChange={toggle}
      variant="primary"
      disabled={isLoading}
    />
  );
}

export default BooleanConfigurationValue;
