import { useHistory } from "react-router-dom";
import {
  BooleanParam,
  NumericArrayParam,
  StringParam,
  useQueryParam,
} from "use-query-params";
import { ScheduleEventRow } from "../types/ScheduleEvent";

export const useQueryParamString = () => {
  const [title] = useQueryParam("title", StringParam);
  const [channels] = useQueryParam("channel", NumericArrayParam);
  const [selectAll] = useQueryParam("selectAll", BooleanParam);
  const [start] = useQueryParam("start", StringParam);
  const [end] = useQueryParam("end", StringParam);
  const [page] = useQueryParam("page", StringParam);
  const [size] = useQueryParam("size", StringParam);

  const searchParams = new URLSearchParams();
  if (title) {
    searchParams.append("title", title);
  }
  if (channels) {
    for (const channel of channels) {
      if (channel !== null) {
        searchParams.append("channel", channel.toString());
      }
    }
  }
  if (selectAll) {
    searchParams.append("selectAll", "1");
  }
  if (start) {
    searchParams.append("start", start);
  }
  if (end) {
    searchParams.append("end", end);
  }
  if (page) {
    searchParams.append("page", page);
  }
  if (size) {
    searchParams.append("size", size);
  }
  return "?" + searchParams.toString();
};

export const useOpenProgramMatchOrDetailsCallback = () => {
  const history = useHistory();
  const queryParamString = useQueryParamString();
  return (row: ScheduleEventRow) => {
    if (row.mediaContentId) {
      history.push(
        `/epg/linear-metadata-db/match/from/${row.tmsId}/to/${row.mediaContentId}` +
          queryParamString
      );
    } else {
      history.push(
        `/epg/linear-metadata-db/details/${row.tmsId}` + queryParamString
      );
    }
  };
};

export const useCloseProgramMatchCallback = () => {
  const history = useHistory();
  const queryParamString = useQueryParamString();
  return () => history.push("/epg/linear-metadata-db" + queryParamString);
};
