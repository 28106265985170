import React, { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { useEsindexerStatus } from "client/hooks";

const ReindexingNotice = () => {
  const { indexes } = useEsindexerStatus();

  const reindexingMessage = useMemo(() => {
    const { mc, assets } = indexes;
    const updatedIndexTypes = [];

    if (mc.status === "reindexing") {
      updatedIndexTypes.push("media content");
    }
    if (assets.status === "reindexing") {
      updatedIndexTypes.push("asset");
    }
    const indexTypes = updatedIndexTypes.join(" and ");

    if (indexTypes === "") {
      return "";
    } else {
      const singular = updatedIndexTypes.length === 1;
      const is = singular ? "is" : "are";
      const index = singular ? "index" : "indexes";

      return [
        `The ${indexTypes} search ${index} ${is} being updated.`,
        `Recent ${indexTypes} changes may not be reflected in the CMS until`,
        "the update is complete. Sorry for the inconvenience!",
      ].join(" ");
    }
  }, [indexes]);

  if (reindexingMessage) {
    return (
      <Alert variant="warning" className="mb-0">
        {reindexingMessage}
      </Alert>
    );
  }

  return null;
};

export default ReindexingNotice;
