import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { UserContext } from "client/App";
import { AssetModal } from "client/views/Enrich/MediaContent/AssetModal";
import { MediaContentModal } from "client/views/Enrich/MediaContent/MediaContentModal";
import { ACLMenuItem } from "components/toolbar/ACLMenu";

const EnrichmentToolbar = () => (
  <div className="secondary publish-toolbar">
    <UserContext.Consumer>
      {({ hasAccessTo }) => (
        <ButtonGroup className="me-auto">
          <ACLMenuItem
            to="/enrich/mediacontent/edits"
            hasAccessTo={hasAccessTo}
          >
            Content Edits
          </ACLMenuItem>
          <ACLMenuItem
            to="/enrich/mediacontent/catchup"
            hasAccessTo={hasAccessTo}
          >
            Catchup (TV-Arkiv)
          </ACLMenuItem>
        </ButtonGroup>
      )}
    </UserContext.Consumer>
    <div className="justify-content-end">
      <MediaContentModal />
      <AssetModal />
    </div>
  </div>
);

export default EnrichmentToolbar;
