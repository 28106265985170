import React from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { CmsCardContainer, CollectionCard } from "components/cards";
import { OrderControl } from "components/controls/ToggleControl";
import SingleSelect from "../SingleSelect";
import { useQuery, useCollections, useCollectionTypeOptions } from "./state";

export function CollectionSearchModal({
  show,
  size = "lg",
  onSelectCollection,
  allowMultiple = undefined,
  selectedIds = undefined,
  onAdd = undefined,
  onHide,
  allowedCollectionTypes = undefined,
  ...rest
}) {
  const [query, setQuery] = useQuery();
  const collections = useCollections(show, query, allowedCollectionTypes);
  const clientTypeOptions = useCollectionTypeOptions(allowedCollectionTypes);

  return (
    <Modal show={show} size={size} onHide={onHide} {...rest}>
      <Modal.Header>Select a collection</Modal.Header>
      <Modal.Body>
        <Row as={Form} className="mb-3">
          <Col as={Form.Group}>
            <Form.Control
              autoFocus
              type="text"
              placeholder="Filter collections by title"
              value={query.title}
              onChange={(e) => setQuery({ title: e.target.value })}
            />
          </Col>
          <Col xs={3}>
            <SingleSelect
              options={clientTypeOptions}
              onChange={(clientType) => setQuery({ clientType })}
              value={query.clientType}
            />
          </Col>
          <Col xs="auto">
            <Button
              variant={query.activeOnly ? "info" : "light"}
              onClick={() => setQuery({ activeOnly: !query.activeOnly })}
            >
              Active only
            </Button>
          </Col>
          <Col xs="auto">
            <OrderControl
              value={query.sortBy}
              onChange={(sortBy) => setQuery({ sortBy })}
              className="my-auto"
            />
          </Col>
        </Row>
        <CmsCardContainer>
          {collections.map((collection) => (
            <CollectionCard
              id={collection.id}
              key={collection.id}
              item={collection}
              onClick={() => onSelectCollection(collection.id)}
              selected={allowMultiple && selectedIds.includes(collection.id)}
              canClick={true}
              zoomOnHover={true}
            />
          ))}
        </CmsCardContainer>
      </Modal.Body>
      {allowMultiple && (
        <Modal.Footer>
          <Button variant="primary" onClick={onAdd}>
            Add
          </Button>
          <div className="divider" />
          <Button variant="default" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
