import { PropTypes } from "prop-types";
import React, { useCallback, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useRequest } from "client/hooks";
import { formatFootballEventCard } from "client/views/Sports/utils";
import { GlyphButton } from "components/buttons";
import { CmsCardContainer, FootballEventCard } from "components/cards";

function mapData(datum) {
  return {
    item: formatFootballEventCard(datum),
    id: datum.id,
    leagueId: datum.competitionId,
  };
}

function FootballEventDialog(props) {
  const [activeOnly, setActiveOnly] = useState(true);
  const [data, setData] = useState([]);
  useRequest("sports/seasons?mappedOnly=true&activeOnly=" + activeOnly, {
    blockRequests: !props.show,
    onSuccess: (res) => setData(res.map(mapData)),
  });
  const toggleActiveOnly = useCallback(
    () => setActiveOnly((current) => !current),
    []
  );

  return (
    <Modal size="xl" autoFocus show={props.show} onHide={props.onClose}>
      <Modal.Header as={Row}>
        <Col>
          <Modal.Title>Select a football event</Modal.Title>
        </Col>
        <Col xs="auto"></Col>
        <Button
          variant={activeOnly ? "info" : "light"}
          onClick={toggleActiveOnly}
        >
          Active only
        </Button>
      </Modal.Header>
      <Modal.Body>
        <CmsCardContainer>
          {data.map((data) => (
            <FootballEventCard
              item={data.item}
              seasonId={data.id}
              leagueId={data.leagueId}
              key={data.id}
              onClick={() => props.onSelect(Number(data.id))}
              showLink={false}
              canClick={data.item.active}
              zoomOnHover
            />
          ))}
        </CmsCardContainer>
        <div className="mt-4">
          <GlyphButton to="/sports/leagues" size="sm">
            View all Sports Seasons
          </GlyphButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

FootballEventDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default FootballEventDialog;
