import queryString from "query-string";
import React, { useState, useEffect, useCallback } from "react";
import { request } from "client/utils";
import { clickOnDownloadLink } from "client/utils/Helpers";
import { GlyphButton } from "./";
import { GLYPHS } from "./glyphs";

function getFilenameFromContentDisposition(contentDisposition: string | null) {
  if (contentDisposition) {
    const pattern = /^attachment; +filename="?(?<filename>[^"]+)"?$/i;
    const m = contentDisposition.trim().match(pattern);
    return m?.groups?.filename;
  }
}

/**
 * Create a button to download the result of an API request.
 *
 * Perform an authenticated GET request to the specified path and
 * download the response as an attachment, using the filename from the
 * Content-Disposition header as the suggested filename.
 */
export default function DownloadButton({
  path,
  children,
  queryParams = undefined,
  glyph = "save",
  ...rest
}: {
  path: string;
  children?: React.ReactNode;
  queryParams?: Record<string, unknown>;
  glyph: keyof typeof GLYPHS | Lowercase<keyof typeof GLYPHS>;
}) {
  const [inProgress, setInProgress] = useState(false);
  const [response, setResponse] = useState<Response | null>(null);

  const startDownload = useCallback(() => {
    setInProgress(true);
    request(
      path + (queryParams ? `?${queryString.stringify(queryParams)}` : ""),
      {},
      false
    )
      .then((res) => {
        setResponse(res);
        setInProgress(false);
      })
      .catch(() => setInProgress(false));
  }, [path, queryParams, setInProgress, setResponse, request]);

  useEffect(() => {
    if (response && response.ok) {
      response.blob().then((blob) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        const filename = getFilenameFromContentDisposition(contentDisposition);
        clickOnDownloadLink(blob, filename);
      });
    }
  }, [response]);

  return (
    <GlyphButton
      glyph={glyph}
      disabled={inProgress}
      onClick={startDownload}
      {...rest}
    >
      {children}
    </GlyphButton>
  );
}
