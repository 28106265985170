import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { permissionTypes, ResourcePermission } from "client/ACL";
import { MultiSelect } from "components/pickers";

const RoleModal: React.FC<{
  role: {
    role: string;
    permission: ResourcePermission[];
    default_route: string;
  };
  onChange: (newValue: object) => void;
  onSave: () => void;
}> = ({
  role: { role, permission, default_route },
  onChange,
  onSave,
  ...rest
}) => {
  return (
    <Modal centered {...rest}>
      <Form onSubmit={onSave}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label hidden>Role</Form.Label>
            <Form.Control
              placeholder="Role name"
              value={role}
              onChange={(e) => onChange({ role: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label hidden>Permissions</Form.Label>
            <MultiSelect
              placeholder="Select permissions"
              value={permission}
              options={permissionTypes}
              onChange={(permission) => onChange({ permission })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label hidden>Default Route</Form.Label>
            <Form.Control
              placeholder="Default route on login"
              value={default_route}
              onChange={(e) => onChange({ default_route: e.target.value })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={!role || !permission.length}>
            Confirm
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RoleModal;
