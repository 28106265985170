import React from "react";
import { Alert } from "react-bootstrap";
import { useKeptResult } from "client/hooks/useKeptResult";
import { useProgram } from "../hooks";
import { DatabaseName } from "../types";
import { ProgramAssets } from "./ProgramAssets";
import { ProgramContentChildren } from "./ProgramContentChildren";

interface ProgramChildrenProps {
  id: string;
  database: DatabaseName;
}

export const ProgramChildren = ({ id, database }: ProgramChildrenProps) => {
  const { program, error } = useProgram(id, database);

  const keptProgram = useKeptResult(program);

  if (error) {
    return <Alert variant="danger">Could not load program ID {id}</Alert>;
  }

  if (!keptProgram) {
    return <div>Loading...</div>;
  }

  const { data, assets } = keptProgram;

  const isParentKind =
    data?.kind &&
    (data?.kind === "series" ||
      data?.kind === "season" ||
      data?.kind === "show");
  return isParentKind && database === "TELIADB" ? (
    <>
      <h3 className="lead border-bottom">Media content children</h3>

      <ProgramContentChildren id={id} />
    </>
  ) : (
    <>
      <h3 className="lead border-bottom">Assets</h3>

      <ProgramAssets assets={assets} database={database} />
    </>
  );
};
