import React from "react";
import { useUser } from "client/hooks";
import { AssetModal } from "client/views/Enrich/MediaContent/AssetModal";
import { MediaContentModal } from "client/views/Enrich/MediaContent/MediaContentModal";
import { ACLMenuItem } from "components/toolbar/ACLMenu";

export function Toolbar() {
  const { hasAccessTo } = useUser();
  return (
    <div className="publish-toolbar justify-content-between">
      <div className="flex-row">
        <ACLMenuItem to="/epg/matches" hasAccessTo={hasAccessTo}>
          Programs
        </ACLMenuItem>
        <ACLMenuItem to="/epg/linear-metadata-db" hasAccessTo={hasAccessTo}>
          Linear MetadataDB
        </ACLMenuItem>
        <ACLMenuItem to="/epg/linear-video-db" hasAccessTo={hasAccessTo}>
          Linear VideoDB
        </ACLMenuItem>
        <ACLMenuItem to="/epg/maintenance" hasAccessTo={hasAccessTo}>
          Maintenance
        </ACLMenuItem>
        <ACLMenuItem to="/epg/channel/info" hasAccessTo={hasAccessTo}>
          Channels
        </ACLMenuItem>
      </div>
      <div className="justify-content-end">
        <MediaContentModal />
        <AssetModal />
      </div>
    </div>
  );
}
