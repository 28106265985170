import classNames from "classnames";
import React from "react";
import { useComponentSize } from "react-use-size";
import { GlyphButton } from "components/buttons";
import { getCloudImagePath, getRequestImageSize } from "components/image";
import ImageDownload from "components/image/ImageDownload";
import { CmsCard } from "./CmsCard";

const CloudImageCard = ({
  value,
  width,
  height,
  isProcessing = false,
  disabled = false,
  description = undefined,
  download = false,
  onDelete = undefined,
}) => {
  const { ref, width: componentWidth } = useComponentSize();
  const size = getRequestImageSize(componentWidth, width, height);
  const src = getCloudImagePath(value, size.width, size.height);

  let maxHeight = "100px";
  let componentHeight = "100%";
  const maxWidth = `${componentWidth}px`;

  if (width && height && componentWidth) {
    const aspectRatio = height / width;
    maxHeight = `${aspectRatio * componentWidth}px`;
    componentHeight = maxHeight;
  }

  return (
    <CmsCard.Base
      variant="custom"
      roundedCorners={false}
      canClick={false}
      style={{
        minHeight: "100px",
        width: "100%",
        height: componentHeight,
        maxHeight,
        textAlign: "center",
      }}
      className={classNames({ disabled })}
      ref={ref}
    >
      {value && <img src={src} style={{ maxHeight, maxWidth }} />}
      <CmsCard.Add
        roundedCorners={false}
        className={classNames("fade", isProcessing || !value ? "in" : "out")}
      >
        <div>{description}</div>
      </CmsCard.Add>

      {isProcessing && <div className="image-control-spinner" />}
      {value && !disabled && (onDelete || download) && (
        <div className="image-actions-overlay">
          <div className="image-actions">
            {download && <ImageDownload images={[value]} />}
            {onDelete && (
              <GlyphButton
                glyph="delete"
                variant="danger"
                size="sm"
                onClick={onDelete}
                className="ms-2"
              />
            )}
          </div>
        </div>
      )}
    </CmsCard.Base>
  );
};

export default React.memo(CloudImageCard);
