import React from "react";
import { JSONTree } from "react-json-tree";

interface Props {
  data: object;
  style?: object;
}

const StyledJSONTree = ({ data, style, ...rest }: Props) => (
  <JSONTree
    {...rest}
    data={data}
    hideRoot={true}
    theme={{
      tree: (props) => ({
        ...props,
        style: {
          ...props.style,
          backgroundColor: undefined, // update the style of the tree view
          border: "1px solid #ccc",
          borderRadius: 5,
          paddingLeft: 10,
          ...style,
        },
      }),
    }}
  />
);

export default StyledJSONTree;
