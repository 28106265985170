import { gql, useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { useKeptResult } from "client/hooks/useKeptResult";
import { AssetsResponse } from "client/types/AssetInfo";
import {
  ProgramItem,
  TableRow,
} from "client/views/OneApp/TvGuideComparator/types";
import { OneAppTvGuide } from "./OneAppTvGuide";
import { TeliaNoTvGuide } from "./TeliaNoTvGuide";

const GET_PROGRAMS = gql`
  query GetProgramsForChannel(
    $channelId: String!
    $input: ProgramByTimeInput!
  ) {
    channel(id: $channelId) {
      programsByTime(input: $input) {
        programItems {
          id
          title
          startTime {
            isoString
          }
          endTime {
            isoString
          }
        }
      }
    }
  }
`;

interface Response {
  channel: {
    programsByTime: {
      programItems: ProgramItem[];
    };
  };
}

export const TvGuideComparator = () => {
  const data = useTvGuideData();
  return (
    <Container fluid className="my-4">
      <DayPagination />
      <Row>
        <Col xs={6}>
          <h1>TPNO exporting</h1>
        </Col>
        <Col xs={6}>
          <h1>OANO presenting</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          {data && <TeliaNoTvGuide combinedPrograms={data.combinedPrograms} />}
        </Col>
        <Col xs={6}>
          {data && <OneAppTvGuide combinedPrograms={data.combinedPrograms} />}
        </Col>
      </Row>
    </Container>
  );
};

const useTvGuideData = (): {
  loading: boolean;
  combinedPrograms?: TableRow[];
} => {
  const [oneappChannel] = useQueryParam("oneappChannel", StringParam);
  const [teliaNoChannel] = useQueryParam("teliaNoChannel", NumberParam);
  const [dayOffset] = useQueryParam("dayOffset", NumberParam);
  const { data: oneAppData } = useQuery<Response>(GET_PROGRAMS, {
    variables: {
      channelId: oneappChannel,
      input: {
        from: DateTime.now()
          .plus({ days: dayOffset || undefined })
          .startOf("day")
          .toMillis(),
        to: DateTime.now()
          .plus({ days: dayOffset || undefined })
          .endOf("day")
          .toMillis(),
      },
    },
  });
  const { data: teliaNoData } = useSWR<AssetsResponse>(
    teliaNoChannel
      ? `assets/by-channel-and-day-offset/${teliaNoChannel}/${dayOffset}`
      : null
  );
  const keptOneAppData = useKeptResult(oneAppData);
  const keptTeliaNoData = useKeptResult(teliaNoData);
  let oneAppPrograms = keptOneAppData?.channel.programsByTime.programItems;
  let teliaNoPrograms = keptTeliaNoData?.programs;

  if (!oneAppPrograms && !teliaNoPrograms) {
    return { loading: true };
  }
  if (!oneAppPrograms) {
    oneAppPrograms = [];
  }
  if (!teliaNoPrograms) {
    teliaNoPrograms = [];
  }

  const combinedPrograms = [];
  let oneAppIndex = 0;
  let teliaNoIndex = 0;
  while (
    oneAppIndex < oneAppPrograms.length ||
    teliaNoIndex < teliaNoPrograms.length
  ) {
    const oneAppProgram = oneAppPrograms[oneAppIndex];
    const teliaNoProgram = teliaNoPrograms[teliaNoIndex];
    if (!oneAppProgram) {
      combinedPrograms.push({ id: teliaNoProgram.id, teliaNo: teliaNoProgram });
      teliaNoIndex++;
    } else if (!teliaNoProgram) {
      combinedPrograms.push({ id: oneAppProgram.id, oneApp: oneAppProgram });
      oneAppIndex++;
    } else {
      const oneAppStart = DateTime.fromISO(oneAppProgram.startTime.isoString);
      const teliaNoStart = DateTime.fromISO(teliaNoProgram.linear_start);
      if (oneAppStart.equals(teliaNoStart)) {
        combinedPrograms.push({
          id: teliaNoProgram.id,
          oneApp: oneAppProgram,
          teliaNo: teliaNoProgram,
        });
        oneAppIndex++;
        teliaNoIndex++;
      } else if (oneAppStart < teliaNoStart) {
        combinedPrograms.push({ id: oneAppProgram.id, oneApp: oneAppProgram });
        oneAppIndex++;
      } else {
        combinedPrograms.push({
          id: teliaNoProgram.id,
          teliaNo: teliaNoProgram,
        });
        teliaNoIndex++;
      }
    }
  }
  return {
    loading: false,
    combinedPrograms,
  };
};

const DayPagination = () => {
  const [dayOffset = 0, setDayOffset] = useQueryParam("dayOffset", NumberParam);

  return (
    <div className="text-center">
      <Button onClick={() => setDayOffset((dayOffset as number) - 1)}>
        <i className="fa fa-arrow-left"></i>
      </Button>
      <span className="mx-2">
        {DateTime.now()
          .plus({ days: dayOffset || undefined })
          .toFormat("yyyy-MM-dd")}
      </span>
      <Button onClick={() => setDayOffset((dayOffset as number) + 1)}>
        <i className="fa fa-arrow-right"></i>
      </Button>
    </div>
  );
};
