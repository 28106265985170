import React from "react";
import { Button } from "react-bootstrap";
import { State } from "./types";

interface SaveButtonProps {
  save: (body: unknown) => void;
  state: State;
}

export const SaveButton = ({ save, state }: SaveButtonProps) => {
  return (
    <Button
      onClick={() => {
        const description_language =
          state.descriptionLanguage !== "" ? state.descriptionLanguage : null;
        const type = state.type !== "None" ? state.type : null;
        const sla = state.sla !== "None" ? state.sla : null;
        const gnClass = state.gnClass !== "None" ? state.gnClass : null;
        save({
          prg_serv_id: state.prgServId,
          description_language,
          comment: state.comment,
          type,
          sla,
          gn_class: gnClass,
          note_on_catchup: state.noteOnCatchup,
        });
      }}
    >
      Save
    </Button>
  );
};
