import React, { useCallback, useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { Link } from "react-router-dom-v5-compat";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import { Truncate } from "components/Truncate";
import { IssueReport } from "../types";

interface Props extends React.HTMLProps<HTMLDivElement> {
  reports?: IssueReport[];
  showAsset?: boolean;
}

export function IssueReportsTable({
  reports,
  showAsset = false,
  ...rest
}: Props) {
  if (!reports) {
    return <h3 className="text-secondary">Loading...</h3>;
  }

  if (reports.length === 0) {
    return <h3>No issue reports</h3>;
  }

  return (
    <div {...rest}>
      <Row className="px-3 gx-2 text-secondary">
        <Col xs={3} lg={2}>
          Created
        </Col>
        {showAsset && <Col xs={1}>Asset</Col>}
        <Col>Report</Col>
      </Row>
      {reports.map((r) => (
        <IssueReportRow key={r.id} report={r} showAsset={showAsset} />
      ))}
    </div>
  );
}

interface IssueReportRowProps {
  report: IssueReport;
  showAsset?: boolean;
}

function IssueReportRow({ report, showAsset = false }: IssueReportRowProps) {
  const [state, setState] = useState({
    expanded: false,
    collapsing: false,
  });
  const { expanded, collapsing } = state;

  const toggleRow = useCallback(() => {
    if (expanded) {
      setState((s) => ({ ...s, collapsing: true }));
    } else {
      setState({ expanded: true, collapsing: false });
    }
  }, [expanded, collapsing, setState]);

  const finishCollapsing = useCallback(
    () =>
      setState({
        expanded: false,
        collapsing: false,
      }),
    [setState]
  );

  const collapsedJson = JSON.stringify(report.data_received, null, " ");
  const expandedJson = JSON.stringify(report.data_received, null, 2);
  const [firstLine, ...otherLines] = expandedJson.split("\n");
  const summary = expanded ? firstLine : collapsedJson;

  return (
    <Row className="px-3 gx-2 bg-light mb-1">
      <Col xs={3} lg={2}>
        {parseDateToDatetimeString(report.created)}
      </Col>
      {showAsset && (
        <Col xs={1}>
          {report.asset_id ? (
            <Link to={`/ams/assets/${report.asset_id}`}>{report.asset_id}</Link>
          ) : (
            <span className="text-muted">N/A</span>
          )}
        </Col>
      )}
      <Col xs={showAsset ? 8 : 9} lg={showAsset ? 9 : 10}>
        <pre className="m-0 expand-row" style={{ lineHeight: "1.5rem" }}>
          <Truncate
            onClick={toggleRow}
            className="collapsed"
            title={expandedJson}
          >
            {summary}
          </Truncate>
        </pre>
        <Collapse in={expanded && !collapsing} onExited={finishCollapsing}>
          <pre className="m-0">{otherLines.join("\n")}</pre>
        </Collapse>
      </Col>
    </Row>
  );
}
