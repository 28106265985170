import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MediaContentDialog from "components/MediaContentDialog";
import { GlyphButton } from "components/buttons";

export const MediaContentModal = () => {
  const history = useHistory();
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  const onSelect = (mediacontentIds: number[]) => {
    setShowSearchDialog(false);
    if (mediacontentIds.length === 1) {
      const id = mediacontentIds[0];
      history.push(`/enrich/mediacontent/editor/${id}`);
    }
  };

  return (
    <>
      <GlyphButton
        glyph="search"
        variant="primary"
        onClick={() => setShowSearchDialog(true)}
      >
        Find Content
      </GlyphButton>
      {showSearchDialog && (
        <MediaContentDialog
          title="Find content"
          show={showSearchDialog}
          onSelect={onSelect}
          selectText="Go"
          onClose={() => setShowSearchDialog(false)}
          initialState={{}}
        />
      )}
    </>
  );
};
