import React from "react";
import { Link } from "react-router-dom";

interface Props {
  channelId?: number;
}

export const LinearLink = ({ channelId }: Props) => {
  if (!channelId) {
    return null;
  }
  return (
    <Link to={`/epg/linear-metadata-db?channel=${channelId}&selectAll=0`}>
      LTS: {channelId}
    </Link>
  );
};
