import React from "react";
import { CLIENT_CONFIG } from "client/config";

interface props {
  crid: string;
}

export const MetadataCollectorCridLink = ({ crid }: props) => {
  return (
    <a
      href={`${CLIENT_CONFIG.METADATA_COLLECTOR_ROOT}/crid?id=${crid}`}
      target="_blank"
      rel="noreferrer"
      onClick={(event) => event.stopPropagation()}
    >
      {crid}
    </a>
  );
};
