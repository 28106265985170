import { Dict } from "@telia-company/tv.no-play-cms-common/types";
import React from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table-v7";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { useKeptResult } from "client/hooks/useKeptResult";
import { MomentParam } from "client/types/MomentParam";
import {
  getTitle,
  prettyPrintDateTime,
  useSearchParams,
} from "client/views/Enrich/Insight/utils";
import { KindBadge } from "components/badges";
import Table from "components/tables";
import { Pagination } from "components/tables/Pagination";

interface ContentReportResponse {
  reports: Report[];
  totalReports: number;
}

export interface Report {
  mediacontent_id: string;
  top_mediacontent_id: string;
  report_type: string;
  kind: string;
  mediacontent_created: string;
  created: string;
  updated: string;
  crid?: string;
  titles?: Dict<string>;
  top_titles?: Dict<string>;
  index?: string;
}

interface ReportRow extends Report {
  id?: string | number;
}

type ContentReportColumns = ReadonlyArray<Column<ReportRow>>;

const columns: ContentReportColumns = [
  {
    Header: "Title",
    accessor: (row) => (
      <Link
        style={{ textDecoration: "underline" }}
        to={`/enrich/mediacontent/editor/${row.mediacontent_id}`}
      >
        {getTitle(row.titles) || row.index || "NO TITLE"}
      </Link>
    ),
    width: 40,
  },
  {
    Header: "Top Title",
    accessor: (row) => (
      <Link
        style={{ textDecoration: "underline" }}
        to={`/enrich/mediacontent/editor/${row.top_mediacontent_id}`}
      >
        {getTitle(row.top_titles) || "NO TITLE"}
      </Link>
    ),
    width: 40,
  },
  {
    Header: "CRID",
    accessor: (row) => row.crid,
    width: 40,
  },
  {
    Header: "Report Type",
    accessor: (row) => row.report_type,
    width: 40,
  },
  {
    Header: "Kind",
    accessor: (row) => <KindBadge kind={row.kind} />,
    width: 40,
  },
  {
    Header: "MediaContent created",
    accessor: (row) => prettyPrintDateTime(row.mediacontent_created),
    width: 40,
  },
];

const addIdsToData = (reportList: ContentReportResponse): ReportRow[] => {
  return reportList.reports.map((row) => ({
    ...row,
    id: `${row.mediacontent_id}-${row.report_type}`,
  }));
};

export const ContentReportTable = () => {
  const params = useSearchParams({
    report_type: StringParam,
    kind: StringParam,
    page: NumberParam,
    size: NumberParam,
  });
  const [from] = useQueryParam("from", MomentParam);
  if (from) {
    params.append("from", from.toISOString());
  }
  const [to] = useQueryParam("to", MomentParam);
  if (to) {
    params.append("to", to.toISOString());
  }

  const { data } = useSWR<ContentReportResponse>(
    "insight/list?" + params.toString()
  );
  const contentReports = useKeptResult(data);
  if (!contentReports) {
    return <div>Loading...</div>;
  }
  const convertedContentReports = addIdsToData(contentReports);
  return (
    <>
      <Table columns={columns} data={convertedContentReports} flex={false} />
      <Pagination totalResults={contentReports.totalReports} />
    </>
  );
};
