const PRODUCTION_HOSTNAMES = ["play-cms.getaccess.no", "cms.teliaplay.no"];
const QA_HOSTNAMES = ["play-cms-qa.getaccess.no", "cms.qa.teliaplay.no"];

function getClientEnvironment(location: Location) {
  const { hostname, port } = location;

  if (PRODUCTION_HOSTNAMES.includes(hostname)) {
    return Environments.Prod;
  }

  if (QA_HOSTNAMES.includes(hostname)) {
    return Environments.QA;
  }

  if (port === "8000") {
    return Environments.DockerDev;
  }

  return Environments.Dev;
}

export const Environments = {
  Prod: "prod",
  QA: "qa",
  Dev: "dev",
  DockerDev: "docker-dev",
} as const;

export type Environment = (typeof Environments)[keyof typeof Environments];

const ENVIRONMENT_CONFIGS = {
  [Environments.Prod]: {
    NAME: Environments.Prod,
    API_ROOT: "",
    CDN_DOMAIN: "https://image.getvideo.cloud/",
    PLAY_DOMAIN: "https://teliaplay.no",
    ONEAPP_GRAPH_API: "https://graphql-telia.t6a.net/",
    METADATA_COLLECTOR_ROOT: "https://play-metadata-collector.pr.getas.net",
    BACKGROUND: "dark",
    AZURE_CLIENT_ID: "d3ceda22-7661-4e9f-8502-f3243d0e02c1",
  },
  [Environments.QA]: {
    NAME: Environments.QA,
    API_ROOT: "",
    CDN_DOMAIN: "https://image.qa.getvideo.cloud/",
    PLAY_DOMAIN: "https://qa.teliaplay.no",
    ONEAPP_GRAPH_API: "https://graphql-telia.t6a.net/",
    METADATA_COLLECTOR_ROOT: "https://play-metadata-collector.qa.getas.net",
    BACKGROUND: "primary",
    AZURE_CLIENT_ID: "aad39839-839f-4a1e-a79b-587e21a1ad8e",
  },
  [Environments.Dev]: {
    NAME: Environments.Dev,
    // Use the dev server at localhost:4000 by default for development,
    // but allow overriding the API root through process.env.API_ROOT
    // when running the dev server on a non-default port.
    API_ROOT: process.env.API_ROOT || "http://localhost:4000",
    CDN_DOMAIN: "https://image.qa.getvideo.cloud/",
    PLAY_DOMAIN: "https://teliaplay.no",
    ONEAPP_GRAPH_API: "https://graphql-telia.t6a.net/",
    METADATA_COLLECTOR_ROOT: "https://play-metadata-collector.qa.getas.net",
    BACKGROUND: "warning",
    AZURE_CLIENT_ID: "aad39839-839f-4a1e-a79b-587e21a1ad8e",
  },
  [Environments.DockerDev]: {
    NAME: Environments.DockerDev,
    API_ROOT: "http://localhost:8000",
    CDN_DOMAIN: "https://image.qa.getvideo.cloud/",
    PLAY_DOMAIN: "https://teliaplay.no",
    ONEAPP_GRAPH_API: "https://graphql-telia.t6a.net/",
    METADATA_COLLECTOR_ROOT: "https://play-metadata-collector.qa.getas.net",
    BACKGROUND: "warning",
    AZURE_CLIENT_ID: "aad39839-839f-4a1e-a79b-587e21a1ad8e",
  },
};

export const CLIENT_CONFIG =
  ENVIRONMENT_CONFIGS[getClientEnvironment(window.location)];
