import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { ChannelsContext } from "client/App";
import { Channels, IChannels } from "client/types";

interface ChannelWithHitCount extends IChannels {
  count?: number;
}

const duplicateSafeLabelKeyWithCount = (options: ChannelWithHitCount[]) => {
  return (value: ChannelWithHitCount) => {
    let label = value.value;
    // see if we have other objects with the same value,
    // and if so, add the ID to the name
    if (options.some((ch) => ch.value == value.value && ch.id != value.id)) {
      label = `${label} [${value.id}]`;
    }
    if (value.count) {
      label = `${label}: ${value.count}`;
    }
    return label;
  };
};

interface Props {
  onChange: (selected: number[]) => void;
  channelValue: number[];
  readOnly: boolean;
  channelCounts?: { [channelId: string]: number };
}

function useChannelOptions(channelCounts: { [p: string]: number } | undefined) {
  const channels = React.useContext(ChannelsContext);
  const channelsThatHaveHitCounts: ChannelWithHitCount[] = [];
  const channelsThatDoNotHaveHitCounts: Channels = [];
  channels.forEach((channel) => {
    if (!channelCounts) {
      channelsThatDoNotHaveHitCounts.push(channel);
      return;
    }
    const channelCount = channelCounts[channel.id];
    if (!channelCount) {
      channelsThatDoNotHaveHitCounts.push(channel);
      return;
    }
    channelsThatHaveHitCounts.push({ ...channel, count: channelCount });
  });
  return [...channelsThatHaveHitCounts, ...channelsThatDoNotHaveHitCounts];
}

export const FilterChannelPicker = ({
  onChange,
  channelValue,
  readOnly,
  channelCounts,
}: Props) => {
  const channels = useChannelOptions(channelCounts);
  // if channels hasn't been fetched yet, or an id is invalid,
  // find will return undefined and needs to be filtered
  const channelObjs = channelValue
    .map((val) => channels.find((channel) => channel.id === val.toString()))
    .filter((ch): ch is ChannelWithHitCount => !!ch);
  return (
    <>
      <Form.Label>Filter by channel</Form.Label>
      <Typeahead
        id="collection-channel-picker"
        multiple
        selected={channelObjs}
        options={channels}
        labelKey={duplicateSafeLabelKeyWithCount(channels)}
        placeholder="Channel filter"
        onChange={(selectedChannels) => {
          onChange(selectedChannels.map((ch) => Number(ch.id)));
        }}
        disabled={readOnly}
      />
    </>
  );
};
