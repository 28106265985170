import _ from "lodash";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";

export function useOpenApiSchema(
  schemaName,
  { defaultValue, transform = _.identity } = {}
) {
  const url = "ams/openapi/components/schemas";
  const { data: schemas, error, isValidating } = useSWRImmutable(url);
  const isLoading = isValidating || (!schemas && !error);

  const schema = useMemo(() => {
    if (schemas) {
      return transform(schemas[schemaName]);
    } else {
      return defaultValue;
    }
  }, [schemas, schemaName, defaultValue, transform]);

  return { schema, error, isLoading };
}

export function useOpenApiEnum(schemaName) {
  const enumOptions = useMemo(
    () => ({
      defaultValue: [],
      transform: (schema) =>
        schema.enum.map((value) => ({ value, label: value })),
    }),
    []
  );
  return useOpenApiSchema(schemaName, enumOptions);
}
