import {
  formatDuration,
  safeGet,
} from "@telia-company/tv.no-play-cms-common/api/util";
import autobind from "autobind-decorator";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Button, Form } from "react-bootstrap";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactTable, { ReactTableDefaults } from "react-table";
import "react-table/react-table.css";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParam,
} from "use-query-params";
import * as XLSX from "xlsx";
import { CLIENT_CONFIG } from "client/config";
import { CmsPure, numericComparator, request } from "client/utils";
import { JSONText } from "client/utils/Helpers";
import { caseInsensitiveSubstringMatch } from "client/utils/TableCommon";
import DateTimeControl from "components/controls/datetime/DateTimeControl";
import AssetOperationTrigger from "./AssetOperationTrigger";

ReactTableDefaults.expanderDefaults = {
  ...ReactTableDefaults.expanderDefaults,
  resizable: true,
  filterable: true,
};

let requestInProgress = null;

const formatLicenseTime = (dt) => {
  if (!dt) return "";
  dt = moment(dt);
  if (dt.isBetween(moment().subtract(1, "month"), moment().add(1, "month"))) {
    return dt.format("YYYY-MM-DD HH:mm");
  } else {
    return dt.format("YYYY-MM-DD");
  }
};

const getPlacement = (viewIndex, pageSize) =>
  viewIndex <= pageSize / 2 ? "bottom" : "top";

const noProvider = "NO PROVIDER";

const getProviderTextStyle = (styles, { data }) => {
  return {
    ...styles,
    textDecoration: !data.active && data.label != noProvider && "line-through",
  };
};

class AssetMonitor extends CmsPure {
  constructor(props) {
    super(props);
    const ignoreEmpty = props.ignoreEmpty || "true";
    this.state = {
      results: [],
      from: props.from
        ? moment(props.from)
        : moment()
            .subtract(1, "days")
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0),
      to: props.to ? moment(props.to) : null,
      limit: props.limit || 1000,
      ignoreEmpty: ignoreEmpty == "true",
      activeOnly: props.activeOnly == "true",
      providers: props.providers || [],
      allProviders: [],
      fetching: false,
      method: props.method || "MergeUpdates",
      methodOptions: [
        { label: "Asset Updates", value: "AssetUpdate" },
        { label: "Event Updates", value: "EventUpdate" },
        { label: "Merge Dates", value: "MergeUpdates" },
      ],
      triggerModalIsVisible: false,
      triggerAssetId: null,
    };
  }

  componentDidMount() {
    // TODO: Take from context, remove all session code
    request("providers").then(
      (providersList) => {
        const allProviders = providersList.map((provider) => ({
          value: provider.id,
          label: provider.value,
          active: provider.active,
        }));

        allProviders.push({ value: 0, label: noProvider });

        this.setState({ allProviders });

        if (window.location.search.length > 1) {
          this.initialDataFetch();
        }
      },
      (error) => toast.error(`Failed to load providers: ${error}`)
    );
  }

  /**
   * Create the filter query by reading relevant states.
   * @returns filters filter object
   */
  createFilter() {
    const filters = _.pick(this.state, [
      "from",
      "to",
      "providers",
      "method",
      "limit",
      "activeOnly",
      "ignoreEmpty",
    ]);
    return filters;
  }

  /**
   * Fetches data based on the cached filter. Previously this function
   * got stored filter settings from the session, but since we changed
   * to storing the filter settings as query parameters in the url, it
   * was renamed and serve to do the initial data fetch.
   */
  initialDataFetch() {
    let filter = this.createFilter();
    filter.providers = this.state.providers;
    this.getData(filter);
  }

  /**
   * Creates new filter based on state parameters and fetches that data.
   */
  @autobind
  onFetchData() {
    const filter = this.createFilter();
    this.props.onChangeTo(this.state.to && this.state.to.toISOString());
    this.props.onChangeFrom(this.state.from && this.state.from.toISOString());
    this.props.onChangeProviders(this.state.providers);
    //   this.state.providers.map(providerObj => providerObj.value)
    // );
    this.props.onChangeMethod(this.state.method);
    this.props.onChangeLimit(this.state.limit);
    this.props.onChangeActiveOnly(this.state.activeOnly ? "true" : undefined);
    this.props.onChangeIgnoreEmpty(this.state.ignoreEmpty ? "true" : "false");
    this.getData(filter);
  }

  /**
   * Fetch data from getAssetsForMonitor with filter parameters
   * set in state: from, to, providers.
   * Updates state.results with returned results and
   * state.recordsCount with results' length.
   */
  getData(filters) {
    this.setState({ fetching: true });
    const filterData = {
      updatedFrom: filters.from
        ? moment(filters.from).toISOString()
        : undefined,
      updatedTo: filters.to ? moment(filters.to).toISOString() : undefined,
      activeOnly: filters.activeOnly,
      ignoreEmpty: filters.ignoreEmpty,
      providers: filters.providers,
      method: filters.method,
      limit: filters.limit,
    };

    requestInProgress = filterData;
    console.log("filterBy", filterData);

    request(`assets/monitor?${queryString.stringify(filterData)}`).then(
      (assets) => {
        if (!_.isEqual(filterData, requestInProgress)) {
          return;
        }
        const results = assets.map((a) => a._source);
        this.resetPage();
        this.setState({ results, fetching: false });
        this.updateRecordsCount(results.length);
        if (results.length === this.state.limit) {
          toast(`Max limit of ${this.state.limit} results reached`, {
            type: toast.TYPE.INFO,
            autoClose: 1200,
          });
        }
        ReactTooltip.rebuild();
      },
      (error) => {
        console.log(error);
        toast.error(`Failed to load assets: ${error.message}`);
        this.setState({ fetching: false });
      }
    );
  }

  /**
   * Updates the records count with the number of records
   * satisfying the table (client-side) filters.
   * @param {Number} forcedCount manual records count.
   */
  @autobind
  updateRecordsCount(forcedCount = null) {
    const recordsCount = this.reactTable
      ? this.reactTable.getResolvedState().sortedData.length
      : forcedCount || this.state.results.length;
    this.setState({ recordsCount });
  }

  @autobind
  setReactTable(r) {
    this.reactTable = r;
  }

  @autobind
  resetPage() {
    if (this.reactTable) {
      this.reactTable.onPageChange(0);
    }
  }

  /**
   * Update selected providers
   * @param {{value: string, label: string}[]} providers selected providers array.
   */
  @autobind
  onProviderChange(providers) {
    this.setState({ providers: (providers || []).map((p) => p.value) });
  }

  /**
   * Update filtering method
   * @param {{value: string, label: string}[]} method selected fetch order.
   */
  @autobind
  onMethodChange(method) {
    this.setState({ method: method.value });
  }

  @autobind
  onLimitChange(limit) {
    this.setState({ limit: Number(limit.target.value) });
  }

  @autobind
  onActiveOnlyChange(e) {
    this.setState({ activeOnly: e.target.checked });
  }

  @autobind
  onIgnoreEmptyChange(e) {
    this.setState({ ignoreEmpty: e.target.checked });
  }

  /**
   * Update selected from date.
   * @param {Date} date moment date object.
   */
  @autobind
  onFromChange(date) {
    this.setState({ from: date });
  }

  /**
   * Update selected to date.
   * @param {Date} date moment date object.
   */
  @autobind
  onToChange(date) {
    this.setState({ to: date });
  }

  @autobind
  onCloseTriggerModal() {
    this.setState({ triggerModalIsVisible: false, triggerAssetId: null });
  }

  @autobind
  onOpenTriggerModal(triggerAssetId) {
    this.setState({ triggerModalIsVisible: true, triggerAssetId });
  }

  @autobind
  onFilterChange() {
    this.updateRecordsCount();
    ReactTooltip.rebuild();
  }

  render() {
    return (
      <>
        <AssetOperationTrigger
          show={this.state.triggerModalIsVisible}
          onSubmit={this.onCloseTriggerModal}
          assetid={this.state.triggerAssetId}
          onClose={this.onCloseTriggerModal}
        />
        <AssetMonitorRender
          onProviderChange={this.onProviderChange}
          providers={this.state.providers.map((id) =>
            this.state.allProviders.find((obj) => obj.value == id)
          )}
          allProviders={this.state.allProviders}
          from={this.state.from}
          onFromChange={this.onFromChange}
          to={this.state.to}
          onToChange={this.onToChange}
          onMethodChange={this.onMethodChange}
          method={this.state.methodOptions.find(
            (option) => option.value === this.state.method
          )}
          methodOptions={this.state.methodOptions}
          limit={this.state.limit}
          onLimitChange={this.onLimitChange}
          ignoreEmpty={this.state.ignoreEmpty}
          onIgnoreEmptyChange={this.onIgnoreEmptyChange}
          activeOnly={this.state.activeOnly}
          onActiveOnlyChange={this.onActiveOnlyChange}
          recordsCount={this.state.recordsCount}
          results={this.state.results}
          fetching={this.state.fetching}
          onFetchData={this.onFetchData}
          onFilterChange={this.onFilterChange}
          setReactTable={this.setReactTable}
        />
      </>
    );
  }
}

class AssetMonitorRender extends CmsPure {
  constructor(props) {
    super(props);

    /**
     * Takes an array of paths and returns a group of <a> objects
     * pointing to the actual URLs of the images.
     * @param {{value: string[]}} imagesRow array of image paths.
     * @param {Number} width image width.
     * @param {Number} height image height.
     */
    const imageLinks = (imagesArray = [], width, height, placement) => {
      const sizeParams = queryString.stringify({ h: height, w: width });
      const style = { textDecoration: "underline" };
      return (
        <span>
          {imagesArray.map((image, index) => (
            <span key={index}>
              <a
                data-tip={`<img src="${CLIENT_CONFIG.CDN_DOMAIN}${image}?${sizeParams}"/>`}
                data-html={true}
                data-place={placement}
                href={`${CLIENT_CONFIG.CDN_DOMAIN}${image}?h=original&w=original`}
                target="_blank"
                rel="noopener noreferrer"
                style={style}
              >
                {image}
              </a>
              <br />
            </span>
          ))}
        </span>
      );
    };

    const customBooleanFilter = ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        value={filter ? filter.value : ""}
      >
        <option value="all"> ALL </option>
        <option value="TRUE"> TRUE </option>
        <option value="FALSE"> FALSE </option>
      </select>
    );

    const customTypeFilter = ({ filter, onChange }) => (
      <select
        onChange={(event) => onChange(event.target.value)}
        value={filter ? filter.value : ""}
      >
        <option value="all"> ALL </option>
        <option value="VOD"> VOD </option>
        <option value="TVOD"> TVOD </option>
      </select>
    );

    const customBooleanFilterMethod = (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      return row[filter.id] === filter.value;
    };

    const posterWidth = 320,
      coverHeight = 320,
      coverWidth = undefined,
      posterHeight = undefined;

    this.columns = [
      {
        id: "LastUpdated",
        Header: "Last updated",
        accessor: "lastUpdated",
        Cell: ({ original, value }) => {
          const { lastAssetUpdated, lastEventUpdated } = original;
          return (
            <Collapsible
              trigger={value}
              triggerClassName="assetMonitorLastUpdatedCollapsible__trigger"
              triggerOpenedClassName="assetMonitorLastUpdatedCollapsible__trigger"
            >
              <div>lastAssetUpdate: {lastAssetUpdated}</div>
              <div
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => this.onOpenTriggerModal(original.PropId)}
              >
                lastEventUpdate: {lastEventUpdated}
              </div>
            </Collapsible>
          );
        },
        minWidth: 100,
      },
      {
        id: "provider",
        Header: "Provider",
        accessor: ({ provider }) =>
          safeGet(
            this.props.allProviders,
            (ap) => {
              const providerObj = ap.find((p) => p.value == provider);
              return providerObj.active ? (
                providerObj.label
              ) : (
                <div style={{ textDecoration: "line-through" }}>
                  {providerObj.label}
                </div>
              );
            },
            "?"
          ),
        minWidth: 50,
      },
      {
        Header: "Catalog",
        accessor: "catalogId",
        minWidth: 100,
      },
      {
        Header: "Content Id",
        id: "ContentId",
        accessor: "contentId",
        Cell: ({ value, original }) => (
          <Link
            data-tip={`<div style="white-space: pre-wrap; max-width: 65vw; font-size: 16px;">${JSONText(
              original.storyline
            )}</div>`}
            data-html={true}
            target="_blank"
            to={`/enrich/mediacontent/editor/${value}`}
            style={{ textDecoration: "underline" }}
          >
            {value}
          </Link>
        ),
        minWidth: 40,
      },
      {
        id: "AgeRating",
        Header: "AgeRating",
        accessor: "ageLimit",
        minWidth: 35,
      },
      {
        Header: "TopTitle",
        accessor: "topTitle",
        minWidth: 150,
      },
      {
        Header: "Title",
        accessor: "title",
        minWidth: 150,
      },
      {
        Header: "Kind",
        accessor: "kind",
        minWidth: 35,
      },
      {
        Header: "Season",
        id: "season",
        accessor: "season",
        minWidth: 35,
        filterMethod: (filter, row) => row.season == filter.value,
        sortMethod: numericComparator,
      },
      {
        Header: "Episode",
        id: "episode",
        accessor: "episode",
        minWidth: 35,
        filterMethod: (filter, row) => row.episode == filter.value,
        sortMethod: numericComparator,
      },
      {
        Header: "AssetId",
        accessor: "assetId",
        Cell: ({ value }) => (
          <Link
            to={`/enrich/asset/${value}`}
            style={{ textDecoration: "underline" }}
          >
            {value}
          </Link>
        ),
        minWidth: 40,
      },
      {
        Header: "type",
        accessor: "type",
        minWidth: 40,
        filterMethod: customBooleanFilterMethod,
        Filter: customTypeFilter,
      },
      {
        id: "mediaStream",
        Header: "MediaStream",
        accessor: ({ mediaStream }) => (mediaStream ? "TRUE" : "FALSE"),
        minWidth: 40,
        filterMethod: customBooleanFilterMethod,
        Filter: customBooleanFilter,
      },
      {
        id: "subtitles",
        Header: "Subtitles",
        accessor: ({ subtitles }) => (subtitles ? "TRUE" : "FALSE"),
        minWidth: 40,
        filterMethod: customBooleanFilterMethod,
        Filter: customBooleanFilter,
      },
      {
        id: "RunningLength",
        Header: "Running length",
        accessor: ({ runningLength }) => {
          let d = moment.duration(runningLength);
          return d.asSeconds();
        },
        Cell: ({ value }) => {
          let d = moment.duration({ seconds: value });
          return formatDuration(d);
        },
        minWidth: 45,
      },
      {
        id: "LicenseStart",
        Header: "LicenseStart",
        accessor: ({ licenseStart }) => formatLicenseTime(licenseStart),
        minWidth: 65,
      },
      {
        id: "LicenseEnd",
        Header: "LicenseEnd",
        accessor: ({ licenseEnd }) => formatLicenseTime(licenseEnd),
        minWidth: 65,
      },
      {
        Header: "Poster Images",
        accessor: "posterImages",
        Cell: ({ value, viewIndex, pageSize }) =>
          imageLinks(
            value,
            posterWidth,
            posterHeight,
            getPlacement(viewIndex, pageSize)
          ),
        minWidth: 100,
      },
      {
        Header: "Cover Images",
        accessor: "coverImages",
        Cell: ({ value, viewIndex, pageSize }) =>
          imageLinks(
            value,
            coverWidth,
            coverHeight,
            getPlacement(viewIndex, pageSize)
          ),
        minWidth: 100,
      },
      {
        id: "TVODPrice",
        Header: "Price (B/R)",
        accessor: ({ price }) =>
          price
            ? `${price.buy ? `${price.buy.value}/` : ""}${
                price.rent ? `${price.rent.value}` : ""
              }`
            : "",
        minWidth: 40,
      },
    ];
  }

  /**
   * Initialize an excel file export of filtered data.
   * - Filename pattern: "Export YYYY-MM-DD.xlsx"
   */
  @autobind
  onDownloadFilteredData() {
    const data = this.getDownloadData(
      this.reactTable.getResolvedState().sortedData
    );

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(data);

    ws["!cols"] = [
      { wch: 20 }, // Updated,
      { wch: 20 }, // lastAssetUpdate,
      { wch: 20 }, // lastEventUpdate,
      { wch: 20 }, // Provider,
      { wch: 25 }, // Catalog,
      { wch: 10 }, // ContentId,
      { wch: 55 }, // TopTitle,
      { wch: 55 }, // Title,
      { wch: 10 }, // AgeRating,
      { wch: 10 }, // Kind,
      { wch: 10 }, // SeasonIndex,
      { wch: 10 }, // EpisodeIndex,
      { wch: 10 }, // AssetId,
      { wch: 15 }, // hasMediaStream,
      { wch: 15 }, // hasSubtitles
      { wch: 15 }, // runningTime,
      { wch: 20 }, // LicenseStart,
      { wch: 20 }, // LicenseEnd,
      { wch: 50 }, // Posters,
      { wch: 50 }, // Covers,
    ];

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const fileName = `${moment().format("YYYY-MM-DD")}.xlsx`;

    XLSX.writeFile(wb, `Export ${fileName}`, { compression: true });
  }

  /**
   * Extracts relevant properties from the reactTable sortedData
   * object to be included in the export.
   * @param {{}} ReactTableData reactTable sortedData object
   * @returns {{Provider, AssetId, Title, AgeRating, Kind, SeasonIndex, EpisodeIndex, PropId, ContentId, hasMediaStream, Updated, LicenseEnd, Posters, Covers, hasSubtitles}}
   */
  getDownloadData(ReactTableData) {
    return ReactTableData.map((row) => {
      const {
        LastUpdated,
        provider,
        catalogId,
        ContentId,
        topTitle,
        title,
        AgeRating,
        kind,
        season,
        episode,
        assetId,
        mediaStream,
        subtitles,
        RunningLength,
        LicenseStart,
        LicenseEnd,
        posterImages,
        coverImages,
      } = row;
      console.log(row);
      return {
        LastUpdated,
        lastAssetUpdate: row._original.lastAssetUpdated,
        lastEventUpdate: row._original.lastEventUpdated,
        Provider: provider,
        Catalog: catalogId,
        ContentId,
        TopTitle: topTitle,
        Title: title,
        AgeRating,
        Kind: kind,
        Season: season,
        Episode: episode,
        AssetId: assetId,
        hasMediaStream: mediaStream,
        hasSubtitles: subtitles,
        "Running Length": RunningLength,
        LicenseStart,
        LicenseEnd,
        Posters: posterImages && posterImages[0],
        Covers: coverImages && coverImages[0],
      };
    });
  }

  @autobind
  getRecordsCountString() {
    const shown = this.props.recordsCount;
    const total = this.props.results.length;
    const n = shown !== undefined ? shown : total;
    const rows = n != 1 ? "ROWS" : "ROW";
    return `${n} ${rows}`;
  }

  render() {
    // Set styles to fix z-index issue for react-select
    // https://github.com/JedWatson/react-select/issues/1537
    const selectStyles = {
      menu: (styles) => ({ ...styles, zIndex: 999 }),
      option: getProviderTextStyle,
      multiValueLabel: getProviderTextStyle,
    };
    return (
      <div className="container-fluid">
        {
          // Add the out-commented deprecation notice below once we're ready
          // to ask people to switch to the AMS.  See play-cms#146.
          // <DeprecationNotice />
        }
        <div className="flex-row justify-content-between my-3 align-items-center">
          <Select
            closeMenuOnSelect={false}
            className="flex-fill me-1"
            styles={selectStyles}
            isMulti
            onChange={this.props.onProviderChange}
            options={this.props.allProviders}
            placeholder="Specify providers?"
            hideSelectedOptions={true}
            value={this.props.providers}
          />
          <DateTimeControl
            className="flex-fill mx-1"
            placeholder="Start"
            closeOnSelect
            value={this.props.from}
            onChange={this.props.onFromChange}
          />
          <DateTimeControl
            className="flex-fill mx-1"
            placeholder="End"
            acceptEmpty
            closeOnSelect
            value={this.props.to}
            onChange={this.props.onToChange}
          />
          <Select
            className="flex-fill mx-1"
            styles={selectStyles}
            onChange={this.props.onMethodChange}
            options={this.props.methodOptions}
            placeholder="Order by?"
            value={this.props.method}
          />
          <Form.Control
            className="mx-1 w-auto"
            placeholder="Limit"
            type="number"
            onChange={this.props.onLimitChange}
            value={this.props.limit}
          />
          <div>
            <div>
              <Form.Check
                type="checkbox"
                checked={this.props.ignoreEmpty}
                onChange={this.props.onIgnoreEmptyChange}
                label="No empty assets"
                id="toggle-empty-assets"
              />
            </div>
            <div>
              <Form.Check
                type="checkbox"
                checked={this.props.activeOnly}
                onChange={this.props.onActiveOnlyChange}
                label="Active only"
                id="toggle-active-only"
              />
            </div>
          </div>
          <Button className="mx-1" onClick={this.props.onFetchData}>
            FETCH
          </Button>
          <Button
            className="ms-1"
            disabled={this.props.recordsCount === 0}
            onClick={this.onDownloadFilteredData}
          >
            EXPORT {this.getRecordsCountString()}
          </Button>
        </div>
        <ReactTooltip className="assetMonitorTooltip" html={true} />
        <ReactTable
          className="-striped"
          ref={(r) => {
            this.reactTable = r;
            this.props.setReactTable(r);
          }}
          data={this.props.results}
          columns={this.columns}
          defaultSorted={[
            {
              id: "LastUpdated",
              desc: true,
            },
          ]}
          minRows={1}
          filterable={true}
          defaultFilterMethod={caseInsensitiveSubstringMatch}
          defaultPageSize={50}
          onFilteredChange={this.props.onFilterChange}
          //PaginationComponent={ReactTablePagination}
          loading={this.props.fetching}
          noDataText={this.props.fetching ? null : "No data available"}
        />
      </div>
    );
  }
}

const AssetMonitorWithUseQuery = () => {
  const [from, setFrom] = useQueryParam("from", StringParam);
  const [to, setTo] = useQueryParam("to", StringParam);
  const [providers, setProviders] = useQueryParam(
    "providers",
    NumericArrayParam
  );
  const [method, setMethod] = useQueryParam("method", StringParam);
  const [limit, setLimit] = useQueryParam("limit", NumberParam);
  const [activeOnly, setActiveOnly] = useQueryParam("activeOnly", StringParam);
  const [ignoreEmpty, setIgnoreEmpty] = useQueryParam(
    "ignoreEmpty",
    StringParam
  );
  return (
    <AssetMonitor
      from={from}
      onChangeFrom={setFrom}
      to={to}
      onChangeTo={setTo}
      providers={providers}
      onChangeProviders={setProviders}
      method={method}
      onChangeMethod={setMethod}
      limit={limit}
      onChangeLimit={setLimit}
      activeOnly={activeOnly}
      onChangeActiveOnly={setActiveOnly}
      ignoreEmpty={ignoreEmpty}
      onChangeIgnoreEmpty={setIgnoreEmpty}
    />
  );
};

export default AssetMonitorWithUseQuery;
