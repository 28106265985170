import React from "react";
import { getCloudImagePath } from "./";

const CloudImage = ({ path, width, height, ...rest }) => {
  if (path) {
    const src = getCloudImagePath(path, width, height);
    return <img src={src} style={{ width, height }} {...rest} />;
  }

  return <div style={{ width, height }} {...rest} />;
};

export default CloudImage;
