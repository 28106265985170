import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from "client/utils";
import { GLYPHS } from "components/buttons/glyphs";

const CONTENT_WARNING_STATUS_VERSION = 2;

interface Props {
  topMediaContentId: string;
  assetId: string;
  CWSAStatus?: string;
  CWSMCStatus?: string;
  warningType: string;
  mutate: () => void;
}

export const ManageWarningStatus = ({
  topMediaContentId,
  assetId,
  CWSAStatus,
  CWSMCStatus,
  warningType,
  mutate,
}: Props) => {
  const markOneAction = (statusName: string) =>
    markOne(statusName, assetId, warningType, mutate);
  const unmarkOneAction = (statusName: string) =>
    unmarkOne(statusName, assetId, warningType, mutate);
  const markAllAction = (statusName: string) =>
    markAll(statusName, topMediaContentId, warningType, mutate);
  const unmarkAllAction = (statusName: string) =>
    unmarkAll(statusName, topMediaContentId, warningType, mutate);

  const optionFactory = (
    statusName: string,
    quantity: string,
    glyph: string
  ) => {
    const marked =
      quantity === "one"
        ? CWSAStatus === statusName
        : CWSMCStatus === statusName;
    const markAction = quantity === "one" ? markOneAction : markAllAction;
    const unmarkAction = quantity === "one" ? unmarkOneAction : unmarkAllAction;
    return (
      <MarkUnmarkOption
        statusName={statusName}
        marked={marked}
        glyph={glyph}
        quantity={quantity}
        markAction={markAction}
        unmarkAction={unmarkAction}
      />
    );
  };

  const reportOneOption = optionFactory("REPORTED", "one", GLYPHS.FORWARD);
  const acceptOneOption = optionFactory("ACCEPTED", "one", GLYPHS.CHECK);
  const reportAllOption = optionFactory("REPORTED", "all", GLYPHS.FORWARD);
  const acceptAllOption = optionFactory("ACCEPTED", "all", GLYPHS.CHECK);
  const spamOption = optionFactory("SPAM", "all", GLYPHS.SKULL);

  return (
    <>
      <div className="text-nowrap">
        <span style={{ display: "inline-block", width: "30px" }}>One</span>
        {reportOneOption}
        {acceptOneOption}
      </div>
      <div className="text-nowrap">
        <span style={{ display: "inline-block", width: "30px" }}>All</span>
        {reportAllOption}
        {acceptAllOption}
        {spamOption}
        {/* Ugly hack to get some space between the manage icons and the edge of
          the viewport, such that the tooltip doesn't need to line wrap. */}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </>
  );
};

interface MarkUnmarkOptionProps {
  statusName: string;
  marked: boolean;
  glyph: string;
  quantity: string;
  markAction: (statusName: string) => void;
  unmarkAction: (statusName: string) => void;
}

const MarkUnmarkOption = ({
  statusName,
  marked,
  glyph,
  quantity,
  markAction,
  unmarkAction,
}: MarkUnmarkOptionProps) => {
  return marked ? (
    <Option
      icon={glyph}
      highlight
      description={`Unmark ${quantity} as ${statusName.toLowerCase()}`}
      action={() => unmarkAction(statusName)}
    />
  ) : (
    <Option
      icon={glyph}
      description={`Mark ${quantity} as ${statusName.toLowerCase()}`}
      action={() => markAction(statusName)}
    />
  );
};

interface OptionProps {
  icon: string;
  description: string;
  action: () => void;
  highlight?: boolean;
}

const Option = ({ icon, description, action, highlight }: OptionProps) => {
  const iconStyle: React.CSSProperties = { marginRight: "8px" };
  if (highlight) {
    iconStyle.color = "#c86af8";
  }
  return (
    <>
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          action();
        }}
      >
        <span data-tip={description}>
          <i className={icon} style={iconStyle} aria-label={description}></i>
        </span>
      </Link>
    </>
  );
};

const markOne = async (
  statusName: string,
  assetId: string,
  warningType: string,
  mutate: () => void
) => {
  const { id } = await request("warningstatus/", {
    method: "POST",
    body: JSON.stringify({
      asset_id: assetId,
      warning_type: warningType,
      status: statusName,
      version: CONTENT_WARNING_STATUS_VERSION,
    }),
  });
  toast(
    <UndoToastContent
      message={`Marked asset ${assetId} as ${statusName.toLowerCase()}`}
      id={id}
      mutate={mutate}
    />
  );
  mutate();
};

const markAll = async (
  statusName: string,
  mediaContentId: string,
  warningType: string,
  mutate: () => void
) => {
  const { id } = await request("warningstatus/", {
    method: "POST",
    body: JSON.stringify({
      mediacontent_id: mediaContentId,
      warning_type: warningType,
      status: statusName,
      version: CONTENT_WARNING_STATUS_VERSION,
    }),
  });
  toast(
    <UndoToastContent
      message={`Marked media content ${mediaContentId} as ${statusName.toLowerCase()}`}
      id={id}
      mutate={mutate}
    />
  );
  mutate();
};

const unmarkOne = async (
  statusName: string,
  assetId: string,
  warningType: string,
  mutate: () => void
) => {
  await request(`warningstatus/by-asset-id/${assetId}/${warningType}`, {
    method: "DELETE",
  });
  toast(`Unmarked asset ${assetId} as ${statusName.toLowerCase()}`);
  mutate();
};

const unmarkAll = async (
  statusName: string,
  mediaContentId: string,
  warningType: string,
  mutate: () => void
) => {
  await request(
    `warningstatus/by-mediacontent-id/${mediaContentId}/${warningType}`,
    { method: "DELETE" }
  );
  toast(
    `Unmarked media media content ${mediaContentId} as ${statusName.toLowerCase()}`
  );
  mutate();
};

interface UndoToastContentProps {
  message: string;
  id: number;
  mutate: () => void;
}

const UndoToastContent = ({ message, id, mutate }: UndoToastContentProps) => {
  return (
    <div>
      <Row>
        <Col xs={8}>{message}</Col>
        <Col xs={4}>
          <div className="d-grid gap-2">
            <Button
              onClick={async () => {
                await request(`warningstatus/${id}`, { method: "DELETE" });
                mutate();
              }}
            >
              Undo
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
