import moment from "moment";
import React, { useState, useCallback, useEffect } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useRequest } from "client/hooks";
import { request } from "client/utils";
import AssetImageGrabber from "components/AssetImageGrabber";
import { useDispatch } from "./context";

const ImageGrabberPane = ({ id, isLoading, setActivePane }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => setImage(undefined), [id]);

  const uploadFrame = useCallback(
    async (type) => {
      if (image && !uploading) {
        setUploading(true);
        const url = `data:image/jpeg;base64,${image}`;
        const blob = await (await fetch(url)).blob();
        const body = new FormData();
        body.append("file", blob);

        request("images/upload-metadata-image", { method: "POST", body })
          .then(({ location }) => {
            dispatch({
              type: "mc.image.add",
              payload: {
                path: location,
                imageType: type,
                switchPane: true,
              },
            });
            setActivePane(type);
          })
          .finally(() => setUploading(false));
      }
    },
    [id, image, uploading, setUploading, setActivePane]
  );

  const uploadCover = useCallback(() => uploadFrame("cover"), [uploadFrame]);
  const uploadPoster = useCallback(() => uploadFrame("poster"), [uploadFrame]);
  const formatTimestamp = useCallback(
    (t) => moment(t).format("YYYY-MM-DD HH:mm"),
    []
  );

  useRequest(`mediacontent/image-grabber/${id}`, {
    onSuccess(assets) {
      const options = assets.map((item, index) => {
        const {
          channel_id,
          provider_name,
          channel_name,
          catchup_end,
          subscription_end,
          buy_end,
          rent_end,
        } = item;
        const expiry = catchup_end || subscription_end || buy_end || rent_end;
        const type = channel_id
          ? "Catchup"
          : subscription_end
            ? "SVOD"
            : "TVOD";
        const source = provider_name || channel_name;
        const formattedExpirationDate = `Expires ${formatTimestamp(expiry)}`;
        const label = `${type}: ${source} | ${formattedExpirationDate}`;

        return {
          label,
          value: index,
          data: item,
        };
      });

      setOptions(options);

      if (options.length === 1) {
        setSelectedOption(options[0]);
      }
    },
  });

  if (options.length === 0 && !isLoading) {
    return <div>No assets available for capture</div>;
  }

  return (
    <div>
      {options.length > 1 && (
        <Select
          value={selectedOption}
          options={options}
          onChange={setSelectedOption}
          className="mb-2"
        />
      )}
      {selectedOption && (
        <AssetImageGrabber
          id={selectedOption.data.id}
          onChange={({ image }) => setImage(image)}
        />
      )}
      <div className="d-flex justify-content-end mt-2">
        <Button
          variant="primary"
          onClick={uploadCover}
          disabled={image === undefined || uploading}
          className="me-2"
        >
          Upload as cover
        </Button>
        <Button
          variant="primary"
          onClick={uploadPoster}
          disabled={image === undefined || uploading}
        >
          Upload as poster
        </Button>
      </div>
    </div>
  );
};

export default ImageGrabberPane;
