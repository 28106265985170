import React, { useEffect, useState } from "react";
import { Card, Form, Nav, Tab } from "react-bootstrap";
import { DynamicCollectionToggle } from "client/views/CollectionEditor/DynamicCollectionToggle";
import StatusComponent from "components/Promo/StatusComponent";
import { ToggleControlWithLabels } from "components/controls/ToggleControl";
import { CollectionContentComponent } from "./CollectionContentComponent";
import { CollectionGraphicsComponent } from "./CollectionGraphicsComponent";
import {
  Collection,
  CollectionContentType,
  CollectionOnChangeHandlers,
} from "./types";
import { CLIENT_TYPE_OPTIONS, getClientType } from "./utils";

const getClientTypeOptions = (dynamic: boolean) => {
  if (!dynamic) return CLIENT_TYPE_OPTIONS;

  return CLIENT_TYPE_OPTIONS.map((option) => {
    if (option.value === "row" || option.value === "rowWithGraphics") {
      return option;
    }

    return { ...option, disabled: true };
  });
};

const canBeDynamic = (collection: Collection): boolean =>
  collection.contentType === CollectionContentType.Media;

interface Props {
  collection: Collection;
  onChangeHandlers: CollectionOnChangeHandlers;
  showAssetDialog: () => void;
  readOnly: boolean;
}

export function CollectionView({
  collection,
  onChangeHandlers,
  showAssetDialog,
  readOnly,
}: Props) {
  const clientType = getClientType(
    collection.collectionType,
    collection.contentType
  );
  const [selectedTab, setSelectedTab] = useState<string | null>("contents");

  useEffect(() => {
    // The Graphics tab is only available for collections with type
    // "page", so automatically switch from the Graphics tab to the
    // Contents tab if the Graphics tab is currently selected when the
    // collection type is changed from "page" to "simple"
    if (collection.collectionType === "simple") {
      setSelectedTab("contents");
    }
  }, [collection.collectionType, setSelectedTab]);

  return (
    <>
      <Card>
        <Card.Header>Collection info</Card.Header>
        <Card.Body>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="collection-title">Title</Form.Label>
            <Form.Control
              id="collection-title"
              value={collection.title}
              placeholder="Title of collection"
              readOnly={readOnly}
              onChange={onChangeHandlers.title}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              id="notes"
              type="text"
              value={collection.description}
              placeholder="Displayed next to content cards in editorial pitch layouts"
              readOnly={readOnly}
              onChange={onChangeHandlers.description}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Editorial notes</Form.Label>
            <Form.Control
              id="notes"
              type="text"
              value={collection.notes}
              placeholder="Internal notes. Will not be displayed by clients."
              readOnly={readOnly}
              onChange={onChangeHandlers.notes}
            />
          </Form.Group>
          <div className="mb-3 d-flex justify-content-between">
            <div>
              <Form.Label>Type of collection</Form.Label>
              <ToggleControlWithLabels
                value={clientType}
                options={getClientTypeOptions(collection.dynamic)}
                onChange={onChangeHandlers.clientType}
              />
            </div>
            <div>
              <Form.Label>Status</Form.Label>
              <StatusComponent
                enabled={collection.enabled}
                activeContent={collection.activeContent}
                onChange={onChangeHandlers.enabled}
                type="collection"
              />
            </div>
          </div>
          <DynamicCollectionToggle
            checked={collection.dynamic}
            onChange={onChangeHandlers.dynamic}
            disabled={!canBeDynamic(collection) || readOnly}
          />
        </Card.Body>
      </Card>
      <Tab.Container
        id="collection-contents-and-graphics"
        activeKey={selectedTab || undefined}
        onSelect={setSelectedTab}
      >
        <Card className="mt-3">
          <Card.Header>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="contents">Contents</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="graphics"
                  disabled={collection.collectionType !== "page"}
                >
                  Graphics
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body>
            <Tab.Content>
              <Tab.Pane eventKey="contents">
                <CollectionContentComponent
                  readOnly={readOnly}
                  collection={collection}
                  onChangeHandlers={onChangeHandlers}
                  showAssetDialog={showAssetDialog}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="graphics" mountOnEnter={true}>
                <CollectionGraphicsComponent
                  collection={collection}
                  readOnly={readOnly}
                  onChangeHandlers={onChangeHandlers}
                />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>
    </>
  );
}
