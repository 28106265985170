import React from "react";
import { Form } from "react-bootstrap";
import { LANGUAGE_NAMES } from "../constants";
import { FieldProps } from "./types";

const languageOptions = [["", "None"], ...Object.entries(LANGUAGE_NAMES)];

export const DescriptionLanguageField = ({ state, applyPatch }: FieldProps) => {
  return (
    <>
      <Form.Label htmlFor="description-language" className="mt-4">
        Description language
      </Form.Label>
      <Form.Select
        id="description-language"
        value={state.descriptionLanguage}
        onChange={(event) =>
          applyPatch({ descriptionLanguage: event.target.value })
        }
      >
        {languageOptions.map(([languageCode, languageName]) => (
          <option key={languageCode} value={languageCode}>
            {languageName}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
