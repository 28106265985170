import { Moment } from "moment";
import React from "react";
import { Col } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";
import { MomentParam } from "client/types/MomentParam";
import DateTimeControl from "components/controls/datetime/DateTimeControl";
import { useResetPage } from "./hooks";

export const DateRangeFilter = () => {
  const [type = "all"] = useQueryParam("type", StringParam);
  if (type === "catchup") {
    return <CatchupDateRangeFilter />;
  }
  return <BroadcastDateRangeFilter />;
};
const CatchupDateRangeFilter = () => {
  const [catchupEndFrom, setCatchupEndFrom] = useQueryParam(
    "catchupEndFrom",
    MomentParam
  );
  const [catchupEndTo, setCatchupEndTo] = useQueryParam(
    "catchupEndTo",
    MomentParam
  );
  const resetPage = useResetPage();
  const selectCatchupEndFrom = (newCatchupEndFrom: Moment | undefined) => {
    setCatchupEndFrom(newCatchupEndFrom);
    resetPage();
  };
  const selectCatchupEndTo = (newCatchupEndTo: Moment | undefined) => {
    setCatchupEndTo(newCatchupEndTo);
    resetPage();
  };

  return (
    <>
      <Col xs={3}>
        <DateTimeControl
          key="catchup-end-from"
          placeholder="From (catchup end)"
          closeOnSelect
          value={catchupEndFrom}
          onChange={selectCatchupEndFrom}
          acceptEmpty
        />
      </Col>
      <Col xs={3}>
        <DateTimeControl
          key="catchup-end-to"
          placeholder="To (catchup end)"
          closeOnSelect
          value={catchupEndTo}
          onChange={selectCatchupEndTo}
          acceptEmpty
        />
      </Col>
    </>
  );
};
const BroadcastDateRangeFilter = () => {
  const [fromDate, setFromDate] = useQueryParam("fromDate", MomentParam);
  const [toDate, setToDate] = useQueryParam("toDate", MomentParam);
  const resetPage = useResetPage();
  const selectFromDate = (newFromDate: Moment | undefined) => {
    setFromDate(newFromDate);
    resetPage();
  };
  const selectToDate = (newToDate: Moment | undefined) => {
    setToDate(newToDate);
    resetPage();
  };

  return (
    <>
      <Col xs={3}>
        <DateTimeControl
          key="broadcast-from"
          placeholder="From (broadcast start)"
          closeOnSelect
          value={fromDate}
          onChange={selectFromDate}
          acceptEmpty
        />
      </Col>
      <Col xs={3}>
        <DateTimeControl
          key="broadcast-to"
          placeholder="To (broadcast start)"
          closeOnSelect
          value={toDate}
          onChange={selectToDate}
          acceptEmpty
        />
      </Col>
    </>
  );
};
