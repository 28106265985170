import { PropTypes } from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom-v5-compat";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import { Truncate } from "components/Truncate";
import { StatusBadge } from "components/badges";
import { getRevisionStatusVariant } from "../status";
import AssetDataModelButton from "./AssetDataModelButton";

function SelectRevision({
  revisions,
  getLinkTo,
  navigateToCompareLink,
  selectedRevisionId,
  selectedDataModelKey = undefined,
  ...rest
}) {
  return (
    <>
      <RevisionHeaders />
      <Row>
        {revisions.map((revision) => (
          <RevisionLink
            key={revision.id}
            to={getLinkTo ? getLinkTo(revision.id) : undefined}
            isSelected={revision.id === selectedRevisionId}
          >
            <RevisionRow
              revisionId={revision.id}
              navigateToCompareLink={navigateToCompareLink}
              selectedDataModelKey={selectedDataModelKey}
              isRowSelected={revision.id === selectedRevisionId}
              {...revision}
              {...rest}
            />
          </RevisionLink>
        ))}
      </Row>
    </>
  );
}

SelectRevision.propTypes = {
  revisions: PropTypes.array.isRequired,
  getLinkTo: PropTypes.func.isRequired,
  selectedRevisionId: PropTypes.number,
};

const RevisionRow = React.memo(function ({
  revisionId,
  source,
  play_status,
  created,
  updated,
  yaaf,
  yaaf_checksum,
  yaafd,
  yaafd_checksum,
  yaafo,
  yaafo_checksum,
  navigateToCompareLink,
  selectedDataModelKey,
  isRowSelected,
}) {
  return (
    <Row className="px-3 gx-2">
      <Truncate as={Col}>{source}</Truncate>
      <Truncate as={Col}>
        <StatusBadge
          status={play_status}
          className="mt-1"
          showTitle
          statusToVariant={getRevisionStatusVariant}
        />
      </Truncate>
      <Truncate as={Col}>
        <AssetDataModelButton
          revisionId={revisionId}
          navigateToCompareLink={navigateToCompareLink}
          dataModelKey="yaafo"
          selectedDataModelKey={selectedDataModelKey}
          dataModel={yaafo}
          checksum={yaafo_checksum}
          isRowSelected={isRowSelected}
        />
      </Truncate>
      <Truncate as={Col}>
        <AssetDataModelButton
          revisionId={revisionId}
          navigateToCompareLink={navigateToCompareLink}
          dataModelKey="yaafd"
          selectedDataModelKey={selectedDataModelKey}
          dataModel={yaafd}
          checksum={yaafd_checksum}
          isRowSelected={isRowSelected}
        />
      </Truncate>
      <Truncate as={Col}>
        <AssetDataModelButton
          revisionId={revisionId}
          navigateToCompareLink={navigateToCompareLink}
          dataModelKey="yaaf"
          selectedDataModelKey={selectedDataModelKey}
          dataModel={yaaf}
          checksum={yaaf_checksum}
          isRowSelected={isRowSelected}
        />
      </Truncate>
      <Truncate as={Col}>{parseDateToDatetimeString(created)}</Truncate>
      <Truncate as={Col}>{parseDateToDatetimeString(updated)}</Truncate>
    </Row>
  );
});

RevisionRow.propTypes = {
  id: PropTypes.number.isRequired,
  uhe_status: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  showUheReleases: PropTypes.bool.isRequired,
};

const RevisionHeaders = () => (
  <Row className="px-3 gx-2">
    <Col xs className="text-secondary">
      SOURCE
    </Col>
    <Col xs className="text-secondary">
      PLAY STATUS
    </Col>
    <Col xs className="text-secondary">
      YAAFO
    </Col>
    <Col xs className="text-secondary">
      YAAFD
    </Col>
    <Col xs className="text-secondary">
      YAAF
    </Col>
    <Col xs className="text-secondary">
      CREATED
    </Col>
    <Col xs className="text-secondary">
      UPDATED
    </Col>
  </Row>
);

const RevisionLink = ({ children, isSelected, ...rest }) => (
  <Link
    className="btn w-100 text-start btn-light"
    style={isSelected ? undefined : { opacity: 0.5 }}
    {...rest}
  >
    {children}
  </Link>
);

RevisionLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default SelectRevision;
