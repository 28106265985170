import React from "react";

const SeasonMediaContentsHelp = ({ contentIds }) => (
  <div>
    {contentIds.length > 0 ? (
      <p className="help-block mt-2">
        The CMS will attempt to automatically link matches to assets that
        correspond to their linear TV broadcasts based on the media contents
        above.
      </p>
    ) : (
      <p className="help-block mt-2">
        No media content has been specified for this season. Add media contents
        that contain episodes for the season&apos;s matches and the CMS will
        attempt to automatically link matches to the assets that correspond to
        their linear TV broadcasts.
      </p>
    )}
  </div>
);

export default SeasonMediaContentsHelp;
