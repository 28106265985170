import memoize from "memoize-one";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { UserContext } from "client/App";
import { PublicationTypes } from "client/types/PublicationTypes";
import { CmsPure, request } from "client/utils";
import PublishBar from "components/toolbar/PublishBar";
import DateBar from "./DateBar";
import ScheduleTable from "./ScheduleTable";
import SchedulerMenu, {
  getValidPlacement,
  isPlacementEqual,
} from "./SchedulerMenu";
import SchedulerView from "./SchedulerView";
import ItemModal from "./modals/ItemModal";
import SchedulerStateHandler, { ItemModalModes } from "./state";
import {
  formatModalScheduleToPayloadSchedule,
  formatPayload,
  formatScheduleToPayloadSchedule,
  getLabel,
  getScheduleEndpoint,
} from "./utils";
import "/public/stylesheets/scheduler.css";

function getValidDateString(date) {
  let mom = moment(date);
  if (!mom.isValid()) {
    console.warn("Bad start date format", date);
    mom = moment();
  }
  return mom.format("YYYY[-]MM[-]DD");
}

function queryPropsAreEqual(prev, next) {
  return (
    next.date === prev.date &&
    next.view === prev.view &&
    isPlacementEqual(prev.placement, next.placement) &&
    next.listView === prev.listView
  );
}

class Schedule extends CmsPure {
  stateHandler = new SchedulerStateHandler(this);

  componentDidMount() {
    this.timerIteration = 0;
    this.timer = setInterval(this.timerUpdate, 60000);

    this.componentDidUpdate();
  }

  timerUpdate = () => {
    this.stateHandler.updateTimeline();
    // refresh the schedule every 5 minutes
    if (this.timerIteration === 4) {
      this.componentDidUpdate();
    } else this.timerIteration++;
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {
    if (prevProps && queryPropsAreEqual(prevProps, this.props)) return;
    const { date, view, placement, listView } = this.props;
    const queryParams = {
      ...placement,
    };

    // if viewing as a timeline, we want only a segment of entries
    // in list view we want all of them
    if (!listView) {
      const { start, end } = this.getDateRange(date, view);
      queryParams.start = start.toISOString();
      queryParams.end = end.toISOString();
    }

    this.stateHandler.setFetching(true);
    // reset the auto update timer here
    this.timerIteration = 0;

    const lastFetchedProps = { date, view, placement, listView };
    request("schedule?" + queryString.stringify(queryParams))
      .then((schedule) => {
        if (!queryPropsAreEqual(this.props, lastFetchedProps)) return;
        this.stateHandler.setSchedule(schedule);
      })
      .catch((err) => {
        toast.error(`Failed to load schedule: ${err.message}`);
        console.error("Failed to load schedule", err);
        this.stateHandler.setFetching(false);
      });
  }

  deleteSelectedItem = () => {
    const { schedule, itemType } = this.stateHandler.getItemModal();
    const endpoint = getScheduleEndpoint(itemType);

    request(endpoint + "/" + schedule.id, { method: "DELETE" })
      .then(() => {
        this.stateHandler.deleteItem(schedule.id, itemType);
        this.stateHandler.hideItemModal();
      })
      .catch((error) => {
        toast.error(`Failed to delete id ${schedule.id}: ${error.message}`);
        console.error(error);
      });
  };

  showSelectedItemModal = (schedule, item, itemType) => {
    this.stateHandler.initItemModal(
      schedule,
      item,
      itemType,
      ItemModalModes.VIEW
    );
  };

  showCreateItemModal = (schedule, itemType) => {
    this.stateHandler.initItemModal(
      schedule,
      null,
      itemType,
      ItemModalModes.CREATE
    );
    this.stateHandler.setHoverItem({ schedule, itemType });
  };

  onCreateModal = () => {
    const { schedule, item, itemType } = this.stateHandler.getItemModal();
    const placement = this.props.placement;
    const endpoint = getScheduleEndpoint(itemType);
    const payload = formatPayload(
      formatModalScheduleToPayloadSchedule(schedule),
      item,
      placement,
      itemType
    );

    request(endpoint, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((newEntry) => {
        if (!isPlacementEqual(placement, this.props.placement)) return;
        this.stateHandler.addItem(newEntry);
        this.stateHandler.hideItemModal();
      })
      .catch((err) => {
        toast.error(
          "Failed to create " + getLabel(itemType) + ". " + err.message
        );
        console.error(err);
      });
  };

  putPayload = (schedule, item, itemType) => {
    const endpoint = getScheduleEndpoint(itemType);
    const placement = this.props.placement;
    const payload = formatPayload(schedule, item, placement, itemType);

    return request(endpoint + "/" + schedule.id, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((updatedEntry) => {
        if (!isPlacementEqual(placement, this.props.placement)) return;
        this.stateHandler.updateItem(updatedEntry);
        this.stateHandler.hideItemModal();
      })
      .catch((err) => {
        toast.error(
          "Failed to update " + getLabel(itemType) + ": " + err.message
        );
        console.error(err);
      });
  };

  onSaveModal = () => {
    const { schedule, item, itemType } = this.stateHandler.getItemModal();
    this.putPayload(
      formatModalScheduleToPayloadSchedule(schedule),
      item,
      itemType
    );
  };

  updateScheduleItem = (entry, syncUI = false, sendPayload = false) => {
    if (sendPayload) {
      this.putPayload(
        formatScheduleToPayloadSchedule(entry.schedule),
        entry.item,
        entry.itemType
      );
    }
    if (syncUI) {
      this.stateHandler.updateItem(entry, false);
    } else {
      this.stateHandler.throttledUpdateItem(entry, true);
    }
  };

  getDateRange = memoize((date, view) => {
    if (view === "week") {
      return {
        start: moment(date).startOf("isoWeek"),
        end: moment(date).endOf("isoWeek"),
      };
    } else if (view === "day") {
      return {
        start: moment(date).startOf("day"),
        end: moment(date).endOf("day"),
      };
    } else {
      return {
        start: moment(date).startOf("month"),
        end: moment(date).endOf("month"),
      };
    }
  });

  getLinkTo = (changedParams) => {
    const { placement, date, view, listView } = this.props;
    return (
      "schedule?" +
      queryString.stringify({
        ...placement,
        date,
        view,
        listView,
        ...changedParams,
      })
    );
  };

  render() {
    const {
      [PublicationTypes.TOP_PROMO]: topPromos,
      [PublicationTypes.COVER_PROMO]: otherPromos,
      [PublicationTypes.COLLECTION]: collections,
      fetching,
      now,
      itemModal,
      indexRange,
      hoverItem,
    } = this.state;
    const { placement, date, view, listView } = this.props;
    const { start, end } = this.getDateRange(date, view);
    const itemBeingCreated = this.stateHandler.getItemBeingManipulated();
    const showTopPromos = placement.section === "home";

    return (
      <UserContext.Consumer>
        {({ hasPermissionTo }) => {
          const isEditor = hasPermissionTo("edit", "publication");
          return (
            <>
              <PublishBar />
              <ItemModal
                canEdit={isEditor}
                {...itemModal}
                onHide={this.stateHandler.hideItemModal}
                onChange={this.stateHandler.updateCreateItemProps}
                onSave={this.onSaveModal}
                onCreate={this.onCreateModal}
                toolbarActions={{
                  onEdit: this.stateHandler.setModalEditMode,
                  onDelete: this.deleteSelectedItem,
                }}
              />
              <Container fluid>
                <Row>
                  <SchedulerMenu
                    placement={placement}
                    showAsList={listView}
                    getLinkTo={this.getLinkTo}
                  />
                  {listView ? (
                    <Col className="position-relative">
                      <h1>Top promos</h1>
                      <ScheduleTable
                        data={topPromos}
                        loading={fetching}
                        now={now}
                        placeholder={
                          !showTopPromos
                            ? "Top promos can only be scheduled on the front page"
                            : undefined
                        }
                        showManageColumn={isEditor}
                        onEdit={this.showSelectedItemModal}
                        defaultPageSize={5}
                      />
                      <h1>Promo cards</h1>
                      <ScheduleTable
                        data={otherPromos}
                        loading={fetching}
                        now={now}
                        showManageColumn={isEditor}
                        onEdit={this.showSelectedItemModal}
                        defaultPageSize={10}
                      />
                      <h1>Collections</h1>
                      <ScheduleTable
                        data={collections}
                        loading={fetching}
                        now={now}
                        showManageColumn={isEditor}
                        onEdit={this.showSelectedItemModal}
                        defaultPageSize={10}
                      />
                    </Col>
                  ) : (
                    <Col className="position-relative">
                      <DateBar
                        date={date}
                        view={view}
                        getLinkTo={this.getLinkTo}
                        listView={listView}
                        placement={placement}
                      />
                      <SchedulerView
                        now={now}
                        start={start}
                        end={end}
                        collections={collections}
                        topPromos={topPromos}
                        otherPromos={otherPromos}
                        indexRange={indexRange}
                        updateItem={this.updateScheduleItem}
                        loading={fetching}
                        onClickItem={this.showSelectedItemModal}
                        onCreateItem={this.showCreateItemModal}
                        onCreateRow={this.stateHandler.addIndex}
                        hoverItem={itemBeingCreated || hoverItem}
                        setHoverItem={this.stateHandler.setHoverItem}
                        canEdit={isEditor}
                        showTopPromos={showTopPromos}
                        getLinkTo={this.getLinkTo}
                      />
                    </Col>
                  )}
                </Row>
              </Container>
            </>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

const ScheduleWithUseQuery = () => {
  const [view] = useQueryParam("view", StringParam);
  const constrainedView =
    view === "month" || view === "week" || view === "day" ? view : "week";
  const [date] = useQueryParam("date", StringParam);
  const [listView] = useQueryParam("listView", StringParam);

  const [section] = useQueryParam("section", StringParam);
  const [provider] = useQueryParam("provider", NumberParam);
  const [channel] = useQueryParam("channel", NumberParam);
  const placement = getValidPlacement(
    { section, provider, channel },
    { section: "home" }
  );

  return (
    <Schedule
      view={constrainedView}
      date={getValidDateString(date)}
      listView={listView === "true"}
      placement={placement}
    />
  );
};

export default ScheduleWithUseQuery;
