import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import RadioControl from "components/controls/RadioControl";
import RadioItem from "components/controls/RadioItem";

const OPTIONS = [
  {
    value: "home",
    label: "Front page",
  },
  {
    value: "tvArchive",
    label: "TV archive page",
  },
  {
    value: "series",
    label: "Series page",
  },
  {
    value: "movies",
    label: "Movies page",
  },
  {
    value: "kids",
    label: "Kids page",
  },
  {
    value: "movieRental",
    label: "Movies rental page",
  },
];
export class PageSectionRadio extends React.Component {
  render() {
    const { disabled, selected, onChange, idPrefix = "" } = this.props;
    return (
      <RadioControl>
        {OPTIONS.map((section) => (
          <RadioItem
            key={section.value}
            title={section.label}
            value={section.value}
            selected={selected}
            disabled={disabled}
            onChange={onChange}
            id={idPrefix + section.label}
          />
        ))}
      </RadioControl>
    );
  }
}

export class PageSectionDropdown extends React.Component {
  render() {
    const { selected, onChange } = this.props;
    return (
      <Typeahead
        id="pageSection"
        multiple={false}
        selected={selected}
        options={OPTIONS}
        placeholder="Select a page section"
        onChange={onChange}
      />
    );
  }
}
