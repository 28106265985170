import _ from "lodash";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import AnnotationLabel from "./AnnotationLabel";

const parseReactEvent = (e) => {
  if (e.nativeEvent && e.nativeEvent instanceof Event) {
    return e.target.value;
  }
  return e;
};

const AnnotatedControl = ({
  label,
  field,
  type,
  component,
  onChange,
  formatValue,
  parseValue,
  parseEvent,
  checkIsModified,
  className = "mb-3",
  ...rest
} = {}) => {
  const { value, currentValue, original, source } = field;

  const isModified = checkIsModified
    ? checkIsModified()
    : currentValue !== undefined;
  const isReverted =
    (source === "patched" &&
      currentValue !== undefined &&
      _.isEqual(currentValue, original)) ||
    (currentValue === "" && original === undefined);

  const rawValue = currentValue === undefined ? value : currentValue;
  const formattedValue = formatValue(rawValue);
  const normalizeValue = (v) => (type === "number" ? parseInt(v, 10) : v);
  const FormControl = component || Form.Control;

  const revert = useCallback(() => {
    const reverted = original !== undefined ? original : "";
    onChange(reverted);
  }, [onChange, original]);

  const handleOnChange = useCallback(
    (e) => {
      const value = parseEvent ? parseEvent(e) : parseReactEvent(e);
      return onChange(parseValue(normalizeValue(value)));
    },
    [parseEvent, normalizeValue, parseValue, onChange]
  );

  return (
    <Form.Group className={className}>
      <AnnotationLabel
        label={label}
        source={source}
        isModified={isModified}
        isReverted={isReverted}
        original={original}
        onRevert={revert}
      />
      <FormControl
        value={formattedValue}
        type={type}
        onChange={handleOnChange}
        {...rest}
      />
    </Form.Group>
  );
};

AnnotatedControl.defaultProps = {
  field: {
    value: "",
    source: "original",
  },
  formatValue: (v) => v,
  parseValue: (v) => v,
};

export default AnnotatedControl;
