import { MediaContentData } from "@telia-company/tv.no-play-cms-common/db/definitions/MediaContent";
import React from "react";
import { Card, Form } from "react-bootstrap";
import DataViewer from "./DataViewer";
import Rollback from "./Rollback";
import { MediaContentHistory } from "./types";

function DataViewCard({
  leftValue,
  rightValue,
  rollback,
}: {
  leftValue?: MediaContentHistory;
  rightValue?: MediaContentHistory;
  rollback: (data: MediaContentData) => void;
}) {
  return (
    <Card className="mt-4">
      <Card.Header className="d-flex justify-content-between">
        Data
        {leftValue && (
          <>
            <div className="d-flex align-items-center">
              <span className="pe-1 fw-bold">Selected version:</span>
              <span>{leftValue?.version}</span>
            </div>
            {rightValue && (
              <div className="d-flex align-items-center">
                <span className="pe-1 fw-bold">Selected version:</span>
                <span>{rightValue?.version}</span>
              </div>
            )}
            <div className="d-flex align-items-end">
              <Form.Label htmlFor="show-deleted-images" className="mb-0 me-2">
                <Rollback data={leftValue} rollback={rollback} />
              </Form.Label>
            </div>
          </>
        )}
      </Card.Header>
      <DataViewer leftValue={leftValue?.data} rightValue={rightValue?.data} />
    </Card>
  );
}

export default DataViewCard;
