import React from "react";
import { Badge, Button } from "react-bootstrap";
import { printChecksum } from "./toNode";

interface Props {
  revisionId: number;
  navigateToCompareLink: (revisionId: number, dataModelKey: string) => void;
  selectedDataModelKey: string;
  dataModelKey: string;
  dataModel: object;
  checksum: string;
  isRowSelected: boolean;
}

function AssetDataModelButton({
  revisionId,
  navigateToCompareLink,
  selectedDataModelKey,
  dataModelKey,
  dataModel,
  checksum,
  isRowSelected,
}: Props) {
  const onCompareClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigateToCompareLink(revisionId, dataModelKey);
  };

  return (
    <Badge
      as={Button}
      pill
      className="btn-primary-hover"
      bg={
        selectedDataModelKey === dataModelKey && isRowSelected
          ? "primary"
          : "light"
      }
      text="dark"
      onClick={onCompareClick}
    >
      {dataModel && printChecksum(checksum)}
    </Badge>
  );
}

export default AssetDataModelButton;
