import React from "react";
import Select from "react-select";
import { NumericArrayParam, useQueryParam } from "use-query-params";
import { useChannelOptions, useResetPage } from "./hooks";
import { channelLabel } from "./utils";

export const ExcludedChannelsFilter = () => {
  const channelOptions = useChannelOptions();
  const [channels] = useQueryParam("channels", NumericArrayParam);
  const [excludedChannels, setExcludedChannels] = useQueryParam(
    "excludedChannels",
    NumericArrayParam
  );
  const resetPage = useResetPage();
  const selectExcludedChannels = (newExcludedChannels: number[]) => {
    setExcludedChannels(newExcludedChannels);
    resetPage();
  };
  const selectedExcludedChannels = (excludedChannels || [])
    .filter((chId) => chId !== null)
    .map((chId) => ({
      value: Number(chId),
      label: channelLabel(chId as number, channelOptions),
    }));
  const isDisabled = !!channels && channels.length > 0;

  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      onChange={(selected) =>
        selectExcludedChannels(selected.map((option) => option.value))
      }
      options={channelOptions}
      placeholder="Exclude channels?"
      hideSelectedOptions={true}
      value={selectedExcludedChannels}
      isDisabled={isDisabled}
    />
  );
};
