import _ from "lodash";
import React, { useReducer, useCallback, useEffect } from "react";
import { Alert, Button, Form, Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useUser } from "client/hooks";
import { request } from "client/utils";
import MatchTable from "./MatchTable";
import SeasonSidebar from "./SeasonSidebar";
import { reducer, init } from "./season-reducer";
import { displayName } from "./utils";

const LeagueSeason = () => {
  let { leagueId, seasonId } = useParams();
  leagueId = parseInt(leagueId);
  seasonId = parseInt(seasonId);
  const user = useUser();
  const canEditPublications = user.hasPermissionTo("edit", "publication");
  const [state, dispatch] = useReducer(reducer, !canEditPublications, init);
  const {
    season,
    matchFilter,
    loading,
    autoRefreshing,
    selectedMatch,
    selectedTeam,
    readOnly,
  } = state;
  const { competition, matches } = season;

  useEffect(() => {
    // reset state when navigating to a new season
    dispatch({ type: "season.changed" });
  }, [leagueId, seasonId]);

  useEffect(() => {
    if (
      autoRefreshing ||
      season.dirty ||
      season.id !== seasonId ||
      season.competitionId !== leagueId
    ) {
      dispatch({ type: "season.request.initialized" });
      const params = matchFilter.excludePlayed ? "?excludePlayed=true" : "";
      const url = `sports/leagues/${leagueId}/seasons/${seasonId}${params}`;
      request(url)
        .then((res) =>
          dispatch({ type: "season.request.success", payload: res })
        )
        .catch((err) =>
          dispatch({ type: "season.request.error", payload: err })
        );
    }
  }, [
    leagueId,
    seasonId,
    matchFilter,
    autoRefreshing,
    season.dirty,
    season.id,
    season.competitionId,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "season.autoRefreshRequested" });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const stages = [];
  let currentStage = { id: undefined, name: undefined, matches: undefined };

  for (const match of matches) {
    const stage = match.stage;
    if (stage.id !== currentStage.id) {
      currentStage = { matches: [], ..._.pick(stage, ["id", "name"]) };
      stages.push(currentStage);
    }
    currentStage.matches.push(match);
  }

  const showStage = stages.length > 1;

  const toggleExcludePlayed = useCallback(() => {
    dispatch({
      type: "matchFilter.updated",
      payload: { excludePlayed: !matchFilter.excludePlayed },
    });
  }, [matchFilter.excludePlayed]);

  return (
    <Container fluid className="p-4">
      <Row>
        <Col>
          <Container fluid className="p-0">
            <Row>
              <Col className="d-flex justify-content-between">
                <h1 className="mt-0">
                  {`${displayName(competition)} ${displayName(season)}`}
                </h1>
                <div>
                  <Form.Check
                    type="checkbox"
                    checked={matchFilter.excludePlayed}
                    onChange={toggleExcludePlayed}
                    label="Exclude played matches"
                    id="exclude-played-matches"
                  />
                </div>
              </Col>
            </Row>
            {stages.length > 0
              ? stages.map((stage) => (
                  <Row key={stage.id}>
                    <Col>
                      {showStage && <h2>{stage.name}</h2>}
                      <MatchTable
                        matches={stage.matches}
                        selectedMatch={selectedMatch}
                        dispatch={dispatch}
                        loading={loading && !autoRefreshing}
                      />
                    </Col>
                  </Row>
                ))
              : (!loading || autoRefreshing) && (
                  <Row>
                    <Col>
                      <Alert variant="info">
                        {matchFilter.excludePlayed ? (
                          <>
                            <div>
                              There are no upcoming matches in this season. Do
                              you want to display matches that have already been
                              played?
                            </div>
                            <div className="mt-2">
                              <Button
                                size="sm"
                                variant="info"
                                onClick={toggleExcludePlayed}
                              >
                                Show played matches
                              </Button>
                            </div>
                          </>
                        ) : (
                          "There are no matches in this season."
                        )}
                      </Alert>
                    </Col>
                  </Row>
                )}
          </Container>
        </Col>
        <Col sm={4}>
          {season.id && (
            <SeasonSidebar
              season={season}
              match={selectedMatch}
              team={selectedTeam}
              loading={loading}
              dispatch={dispatch}
              seasonCard={state.seasonCard}
              matchCard={state.matchCard}
              readOnly={readOnly}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default LeagueSeason;
