import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import IMDbLink from "./IMDbLink";
import IMDbRating from "./IMDbRating";

const IMDB_TITLE_TYPES = {
  short: "Short",
  tvSeries: "TV Series",
  videoGame: "Video Game",
  tvSpecial: "TV Special",
  tvShort: "TV Short",
  tvMovie: "TV Movie",
  tvEpisode: "Episode",
  video: "Video",
  movie: "Movie",
  tvMiniSeries: "TV Mini Series",
};

const IMDbMetadata = ({ children, divider }) => (
  <>
    <span>{children}</span>
    {divider && <span className="divider">|</span>}
  </>
);

IMDbMetadata.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
};

IMDbMetadata.defaultProps = {
  divider: true,
};

const norwegianTitle = (imdb) => {
  if (imdb && imdb.norwegianTitle) {
    return imdb.norwegianTitle;
  } else if (imdb && imdb.data && imdb.data.akas) {
    for (const aka of imdb.data.akas) {
      if (aka.region === "NO") {
        return aka.title;
      }
    }
  }
  return undefined;
};

const IMDbTitleCard = ({
  imdb,
  preHeading,
  missingText,
  className,
  children,
}) => (
  <Card className={classNames("imdb-card", className)}>
    <Card.Header className="pb-1">
      {preHeading && <h6 className="text-muted m-0">{preHeading}</h6>}
      <div className="d-flex">
        <h2 className="flex-grow-1">
          {imdb && imdb.data ? <IMDbLink imdb={imdb.data} /> : missingText}
        </h2>
        {imdb && imdb.data && imdb.data.imdbRating && (
          <IMDbRating className="ms-4" {...imdb.data.imdbRating} />
        )}
      </div>
      <div className="text-muted">
        {norwegianTitle(imdb) && (
          <IMDbMetadata>
            {"\u{1f1f3}\u{1f1f4} "}
            {norwegianTitle(imdb)}
          </IMDbMetadata>
        )}
        {imdb && imdb.data && (
          <>
            <IMDbMetadata>
              {IMDB_TITLE_TYPES[imdb.data.titleType] || "Unknown"}
            </IMDbMetadata>
            <IMDbMetadata divider={false}>
              <IMDbLink imdb={imdb.data} className="text-muted">
                View on IMDb
              </IMDbLink>
            </IMDbMetadata>
          </>
        )}
      </div>
    </Card.Header>
    {imdb && imdb.warnings && imdb.warnings.length > 0 && (
      <ListGroup variant="flush">
        {imdb.warnings.map((w) => (
          <ListGroup.Item key={w} variant="warning" className="pt-2 pb-2">
            <span className="me-2">{"\u26a0\ufe0f"}</span>
            {w}
          </ListGroup.Item>
        ))}
      </ListGroup>
    )}
    <Card.Body>{children}</Card.Body>
  </Card>
);

IMDbTitleCard.propTypes = {
  imdb: PropTypes.shape({
    data: PropTypes.shape({
      titleType: PropTypes.string.isRequired,
      imdbRating: PropTypes.shape({
        rating: PropTypes.number,
        numberOfVotes: PropTypes.number,
      }),
    }),
    norwegianTitle: PropTypes.string,
    warnings: PropTypes.arrayOf(PropTypes.string),
  }),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  preHeading: PropTypes.string,
  missingText: PropTypes.string,
};

IMDbTitleCard.defaultProps = {
  imdb: undefined,
  preHeading: undefined,
  missingText: "No IMDb title",
  className: undefined,
};

export default IMDbTitleCard;
