import React from "react";
import { CLIENT_CONFIG } from "client/config";
import { ChannelLogoData } from "./types";

interface Props {
  logos: ChannelLogoData[];
  name: string;
}

export const ChannelLogo = ({ logos, name }: Props) => {
  const path = getLogoPath(logos);
  if (!path) {
    return null;
  }
  return (
    <div style={{ textAlign: "center" }}>
      <img src={CLIENT_CONFIG.CDN_DOMAIN + path + "?h=30"} alt={name} />
    </div>
  );
};

const getLogoPath = (logos: ChannelLogoData[]) => {
  const fixedOnLight = logos.find(
    (logo) => logo.logoType === "fixed" && logo.onBackground === "light"
  );
  if (fixedOnLight) {
    return fixedOnLight.path;
  }
  const fixedOnDark = logos.find(
    (logo) => logo.logoType === "fixed" && logo.onBackground === "dark"
  );
  if (fixedOnDark) {
    return fixedOnDark.path;
  }
  const normalOnDark = logos.find(
    (logo) => logo.logoType === "normal" && logo.onBackground === "dark"
  );
  if (normalOnDark) {
    return normalOnDark.path;
  }
  const detailOnDark = logos.find(
    (logo) => logo.logoType === "detail" && logo.onBackground === "dark"
  );
  if (detailOnDark) {
    return detailOnDark.path;
  }
  return undefined;
};
