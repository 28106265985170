import moment from "moment";

const dateFormat = "D. MMM YYYY HH:mm";
export const formatEditedBy = (user, updated) =>
  `${user} on ${updated.format(dateFormat)}`;

/**
 * React table custom filter
 * - Case insensitive.
 * - Supports search in middle of string.
 */
export const caseInsensitiveSubstringMatch = (filter, row) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? isValueCaseInsensitiveSubstring(String(row[id]), filter.value)
    : true;
};

export const isValueCaseInsensitiveSubstring = (itemValue, filterValue) => {
  return itemValue
    .toLocaleLowerCase("no")
    .includes(filterValue.toLocaleLowerCase("no"));
};

const timestampFormat = "YYYY-MM-DD HH:mm:ss";

/**
 * Format a timestamp relative to the current time.
 */
export const formatRelativeTimestamp = (date) => {
  if (!date) {
    return "N/A";
  }

  const m = moment(date);
  const now = moment();
  const tomorrow = moment(now).add(1, "d");
  const yesterday = moment(now).subtract(1, "d");
  const from = m.from(now);
  const fromDays = moment(m).startOf("day").from(moment(now).startOf("day"));
  const calendar = m.calendar(now, {
    sameDay: "[today at] H:mm",
    nextDay: "[tomorrow at] H:mm",
    nextWeek: "MMM D H:mm",
    lastDay: "[yesterday at] H:mm",
    lastWeek: "MMM D H:mm",
    sameElse: "MMM D H:mm",
  });

  if (m.isSame(now, "day")) {
    return `${from} (${m.format("H:mm")})`;
  } else if (m.isSame(tomorrow, "day") || m.isSame(yesterday, "day")) {
    return calendar;
  }

  return `${fromDays} (${calendar})`;
};

export const parseDateToDatetimeString = (date) => {
  return date ? moment(date).format(timestampFormat) : undefined;
};
export const parseDateToDateString = (date) => {
  if (date) {
    return moment(date).format("YYYY[-]MM[-]DD");
  } else {
    return undefined;
  }
};
