import React from "react";
import { Badge } from "react-bootstrap";
import { AssetInfo } from "client/types/AssetInfo";

type Props = Pick<AssetInfo, "live">;

export const LiveBadge = ({ live }: Props): JSX.Element => (
  <>
    {live && (
      <Badge key="live" bg="secondary" className="me-1">
        Live
      </Badge>
    )}
  </>
);
