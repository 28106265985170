import moment from "moment-timezone";
import { PublicationTypes } from "client/types/PublicationTypes";

export function getScheduleEndpoint(itemType) {
  switch (itemType) {
    case PublicationTypes.COLLECTION:
      return "schedule/collection";
    case PublicationTypes.TOP_PROMO:
    case PublicationTypes.COVER_PROMO:
      return "schedule/promotion";
  }
}
export function getItemEndpoint(itemType) {
  switch (itemType) {
    case PublicationTypes.COLLECTION:
      return "collections";
    case PublicationTypes.TOP_PROMO:
    case PublicationTypes.COVER_PROMO:
      return "promotions";
  }
}

export function getPathTo(itemType, location, itemId) {
  let path = "/";
  if (itemType === PublicationTypes.COLLECTION) path += "collections";
  else path += "promos";

  if (location === "create") path += "/create";
  else if (location === "edit" && itemId) path += "/edit/" + itemId;

  return path;
}

export function getLabel(itemType) {
  switch (itemType) {
    case PublicationTypes.COLLECTION:
      return "collection";
    case PublicationTypes.TOP_PROMO:
      return "top promotion";
    case PublicationTypes.COVER_PROMO:
      return "promotion card";
  }
}

export function getDefaultDuration(itemType) {
  switch (itemType) {
    case PublicationTypes.COLLECTION:
      return 7 * 24 * 3600;
    case PublicationTypes.TOP_PROMO:
    case PublicationTypes.COVER_PROMO:
      return 24 * 3600;
  }
}

export const SNAP_INTERVAL = 60 * 30;
export function snapTime(time) {
  return Math.round(time / SNAP_INTERVAL) * SNAP_INTERVAL;
}

export const ITEM_HEIGHT = 50;
export const ROW_OFFSET = 1;
export const ITEM_MIN_WIDTH = 15; // currently only used to calculate overlaps

export function getContainerY(index) {
  return index * ITEM_HEIGHT;
}

export function unixToDescriptionString(start, end, size) {
  switch (size) {
    case "sm":
      return moment
        .utc(moment.unix(end).diff(moment.unix(start)))
        .format("H:mm");
    case "md":
      return (
        moment.unix(start).format("Do HH:mm") +
        " - " +
        moment.unix(end).format("Do HH:mm")
      );
    case "lg":
    default:
      return (
        moment.unix(start).format("Do MMM HH:mm") +
        " - " +
        moment.unix(end).format("Do MMM HH:mm")
      );
  }
}

/** Formatting functions */

/**
 * @param {{id, title, index, publish, unpublish}} modalSchedule
 * @returns {{id, index, title, unixStart, unixEnd}}
 */
export function formatModalScheduleToSchedule({
  id,
  title,
  index,
  publish,
  unpublish,
}) {
  return {
    id,
    title,
    index,
    unixStart: publish.unix(),
    unixEnd: unpublish.unix(),
  };
}
/**
 * @param {{section?, channel?, provider?}} placement
 * @returns {{placement, placement_channel?, placement_provider?}}
 */
function formatPlacement(placement) {
  if (placement.section) return { placement: placement.section };
  else if (placement.channel)
    return { placement: "channel", placement_channel: placement.channel };
  else if (placement.provider)
    return { placement: "provider", placement_provider: placement.provider };
}
/**
 * @param {{id, index, title, publish, unpublish}} schedule
 * @returns {{id, index, title, schedue_start, schedule_end}}
 */
export function formatModalScheduleToPayloadSchedule(modalSchedule) {
  return {
    id: modalSchedule.id,
    index: modalSchedule.index,
    title: modalSchedule.title || null,
    schedule_start: modalSchedule.publish.toISOString(),
    schedule_end: modalSchedule.unpublish.toISOString(),
  };
}
/**
 * @param {{id, index, title, unixStart, unixEnd}} schedule
 * @returns {{id, index, title, schedue_start, schedule_end}}
 */
export function formatScheduleToPayloadSchedule(schedule) {
  return {
    id: schedule.id,
    index: schedule.index,
    title: schedule.title || null,
    schedule_start: moment.unix(schedule.unixStart).toISOString(),
    schedule_end: moment.unix(schedule.unixEnd).toISOString(),
  };
}

export function formatPayload(formattedSchedule, item, placement, itemType) {
  const payload = {
    ...formattedSchedule,
    ...formatPlacement(placement),
  };
  switch (itemType) {
    case PublicationTypes.COLLECTION:
      payload.collection_id = item.id;
      break;
    case PublicationTypes.TOP_PROMO:
    case PublicationTypes.COVER_PROMO:
      payload.promotion_id = item.id;
      break;
  }
  return payload;
}

export function replaceDateStringsWithMoments({ publish, unpublish, ...rest }) {
  const data = { ...rest };
  if (publish) data.publish = moment(publish);
  if (unpublish) data.unpublish = moment(unpublish);
  return data;
}
