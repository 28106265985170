import React from "react";
import { ContentCardWrapper } from "components/cards";

const SeasonMediaContentCard = ({ contentId, onClick, onRemove }) => (
  <ContentCardWrapper
    id={contentId}
    isAsset={false}
    showLink
    onRemove={onRemove}
    onClick={onClick}
    canClick={!!onClick}
    title="Choose another media content"
  />
);

export default SeasonMediaContentCard;
