import React from "react";
import { Alert, Container } from "react-bootstrap";
import InsightToolbar from "../InsightToolbar";
import { WarningStatusTable } from "./WarningStatusTable";

interface Props {
  status: "REPORTED" | "ACCEPTED";
  type: "ASSET" | "MEDIACONTENT";
}

export const WarningStatuses = ({ status, type }: Props) => {
  return (
    <>
      <InsightToolbar />
      <Container fluid className="my-4">
        <InfoBox status={status} type={type} />
        <WarningStatusTable status={status} type={type} />
      </Container>
    </>
  );
};

const InfoBox = ({ status, type }: Props) => {
  // Valid / used input combinations:
  //   * status: REPORTED, type: ASSET
  //   * status: ACCEPTED, type: ASSET
  //   * status: ACCEPTED, type: MEDIACONTENT (this is called spam warnings in
  //       the UI)
  let infoString;
  if (status === "REPORTED") {
    infoString = "These issues have been reported";
  } else if (type === "ASSET") {
    infoString =
      "These issues are acceptable / false positives, and have been soft ignored";
  } else {
    infoString = "These issues are permanently accepted at top level";
  }
  return <Alert variant="info">{infoString}</Alert>;
};
