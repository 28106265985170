import { DateTime } from "luxon";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { ColumnWithHeaderTooltip } from "client/types/ColumnWithHeaderTooltip";
import { ChannelName } from "client/views/Enrich/Insight/GapLog/ChannelName";
import {
  GapLogResponse,
  GapLogWithId,
} from "client/views/Enrich/Insight/GapLog/types";
import { capitalize, getTitle } from "client/views/Enrich/Insight/GapLog/utils";
import { KindBadge } from "components/badges";
import Table from "components/tables";
import { Pagination } from "components/tables/Pagination";
import InsightToolbar from "../InsightToolbar";
import { StatusFilter } from "./StatusFilter";
import { TypeFilter } from "./TypeFilter";
import { UpcomingFilter } from "./UpcomingFilter";

const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";

const columns: ColumnWithHeaderTooltip<GapLogWithId>[] = [
  {
    Header: "Channel",
    accessor: (row) => <ChannelName id={row.leadingAsset.channel_id} />,
  },
  {
    Header: "Leading Asset Id",
    accessor: (row) => (
      <Link to={`/enrich/asset/${row.leading_asset_id}`}>
        {row.leading_asset_id}
      </Link>
    ),
  },
  {
    Header: "Kind",
    accessor: (row) => (
      <KindBadge kind={row.leadingAsset.MediaContent.data.kind} />
    ),
  },
  {
    Header: "Title",
    accessor: (row) => getTitle(row.leadingAsset),
  },
  {
    Header: "Leading end time",
    accessor: (row) =>
      DateTime.fromISO(row.observed_leading_end_time).toFormat(
        DATE_TIME_FORMAT
      ),
  },
  {
    Header: "Trailing start time",
    accessor: (row) =>
      DateTime.fromISO(row.observed_trailing_start_time).toFormat(
        DATE_TIME_FORMAT
      ),
  },
  {
    Header: "Gap or Overlap?",
    accessor: (row) => capitalize(row.type),
  },
  {
    Header: "Trailing Asset Id",
    accessor: (row) => (
      <Link to={`/enrich/asset/${row.trailing_asset_id}`}>
        {row.trailing_asset_id}
      </Link>
    ),
  },
  {
    Header: "Trailing kind",
    accessor: (row) => (
      <KindBadge kind={row.trailingAsset.MediaContent.data.kind} />
    ),
  },
  {
    Header: "Trailing title",
    accessor: (row) => getTitle(row.trailingAsset),
  },
  {
    Header: "First seen at",
    accessor: (row) =>
      DateTime.fromISO(row.first_seen_at).toFormat(DATE_TIME_FORMAT),
  },
  {
    Header: "No longer present at",
    accessor: (row) =>
      row.no_longer_present_at
        ? DateTime.fromISO(row.no_longer_present_at).toFormat(DATE_TIME_FORMAT)
        : null,
  },
  {
    Header: "Closed reason",
    accessor: (row) => row.closed_reason,
  },
];

export const GapOverlapLog = () => {
  const searchParams = useSearchParams();
  const { data } = useSWR<GapLogResponse>(
    `insight/gap-log?${searchParams.toString()}`
  );
  const entriesWithId = data
    ? data.entries.map((entry) => ({
        ...entry,
        id: `${entry.leading_asset_id} - ${entry.trailing_asset_id}`,
      }))
    : [];
  return (
    <>
      <InsightToolbar />
      <div className="container-fluid">
        <Filters />
        <Table columns={columns} data={entriesWithId} />
        <Pagination totalResults={data?.totalEntries} />
      </div>
    </>
  );
};

const useSearchParams = () => {
  const [{ page, size, type, status, upcoming }] = useQueryParams({
    page: NumberParam,
    size: NumberParam,
    type: StringParam,
    status: StringParam,
    upcoming: StringParam,
  });

  const searchParams = new URLSearchParams();
  searchParams.set("pageIndex", page ? page.toString() : "0");
  searchParams.set("pageSize", size ? size.toString() : "20");
  if (type) {
    searchParams.set("type", type);
  }
  if (status) {
    searchParams.set("status", status);
  }
  if (upcoming) {
    searchParams.set("upcoming", upcoming);
  }
  return searchParams;
};

const Filters = () => {
  return (
    <Row className="my-2">
      <Col xs={3}>
        <TypeFilter />
      </Col>
      <Col xs={3}>
        <StatusFilter />
      </Col>
      <Col xs={3}>
        <UpcomingFilter />
      </Col>
    </Row>
  );
};
