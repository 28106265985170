import React from "react";
import { ChannelMultiPicker } from "./ChannelMultiPicker";

interface Props {
  onChange: (selected: number | null) => void;
  placeholder?: string;
  value: string | undefined;
  disabled?: boolean;
}

export const ChannelPicker = ({
  onChange,
  placeholder = "Find channel by name...",
  value,
  disabled,
  ...rest
}: Props) => (
  <ChannelMultiPicker
    id="channels"
    multiple={false}
    value={value ? [value] : []}
    placeholder={placeholder}
    onChange={(channels) => onChange(channels[0] || null)}
    disabled={disabled}
    {...rest}
  />
);
