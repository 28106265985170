import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import React from "react";
import { Button } from "react-bootstrap";
import DateControl from "components/controls/datetime/DateControl";
import { useFrom, useTo } from "./hooks/useFromAndTo";

interface Props {
  firstData?: DateTime;
}

export const DateBasedPagination = ({ firstData }: Props) => {
  if (!firstData) {
    return <div>Loading...</div>;
  }
  return (
    <div style={{ textAlign: "center" }}>
      <NewerButton />
      <ToDateField />
      -
      <FromDateField />
      <OlderButton firstData={firstData} />
    </div>
  );
};

interface PreviousButtonProps {
  firstData: DateTime;
}

const OlderButton = ({ firstData }: PreviousButtonProps) => {
  const { stepBack, stepBackDisabled } = useStepBack(firstData);

  return (
    <Button
      variant="outline-secondary"
      onClick={stepBack}
      disabled={stepBackDisabled}
      className="mx-2"
    >
      Older
    </Button>
  );
};

const NewerButton = () => {
  const { stepForward, stepForwardDisabled } = useStepForward();

  return (
    <Button
      variant="outline-secondary"
      onClick={stepForward}
      disabled={stepForwardDisabled}
      className="mx-2"
    >
      Newer
    </Button>
  );
};

const useStepBack = (firstData: DateTime) => {
  const { from, setFrom } = useFrom();
  const { to, setTo } = useTo();

  const stepBack = () => {
    const duration = to.diff(from, "days").days + 1;
    const newFrom = from.minus({ days: duration });
    if (newFrom.startOf("day") < firstData.startOf("day")) {
      setFrom(firstData.startOf("day"));
      setTo(firstData.startOf("day").plus({ days: duration - 1 }));
    } else {
      setFrom(newFrom);
      setTo(to.minus({ days: duration }));
    }
  };

  const stepBackDisabled = firstData.startOf("day") >= from.startOf("day");

  return { stepBack, stepBackDisabled };
};

const useStepForward = () => {
  const { from, setFrom } = useFrom();
  const { to, setTo } = useTo();

  const stepForward = () => {
    const duration = to.diff(from, "days").days + 1;
    const newTo = to.plus({ days: duration });
    if (newTo.startOf("day") > DateTime.now().startOf("day")) {
      setFrom(
        DateTime.now()
          .minus({ days: duration - 1 })
          .startOf("day")
      );
      setTo(DateTime.now().startOf("day"));
    } else {
      setFrom(from.plus({ days: duration }));
      setTo(newTo);
    }
  };

  const stepForwardDisabled = to
    .startOf("day")
    .equals(DateTime.now().startOf("day"));

  return { stepForward, stepForwardDisabled };
};

const ToDateField = () => {
  const { toDateString, setToBounded } = useTo();
  return (
    <div style={{ display: "inline-block", width: "100px" }} className="me-2">
      <DateControl
        canClear={false}
        onChange={(newTo: Moment) =>
          setToBounded(DateTime.fromISO(newTo.toISOString()))
        }
        value={moment(toDateString)}
        className={undefined}
        size={undefined}
        readOnly={undefined}
        placeholder={undefined}
      />
    </div>
  );
};

const FromDateField = () => {
  const { fromDateString, setFromBounded } = useFrom();
  return (
    <div style={{ display: "inline-block", width: "100px" }} className="ms-2">
      <DateControl
        canClear={false}
        onChange={(newFrom: Moment) =>
          setFromBounded(DateTime.fromISO(newFrom.toISOString()))
        }
        value={moment(fromDateString)}
        className={undefined}
        size={undefined}
        readOnly={undefined}
        placeholder={undefined}
      />
    </div>
  );
};
