import { RequestAction } from "./BulkActions/actions";

export interface Asset {
  id: number;
  providerId: number;
  yaafAssetId: string;
  providerAssetId: string;
  dataQualityWarnings: DataQualityWarnings[];

  assetPlayStatus: string;
  assetLicenseStatus: string;

  // from latest revision
  revisionId: number;
  revisionUheStatus: string;
  revisionPlayStatus: string;
  productionTitle?: string;
  episodicNotation?: string;
  seriesTitle?: string;
  title?: string;
  latestRevisionLocators: AssetRevisionLocators;
  issueReportCount: number;

  // from all ADI:s in all revisions
  packageIds: string[];

  availability: string;
  updated: string;
  revisionUpdated: string;
}

export enum DataQualityWarnings {
  Duplicate = "DUPLICATE",
  EpisodeGapBefore = "EPISODE_GAP_BEFORE",
  TitleMissingCover = "TITLE_MISSING_COVER",
  AssetMissingPoster = "ASSET_MISSING_POSTER",
  SeriesMissingPoster = "SERIES_MISSING_POSTER",
  EvaluationFailed = "EVALUATION_FAILED",
  LatestRevisionMissingYaaf = "LATEST_REVISION_MISSING_YAAF",
}

export interface DataQualityWarningEpisodeGapBeforeData {
  missing_count: number;
}

export interface DataQualityWarningDuplicateData {
  duplicate_assets_ids: number[];
}

export interface DataQualityWarningEvaluationFailedData {
  exception_message: string;
}

interface BaseDataQualityWarning {
  id: number;
  created: string;
}

interface DataQualityWarningDuplicate extends BaseDataQualityWarning {
  type: DataQualityWarnings.Duplicate;
  data: DataQualityWarningDuplicateData;
}

interface DataQualityWarningEpisodeGapBefore extends BaseDataQualityWarning {
  type: DataQualityWarnings.EpisodeGapBefore;
  data: DataQualityWarningEpisodeGapBeforeData;
}

interface DataQualityWarningEvaluationFailed extends BaseDataQualityWarning {
  type: DataQualityWarnings.EvaluationFailed;
  data: DataQualityWarningEvaluationFailedData;
}

interface DataQualityWarningTitleMissingCover extends BaseDataQualityWarning {
  type: DataQualityWarnings.TitleMissingCover;
}

interface DataQualityWarningSeriesMissingPoster extends BaseDataQualityWarning {
  type: DataQualityWarnings.SeriesMissingPoster;
}

interface DataQualityWarningAssetMissingPoster extends BaseDataQualityWarning {
  type: DataQualityWarnings.AssetMissingPoster;
}

interface DataQualityWarningLatestRevisionMissingYaaf
  extends BaseDataQualityWarning {
  type: DataQualityWarnings.LatestRevisionMissingYaaf;
}

export type DataQualityWarning =
  | DataQualityWarningDuplicate
  | DataQualityWarningEpisodeGapBefore
  | DataQualityWarningEvaluationFailed
  | DataQualityWarningTitleMissingCover
  | DataQualityWarningSeriesMissingPoster
  | DataQualityWarningAssetMissingPoster
  | DataQualityWarningLatestRevisionMissingYaaf;

export interface Log {
  timestamp: string;
  level: string;
  service: string;
  yaaf_asset_id: string;
  message: string;
  exception?: string;
}

export interface LogsResponse {
  data: Log[];
  limit: number;
}

export interface IssueReport {
  id: number;
  asset_id: number;
  data_received: Record<string, unknown>;

  created: string;
  updated: string;
}

export interface AssetRevisionLocators {
  yaaf_locator?: string;
  yaafd_locator?: string;
  yaafo_locator?: string;
}

export interface AssetRevision {
  id: number;
  yaafd: YAAFX;
  yaafo: YAAFX;
  yaaf_locator?: string;
  yaafd_locator?: string;
  yaafo_locator?: string;
}

export interface AssetRevisionProviderImage {
  getClass: string;
  language: string;
  providerClass: string;
  content: {
    transfer: {
      source: {
        uri: string;
      };
    };
  };
}

interface YAAFX {
  asset?: {
    imageDownloads?: AssetRevisionProviderImage[];
  };
  production?: { title?: string };
  season?: { descriptiveMetadata?: { index?: number } };
  episode?: { descriptiveMetadata?: { index?: number } };
  series?: { descriptiveMetadata?: { title?: string } };
}

export type AssetRevisionLocator =
  | "yaaf_locator"
  | "yaafd_locator"
  | "yaafo_locator";

export type ActionOnClickHandler = (action: RequestAction) => unknown;

export type ProviderStatus = "UNKNOWN" | "ACTIVE" | "INACTIVE";

export interface Provider {
  id: number;
  name: string;
  asset_import_concurrency_max?: number;
  play_id: string;
  play_status: ProviderStatus;
  uhe_id?: string;
  uhe_summary_name?: string;
  uhe_content_tier?: string;
  uhe_asset_id_prefix?: string;
  uhe_status: ProviderStatus;
  created: string;
  updated: string;
}
