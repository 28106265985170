import React, { useMemo } from "react";
import { CellProps, Column } from "react-table-v7";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import Table from "components/tables";
import { DatabaseName, ProgramAsset } from "../types";
import { ChannelName } from "./ChannelName";

interface Props {
  assets: ProgramAsset[];
  database: DatabaseName;
}

export function ProgramAssets({ assets, database }: Props) {
  const columns: ReadonlyArray<Column<ProgramAsset>> = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        width: 80,
      },
      {
        Header: "Start",
        accessor: "linearStart",
        Cell: datetimeCell,
        width: 80,
      },
      {
        Header: "End",
        accessor: "linearEnd",
        Cell: datetimeCell,
        width: 80,
      },
      {
        Header: "Channel",
        accessor: "channel",
        Cell: ({ cell: { value } }) => (
          <ChannelName id={value} database={database} />
        ),
        width: 80,
      },
      {
        Header: "Catchup",
        accessor: "catchup",
        width: 80,
      },
    ],
    []
  );

  return <Table data={assets} columns={columns} flex />;
}

const datetimeCell = ({ cell: { value } }: CellProps<ProgramAsset, Date>) => {
  const datetimeString = parseDateToDatetimeString(value);
  if (!datetimeString) {
    return null;
  }
  return <span>{datetimeString}</span>;
};
