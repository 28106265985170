import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { stringify } from "client/utils";
import { AssetAction } from "client/views/Ams/BulkActions";
import { useAsset } from "client/views/Ams/hooks";
import { ConfirmActionButton } from "components/buttons";

interface Props {
  assetId: number;
}

const EvaluateStatus = ({ assetId }: Props) => {
  const { asset, isLoading, mutate: refreshAsset } = useAsset(assetId);

  const onClick = useCallback(async () => {
    try {
      const action = new AssetAction("evaluate-status");
      const { status, detail, message, error } = await action.run({ asset });
      if (status && status < 300) {
        toast.success("Triggered status evaluation");
      } else {
        const msg = message || stringify(detail) || stringify(error);
        toast.error("Could not trigger status evaluation: " + msg);
      }
    } catch (error) {
      //toast.error("Could not clear quality warnings: " + stringify(error));
    }
    refreshAsset();
  }, [asset, refreshAsset]);
  return (
    <ConfirmActionButton variant="light" onClick={onClick} disabled={isLoading}>
      Evaluate status
    </ConfirmActionButton>
  );
};

export default EvaluateStatus;
