import React, { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { stringify } from "client/utils";
import { RevisionActionButtons } from "../BulkActions";
import AssetRevisionDataView from "./AssetRevisionDataView";
import RevisionMetadataSource from "./RevisionMetadataSource";

function AssetRevision({
  yaaf,
  yaaf_checksum,
  yaaf_locator,
  yaafd,
  yaafd_checksum,
  yaafd_locator,
  yaafo,
  yaafo_checksum,
  yaafo_locator,
  id,
}) {
  const locators = { yaaf_locator, yaafd_locator, yaafo_locator };
  const revision = { id, ...locators };

  const runAction = useCallback(
    async (action) => {
      const { status, detail, message, error } = await action.run({ revision });

      if (status && status < 300) {
        toast.info("Success");
      } else if (status === 403 || status === 404) {
        toast.error(`${message}: ${detail}`);
      } else {
        const msg = message || stringify(detail) || stringify(error) || "";
        const httpStatus = status ? ` (HTTP ${status})` : "";
        console.error({ status, detail, message, error });
        toast.error(`Code execution failed: ${msg}${httpStatus}`);
      }
    },
    [id, yaaf_locator, yaafd_locator, yaafo_locator]
  );

  const disabled = useCallback(
    (locator) => locator in locators && !locators[locator],
    [locators]
  );

  const metadataSourceLocator = useMemo(() => {
    return yaafd?.metadataSource || yaafo?.metadataSource;
  }, [yaafd, yaaf]);

  return (
    <>
      <h3 className="lead">Manage revision</h3>
      <RevisionActionButtons
        onClick={runAction}
        disabled={disabled}
        mustConfirm
      />
      <Row>
        <Col>
          <h3 className="lead">Metadata Source</h3>
          <RevisionMetadataSource id={id} locator={metadataSourceLocator} />
        </Col>
      </Row>
      <AssetRevisionDataView
        title="YAAFO"
        data={yaafo}
        checksum={yaafo_checksum}
        locator={yaafo_locator}
      />
      <AssetRevisionDataView
        title="YAAFD"
        data={yaafd}
        checksum={yaafd_checksum}
        locator={yaafd_locator}
      />
      <AssetRevisionDataView
        title="YAAF"
        data={yaaf}
        checksum={yaaf_checksum}
        locator={yaaf_locator}
      />
    </>
  );
}

export default AssetRevision;
