import React from "react";
import { Badge } from "react-bootstrap";
import { AssetInfo } from "client/types/AssetInfo";

type Props = Pick<AssetInfo, "repeat">;

export const RepeatBadge = ({ repeat }: Props): JSX.Element => (
  <>
    {repeat && (
      <Badge key="repeat" bg="info" className="me-1">
        Repeat
      </Badge>
    )}
  </>
);
