import _ from "lodash";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Truncate } from "components/Truncate";
import { ChannelName } from "../ProgramDetails/ChannelName";
import { MetadataProgramResponse } from "../types/MetadataProgramResponse";
import { getNextBroadcast } from "./NextBroadcast";

interface Props {
  program?: MetadataProgramResponse;
}

export function ChannelSummary({ program }: Props) {
  if (!program) {
    return <div className="text-muted">...</div>;
  }

  const { assets } = program;
  const nextBroadcast = getNextBroadcast(assets);
  const channel = nextBroadcast?.channel || assets[0]?.channel;

  if (channel) {
    const otherChannels = _.uniq(
      assets.map((a) => a.channel).filter((ch) => ch !== channel)
    );
    if (_.isEmpty(otherChannels)) {
      return <ChannelName id={channel} as={Truncate} />;
    } else {
      return (
        <OverlayTrigger
          placement="bottom-start"
          overlay={
            <Tooltip>
              {otherChannels.map((ch) => (
                <ChannelName key={ch} id={ch} as="div" />
              ))}
            </Tooltip>
          }
        >
          <div className="d-flex justify-content-between">
            <Truncate>
              <ChannelName id={channel} />
            </Truncate>
            {!_.isEmpty(otherChannels) ? (
              <div className="text-muted ms-1"> +{otherChannels.length}</div>
            ) : null}
          </div>
        </OverlayTrigger>
      );
    }
  }

  return null;
}
