import React from "react";
import { LANGUAGE_NAMES } from "./constants";

interface Props {
  languageCode: string;
}

export const DescriptionLanguage = ({ languageCode }: Props) => {
  const languageName = LANGUAGE_NAMES[languageCode];
  if (languageName) {
    return <div>{languageName}</div>;
  }
  return <div>{languageCode}</div>;
};
