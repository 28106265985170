import React from "react";
import { IssueReportsTable } from "../IssueReports/IssueReportsTable";
import { IssueReport } from "../types";

interface Props {
  reports?: IssueReport[];
}

export default function IssueReportsTab({ reports }: Props) {
  return <IssueReportsTable reports={reports} className="mt-3" />;
}
