import classNames from "classnames";
import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
import "highlight.js/styles/default.css";
import React, { useRef, useEffect, HTMLProps } from "react";
import { ExpandableCard } from "components/cards";

// "hljs-" is the default prefix. added "text-wrap" to cause long lines to break
hljs.configure({ classPrefix: "text-wrap hljs-" });
hljs.registerLanguage("xml", xml);

export function XML({
  className,
  children,
  ...rest
}: HTMLProps<HTMLPreElement>) {
  const highlightRef = useRef(null);

  useEffect(() => {
    if (highlightRef.current) {
      hljs.highlightElement(highlightRef.current);
    }
  }, [highlightRef.current]);

  return (
    <ExpandableCard>
      <pre {...rest} className={classNames("mb-0", className)}>
        <code className="language-xml" ref={highlightRef}>
          {children}
        </code>
      </pre>
    </ExpandableCard>
  );
}
