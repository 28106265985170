import React from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { mergeReducer } from "client/hooks";
import { request } from "client/utils";
import {
  getEmptyPromo,
  getPromotionChangeHandlers,
  isValidPromotion,
} from "../../Promo/PromoObject";
import PromoView from "../../Promo/PromoView";

function QuickCreatePromo(props) {
  const initialState = getEmptyPromo();
  initialState.promotionType = props.promotionType;
  const [promo, setPromo] = React.useReducer(mergeReducer, initialState);
  const onChange = React.useMemo(
    () => getPromotionChangeHandlers(setPromo),
    [setPromo]
  );

  const [loading, setLoading] = React.useState(false);

  const onCreate = (e) => {
    e.preventDefault();
    const payload = promo;
    console.log("payload sent", payload);
    setLoading(true);
    request("promotions/", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        props.onCreate(Number(response.value));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to create promotion", err, payload);
        toast.error("Failed to create promotion: " + err.message);
      });
  };

  const isValid = isValidPromotion(promo);

  return (
    <>
      <PromoView
        item={promo}
        onChangeHandlers={onChange}
        readOnly={false}
        hideFirstRow
      />
      <Button
        disabled={!isValid || loading}
        onClick={onCreate}
        variant="primary"
        size="sm"
        className="mt-3"
      >
        Create
      </Button>
    </>
  );
}

export default QuickCreatePromo;
