import { useState, useRef, useCallback, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { toast } from "react-toastify";
import { request } from "client/utils";

/**
 * Convenience hook for fetching
 * @param {string} endpoint api url
 * @param {{onSuccess, onError, blockRequests}} options
 * @param {GetRequestOptions} options
 *
 * @returns {[status, data, refreshData, dispatchAction]}
 */
export function useRequest(endpoint, options = {}, requestOptions) {
  const onSuccess = options.onSuccess || throwIfNoOnSuccess;
  const onError = options.onError || defaultOnErrorFunction;
  const beforeRequest = options.beforeRequest;
  const blockRequests = !!options.blockRequests;
  const [status, setStatus] = useState(
    !blockRequests ? STATUS.FETCHING : STATUS.OK
  );
  const fetchIdRef = useRef(0);

  const onFetch = useCallback(() => {
    const fetchId = ++fetchIdRef.current;

    if (beforeRequest) {
      beforeRequest();
    }

    setStatus(STATUS.FETCHING);
    request(endpoint, requestOptions)
      .then((result) => {
        if (fetchId !== fetchIdRef.current) return;
        unstable_batchedUpdates(() => {
          setStatus(STATUS.OK);
          onSuccess(result);
        });
      })
      .catch((err) => {
        setStatus(STATUS.ERROR);
        onError(err, endpoint);
      });
  }, [endpoint, beforeRequest, onSuccess, onError, requestOptions]);

  useEffect(() => {
    if (!blockRequests) onFetch();
  }, [endpoint, blockRequests]);

  return [status, onFetch];
}

export const STATUS = {
  FETCHING: Symbol("FETCHING"),
  OK: Symbol("OK"),
  ERROR: Symbol("ERROR"),
};

function throwIfNoOnSuccess() {
  throw new Error("Missing onSuccess function in useRequest hook");
}

function defaultOnErrorFunction(err) {
  toast.error("Failed to fetch: " + err.message);
}
