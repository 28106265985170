import update from "immutability-helper";
import { set } from "lodash";

/** Action.type for the defaultActionReducer */
export const ACTION = {
  /** Set state to action.value */
  SET: Symbol(),
  /** Shallow merge state with action.value */
  MERGE: Symbol(),
  /** Use immutability-helper to update(state, action.value) */
  UPDATE: Symbol(),
  /** Use immutability-helper to update state based on { op, path, value } */
  UPDATE_PATH: Symbol(),
};

/**
 * Shallow merges state with changes
 * @param {*} state
 * @param {*} changes
 */
export function mergeReducer(state, changes) {
  return { ...state, ...changes };
}

export function functionReducer(state, func) {
  return func(state);
}

/**
 * Reduces state and action.value as defined by action.type
 * If action is a function, it is called as action(state)
 * in order to generate the state
 * @param {object} state
 * @param {{ type, value } | function} action
 */
export function defaultActionReducer(state, action) {
  if (typeof action === "function") {
    action = action(state);
  }

  if (action.value === undefined)
    console.warn(
      "defaultActionReducer was passed an action with value undefined. If this was intended, consider using null instead."
    );

  switch (action.type) {
    case ACTION.SET:
      return action.value;
    case ACTION.MERGE:
      return mergeReducer(state, action.value);
    case ACTION.UPDATE:
      return update(state, action.value);
    case ACTION.UPDATE_PATH:
      // convert a path, op and value to the immutability-helper-format
      // eg { op: "$set", path: "a.b.c", value: "value "}
      // to { a: { b: { c: { $set: "value" } } } }
      return update(state, set({}, action.path, { [action.op]: action.value }));
    default:
      console.warn(
        "defaultActionReducer was passed an action without a valid type",
        state,
        action
      );
      return state;
  }
}
