import { formatPbString } from "@telia-company/tv.no-play-cms-common/api/util";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useMemo } from "react";
import { Row, Form } from "react-bootstrap";
import { Column } from "react-table-v7";
import { BooleanParam, useQueryParam } from "use-query-params";
import { ChannelsContext } from "client/App";
import { CLIENT_CONFIG } from "client/config";
import { Channels, IChannels } from "client/types";
import { AssetInfo } from "client/types/AssetInfo";
import { getPrimaryTitle, getPrimaryTopTitle } from "client/utils/AssetInfo";
import { AvailabilitiesBadges } from "client/views/Epg/LinearVideoDb/AvailabilitiesBadges";
import { LinkToMediaContentEditor } from "client/views/Epg/LinkToMediaContentEditor";
import { KindBadge } from "components/badges";
import Table from "components/tables";
import { Pagination } from "components/tables/Pagination";
import { LinkToAssetEditor } from "../LinkToAssetEditor";
import { LiveBadge } from "./LiveBadge";
import { RepeatBadge } from "./RepeatBadge";

const getColumns = (
  channels: Channels,
  showStoryline: boolean
): Column<AssetInfo>[] => {
  return [
    {
      Header: "Date",
      accessor: (row) => {
        return DateTime.fromISO(row.linear_start).toFormat("yyyy-MM-dd");
      },
    },
    {
      Header: "Start",
      accessor: (row) => {
        return DateTime.fromISO(row.linear_start).toFormat("HH:mm");
      },
    },
    {
      Header: "End",
      accessor: (row) => {
        return DateTime.fromISO(row.linear_end).toFormat("HH:mm");
      },
    },
    {
      Header: "Duration",
      accessor: (row) => {
        return row.availabilities.linear?.duration;
      },
    },
    {
      Header: "Title",
      accessor: (row) => {
        let numbering;
        if (row.seasonIndex && row.episodeIndex) {
          numbering = formatPbString(row.seasonIndex, row.episodeIndex) + " -";
        } else if (row.episodeIndex) {
          numbering =
            "E" + _.padStart(row.episodeIndex.toString(), 2, "0") + " -";
        } else {
          numbering = "";
        }
        return (
          <div>
            <LinkToMediaContentEditor
              id={row.topMcId}
              linkText={getPrimaryTopTitle(row) || "___"}
            />
            {" - "}
            <LinkToMediaContentEditor
              id={row.mcId}
              linkText={`${numbering} ${getPrimaryTitle(row)}`}
            />
          </div>
        );
      },
    },
    ...(showStoryline
      ? [
          {
            Header: "Storyline",
            accessor: (row: AssetInfo) => {
              return row.storyline;
            },
          },
        ]
      : []),
    {
      Header: "Channel",
      accessor: (row) =>
        channels.find((ch: IChannels) => ch.id === row.channel)?.value ||
        row.channel,
    },
    {
      Header: "Availabilities",
      accessor: (row) => {
        return <AvailabilitiesBadges availabilities={row.availabilities} />;
      },
    },
    {
      Header: "Live",
      accessor: (row) => {
        return <LiveBadge live={row.live} />;
      },
    },
    {
      Header: "Repeat",
      accessor: (row) => {
        return <RepeatBadge repeat={row.repeat} />;
      },
    },
    {
      Header: "Kind",
      accessor: (row) => {
        return (
          <>
            {row.topLevelKind && <KindBadge kind={row.topLevelKind} inline />}
            <KindBadge kind={row.kind} inline />
          </>
        );
      },
    },
    {
      Header: "Asset ID",
      accessor: (row) => {
        return <LinkToAssetEditor id={row.id} linkText={row.id} />;
      },
    },
    {
      Header: "MDC Link",
      accessor: (row) => {
        return (
          <a
            href={`${CLIENT_CONFIG.METADATA_COLLECTOR_ROOT}/asset/${row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            MDC
          </a>
        );
      },
    },
  ];
};

interface Props {
  tableRows: AssetInfo[];
  totalResults: number;
}

export const ProgramList = ({ tableRows, totalResults }: Props) => {
  const channels = useContext(ChannelsContext);

  const [showStoryline, setShowStoryline] = useQueryParam(
    "storyline",
    BooleanParam
  );

  const columns = useMemo(
    () => getColumns(channels, showStoryline !== false),
    [channels, showStoryline]
  );

  const toggleShowStoryline = useCallback(
    () => setShowStoryline((v) => v === false),
    [setShowStoryline]
  );

  return (
    <>
      <Row>
        <div className="d-flex justify-content-end">
          <Form.Label htmlFor="toggle-storyline">
            Show storyline column
          </Form.Label>
          <Form.Switch
            id="toggle-storyline"
            className="ms-2"
            checked={showStoryline !== false}
            onChange={toggleShowStoryline}
          />
        </div>
      </Row>
      <Table columns={columns} data={tableRows} />
      <Pagination totalResults={totalResults} />
    </>
  );
};
