import { DateTime } from "luxon";
import React from "react";

interface Props {
  prgServId?: number;
}

export const GNChannel = ({ prgServId }: Props) => {
  if (!prgServId) {
    return null;
  }

  const startDate = DateTime.now().startOf("week").toFormat("yyyy-MM-dd");
  const endDate = DateTime.now().endOf("week").toFormat("yyyy-MM-dd");
  const href = `https://gracenoteview.com/schedules/result?date_type=This%20week&start_date=${startDate}&end_date=${endDate}&station_ids=${prgServId}`;

  return <a href={href}>GN: {prgServId}</a>;
};
