import classNames from "classnames";
import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { request } from "client/utils";
import { MultiSelect, ProviderMultiPicker } from "components/pickers";
import Table, { ReactTablePagination } from "components/tables";

const DEFAULT_SORT_KEY = "updatedAt";
const DEFAULT_SORT_DIRECTION = "desc";
const KINDS_OPTIONS = [
  { value: "movie", label: "Movies" },
  { value: "series", label: "Series" },
];

const IMDbList = ({ endpoint, columns }) => {
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const loadPage = (page) => {
    if (page > 1) {
      params.set("page", page);
    } else {
      params.delete("page");
    }
    history.push({ pathname: location.pathname, search: params.toString() });
  };

  const getInitialSortBy = useCallback(() => {
    let sortKey = params.get("sort", DEFAULT_SORT_KEY);
    let direction = params.get("direction", DEFAULT_SORT_DIRECTION);

    const columnIds = columns
      .filter((c) => !c.disableSortBy)
      .map((c) => c.accessor);
    if (!columnIds.includes(sortKey)) {
      sortKey = DEFAULT_SORT_KEY;
    }

    if (!["desc", "asc"].includes(direction)) {
      direction = DEFAULT_SORT_DIRECTION;
    }

    return [{ id: sortKey, desc: direction === "desc" }];
  }, [params, columns]);

  const [sortBy, setSortBy] = useState(getInitialSortBy());
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState();
  const [kinds, setKinds] = useState([]);
  const [providerIds, setProviderIds] = useState([]);
  const totalPages = Math.ceil(totalCount / pageSize);

  let page;

  if (params.has("page")) {
    page = parseInt(params.get("page"), 10);
    if (Number.isNaN(page) || page < 1) {
      loadPage(1);
    }
  } else {
    page = 1;
  }

  const offset = (page - 1) * pageSize;

  const loadPageSize = (newPageSize) => {
    setLoading(true);
    setPageSize(newPageSize);
  };

  const onClick = React.useCallback(
    (match) => history.push(`/imdb/match/${match.id}`),
    []
  );

  const onChange = React.useCallback(
    ({ sortBy }) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sortKey = sortBy.length ? sortBy[0].id : DEFAULT_SORT_KEY;
    const desc = sortBy.length ? sortBy[0].desc : true;

    params.set("sort", sortKey);
    params.set("direction", desc ? "desc" : "asc");

    params.delete("kind");
    if (kinds) {
      for (const kind of kinds) {
        params.append("kind", kind);
      }
    }

    params.delete("provider_id");
    if (providerIds) {
      for (const id of providerIds) {
        params.append("provider_id", id);
      }
    }

    history.push({ pathname: location.pathname, search: params.toString() });
  }, [sortBy, kinds, providerIds]);

  useEffect(() => {
    const params = new URLSearchParams([
      ["offset", offset],
      ["limit", pageSize],
      ...kinds.map((kind) => ["kind", kind]),
      ...providerIds.map((id) => ["provider_id", id]),
    ]);

    if (sortBy.length > 0) {
      const { id: sortKey, desc } = sortBy[0];
      const sortDirection = desc ? "desc" : "asc";

      params.set("sort", sortKey);
      params.set("direction", sortDirection);
    }

    const url = `${endpoint}?${params.toString()}`;

    setLoading(true);
    request(url)
      .then(({ results, totalCount }) => {
        setContent(results || []);
        setTotalCount(totalCount);

        const newTotalPages = Math.ceil(totalCount / pageSize);
        if (page <= newTotalPages || (page === 1 && totalCount === 0)) {
          setLoading(false);
        } else {
          loadPage(newTotalPages);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [endpoint, kinds, providerIds, page, pageSize, offset, sortBy]);

  const firstItem = offset + 1;
  const lastItem = offset + content.length;

  return (
    <Container fluid className="p-4">
      <Row>
        <Col xs={3}>
          <MultiSelect
            value={kinds}
            options={KINDS_OPTIONS}
            onChange={setKinds}
            placeholder="Content kinds"
          />
        </Col>
        <Col xs={3}>
          <ProviderMultiPicker
            value={providerIds}
            onChange={setProviderIds}
            placeholder="Providers"
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-end text-muted pb-2">
          {totalCount
            ? `Showing items ${firstItem}–${lastItem} of ${totalCount}`
            : "No items to show"}
        </Col>
      </Row>
      <Row>
        <Col className={classNames("imdb-list", loading ? "loading" : null)}>
          <Table
            columns={columns}
            data={content}
            onClick={onClick}
            onChange={onChange}
            initialState={{ sortBy: getInitialSortBy() }}
            loading={loading}
            manualSortBy
            disableMultiSort
            disableSortRemove
          />
        </Col>
      </Row>
      <ReactTablePagination
        previousPage={() => loadPage(page - 1)}
        canPreviousPage={page > 1}
        pageIndex={page - 1}
        gotoPage={(p) => loadPage(p + 1)}
        totalPages={totalPages}
        pageSize={pageSize}
        setPageSize={loadPageSize}
        nextPage={() => loadPage(page + 1)}
        canNextPage={page < totalPages}
      />
    </Container>
  );
};

export default IMDbList;
