import React from "react";
import { Container, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useMsal, useUser } from "client/hooks";

export default function Login() {
  const { login } = useMsal();
  const user = useUser();

  if (user.isLoggedIn) {
    return <Redirect to={user.defaultRoute} />;
  }

  return (
    <Container>
      <h1>You have been logged out</h1>
      <Button variant="primary" onClick={login}>
        Log in again
      </Button>
    </Container>
  );
}
