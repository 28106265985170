import React, { useCallback, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useOnScreen, useSelection } from "client/hooks";
import { useMediaContentsWithSessionStorage } from "client/hooks/useMediaContents";
import SearchForm from "components/MediaContentDialog/SearchForm";
import { CmsCardContainer, ContentCard } from "components/cards";
import Pagination from "./Pagination";

const MediaContentDialog: React.FunctionComponent<{
  title?: string;
  selectText?: string;
  onSelect: (ids: number[]) => void;
  onClose: () => void;
  show: boolean;
  multiple?: boolean;
  initialState: { excludeKinds?: string[] };
  size?: "xl" | "sm" | "lg";
}> = ({
  title = "Search for and add content",
  selectText = "Add",
  onSelect,
  onClose,
  show,
  multiple = false,
  initialState = {},
  size: modalSize,
}) => {
  const {
    mediaContents,
    queryParams,
    size,
    setSize,
    isLoadingMore,
    isEmpty,
    isMoreAvailable,
    onChangeQueryParams,
  } = useMediaContentsWithSessionStorage(initialState, "mediaContentDialog");
  const { selection, resetSelection, toggleSelection } = useSelection<number>();

  const loader = useRef(null);
  const isVisible = useOnScreen(loader, show && isMoreAvailable);

  const onContentCardClick = useCallback(
    (id: number) => {
      if (!multiple) onSelect([id]);
      else toggleSelection(id);
    },
    [multiple]
  );
  const onSubmit = () => {
    onSelect(selection);
    resetSelection();
  };

  React.useEffect(() => {
    if (isVisible && isMoreAvailable) {
      setSize(size + 1);
    }
  }, [isVisible, isMoreAvailable]);

  return (
    <Modal size={modalSize || "xl"} autoFocus show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchForm
          excludeKinds={initialState.excludeKinds}
          value={queryParams}
          onChange={onChangeQueryParams}
        />
        <CmsCardContainer className="mt-4">
          {mediaContents.map((card) => (
            <ContentCard
              item={card}
              key={card.id}
              id={card.id}
              isAsset={false}
              selected={selection.includes(Number(card.id))}
              onClick={() => onContentCardClick(Number(card.id))}
              showLink={false}
              canClick
              zoomOnHover
            />
          ))}
        </CmsCardContainer>
        <div className="my-3 text-center text-secondary">
          <Pagination
            autoScroll={!multiple}
            loaderRef={loader}
            isMoreAvailable={isMoreAvailable}
            isLoadingMore={isLoadingMore}
            isEmpty={isEmpty}
            onLoadMoreClick={() => setSize(size + 1)}
          />
        </div>
      </Modal.Body>
      {multiple && (
        <Modal.Footer>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            {selectText}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default MediaContentDialog;
