import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

const DiffView = (props) => {
  return (
    <ReactDiffViewer
      compareMethod={DiffMethod.WORDS}
      styles={{
        // Word diffs have a default padding of 2px, which causes lines
        // with highlighted diffs to get horizontally misaligned with
        // the unchanged lines immediately before or after them, so
        // remove the word-diff padding to fix this.
        //
        // However, this causes single spaces wrapped in word-diff
        // <span>s to disappear, as word-diff has display: inline-flex
        // by default, which ignores spaces without surrounding text.
        // Work around this by setting display: inline for empty
        // word-diff <span>s, but revert to display:inline-flex for
        // added/removed words.
        //
        // See:
        //  * https://gitlab.getas.net/no.teliaplay/play-cms/-/issues/131
        //  * https://gitlab.getas.net/no.teliaplay/play-cms/-/issues/63
        //  * https://github.com/praneshr/react-diff-viewer/issues/101
        wordDiff: "padding: 0px; display: inline",
        wordAdded: "display: inline-flex",
        wordRemoved: "display: inline-flex",
      }}
      {...props}
    />
  );
};

DiffView.propTypes = ReactDiffViewer.propTypes;

export default DiffView;
