import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

export const ShowReportedToggle = () => {
  const [showReported, setShowReported] = useQueryParam(
    "showReported",
    BooleanParam
  );
  const resetPage = useResetPage();
  const selectShowReported = (newShowReported: true | null) => {
    setShowReported(newShowReported);
    resetPage();
  };

  return (
    <InputGroup>
      <Form.Check
        type="switch"
        id="search-filter-show-reported"
        checked={showReported || false}
        onChange={(e) => selectShowReported(e.target.checked || null)}
      />
      <Form.Label htmlFor="search-filter-show-reported">
        Show reported
      </Form.Label>
    </InputGroup>
  );
};
