import moment from "moment";
import { PropTypes } from "prop-types";
import React from "react";
import { FormControl } from "react-bootstrap";
import Datetime from "react-datetime";
import { GlyphButton } from "components/buttons";

const DATE_FORMAT = "YYYY-MM-DD";

const DateControl = ({
  canClear,
  className,
  value,
  size,
  readOnly,
  onChange,
  placeholder,
  ...rest
}) => {
  if (readOnly) {
    return (
      <FormControl
        value={moment(value).format(DATE_FORMAT)}
        readOnly
        size={size}
        className={className}
      />
    );
  }

  return (
    <div className="position-relative">
      <Datetime
        inputProps={{
          placeholder: placeholder,
        }}
        timeFormat={false}
        dateFormat={DATE_FORMAT}
        closeOnSelect
        value={moment.isMoment(value) ? moment(value) : undefined}
        size={size}
        className={className}
        onChange={onChange}
        {...rest}
      />
      {canClear && (
        <GlyphButton
          glyph="remove"
          variant="link"
          onClick={() => onChange(null)}
          size={size}
          className="position-absolute-right"
        />
      )}
    </div>
  );
};

DateControl.propTypes = {
  canClear: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DateControl.defaultProps = {
  canClear: false,
};

export default DateControl;
