import _ from "lodash";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import {
  DatabaseName,
  Program,
  ProgramAsset,
  ProgramAssetDTO,
  ProgramDTO,
  ProgramResponseDTO,
  TeliaPlayProgramDTO,
} from "../types";

function programAssetFromDTO(asset: ProgramAssetDTO): ProgramAsset {
  const { id } = asset.data;

  return {
    ...asset,
    id,
    linearStart: new Date(asset.linearStart),
    linearEnd: new Date(asset.linearEnd),
    catchupEnd: asset.catchupEnd ? new Date(asset.catchupEnd) : undefined,
  };
}

function programFromDTO(
  dto: ProgramDTO,
  topTitle: string,
  seasonIndex: number | undefined,
  assets: ProgramAssetDTO[]
): Program {
  const { mediaData } = dto;
  const { crid } = mediaData;

  return {
    crid,
    data: { ...mediaData, topTitle, seasonIndex },
    assets: assets.map(programAssetFromDTO),
  };
}

function programFromProgramResponseDTO(
  dto?: ProgramResponseDTO
): Program | undefined {
  if (!dto) {
    return undefined;
  }

  const { programs } = dto;

  // For most contents, the 'epg/program/:id' endpoint returns the program
  // and its ancestors in the 'programs' array, with the requested program
  // as the last element.  However, if a series is requested, the series is
  // the first element of the array and its seasons follow afterwards.
  //
  // As there is no way to select an individual season in the program
  // matching UI, we know that a season as the last element means that a
  // a series was requested and that we should return the first element in
  // the list.
  const topParent = programs[0];
  const last = programs[programs.length - 1];
  const program = last?.mediaData.kind === "season" ? topParent : last;

  const programsByCrid = _.keyBy(programs, (p) => p.mediaData.crid);
  const parent = program.parentCrid
    ? programsByCrid[program.parentCrid]
    : undefined;
  const seasonIndex =
    parent?.mediaData.kind === "season" ? parent.mediaData.index : undefined;

  const topTitle = topParent.mediaData.originalTitle;
  const assets = dto.assets.filter((a) => a.content === program.mediaData.crid);

  return programFromDTO(program, topTitle, seasonIndex, assets);
}

function useMetadataProgram(id: string) {
  const { data, error, isValidating, mutate } =
    useSWRImmutable<ProgramResponseDTO>(`epg/program/${id}`);
  const program = programFromProgramResponseDTO(data);
  const isLoading = isValidating || (!data && !error);

  return { program, error, isLoading, mutate };
}

export function useTeliaPlayProgram(id: string) {
  const { data, error, isValidating, mutate } = useSWR<TeliaPlayProgramDTO>(
    `mediacontent/${id}`
  );
  const program = data
    ? {
        ...data,
        assets: data.assets.map(programAssetFromDTO),
      }
    : undefined;
  const isLoading = isValidating || (!data && !error);
  return { program, error, isLoading, mutate };
}

export function useProgram(id: string, database: DatabaseName = "METADB") {
  switch (database) {
    case "METADB":
      return useMetadataProgram(id);
    case "TELIADB":
      return useTeliaPlayProgram(id);
  }
}
