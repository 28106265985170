import React from "react";
import { BadgeProps } from "react-bootstrap";
import { StatusBadge } from "components/badges";
import { DatabaseName } from "../types";

interface Props extends BadgeProps {
  db: DatabaseName;
}

function dbToVariant(db: string) {
  switch (db) {
    case "TELIADB":
      return "primary";
    case "METADB":
      return "success";
    default:
      return "light";
  }
}

export function DatabaseBadge({ db, ...rest }: Props) {
  return <StatusBadge status={db} statusToVariant={dbToVariant} {...rest} />;
}
