import { makeArray } from "@telia-company/tv.no-play-cms-common/api/util";
import autobind from "autobind-decorator";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { CmsPure } from "client/utils";
import { DownloadButton } from "components/buttons";
import DateTimeControl from "components/controls/datetime/DateTimeControl";

const mkDate = (d) => {
  if (typeof d === "string") {
    return d || undefined;
  }
  return d ? d.toISOString() : undefined;
};

const typeOptions = [
  { label: "Transactions: Rent only", value: "rent" },
  { label: "Transactions: Buy only", value: "buy" },
  { label: "Transactions: Rent + Buy", value: "all" },
];

const typeOptionsMap = _.fromPairs(typeOptions.map((t) => [t.value, t.label]));

const isValidType = (type) => typeOptions.map((t) => t.value).includes(type);

const TvodSearchFilter = (props) => {
  const getParams = queryString.parse(props.history.location.search);
  const fromDate = getParams.fromDate
    ? moment(getParams.fromDate)
    : moment().subtract(3, "days").hour(0).minute(0).second(0).millisecond(0);
  const toDate = getParams.toDate ? moment(getParams.toDate) : null;
  const type = isValidType(getParams.type) ? getParams.type : "all";

  const newProps = {
    providers: makeArray(getParams.providers),
    fromDate: fromDate && fromDate.isValid() ? fromDate : null,
    toDate: toDate && toDate.isValid() ? toDate : null,
    type,
    setUrlParams: (params) => {
      console.log(params);
      props.history.push({
        location: props.history.location.pathname,
        search: queryString.stringify(params),
      });
    },
    ...props,
  };
  return <TvodSearchFilterRender {...newProps} />;
};

class TvodSearchFilterRender extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      providers: props.providers,
      fromDate: props.fromDate,
      toDate: props.toDate,
      recordsCount: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  /**
   * Create and return a filter object from the current states.
   * @returns filters current filters defined in state.
   */
  createFilter() {
    const { fromDate, toDate, providers, type } = this.state;
    const filters = { type };

    if (fromDate) {
      filters.fromDate = mkDate(fromDate);
    }
    if (toDate) {
      filters.toDate = mkDate(toDate);
    }
    if (providers.length > 0) {
      filters.providers = providers.map((p) => Number(p));
    }

    return filters;
  }

  /**
   * Update selected providers
   * @param {{value: string, label: string}[]} providers selected providers array.
   */
  @autobind
  onProviderChange(providers) {
    this.setState({ providers: (providers || []).map((p) => p.value) });
  }

  /**
   * Update selected from date.
   * @param {Date} date moment date object.
   */
  @autobind
  onFromChange(fromDate) {
    this.setState({ fromDate });
  }

  /**
   * Update selected to date.
   * @param {Date} date moment date object.
   */
  @autobind
  onToChange(toDate) {
    this.setState({ toDate });
  }

  @autobind
  onTypeChange(type) {
    this.setState({ type: type.value });
  }

  @autobind
  async getData() {
    this.props.setUrlParams({
      type: this.state.type,
      providers: this.state.providers,
      fromDate: mkDate(this.state.fromDate),
      toDate: mkDate(this.state.toDate),
    });
    const recordsCount = await this.props.getData(this.createFilter());
    this.setState({ recordsCount });
  }

  providerLabel(providerId) {
    const entry = this.props.providerOptions.find((p) => p.value == providerId);
    return entry ? entry.label : "N/A";
  }

  render() {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col xs={8}>
          <Row>
            <Col xs={12}>
              <Select
                closeMenuOnSelect={false}
                isMulti
                onChange={this.onProviderChange}
                options={this.props.providerOptions}
                placeholder="Specify providers?"
                hideSelectedOptions={true}
                value={this.state.providers.map((pId) => ({
                  value: Number(pId),
                  label: this.providerLabel(pId),
                }))}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col xs={4}>
              <DateTimeControl
                placeholder="From"
                closeOnSelect
                value={this.state.fromDate}
                onChange={this.onFromChange}
                acceptEmpty
              />
            </Col>
            <Col xs={4}>
              <DateTimeControl
                placeholder="To"
                closeOnSelect
                value={this.state.toDate}
                onChange={this.onToChange}
                acceptEmpty
              />
            </Col>
            <Col xs={4}>
              <Select
                placeholder="Type"
                options={typeOptions}
                value={{
                  value: this.state.type,
                  label: typeOptionsMap[this.state.type],
                }}
                onChange={this.onTypeChange}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={2}>
          <Button style={{ width: "100%" }} onClick={this.getData}>
            FETCH
          </Button>
        </Col>
        <Col xs={2}>
          {/**
           * Download an excel file export of filtered data.
           * - Filename pattern: "YYYY-MM-DD.xlsx"
           */}
          <DownloadButton
            variant="primary"
            path={`tvod/report?${queryString.stringify(this.createFilter())}`}
          >
            {this.state.recordsCount
              ? `${this.state.recordsCount} records`
              : ""}
          </DownloadButton>
        </Col>
      </Row>
    );
  }
}

export default withRouter(TvodSearchFilter);
