import { useContext } from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import { ChannelsContext } from "client/App";
import { IChannels } from "client/types";

export const useResetPage = () => {
  const [, setPage] = useQueryParam("page", NumberParam);

  return () => setPage(null);
};

export const useChannelOptions = () => {
  const allChannels = useContext(ChannelsContext);
  return allChannels.map(buildSelectOption);
};

const buildSelectOption = (object: IChannels) => ({
  value: Number(object.id),
  label: object.value,
});
