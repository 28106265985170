import React from "react";
import { Form } from "react-bootstrap";
import { FieldProps } from "./types";

export const PrgServIDField = ({ state, applyPatch }: FieldProps) => {
  return (
    <>
      <Form.Label htmlFor="prg-serv-id">PrgServID</Form.Label>
      <Form.Control
        type="text"
        id="prg-serv-id"
        value={state.prgServId || ""}
        onChange={(e) => applyPatch({ prgServId: parseInt(e.target.value) })}
      />
    </>
  );
};
