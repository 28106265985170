import React from "react";

const sourceAnnotationMap = {
  original: "external",
  patched: "internal",
  deleted: "deleted",
};

const sourceClassMap = {
  original: "text-success",
  patched: "text-warning",
  deleted: "text-danger",
};

function getAnnotation(source, isModified) {
  if (isModified) {
    if (source === "deleted") {
      return (
        <span>
          deleted <span className="text-muted">(unsaved)</span>
        </span>
      );
    } else {
      return "unsaved";
    }
  }

  return sourceAnnotationMap[source];
}

const SourceAnnotation = ({
  source,
  isModified,
  isReverted,
  onRevert,
  onRestore,
}) => {
  const annotation = getAnnotation(source, isModified);
  const className = isModified ? "text-danger" : sourceClassMap[source];

  return (
    <div className="text-end">
      {!isModified && <span className="text-muted">source: </span>}
      <span className={className}>{annotation}</span>
      {source === "patched" && !isReverted && onRevert && (
        <>
          <span className="text-muted"> | </span>
          <span className="text-danger cursor-pointer" onClick={onRevert}>
            revert
          </span>
        </>
      )}
      {source === "deleted" && onRestore && !isModified && (
        <>
          <span className="text-muted"> | </span>
          <span className="text-success cursor-pointer" onClick={onRestore}>
            restore
          </span>
        </>
      )}
    </div>
  );
};

export default SourceAnnotation;
