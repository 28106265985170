export type DatabaseName = "TELIADB" | "METADB";

interface ProgramMatchBase<D> {
  id: number;
  kind: string | null; // null should never happen, but apparently still does
  from: string;
  fromDatabase: DatabaseName;
  fromTitle: string;
  to: string;
  toDatabase: DatabaseName;
  toTitle: string;
  isDominant: boolean;
  matchCreatedAt: D;
  matchUpdatedAt: D;
}

export type ProgramMatchDTO = ProgramMatchBase<string>;
export type ProgramMatchEntity = ProgramMatchBase<Date>;

export function programMatchFromDTO(dto: ProgramMatchDTO): ProgramMatchEntity {
  return {
    ...dto,
    matchCreatedAt: new Date(dto.matchCreatedAt),
    matchUpdatedAt: new Date(dto.matchUpdatedAt),
  };
}
