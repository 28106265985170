export function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "season.changed":
      return init(state.readOnly);
    case "season.request.initialized":
      return { ...state, loading: true };
    case "season.request.success":
      return {
        ...state,
        loading: false,
        autoRefreshing: false,
        season: payload,
      };
    case "season.request.error":
      return {
        ...state,
        loading: false,
        autoRefreshing: false,
        error: payload,
      };
    case "season.updateRequest.initialized":
      return {
        ...state,
        seasonCard: {
          ...state.seasonCard,
          saving: true,
        },
      };
    case "season.updateRequest.success":
      return {
        ...state,
        season: {
          ...state.season,
          dirty: true,
        },
        seasonCard: {
          ...state.seasonCard,
          editing: false,
          saving: false,
        },
      };
    case "season.updateRequest.error":
      return {
        ...state,
        seasonCard: {
          ...state.seasonCard,
          error: payload,
          saving: false,
        },
      };
    case "season.autoRefreshRequested":
      return {
        ...state,
        autoRefreshing: true,
      };
    case "matchFilter.updated":
      return {
        ...state,
        season: {
          ...state.season,
          dirty: true,
        },
        matchFilter: payload,
      };
    case "match.selected":
      return {
        ...state,
        selectedMatch: payload,
        selectedTeam: {},
      };
    case "team.selected":
      return {
        ...state,
        selectedTeam: payload,
      };
    case "team.deselected":
      return {
        ...state,
        selectedTeam: {},
      };
    case "team.reloaded":
      return {
        ...state,
        season: {
          ...state.season,
          dirty: true,
        },
        selectedTeam: payload,
      };
    case "seasonCard.editing.enabled":
      return {
        ...state,
        seasonCard: {
          ...state.seasonCard,
          editing: true,
        },
      };
    case "seasonCard.editing.cancel":
      return {
        ...state,
        seasonCard: {
          ...state.seasonCard,
          editing: false,
        },
      };
    case "seasonCard.selectedContentIds.changed": {
      return {
        ...state,
        seasonCard: {
          ...state.seasonCard,
          selectedContentIds: payload,
          mediaContentDialog: {
            ...state.seasonCard.mediaContentDialog,
            isOpen: false,
          },
        },
      };
    }
    case "seasonCard.mediaContentDialog.open":
      return {
        ...state,
        seasonCard: {
          ...state.seasonCard,
          mediaContentDialog: {
            ...state.seasonCard.mediaContentDialog,
            ...payload,
            isOpen: true,
          },
        },
      };
    case "seasonCard.mediaContentDialog.close":
      return {
        ...state,
        seasonCard: {
          ...state.seasonCard,
          mediaContentDialog: {
            initialQuery: "",
            isOpen: false,
          },
        },
      };
    case "matchCard.mappingDialog.open":
      return {
        ...state,
        matchCard: {
          ...state.matchCard,
          mappingDialog: {
            ...state.matchCard.mappingDialog,
            initialQuery: payload.initialQuery,
            isOpen: true,
          },
        },
      };
    case "matchCard.mappingDialog.close":
      return {
        ...state,
        matchCard: {
          ...state.matchCard,
          mappingDialog: {
            ...state.matchCard.mappingDialog,
            isOpen: false,
          },
        },
      };
    case "mapping.updated":
      return {
        ...state,
        selectedMatch: {
          ...state.selectedMatch,
          mapping: {
            ...state.selectedMatch.mapping,
            matchId: state.selectedMatch.id,
            asset: payload.asset,
            assetId: payload.assetId,
            contentId: payload.contentId,
            dirty: true,
          },
        },
      };
    case "mapping.reloaded":
      return {
        ...state,
        season: {
          ...state.season,
          dirty: true,
        },
        selectedMatch: {
          ...state.selectedMatch,
          mapping: payload,
        },
        matchCard: {
          ...state.matchCard,
          mappingDialog: {
            ...state.matchCard.mappingDialog,
            isOpen: false,
          },
        },
      };
  }
}

export const initialState = {
  readOnly: true,
  season: {
    matches: [],
  },
  matchFilter: {
    excludePlayed: true,
  },
  selectedMatch: {},
  selectedTeam: {},
  seasonCard: {
    editing: false,
    mediaContentDialog: {
      initialQuery: "",
      isOpen: false,
    },
    selectedContentIds: undefined,
    saving: false,
  },
  matchCard: {
    mappingDialog: {
      initialQuery: "",
      isOpen: false,
    },
  },
  loading: true,
  autoRefreshing: false,
  error: undefined,
};

export const init = (readOnly) => ({ ...initialState, readOnly });
