import React from "react";
import { BadgeProps } from "react-bootstrap";
import { StatusBadge } from "components/badges";

interface Props extends BadgeProps {
  status: string;
}

function statusToVariant(status: string) {
  switch (status) {
    case "SYNCED":
      return "success";
    case "MATCHED":
      return "primary";
    case "LANDED":
      return "secondary";
    case "NOT_MATCHED":
      return "danger";
    default:
      return "light";
  }
}

export function MetadataStatusBadge({ status }: Props) {
  return (
    <StatusBadge
      status={status.toUpperCase()}
      statusToVariant={statusToVariant}
    />
  );
}
