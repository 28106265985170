import React, { useRef, useState, useCallback } from "react";
import { Card, CardProps } from "react-bootstrap";
import { useOnScreen } from "client/hooks";
import { GlyphButton } from "components/buttons";

interface Props extends CardProps {
  collapsedHeight?: string;
}

export function ExpandableCard({
  children,
  collapsedHeight = "300px",
  ...rest
}: Props) {
  const ref = useRef(null);
  const isBottomVisible = useOnScreen(ref);
  const [isExpanded, setIsExpanded] = useState(false);

  const expand = useCallback(() => setIsExpanded(true), [setIsExpanded]);
  const collapse = useCallback(() => setIsExpanded(false), [setIsExpanded]);
  const style = isExpanded ? undefined : { maxHeight: collapsedHeight };

  return (
    <Card {...rest}>
      <div style={style} className="overflow-hidden">
        {children}
        <div ref={ref} />
      </div>
      {!isBottomVisible && (
        <Card.Footer>
          <GlyphButton
            glyph="show_more"
            variant="success"
            size="sm"
            onClick={expand}
          >
            Show more
          </GlyphButton>
        </Card.Footer>
      )}
      {isExpanded && (
        <Card.Footer>
          <GlyphButton
            glyph="show_less"
            variant="success"
            size="sm"
            onClick={collapse}
          >
            Show less
          </GlyphButton>
        </Card.Footer>
      )}
    </Card>
  );
}
