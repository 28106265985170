import React from "react";
import { numberOrStringPropType } from "client/utils";
import DefaultCmsCard from "./DefaultCmsCard";

const FootballEventCard = ({ leagueId, seasonId, ...props }) => (
  <DefaultCmsCard
    {...props}
    linkTo={`/sports/leagues/${leagueId}/seasons/${seasonId}`}
    variant="landscape"
  />
);

FootballEventCard.propTypes = {
  seasonId: numberOrStringPropType.isRequired,
  leagueId: numberOrStringPropType.isRequired,
};

export default FootballEventCard;
