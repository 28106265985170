import React from "react";
import { ProviderMultiPicker } from "./ProviderMultiPicker";

interface Props {
  onChange: (newValue: number | null) => void;
  placeholder?: string;
  value?: number;
  disabled?: boolean;
}

export const ProviderPicker = ({
  onChange,
  placeholder = "Find provider by name...",
  value,
  disabled = false,
  ...rest
}: Props) => (
  <ProviderMultiPicker
    id="providers"
    multiple={false}
    value={value ? [value] : []}
    placeholder={placeholder}
    onChange={(providers) => onChange(providers[0] || null)}
    disabled={disabled}
    {...rest}
  />
);
