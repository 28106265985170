import _ from "lodash";
import React, { useState, useCallback } from "react";
import BooleanConfigurationValue from "./BooleanConfigurationValue";
import StringConfigurationValue from "./StringConfigurationValue";
import { getValue } from "./utils";

function ConfigurationValue({ value: rawValue, configKey, updateConfig }) {
  const value = getValue(rawValue);
  const [isLoading, setIsLoading] = useState(false);

  const submit = useCallback(
    (newValue) => {
      setIsLoading(true);
      updateConfig(configKey, newValue)
        .then(() => setIsLoading(false))
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    },
    [configKey, setIsLoading, updateConfig]
  );

  if (_.isBoolean(value)) {
    return (
      <BooleanConfigurationValue
        configKey={configKey}
        value={value}
        submit={submit}
        isLoading={isLoading}
      />
    );
  }

  return (
    <StringConfigurationValue
      value={value}
      submit={submit}
      isLoading={isLoading}
    />
  );
}

export default ConfigurationValue;
