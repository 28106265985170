import React from "react";
import { Accordion } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";
import { BulkCreateSeEp } from "./BulkCreateSeEp";
import { MergeContent } from "./MergeContent";

export const Maintenance = () => {
  const [openItem, setOpenItem] = useQueryParam("openItem", StringParam);

  return (
    <Accordion
      activeKey={openItem}
      onSelect={(selected) =>
        setOpenItem(selected as string | null | undefined)
      }
    >
      <Accordion.Item eventKey="merge-content">
        <Accordion.Header>
          <h2>Merge content</h2>
        </Accordion.Header>
        <Accordion.Body>
          <MergeContent />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="bulk-create-se-ep">
        <Accordion.Header>
          <h2>Bulk create SeEp</h2>
        </Accordion.Header>
        <Accordion.Body>
          <BulkCreateSeEp />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
