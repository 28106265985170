import React, { useState, useCallback, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { SingleSelect } from "client/components/pickers";

const AddLanguageDialog = ({ languages, dispatch }) => {
  const options = Object.entries(languages).map(([value, label]) => ({
    value,
    label,
  }));
  options.push({ value: "other", label: "Other" });

  const [selectedLanguage, setSelectedLanguage] = useState(undefined);
  const [customLanguageCode, setCustomLanguageCode] = useState("");

  const isCustomLanguageValid = useCallback(
    () => customLanguageCode.match(/^[a-z]{2}$/),
    [customLanguageCode]
  );

  const addLanguage = useCallback(
    (languageCode) => {
      setSelectedLanguage(undefined);
      setCustomLanguageCode("");

      dispatch({
        type: "language.added",
        payload: { languageCode },
      });
    },
    [setSelectedLanguage, setCustomLanguageCode, dispatch]
  );

  useEffect(() => {
    // Add the new language immediately when selecting one of the
    // predefined language options
    if (selectedLanguage && selectedLanguage !== "other") {
      addLanguage(selectedLanguage);
    }
  }, [addLanguage, selectedLanguage]);

  const updateCustomLanguageCode = useCallback(
    (e) => {
      const { value } = e.target;
      console.log({ value });
      if (value.match(/^[a-z]{0,2}$/)) {
        setCustomLanguageCode(value);
      }
    },
    [setCustomLanguageCode]
  );

  const addCustomLanguage = useCallback(
    () => addLanguage(customLanguageCode),
    [addLanguage, customLanguageCode]
  );

  return (
    <div className="d-flex">
      <Form.Group>
        <Form.Label>Select a language to add</Form.Label>
        <SingleSelect
          options={options}
          value={selectedLanguage}
          onChange={setSelectedLanguage}
        />
      </Form.Group>
      {selectedLanguage === "other" && (
        <>
          <Form.Group className="mx-4">
            <Form.Label>Language code</Form.Label>
            <Form.Control
              type="text"
              value={customLanguageCode}
              onChange={updateCustomLanguageCode}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-column justify-content-end">
            <Button
              onClick={addCustomLanguage}
              disabled={!isCustomLanguageValid()}
            >
              Add language
            </Button>
          </Form.Group>
        </>
      )}
    </div>
  );
};

export default AddLanguageDialog;
