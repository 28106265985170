import { PropTypes } from "prop-types";
import React from "react";
import { Row, Col, FormLabel, FormGroup } from "react-bootstrap";
import momentPropTypes from "react-moment-proptypes";
import { CmsPure } from "client/utils";
import DateTimeControl from "components/controls/datetime/DateTimeControl";

const HelpBlock = (props) => (
  <span className="help-block">{props.children}</span>
);

export default class DateTimeRangeControl extends CmsPure {
  render() {
    const {
      publish,
      unpublish,
      onPublishChange,
      onUnpublishChange,
      large,
      ...rest
    } = this.props;
    return (
      <Row>
        <Col as={FormGroup} xs={6}>
          <FormLabel hidden={!large}>Publish on</FormLabel>
          <DateTimeControl
            placeholder="Publish time"
            value={publish}
            onChange={onPublishChange}
            acceptEmpty={false}
            {...rest}
          />
          {large && <HelpBlock>Oslo local time</HelpBlock>}
        </Col>
        <Col as={FormGroup} xs={6}>
          <FormLabel hidden={!large}>Unpublish on</FormLabel>
          <DateTimeControl
            placeholder="Unpublish time"
            value={unpublish}
            onChange={onUnpublishChange}
            acceptEmpty={false}
            {...rest}
          />
          {large && <HelpBlock>Oslo local time</HelpBlock>}
        </Col>
      </Row>
    );
  }
}

DateTimeRangeControl.propTypes = {
  large: PropTypes.bool,
  publish: momentPropTypes.momentObj,
  unpublish: momentPropTypes.momentObj,
  onPublishChange: PropTypes.func.isRequired,
  onUnpublishChange: PropTypes.func.isRequired,
};
