import React from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { CmsPure } from "client/utils";
import { getComposerTitle } from "../provisioningUtils";
import ProvisionerIdPicker from "./ProvisionerIdPicker";

const LABEL = "DVB Cable Plan";

class DVBCablePlansComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      dvbCableStreamId: "",
      dvbTripletId: "",
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) return;

    this.props.get(id, (state) => this.setState(state));
  }

  create = (event) => {
    event.preventDefault();
    const payload = this.state;
    this.props.put(payload, this.props.id, LABEL);
  };

  isValid = () => {
    const { dvbCableStreamId, dvbTripletId } = this.state;
    return dvbCableStreamId && dvbTripletId;
  };

  render() {
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{title}</h1>
        <ProvisionerIdPicker
          contentType="DVB Triplet"
          selectedId={this.state.dvbTripletId}
          onSelect={(val) => this.setState({ dvbTripletId: val })}
        />
        <ProvisionerIdPicker
          contentType="DVB Cable Stream"
          selectedId={this.state.dvbCableStreamId}
          onSelect={(val) => this.setState({ dvbCableStreamId: val })}
        />
        <Row>
          <Col>
            <Form.Group className="my-2">
              <Form.Label>CA descriptor private data</Form.Label>
              <InputGroup>
                <Form.Control
                  type="string"
                  value={this.state.caDescriptorPrivateData}
                  placeholder="CA-system specific bytes"
                  onChange={(e) =>
                    this.setState({ caDescriptorPrivateData: e.target.value })
                  }
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Button
          className="pull-right mt-4"
          variant="primary"
          type="submit"
          disabled={!this.isValid()}
        >
          {this.props.mode}
        </Button>
      </Form>
    );
  }
}

export default DVBCablePlansComposer;
