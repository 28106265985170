import { extractTitle } from "@telia-company/tv.no-play-cms-common/api/MediaContentUtils";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { getValidDateString } from "client/utils";
import Table from "components/tables";
import { displayName } from "./utils";

const SeasonsTable = ({ seasons, loading }) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: "League",
        accessor: "competition",
        Cell: ({ value }) => displayName(value),
      },
      {
        Header: "Season",
        accessor: (calendar) => displayName(calendar),
      },
      {
        Header: "Country",
        accessor: "competition.country.name",
      },
      {
        Header: "Matches played",
        accessor: "completedMatches",
        className: "compact",
      },
      {
        Header: "Matches remaining",
        accessor: "remainingMatches",
        className: "compact",
      },
      {
        Header: "Start date",
        accessor: "startDate",
        className: "compact",
        Cell: ({ value }) => getValidDateString(value),
      },
      {
        Header: "End date",
        accessor: "endDate",
        className: "compact",
        Cell: ({ value }) => getValidDateString(value),
      },
      {
        Header: "Media content",
        accessor: "editorial.contents.0.data",
        Cell: ({ value }) =>
          value ? `${extractTitle(value)} (${value.id})` : null,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      seasons.map((s) => (s.active ? s : { ...s, className: "text-muted" })),
    [seasons]
  );

  const onClick = useCallback((season) => {
    const { id, competition } = season;
    const url = `/sports/leagues/${competition.id}/seasons/${id}`;
    history.push(url);
  }, []);

  return (
    <Table
      loading={loading}
      className="mb-0"
      columns={columns}
      data={data}
      onClick={onClick}
    />
  );
};

export default SeasonsTable;
