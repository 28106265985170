import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "client/hooks";
import { ContentCardWrapper } from "components/cards";

const ContentLinks = ({ parentId }) => {
  const [contents, setContents] = useState([]);
  const history = useHistory();

  useRequest(`mediacontent/${parentId}/children`, {
    onSuccess: setContents,
  });

  const style = {
    gridTemplateColumns: `repeat(${contents.length}, 150px)`,
    overflow: "scroll",
  };

  return (
    <div className="mt-3 cms-card-container" style={style}>
      {contents.map(({ id, data }) => {
        const { index, kind } = data || {};
        const onClick = () => {
          const url = `/enrich/mediacontent/editor/${id}`;
          history.push(url);
        };

        const classes = ["truncate"];
        if (kind === "season") {
          classes.push("center-title");
        }

        return (
          <ContentCardWrapper
            key={id}
            id={id}
            isAsset={false}
            className={classNames(classes)}
            titlePrefix={
              kind === "episode" && index !== undefined
                ? index.toString()
                : undefined
            }
            title={
              kind === "season" && index !== undefined
                ? index.toString()
                : undefined
            }
            onClick={onClick}
            canClick
            hideDescription
          />
        );
      })}
    </div>
  );
};

export default ContentLinks;
