import React from "react";
import { FormGroup, FormLabel, Row, Col, Tabs, Tab } from "react-bootstrap";
import { PublicationTypes } from "client/types/PublicationTypes";
import { CmsPure } from "client/utils";
import TitleFormControl from "components/controls/TitleFormControl";
import CloudImageControl from "components/image/CloudImageControl";
import PromoLinkToComponent from "./PromoLinkToComponent";
import StatusComponent from "./StatusComponent";
import TopPromoGraphicsComponent from "./TopPromoGraphicsComponent";

class PromoView extends CmsPure {
  render() {
    const { item, onChangeHandlers, readOnly, hideFirstRow } = this.props;
    return (
      <>
        <TitleFormControl
          title={item.title}
          placeholder="Title of promotion"
          readOnly={readOnly}
          onChangeTitle={onChangeHandlers.title}
        />
        {!hideFirstRow && (
          <Row className="py-2">
            <Col />
            <Col xs="auto">
              <StatusComponent
                enabled={item.enabled}
                activeContent={item.activeContent}
                onChange={onChangeHandlers.enabled}
                type="promotion"
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <h2>Promotion Type</h2>
            <Tabs
              id="promotion-tabs"
              activeKey={item.promotionType}
              onSelect={onChangeHandlers.promotionType}
              mountOnEnter
            >
              <Tab eventKey={PublicationTypes.COVER_PROMO} title="Card">
                <FormGroup className="w-50">
                  <FormLabel className="lead">Promotion Cover</FormLabel>
                  <CloudImageControl
                    disabled={readOnly}
                    type="promotion"
                    width={576}
                    height={864}
                    value={item.cover}
                    onChange={onChangeHandlers.cover}
                  />
                </FormGroup>
              </Tab>
              <Tab
                eventKey={PublicationTypes.TOP_PROMO}
                title="Front Page Banner"
              >
                <TopPromoGraphicsComponent
                  readOnly={readOnly}
                  item={item}
                  onChangeHandlers={onChangeHandlers}
                />
              </Tab>
            </Tabs>
          </Col>
          <Col xs={{ span: 5, offset: 1 }}>
            <PromoLinkToComponent
              readOnly={readOnly}
              item={item}
              onChangeHandlers={onChangeHandlers}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default PromoView;
