import { DateTime } from "luxon";
import moment from "moment/moment";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useStartAndEndDates } from "client/views/Epg/hooks/useStartAndEndDates";
import DateControl from "components/controls/datetime/DateControl";

export const DateRangePicker = () => {
  const { startDate, setStartDate, endDate, setEndDate } =
    useStartAndEndDates();

  const stepBack = () => {
    setStartDate(
      DateTime.fromFormat(startDate, "yyyy-MM-dd")
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd")
    );
    setEndDate(
      DateTime.fromFormat(endDate, "yyyy-MM-dd")
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd")
    );
  };
  const stepForward = () => {
    setEndDate(
      DateTime.fromFormat(endDate, "yyyy-MM-dd")
        .plus({ days: 1 })
        .toFormat("yyyy-MM-dd")
    );
    setStartDate(
      DateTime.fromFormat(startDate, "yyyy-MM-dd")
        .plus({ days: 1 })
        .toFormat("yyyy-MM-dd")
    );
  };
  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <Form.Label>&nbsp;</Form.Label>
          <br />
          <Button className="me-1" onClick={stepBack}>
            <i className="fa fa-arrow-left"></i>
          </Button>
        </div>
        <DatePicker
          date={startDate}
          setDate={setStartDate}
          label="Start date"
        />
        &nbsp;
        <DatePicker date={endDate} setDate={setEndDate} label="End date" />
        <div>
          <Form.Label>&nbsp;</Form.Label>
          <br />
          <Button className="ms-1" onClick={stepForward}>
            <i className="fa fa-arrow-right"></i>
          </Button>
        </div>
      </div>
    </>
  );
};

interface DatePickerProps {
  date: string;
  setDate: (newDate: string) => void;
  label: string;
}

export const DatePicker = ({ date, setDate, label }: DatePickerProps) => {
  return (
    <div>
      <Form.Label>{label}</Form.Label>
      <DateControl
        onChange={(newDate: moment.Moment) =>
          setDate(newDate.format("YYYY-MM-DD"))
        }
        canClear={false}
        className={undefined}
        value={moment(date)}
        size={undefined}
        readOnly={undefined}
        placeholder={undefined}
        inputProps={{ readOnly: true }}
      />
    </div>
  );
};
