import React from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom-v5-compat";
import { ProgramChildren } from "../ProgramDetails/ProgramChildren";
import { ProgramDetails } from "../ProgramDetails/ProgramDetails";
import { MatchDetails } from "./MatchDetails";

interface Props {
  fromMatches: FromMatch[];
  selectedFromId?: string;
  toId: string;
  mutate: () => void;
  path: string;
}

interface FromMatch {
  from: string;
  matchId: number;
  isDominant: boolean;
}

export const ManyToOneProgramMatch = ({
  fromMatches,
  selectedFromId,
  toId,
  mutate,
  path,
}: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectFromId = (newFromId: string | string[] | null | undefined) => {
    if (!newFromId) {
      navigate({
        pathname: `/epg/matches/to/${toId}`,
        search: `?${createSearchParams(searchParams)}`,
      });
      return;
    }
    if (Array.isArray(newFromId)) {
      // TODO(alvin): figure out if this can ever happen
      newFromId = newFromId[0];
    }
    navigate({
      pathname: `/epg/matches/from/${newFromId}/to/${toId}`,
      search: `?${createSearchParams(searchParams)}`,
    });
  };
  return (
    <Row>
      <Col xs={6}>
        <Accordion
          activeKey={selectedFromId}
          onSelect={(e) => {
            selectFromId(e);
          }}
        >
          {fromMatches.map(({ from, matchId, isDominant }) => (
            <MatchDetails
              fromId={from}
              toId={toId}
              matchId={matchId}
              key={matchId}
              selectedFromId={selectedFromId}
              isDominant={isDominant}
              mutate={mutate}
              path={path}
            />
          ))}
        </Accordion>
      </Col>
      <Col xs={6}>
        <Card>
          <Card.Body>
            <ProgramDetails id={toId} database="TELIADB" />
            <ProgramChildren id={toId} database="TELIADB" />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
