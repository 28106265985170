import React from "react";
import { Row, Col } from "react-bootstrap";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import KeyValuePair from "components/KeyValuePair";
import LoadingOverlayWrapper from "components/LoadingOverlayWrapper";
import StyledJSONTree from "components/StyledJSONTree";
import { XML } from "components/code";
import { AmsErrorDetails } from "../AmsErrorDetails";
import { AmsRequestError } from "../AmsRequestError";
import {
  ParsedRevisionMetadataSource,
  useRevisionMetadataSource,
} from "../hooks";

interface Props {
  id: number;
  locator: string;
}

export default function RevisionMetadataSource({ id, locator }: Props) {
  const { metadataSource, error, isLoading } = useRevisionMetadataSource(id);

  return (
    <LoadingOverlayWrapper show={isLoading}>
      {isLoading && !error && !metadataSource && (
        <RevisionMetadataSourceLoadingIndicator />
      )}
      {error && <RevisionMetadataSourceError error={error} locator={locator} />}
      {metadataSource && (
        <RevisionMetadataSourceDetails
          metadataSource={metadataSource}
          locator={locator}
        />
      )}
    </LoadingOverlayWrapper>
  );
}

interface RevisionMetadataSourceErrorProps {
  error: AmsRequestError;
  locator: string;
}

function RevisionMetadataSourceError({
  error,
  locator,
}: RevisionMetadataSourceErrorProps) {
  return (
    <>
      <Row>
        <Col>
          <KeyValuePair id="Locator" labelWidth={1} value={locator} />
        </Col>
      </Row>
      <Row>
        <Col xs={{ offset: 1, span: 10 }}>
          <div className="text-muted">Could not load metadata source:</div>
          <AmsErrorDetails error={error} />
        </Col>
      </Row>
    </>
  );
}

function RevisionMetadataSourceLoadingIndicator() {
  return (
    <Row>
      <Col xs={{ offset: 1 }}>Loading...</Col>
    </Row>
  );
}

interface RevisionMetadataSourceDetailsProps {
  metadataSource: ParsedRevisionMetadataSource;
  locator: string;
}

function RevisionMetadataSourceDetails({
  metadataSource,
  locator,
}: RevisionMetadataSourceDetailsProps) {
  return (
    <>
      <Row>
        <Col>
          <KeyValuePair
            id="Updated"
            labelWidth={1}
            value={parseDateToDatetimeString(metadataSource.updated)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <KeyValuePair id="Locator" labelWidth={1} value={locator} />
        </Col>
      </Row>
      <Row>
        <Col xs={{ offset: 1, span: 10 }}>
          {metadataSource.content ? (
            <StyledJSONTree data={metadataSource.content} />
          ) : (
            <XML>{metadataSource.rawContent}</XML>
          )}
        </Col>
      </Row>
    </>
  );
}
