import { useCallback, useState } from "react";

export function useSelection<T>(initialValue?: T[]) {
  const [selection, setSelection] = useState<T[]>(
    initialValue ? initialValue : []
  );

  const toggleSelection = useCallback(
    (value: T) =>
      setSelection((state) => {
        if (state.includes(value))
          return state.filter((other) => other !== value);
        else return [...state, value];
      }),
    [setSelection]
  );

  const resetSelection = useCallback(() => setSelection([]), [setSelection]);

  return { selection, resetSelection, toggleSelection };
}
