import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

const STATUS_OPTIONS: { value: string; label: string }[] = [
  { value: "all", label: "All" },
  { value: "open", label: "Open" },
  { value: "closed", label: "Closed" },
];
export const StatusFilter = () => {
  const [status, setStatus] = useQueryParam("status", StringParam);
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "all") {
      setStatus(null);
    } else {
      setStatus(e.target.value);
    }
  };
  return (
    <>
      <Form.Label htmlFor="status">Open / closed</Form.Label>
      <Form.Select id="status" value={status || "all"} onChange={onChange}>
        {STATUS_OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
