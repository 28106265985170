export const LANGUAGE_NAMES: { [languageCode: string]: string } = {
  no: "Norwegian",
  en: "English",
  sv: "Swedish",
  da: "Danish",
  fr: "French",
  pl: "Polish",
  hr: "Croatian",
  de: "German",
  uk: "Ukrainian",
  it: "Italian",
  es: "Spanish",
  bs: "Bosnian",
};
