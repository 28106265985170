import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { CmsPure } from "client/utils";

const renderMonth = (props, getLinkTo) => {
  const day = moment(props.start);
  const columns = {};
  let totalDays = 0;
  while (day.isBefore(props.end)) {
    const weekNumber = day.isoWeek();
    if (!columns[weekNumber]) {
      columns[weekNumber] = {
        count: 0,
        header: "Week " + day.format("W"),
        linkTo: getLinkTo(day, "week"),
      };
    }
    columns[weekNumber].count++;
    totalDays++;
    day.add(1, "day");
  }
  return (
    <div data-testid="schedule-columns">
      {Object.keys(columns)
        .sort()
        .map((key) => columns[key])
        .map((col, index) => (
          <Link
            key={index}
            className="scheduler-column-header week"
            style={{ width: `${(col.count / totalDays) * 100}%` }}
            to={col.linkTo}
          >
            {col.header}
          </Link>
        ))}
    </div>
  );
};

function renderWeek(props, getLinkTo) {
  const day = moment(props.start);
  const columns = [];
  while (day.isBefore(props.end)) {
    columns.push({
      className: day.format("dddd").toLowerCase(),
      header: day.format("dddd Do"),
      linkTo: getLinkTo(day, false),
    });
    day.add(1, "days");
  }
  return (
    <div data-testid="schedule-columns">
      {columns.map((col, index) => (
        <Link
          key={index}
          className={"scheduler-column-header " + col.className}
          style={{ width: `${100 / columns.length}%` }}
          to={col.linkTo}
        >
          {col.header}
        </Link>
      ))}
    </div>
  );
}

function renderDay(props, getLinkTo) {
  const day = moment(props.start);
  const columns = [];
  const linkTo = getLinkTo(day, true);
  while (day.isBefore(props.end)) {
    columns.push(day.format("HH:mm"));
    day.add(4, "hours");
  }
  return (
    <div data-testid="schedule-columns">
      {columns.map((col, index) => (
        <Link
          key={index}
          className="scheduler-column-header hour"
          style={{ width: `${100 / columns.length}%` }}
          to={linkTo}
        >
          {col}
        </Link>
      ))}
    </div>
  );
}

class ScheduleColumns extends CmsPure {
  getLinkTo = (mom, weekView) => {
    const date = mom.format("YYYY[-]MM[-]DD");
    const view = weekView ? "week" : "day";
    return this.props.getLinkTo({ date, view });
  };

  render() {
    const duration = moment.duration(this.props.end.diff(this.props.start));
    if (duration.asHours() < 28) return renderDay(this.props, this.getLinkTo);
    if (duration.asHours() < 170) return renderWeek(this.props, this.getLinkTo);
    return renderMonth(this.props, this.getLinkTo);
  }
}

export default ScheduleColumns;
