import _ from "lodash";

export const accountArraysAreEqual = (accountsA, accountsB) => {
  if (accountsA.length !== accountsB.length) {
    return false;
  }

  const accountPairs = _.zip(accountsA, accountsB);
  return accountPairs.every(([a, b]) => accountsAreEqual(a, b));
};

export const accountsAreEqual = (a, b) => {
  if (!a || !b) {
    return false;
  }

  return (
    a.homeAccountId === b.homeAccountId &&
    a.localAccountId == b.localAccountId &&
    a.username === b.username
  );
};
