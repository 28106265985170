import React, { useEffect, useState } from "react";
import { request } from "client/utils";
import AssetImageGrabber from "./AssetImageGrabber";

const AssetImageGrabberContainer = ({ id, ...rest }) => {
  const [asset, setAsset] = useState();

  useEffect(() => {
    request(`assets/image-grabber/${id}`).then((res) => {
      setAsset(res);
    });
  }, [id]);

  return <AssetImageGrabber asset={asset} {...rest} />;
};

export default AssetImageGrabberContainer;
