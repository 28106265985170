import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import GlyphButton from "components/buttons/GlyphButton";
import { AssetRevisionProviderImage } from "../types";

const displayImageKeys: (keyof AssetRevisionProviderImage)[] = [
  "getClass",
  "language",
  "providerClass",
];

function ProviderImageCard({ image }: { image: AssetRevisionProviderImage }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const imageRef = React.useRef<HTMLImageElement>(null);

  const onLoad = React.useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  React.useEffect(() => {
    setIsLoading(true);
    if (imageRef.current?.complete) {
      onLoad();
    }
  }, [image]);

  return (
    <Card style={{ width: "24rem" }}>
      <Card.Img
        variant="top"
        src={image.content.transfer.source.uri}
        style={isLoading ? { opacity: 0.5 } : {}}
        onLoad={onLoad}
        ref={imageRef}
      />
      <ListGroup className="list-group-flush">
        {displayImageKeys.map((key, i) => (
          <ListGroupItem key={i}>
            <>
              <span className="fw-bold">{key}: </span>
              {image[key]}
            </>
          </ListGroupItem>
        ))}
      </ListGroup>
      <Card.Footer>
        <a
          href={image.content.transfer.source.uri}
          target="_blank"
          rel="noreferrer"
          title="Original size"
        >
          <small className="text-muted">Original size</small>
          <GlyphButton
            glyph="external"
            variant="link"
            className="ms-1 p-0"
            iconLast
          />
        </a>
      </Card.Footer>
    </Card>
  );
}

export default ProviderImageCard;
