import _ from "lodash";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { MetadataSource } from "../views/Epg/types";

export function useMetadataChannels() {
  const { data, error, isValidating, mutate } = useSWRImmutable<
    MetadataSource[]
  >(
    "epg/source/provider/1" // provider 1 is gracenote
  );

  const isLoading = isValidating || (!data && !error);
  const channels = data || [];
  const channelMap = useMemo(
    () => _.fromPairs(channels.map((channel) => [channel.id, channel])),
    [channels]
  );
  const channelNameMap = useMemo(
    () => _.fromPairs(channels.map(({ id, name }) => [id, name])),
    [channels]
  );

  return { channels, channelMap, channelNameMap, isLoading, error, mutate };
}
