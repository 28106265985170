import React, { useCallback } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { withChannels } from "client/App";
import { getQueryString } from "client/hooks";
import { DownloadButton } from "components/buttons";
import DateTimeControl from "components/controls/datetime/DateTimeControl";

const getReportPath = (params) => {
  const queryString = getQueryString(params);
  return `enrichment/catchup/report?${queryString}`;
};

const CatchupListFilter = ({ filters, setFilters, channels, channelMap }) => {
  const onChannelChange = useCallback(
    (channels) => {
      const selectedChannelIds = (channels || []).map((c) => c.value);
      setFilters({ channels: selectedChannelIds });
    },
    [setFilters]
  );

  return (
    <Container className="mt-4" fluid>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>Broadcast</Form.Label>
          <DateTimeControl
            placeholder="From"
            value={filters.broadcastFrom}
            onChange={(broadcastFrom) => setFilters({ broadcastFrom })}
            closeOnSelect
            acceptEmpty
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>&nbsp;</Form.Label>
          <DateTimeControl
            placeholder="To"
            value={filters.broadcastTo}
            onChange={(broadcastTo) => setFilters({ broadcastTo })}
            closeOnSelect
            acceptEmpty
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Catchup end</Form.Label>
          <DateTimeControl
            placeholder="From"
            value={filters.catchupEndFrom}
            onChange={(catchupEndFrom) => setFilters({ catchupEndFrom })}
            closeOnSelect
            acceptEmpty
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>&nbsp;</Form.Label>
          <DateTimeControl
            placeholder="To"
            value={filters.catchupEndTo}
            onChange={(catchupEndTo) => setFilters({ catchupEndTo })}
            closeOnSelect
            acceptEmpty
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Select
            placeholder="Select channels"
            options={channels.map(({ id, value }) => ({
              value: Number(id),
              label: value,
            }))}
            value={filters.channels.map((id) => ({
              value: Number(id),
              label: channelMap[id] || "N/A",
            }))}
            onChange={onChannelChange}
            closeMenuOnSelect={false}
            isMulti
            hideSelectedOptions
          />
        </Form.Group>
        <Form.Group as={Col} xs={1}>
          <DownloadButton
            variant="primary"
            className="w-100"
            path={getReportPath(filters)}
          />
        </Form.Group>
      </Row>
    </Container>
  );
};

export default withChannels(CatchupListFilter);
