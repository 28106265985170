import { ContentType } from "@telia-company/tv.no-play-cms-common/collection/types";
import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { assertUnreachable } from "client/utils/assertUnreachable";

interface ContentTypeOption {
  label: string;
  value: ContentType;
}

const contentTypes: ContentType[] = ["series", "movie", "program"];

const getContentTypeDisplayString = (value: ContentType): string => {
  switch (value) {
    case "series":
      return "Series";
    case "movie":
      return "Movies";
    case "program":
      return "Programs";
  }
  return assertUnreachable(value);
};

const contentTypeOptions: ContentTypeOption[] = contentTypes.map(
  (contentType) => ({
    label: getContentTypeDisplayString(contentType),
    value: contentType,
  })
);

interface Props {
  selectedContentTypes: ContentType[];
  setNewContentTypes: (contentTypes: ContentType[]) => void;
  readOnly: boolean;
}

export const ContentTypePicker = ({
  selectedContentTypes,
  setNewContentTypes,
  readOnly,
}: Props) => {
  const selected: ContentTypeOption[] = selectedContentTypes.map(
    (selectedContentType) => ({
      value: selectedContentType,
      label: getContentTypeDisplayString(selectedContentType),
    })
  );

  const onChange = (options: ContentTypeOption[]) =>
    setNewContentTypes(options.map((option) => option.value));

  return (
    <Form className="mb-3">
      <Form.Label htmlFor="content-type-picker">
        Filter by content type
      </Form.Label>
      <Typeahead
        id="content-type-picker"
        multiple={true}
        options={contentTypeOptions}
        selected={selected}
        placeholder="Content type filter"
        onChange={onChange}
        labelKey={(option: ContentTypeOption) => option.label}
        disabled={readOnly}
      />
    </Form>
  );
};
