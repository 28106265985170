import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

const OPTIONS = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const StatusFilter = () => {
  const [status = "active", setStatus] = useQueryParam("status", StringParam);

  return (
    <>
      <Form.Label htmlFor="status-filter">Status</Form.Label>
      <Form.Select
        value={status || "all"}
        onChange={(e) => setStatus(e.target.value)}
      >
        {OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
