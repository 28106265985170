import { pathToRegexp } from "path-to-regexp";

const adminPaths = ["/admin", "/admin/:tab"];
const amsPaths = [
  "/ams",
  "/ams/configurations/:tab?",
  "/ams/issue-reports",
  "/ams/assets/:id?/:tab?/:revision?/:revision2?/:adiId1?/:adiId2?",
  "/ams/providers/:mode?/:providerId?",
];
const viewContentPaths = [
  "/",
  "/enrich/mediacontent/:tab/:id/:version_id?",
  "/enrich/asset/:id",
];
const commercePaths = ["/tvod", "/tvod/report", "/tvod/price-overrides"];
const provisionPaths = [
  "/provisioning",
  "/provisioning/:type?",
  "/provisioning/:type?/:tab?",
  "/provisioning/:type?/:tab?/:mode?/:id?",
  "/provisioning/:type1?/:type2?/:tab?/:mode?/:id?",
];
const epgPaths = [
  "/epg",
  "/epg/matches",
  "/epg/matches/to/:toId",
  "/epg/matches/from/:fromId/to/:toId",
  "/epg/matches/details/:id",
  "/epg/linear-metadata-db",
  "/epg/linear-metadata-db/match/from/:fromId/to/:toId",
  "/epg/linear-metadata-db/details/:id",
  "/epg/linear-video-db",
  "/epg/maintenance",
  "/epg/channel/info",
];
const insightPaths = [
  "/insight",
  "/insight/gap-overlap-log",
  "/insight/gap-overlap-stats",
  "/insight/report/list",
  "/insight/report/stats",
  "/insight/warning/stats",
  "/insight/warningstatus/reported/list",
  "/insight/warningstatus/accepted/list",
  "/insight/warningstatus/spam/list",
];
const oneappPaths = ["/oneapp", "/oneapp/tv-guide-comparator"];

const viewPublicationPaths = [
  "/collections",
  "/collections/edit/:id",
  "/promos",
  "/promos/top",
  "/promos/card",
  "/promos/edit/:id",
  "/sports",
  "/sports/leagues",
  "/sports/leagues/:leagueId/seasons/:seasonId",
  "/sports/teams",
  "/schedule",
  "/schedule/:date",
];

interface Permission {
  view?: string[];
  edit?: string[];
}

type PermissionAction = keyof Permission;

export interface ResourcePermission {
  resource: string;
  permission: PermissionAction;
}

const permissionDefinition: { [key: string]: Permission } = {
  // The only available permission for unassigned users.
  // Isn't strictly needed in order to get permissions to work
  // correctly, but is included anyway in order to silence console
  // warnings about missing permission definitions.
  user: { view: ["/"] },

  admin: { view: adminPaths, edit: adminPaths },
  ams: { view: amsPaths, edit: amsPaths },
  content: {
    view: viewContentPaths,
    edit: [
      ...viewContentPaths,
      ...epgPaths,
      ...insightPaths,
      ...oneappPaths,
      "/enrich",
      "/enrich/mediacontent/:tab",
      "/monitor",
      "/monitor/asset",
    ],
  },
  commerce: { view: commercePaths, edit: commercePaths },
  provision: { view: provisionPaths, edit: provisionPaths },
  publication: {
    view: viewPublicationPaths,
    edit: [...viewPublicationPaths, "/collections/create", "/promos/create"],
  },
  imdb: {
    edit: [
      "/imdb",
      "/imdb/matched",
      "/imdb/unmatched",
      "/imdb/ignored",
      "/imdb/match/:id",
    ],
  },
  assetCuepoints: { edit: [] },
};

export const permissionTypes = [
  {
    value: { resource: "admin", permission: "edit" },
    label: "Administration",
  },
  {
    value: { resource: "ams", permission: "edit" },
    label: "AMS",
  },
  {
    value: { resource: "content", permission: "view" },
    label: "View Content",
  },
  {
    value: { resource: "content", permission: "edit" },
    label: "Enrich Content",
  },
  {
    value: { resource: "commerce", permission: "edit" },
    label: "TVOD",
  },
  {
    value: { resource: "provision", permission: "edit" },
    label: "Provision",
  },
  {
    value: { resource: "publication", permission: "edit" },
    label: "Publish",
  },
  {
    value: { resource: "publication", permission: "view" },
    label: "View Publications",
  },
  {
    value: { resource: "imdb", permission: "edit" },
    label: "Edit IMDb Matches",
  },
  {
    value: { resource: "assetCuepoints", permission: "edit" },
    label: "Edit Asset Cuepoints",
  },
];

function resourcePermissionAllowsPath(
  resource: string,
  permission: PermissionAction,
  requestedPath: string
) {
  const allowedPaths =
    permissionDefinition &&
    permissionDefinition[resource] &&
    permissionDefinition[resource][permission];
  if (!allowedPaths) {
    console.warn(
      `The user has access to resource=${resource} with permission=${permission}`,
      "but client-side ACL lacks a list of allowed paths for that combination"
    );
    return false;
  }

  return allowedPaths.some((other) => pathToRegexp(other).test(requestedPath));
}

/**
 * Checks if any of the given permissions grant the user access to path
 */
export function hasAccessTo(
  requestedPath: string,
  permissions: ResourcePermission[]
): boolean {
  for (const { resource, permission } of permissions) {
    const permitted = resourcePermissionAllowsPath(
      resource,
      permission,
      requestedPath
    );
    if (permitted) return true;
  }
  return false;
}

export function hasPermissionTo(
  requestedPermission: PermissionAction,
  requestedResource: string,
  permissions: ResourcePermission[]
) {
  return permissions.some(
    (actual) =>
      actual.resource === requestedResource &&
      getLevel(actual.permission) >= getLevel(requestedPermission)
  );
}

function getLevel(permission: PermissionAction) {
  switch (permission) {
    case "view":
      return 1;
    case "edit":
      return 2;
    default:
      return 0;
  }
}
