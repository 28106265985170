import classNames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";
import { CmsPure } from "client/utils";
import { GLYPHS } from "components/buttons/glyphs";

/**
 * Renders a CMS Card which inherits its width from its parent.
 * The height depends on the portrait property. Landscape cards
 * have an aspect ratio of 16:9, whereas portrait cards' aspect
 * ratio matches that of promotion images. Check property variant
 * for more valid options.
 */
export class CmsCard extends CmsPure {
  static Base = React.forwardRef(
    (
      {
        children,
        className,
        variant,
        canClick,
        roundedCorners = true,
        selected = false,
        zoomOnHover = false,
        onClick,
        disabled,
        ...rest
      },
      ref
    ) => {
      const selectable = onClick && canClick;
      return (
        <div
          className={classNames("cms-card", className, variant, {
            selectable,
            disabled,
          })}
          onClick={selectable ? onClick : undefined}
          ref={ref}
          {...rest}
        >
          <div
            className={classNames("cms-card-content", {
              selected,
              zoom: zoomOnHover,
              // bootstrap class
              rounded: roundedCorners,
            })}
          >
            {children}
          </div>
        </div>
      );
    }
  );

  static Overlay = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ children, className, roundedCorners = true, ...rest }, ref) => (
      <div
        {...rest}
        className={classNames("cms-card-overlay", className, {
          rounded: roundedCorners,
        })}
      >
        {children}
      </div>
    )
  );

  static Icons = ({ children, className, roundedCorners = true, ...rest }) => {
    if (!children) return null;
    return (
      <div
        {...rest}
        className={classNames("cms-card-icons", className, {
          rounded: roundedCorners,
        })}
      >
        {children}
      </div>
    );
  };

  static Add = ({ roundedCorners = true, children, className, ...rest }) => (
    <div
      className={classNames(
        "cms-card-add user-select-none cursor-pointer d-flex justify-content-center align-items-center",
        { rounded: roundedCorners },
        className
      )}
      {...rest}
    >
      <div className="text-center text-secondary">
        <span className={GLYPHS.ADD} style={{ fontSize: "2.5rem" }} />
        {children}
      </div>
    </div>
  );
}

CmsCard.Base.propTypes = {
  variant: PropTypes.oneOf([
    "landscape",
    "portrait",
    "topBanner",
    "cover",
    "collection",
    "custom", // use custom topPadding in the style prop to set aspect ratio
  ]).isRequired,
  canClick: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  zoomOnHover: PropTypes.bool,
  roundedCorners: PropTypes.bool,
  onClick: PropTypes.func,
};
