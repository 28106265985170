import React from "react";
import { Badge } from "react-bootstrap";

export function OptionCell({ options = [], value }) {
  const item = options.find((other) => other.value == value);
  if (!item) {
    return <Badge bg="dark">{String(value)}</Badge>;
  }
  return (
    <Badge bg={item.variant} text={item.variant === "light" && "dark"}>
      {item.label}
    </Badge>
  );
}
