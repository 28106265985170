import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ProgramChildren } from "../ProgramDetails/ProgramChildren";
import { ProgramDetails } from "../ProgramDetails/ProgramDetails";
import { DeleteMatchButton } from "./DeleteMatchButton";
import { UpdateMatch } from "./UpdateMatch";

interface Props {
  matchId?: number;
  fromId: string;
  toId: string;
  path: string;
}

export const OneToOneProgramMatch = ({
  matchId,
  fromId,
  toId,
  path,
}: Props) => {
  return (
    <Row>
      <Col xs={6}>
        <Card>
          <Card.Body>
            <ProgramDetails id={fromId} database="METADB" />
            {matchId && (
              <>
                <UpdateMatch
                  path={path}
                  fromId={fromId}
                  toId={toId}
                  matchId={matchId}
                />
                <div className="clearfix">
                  <div className="float-end">
                    <DeleteMatchButton
                      fromId={fromId}
                      toId={toId}
                      matchId={matchId}
                      path={path}
                    />
                  </div>
                </div>
              </>
            )}
            <ProgramChildren id={fromId} database="METADB" />
          </Card.Body>
        </Card>
      </Col>
      <Col xs={6}>
        <Card>
          <Card.Body>
            <ProgramDetails id={toId} database="TELIADB" />
            <ProgramChildren id={toId} database="TELIADB" />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
