import React from "react";
import { Col, Row } from "react-bootstrap";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { useDebouncedValue } from "client/hooks";
import { useKeptResult } from "client/hooks/useKeptResult";
import { AssetsResponse } from "client/types/AssetInfo";
import { DateRangePicker } from "../DateRangePicker";
import { useStartAndEndDates } from "../hooks/useStartAndEndDates";
import { ChannelSelector } from "./ChannelSelector";
import { ProgramList } from "./ProgramList";
import { TitleFilter } from "./TitleFilter";
import { useChannelSelection } from "./useChannelSelection";

const LinearVideoDb = () => {
  const data = useAssets();
  return (
    <>
      <Row className="mb-2">
        <Col xs={6}>
          <TitleFilter />
        </Col>
        <Col xs={6}>
          <ChannelSelector />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <DateRangePicker />
        </Col>
      </Row>
      {data && (
        <ProgramList
          tableRows={data.programs}
          totalResults={data.totalResults}
        />
      )}
      {!data && <div>Loading...</div>}
    </>
  );
};

const useAssets = () => {
  const { selectedChannels } = useChannelSelection();
  const { startDate, endDate } = useStartAndEndDates();
  const [pageIndex] = useQueryParam("page", NumberParam);
  const [pageSize] = useQueryParam("size", NumberParam);
  const [title] = useQueryParam("title", StringParam);
  const debouncedTitle = useDebouncedValue(title);

  const searchParams = new URLSearchParams();
  if (selectedChannels) {
    for (const channel of selectedChannels) {
      if (channel) {
        searchParams.append("channelId", channel.toString());
      }
    }
  }
  searchParams.append("from", startDate);
  searchParams.append("to", endDate);
  if (debouncedTitle) {
    searchParams.append("title", debouncedTitle);
  }
  if (pageIndex !== undefined && pageIndex !== null) {
    searchParams.append("page", pageIndex.toString());
  }
  if (pageSize !== undefined && pageSize !== null) {
    searchParams.append("size", pageSize.toString());
  }
  const { data } = useSWR<AssetsResponse>(`assets/?${searchParams.toString()}`);

  return useKeptResult(data);
};

export default LinearVideoDb;
