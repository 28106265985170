import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

const REPORT_TYPES = ["All", "NEW_CRID", "SE_EP_CRID"];
export const ReportTypePicker = () => {
  const [reportType, setReportType] = useQueryParam("report_type", StringParam);

  const changeReportType = (newReportType: string) => {
    if (newReportType === "All") {
      setReportType(null);
    } else {
      setReportType(newReportType);
    }
  };
  return (
    <>
      <Form.Label htmlFor="content-insight-report-type">Report Type</Form.Label>
      <Form.Select
        id="content-insight-report-type"
        value={reportType || ""}
        onChange={(event) => changeReportType(event.target.value)}
      >
        {REPORT_TYPES.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
