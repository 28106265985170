import { PropTypes } from "prop-types";
import React from "react";
import { Alert } from "react-bootstrap";
import { CmsPure } from "client/utils";
import { Spinner } from "components/Spinner";

class RawProvisionerJSON extends CmsPure {
  state = { data: null, error: null, fetching: true };

  componentDidMount() {
    this.componentDidUpdate();
  }
  componentDidUpdate(prevProps) {
    const { id, fetchData, path } = this.props;
    if (prevProps && prevProps.id === id && prevProps.path === path) return;

    if (id || path) {
      const callback = (data) => this.setState({ data, fetching: false });
      const errorCallback = () => {
        this.setState({
          fetching: false,
          error: "The data could not be loaded",
        });
      };
      fetchData({ id, path }, callback, errorCallback);
    }
  }

  render() {
    if (this.state.fetching) {
      return <Spinner className="m-auto" />;
    } else if (this.state.error) {
      return <Alert variant="danger">{this.state.error}</Alert>;
    }
    if (typeof this.state.data === "string") {
      return <pre>{this.state.data}</pre>;
    } else {
      return <pre>{JSON.stringify(this.state.data, null, 2)}</pre>;
    }
  }
}

RawProvisionerJSON.propTypes = {
  id: PropTypes.string,
  path: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
};

RawProvisionerJSON.defaultProps = {
  id: undefined,
  path: undefined,
};

export default RawProvisionerJSON;
