import React, { useCallback, useEffect, useState } from "react";
import { hasAccessTo, hasPermissionTo } from "client/ACL";
import { useMsal } from "client/hooks";
import { request } from "client/utils";

export const UserContext = React.createContext();

// Exported for tests only
export class User {
  constructor(user, { isLoggedIn = true } = {}) {
    this.isLoggedIn = isLoggedIn;
    this.username = user.username;
    this.displayName = user.displayName || user.username;
    this.role = user.role;
    this.defaultRoute = user.defaultRoute;
    this.token = user.token;
    this.permission = user.permission || [];
  }

  hasAccessTo = (path) => hasAccessTo(path, this.permission);

  hasPermissionTo = (perm, resource) =>
    hasPermissionTo(perm, resource, this.permission);
}

/**
 * Provide access to the currently logged in user.
 *
 * If the user has already authenticated against Azure AD, use the ID
 * token to retrieve user metadata and permissions from /api/user, and
 * refresh the user object regularly.
 *
 * If the user is not yet authenticated, attempt to trigger a silent
 * reauthentication and proceed to fetch user metadata and permissions
 * once authenticated.
 *
 * UserProvider is guaranteed to always provide a User instance to its
 * children, regardless of the user's authentication status.
 *
 * The easiest way to access the user context is through useUser() in
 * client/hooks.  Class components that cannot use hooks can instead use
 * the UserContext context directly.
 */
export function UserProvider({ children, onLogin }) {
  const { token, login } = useMsal();
  const [user, setUser] = useState(new User({}, { isLoggedIn: false }));
  const [isFetching, setIsFetching] = useState(false);

  const fetchUser = useCallback(() => {
    // Get the current user from the API and update the user context with it
    if (!isFetching && token) {
      setIsFetching(true);
      request("user")
        .then((res) => {
          setUser(new User(res));
          setIsFetching(false);
        })
        .catch((error) => {
          console.error(error);
          setIsFetching(false);
        });
    }
  }, [setUser, token, isFetching, setIsFetching]);

  useEffect(() => {
    // Clear the user context if we no longer have a valid ID token
    if (user.isLoggedIn && !token) {
      setUser(new User({}, { isLoggedIn: false }));
    }

    // Refresh the current user when a token has been acquired as well
    // as when the token has been refreshed
    if (token && (!user.isLoggedIn || user.token !== token)) {
      fetchUser();
    }
  }, [token, user, setUser]);

  useEffect(() => {
    // Trigger the onLogin() callback when we've successfully
    // authenticated with both Azure AD and the CMS API
    if (user.isLoggedIn) {
      onLogin();
    }
  }, [user.isLoggedIn, onLogin]);

  useEffect(() => {
    // Refresh the user context every minute in order to track
    // changed permissions and expired or updated sessions
    const interval = setInterval(() => {
      if (!token) {
        login({ redirect: false });
      } else {
        fetchUser();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [fetchUser]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
