import _ from "lodash";
import { useContext, useMemo } from "react";
import { ProvidersContext } from "client/App";

export const useProviders = () => {
  const providers = useContext(ProvidersContext);
  const providerMap = useMemo(
    () => _.fromPairs(providers.map(({ id, value }) => [id, value])),
    [providers]
  );

  return { providers, providerMap };
};
