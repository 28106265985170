import React, { useCallback, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { STATUS, useRequest, useUser } from "client/hooks";
import { formatPlayUrl, request } from "client/utils";
import Table, {
  getDateColumn,
  getManageColumn,
  getStatusColumn,
  OptionCell,
  OptionsFilter,
  TextFilter,
} from "components/tables";
import PublishBar from "components/toolbar/PublishBar";

const CONTENT_OPTIONS = [
  { value: "asset", label: "Asset", variant: "light" },
  { value: "mediaContent", label: "Media Content", variant: "light" },
  { value: "collection", label: "Collection", variant: "info" },
  { value: "channel", label: "Channel", variant: "secondary" },
  { value: "provider", label: "Provider", variant: "secondary" },
  { value: "pageSection", label: "Page Section", variant: "secondary" },
];

function Promos({ promotionType }) {
  const [data, setData] = useState([]);
  const [status, refresh] = useRequest(
    `promotions?promotionType=${promotionType}`,
    { onSuccess: setData }
  );

  const onDelete = useCallback(
    ({ id }) => {
      request("promotions/" + id, {
        method: "DELETE",
      })
        .then(refresh)
        .catch((error) =>
          toast.error(`Failed to delete promotion with id ${id}: ${error}`)
        );
    },
    [refresh]
  );

  const navigate = useNavigate();
  const onEdit = useCallback(
    (promo) => navigate("/promos/edit/" + promo.id),
    [navigate]
  );

  const { hasPermissionTo } = useUser();
  const isEditor = hasPermissionTo("edit", "publication");

  const columns = useMemo(
    () =>
      [
        getStatusColumn({
          accessor: (row) => row.enabled && row.active_content,
        }),
        {
          Header: "Title",
          accessor: "title",
          Cell: ({ value, row: { original } }) =>
            original.path ? (
              <a
                href={formatPlayUrl(original.path)}
                target="_blank"
                rel="noreferrer"
              >
                {value}
              </a>
            ) : (
              value
            ),
          Filter: TextFilter,
          filter: "text",
          width: 120,
        },
        {
          Header: "Editorial Notes",
          accessor: "notes",
          Filter: TextFilter,
          filter: "text",
          width: 80,
        },
        {
          accessor: "content_type",
          Header: "Content",
          Cell: ({ value }) => (
            <OptionCell options={CONTENT_OPTIONS} value={value} />
          ),
          Filter: ({ column }) => (
            <OptionsFilter options={CONTENT_OPTIONS} column={column} />
          ),
          filter: "equals",
          width: 40,
        },
        getDateColumn({
          Header: "Last edited",
          accessor: "updated",
          width: 80,
        }),
        {
          Header: "By",
          accessor: "editor",
          Filter: TextFilter,
          width: 80,
        },
        isEditor && getManageColumn(onEdit, onDelete),
      ].filter((v) => !!v),
    [isEditor]
  );

  return (
    <>
      <PublishBar />
      <Container fluid>
        <h1>All Collections</h1>
        <Table
          columns={columns}
          data={data}
          loading={status === STATUS.FETCHING}
          initialState={{
            sortBy: [{ id: "updated", desc: true }],
            pageIndex: 0,
          }}
          filter
          paginate
        />
      </Container>
    </>
  );
}

export default Promos;
