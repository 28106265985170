import autobind from "autobind-decorator";
import moment from "moment";
import { PropTypes } from "prop-types";
import React from "react";
import { FormControl } from "react-bootstrap";
import Datetime from "react-datetime";
import momentPropTypes from "react-moment-proptypes";
import { CmsPure } from "client/utils";

const TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm";

/**
 * Class that wraps react-datetime and customizes it for how we use it in the CMS
 */
class DateTimeControl extends CmsPure {
  constructor(props) {
    super(props);
    // const m = moment(props.value, TIMESTAMP_FORMAT, true);
    this.state = {
      value: props.value || "",
    };
  }

  isValid() {
    return (
      moment.isMoment(this.state.value) ||
      (this.state.value === "" && this.props.acceptEmpty)
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.value && props.acceptEmpty) return { value: "" };
    return {
      value: props.value || state.value || "",
    };
  }

  @autobind
  onChange(value) {
    const externalValue = moment.isMoment(value) ? value : undefined;
    this.props.onChange(externalValue);
    this.setState({ value });
  }

  render() {
    const inputStyle = this.isValid() ? {} : { backgroundColor: "#fdd" };
    if (this.props.readOnly)
      return (
        <FormControl
          className={this.props.className}
          value={moment(this.state.value).format(TIMESTAMP_FORMAT)}
          readOnly
        />
      );

    return (
      <Datetime
        dateFormat="YYYY-MM-DD"
        timeFormat="HH:mm"
        {...this.props}
        value={this.state.value}
        onChange={this.onChange}
        inputProps={{
          placeholder: this.props.placeholder || TIMESTAMP_FORMAT,
          style: inputStyle,
          id: this.props.inputId,
        }}
        className={this.props.anchorRight && "rdtPicker-right"}
      />
    );
  }
}

DateTimeControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: momentPropTypes.momentObj,
  acceptEmpty: PropTypes.bool,
  anchorRight: PropTypes.bool,
};

export default DateTimeControl;
