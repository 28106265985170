import _, { orderBy } from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { UserContext } from "client/contexts";
import { STATUS, useRequest } from "client/hooks";
import { request } from "client/utils";
import StatusBoundary from "../StatusBoundary";
import { useOpenApiEnum, useProvider } from "../hooks";
import ProviderView from "./ProviderView";
import ProviderSideMenu from "./ProvidersSideMenu";

function providerStatusToBool(provider) {
  switch (provider.uhe_status) {
    case "ACTIVE":
    case "TDC":
      return true;
    case "INACTIVE":
    case "UNKNOWN:":
    default:
      return false;
  }
}

function Providers() {
  const [allProviders, setAllProviders] = useState([]);
  const [providersStatus, refreshSideMenu] = useRequest("ams/provider", {
    onSuccess: (res) =>
      setAllProviders(
        orderBy(res.data, [providerStatusToBool, "name"], ["desc", "asc"])
      ),
  });

  const { hasPermissionTo } = useContext(UserContext);
  const canEdit = hasPermissionTo("edit", "ams");

  const { providerId: rawProviderId, mode } = useParams();
  const providerId = rawProviderId ? parseInt(rawProviderId, 10) : undefined;
  const getLinkTo = (id) => `/ams/providers/${canEdit ? "edit" : "view"}/${id}`;

  const { provider, error, isLoading, mutate } = useProvider(providerId, mode);
  const onChange = useCallback(
    (changes) => mutate({ ...provider, ...changes }, false),
    [provider, mutate]
  );

  const [isSubmitting, setSubmitting] = useState(false);
  const onSubmit = (e) => {
    const endpoint = `ams/provider/${mode !== "create" ? providerId : ""}`;
    const payload = _.omit(provider, ["id", "created"]);
    const method = mode === "create" ? "POST" : "PUT";
    setSubmitting(true);
    request(endpoint, {
      method,
      body: JSON.stringify(
        _.pickBy(payload, (value) => !_.isNil(value) && value !== "")
      ),
    })
      .then((response) => {
        mutate(response, false);
        toast.info(`Provider ${payload.name} update`);
        refreshSideMenu();
        setSubmitting(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 422) {
          toast.error(`Validation error, nothing saved`);
        } else {
          toast.error(`Failed to ${mode} provider: ${err.message}`);
        }
        setSubmitting(false);
      });
    e.preventDefault();
  };

  const { schema: providerStatuses, isLoading: isLoadingProviderStatuses } =
    useOpenApiEnum("ProviderStatus");
  const { schema: transcodeServices, isLoading: isLoadingTranscodeServices } =
    useOpenApiEnum("TranscodeService");

  return (
    <Container fluid>
      <Row>
        <Col xs="auto">
          <ProviderSideMenu
            getLinkTo={getLinkTo}
            items={allProviders}
            isLoading={providersStatus === STATUS.FETCHING}
            selectedId={providerId}
            canEdit={canEdit}
          />
        </Col>
        <Col xs>
          {mode && (
            <Container>
              <StatusBoundary
                isLoading={isLoading}
                error={error}
                className="flex-fill"
              >
                <ProviderView
                  provider={provider}
                  mode={mode}
                  onChange={onChange}
                  canEdit={canEdit}
                  onSubmit={onSubmit}
                  isSubmitting={isSubmitting}
                  providerStatuses={providerStatuses}
                  isLoadingProviderStatuses={isLoadingProviderStatuses}
                  transcodeServices={transcodeServices}
                  isLoadingTranscodeServices={isLoadingTranscodeServices}
                />
              </StatusBoundary>
            </Container>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Providers;
