import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

export const ShowAcceptedToggle = () => {
  const [showAccepted, setShowAccepted] = useQueryParam(
    "showAccepted",
    BooleanParam
  );
  const resetPage = useResetPage();
  const selectShowAccepted = (newShowAccepted: true | null) => {
    setShowAccepted(newShowAccepted);
    resetPage();
  };

  return (
    <InputGroup>
      <Form.Check
        type="switch"
        id="search-filter-show-accepted"
        checked={showAccepted || false}
        onChange={(e) => selectShowAccepted(e.target.checked || null)}
      />
      <Form.Label htmlFor="search-filter-show-accepted">
        Show accepted
      </Form.Label>
    </InputGroup>
  );
};
