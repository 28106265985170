import {
  getAvailabilityDescription,
  getChannelOrProviderName,
} from "@telia-company/tv.no-play-cms-common/api/MediaContentUtils";
import PropTypes from "prop-types";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { STATUS, useRequest } from "client/hooks";
import { numberOrStringPropType } from "client/utils";
import { SingleSelect } from "components/pickers";
import { GlyphButton } from "./buttons";

const NO_SPECIFIC_ASSET = {
  value: null,
  label: "No specific asset",
};

const formatAssetOption = (assetWithIncludes) => {
  const description =
    getAvailabilityDescription(assetWithIncludes) ||
    "Asset with id " + assetWithIncludes.id;
  return {
    value: assetWithIncludes.id,
    label: `[${getChannelOrProviderName(assetWithIncludes)}] ${description}`,
  };
};

function AssetSelectorDialog({
  selectedId,
  mcId,
  show,
  onClose,
  onChange,
  onDelete,
}) {
  const [internalId, setInternalId] = React.useState(selectedId);

  const endpoint = "assets/by-mc-id/" + mcId;
  const [options, setOptions] = React.useState([]);
  const [status] = useRequest(endpoint, {
    onSuccess: (result) =>
      setOptions([NO_SPECIFIC_ASSET, ...result.map(formatAssetOption)]),
    blockRequests: !show,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    onChange(internalId);
  };

  // make sure the internal id matches the external
  React.useEffect(
    () => setInternalId(selectedId ? selectedId.toString() : null),
    [selectedId]
  );

  return (
    <>
      <Modal animation autoFocus show={show} onHide={onClose}>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="lead">Select asset</Form.Label>
              {onDelete && (
                <GlyphButton
                  glyph="delete"
                  className="pull-right"
                  variant="link"
                  onClick={() =>
                    onDelete(internalId, internalId !== null, mcId)
                  }
                />
              )}
              <SingleSelect
                value={internalId}
                options={options}
                onChange={setInternalId}
                isLoading={status === STATUS.FETCHING}
              />
            </Form.Group>
            <Button
              className="pull-right"
              variant="primary"
              type="submit"
              disabled={mcId == internalId}
            >
              Set
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

AssetSelectorDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  selectedId: numberOrStringPropType,
  mcId: numberOrStringPropType,
};

export default AssetSelectorDialog;
