import { isNumeric } from "@telia-company/tv.no-play-cms-common/api/util";
import { startCase, toLower } from "lodash";
import moment from "moment";
import { PropTypes } from "prop-types";
import { CLIENT_CONFIG } from "client/config";
// So many components use these classes.
// Reexport them here to avoid dealing with
// import patterns
import CmsPure from "components/CmsPure";
import LoadingOverlayWrapper from "components/LoadingOverlayWrapper";

export { request } from "./request";
export { stringify } from "./stringify";

export { CmsPure, LoadingOverlayWrapper };

/**
 * Comparator that can be passed to the sort function. If a number is parseable as a number it is
 * sorted by that numbers value, smallest first, all other values are sorted after numbers according
 * to localeCompare.
 */
export const numericComparator = (index1 = "", index2 = "") => {
  const num1 = isNumeric(index1);
  const num2 = isNumeric(index2);
  // numbers are sorted before anything else, lower first
  if (num1 && num2) {
    return Number(index1) - Number(index2);
  }
  if (num1 != num2) {
    return num1 ? -1 : 1;
  }
  // if we get here both are strings
  return index1.localeCompare(index2, "no");
};

/**
 * Takes a list of option-objects { id, value }, and returns a function for generating labels from an
 * object, taking into account if two objects have the same value (label), and in that case adds the
 * id to the label to allow the user to differentiate them.
 * This is a case for example for channels where NRK has both id 1 and 157
 */
export const duplicateSafeLabelKey = (options) => {
  return (value) => {
    let label = value.value;
    // see if we have other objects with the same value,
    // and if so, add the ID to the name
    if (options.some((ch) => ch.value == value.value && ch.id != value.id)) {
      label = `${label} [${value.id}]`;
    }
    return label;
  };
};

/**
 * @param {string} key SOME_EXAMPLE, someExample
 * @returns {string} Some Example, SomeExample
 */
export function humanize(key) {
  return startCase(toLower(key));
}

/**
 * Parses a moment into the format "YYYY[-]MM[-]DD". If the moment
 * is invalid, undefined is returned.
 * @param {moment or string} mom
 * @returns {string or undefined} "YYYY[-]MM[-]DD"
 */
export function getValidDateString(mom) {
  if (typeof mom === "string") mom = moment(mom);
  if (!mom || !mom.isValid()) {
    return undefined;
  }
  return mom.format("YYYY[-]MM[-]DD");
}

/**
 * @param {moment | date | string} from
 * @param {moment | date | string} to
 * @returns {boolean}
 */
export function isValidDateRange(from, to) {
  const publish = moment(from);
  const unpublish = moment(to);

  if (!publish.isValid() || !unpublish.isValid()) return false;

  return publish.isBefore(unpublish);
}

/**
 * Takes a list of selected values passed by a Typehead component and:
 *  - filters any duplicates
 *  - remaps any custom options to the entered value
 *
 * @param {any[]} values
 * @param {string} labelKey must match the labelKey of the Typeahead component
 * @returns {string[]}
 */
export const formatSimpleTypeaheadSelection = (values, labelKey = "label") => {
  const lastItem = values[values.length - 1];
  // the user added a custom option
  if (typeof lastItem == "object" && lastItem.customOption) {
    const lastId = lastItem[labelKey];
    if (values.find((value) => value == lastId)) {
      return values.slice(0, -1);
    }

    return values.map((value) =>
      typeof value == "object" ? value[labelKey] : value
    );
  }
  return values;
};

/**
 * Convert a duration in seconds to [hh:]mm:ss[.msec] format.
 *
 * If no duration is specified, return undefined.
 */
export const formatDuration = (
  s,
  { milliseconds } = { milliseconds: false }
) => {
  if (s === null || s === undefined) {
    return undefined;
  }

  const duration = moment.duration(s, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const parts = [minutes, seconds];

  if (hours) {
    parts.splice(0, 0, hours);
  }

  const formattedParts = parts.map((p, i) => {
    if (i === 0) {
      return String(p);
    } else {
      return String(p).padStart(2, "0");
    }
  });

  const hms = formattedParts.join(":");

  if (milliseconds) {
    return `${hms}.${duration.milliseconds()}`;
  } else {
    return hms;
  }
};

/**
 * Parse a duration string to a moment.duration object.
 *
 * This function differs from moment.duration(str) by interpreting a
 * duration on the format 'xx:yy' as minutes and seconds rather than
 * hours and minutes.  Durations in any other format are simply
 * forwarded to moment.duration().
 */
export const parseDuration = (str) => {
  if (str.match(/^[0-9]{1,2}:[0-9]{1,2}$/)) {
    // Convert durations on the form 'mm:ss' to '0:mm:ss' to get
    // moment.duration() to parse them as minutes rather than hours
    return moment.duration(`0:${str}`);
  }

  return moment.duration(str);
};

/**
 * Moves the element with position 'from' to position 'to'
 * @param {array} array
 * @param {number} from
 * @param {number} to
 * @returns a new array
 */
export function move(array, from, to) {
  const copy = [...array];
  const element = copy.splice(from, 1)[0];
  copy.splice(to, 0, element);
  return copy;
}

export function asLookupMap(array, key) {
  return array.reduce((prev, curr) => {
    prev[curr[key]] = curr;
    return prev;
  }, {});
}

/**
 * Duplicated from play-cms-esindexer
 * Gets a the first poster image (landscape) available. Otherwise any image available
 */
export function getFirstImagePath(images) {
  if (!images?.length) return undefined;
  const posterImages = images.filter((image) => image.type === "poster");
  return posterImages.length ? posterImages[0].path : images[0].path;
}

export const formatPlayUrl = (path) => `${CLIENT_CONFIG.PLAY_DOMAIN}/${path}`;

export const numberOrStringPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);
