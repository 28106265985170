import moment from "moment";
import { QueryParamConfig } from "use-query-params";

export const MomentParam: QueryParamConfig<
  moment.Moment | undefined,
  moment.Moment | undefined
> = {
  encode: (moment?: moment.Moment) =>
    moment ? moment.toISOString() : undefined,
  decode: (date: string | null | undefined | (string | null)[]) => {
    if (!date) {
      return undefined;
    }
    const m = moment(date as string);
    if (!m.isValid()) {
      throw new Error(`Invalid timestamp value: '${date}'`);
    }
    return m;
  },
};

export const MomentDayParam: QueryParamConfig<
  moment.Moment | undefined,
  moment.Moment | undefined
> = {
  encode: (moment?: moment.Moment) =>
    moment ? moment.format("YYYY-MM-DD") : undefined,
  decode: (date: string | null | undefined | (string | null)[]) => {
    if (!date) {
      return undefined;
    }
    const m = moment(date as string);
    if (!m.isValid()) {
      throw new Error(`Invalid timestamp value: '${date}'`);
    }
    return m;
  },
};
