import React from "react";
import { CmsPure } from "client/utils";
import ACLManagement from "./AccessControl";

class Admin extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.match.params.tab || "access-control",
    };
  }

  render() {
    switch (this.state.activeTab) {
      case "access-control":
      default:
        return <ACLManagement />;
    }
  }
}
export default Admin;
