import React from "react";
import { request } from "client/utils";
import Roles from "./Roles";
import Users from "./Users";

function ACLManagement() {
  const [roles, setRoles] = React.useState([]);
  React.useEffect(() => {
    request("settings/admin/role-permission")
      .then((roles) => setRoles(roles))
      .catch(console.error);
  }, []);

  return (
    <div className="container">
      <Users roles={roles} />
      <Roles roles={roles} setRoles={setRoles} />
    </div>
  );
}

export default ACLManagement;
