import Promise from "bluebird";
import React, { useState, useCallback } from "react";
import { Badge, Button, Modal } from "react-bootstrap";

function getVariant(status, error, skipped) {
  if (error) return "danger";
  if (status >= 500) return "danger";
  if (status >= 400) return "warning";
  if (status >= 200) return "success";
  if (skipped) return "secondary";
  return "dark";
}

function ActionRow({ asset, status, detail, skipped, error }) {
  const variant = getVariant(status, error, skipped);
  return (
    <div key={asset.id}>
      {asset.productionTitle}
      <span className="text-secondary mx-2">
        (
        {[asset.episodicNotation, asset.yaafAssetId, asset.providerAssetId]
          .filter((v) => !!v)
          .join(", ")}
        )
      </span>
      {(status || skipped || error) && (
        <Badge bg={variant} text={variant === "light" && "dark"}>
          {skipped
            ? "SKIPPED"
            : error
              ? "ERROR"
              : status >= 400
                ? "FAILED"
                : "DONE"}
        </Badge>
      )}
      {detail && <div className={`text-${variant}`}>{detail}</div>}
      {error && <div className={`text-${variant}`}>{String(error)}</div>}
    </div>
  );
}

function ActionModal({ assets, action, onHide }) {
  const [results, setResults] = useState({});
  const [isPending, setIsPending] = useState(false);
  const isDone = Object.values(results).length === assets.length;

  const startBatchActions = useCallback(() => {
    setIsPending(true);
    const actions = Promise.map(
      assets,
      async (asset) => {
        try {
          const revision = {
            id: asset.revisionId,
            ...asset.latestRevisionLocators,
          };
          const result = await action.run({ asset, revision });
          setResults((results) => ({ ...results, [asset.id]: result }));
        } catch (error) {
          setResults((results) => ({ ...results, [asset.id]: { error } }));
        }
      },
      { concurrency: 5 }
    );
    Promise.join(actions, () => setIsPending(false));
  }, [assets, action, setIsPending, setResults]);

  return (
    <Modal centered size="lg" show onHide={onHide}>
      <Modal.Body>
        {action.message()}
        <div className="p-4">
          {assets.map((asset) => {
            const { status, detail, error, skipped } = results[asset.id] || {};
            return (
              <ActionRow
                asset={asset}
                status={status}
                detail={detail}
                error={error}
                skipped={skipped}
                key={asset.id}
              />
            );
          })}
        </div>
        {isDone
          ? "The above changes were made. You may close this window."
          : "Changes cannot be undone."}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={startBatchActions}
          disabled={isPending || isDone}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActionModal;
