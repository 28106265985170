import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { CmsPure, formatSimpleTypeaheadSelection } from "client/utils";
import { SingleSelect } from "components/pickers";
import { getComposerTitle } from "../provisioningUtils";

const LABEL = "VOD Provider";

class VODProviderComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      beta: false,
      name: "",
      shortName: "",
      videoMetadataId: "",
      sortOrder: "",
      logos: {
        fixedOnDark: "",
        fixedOnLight: "",
        normalOnDark: "",
      },
      caIds: [],
      icxChannelId: "",
      icxALaCarte: false,
      offlineEnabled: false,
      offline: {
        licenseDurationInHours: undefined,
        localStorageDurationInHours: undefined,
        requireOfflineFlagOnAsset: undefined,
      },
      tdcImport: {
        providerId: "",
        catalogue: "",
      },
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) {
      return;
    }

    this.props.get(id, (state) => {
      const tweakedState = {
        ...state,
        offlineEnabled: !!state.offline,
      };
      this.setState(tweakedState);
    });
  }

  create = (event) => {
    event.preventDefault();
    const payload = { ...this.state };

    // remove optional properties if the user did not set them
    if (!payload.tdcImport.providerId && !payload.tdcImport.catalogue) {
      delete payload.tdcImport;
    } else if (!payload.tdcImport.providerId) {
      delete payload.tdcImport.providerId;
    } else if (!payload.tdcImport.catalogue) {
      delete payload.tdcImport.catalogue;
    }

    if (!payload.icxChannelId) {
      delete payload.icxChannelId;
    }

    if (!payload.offlineEnabled) {
      delete payload.offline;
    } else if (
      !payload.offline.licenseDurationInHours ||
      !payload.offline.localStorageDurationInHours
    ) {
      toast.warn("Offline license duration and storage duration are required");
      return;
    }

    if (!payload.hasTrailer) {
      delete payload.hasTrailer;
    }

    if (!payload.isDeepLinked) {
      delete payload.isDeepLinked;
    }

    this.props.put(payload, this.props.id, LABEL);
  };

  isValid = () => {
    const { name, shortName, videoMetadataId, logos } = this.state;
    if (!logos.fixedOnDark || !logos.fixedOnLight || !logos.normalOnDark) {
      return false;
    }
    return name && shortName && videoMetadataId;
  };

  // shortName is generally just name so update them together if they are equal
  setName = (e) => {
    const name = e.target.value;
    const props = { name };
    if (this.state.name === this.state.shortName) {
      props.shortName = name;
    }
    this.setState(props);
  };

  providerstate = () => {
    if (this.state.active) {
      return "active";
    } else if (this.state.beta) {
      return "beta";
    } else {
      return "inactive";
    }
  };

  setProviderStatus = (status) => {
    if (status == "active") {
      this.setState({ active: true, beta: false });
    } else if (status == "beta") {
      this.setState({ active: false, beta: true });
    } else if (status == "inactive") {
      this.setState({ active: false, beta: false });
    }
  };

  render() {
    const { logos, tdcImport } = this.state;
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    const providerStatus = this.providerstate();

    return (
      <Form onSubmit={this.create}>
        <h1>{title}</h1>
        <Row className="mb-3">
          <Col as={Form.Group} xs={8}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={this.state.name}
              placeholder={LABEL + " name"}
              onChange={this.setName}
            />
          </Col>
          <Col as={Form.Group} xs={4}>
            <Form.Label>Short Name</Form.Label>
            <Form.Control
              type="text"
              placeholder={"Shortened " + LABEL + " name"}
              value={this.state.shortName}
              onChange={(e) => this.setState({ shortName: e.target.value })}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col as={Form.Group} xs={8}>
            <Form.Label>Conditional Access Ids</Form.Label>
            <Typeahead
              id="caIds"
              multiple
              allowNew
              options={[]}
              emptyLabel="Type to add"
              newSelectionPrefix="Add conditional access id: "
              selected={this.state.caIds}
              onChange={(values) =>
                this.setState({ caIds: formatSimpleTypeaheadSelection(values) })
              }
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col as={Form.Group} xs={6}>
            <Form.Label>Provider is an ICX a-la-carte choice</Form.Label>
            <Form.Check
              id="icxALaCarte"
              type="checkbox"
              checked={this.state.icxALaCarte}
              onChange={() =>
                this.setState(({ icxALaCarte }) => ({
                  icxALaCarte: !icxALaCarte,
                }))
              }
            />
          </Col>
          <Col as={Form.Group}>
            <Form.Label>Provider Status</Form.Label>
            <SingleSelect
              id="providerStatus"
              onChange={(status) => this.setProviderStatus(status)}
              value={providerStatus}
              options={[
                { value: "active", label: "Active" },
                { value: "beta", label: "Beta" },
                { value: "inactive", label: "Inactive" },
              ]}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col as={Form.Group} xs={6}>
            <Form.Label>Video Metadata Id</Form.Label>
            <Form.Control
              type="number"
              value={this.state.videoMetadataId}
              onChange={(e) =>
                this.setState({ videoMetadataId: Number(e.target.value) })
              }
            />
          </Col>
          <Col as={Form.Group} xs={6}>
            <Form.Label>ICX Channel Id</Form.Label>
            <Form.Control
              type="number"
              value={this.state.icxChannelId}
              onChange={(e) =>
                this.setState({ icxChannelId: Number(e.target.value) })
              }
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col as={Form.Group} xs={6}>
            <Form.Label>Sort order</Form.Label>
            <Form.Control
              size="sm"
              type="number"
              value={this.state.sortOrder}
              onChange={(e) =>
                this.setState({ sortOrder: Number(e.target.value) })
              }
            />
          </Col>
        </Row>
        <h5>TDC Import</h5>
        <Row className="mb-3">
          <Col as={Form.Group} xs={6}>
            <Form.Label>Provider Id</Form.Label>
            <Form.Control
              type="text"
              value={tdcImport.providerId}
              onChange={(e) =>
                this.setState({
                  tdcImport: { ...tdcImport, providerId: e.target.value },
                })
              }
            />
          </Col>

          <Col as={Form.Group} xs={6}>
            <Form.Label>Catalogue</Form.Label>
            <Form.Control
              type="text"
              value={tdcImport.catalogue}
              onChange={(e) =>
                this.setState({
                  tdcImport: { ...tdcImport, catalogue: e.target.value },
                })
              }
            />
          </Col>
        </Row>
        <h5>Logos</h5>
        <Row className="mb-3">
          <Col as={Form.Group} xs={6}>
            <Form.Label>Normal</Form.Label>
            <Form.Control
              type="text"
              value={logos.normalOnDark}
              onChange={(e) =>
                this.setState({
                  logos: { ...logos, normalOnDark: e.target.value },
                })
              }
            />
          </Col>

          <Col xs={6} />
        </Row>
        <Row className="mb-3">
          <Col as={Form.Group} xs={6}>
            <Form.Label>Fixed on Dark</Form.Label>
            <Form.Control
              type="text"
              value={logos.fixedOnDark}
              onChange={(e) =>
                this.setState({
                  logos: { ...logos, fixedOnDark: e.target.value },
                })
              }
            />
          </Col>
          <Col as={Form.Group} xs={6}>
            <Form.Label>Fixed on Light</Form.Label>
            <Form.Control
              type="text"
              value={logos.fixedOnLight}
              onChange={(e) =>
                this.setState({
                  logos: { ...logos, fixedOnLight: e.target.value },
                })
              }
            />
          </Col>
        </Row>
        <h5>Offline Availability</h5>
        <Row>
          <Col xs={2} className="flex-row align-items-center">
            <Form.Check
              id="offlineEnabled"
              type="checkbox"
              checked={this.state.offlineEnabled}
              onChange={(e) =>
                this.setState({
                  offlineEnabled: e.target.checked,
                })
              }
              label="Enable offline"
            />
          </Col>
          <Col as={Form.Group} xs={3}>
            <Form.Label>License Duration (Hours)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Duration in hours"
              disabled={!this.state.offlineEnabled}
              value={this.state.offline.licenseDurationInHours}
              onChange={(e) =>
                this.setState({
                  offline: {
                    ...this.state.offline,
                    licenseDurationInHours: Number(e.target.value),
                  },
                })
              }
            />
          </Col>
          <Col as={Form.Group} xs={3}>
            <Form.Label>Local Storage Duration (Hours)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Duration in hours"
              disabled={!this.state.offlineEnabled}
              value={this.state.offline.localStorageDurationInHours}
              onChange={(e) =>
                this.setState({
                  offline: {
                    ...this.state.offline,
                    localStorageDurationInHours: Number(e.target.value),
                  },
                })
              }
            />
          </Col>
          <Col xs={4} className="flex-row align-items-center">
            <Form.Check
              id="requireOfflineFlagOnAsset"
              type="checkbox"
              disabled={!this.state.offlineEnabled}
              checked={this.state.offline.requireOfflineFlagOnAsset}
              onChange={(e) =>
                this.setState({
                  offline: {
                    ...this.state.offline,
                    requireOfflineFlagOnAsset: e.target.checked,
                  },
                })
              }
              label="Require offline flag on assets"
            />
          </Col>
        </Row>
        <h5>Trailer</h5>
        <Row className="mb-3">
          <Col>
            <Form.Check
              id="hasTrailer"
              type="checkbox"
              checked={this.state.hasTrailer}
              onChange={(e) => this.setState({ hasTrailer: e.target.checked })}
              label="Has trailer"
            />
          </Col>
        </Row>
        <h5>Deep link</h5>
        <Row className="mb-3">
          <Col>
            <Form.Check
              id="isDeepLinked"
              type="checkbox"
              checked={this.state.isDeepLinked}
              onChange={(e) =>
                this.setState({ isDeepLinked: e.target.checked })
              }
              label="Is deep linked"
            />
          </Col>
        </Row>
        <Button
          className="pull-right mt-4"
          variant="primary"
          type="submit"
          disabled={!this.isValid()}
        >
          {this.props.mode}
        </Button>
      </Form>
    );
  }
}

export default VODProviderComposer;
