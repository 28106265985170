import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import CloudImageControl from "client/components/image/CloudImageControl";
import { CmsPure, formatSimpleTypeaheadSelection } from "client/utils";
import { SingleSelect } from "components/pickers";
import { getComposerTitle } from "../provisioningUtils";

const LABEL = "TV Provider";

const originOptions = [
  { value: "norigin", label: "Norigin" },
  { value: "tvm", label: "TVM" },
  { value: "", label: "Disabled" },
];

class TVProviderComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      name: "",
      shortName: "",
      videoMetadataId: 0,
      logos: {
        fixedOnDark: "",
        fixedOnLight: "",
        normalOnDark: "",
        detailOnDark: "",
      },
      caIds: [],
      playableAwayFromHome: true,
      recordable: true,
      redBeeServiceId: 0,
      icxChannelId: 0,
      icxALaCarte: false,
      widevineCasAssetId: "",
      httpStream: {
        origin: "",
        channelRecordingId: "",
        drmAssetId: "",
        hlsStream: "",
        dashStream: "",
        dvrWindowInMinutes: 360,
      },
      catchupEnabled: false,
      externalCatchup: false,
      catchup: {
        durationInDays: undefined,
        restrictedToStb: false,
      },
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) return;

    this.props.get(id, (state) => {
      const modifiedState = {
        ...state,
        catchupEnabled: !!state.catchup,
      };
      this.setState(modifiedState);
    });
  }

  create = (event) => {
    event.preventDefault();
    const payload = { ...this.state };
    const {
      origin,
      channelRecordingId,
      drmAssetId,
      hlsStream,
      dashStream,
      dvrWindowInMinutes,
    } = payload.httpStream;

    // remove optional properties if the user did not set them
    if (origin === "") delete payload.httpStream;
    else if (
      !channelRecordingId ||
      !drmAssetId ||
      !hlsStream ||
      !dashStream ||
      !dvrWindowInMinutes
    ) {
      toast.warn("All httpStream fields are required");
      return;
    }
    // catchup can only be used with stream
    if (!payload.httpStream || !payload.catchupEnabled) delete payload.catchup;
    else if (!payload.catchup.durationInDays) {
      toast.warn("Catchup duration is required");
      return;
    }
    if (!payload.icxChannelId) delete payload.icxChannelId;

    this.props.put(payload, this.props.id, LABEL);
  };

  isValid = () => {
    const { name, shortName, redBeeServiceId, videoMetadataId, logos } =
      this.state;
    if (
      !logos.fixedOnDark ||
      !logos.fixedOnLight ||
      !logos.normalOnDark ||
      !logos.detailOnDark
    )
      return false;
    return name && shortName && redBeeServiceId && videoMetadataId;
  };

  // shortName is generally just name so update them together if they are equal
  setName = (e) => {
    const name = e.target.value;
    const props = { name };
    if (this.state.name === this.state.shortName) props.shortName = name;
    this.setState(props);
  };

  toggleStateHandler = (key) => () =>
    this.setState((state) => ({ [key]: !state[key] }));

  render() {
    const { logos, httpStream } = this.state;
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    const httpStreamEnabled = !!httpStream.origin;
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{title}</h1>
        <Row>
          <Col as={Form.Group} xs={8}>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={this.state.name}
              placeholder={LABEL + " name"}
              onChange={this.setName}
            />
          </Col>
          <Col as={Form.Group} xs={4}>
            <Form.Label>Short Name</Form.Label>
            <Form.Control
              type="text"
              placeholder={"Shortened " + LABEL + " name"}
              value={this.state.shortName}
              onChange={(e) => this.setState({ shortName: e.target.value })}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col as={Form.Group} xs={8}>
            <Form.Label>Conditional Access Ids</Form.Label>
            <Typeahead
              id="caIds"
              multiple
              allowNew
              emptyLabel="Type to add"
              newSelectionPrefix="Add conditional access id: "
              options={this.state.caIds}
              selected={this.state.caIds}
              onChange={(values) =>
                this.setState({ caIds: formatSimpleTypeaheadSelection(values) })
              }
            />
          </Col>
          <Col xs={4}>
            <Form.Check
              id="active"
              type="checkbox"
              checked={this.state.active}
              onChange={this.toggleStateHandler("active")}
              label={LABEL + " is active"}
            />
            <Form.Check
              id="playableAwayFromHome"
              type="checkbox"
              checked={this.state.playableAwayFromHome}
              onChange={this.toggleStateHandler("playableAwayFromHome")}
              label="Content is playable away from home"
            />
            <Form.Check
              id="recordable"
              type="checkbox"
              checked={this.state.recordable}
              onChange={this.toggleStateHandler("recordable")}
              label="Channel allows recording"
            />
            <Form.Check
              id="icxALaCarte"
              type="checkbox"
              checked={this.state.icxALaCarte}
              onChange={this.toggleStateHandler("icxALaCarte")}
              label="Channel is an ICX a-la-carte choice"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col as={Form.Group}>
            <Form.Label>Video Metadata Id</Form.Label>
            <Form.Control
              type="number"
              value={this.state.videoMetadataId}
              onChange={(e) =>
                this.setState({ videoMetadataId: Number(e.target.value) })
              }
            />
          </Col>
          <Col as={Form.Group}>
            <Form.Label>ICX Channel Id</Form.Label>
            <Form.Control
              type="number"
              value={this.state.icxChannelId}
              onChange={(e) =>
                this.setState({ icxChannelId: Number(e.target.value) })
              }
            />
          </Col>
          <Col as={Form.Group}>
            <Form.Label>EPG Service Id</Form.Label>
            <Form.Control
              type="number"
              value={this.state.redBeeServiceId}
              onChange={(e) =>
                this.setState({ redBeeServiceId: Number(e.target.value) })
              }
            />
          </Col>
          <Col as={Form.Group}>
            <Form.Label>Widevine CAS Asset Id</Form.Label>
            <Form.Control
              type="text"
              value={this.state.widevineCasAssetId}
              onChange={(e) =>
                this.setState({ widevineCasAssetId: e.target.value })
              }
            />
          </Col>
        </Row>

        <h5 className="mt-4">Logos</h5>
        <Row>
          <Col as={Form.Group} xs={6}>
            <Form.Label>Normal</Form.Label>
            <CloudImageControl
              type="channel-logo"
              onChange={(path) => {
                this.setState({ logos: { ...logos, normalOnDark: path } });
              }}
              onDelete={() =>
                this.setState({ logos: { ...logos, normalOnDark: "" } })
              }
              value={logos.normalOnDark}
              accept="image/svg+xml"
              validateResolution={false}
              description="svg"
            />
          </Col>

          <Col as={Form.Group} xs={6}>
            <Form.Label>Detail on Dark</Form.Label>
            <CloudImageControl
              type="channel-logo"
              onChange={(path) => {
                this.setState({ logos: { ...logos, detailOnDark: path } });
              }}
              onDelete={() =>
                this.setState({ logos: { ...logos, detailOnDark: "" } })
              }
              value={logos.detailOnDark}
              accept="image/svg+xml"
              validateResolution={false}
              description="svg"
            />
          </Col>

          <Col as={Form.Group} xs={6}>
            <Form.Label>Fixed on Dark</Form.Label>
            <CloudImageControl
              type="channel-logo"
              onChange={(path) => {
                this.setState({ logos: { ...logos, fixedOnDark: path } });
              }}
              onDelete={() =>
                this.setState({ logos: { ...logos, fixedOnDark: "" } })
              }
              value={logos.fixedOnDark}
              accept="image/svg+xml"
              validateResolution={false}
              description="svg"
            />
          </Col>
          <Col as={Form.Group} xs={6}>
            <Form.Label>Fixed on Light</Form.Label>
            <CloudImageControl
              type="channel-logo"
              onChange={(path) => {
                this.setState({ logos: { ...logos, fixedOnLight: path } });
              }}
              onDelete={() =>
                this.setState({ logos: { ...logos, fixedOnLight: "" } })
              }
              value={logos.fixedOnLight}
              accept="image/svg+xml"
              validateResolution={false}
              description="svg"
            />
          </Col>
        </Row>

        <h5 className="mt-4">Stream</h5>
        <Row>
          <Col xs={4}>
            <Form.Label>Origin</Form.Label>
            <SingleSelect
              value={httpStream.origin}
              options={originOptions}
              onChange={(value) =>
                this.setState({ httpStream: { ...httpStream, origin: value } })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col as={Form.Group} xs={12}>
            <Form.Label>HLS Stream</Form.Label>
            <Form.Control
              type="text"
              value={httpStream.hlsStream}
              onChange={(e) =>
                this.setState({
                  httpStream: { ...httpStream, hlsStream: e.target.value },
                })
              }
              disabled={!httpStreamEnabled}
            />
          </Col>
        </Row>
        <Row>
          <Col as={Form.Group} xs={12}>
            <Form.Label>Dash Stream</Form.Label>
            <Form.Control
              type="text"
              value={httpStream.dashStream}
              onChange={(e) =>
                this.setState({
                  httpStream: { ...httpStream, dashStream: e.target.value },
                })
              }
              disabled={!httpStreamEnabled}
            />
          </Col>
        </Row>

        <Row>
          <Col as={Form.Group}>
            <Form.Label>Channel Recording Id</Form.Label>
            <Form.Control
              type="text"
              value={httpStream.channelRecordingId}
              onChange={(e) =>
                this.setState({
                  httpStream: {
                    ...httpStream,
                    channelRecordingId: e.target.value,
                  },
                })
              }
              disabled={!httpStreamEnabled}
            />
          </Col>

          <Col as={Form.Group}>
            <Form.Label>DRM Asset Id</Form.Label>
            <Form.Control
              type="text"
              value={httpStream.drmAssetId}
              onChange={(e) =>
                this.setState({
                  httpStream: { ...httpStream, drmAssetId: e.target.value },
                })
              }
              disabled={!httpStreamEnabled}
            />
          </Col>

          <Col as={Form.Group}>
            <Form.Label>DVR Window (in minutes)</Form.Label>
            <Form.Control
              type="number"
              value={httpStream.dvrWindowInMinutes}
              onChange={(e) =>
                this.setState({
                  httpStream: {
                    ...httpStream,
                    dvrWindowInMinutes: e.target.value,
                  },
                })
              }
              disabled={!httpStreamEnabled}
            />
          </Col>
        </Row>

        <h5 className="mt-4">Catchup (Requires stream)</h5>
        <Row>
          <Col xs={3}>
            <Form.Check
              id="catchupEnabled"
              type="checkbox"
              checked={httpStreamEnabled && this.state.catchupEnabled}
              onChange={this.toggleStateHandler("catchupEnabled")}
              label="Enable catchup"
              disabled={!httpStreamEnabled}
            />
          </Col>
          <Col xs={3}>
            <Form.Check
              id="catchup.restrictedToStb"
              type="checkbox"
              checked={this.state.catchup.restrictedToStb}
              onChange={() =>
                this.setState(({ catchup }) => ({
                  catchup: {
                    ...catchup,
                    restrictedToStb: !catchup.restrictedToStb,
                  },
                }))
              }
              label="Restrict catchup to STB"
              disabled={!httpStreamEnabled || !this.state.catchupEnabled}
            />
          </Col>
          <Col xs={3}>
            <Form.Check
              id="externalCatchup"
              type="checkbox"
              checked={this.state.externalCatchup}
              onChange={this.toggleStateHandler("externalCatchup")}
              label="Enable external catchup"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Form.Label>Catchup duration in days</Form.Label>
            <Form.Control
              type="number"
              placeholder="Duration"
              value={this.state.catchup.durationInDays}
              onChange={(e) =>
                this.setState({
                  catchup: {
                    ...this.state.catchup,
                    durationInDays: Number(e.target.value),
                  },
                })
              }
              disabled={!httpStreamEnabled || !this.state.catchupEnabled}
            />
          </Col>
        </Row>
        <Button
          className="pull-right mt-4"
          variant="primary"
          type="submit"
          disabled={!this.isValid()}
        >
          {this.props.mode}
        </Button>
      </Form>
    );
  }
}

export default TVProviderComposer;
