import React from "react";
import { Col, Row } from "react-bootstrap";
import { ChannelFilter } from "./ChannelFilter";
import { DateRangeFilter } from "./DateRangeFilter";
import { ExcludedChannelsFilter } from "./ExcludedChannelsFilter";
import { KindFilter } from "./KindFilter";
import { ShowAcceptedToggle } from "./ShowAcceptedToggle";
import { ShowMarkedWarningsOnlyToggle } from "./ShowMarkedWarningsOnlyToggle";
import { ShowReportedToggle } from "./ShowReportedToggle";
import { ShowSpamToggle } from "./ShowSpamToggle";
import { TitleFilter } from "./TitleFilter";
import { TypeFilter } from "./TypeFilter";
import { WarningFilter } from "./WarningFilter";

const SearchFilter = () => {
  return (
    <>
      <Row className="mt-4 mb-2">
        <Col xs={6}>
          <TitleFilter />
        </Col>
        <Col xs={3}>
          <TypeFilter />
        </Col>
        <Col xs={3}>
          <KindFilter />
        </Col>
      </Row>
      <Row className="mb-2">
        <DateRangeFilter />
        <Col>
          <WarningFilter />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <ChannelFilter />
        </Col>
        <Col xs={6}>
          <ExcludedChannelsFilter />
        </Col>
      </Row>
      <Row className="mt-2 mb-4">
        <Col xs={2}>
          <ShowMarkedWarningsOnlyToggle />
        </Col>
        <Col xs={2}>
          <ShowReportedToggle />
        </Col>
        <Col xs={2}>
          <ShowAcceptedToggle />
        </Col>
        <Col xs={2}>
          <ShowSpamToggle />
        </Col>
      </Row>
    </>
  );
};

export default SearchFilter;
