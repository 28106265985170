import { DateTime } from "luxon";
import React from "react";
import { Column } from "react-table-v7";
import { NumberParam, useQueryParam } from "use-query-params";
import { getPrimaryTitle, getPrimaryTopTitle } from "client/utils/AssetInfo";
import Table from "components/tables";
import { TableRow } from "../types";

const columns: Column<TableRow>[] = [
  {
    Header: "Start",
    accessor: (row) => {
      if (!row.teliaNo) {
        return null;
      }
      return DateTime.fromISO(row.teliaNo.linear_start).toFormat("HH:mm");
    },
  },
  {
    Header: "End",
    accessor: (row) => {
      if (!row.teliaNo) {
        return null;
      }
      return DateTime.fromISO(row.teliaNo.linear_end).toFormat("HH:mm");
    },
  },
  {
    Header: "Title",
    accessor: (row) => {
      if (!row.teliaNo) {
        return <div>&nbsp;</div>;
      }
      return (
        <div>
          {getPrimaryTopTitle(row.teliaNo) || "___"} -{" "}
          {getPrimaryTitle(row.teliaNo)}
        </div>
      );
    },
  },
];

interface Props {
  combinedPrograms?: TableRow[];
}

export const ProgramList = ({ combinedPrograms }: Props) => {
  const [teliaNoChannel] = useQueryParam("teliaNoChannel", NumberParam);
  if (!teliaNoChannel) {
    return null;
  }
  if (!combinedPrograms) {
    return <div>Loading...</div>;
  }
  return <Table columns={columns} data={combinedPrograms} />;
};
