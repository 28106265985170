import React from "react";
import { Form } from "react-bootstrap";
import {
  Menu,
  MenuItem,
  Token,
  TokenProps,
  Typeahead,
  TypeaheadMenuProps,
  TypeaheadResult,
} from "react-bootstrap-typeahead";
import { ProvidersContext } from "client/App";
import { IProviders, Providers } from "client/types";

interface ProviderWithHitCount extends IProviders {
  count?: number;
}

const getLabel = (value: ProviderWithHitCount) => {
  let label = value.value;
  if (value.count) {
    label = `${label}: ${value.count}`;
  }
  return label;
};

const renderProviderToken = (
  option: IProviders,
  props: TokenProps,
  index: number
) => {
  const style = option.active ? {} : { textDecoration: "line-through" };
  return (
    <Token key={index} option={option} onRemove={props.onRemove} style={style}>
      {getLabel(option)}
    </Token>
  );
};

const renderProviderMenu = (
  results: TypeaheadResult<IProviders>[],
  menuProps: TypeaheadMenuProps<IProviders>
) => {
  return (
    <Menu {...menuProps}>
      {results.map((result, index) => (
        <MenuItem
          key={index}
          option={result}
          style={result.active ? {} : { textDecoration: "line-through" }}
          position={index}
        >
          {getLabel(result)}
        </MenuItem>
      ))}
    </Menu>
  );
};

interface Props {
  onChange: (selected: number[]) => void;
  providersValue: number[];
  readOnly: boolean;
  providerCounts?: { [providerId: number]: number };
}

const useProviderOptions = (providerCounts?: {
  [providerId: number]: number;
}) => {
  const providers = React.useContext(ProvidersContext);
  const providersThatHaveCounts: ProviderWithHitCount[] = [];
  const providersThatDoNotHaveCounts: ProviderWithHitCount[] = [];
  providers.forEach((provider) => {
    if (!providerCounts) {
      providersThatDoNotHaveCounts.push(provider);
      return;
    }

    const providerCount = providerCounts[Number(provider.id)];
    if (!providerCount) {
      providersThatDoNotHaveCounts.push(provider);
      return;
    }
    providersThatHaveCounts.push({ ...provider, count: providerCount });
  });
  return [...providersThatHaveCounts, ...providersThatDoNotHaveCounts];
};

export const FilterProviderPicker = ({
  onChange,
  providersValue,
  readOnly,
  providerCounts,
}: Props) => {
  const providers = useProviderOptions(providerCounts);
  const providerObjs = providersValue
    .map((val) => providers.find((provider) => Number(provider.id) === val))
    .filter((pr) => !!pr) as Providers;
  return (
    <>
      <Form.Label>Filter by provider</Form.Label>
      <Typeahead
        id="collection-provider-picker"
        multiple
        selected={providerObjs}
        options={providers}
        labelKey="value"
        placeholder="Provider filter"
        onChange={(selectedProviders) => {
          onChange(selectedProviders.map((p): number => parseInt(p.id)));
        }}
        renderToken={renderProviderToken}
        renderMenu={renderProviderMenu}
        disabled={readOnly}
      />
    </>
  );
};
