import _ from "lodash";
import ParamTypes from "./paramtypes";

/**
 * Create a query string from a set of parameter values.
 *
 * Serialize a set of parameter values into a query string.  Parameters
 * can be passed in either as plain key/value pairs or as parameter
 * objects with serialization settings, as returned in the 'params'
 * attribute of the return value of useQueryParams().
 *
 * The optional defaultQueryString parameter can be used to generate a
 * new query string using another string as a template:
 *
 *   > getQueryString({ name: "johndoe" }, "id=1&name=foobar")
 *   'id=1&name=johndoe'
 */
export default function getQueryString(params, defaultQueryString = "") {
  const urlParams = new URLSearchParams(defaultQueryString);

  for (const [name, optionsOrValue] of Object.entries(params)) {
    let options;

    if (isParamOptions(optionsOrValue)) {
      options = optionsOrValue;
    } else if (Array.isArray(optionsOrValue)) {
      options = {
        serializedName: name,
        value: optionsOrValue,
        multi: true,
        type: ParamTypes.raw,
      };
    } else {
      options = {
        serializedName: name,
        value: optionsOrValue,
        type: ParamTypes.raw,
      };
    }

    urlParams.delete(options.serializedName);

    if (options.value !== null && options.value !== undefined) {
      const paramValues = options.multi ? options.value : [options.value];
      for (const v of paramValues) {
        urlParams.append(options.serializedName, options.type.serialize(v));
      }
    }
  }

  return urlParams.toString();
}

const isParamOptions = (o) => {
  const requiredProperties = ["serializedName", "value", "type"];
  return o instanceof Object && _.every(requiredProperties, (p) => p in o);
};
