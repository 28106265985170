import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { StringParam, useQueryParam } from "use-query-params";

const GET_CHANNELS = gql`
  query GetChannels($limit: Int) {
    channels(limit: $limit) {
      channelItems {
        id
        name
      }
    }
  }
`;

interface Response {
  channels: {
    channelItems: {
      id: string;
      name: string;
    }[];
  };
}

export const ChannelSelector = () => {
  const { loading, error, data } = useQuery<Response>(GET_CHANNELS, {
    variables: { limit: 1000 },
  });
  const [channel, setChannel] = useQueryParam("oneappChannel", StringParam);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error : {error.message}</div>;
  }
  if (!data) {
    return null;
  }

  return (
    <>
      <Typeahead
        id="oano-channel-selector"
        selected={data.channels.channelItems.filter(({ id }) => id === channel)}
        options={data.channels.channelItems}
        labelKey="name"
        placeholder="Select channel"
        onChange={(selected) => {
          if (selected.length > 0) {
            setChannel(selected[0].id);
          } else {
            setChannel(null);
          }
        }}
      />
    </>
  );
};
