import _ from "lodash";
import { STATUS } from "client/hooks";

const normalizeCurrentValue = (v) => (v !== "" ? v : undefined);

const getValue = (field) => {
  if (field) {
    const { source, value, currentValue } = field;
    return {
      value:
        currentValue !== undefined
          ? normalizeCurrentValue(currentValue)
          : value,
      changed: currentValue !== undefined || source === "patched",
    };
  }

  return {
    value: undefined,
    changed: false,
  };
};

const getChanges = (state) => {
  const { mc, applyGenresToChildren } = state;
  const { id, data } = mc;

  const titleEntries = Object.entries(data.titles);
  const titles = {};
  const storylines = {};

  let titlesChanged = false;
  let storylinesChanged = false;

  for (const [language, annotatedTitle] of titleEntries) {
    const { value, changed } = getValue(annotatedTitle);
    titles[language] = value;
    titlesChanged = titlesChanged || changed;
  }

  for (const language of Object.keys(data.storylines)) {
    const storylineEntries = Object.entries(data.storylines[language]);
    storylines[language] = {};

    for (const [key, annotatedStoryline] of storylineEntries) {
      const { value, changed } = getValue(annotatedStoryline);
      storylines[language][key] = value;
      storylinesChanged = storylinesChanged || changed;
    }
  }

  const changes = { id };

  if (titlesChanged) {
    changes.titles = titles;
  }

  if (storylinesChanged) {
    changes.storylines = storylines;
  }

  const metadataKeys = [
    "productionDate",
    "index",
    "crid",
    "genres",
    "keywords",
    "adultContent",
  ];
  for (const key of metadataKeys) {
    const { value, changed } = getValue(data[key]);
    if (changed) {
      changes[key] = value;
    }
  }

  const images = [];
  let imagesChanged = false;

  for (const { source, dirty, value } of data.images) {
    if (source !== "deleted") {
      images.push(value);
    }

    if (dirty || source !== "original") {
      imagesChanged = true;
    }
  }

  if (imagesChanged) {
    changes.images = images;
  }

  const options = { applyGenresToChildren };
  return { data: changes, options };
};

function getMetadataApiChanges(state) {
  if (
    !state.parentMediaContentId ||
    !state.parentMediaContentId.currentValue ||
    state.parentMediaContentId.currentValue ===
      state.parentMediaContentId.original
  ) {
    // parent media content id is unchanged; no changes required
    return null;
  }
  return {
    mediaContentId: state.mc.id,
    parentMediaContentId: parseInt(state.parentMediaContentId.currentValue),
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case "mc.load.init":
      return {
        ...state,
        status: STATUS.FETCHING,
      };
    case "mc.load.success":
      return {
        ...state,
        mc: action.payload,
        parentMediaContentId: action.payload.data.parent,
        status: STATUS.OK,
      };
    case "mc.load.error":
      return {
        ...state,
        status: STATUS.ERROR,
      };
    case "mc.save.init":
      return {
        ...state,
        saving: true,
        changes: getChanges(state),
        metadataApiChanges: getMetadataApiChanges(state),
      };
    case "mc.save.success":
      return {
        ...state,
        saving: false,
        changes: undefined,
        dirty: false,
      };
    case "mc.title.update": {
      const { language, value } = action.payload;

      return _.merge(
        { ...state },
        {
          dirty: true,
          mc: {
            data: {
              titles: {
                [language]: { currentValue: value },
              },
            },
          },
        }
      );
    }
    case "mc.storyline.update": {
      const { language, value, key } = action.payload;

      return _.merge(
        { ...state },
        {
          dirty: true,
          mc: {
            data: {
              storylines: {
                [language]: {
                  [key]: {
                    currentValue: value,
                  },
                },
              },
            },
          },
        }
      );
    }
    case "mc.genres.update": {
      const { language, genres } = action.payload;
      const dataGenres = state.mc.data.genres;
      const allGenres = dataGenres?.currentValue || dataGenres?.value || [];
      const otherGenres = allGenres.filter((g) => g.language !== language);
      const newGenres = [...otherGenres, ...genres];

      const merged = _.mergeWith(
        { ...state },
        {
          dirty: true,
          mc: {
            data: {
              genres: { currentValue: newGenres },
            },
          },
        },
        (objValue, srcValue) => {
          // Override the default array merge behavior from _.merge() and
          // _.mergeWith() to make sure that we only use the array with
          // updated genres without trying to merge it with the old state.
          if (_.isArray(objValue) && _.isArray(srcValue)) {
            return srcValue;
          }
        }
      );
      return merged;
    }
    case "mc.keywords.update": {
      const { language, keywords } = action.payload;
      const dataKeywords = state.mc.data.keywords;
      const allKeywords =
        dataKeywords?.currentValue || dataKeywords?.value || [];
      const otherKeywords = allKeywords.filter((g) => g.language !== language);
      const newKeywords = [...otherKeywords, ...keywords];

      const merged = _.mergeWith(
        { ...state },
        {
          dirty: true,
          mc: {
            data: {
              keywords: { currentValue: newKeywords },
            },
          },
        },
        (objValue, srcValue) => {
          // Override the default array merge behavior from _.merge() and
          // _.mergeWith() to make sure that we only use the array with
          // updated genres without trying to merge it with the old state.
          if (_.isArray(objValue) && _.isArray(srcValue)) {
            return srcValue;
          }
        }
      );
      return merged;
    }
    case "mc.metadata.update": {
      const data = _.reduce(
        action.payload,
        (result, value, key) => {
          result[key] = { currentValue: value };
          return result;
        },
        {}
      );

      return _.merge(
        { ...state },
        {
          dirty: true,
          mc: { data },
        }
      );
    }
    case "mc.image.add": {
      const img = {
        source: "patched",
        dirty: true,
        value: action.payload,
      };

      return _.mergeWith(
        { ...state },
        {
          dirty: true,
          mc: { data: { images: [img] } },
        },
        (objValue, srcValue) => {
          // Override the default array merge behavior from _.merge() and
          // _.mergeWith() to push the added image onto the
          // array
          if (_.isArray(objValue) && _.isArray(srcValue)) {
            return [...objValue, ...srcValue];
          }
        }
      );
    }
    case "mc.image.delete": {
      const images = _.clone(state.mc.data.images);
      const deletedImage = action.payload;
      const img = _.find(images, (img) => _.isEqual(img, deletedImage));
      img.source = "deleted";
      img.dirty = true;

      return _.mergeWith(
        { ...state },
        {
          dirty: true,
          mc: { data: { images } },
        },
        (objValue, srcValue) => {
          // Override the default array merge behavior from _.merge() and
          // _.mergeWith() to make sure that we only use the array with
          // updated images without trying to merge it with the old state.
          if (_.isArray(objValue) && _.isArray(srcValue)) {
            return srcValue;
          }
        }
      );
    }
    case "mc.image.restore": {
      const images = _.clone(state.mc.data.images);
      const restoredImage = action.payload;
      const img = _.find(images, (img) => _.isEqual(img, restoredImage));

      if (img.original) {
        img.source = "original";
      } else {
        img.source = "patched";
      }
      img.dirty = true;

      return _.mergeWith(
        { ...state },
        {
          dirty: true,
          mc: { data: { images } },
        },
        (objValue, srcValue) => {
          // Override the default array merge behavior from _.merge() and
          // _.mergeWith() to make sure that we only use the array with
          // updated images without trying to merge it with the old state.
          if (_.isArray(objValue) && _.isArray(srcValue)) {
            return srcValue;
          }
        }
      );
    }
    case "editor.applyGenresToChildren.updated": {
      const { applyGenresToChildren } = action.payload;
      return {
        ...state,
        applyGenresToChildren,
      };
    }
    case "language.changed": {
      const { languageCode } = action.payload;
      return {
        ...state,
        selectedLanguage: languageCode,
      };
    }
    case "language.added": {
      const { languageCode } = action.payload;
      return _.merge(
        { ...state },
        {
          selectedLanguage: languageCode,
          mc: {
            data: {
              storylines: {
                [languageCode]: {},
              },
            },
          },
        }
      );
    }
    case "parent.update": {
      const { parent } = action.payload;
      return _.merge(
        { ...state },
        {
          dirty: true,
          parentMediaContentId: { currentValue: parent },
        }
      );
    }
    default:
      return state;
  }
}

export const initialState = {
  mc: undefined,
  selectedLanguage: "no",
  applyGenresToChildren: false,
  status: STATUS.OK,
  dirty: false,
  saving: false,
};
