import React from "react";
import { Button } from "react-bootstrap";
import { Spinner } from "components/Spinner";

interface Props {
  loaderRef: React.MutableRefObject<null>;
  isEmpty: boolean;
  isMoreAvailable?: boolean;
  isLoadingMore?: boolean;
  autoScroll?: boolean;
  onLoadMoreClick?: () => void;
}

function Pagination({
  loaderRef,
  isEmpty,
  isMoreAvailable,
  isLoadingMore,
  onLoadMoreClick,
  autoScroll = false,
}: Props) {
  return (
    <div ref={autoScroll ? loaderRef : null}>
      {isMoreAvailable && !isEmpty && !autoScroll ? (
        <Button onClick={onLoadMoreClick} disabled={isLoadingMore}>
          {isLoadingMore ? "Loading..." : "Load more"}
        </Button>
      ) : isLoadingMore ? (
        <Spinner />
      ) : isEmpty ? (
        "There are no results. Adjust your search."
      ) : !isMoreAvailable ? (
        "End of results."
      ) : (
        ""
      )}
    </div>
  );
}

export default Pagination;
