import React, { useEffect, useMemo } from "react";
import {
  useFilters,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table-v7";
import useSWR from "swr";
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { getQueryString, useDebouncedValue } from "client/hooks";
import { useReactTableSort } from "client/hooks/useQueryParams/extras";
import { MomentParam } from "client/types/MomentParam";
import { LoadingOverlayWrapper } from "client/utils";
import { ControlledReactTable, ReactTablePagination } from "components/tables";
import BulkActions from "../BulkActions";
import { useProviders } from "../hooks";
import { getActiveProviders } from "./getActiveProviders";
import { useColumns } from "./useColumns";

function countToString(count) {
  switch (count) {
    // elastic caps the search results to 10k
    case 10000:
      return count.toLocaleString() + "+";
    case 0:
      return "no";
    default:
      return count.toLocaleString();
  }
}

const INITIAL_SORT = [{ id: "revisionUpdated", desc: true }];

function Assets() {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 20),
    assetPlayStatus: withDefault(ArrayParam, []),
    assetLicenseStatus: withDefault(ArrayParam, []),
    revisionUheStatus: withDefault(ArrayParam, []),
    revisionPlayStatus: withDefault(ArrayParam, []),
    dataQualityWarnings: withDefault(ArrayParam, []),
    providerId: withDefault(NumericArrayParam, []),
    yaafAssetId: StringParam,
    providerAssetId: StringParam,
    from: MomentParam,
    to: MomentParam,
    availability: withDefault(ArrayParam, []),
    title: StringParam,
    type: StringParam,
    issueReports: StringParam,
    activeOnly: withDefault(BooleanParam, false),
  });

  const gotoPage = (newPage) => setQuery({ page: newPage });
  const setPageSize = (newPageSize) => setQuery({ limit: newPageSize });

  const [sort, setSort] = useReactTableSort(INITIAL_SORT);

  const { providers } = useProviders();

  const activeProviderIds = getActiveProviders(providers).map(
    (provider) => provider.id
  );
  const providerIdValue = getProviderIdValue(
    activeProviderIds,
    query.providerId,
    query.activeOnly
  );
  const qs = getQueryString({
    ...query,
    sort,
    providerId: providerIdValue,
  });
  const debouncedUrl = useDebouncedValue(`ams/assets?${qs}`);

  const fallbackData = useMemo(
    () => ({
      data: [],
      page: query.page,
      limit: query.limit,
      total: 1,
    }),
    [query.page, query.limit]
  );

  const {
    data: { data, page, limit, total },
    isValidating,
  } = useSWR(debouncedUrl, { fallbackData });

  const totalPages = Math.ceil(total / limit);

  const { columns, hiddenColumns } = useColumns(query.type, query.activeOnly);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setHiddenColumns,
    selectedFlatRows,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: INITIAL_SORT,
        hiddenColumns,
      },
      manualSortBy: true,
      autoResetSelectedRows: true,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useRowSelect,
    useResizeColumns
  );

  // Make sure that setHiddenColumns() from useTable() gets called whenever
  // the column selection changes in order to actually update the visible
  // columns in the table
  useEffect(() => setHiddenColumns(hiddenColumns), [hiddenColumns]);

  useEffect(() => {
    setSort(sortBy);
  }, [sortBy]);

  const assets = useMemo(
    () => selectedFlatRows.map((row) => row.original),
    [selectedFlatRows]
  );

  const toggleShowActive = (event) => {
    const willShowActiveOnly = event.target.checked;
    let newParams = { activeOnly: willShowActiveOnly };
    if (willShowActiveOnly) {
      // Remove any inactive provider ids when enabling the
      // "show active providers" checkbox
      newParams.providerId = query.providerId.filter((id) =>
        activeProviderIds.includes(id)
      );
    }
    setQuery(newParams);
  };

  return (
    <LoadingOverlayWrapper show={isValidating}>
      <label>
        <input
          type="checkbox"
          defaultChecked={query.activeOnly}
          onChange={toggleShowActive}
        />{" "}
        Show active providers only
      </label>
      <div className="text-muted">
        The query returned {countToString(total)} assets
      </div>
      <ControlledReactTable
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        rows={rows}
        placeholderProps={{ colSpan: columns.length }}
      />
      <ReactTablePagination
        pageIndex={page || 0}
        pageSize={limit || 20}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        totalPages={totalPages}
      />
      <BulkActions assets={assets} />
    </LoadingOverlayWrapper>
  );
}

const getProviderIdValue = (
  activeProviderIds,
  providerIdParam,
  showActiveOnly
) => {
  const defaultProviderIds = showActiveOnly ? activeProviderIds : [];

  return providerIdParam.length > 0 ? providerIdParam : defaultProviderIds;
};

export default Assets;
