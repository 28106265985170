import { isNumeric } from "@telia-company/tv.no-play-cms-common/api/util";
import autobind from "autobind-decorator";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { ChannelsContext, ProvidersContext } from "client/App";
import { CmsPure, duplicateSafeLabelKey } from "client/utils";
import SideMenu from "components/toolbar/SideMenu";
import AddProviderDialog from "./AddProviderDialog";

const OverviewSelector = ({ value, ...rest }) => {
  const options = [
    { value: false, label: "Timeline" },
    { value: true, label: "List view" },
  ];
  const selected = options.find((item) => item.value == value) || null;
  return (
    <Select isMulti={false} value={selected} options={options} {...rest} />
  );
};
OverviewSelector.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SECTION_OPTIONS = [
  { value: "home", label: "Hjem" },
  { value: "tvArchive", label: "TV" },
  { value: "series", label: "Serier" },
  { value: "movies", label: "Film" },
  { value: "kids", label: "Barn" },
  { value: "movieRental", label: "Leiefilm" },
  { value: "explore", label: "Utforsk" },
  { value: "sport", label: "Sport" },
];

function getValidSection(page) {
  const view = SECTION_OPTIONS.find((item) => item.value === page);
  if (view) return page;

  return SECTION_OPTIONS[0].value;
}

/**
 * @param {Object} url unparsed { section, channel, provider }
 * @param {Object} fallback placement object if unparsed url object is empty
 * @returns {Object} parsed { section, channel, provider }
 */
export function getValidPlacement(url, fallback) {
  const channel =
    url.channel && isNumeric(url.channel) ? Number(url.channel) : undefined;
  const provider =
    url.provider && isNumeric(url.provider) ? Number(url.provider) : undefined;
  const section = url.section ? getValidSection(url.section) : undefined;

  if (!channel && !provider && !section) return fallback;

  return { channel, provider, section };
}

export function isPlacementEqual(prev, next) {
  return (
    next.section === prev.section &&
    next.provider === prev.provider &&
    next.channel === prev.channel
  );
}

class SchedulerMenu extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      providersExpanded: true,
      channelsExpanded: true,
      showAddProviderDialog: false,
      showAddChannelDialog: false,
      providers: [26, 24, 6, 2],
      channels: [1],
    };
  }
  @autobind
  onAddProvider(provider) {
    this.setState({
      providers: [...this.state.providers, provider],
    });
  }
  @autobind
  onShowProviderDialog() {
    this.setState({ showAddProviderDialog: true });
  }
  @autobind
  onHideProviderDialog() {
    this.setState({ showAddProviderDialog: false });
  }
  @autobind
  onAddChannel(channel) {
    this.setState({
      channels: [...this.state.channels, channel],
    });
  }
  @autobind
  onShowChannelDialog() {
    this.setState({ showAddChannelDialog: true });
  }
  @autobind
  onHideChannelDialog() {
    this.setState({ showAddChannelDialog: false });
  }
  render() {
    const { getLinkTo, placement, showAsList, history } = this.props;
    return (
      <>
        <AddProviderDialog
          show={this.state.showAddProviderDialog}
          onAdd={this.onAddProvider}
          onHide={this.onHideProviderDialog}
          exclude={this.state.providers}
          mode="provider"
        />
        <AddProviderDialog
          show={this.state.showAddChannelDialog}
          onAdd={this.onAddChannel}
          onHide={this.onHideChannelDialog}
          exclude={this.state.channels}
          mode="channel"
        />
        <SideMenu className="m-4">
          <OverviewSelector
            value={showAsList}
            onChange={(item) =>
              history.push(getLinkTo({ listView: item.value || undefined }))
            }
          />
          <SideMenu.Header className="mt-4">Menu pages</SideMenu.Header>
          <div>
            {SECTION_OPTIONS.map((item) => (
              <SideMenu.Link
                key={item.value}
                selected={placement.section == item.value}
                to={getLinkTo({
                  section: item.value,
                  provider: undefined,
                  channel: undefined,
                })}
              >
                {item.label}
              </SideMenu.Link>
            ))}
          </div>
          <div className="mt-4">
            <SideMenu.Header
              className="cursor-pointer"
              onClick={() => {
                this.setState((prevState) => ({
                  providersExpanded: !prevState.providersExpanded,
                }));
              }}
            >
              <span
                className={classNames(
                  "fas",
                  this.state.providersExpanded
                    ? "fa-chevron-up"
                    : "fa-chevron-down",
                  "me-2 text-secondary"
                )}
              />
              Provider pages
            </SideMenu.Header>
            {this.state.providersExpanded && (
              <>
                <ProvidersContext.Consumer>
                  {(providers) => {
                    const labelKey = duplicateSafeLabelKey(providers);
                    return this.state.providers.map((provider) => {
                      const providerObj = providers.find(
                        (p) => p.id == provider
                      );
                      // return null if the context hasn't loaded yet
                      if (!providerObj) return null;
                      const label = labelKey(providerObj); // providerObj.value;

                      return (
                        <SideMenu.Link
                          key={provider}
                          selected={placement.provider == provider}
                          to={getLinkTo({
                            section: undefined,
                            provider,
                            channel: undefined,
                          })}
                        >
                          {label}
                        </SideMenu.Link>
                      );
                    });
                  }}
                </ProvidersContext.Consumer>
                <SideMenu.AddItem onClick={this.onShowProviderDialog}>
                  Add provider
                </SideMenu.AddItem>
              </>
            )}
          </div>

          <div className="mt-4">
            <SideMenu.Header
              className="cursor-pointer"
              onClick={() => {
                this.setState((prevState) => ({
                  channelsExpanded: !prevState.channelsExpanded,
                }));
              }}
            >
              <span
                className={classNames(
                  "fas",
                  this.state.channelsExpanded
                    ? "fa-chevron-up"
                    : "fa-chevron-down",
                  "me-2 text-secondary"
                )}
              />
              Channels pages
            </SideMenu.Header>

            {this.state.channelsExpanded && (
              <>
                <ChannelsContext.Consumer>
                  {(channels) => {
                    const labelKey = duplicateSafeLabelKey(channels);
                    return this.state.channels.map((channel) => {
                      const channelObj = channels.find(
                        (ch) => ch.id == channel
                      );
                      if (!channelObj) return null;
                      const label = labelKey(channelObj);

                      return (
                        <SideMenu.Link
                          key={channel}
                          selected={placement.channel == channel}
                          to={getLinkTo({
                            section: undefined,
                            provider: undefined,
                            channel,
                          })}
                        >
                          {label}
                        </SideMenu.Link>
                      );
                    });
                  }}
                </ChannelsContext.Consumer>
                <SideMenu.AddItem onClick={this.onShowChannelDialog}>
                  Add channel
                </SideMenu.AddItem>
              </>
            )}
          </div>
        </SideMenu>
      </>
    );
  }
}

SchedulerMenu.propTypes = {
  getLinkTo: PropTypes.func.isRequired,
  placement: PropTypes.shape({
    section: PropTypes.string,
    channel: PropTypes.number,
    provider: PropTypes.number,
  }).isRequired,
  showAsList: PropTypes.bool.isRequired,
};

export default withRouter(SchedulerMenu);
