import { PropTypes } from "prop-types";
import React, { useContext, useMemo } from "react";
import { KindsContext } from "client/App";
import { ToggleMultiControl } from "components/controls/ToggleControl";

export const KindMultiPicker = ({ onChange, value, excludeKinds = [] }) => {
  const kinds = useContext(KindsContext);
  const options = useMemo(
    () => kinds.filter((kind) => excludeKinds.indexOf(kind) === -1),
    [kinds, excludeKinds]
  );
  return (
    <ToggleMultiControl options={options} onChange={onChange} value={value} />
  );
};

KindMultiPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  excludeKinds: PropTypes.arrayOf(PropTypes.string),
};
