import { PropTypes } from "prop-types";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { PublicationTypes } from "client/types/PublicationTypes";
import { CmsPure, isValidDateRange } from "client/utils";
import { ConfirmDeleteButton, GlyphButton } from "components/buttons";
import TitleFormControl from "components/controls/TitleFormControl";
import DateTimeRangeControl from "components/controls/datetime/DateTimeRangeControl";
import { CollectionPicker, PromotionPicker } from "components/pickers";
import { ItemModalModes } from "../state";

function isValidNewSchedule(schedule, item) {
  if (!schedule) return false;
  if (!isValidDateRange(schedule.publish, schedule.unpublish)) return false;

  return !!item.id;
}

class ItemModal extends CmsPure {
  onTitleChange = (event) =>
    this.props.onChange({ schedule: { title: { $set: event.target.value } } });
  onPublishChange = (publish) =>
    this.props.onChange({ schedule: { publish: { $set: publish } } });
  onUnpublishChange = (unpublish) =>
    this.props.onChange({ schedule: { unpublish: { $set: unpublish } } });
  onChangeItem = (id) => this.props.onChange({ item: { $set: { id } } });

  onSubmit = (event) => {
    const { mode, onSave, onCreate } = this.props;
    event.preventDefault();
    if (mode === ItemModalModes.EDIT) onSave();
    else if (mode === ItemModalModes.CREATE) onCreate();
  };

  render() {
    const {
      schedule,
      item,
      itemType,
      show,
      canEdit,
      mode,
      toolbarActions,
      onHide,
    } = this.props;
    const readOnly = !canEdit || mode === ItemModalModes.VIEW;

    const renderedPreviewComponent =
      itemType === PublicationTypes.COLLECTION ? (
        <Col xs={{ span: 12, order: 3 }}>
          <CollectionPicker
            selectedId={item.id}
            initialValue={item}
            onChange={this.onChangeItem}
            readOnly={readOnly}
          />
        </Col>
      ) : itemType === PublicationTypes.COVER_PROMO ? (
        <Col xs={{ span: 4, order: 1 }}>
          <PromotionPicker
            selectedId={item.id}
            initialValue={item}
            onChange={this.onChangeItem}
            readOnly={readOnly}
            promotionType={PublicationTypes.COVER_PROMO}
          />
        </Col>
      ) : itemType === PublicationTypes.TOP_PROMO ? (
        <Col xs={{ span: 12, order: 3 }}>
          <PromotionPicker
            selectedId={item.id}
            initialValue={item}
            onChange={this.onChangeItem}
            readOnly={readOnly}
            promotionType={PublicationTypes.TOP_PROMO}
          />
        </Col>
      ) : null;

    return (
      <Modal autoFocus show={show} onHide={onHide} size="md">
        <form onSubmit={this.onSubmit}>
          <Modal.Body>
            <Row>
              <Col xs={{ order: 2 }}>
                <TitleFormControl
                  title={schedule.title}
                  placeholder="Custom title"
                  readOnly={readOnly}
                  onChangeTitle={this.onTitleChange}
                >
                  {!canEdit || mode !== ItemModalModes.VIEW ? (
                    mode
                  ) : (
                    <>
                      <GlyphButton
                        glyph="edit"
                        className="px-2"
                        variant="link"
                        aria-label="Edit"
                        onClick={toolbarActions.onEdit}
                      />
                      <ConfirmDeleteButton
                        className="mx-2 px-0"
                        onClick={toolbarActions.onDelete}
                        placement="right"
                      />
                    </>
                  )}
                </TitleFormControl>
                <DateTimeRangeControl
                  readOnly={readOnly}
                  publish={schedule.publish}
                  unpublish={schedule.unpublish}
                  onPublishChange={this.onPublishChange}
                  onUnpublishChange={this.onUnpublishChange}
                  large={false}
                  className="my-3"
                />
              </Col>
              {renderedPreviewComponent}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {!readOnly && (
              <Button
                variant="primary"
                type="submit"
                disabled={!isValidNewSchedule(schedule, item, itemType)}
              >
                {mode === ItemModalModes.EDIT ? "Save" : "Create"}
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

ItemModal.propTypes = {
  mode: PropTypes.string.isRequired,

  schedule: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  itemType: PropTypes.string,
  show: PropTypes.bool.isRequired,

  toolbarActions: PropTypes.shape({
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,

  onHide: PropTypes.func.isRequired,
};

export default ItemModal;
