import { uniq } from "lodash";
import { PropTypes } from "prop-types";
import queryString from "query-string";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { toast } from "react-toastify";
import { PublicationTypes } from "client/types/PublicationTypes";
import { asLookupMap, request, move } from "client/utils";
import Rearrangable from "components/Rearrangable";
import {
  CmsCardContainer,
  EmptyCmsCard,
  PromotionCard,
} from "components/cards";
import { PromotionSearchModal } from "./PromotionSearchModal";

export function PromotionMultiPicker(props) {
  const { selectedIds, readOnly, promotionType } = props;
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);
  const [promotionMap, setPromotionMap] = React.useState({});
  const [dialogIds, setDialogIds] = React.useState([]);

  const onSelectPromotion = React.useMemo(
    () => (promoId) => {
      if (dialogIds.includes(promoId)) {
        setDialogIds(dialogIds.filter((id) => id !== promoId));
      } else {
        setDialogIds([...dialogIds, promoId]);
      }
    },
    [props.onChange, dialogIds]
  );

  const onAddPromotions = React.useMemo(
    () => () => {
      setModalVisible(false);
      props.onChange(uniq([...selectedIds, ...dialogIds]));
      setDialogIds([]);
    },
    [props.onChange, selectedIds, dialogIds]
  );

  const onRearrange = React.useMemo(
    () => (index1, index2) => {
      props.onChange(move(selectedIds, index1, index2));
    },
    [props.onChange, selectedIds]
  );

  React.useEffect(() => {
    if (!selectedIds.length) return;
    const query = queryString.stringify({
      promotion_type: promotionType,
      id: selectedIds.join(","),
    });

    setFetching(true);
    request("promotions/card?" + query)
      .then((result) => {
        unstable_batchedUpdates(() => {
          setFetching(false);
          setPromotionMap(asLookupMap(result, "id"));
        });
      })
      .catch((err) => {
        setFetching(false);
        toast.error("Failed to load promotions: " + err.message);
        console.error(err);
      });
  }, [promotionType, selectedIds.length]);

  return (
    <>
      <PromotionSearchModal
        show={isModalVisible}
        onHide={() => setModalVisible(false)}
        promotionType={promotionType}
        allowMultiple
        onAdd={onAddPromotions}
        onSelectPromotion={onSelectPromotion}
        selectedIds={dialogIds}
      />
      <CmsCardContainer>
        {selectedIds.map((id, index) => (
          <Rearrangable
            key={id}
            index={index}
            onMove={onRearrange}
            type={promotionType}
          >
            <PromotionCard
              id={id}
              item={promotionMap[id]}
              isLoading={!promotionMap[id] && isFetching}
              isValid={isFetching || !!promotionMap[id]}
              canClick={false}
              promotionType={promotionType}
              showLink
            >
              {!readOnly && (
                <div
                  className="delete-overlay"
                  onClick={() =>
                    props.onChange(
                      selectedIds.filter((otherId) => otherId !== id)
                    )
                  }
                />
              )}
            </PromotionCard>
          </Rearrangable>
        ))}
        <EmptyCmsCard
          onClick={() => setModalVisible(true)}
          canClick={!readOnly}
          variant={promotionType}
        />
      </CmsCardContainer>
    </>
  );
}

PromotionMultiPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  promotionType: PropTypes.oneOf([
    PublicationTypes.TOP_PROMO,
    PublicationTypes.COVER_PROMO,
  ]).isRequired,
  readOnly: PropTypes.bool,
};
