import {
  linearEnd,
  linearStart,
} from "@telia-company/tv.no-play-cms-common/api/Date";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { withChannels } from "client/App";
import Table from "components/tables";

const MappingResultsTable = ({ match, results, channels, onSelect }) => {
  const channelMap = useMemo(
    () => _.fromPairs(channels.map((c) => [c.id, c.value])),
    [channels]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Top title",
        accessor: "topTitle",
      },
      {
        Header: "Parent title",
        accessor: "parentTitle",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Channel",
        accessor: "asset.data.channel",
        Cell: ({ value }) =>
          channelMap[value] || (value && `Channel ID ${value}`) || "N/A",
      },
      {
        Header: "Active?",
        accessor: "active",
        Cell: ({ value }) => (value ? "Active" : "Inactive"),
      },
      {
        Header: "Start time",
        accessor: "asset",
        id: "linearStart",
        Cell: ({ value }) => linearStart(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        Header: "End time",
        accessor: "asset",
        id: "linearEnd",
        Cell: ({ value }) => linearEnd(value).format("YYYY-MM-DD HH:mm"),
      },
      {
        Header: "Asset ID",
        accessor: "asset.id",
      },
    ],
    [channelMap]
  );

  const data = useMemo(
    () =>
      results
        .map((mc) => {
          const linearAssets = mc.assets.filter(
            (a) => a.linear_start && a.linear_end && a.channel_id in channelMap
          );
          const assets = _.sortBy(linearAssets, ["linear_start", "channel_id"]);
          const asset = assets[0];

          return { ...mc, asset };
        })
        .filter((mc) => mc.asset),
    [results, channelMap]
  );

  const onClick = useCallback(
    (mc) => {
      onSelect({
        asset: mc.asset,
        assetId: mc.asset.id,
        matchId: match.id,
        contentId: mc.id,
      });
    },
    [match.id, onSelect]
  );

  return (
    <Table
      className="mt-2 mb-0"
      columns={columns}
      data={data}
      onClick={onClick}
    />
  );
};

export default withChannels(MappingResultsTable);
