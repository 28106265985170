import React from "react";
import { TableRow } from "../types";
import { ChannelSelector } from "./ChannelSelector";
import { ProgramList } from "./ProgramList";

interface Props {
  combinedPrograms?: TableRow[];
}

export const TeliaNoTvGuide = ({ combinedPrograms }: Props) => {
  return (
    <>
      <ChannelSelector />
      <ProgramList combinedPrograms={combinedPrograms} />
    </>
  );
};
