import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { request } from "client/utils";

interface Props {
  fromId: string;
  toId: string;
  matchId: number;
  path: string;
}

export const UpdateMatch = ({ fromId, toId, matchId, path }: Props) => {
  const [updatedMcId, setUpdatedMcId] = useState("");
  const navigate = useNavigate();

  const updateMatch = async () => {
    await request("epg/match", {
      method: "PATCH",
      body: JSON.stringify({
        id: matchId,
        to: updatedMcId,
        from: fromId,
        isActive: true,
      }),
    });
    navigate(`${path}/from/${fromId}/to/${updatedMcId}`);
    toast(`Updated match for ${fromId} from ${toId} to ${updatedMcId}`);
  };

  return (
    <>
      <h3 className="lead border-bottom">Update match</h3>
      <Form.Label htmlFor="media-content-id">Media content id</Form.Label>
      <Form.Group className="flex-row mb-4">
        <Form.Control
          type="text"
          id="media-content-id"
          value={updatedMcId}
          onChange={(e) => {
            setUpdatedMcId(e.target.value);
          }}
        />
        <Button onClick={updateMatch} className="ms-2">
          Update match
        </Button>
      </Form.Group>
    </>
  );
};
