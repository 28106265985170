import React from "react";
import { Form } from "react-bootstrap";
import SourceAnnotation from "./SourceAnnotation";

const AnnotationLabel = ({
  label,
  source,
  isModified,
  isReverted,
  onRevert,
}) => {
  return (
    <Form.Label className="d-flex justify-content-between">
      <div>{label}</div>
      <SourceAnnotation
        source={source}
        isModified={isModified}
        isReverted={isReverted}
        onRevert={onRevert}
      />
    </Form.Label>
  );
};

export default AnnotationLabel;
