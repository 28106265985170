import classNames from "classnames";
import { PropTypes } from "prop-types";
import queryString from "query-string";
import React from "react";
import { Link } from "react-router-dom";
import { useComponentSize } from "react-use-size";
import { CLIENT_CONFIG } from "client/config";
import { useChannels, useProviders } from "client/hooks";
import TooltipWrapper from "components/TooltipWrapper";
import { GLYPHS } from "components/buttons/glyphs";
import { CmsCard } from "components/cards/CmsCard";
import CmsCardTooltipInfo from "./CmsCardTooltipInfo";

function getCloudImagePath(src, width, height) {
  if (!src) return "/images/missing.jpg";
  const w = width ? Math.round(width) : undefined;
  const h = height ? Math.round(height) : undefined;
  return `${CLIENT_CONFIG.CDN_DOMAIN}${src}?${queryString.stringify({ h, w })}`;
}

const LOADING_STATE = { id: 0, title: "Loading...", active: true };
const ERROR_STATE = { id: 0, title: "Unavailable", active: false };

/**
 * Renders a Card with an image background and an overlay.
 * The variant prop changes its aspect ratio
 *     ____________________
 *    |channel         | i |
 *    |provider         ‾‾‾|
 *    |        image       |
 *    |____________________|
 *    | Title              |
 *    | Description        |
 *     ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
 */
export default function DefaultCmsCard({
  item: actualItem,
  children,
  showLink = false,
  onRemove = undefined,
  isLoading = false,
  isValid = true,
  linkTo = "",
  variant = "landscape",
  titlePrefix = undefined,
  title = undefined,
  isBlank = false,
  className = undefined,
  hideDescription = false,
  ...rest
}) {
  const item = isLoading
    ? LOADING_STATE
    : !isValid
      ? ERROR_STATE
      : actualItem || {};
  const tooltip = item.tooltip
    ? item.tooltip
    : !item.active
      ? "Content unavailable"
      : undefined;
  const isActive = item.active;
  const linkCardTo = isValid && showLink ? linkTo : undefined;
  const { ref, width, height } = useComponentSize();
  const classes = isBlank ? [className, "cms-card-blank"] : [className];
  const { providerMap } = useProviders();
  const { channelMap } = useChannels();

  return (
    <CmsCard.Base
      variant={variant}
      title={item.tooltip}
      disabled={!item.active}
      ref={ref}
      className={classNames(classes)}
      {...rest}
    >
      {children}
      {!isLoading && !isBlank && (
        <img
          loading="lazy"
          src={getCloudImagePath(item.image_src, width, height)}
          style={{ width: "100%", height: "100%" }}
        />
      )}

      {item.channels && !isBlank && (
        <CmsCardTooltipInfo
          items={item?.channels
            .map((channel) => channelMap[channel] || null)
            .filter((c) => c != null)}
          className="cms-card-drop-info"
        />
      )}

      {item.providers && !isBlank && (
        <CmsCardTooltipInfo
          items={item?.providers
            .map((provider) => providerMap[provider] || null)
            .filter((p) => p != null)}
          className={classNames(
            "cms-card-drop-info",
            item.channels ?? "cms-card-drop-info-spot-2"
          )}
        />
      )}

      {(linkCardTo || !isActive || onRemove) && !isBlank && (
        <CmsCard.Icons>
          {!isActive && (
            <span className={classNames(GLYPHS.WARNING, "text-danger mx-1")} />
          )}
          {linkCardTo && (
            <Link to={linkCardTo} className="mx-1 text-light" title="Open">
              <span className={GLYPHS.OPEN} />
            </Link>
          )}
          {onRemove && (
            <span
              className={classNames(GLYPHS.REMOVE, "text-danger mx-1")}
              onClick={onRemove}
              title="Remove"
            />
          )}
        </CmsCard.Icons>
      )}
      <TooltipWrapper value={tooltip} placement="top">
        <CmsCard.Overlay
          className="lead text-light"
          roundedCorners={rest.roundedCorners}
        >
          <div className="clamp-two-lines">
            {titlePrefix && (
              <span className="font-weight">{titlePrefix}: </span>
            )}
            <span className="fw-bold">{title || item.title}</span>
          </div>
          {item.description && !hideDescription && (
            <div className="help-block text-capitalize">{item.description}</div>
          )}
        </CmsCard.Overlay>
      </TooltipWrapper>
    </CmsCard.Base>
  );
}

DefaultCmsCard.propTypes = {
  item: PropTypes.shape({
    image_src: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  linkTo: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showLink: PropTypes.bool,
  onRemove: PropTypes.func,
  isLoading: PropTypes.bool,
  isValid: PropTypes.bool,
  titlePrefix: PropTypes.string,
  title: PropTypes.string,
};
