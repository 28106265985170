import React from "react";
import { numberOrStringPropType } from "client/utils";
import DefaultCmsCard from "./DefaultCmsCard";

const CollectionCard = ({ id, ...props }) => (
  <DefaultCmsCard
    {...props}
    linkTo={"/collections/edit/" + id}
    variant="collection"
  />
);

CollectionCard.propTypes = { id: numberOrStringPropType.isRequired };

export default CollectionCard;
