import React, { useCallback, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { STATUS, useRequest, useUser } from "client/hooks";
import { formatPlayUrl, request } from "client/utils";
import Table, {
  getDateColumn,
  getManageColumn,
  getStatusColumn,
  OptionCell,
  OptionsFilter,
  TextFilter,
} from "components/tables";
import PublishBar from "components/toolbar/PublishBar";
import { CLIENT_TYPE_BADGES, getClientType } from "./CollectionEditor/utils";

const CONTENT_BADGES = [
  { value: "dynamic", label: "Dynamic", variant: "warning" },
  { value: "manual", label: "Manual", variant: "light" },
];

function Collections() {
  const [data, setData] = useState([]);
  const [status, refresh] = useRequest("collections", { onSuccess: setData });

  const onDelete = useCallback(
    ({ id }) => {
      request("collections/" + id, {
        method: "DELETE",
      })
        .then(refresh)
        .catch((error) =>
          toast.error(`Failed to delete collection with id ${id}: ${error}`)
        );
    },
    [refresh]
  );

  const navigate = useNavigate();
  const onEdit = useCallback(
    (collection) => navigate("/collections/edit/" + collection.id),
    [navigate]
  );

  const { hasPermissionTo } = useUser();
  const isEditor = hasPermissionTo("edit", "publication");

  const columns = useMemo(
    () =>
      [
        getStatusColumn({
          accessor: (row) => row.enabled && row.active_content,
        }),
        {
          Header: "Title",
          accessor: "title",
          Cell: ({ value, row: { original } }) => {
            const type = original.collection_type;
            if (type !== "page") return value;
            return (
              <a
                href={formatPlayUrl(`samling/${original.id}`)}
                target="_blank"
                rel="noreferrer"
              >
                {value}
              </a>
            );
          },
          Filter: TextFilter,
          filter: "text",
          width: 120,
        },
        {
          Header: "Editorial Notes",
          accessor: "notes",
          Filter: TextFilter,
          filter: "text",
          width: 80,
        },
        {
          id: "collection_type",
          Header: "Type",
          accessor: ({ collection_type, content_type }) =>
            getClientType(collection_type, content_type),
          Cell: ({ value }) => (
            <OptionCell options={CLIENT_TYPE_BADGES} value={value} />
          ),
          Filter: ({ column }) => (
            <OptionsFilter options={CLIENT_TYPE_BADGES} column={column} />
          ),
          filter: "equals",
          width: 40,
        },
        {
          id: "contents",
          Header: "Contents",
          accessor: ({ dynamic }) => (dynamic ? "dynamic" : "manual"),
          Cell: ({ value }) => (
            <OptionCell options={CONTENT_BADGES} value={value} />
          ),
          Filter: ({ column }) => (
            <OptionsFilter options={CONTENT_BADGES} column={column} />
          ),
          filter: "equals",
          width: 40,
        },
        getDateColumn({
          Header: "Last edited",
          accessor: "updated",
          width: 80,
        }),
        {
          Header: "By",
          accessor: "editor",
          Filter: TextFilter,
          width: 80,
        },
        isEditor && getManageColumn(onEdit, onDelete),
      ].filter((v) => !!v),
    [isEditor]
  );

  return (
    <>
      <PublishBar />
      <Container fluid>
        <h1>All Collections</h1>
        <Table
          columns={columns}
          data={data}
          loading={status === STATUS.FETCHING}
          initialState={{
            sortBy: [{ id: "updated", desc: true }],
            pageIndex: 0,
          }}
          filter
          paginate
        />
      </Container>
    </>
  );
}

export default Collections;
