import _ from "lodash";
import React from "react";
import { Form } from "react-bootstrap";
import Select, {
  components,
  Props,
  Options,
  OptionProps,
  MultiValueProps,
  ValueContainerProps,
  OnChangeValue,
  GroupBase,
  ActionMeta,
} from "react-select";
import { StatusBadge } from "components/badges";
import { getRevisionStatusVariant } from "../status";

const SELECT_ALL = "Select all (any)";
const DESELECT_ALL = "Deselect all";

interface QueryParam<T> {
  value: T[];
  update: (newValue: T) => void;
}

export interface SelectOption {
  label: string;
  value: string;
}

interface FilterSelectProps {
  onChange: (
    newValue: OnChangeValue<SelectOption, true>,
    actionMeta?: ActionMeta<SelectOption>
  ) => void;
  value: SelectOption[];
  options: Options<SelectOption>;
  includeSelectAll: boolean;
  filterParams: QueryParam<string>;
  isLoading: boolean;
}

function FilterSelect({
  includeSelectAll,
  onChange,
  options,
  isLoading,
  value,
  components = {},
  ...rest
}: Props<SelectOption, true, GroupBase<SelectOption>> & FilterSelectProps) {
  const allAreSelected = React.useMemo(
    () => value.length === options.length,
    [value, options]
  );

  const [selectAllOption, setSelectAllOption] = React.useState({
    label: allAreSelected ? DESELECT_ALL : SELECT_ALL,
    value: "*",
  });

  const onChangeHandler = (
    selectedOption: OnChangeValue<SelectOption, true>
  ) => {
    if (selectedOption !== null && selectedOption.length > 0) {
      if (
        selectedOption[selectedOption.length - 1].value ===
        selectAllOption.value
      ) {
        if (allAreSelected) {
          setSelectAllOption({ ...selectAllOption, label: SELECT_ALL });
          return onChange([]);
        }
        setSelectAllOption({ ...selectAllOption, label: DESELECT_ALL });
        return onChange(options);
      }
    }
    setSelectAllOption({
      ...selectAllOption,
      label:
        selectedOption.length === options.length ? DESELECT_ALL : SELECT_ALL,
    });
    return onChange(selectedOption);
  };

  const placeholder = isLoading ? "Loading..." : "Search...";

  return (
    <Select
      {...rest}
      options={includeSelectAll ? [selectAllOption, ...options] : options}
      onChange={includeSelectAll ? onChangeHandler : onChange}
      placeholder={placeholder}
      components={{
        Option,
        ValueContainer,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueRemove: () => null,
        ...components,
      }}
      value={value}
    />
  );
}

interface StatusBadgeMultiValueProps
  extends MultiValueProps<SelectOption, true> {
  statusToVariant?: (status: string) => string;
}

export const StatusBadgeMultiValue = ({
  children,
  statusToVariant = getRevisionStatusVariant,
  ...rest
}: StatusBadgeMultiValueProps) => {
  if (_.isString(children)) {
    return (
      <StatusBadge
        className="lh-1"
        status={children}
        statusToVariant={statusToVariant}
      />
    );
  }
  return <components.MultiValue {...rest}>{children}</components.MultiValue>;
};

export const getStatusBadgeMultiValue = (
  statusToVariant = getRevisionStatusVariant
) => {
  return function StatusBadgeMultiValueWithVariant(
    props: MultiValueProps<SelectOption, true>
  ) {
    return (
      <StatusBadgeMultiValue {...props} statusToVariant={statusToVariant} />
    );
  };
};

const ValueContainer = ({
  children,
  options,
  ...rest
}: ValueContainerProps<SelectOption, true>) => {
  if (_.isArray(children) && children.length === 2) {
    const [selectedValues, input] = children;
    const realOptions = options.filter((o) => "value" in o && o.value !== "*");
    const selectedCount = selectedValues ? selectedValues.length : 0;
    const selectedText =
      selectedCount === realOptions.length
        ? "All selected"
        : selectedCount > 1
          ? `Selected: ${selectedCount}`
          : undefined;

    if (selectedText) {
      return (
        <components.ValueContainer options={options} {...rest}>
          {selectedText} {input}
        </components.ValueContainer>
      );
    }
  }

  return (
    <components.ValueContainer options={options} {...rest}>
      {children}
    </components.ValueContainer>
  );
};

export const Option = (props: OptionProps<SelectOption, true>) => {
  const { data } = props;
  return (
    <div>
      <components.Option {...props}>
        {data.value === "*" ? (
          <span>{props.label}</span>
        ) : (
          <Form.Check
            readOnly
            type="checkbox"
            checked={props.isSelected}
            label={props.label}
          />
        )}
      </components.Option>
    </div>
  );
};

interface StatusBadgeOptionProps extends OptionProps<SelectOption, true> {
  statusToVariant?: (status: string) => string;
}

export const StatusBadgeOption = (props: StatusBadgeOptionProps) => {
  const { data, statusToVariant } = props;
  return (
    <div>
      <components.Option {...props}>
        {data.value === "*" ? (
          <span>{props.label}</span>
        ) : (
          <div className="d-flex align-items-center">
            <Form.Check readOnly type="checkbox" checked={props.isSelected} />
            <StatusBadge
              status={data.value}
              className="ms-2 lh-1"
              statusToVariant={statusToVariant || getRevisionStatusVariant}
            />
          </div>
        )}
      </components.Option>
    </div>
  );
};

export const getStatusBadgeOption = (
  statusToVariant = getRevisionStatusVariant
) => {
  return function StatusBadgeOptionWithVariant(
    props: OptionProps<SelectOption, true>
  ) {
    return <StatusBadgeOption {...props} statusToVariant={statusToVariant} />;
  };
};

export default FilterSelect;
