import React from "react";
import { Button, Form } from "react-bootstrap";
import { CmsPure } from "client/utils";
import { getComposerTitle } from "../provisioningUtils";

const LABEL = "Lineup";

class LineupComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      dvbBouquetId: "",
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) return;

    this.props.get(id, (state) => this.setState(state));
  }

  create = (event) => {
    event.preventDefault();
    const { name, dvbBouquetId } = this.state;
    const payload = { name, dvbBouquetId: Number(dvbBouquetId) };
    this.props.put(payload, this.props.id, LABEL);
  };

  render() {
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{title}</h1>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Bouquet Id</Form.Label>
          <Form.Control
            type="number"
            value={this.state.dvbBouquetId}
            onChange={(e) => this.setState({ dvbBouquetId: e.target.value })}
          />
        </Form.Group>
        <Button
          className="pull-right mt-4"
          variant="primary"
          type="submit"
          disabled={!this.state.name || !this.state.dvbBouquetId}
        >
          {this.props.mode}
        </Button>
      </Form>
    );
  }
}

export default LineupComposer;
