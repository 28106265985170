import { DateTime } from "luxon";
import useSWR from "swr";
import {
  MetadataAsset,
  MetadataAssetDTO,
  MetadataProgramResponse,
  MetadataProgramResponseDTO,
  MetadataProgramsResponseDTO,
} from "client/views/Epg/types/MetadataProgramResponse";
import {
  PaginatedResponse,
  ProgramMatchDTO,
  programMatchFromDTO,
} from "../types";

interface UseProgramMatchesOptions {
  page: number;
  size: number;
  title?: string | null;
  kind?: string | null;
  manualMatches?: string | null;
  ids?: string | null;
  matched: string;
}

export function useProgramMatches(
  {
    page = 0,
    size = 25,
    title,
    kind,
    manualMatches,
    ids,
    matched,
  }: UseProgramMatchesOptions = {
    page: 0,
    size: 25,
    matched: "matchedFirst",
  }
) {
  const searchParams = new URLSearchParams();
  searchParams.append("page", page.toString());
  searchParams.append("size", size.toString());
  if (title) {
    searchParams.append("title", title);
  }
  if (kind) {
    searchParams.append("kind", kind);
  }
  if (manualMatches) {
    searchParams.append(
      "manualMatches",
      (manualMatches === "manual").toString()
    );
  }
  if (ids) {
    searchParams.append("ids", ids.replace("crid://gn/", ""));
  }
  searchParams.append("matched", matched);
  const { data, error, isValidating, mutate } = useSWR<
    PaginatedResponse<ProgramMatchDTO>
  >("epg/unified-programs?" + searchParams.toString());

  const isLoading = isValidating || (!data && !error);
  const programMatches = data?.results.map(programMatchFromDTO) || [];
  const totalPages = data?.totalPages || 1;
  const totalMatches = data?.totalElements;

  const programIds = programMatches.map((programMatch) => programMatch.from);
  const programSearchParams = new URLSearchParams();
  for (const programId of programIds) {
    programSearchParams.append("programIds", programId);
  }
  const { data: programDTOs } = useSWR<MetadataProgramsResponseDTO>(
    programIds.length > 0
      ? "epg/program?" + programSearchParams.toString()
      : null
  );
  const programs = programDTOs?.map(programResponseDTOToProgramResponse);

  const programsByTmsId = new Map(
    programs?.map((program) => [
      program.programs[0].mediaData.metadataId,
      program,
    ]) || []
  );

  return {
    programMatches,
    programsByTmsId,
    error,
    isLoading,
    mutate,
    totalPages,
    totalMatches,
  };
}

const programResponseDTOToProgramResponse = (
  programResponseDTO: MetadataProgramResponseDTO
): MetadataProgramResponse => {
  return {
    programs: programResponseDTO.programs,
    assets: programResponseDTO.assets.map(metadataAssetDTOToMetadataAsset),
  };
};

const metadataAssetDTOToMetadataAsset = (
  metadataAssetDTO: MetadataAssetDTO
): MetadataAsset => {
  const { catchupStart, catchupEnd, linearStart, linearEnd, ...rest } =
    metadataAssetDTO;
  return {
    ...rest,
    catchupStart: catchupStart ? DateTime.fromISO(catchupStart) : null,
    catchupEnd: catchupEnd ? DateTime.fromISO(catchupEnd) : null,
    linearStart: DateTime.fromISO(linearStart),
    linearEnd: DateTime.fromISO(linearEnd),
  };
};
