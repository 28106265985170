import { toUpper } from "lodash";

export const GLYPHS = {
  CREATE: "fas fa-plus-circle",
  SAVE: "fas fa-save",
  DELETE: "far fa-trash-alt",

  ADD: "fas fa-plus",
  EDIT: "far fa-edit",
  REMOVE: "fas fa-times",
  COPY: "far fa-copy",

  OPEN: "fas fa-up-right-and-down-left-from-center",
  EXTERNAL: "fas fa-up-right-from-square",
  CLOSE: "fas fa-times",
  CROSS: "fas fa-times",
  REFRESH: "fas fa-sync",
  REFRESHING: "fas fa-sync fa-spin",
  SEARCH: "fas fa-search",

  SHOW_MORE: "fas fa-chevron-down",
  SHOW_LESS: "fas fa-chevron-up",

  LANDSCAPE: "fas fa-image",
  PORTRAIT: "fas fa-portrait",

  BACKWARD: "fas fa-arrow-left",
  CODE: "fas fa-code",
  CHECK: "fas fa-check",
  DIFF: "fas fa-code-compare",
  DOWNLOAD: "fas fa-download",
  EVERYTHING: "fas fa-globe",
  SETTINGS: "fas fa-cog",
  USER: "fas fa-user",
  WARNING: "fas fa-exclamation-triangle",
  INFO: "fas fa-info-circle",

  FORWARD: "fas fa-forward",
  SKULL: "fas fa-skull",
};

export function getGlyphClassName(type) {
  if (!type) return undefined;
  return GLYPHS[toUpper(type)];
}
