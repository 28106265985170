import React from "react";
import { Form } from "react-bootstrap";
import { ToggleControlWithLabels } from "components/controls/ToggleControl";
import { CollectionRowType } from "./types";

const ROW_TYPE_OPTIONS = [
  {
    label: "Vertical cards",
    value: "verticalCards",
  },
  {
    label: "Horizontal cards",
    value: "horizontalCards",
  },
  {
    label: "Horizontal storyline",
    value: "horizontalStoryline",
  },
  {
    label: "Full width",
    value: "fullWidth",
  },
  {
    label: "Small 16:9",
    value: "small16:9",
  },
  {
    label: "Large 16:9",
    value: "large16:9",
  },
  {
    label: "Vertical pitch",
    value: "verticalEditorialPitch",
  },
  {
    label: "Horizontal pitch",
    value: "horizontalEditorialPitch",
  },
];

interface Props {
  rowType: CollectionRowType;
  onChange: (newValue: CollectionRowType) => void;
  disabled: boolean;
}

export const RowTypeToggle = ({ rowType, onChange, disabled }: Props) => {
  return (
    <>
      <Form.Label>Row type</Form.Label>
      <ToggleControlWithLabels
        value={rowType}
        options={ROW_TYPE_OPTIONS}
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );
};
