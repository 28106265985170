import React from "react";
import { Route } from "react-router-dom";
import { ProgramMatches } from "./ProgramMatches";

/*
 * This array has been put in a separate file to allow jest to figure out
 * that ProgramMatches tests don't depend on LinearMetadataDb or MergeContent.
 * Had it been placed in the same file as the Epg component, jest will run all
 * tests that import anything from the file containing the Epg component.
 */

export const programMatchesRoutes = [
  <Route
    key="program-matches"
    path={"/epg/matches"}
    exact
    component={ProgramMatches}
  />,
  <Route
    key="program-matches-to"
    path={"/epg/matches/to/:toId"}
    component={ProgramMatches}
  />,
  <Route
    key="program-matches-from-to"
    path={"/epg/matches/from/:fromId/to/:toId"}
    component={ProgramMatches}
  />,
  <Route
    key="program-matches-details"
    path={"/epg/matches/details/:id"}
    component={ProgramMatches}
  />,
];
