import {
  linearEnd,
  linearStart,
} from "@telia-company/tv.no-play-cms-common/api/Date";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { withChannels } from "client/App";
import { request } from "client/utils";
import MappingDialog from "./MappingDialog";
import MappingDialogLink from "./MappingDialogLink";

const MatchMappingSummary = ({
  match,
  season,
  dialog,
  readOnly,
  channels,
  dispatch,
}) => {
  const { mapping } = match;
  const channelMap = _.fromPairs(channels.map((c) => [c.id, c.value]));
  const heading = mapping ? channelMap[mapping.asset.channel_id] : "Channel";
  const startFormat = mapping && mapping.isLive ? "HH:mm" : "YYYY-MM-DD HH:mm";
  const ls = linearStart(mapping?.asset);
  const le = linearEnd(mapping?.asset);

  const start = ls && ls.format(startFormat);
  const end = le && le.format("HH:mm");

  const openDialog = useCallback(() => {
    dispatch({
      type: "matchCard.mappingDialog.open",
      payload: {
        initialQuery: match.description,
      },
    });
  }, [match]);
  const closeDialog = useCallback(() => {
    dispatch({ type: "matchCard.mappingDialog.close" });
  }, []);
  const selectMapping = useCallback((mapping) => {
    dispatch({ type: "mapping.updated", payload: mapping });
  }, []);

  useEffect(() => {
    if (!mapping || !mapping.dirty) return;

    let url = "sports/mappings";
    let method = "POST";

    if (mapping.id) {
      url = `${url}/${mapping.id}`;
      method = "PUT";
    }
    request(url, {
      method,
      body: JSON.stringify(mapping),
    })
      .then((res) => dispatch({ type: "mapping.reloaded", payload: res }))
      .catch(console.log);
  }, [mapping]);

  return (
    <div>
      {dialog.isOpen && (
        <MappingDialog
          match={match}
          season={season}
          dialog={dialog}
          onClose={closeDialog}
          onSelect={selectMapping}
        />
      )}
      <div>
        <b>{heading}</b>
      </div>
      <div>
        {mapping ? (
          <MappingDialogLink
            glyph="edit"
            onClick={openDialog}
            readOnly={readOnly}
          >
            {`${start} - ${end}`}
          </MappingDialogLink>
        ) : (
          <MappingDialogLink
            glyph="search"
            onClick={openDialog}
            readOnly={readOnly}
          >
            {readOnly ? "N/A" : "Find asset"}
          </MappingDialogLink>
        )}
      </div>
    </div>
  );
};

export default withChannels(MatchMappingSummary);
