import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useResetPage } from "./hooks";

export const ShowSpamToggle = () => {
  const [showSpam, setShowSpam] = useQueryParam("showSpam", BooleanParam);
  const resetPage = useResetPage();
  const selectShowSpam = (newShowSpam: true | null) => {
    setShowSpam(newShowSpam);
    resetPage();
  };

  return (
    <InputGroup>
      <Form.Check
        type="switch"
        id="search-filter-show-spam"
        checked={showSpam || false}
        onChange={(e) => selectShowSpam(e.target.checked || null)}
      />
      <Form.Label htmlFor="search-filter-show-spam">Show spam</Form.Label>
    </InputGroup>
  );
};
