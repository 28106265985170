import React from "react";
import { TableRow } from "../types";
import { ChannelSelector } from "./ChannelSelector";
import { LinkToGemCacheViewer } from "./LinkToGemCacheViewer";
import { ProgramList } from "./ProgramList";

interface Props {
  combinedPrograms?: TableRow[];
}

export const OneAppTvGuide = ({ combinedPrograms }: Props) => {
  return (
    <div>
      <ChannelSelector />
      <ProgramList combinedPrograms={combinedPrograms} />
      <LinkToGemCacheViewer />
    </div>
  );
};
