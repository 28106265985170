import classNames from "classnames";
import moment from "moment";
import { PropTypes } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const MONTH_FORMAT = "MMMM";

const Day = React.memo(({ linkTo, number, selected, today }) => (
  <Link className={classNames("day", { selected, today })} to={linkTo}>
    {number}
  </Link>
));

const Week = React.memo(
  ({ number, selected, current, only, linkTo, widthPercent }) => (
    <Link
      className={classNames("week", { selected, current, only })}
      to={linkTo}
      style={{ maxWidth: `${widthPercent}%` }}
    >
      {(widthPercent > 10 ? "Week " : "W ") + number}
    </Link>
  )
);

Day.propTypes = {
  number: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  today: PropTypes.bool.isRequired,
  linkTo: PropTypes.string.isRequired,
};

const renderDays = (start, selectedDay, getLinkTo) => {
  const now = moment();
  const numDays = start.daysInMonth();
  const today = now.isSame(start, "month") ? now.date() : -1;
  const days = [];

  for (let day = 1; day <= numDays; day++) {
    days.push(
      <Day
        key={`day-${day}`}
        number={day}
        selected={day === selectedDay}
        today={day === today}
        linkTo={getLinkTo(start, "day")}
      />
    );
    start.add(1, "day");

    if (day !== numDays) {
      days.push(<div key={`divider-${day}`} className="divider" />);
    }
  }

  return <div className="days">{days}</div>;
};

const renderWeeks = (start, selectedWeek, only, getLinkTo) => {
  const currentWeek = moment().isoWeek();
  const end = start.clone().endOf("month");
  const date = start.clone().startOf("isoweek");
  const weeks = [];
  const numDays = start.daysInMonth();

  while (date <= end) {
    const week = date.clone();
    const i = week.isoWeek();
    const sow = week < start ? start : week;
    let eow = week.clone().endOf("isoweek");
    eow = eow > end ? end : eow;
    weeks.push(
      <Week
        key={`week-${i}`}
        number={i}
        selected={i === selectedWeek}
        only={only}
        widthPercent={(100 * (eow.diff(sow, "days") + 1)) / numDays}
        current={i === currentWeek}
        linkTo={getLinkTo(sow, "week")}
      />
    );

    date.add(7, "days");
    if (date <= end) {
      weeks.push(<div key={`divider-week-${i}`} className="divider" />);
    }
  }

  return <div className="weeks">{weeks}</div>;
};

const renderMonth = (date, view, getLinkTo) => {
  const currentMonth = moment().month();
  const month = date.format(MONTH_FORMAT);
  return (
    <Link
      className={classNames("month", "active", "selected", {
        only: view === "month",
        current: currentMonth === date.month(),
      })}
      to={getLinkTo(date.clone().startOf("month"), "month")}
    >
      {month}
    </Link>
  );
};

class DateBar extends React.PureComponent {
  getLinkTo = (mom, view) => {
    const date = mom.format("YYYY[-]MM[-]DD");
    return this.props.getLinkTo({ date, view });
  };

  render() {
    const { date, view } = this.props;
    const mom = moment(date);
    const firstDayOfMonth = moment(date).date(1);
    const lastDayInPreviousMonth = moment(firstDayOfMonth).subtract(1, "day");
    const firstDayInNextMonth = moment(firstDayOfMonth).add(1, "month");

    return (
      <div className="date-bar">
        <Link
          className="month previous"
          to={this.getLinkTo(lastDayInPreviousMonth, view)}
        >
          &lsaquo; {lastDayInPreviousMonth.format(MONTH_FORMAT)}
        </Link>
        <div>
          {renderMonth(mom, view, this.getLinkTo)}
          {renderWeeks(
            firstDayOfMonth,
            view === "week" || view === "day" ? mom.isoWeek() : -1,
            view === "week",
            this.getLinkTo
          )}
          {renderDays(
            firstDayOfMonth,
            view === "day" ? mom.date() : -1,
            this.getLinkTo
          )}
        </div>
        <Link
          className="month next"
          to={this.getLinkTo(firstDayInNextMonth, view)}
        >
          {firstDayInNextMonth.format(MONTH_FORMAT)} &rsaquo;
        </Link>
      </div>
    );
  }
}

DateBar.propTypes = {
  date: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  getLinkTo: PropTypes.func.isRequired,

  // the following props are not used by the component but
  // passing them causes DateBar to rerender when they change
  placement: PropTypes.any.isRequired,
  listView: PropTypes.any.isRequired,
};

export default DateBar;
