import React from "react";
import { UserContext } from "client/App";
import { ACLMenuItem, ACLMenuButton } from "./ACLMenu";

class PublishBar extends React.PureComponent {
  render() {
    return (
      <UserContext.Consumer>
        {({ hasAccessTo }) => (
          <div className="publish-toolbar justify-content-between">
            <div className="flex-row">
              <ACLMenuItem to="/schedule" hasAccessTo={hasAccessTo}>
                SCHEDULE
              </ACLMenuItem>
              <ACLMenuItem to="/collections" hasAccessTo={hasAccessTo}>
                COLLECTIONS
              </ACLMenuItem>
              <ACLMenuItem to="/promos/top" hasAccessTo={hasAccessTo}>
                TOP PROMOS
              </ACLMenuItem>
              <ACLMenuItem to="/promos/card" hasAccessTo={hasAccessTo}>
                PROMO CARDS
              </ACLMenuItem>
            </div>
            <div>
              <ACLMenuButton to="/collections/create" hasAccessTo={hasAccessTo}>
                New collection
              </ACLMenuButton>
              <ACLMenuButton to="/promos/create" hasAccessTo={hasAccessTo}>
                New promo
              </ACLMenuButton>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default PublishBar;
