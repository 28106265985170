import classNames from "classnames";
import React from "react";
import { Badge, BadgeProps } from "react-bootstrap";

interface Props extends BadgeProps {
  status: string;
  showTitle?: boolean;
  printStatus?: (status: string) => string;
  statusToVariant?: (status: string) => string;
  inline?: boolean;
}

function defaultVariant() {
  return "light";
}

export const StatusBadge = ({
  status,
  showTitle = false,
  statusToVariant = defaultVariant,
  printStatus,
  className = undefined,
  inline = false,
  ...rest
}: Props) => {
  const variant = statusToVariant(status);
  const badge = (
    <Badge
      title={
        showTitle && printStatus
          ? printStatus(status)
          : showTitle
            ? status
            : undefined
      }
      bg={variant}
      text={variant === "light" ? "dark" : undefined}
      style={{
        fontSize: 11,
        maxWidth: inline ? "auto" : "100%",
      }}
      className={classNames(
        { "d-block": !inline, "me-1": inline },
        "text-truncate"
      )}
      {...rest}
    >
      {printStatus ? printStatus(status) : status}
    </Badge>
  );
  if (inline) {
    return badge;
  }
  return (
    <div
      className={classNames(
        "overflow-hidden d-flex align-items-center",
        className
      )}
    >
      {badge}
    </div>
  );
};
