import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import useSWR from "swr";
import { UserContext } from "client/App";
import { humanize, request } from "client/utils";
import {
  getEmptyPromo,
  getPromotionChangeHandlers,
  isValidPromotion,
} from "components/Promo/PromoObject";
import PromoView from "components/Promo/PromoView";
import { Spinner } from "components/Spinner";
import { EditorHeaderToolbar } from "components/toolbar/EditorHeaderToolbar";
import FooterToolbar from "components/toolbar/FooterToolbar";

const PromoEditor = () => {
  const navigate = useNavigate();
  const { id: promoId, mode } = useParams();
  const { data, isValidating, error } = useSWR(
    promoId ? `promotions/${promoId}` : null
  );
  const defaultPromoValues = useMemo(() => getEmptyPromo(), []);
  const showSpinner = (isValidating || (!data && !error)) && promoId;
  const initialState = data ? { ...getEmptyPromo(), ...data } : getEmptyPromo();
  const [promo, setPromo] = useState(initialState);
  const onChangeHandlers = getPromotionChangeHandlers((patch) =>
    setPromo((state) => {
      return { ...state, ...patch };
    })
  );

  useEffect(
    () => setPromo({ ...defaultPromoValues, ...data }),
    [defaultPromoValues, data]
  );

  if (showSpinner) {
    return (
      <>
        <EditorHeaderToolbar
          title={`Edit promo (id: ${promoId}) | Loading...`}
          isValid={false}
          onCancel={() => navigate(-1)}
        />
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <Spinner className="m-auto" />
        </div>
      </>
    );
  }
  const isValid = isValidPromotion(promo);
  const headerTitle =
    (promoId === undefined
      ? "Create new promo"
      : `${humanize(mode)}  promo (id: ${promoId})`) +
    (promo.title ? ` | ${promo.title}` : "");
  let onSave = () => save(promo, promoId, navigate);
  return (
    <UserContext.Consumer>
      {({ hasPermissionTo }) => {
        const isEditor = hasPermissionTo("edit", "publication");
        return (
          <>
            <EditorHeaderToolbar
              title={headerTitle}
              isValid={isEditor && isValid}
              onCancel={() => navigate(-1)}
              onSave={onSave}
            />
            <div className="container justify-content-around">
              <PromoView
                item={promo}
                onChangeHandlers={onChangeHandlers}
                readOnly={!isEditor}
              />
            </div>
            <FooterToolbar
              isValid={isEditor && isValid}
              onSave={onSave}
              onCancel={() => navigate(-1)}
            />
          </>
        );
      }}
    </UserContext.Consumer>
  );
};

const save = (payload, id, navigate) => {
  if (!id) {
    // new promotion
    request("promotions/", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then(() => goToListingPage(navigate, payload.promotionType))
      .catch((err) => {
        console.log("Failed to create promo", err);
        toast.error("Failed to create promotion. " + err.message);
      });
  } else {
    // update existing promotion
    request("promotions/" + id, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((result) => {
        console.log("Payload sent", payload, result);
        goToListingPage(navigate, payload.promotionType);
      })
      .catch((err) => {
        console.log("Failed to update promo", err);
        toast.error("Failed to update promotion. " + err.message);
      });
  }
};

const goToListingPage = (navigate, promotionType) => {
  const type = promotionType === "topBanner" ? "top" : "card";
  const url = "/promos/" + type;
  navigate(url);
};

export default PromoEditor;
