import { PropTypes } from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";

export default function FooterToolbar({ isValid, onSave, onCancel }) {
  return (
    <div className="secondary btn-toolbar fixed-bottom">
      <Button disabled={!isValid} variant="primary" onClick={onSave}>
        Save
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
}

FooterToolbar.propTypes = {
  isValid: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
