import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useChannels } from "client/hooks";
import { Channels } from "client/types";
import { useChannelSelection } from "client/views/Epg/LinearVideoDb/useChannelSelection";

export const ChannelSelector = () => {
  const channels = useChannels();
  const sortedChannels = [...channels.channels].sort(
    (a, b) => parseInt(a.id) - parseInt(b.id)
  );

  const { selectedChannels, setChannels } = useChannelSelection();

  const onSelectChannels = (selected: Channels) => {
    setChannels(
      selected.length > 0
        ? selected.map((channel) => parseInt(channel.id))
        : null
    );
  };
  return (
    <>
      <Form.Label>Channel</Form.Label>
      <Typeahead
        id="channel-selector"
        multiple
        selected={sortedChannels.filter(
          (c) => (selectedChannels || []).indexOf(parseInt(c.id)) >= 0
        )}
        options={sortedChannels}
        labelKey="value"
        placeholder="Select channel"
        onChange={onSelectChannels}
      />
    </>
  );
};
