import React, { useState } from "react";
import { Button, Card, Form, InputGroup, ListGroup } from "react-bootstrap";

const QueryCard = ({ query, dispatch }) => {
  const [title, setTitle] = useState("");

  const isValidTitle = React.useCallback(
    (title) => {
      const t = title.trim();
      return (
        !query.customTitles.includes(t) &&
        !query.titles.includes(t) &&
        t.trim().length > 0
      );
    },
    [query]
  );

  const addTitle = React.useCallback(() => {
    if (isValidTitle(title)) {
      dispatch({ type: "query.customTitles.added", payload: title });
      setTitle("");
    }
  }, [title]);

  const removeCustomTitle = (title) => {
    dispatch({ type: "query.customTitles.removed", payload: title });
  };

  return (
    <Card className="imdb-card">
      <Card.Header>
        <h2>IMDb search query</h2>
      </Card.Header>
      <ListGroup variant="flush">
        {query.titles.map((t) => (
          <ListGroup.Item key={t} className="text-muted">
            {t}
          </ListGroup.Item>
        ))}
        {query.customTitles.map((t) => (
          <ListGroup.Item key={t} className="d-flex">
            <div className="flex-grow-1">{t}</div>
            <div className="link" onClick={() => removeCustomTitle(t)}>
              {"\u274c"}
            </div>
          </ListGroup.Item>
        ))}
        <ListGroup.Item key="add-custom-title">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Alternative title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onKeyUp={(e) => {
                if (e.keyCode === 13) addTitle();
              }}
            />
            <Button disabled={!isValidTitle(title)} onClick={addTitle}>
              Add
            </Button>
          </InputGroup>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default QueryCard;
