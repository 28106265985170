import React from "react";
import { Card } from "react-bootstrap";
import IMDbTitleCard from "./IMDbTitleCard";
import MatchCardActions from "./MatchCardActions";

const IMDbMatchCard = ({ match, dispatch }) => (
  <IMDbTitleCard
    imdb={match}
    preHeading="Current IMDb match"
    missingText="No IMDb match yet"
  >
    <Card.Text className="text-muted">
      To update the IMDb match for this media content, either enter a new IMDb
      ID here or pick the correct match among the suggested titles below.
    </Card.Text>
    <MatchCardActions match={match} dispatch={dispatch} />
  </IMDbTitleCard>
);

export default IMDbMatchCard;
