import React from "react";
import { CmsPure } from "client/utils";

class SchedulerOverlays extends CmsPure {
  render() {
    const { loading, indicatorPosition } = this.props;
    return (
      <>
        <div className={"loading-overlay " + loading} />
        {indicatorPosition !== null && (
          <div
            className="scheduler-overlay-date"
            style={{ left: indicatorPosition }}
          />
        )}
      </>
    );
  }
}

export default SchedulerOverlays;
