import { Warnings } from "@telia-company/tv.no-play-cms-common/api/Warning";
import useSWR from "swr";

export const useWarningStats = (): { type: string; count: number }[] => {
  const { data } = useSWR("enrichment/stats");
  if (!data) {
    return [];
  }

  const countObject: { [type: string]: number } = {};
  for (const row of data) {
    countObject[row.type] = row.count;
  }

  return Warnings.map((warning) => ({
    type: warning,
    count: countObject[warning] || 0,
  }));
};
