import React from "react";
import { CmsPure } from "client/utils";
import { ITEM_HEIGHT, getLabel } from "../utils";

function getTransform(left, top) {
  const transform = `translate3d(${Math.round(left)}px, ${Math.round(
    top
  )}px, 0)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export default class AddContent extends CmsPure {
  onClick = () => {
    const { schedule, itemType, onClick } = this.props;
    onClick(schedule, itemType);
  };

  render() {
    const { left, right, top, itemType } = this.props;
    const transform = getTransform(left, top);
    const width = right - left;

    return (
      <div
        className="scheduler-event-add-content"
        title={"Create a new " + getLabel(itemType)}
        style={{ width, ...transform, height: ITEM_HEIGHT }}
        onClick={this.onClick}
      />
    );
  }
}
