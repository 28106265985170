import _ from "lodash";
import moment from "moment";
import { getFirstImagePath } from "client/utils";

export function displayName(optaResource) {
  if (!optaResource) {
    return "";
  }
  const editorial = optaResource.editorial;
  return (editorial && editorial.displayName) || optaResource.name || "";
}

export function formatMatchDate(date, time) {
  if (date && time) {
    return moment(`${date} ${time}`).format("YYYY-MM-DD HH:mm");
  } else if (date) {
    return moment(date).format("YYYY-MM-DD");
  }
  return "TBD";
}

export function formatFootballEventCard(calendar) {
  const contents = calendar.editorial?.contents || [];
  const active = contents.length > 0;
  const images = _.flatten(contents.map((c) => c.data?.images || []));
  return {
    active,
    title: displayName(calendar.competition),
    description: displayName(calendar),
    image_src: getFirstImagePath(images),
    tooltip: !active ? "No content specified for this event" : undefined,
  };
}
