import useSWR from "swr";
import { AmsRequestError } from "../AmsRequestError";

interface RevisionMetadataSourceResponse {
  last_modified: string;
  content: string;
}

export interface ParsedRevisionMetadataSource {
  updated: Date;
  rawContent: string;
  content?: Record<string, unknown>;
}

export function useRevisionMetadataSource(revisionId: number) {
  const url = revisionId
    ? `ams/revisions/${revisionId}/metadata-source/`
    : null;
  const { data, error, isValidating, mutate } = useSWR<
    RevisionMetadataSourceResponse,
    AmsRequestError
  >(url, { shouldRetryOnError: false });

  let content;
  if (data?.content) {
    try {
      content = JSON.parse(data.content);
    } catch {
      // Content doesn't have to be JSON, so we don't need any additional
      // error handling besides making sure we don't throw an exception
    }
  }

  const metadataSource: ParsedRevisionMetadataSource | undefined = data && {
    updated: new Date(data.last_modified),
    rawContent: data.content,
    content,
  };

  return {
    metadataSource,
    error,
    isLoading: isValidating || (!data && !error),
    mutate,
  };
}
