import React from "react";
import { Container } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import { ChannelAdditionalInfo } from "./ChannelAdditionalInfo";
import LinearVideoDb from "./LinearVideoDb";
import { Toolbar } from "./Toolbar";
import { linearMetadataDbRoutes } from "./linearMetadataDbRoutes";
import { maintenanceRoutes } from "./maintenanceRoutes";
import { programMatchesRoutes } from "./programMatchesRoutes";

export function Epg() {
  return (
    <>
      <Toolbar />
      <Container fluid className="my-4">
        <Switch>
          <Route
            path="/epg/channel/info"
            exact
            component={ChannelAdditionalInfo}
          />
          {programMatchesRoutes}
          {linearMetadataDbRoutes}
          <Route
            path={"/epg/linear-video-db"}
            exact
            component={LinearVideoDb}
          />
          {maintenanceRoutes}
        </Switch>
      </Container>
    </>
  );
}
