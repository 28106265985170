import { PropTypes } from "prop-types";
import React from "react";
import { Accordion, Button, useAccordionButton } from "react-bootstrap";
import { CmsPure, LoadingOverlayWrapper } from "client/utils";
import StyledJSONTree from "components/StyledJSONTree";
import { GlyphButton } from "components/buttons";
import { GLYPHS } from "components/buttons/glyphs";

const Label = ({ label }) => {
  if (!label) return null;
  return (
    <span className="flex-fill text-start" style={{ pointerEvents: "none" }}>
      {label}
    </span>
  );
};

const Chevron = ({ expanded }) => (
  <span
    className={expanded ? GLYPHS.SHOW_LESS : GLYPHS.SHOW_MORE}
    style={{ pointerEvents: "none" }}
  />
);

const CustomAccordionToggle = (props) => {
  const onClick = useAccordionButton(props.id, props.onClick);
  return (
    <Button
      variant=""
      className={props.className}
      style={{ width: "100%" }}
      onClick={onClick}
      id={props.id}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

class ProvisionerAccordionRow extends CmsPure {
  state = { fetchedData: null };

  onClick = (event) => {
    const id = event.target.id;
    this.props.onClick(id);
    if (!this.props.isExpanded && !this.props.fetchedData) {
      this.props
        .fetchById(id)
        .then((fetchedData) => this.setState({ fetchedData }));
    }
  };

  render() {
    const {
      downloadPrefix,
      label,
      className,
      disabled,
      selection,
      isExpanded,
    } = this.props;
    const { fetchedData } = this.state;

    return (
      <>
        <CustomAccordionToggle
          className={className}
          onClick={this.onClick}
          id={selection.id}
          disabled={disabled}
        >
          <Label label={label} />
          <Chevron expanded={isExpanded} />
        </CustomAccordionToggle>
        <Accordion.Collapse eventKey={selection.id}>
          <LoadingOverlayWrapper show={!fetchedData}>
            <GlyphButton
              glyph="download"
              variant="link"
              className="m-1 pull-right"
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(selection, null, 2)
              )}`}
              download={`${downloadPrefix}_${selection.id}.json`}
            />
            <StyledJSONTree
              // if not finished fetching, display selected option
              data={fetchedData || selection}
            />
          </LoadingOverlayWrapper>
        </Accordion.Collapse>
      </>
    );
  }
}

ProvisionerAccordionRow.propTypes = {
  selection: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  fetchById: PropTypes.func.isRequired,
  label: PropTypes.string,
  downloadPrefix: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProvisionerAccordionRow;
