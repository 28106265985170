import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";
import { UserContext } from "client/App";
import { GlyphButton } from "components/buttons";
import { ACLMenuItem } from "components/toolbar/ACLMenu";

const IMDbToolbar = ({ showSearchDialog }) => {
  const { url } = useRouteMatch();

  return (
    <UserContext.Consumer>
      {({ hasAccessTo }) => (
        <>
          <div className="publish-toolbar">
            <ButtonGroup className="me-auto">
              <ACLMenuItem to={`${url}/unmatched`} hasAccessTo={hasAccessTo}>
                Unmatched content
              </ACLMenuItem>
              <ACLMenuItem to={`${url}/matched`} hasAccessTo={hasAccessTo}>
                Matched content
              </ACLMenuItem>
              <ACLMenuItem to={`${url}/ignored`} hasAccessTo={hasAccessTo}>
                Not on IMDb
              </ACLMenuItem>
            </ButtonGroup>
            <div className="justify-content-end">
              <GlyphButton
                glyph="search"
                variant="primary"
                className="ms-auto"
                onClick={showSearchDialog}
              >
                Find Content
              </GlyphButton>
            </div>
          </div>
        </>
      )}
    </UserContext.Consumer>
  );
};

export default IMDbToolbar;
