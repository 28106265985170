import React, { useContext } from "react";
import { UserContext } from "client/App";
import { ACLMenuItem } from "components/toolbar/ACLMenu";

function Toolbar() {
  const { hasAccessTo } = useContext(UserContext);
  return (
    <div className="publish-toolbar justify-content-between">
      <div className="flex-row">
        <ACLMenuItem to="/ams/assets" hasAccessTo={hasAccessTo}>
          ASSETS
        </ACLMenuItem>
        <ACLMenuItem to="/ams/providers" hasAccessTo={hasAccessTo}>
          PROVIDERS
        </ACLMenuItem>
        <ACLMenuItem to="/ams/issue-reports" hasAccessTo={hasAccessTo}>
          ISSUE REPORTS
        </ACLMenuItem>
        <ACLMenuItem to="/ams/configurations" hasAccessTo={hasAccessTo}>
          CONFIGURATIONS
        </ACLMenuItem>
      </div>
    </div>
  );
}

export default Toolbar;
