import React from "react";
import { Button, Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";
import { useChannelSelection } from "../hooks";

export const ClearButton = () => {
  const { selectedChannels, setChannels } = useChannelSelection();
  const [startDate, setStartDate] = useQueryParam("start", StringParam);
  const [endDate, setEndDate] = useQueryParam("end", StringParam);

  const disabled =
    (!selectedChannels || selectedChannels.length == 0) &&
    !startDate &&
    !endDate;
  const clear = () => {
    setChannels(null);
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <>
      <Form.Label>&nbsp;</Form.Label>
      <br />
      <Button onClick={clear} disabled={disabled}>
        Clear
      </Button>
    </>
  );
};
