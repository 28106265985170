import { DateTime, DurationLike } from "luxon";
import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";
import { useFrom, useTo } from "../hooks/useFromAndTo";

export const ResolutionFilter = () => {
  const [resolution, setResolution] = useQueryParam("resolution", StringParam);
  const { setFrom } = useFrom();
  const { setTo } = useTo();

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newResolution = e.target.value;
    setResolution(newResolution);
    setTo(DateTime.now().startOf("day"));
    let durationToSubtract: DurationLike = { days: 9 };
    switch (newResolution) {
      case "day":
        durationToSubtract = { days: 9 };
        break;
      case "week":
        durationToSubtract = { weeks: 9 };
        break;
      case "month":
        durationToSubtract = { months: 9 };
        break;
      case "year":
        durationToSubtract = { years: 9 };
        break;
    }
    setFrom(DateTime.now().startOf("day").minus(durationToSubtract));
  };

  return (
    <>
      {" "}
      <Form.Label>Resolution (day/week/month/year)</Form.Label>
      <Form.Select value={resolution || "day"} onChange={onChange}>
        <option value="day">Day</option>
        <option value="week">Week</option>
        <option value="month">Month</option>
        <option value="year">Year</option>
      </Form.Select>
    </>
  );
};
