import React from "react";
import { useParams } from "react-router-dom";
import { ProgramDetailsModal } from "../ProgramDetails/ProgramDetailsModal";
import { DetailsModal } from "../ProgramMatch/DetailsModal";
import { OneToOneProgramMatch } from "../ProgramMatch/OneToOneProgramMatch";
import { useCloseProgramMatchCallback } from "../hooks";
import { MatchParams } from "../types/MatchParams";
import { LinearContent } from "./LinearContent";
import { LinearPickers } from "./LinearPickers";

const LinearMetadataDb = () => {
  const { id, fromId, toId, type } = useParams<MatchParams>();
  const closeProgramMatch = useCloseProgramMatchCallback();

  if (type === "match") {
    if (fromId && toId) {
      return (
        <>
          <OneToOneProgramMatchModal
            fromId={fromId}
            toId={toId}
            onClose={closeProgramMatch}
          />
          <LinearPickers />
          <LinearContent />
        </>
      );
    } else {
      return <div>Missing fromId and/or toId</div>;
    }
  }
  if (type === "details") {
    if (id) {
      return (
        <>
          <ProgramDetailsModal
            id={id}
            onClose={closeProgramMatch}
            path="/epg/linear-metadata-db/match"
            showNewMatch={false}
          />
          <LinearPickers />
          <LinearContent />
        </>
      );
    } else {
      return <div>Missing id</div>;
    }
  }
  return (
    <>
      <LinearPickers />
      <LinearContent />
    </>
  );
};

interface OneToOneProgramMatchModalProps {
  onClose: () => void;
  fromId: string;
  toId: string;
}

const OneToOneProgramMatchModal = ({
  onClose,
  fromId,
  toId,
}: OneToOneProgramMatchModalProps) => {
  return (
    <DetailsModal onClose={onClose}>
      <OneToOneProgramMatch
        fromId={fromId}
        toId={toId}
        path={"/epg/linear-metadata-db/match"}
      />
    </DetailsModal>
  );
};

export default LinearMetadataDb;
