import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useUser } from "client/hooks";

const UnauthorizedUser = () => {
  const location = useLocation();
  const user = useUser();

  return (
    <Container>
      <Row style={{ marginTop: "10%" }}>
        <Col className="h3">
          <strong>Unauthorized access</strong>
          <div className="my-2">
            You do not have the access rights to view {location.pathname}.
          </div>
          <Link to={user.defaultRoute}>
            <Button>Redirect me</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default UnauthorizedUser;
