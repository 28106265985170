import { uniq } from "lodash";
import { PropTypes } from "prop-types";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { toast } from "react-toastify";
import { PublicationTypes } from "client/types/PublicationTypes";
import { asLookupMap, request } from "client/utils";
import Rearrangable from "components/Rearrangable";
import {
  CmsCardContainer,
  CollectionCard,
  EmptyCmsCard,
} from "components/cards";
import { CollectionSearchModal } from "./CollectionSearchModal";

export function CollectionMultiPicker(props) {
  const { selectedIds, readOnly } = props;
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);
  const [collectionMap, setCollectionMap] = React.useState({});
  const [dialogIds, setDialogIds] = React.useState([]);

  const onSelectCollection = React.useCallback(
    (collectionId) => {
      setDialogIds((dialogIds) => {
        if (dialogIds.includes(collectionId)) {
          return dialogIds.filter((id) => id !== collectionId);
        }
        return [...dialogIds, collectionId];
      });
    },
    [setDialogIds]
  );

  const onAddCollections = React.useCallback(() => {
    setModalVisible(false);
    props.onChange(uniq([...selectedIds, ...dialogIds]));
    setDialogIds([]);
  }, [props.onChange, selectedIds, setDialogIds, dialogIds]);

  React.useEffect(() => {
    if (!selectedIds.length) return;

    setFetching(true);
    request("collections/card?id=" + selectedIds.join(","))
      .then((result) => {
        unstable_batchedUpdates(() => {
          setFetching(false);
          setCollectionMap(asLookupMap(result, "id"));
        });
      })
      .catch((err) => {
        setFetching(false);
        toast.error("Failed to load collections: " + err.message);
        console.error(err);
      });
  }, [selectedIds.length]);

  return (
    <>
      <CollectionSearchModal
        show={isModalVisible}
        size="lg"
        onHide={() => setModalVisible(false)}
        onAdd={onAddCollections}
        onSelectCollection={onSelectCollection}
        allowMultiple
        selectedIds={dialogIds}
        allowedCollectionTypes={props.allowedCollectionTypes}
      />
      <CmsCardContainer>
        {selectedIds.map((id, index) => (
          <Rearrangable
            key={id}
            index={index}
            onMove={props.onMove}
            type="collection"
          >
            <CollectionCard
              id={id}
              item={collectionMap[id]}
              isLoading={!collectionMap[id] && isFetching}
              isValid={isFetching || !!collectionMap[id]}
              showLink
              canClick={false}
            >
              {!readOnly && (
                <div
                  className="delete-overlay"
                  onClick={() =>
                    props.onChange(
                      selectedIds.filter((otherId) => otherId !== id)
                    )
                  }
                />
              )}
            </CollectionCard>
          </Rearrangable>
        ))}
        <EmptyCmsCard
          onClick={() => setModalVisible(true)}
          canClick={!readOnly}
          variant={PublicationTypes.COLLECTION}
        />
      </CmsCardContainer>
    </>
  );
}

CollectionMultiPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  readOnly: PropTypes.bool,
  allowedCollectionTypes: PropTypes.arrayOf(
    PropTypes.oneOf([
      "row",
      "rowWithGraphics",
      "rowOfThemes",
      "hubWithGraphics",
      "footballEvent",
    ])
  ),
};
