import useSWR from "swr";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { useDebouncedValue } from "client/hooks";
import { useMetadataChannels } from "client/hooks/useMetadataChannels";
import { ScheduleEvent, ScheduleEventRow } from "../types/ScheduleEvent";
import { ScheduleResponse } from "../types/ScheduleResponse";
import { useStartAndEndDates } from "./useStartAndEndDates";

export const useLinear = (
  selectedChannels: (number | null)[] | null
): {
  data?: {
    results: ScheduleEventRow[];
    totalPages: number;
    totalElements: number;
  };
  isLoading: boolean;
} => {
  const searchParams = new URLSearchParams();

  const { channels } = useMetadataChannels();

  let canFetch = true;
  if (selectedChannels && selectedChannels.length > 0) {
    for (const channel of selectedChannels.filter(
      (channel) => channel !== null
    )) {
      searchParams.append("channelIds", (channel as number).toString());
    }
  } else if (channels && channels.length > 0) {
    // No channel selected, include all channels
    for (const channel of channels.map((channel) => channel.id)) {
      searchParams.append("channelIds", channel.toString());
    }
  } else {
    // We can't fetch, since no channels are selected and the channel list
    // hasn't been retrieved yet.
    canFetch = false;
  }

  const [title] = useQueryParam("title", StringParam);
  const debouncedTitle = useDebouncedValue(title);
  if (debouncedTitle) {
    searchParams.append("substring", debouncedTitle);
  }

  const { startDate: start, endDate: end } = useStartAndEndDates();
  const [page = 0] = useQueryParam("page", NumberParam);
  const [size = 20] = useQueryParam("size", NumberParam);
  searchParams.append("start", start);
  searchParams.append("end", end);
  searchParams.append("page", (page as number).toString());
  searchParams.append("size", (size as number).toString());

  const { data, isValidating, error } = useSWR<ScheduleResponse>(
    canFetch ? "epg/schedule?" + searchParams.toString() : null
  );

  const isLoading = isValidating || (!data && !error);

  if (!data) {
    return { data: undefined, isLoading };
  }
  return {
    data: {
      ...data,
      results: data.results?.map(createScheduleRow),
    },
    isLoading,
  };
};

export const createScheduleRow = (
  event: ScheduleEvent,
  index: number
): ScheduleEventRow => {
  return {
    ...event,
    id: index,
  };
};
