import React from "react";
import useSWR from "swr";
import { KindBadge } from "components/badges";
import { TeliaPlayProgramDTO } from "./types";

interface Props {
  mediaContentId: string | null;
}

export const TeliaDBKindBadge = ({ mediaContentId }: Props) => {
  const { data } = useSWR<TeliaPlayProgramDTO>(
    mediaContentId ? `mediacontent/${mediaContentId}` : null
  );

  if (!data) return <></>;

  return <KindBadge kind={data.data?.kind} />;
};
