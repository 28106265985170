import React from "react";
import { Form, FormControl } from "react-bootstrap";

export function TextFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <FormControl
      size="sm"
      type="text"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${preFilteredRows.length} records...`}
    />
  );
}

export function OptionsFilter({
  options = [],
  column: { filterValue, setFilter },
}) {
  return (
    <Form.Select
      className="form-control form-control-sm"
      onChange={(event) => setFilter(event.target.value || undefined)}
      value={filterValue || ""}
      size="sm"
    >
      <option value="">Any</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  );
}

export function SingleSelectParamFilter({ param, setParam, options, ...rest }) {
  const onChange = React.useCallback(
    (e) => setParam(e.target.value),
    [setParam]
  );

  return (
    <Form.Select value={param} onChange={onChange} {...rest}>
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Form.Select>
  );
}

export function TextParamFilter({ param, setParam, ...rest }) {
  return (
    <FormControl
      type="text"
      placeholder="Search..."
      value={param}
      onChange={(e) => setParam(e.target.value)}
      {...rest}
    />
  );
}
