import React from "react";
import { CollectionMultiPicker, FootballEventPicker } from "components/pickers";
import { CollectionContentCards } from "./CollectionContentCards";
import CollectionFiltersComponent from "./CollectionFiltersComponent";
import {
  Collection,
  CollectionContentType,
  CollectionOnChangeHandlers,
} from "./types";
import { getClientType } from "./utils";

function getAllowedCollections(collection: Collection) {
  const clientType = getClientType(
    collection.collectionType,
    collection.contentType
  );

  switch (clientType) {
    case "rowOfThemes":
      return ["rowWithGraphics", "hubWithGraphics", "footballEvent"];
    case "hubWithGraphics":
      return ["row", "rowWithGraphics", "rowOfThemes", "footballEvent"];
    default:
      return undefined;
  }
}

interface Props {
  collection: Collection;
  onChangeHandlers: CollectionOnChangeHandlers;
  readOnly: boolean;
  showAssetDialog: () => void;
}

export function CollectionContentComponent({
  collection,
  onChangeHandlers,
  readOnly,
  showAssetDialog,
}: Props) {
  const allowedCollections = getAllowedCollections(collection);
  const { contentType, collections, footballEvent, dynamic } = collection;

  switch (contentType) {
    case CollectionContentType.Media:
      if (dynamic) {
        return (
          <CollectionFiltersComponent
            collection={collection}
            onChangeHandlers={onChangeHandlers}
            readOnly={readOnly}
          />
        );
      }
      return (
        <CollectionContentCards
          collection={collection}
          onChangeHandlers={onChangeHandlers}
          readOnly={readOnly}
          showAssetDialog={showAssetDialog}
        />
      );
    case CollectionContentType.Collections:
      return (
        <>
          <div className="lead">Collection Pages</div>
          <CollectionMultiPicker
            readOnly={readOnly}
            selectedIds={collections}
            onChange={onChangeHandlers.collections}
            onMove={onChangeHandlers.moveCollections}
            allowedCollectionTypes={allowedCollections}
          />
        </>
      );
    case CollectionContentType.Matches:
      return (
        <FootballEventPicker
          selectedId={footballEvent}
          onChange={onChangeHandlers.footballEvent}
          readOnly={readOnly}
        />
      );
    default:
      return null;
  }
}
