import React from "react";

export const useProgress = (
  estimatedSeconds: number,
  updateIntervalSeconds = 1,
  done = false
) => {
  const [elapsed, setElapsed] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (done) {
        clearInterval(interval);
        clearProgress();
      }
      if (progress < 100)
        setElapsed((percentages) => percentages + updateIntervalSeconds);
    }, updateIntervalSeconds * 1000);

    return () => clearInterval(interval);
  }, [done]);

  React.useEffect(() => {
    setProgress((elapsed / estimatedSeconds) * 100);
  }, [elapsed]);

  const clearProgress = React.useCallback(() => {
    setProgress(0);
    setElapsed(0);
  }, [setProgress, setElapsed]);

  return { progress, clearProgress };
};
