import _ from "lodash";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import DiffView from "components/DiffView";
import SelectRevision from "./SelectRevision";
import { getAvailableModelKeys } from "./toNode";

function getStringifiedData(revision, dataKey) {
  if (!revision) return "";
  const data = revision[dataKey];
  return data ? JSON.stringify(data, null, 2) : "";
}

function CompareRevisionsTab({
  first,
  second,
  third,
  fourth,
  getLinkTo,
  navigateToCompareLink,
  navigateTo,
  revisions,
}) {
  const firstRevision = useMemo(
    () => revisions.find((revision) => revision.id === first),
    [revisions, first]
  );
  const secondRevision = useMemo(
    () => revisions.find((revision) => revision.id === second),
    [revisions, second]
  );

  const firstRevisionAvailableModelKeys = useMemo(
    () => getAvailableModelKeys([firstRevision]),
    [firstRevision]
  );
  const secondRevisionAvailableModelKeys = useMemo(
    () => getAvailableModelKeys([secondRevision]),
    [secondRevision]
  );

  const oldValue = useMemo(
    () => getStringifiedData(firstRevision, third),
    [firstRevision, third]
  );
  const newValue = useMemo(
    () => getStringifiedData(secondRevision, fourth),
    [secondRevision, fourth]
  );

  React.useEffect(() => {
    if (
      !_.isEmpty(firstRevisionAvailableModelKeys) &&
      !_.isEmpty(secondRevisionAvailableModelKeys)
    ) {
      navigateTo({
        first: first,
        second: second,
        third: firstRevisionAvailableModelKeys.includes(third)
          ? third
          : firstRevisionAvailableModelKeys[0],
        fourth: secondRevisionAvailableModelKeys.includes(fourth)
          ? fourth
          : secondRevisionAvailableModelKeys[0],
      });
    }
  }, [
    first,
    second,
    third,
    fourth,
    firstRevisionAvailableModelKeys,
    secondRevisionAvailableModelKeys,
  ]);

  return (
    <>
      <h3 className="lead">Select two revisions to compare YAAFx</h3>
      <Row>
        <Col>
          <SelectRevision
            revisions={revisions}
            getLinkTo={(first) => getLinkTo(first, second, third, fourth)}
            navigateToCompareLink={(first, third) =>
              navigateToCompareLink(first, second, third, fourth)
            }
            selectedDataModelKey={third}
            selectedRevisionId={first}
          />
        </Col>
        <Col>
          <SelectRevision
            revisions={revisions}
            getLinkTo={(second) => getLinkTo(first, second, third, fourth)}
            navigateToCompareLink={(second, fourth) =>
              navigateToCompareLink(first, second, third, fourth)
            }
            selectedDataModelKey={fourth}
            selectedRevisionId={second}
          />
        </Col>
      </Row>
      {first && second && third && fourth ? (
        <div className="mt-3">
          <DiffView oldValue={oldValue} newValue={newValue} />
        </div>
      ) : null}
    </>
  );
}

export default CompareRevisionsTab;
