import { PropTypes } from "prop-types";
import React from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { GLYPHS } from "./glyphs";

function ConfirmButton({
  onClick,
  children,
  confirmVariant,
  confirmChildren,
  placement = "bottom",
  ...rest
}) {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);

  const onHide = React.useCallback(() => setShow(false), [setShow]);
  const onToggle = React.useCallback(() => {
    if (!onClick) return;
    setShow((show) => !show);
  }, [onClick, setShow]);
  const onConfirm = React.useCallback(() => {
    onHide();
    onClick();
  }, [onHide, onClick]);

  return (
    <div style={{ display: "inline" }} ref={target}>
      <Button onClick={onToggle} {...rest}>
        {children}
      </Button>
      <Overlay
        target={target.current}
        show={show}
        placement={placement}
        rootClose
        onHide={onHide}
      >
        {(props) => (
          <Tooltip className="confirm-tooltip" {...props}>
            <Button size="sm" variant={confirmVariant} onClick={onConfirm}>
              {confirmChildren}
            </Button>
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
}

ConfirmButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
  confirmVariant: PropTypes.string.isRequired,
  confirmChildren: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export function ConfirmDeleteButton(props) {
  return (
    <ConfirmButton
      variant="link"
      confirmVariant="danger"
      confirmChildren="Confirm delete"
      aria-label="Delete"
      {...props}
    >
      <span className={GLYPHS.DELETE} />
    </ConfirmButton>
  );
}

export function ConfirmResetButton(props) {
  return (
    <ConfirmButton
      variant="light"
      confirmVariant="warning"
      confirmChildren="Reset current changes"
      {...props}
    >
      Reset
    </ConfirmButton>
  );
}

export function ConfirmActionButton({ children, ...rest }) {
  return (
    <ConfirmButton
      variant="light"
      confirmVariant="info"
      confirmChildren="Confirm action"
      {...rest}
    >
      {children}
    </ConfirmButton>
  );
}

export default ConfirmButton;
