import PropTypes from "prop-types";
import React, { useState } from "react";
import { useRequest, STATUS } from "client/hooks";
import { numberOrStringPropType } from "client/utils";
import DefaultCmsCard from "./DefaultCmsCard";

function ContentCard({ isAsset, id, ...props }) {
  const baseUrl = `/enrich/${isAsset ? "asset" : "mediacontent"}/editor`;
  const linkTo = id ? `${baseUrl}/${id}` : undefined;

  return (
    <DefaultCmsCard
      variant="landscape"
      linkTo={linkTo}
      isBlank={!id}
      {...props}
    />
  );
}

ContentCard.propTypes = {
  isAsset: PropTypes.bool.isRequired,
  id: numberOrStringPropType.isRequired,
};

const INITIAL_STATE = { card: null, mcId: undefined };
const BLANK_STATE = { card: { title: "None" }, mcId: undefined };

export function useContentCard(id, isAsset) {
  const baseEndpoint = isAsset
    ? "mediacontent/card-by-asset-id"
    : "mediacontent/card";
  const endpoint = id ? `${baseEndpoint}/${id}` : undefined;

  // supress the error toast
  const onError = React.useMemo(
    () => (err, endpoint) => {
      console.error("Failed to fetch:", endpoint, err);
    },
    []
  );

  const initialState = id ? INITIAL_STATE : BLANK_STATE;
  const [card, setCard] = useState(initialState);

  const [status] = useRequest(endpoint, {
    blockRequests: !endpoint,
    onSuccess: setCard,
    onError,
  });
  return { status, card };
}

/**
 * Wraps a ContentCard with a fetcher
 * @param {{ id, isAsset, ... }} props
 */
export function ContentCardWrapper({
  id,
  onClick,
  onRemove,
  isAsset,
  canClick,
  ...rest
}) {
  const { status, card } = useContentCard(id, isAsset);

  const onClickFinal = React.useMemo(
    () => () => onClick(id, isAsset, card.mcId),
    [id, isAsset, card.mcId, onClick]
  );
  const onRemoveFinal = React.useMemo(() => {
    if (onRemove) {
      return (e) => {
        e.stopPropagation();
        onRemove(id);
      };
    }
    return undefined;
  }, [id, onRemove]);

  return (
    <ContentCard
      id={id}
      item={card.card}
      isAsset={isAsset}
      onClick={onClickFinal}
      onRemove={onRemoveFinal}
      isLoading={status === STATUS.FETCHING}
      isValid={status === STATUS.OK}
      canClick={canClick && status === STATUS.OK}
      {...rest}
    />
  );
}

ContentCardWrapper.propTypes = {
  id: numberOrStringPropType.isRequired,
  onClick: PropTypes.func,
  isAsset: PropTypes.bool.isRequired,
  canClick: PropTypes.bool.isRequired,
  showLink: PropTypes.bool,
  zoomOnHover: PropTypes.bool,
};

ContentCardWrapper.defaultProps = {
  onClick: undefined,
};

export default ContentCard;
