import { Warnings } from "@telia-company/tv.no-play-cms-common/api/Warning";
import React from "react";
import { Alert, Col, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Line, LineChart } from "recharts";
import { useQueryParamWarnings } from "client/views/Enrich/Insight/ContentWarningStats/useQueryParamWarnings";
import { DateBasedPagination } from "../DateBasedPagination";
import InsightToolbar from "../InsightToolbar";
import { ResolutionFilter } from "./ResolutionFilter";
import { DataPoint, StatsData } from "./types";
import { useWarningStats } from "./useWarningStats";
import { useWarningStatsHistory } from "./useWarningStatsHistory";

const warningOptions = Warnings.map((warn) => ({ value: warn, label: warn }));

export const ContentWarningStats = () => {
  const { firstData, data: warningStatsHistory } = useWarningStatsHistory();
  const { warnings, setWarnings } = useQueryParamWarnings();

  return (
    <>
      <InsightToolbar />
      <div className="container-fluid">
        <h1>Content warning stats</h1>
        <Row>
          <Col xs={6}>
            <Form.Label>Warnings to show</Form.Label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              placeholder="Select warnings"
              options={warningOptions}
              value={warnings?.map((w) =>
                warningOptions.find((option) => option.value === w)
              )}
              onChange={(options) =>
                setWarnings(options.map((option) => option?.value || null))
              }
            />
          </Col>
          <Col xs={3}>
            <ResolutionFilter />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Alert variant="info">Note: * means the column is incomplete</Alert>
          </Col>
        </Row>
        <Table striped hover className="mt-4 bg-light condensed">
          <TableHead warningStatsHistory={warningStatsHistory} />
          <TableBody warningStatsHistory={warningStatsHistory} />
        </Table>
        <DateBasedPagination firstData={firstData} />
      </div>
    </>
  );
};

interface TableHeadProps {
  warningStatsHistory?: StatsData;
}

const TableHead = ({ warningStatsHistory }: TableHeadProps) => {
  const historyHeadings =
    warningStatsHistory &&
    warningStatsHistory["DUPLICATE_INDEX"] &&
    warningStatsHistory["DUPLICATE_INDEX"].map((dataPoint) => (
      <th key={dataPoint.created.toISO()} className="sticky">
        {dataPoint.average_of
          ? dataPoint.average_of
          : dataPoint.created.toFormat("dd/MM HH:mm")}
        {dataPoint.incomplete ? "*" : ""}
      </th>
    ));
  return (
    <thead>
      <tr>
        <th className="sticky">Warning</th>
        <th className="sticky">Live-ish count</th>
        <th className="sticky">Charts</th>
        {historyHeadings}
      </tr>
    </thead>
  );
};

interface TableBodyProps {
  warningStatsHistory?: StatsData;
}

const TableBody = ({ warningStatsHistory }: TableBodyProps) => {
  const warningStats = useWarningStats();
  const { warnings } = useQueryParamWarnings();
  const filteredWarningStats = warnings
    ? warningStats.filter(({ type }) => warnings.includes(type))
    : warningStats;

  const rows = filteredWarningStats.map((warningStat) => {
    const history =
      warningStatsHistory && warningStatsHistory[warningStat.type]
        ? warningStatsHistory[warningStat.type]
        : [];
    return (
      <TableRow
        key={warningStat.type}
        warningStat={warningStat}
        history={history}
      />
    );
  });

  return <tbody>{rows}</tbody>;
};

interface TableRowProps {
  warningStat: { type: string; count: number };
  history: DataPoint[];
}

const TableRow = ({ warningStat, history }: TableRowProps) => {
  return (
    <tr key={warningStat.type}>
      <td>
        <Link
          to={`/enrich/mediacontent/list?warnings=${warningStat.type}&type=upcoming`}
        >
          {warningStat.type}
        </Link>
      </td>
      <NumericCell value={warningStat.count} />
      <td>
        <LineChart width={70} height={20} data={history}>
          <Line
            type="monotone"
            dataKey="count"
            stroke="#c86af8"
            strokeWidth={2}
            dot={false}
            isAnimationActive={false}
          />
        </LineChart>
      </td>
      {history.map((dataPoint) => (
        <NumericCell key={dataPoint.created.toISO()} value={dataPoint.count} />
      ))}
    </tr>
  );
};

interface NumericCellProps {
  value: number;
}

const NumericCell = ({ value }: NumericCellProps) => {
  const cellValue = value === -1 ? "N/A" : value.toLocaleString("NO-nb");
  return <td style={{ textAlign: "end" }}>{cellValue}</td>;
};
