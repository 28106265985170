import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ContentCardWrapper } from "components/cards";

const MediaContentHeader = ({ id, parentId, grandParentId, isLoading }) => {
  return (
    <Container fluid className="px-0">
      <Row>
        <Col xs={8}>
          <ContentCardWrapper id={id} isAsset={false} />
        </Col>
        <Col xs={4}>
          {!isLoading && (
            <>
              <div>
                <ContentCardWrapper
                  className="muted"
                  titlePrefix="Parent"
                  id={parentId}
                  isAsset={false}
                  showLink
                />
              </div>
              <div className="pt-3">
                <ContentCardWrapper
                  titlePrefix="Grandparent"
                  className="muted"
                  id={grandParentId}
                  isAsset={false}
                  showLink
                />
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MediaContentHeader;
