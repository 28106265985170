import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { GlyphButton } from "components/buttons";

interface Props {
  ids?: number[];
  setIds: (ids: number[]) => void;
  htmlId: string;
}

export const ContentIdsManager = ({ ids, setIds, htmlId }: Props) => {
  return (
    <>
      <ContentIdList ids={ids} setIds={setIds} />
      <AddContentIdForm ids={ids} setIds={setIds} htmlId={htmlId} />
    </>
  );
};

interface ContentIdListProps {
  ids?: number[];
  setIds: (ids: number[]) => void;
}

const ContentIdList = ({ ids, setIds }: ContentIdListProps) => {
  if (!ids) {
    return null;
  }

  const removeId = (contentId: number) => {
    if (!ids) {
      return;
    }
    setIds(ids.filter((id) => id !== contentId));
  };

  return (
    <ul>
      {ids.map((id) => (
        <li key={id} className="mb-1">
          {id}{" "}
          <GlyphButton
            glyph="delete"
            variant="link"
            onClick={() => removeId(id)}
            aria-label="Remove"
          />
        </li>
      ))}
    </ul>
  );
};

interface AddContentIdFormProps {
  ids?: number[];
  setIds: (ids: number[]) => void;
  htmlId: string;
}

const AddContentIdForm = ({ ids, setIds, htmlId }: AddContentIdFormProps) => {
  const [newContentId, setNewContentId] = useState<string>("");

  const addId = () => {
    if (!newContentId) {
      return;
    }
    if (!ids) {
      setIds([parseInt(newContentId)]);
    } else {
      setIds([...ids, parseInt(newContentId)]);
    }
    setNewContentId("");
  };
  return (
    <>
      <Form.Label htmlFor={htmlId}>Content id</Form.Label>
      <Form.Control
        type="number"
        id={htmlId}
        style={{ width: "30rem" }}
        value={newContentId}
        onChange={(e) => {
          const newValue = e.target.value;
          if (!newValue.startsWith("-")) {
            setNewContentId(newValue);
          } else {
            setNewContentId("");
          }
        }}
      />
      <Button
        variant="primary"
        className="mt-1"
        style={{ display: "inline-block" }}
        onClick={addId}
        disabled={newContentId.length === 0}
      >
        Add
      </Button>
    </>
  );
};
