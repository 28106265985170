import _ from "lodash";
import React, { useMemo, useEffect, useReducer, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useUser, useRequest, STATUS } from "client/hooks";
import { request } from "client/utils";
import TeamsSidebar from "./TeamsSidebar";
import TeamsTable from "./TeamsTable";
import { reducer, init } from "./teams-reducer";

const getQueryString = (params) =>
  new URLSearchParams(_.pickBy(params)).toString();

const getFilter = (params) => {
  const seasonId = params.get("seasonId");
  const filter = {
    search: params.get("search") || "",
  };
  if (seasonId) {
    filter.seasonId = parseInt(seasonId);
  }

  return filter;
};

const Teams = () => {
  const { hasPermissionTo } = useUser();
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const qsTeamId = useMemo(() => {
    const teamId = params.get("teamId");
    return teamId ? parseInt(teamId) : undefined;
  }, [params]);

  const initialState = {
    filter: getFilter(params),
    readOnly: !hasPermissionTo("edit", "publication"),
    selectedTeam: qsTeamId ? { id: qsTeamId, ready: false } : {},
  };
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { teams, selectedTeam, readOnly, needRefresh, filter } = state;

  // Load teams for the current filter
  const url = "sports/teams";
  const queryString = getQueryString(filter);
  const endpoint = `${url}?${queryString}`;
  const updateTeams = useCallback(
    (teams) =>
      dispatch({
        type: "teams.loaded",
        payload: teams,
      }),
    []
  );
  const [status, refreshTeams] = useRequest(endpoint, {
    onSuccess: updateTeams,
  });

  // Update the internal filter state to reflect changed query params.
  // Needed when using the league shortcuts in the top toolbar.
  useEffect(() => {
    const newFilter = getFilter(params);

    if (newFilter.seasonId !== filter.seasonId) {
      dispatch({
        type: "filter.reset",
        payload: newFilter,
      });
    }
  }, [params]);

  // Keep query params in sync with changed filters to allow hotlinking
  useEffect(() => {
    const qs = getQueryString({ ...filter, teamId: selectedTeam.id });
    history.push(`?${qs}`);
  }, [filter, selectedTeam.id]);

  // Load info about the selected team when linking directly to
  // a specific team id
  useEffect(() => {
    // Wait until the team list has been loaded, so that we know if
    // the selected team is really within the filtered list of teams
    if (status !== STATUS.OK) {
      return;
    }

    if (selectedTeam.id && !selectedTeam.ready) {
      const teamIds = teams.map((t) => t.id);

      if (teamIds.includes(selectedTeam.id)) {
        request(`sports/teams/${selectedTeam.id}`)
          .then((team) => dispatch({ type: "team.selected", payload: team }))
          .catch(console.error);
      } else {
        // The linked team is not in the list of currently filtered
        // teams, so reset the team selection.
        dispatch({ type: "team.reset" });
      }
    }
  }, [status, teams, selectedTeam]);

  // Refresh the list of teams when a sub-component has indicated
  // that the list is outdated (e.g. when team metadata has been
  // changed through a team card)
  useEffect(() => {
    if (needRefresh) {
      refreshTeams();
    }
  }, [needRefresh, refreshTeams]);

  return (
    <Container fluid className="p-4">
      <Row>
        <Col>
          <TeamsTable
            teams={teams}
            selectedTeam={selectedTeam}
            highlight={filter.search}
            loading={status === STATUS.FETCHING}
            dispatch={dispatch}
          />
        </Col>
        <Col sm="4">
          <TeamsSidebar
            team={selectedTeam}
            filter={filter}
            dispatch={dispatch}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Teams;
