import { extractTitle } from "@telia-company/tv.no-play-cms-common/api/MediaContentUtils";
import { truncate } from "lodash";
import { Asset } from "client/views/Enrich/Insight/GapLog/types";

export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();

export const getTitle = (asset: Asset): string => {
  const topContentData =
    asset.MediaContent.Parent?.Parent?.data ||
    asset.MediaContent.Parent?.data ||
    undefined;
  if (topContentData) {
    return `${truncateTitle(
      extractTitle(asset.MediaContent.data),
      15
    )} - ${truncateTitle(extractTitle(topContentData), 15)}`;
  }
  return `${truncateTitle(extractTitle(asset.MediaContent.data), 30)}`;
};

const truncateTitle = (title: string, length: number): string =>
  truncate(title, { length, omission: "…" });
