import _ from "lodash";
import React, { useCallback } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import AddLanguageDialog from "./AddLanguageDialog";
import LocalizedMetadataEditor from "./LocalizedMetadataEditor";

const defaultLanguages = ["no", "en", "sv"];
const LanguageMap = {
  no: "Norwegian",
  en: "English",
  sv: "Swedish",
  da: "Danish",
  fi: "Finnish",
  es: "Spanish",
  de: "German",
  it: "Italian",
  tr: "Turkish",
  pl: "Polish",
  ar: "Arabic",
};

const isArrayFieldPatched = (allItems, language) => {
  const items = (allItems?.value || []).filter(
    (item) => item.language === language
  );
  const originalItems = (allItems?.original || []).filter(
    (item) => item.language === language
  );

  return !_.isEqual(
    _.sortBy(items, ["value"]),
    _.sortBy(originalItems, ["value"])
  );
};

const isArrayFieldUnsaved = (allItems, language) => {
  if (allItems?.currentValue === undefined) {
    return false;
  }

  const items = (allItems.value || []).filter(
    (item) => item.language === language
  );
  const currentItems = allItems.currentValue.filter(
    (item) => item.language === language
  );

  return !_.isEqual(
    _.sortBy(items, ["value"]),
    _.sortBy(currentItems, ["value"])
  );
};

const LocalizedMetadataCard = ({
  isLoading,
  selectedLanguage,
  applyGenresToChildren,
  data,
  dispatch,
}) => {
  const mcLanguages = [
    ...Object.keys(data?.titles || {}),
    ...Object.keys(data?.storylines || {}),
  ];
  const languages = Array.from(new Set([...defaultLanguages, ...mcLanguages]));
  const missingLanguageTabs = _.omit(LanguageMap, languages);

  const hasEditorialChanges = useCallback(
    (lang) => {
      if (!data) {
        return false;
      }

      const title = data.titles[lang];
      const storylines =
        data.storylines && lang in data.storylines ? data.storylines[lang] : {};

      if (title?.source === "patched") {
        return true;
      }

      for (const { source } of Object.values(storylines)) {
        if (source === "patched") {
          return true;
        }
      }

      if (isArrayFieldPatched(data.genres, lang)) {
        return true;
      }

      if (isArrayFieldPatched(data.keywords, lang)) {
        return true;
      }

      return false;
    },
    [data]
  );

  const hasUnsavedChanges = useCallback(
    (lang) => {
      if (!data) {
        return false;
      }

      const title = data.titles[lang];
      const storylines =
        data.storylines && lang in data.storylines ? data.storylines[lang] : {};

      if (title?.currentValue !== undefined) {
        return true;
      }

      for (const { currentValue } of Object.values(storylines)) {
        if (currentValue !== undefined) {
          return true;
        }
      }

      if (isArrayFieldUnsaved(data.genres, lang)) {
        return true;
      }

      if (isArrayFieldUnsaved(data.keywords, lang)) {
        return true;
      }

      return false;
    },
    [data]
  );

  return (
    <Tab.Container
      id="localized-metadata-editor"
      activeKey={selectedLanguage}
      onSelect={(languageCode) =>
        dispatch({ type: "language.changed", payload: { languageCode } })
      }
    >
      <Card className="mt-4">
        <Card.Header>
          <Nav variant="tabs">
            {languages.map((l) => (
              <Nav.Item key={l}>
                <Nav.Link
                  eventKey={l}
                  className={hasEditorialChanges(l) ? "text-warning" : ""}
                >
                  {LanguageMap[l] || l}
                  {hasUnsavedChanges(l) ? (
                    <span className="text-danger"> *</span>
                  ) : (
                    ""
                  )}
                </Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item>
              <Nav.Link eventKey="add-language">
                <span className="fas fa-plus" title="Add language" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          <Tab.Content>
            {languages.map((l) => (
              <Tab.Pane key={l} eventKey={l}>
                <LocalizedMetadataEditor
                  isLoading={isLoading}
                  language={l}
                  applyGenresToChildren={applyGenresToChildren}
                  data={data}
                  dispatch={dispatch}
                />
              </Tab.Pane>
            ))}
            <Tab.Pane eventKey="add-language">
              <AddLanguageDialog
                languages={missingLanguageTabs}
                dispatch={dispatch}
              />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Card>
    </Tab.Container>
  );
};

export default LocalizedMetadataCard;
