import React, { ReactNode } from "react";
import { Container, Modal } from "react-bootstrap";

interface Props {
  children: ReactNode;
  onClose: () => void;
}

export const DetailsModal = ({ children, onClose }: Props) => {
  return (
    <Modal size="xl" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <h2 className="m-0">Program match details</h2>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>{children}</Container>
      </Modal.Body>
    </Modal>
  );
};
