import React from "react";
import { Form } from "react-bootstrap";
import { FieldProps } from "./types";

export const NoteOnCatchupField = ({ state, applyPatch }: FieldProps) => {
  return (
    <>
      <Form.Label htmlFor="note-on-catchup" className="mt-4">
        Note on catchup
      </Form.Label>
      <Form.Control
        id="note-on-catchup"
        as="textarea"
        rows={5}
        value={state.noteOnCatchup || ""}
        onChange={(event) => applyPatch({ noteOnCatchup: event.target.value })}
      />
    </>
  );
};
