import classNames from "classnames";
import React from "react";

// Telia Play logo, adapted from play-client-web
export function PlayLogo({
  className,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={classNames(className, "play-logo")}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187 196"
      aria-label="Telia logo"
      {...rest}
    >
      <path
        className="pebble-bottom"
        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
        d="M179.7,99.9c0.6-2.2,1.2-4.3,1.7-6.5c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3,0.1-0.4,0.3
	c-5.5,11.6-13,24.2-31.1,38.1c-29.2,22.5-64,37-88.7,37c-10.7,0-19.5-2.6-25.9-7.8c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1
	c0,0,0,0.1-0.1,0.1c1.3,1.5,2.7,3,4,4.4c0,0,0.1,0.1,0.1,0.1c2.1,2.3,4.3,4.4,6.5,6.5c0,0,0,0,0.1,0c0.5,0.5,1,0.9,1.5,1.3
	c0,0,0,0,0,0c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.3,0.7,0.6,1,0.9c1.5,1.4,3.1,2.7,4.7,3.9c0,0,0,0,0,0c1.3,1,2.6,2,3.9,3c0,0,0,0,0,0
	c2.7,2,5.4,3.8,8.2,5.4c0,0,0,0,0,0c2.9,1.7,5.8,3.2,8.6,4.5c0,0,0,0,0,0c2.3,1,4.6,1.8,6.8,2.5c0,0,0,0,0,0c4,1.2,7.9,1.8,11.7,1.8
	c15.5,0,34.1-10.3,50.7-28.4c0.1-0.1,0.1-0.1,0.2-0.2c4.9-5.3,9.6-11.3,14-17.8c0,0,0,0,0,0c1.4-2.1,2.8-4.3,4.2-6.6c0,0,0,0,0,0
	c3-5,5.9-10.3,8.5-15.9l0,0c0.3-0.7,0.7-1.5,1-2.2c0,0,0,0,0,0c1.6-3.5,3.1-7.1,4.5-10.8c0,0,0,0,0,0c0.7-1.9,1.4-3.9,2.1-5.8
	c0,0,0,0,0,0c0.9-2.6,1.7-5.3,2.5-8C179.7,100,179.7,99.9,179.7,99.9"
      />
      <defs>
        <filter
          filterUnits="userSpaceOnUse"
          x="0"
          y="7.7"
          width="187"
          height="161.7"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="7.7" width="187" height="161.7">
        <g>
          <polygon
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#FFFFFF",
            }}
            points="0,7.7 187,7.7 187,169.4 0,169.4 		"
          />
        </g>
      </mask>
      <path
        className="pebble-middle"
        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
        d="M186.9,52.4c0-1.6,0.1-3.2,0.1-4.8c0-2-0.1-3.9-0.3-5.8c0,0,0,0,0,0c-0.2-2.1-0.4-4.1-0.8-6
	c0,0,0,0-0.1,0c-0.5-2.5-1.2-4.8-2-7c0,0,0,0,0,0c-0.7-1.9-1.5-3.6-2.4-5.3c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.4-0.7
	c0,0,0,0,0,0c-0.9-1.6-1.9-3.1-3-4.6c0,0,0,0,0,0c-1-1.2-2-2.4-3.1-3.5c0,0,0,0,0,0c-1.4-1.4-2.9-2.7-4.5-3.9c0,0,0,0,0,0
	c-1.5-1.1-3.1-2.1-4.7-3c0,0-0.1,0-0.1,0c-0.1,0.2-0.2,0.4,0,0.5c0.1,0.2,0.3,0.4,0.4,0.6c1.6,2.7,1.9,6,0.7,9.8
	c-4.7,15.4-31.9,37.6-66.2,53.9C69.9,87.1,40.7,95.8,22.2,95.8c-10.7,0-17.4-2.8-20.1-8.3c-0.7-1.3-1-3.1-0.9-5.2
	c0-0.2-0.2-0.4-0.4-0.4h0c0,0-0.1,0-0.1,0c-0.3,2.3-0.6,4.6-0.6,7c0,0.1,0,0.3,0,0.4c0,2.6,0.3,5.4,0.7,8.3c0,0,0,0,0,0
	c0.2,1.5,0.5,3,0.9,4.5c0,0,0,0,0,0c0.7,3.2,1.7,6.5,3,9.9c0,0,0,0,0,0c0.3,0.8,0.6,1.6,0.9,2.4c0,0,0,0,0,0c1,2.6,2.2,5.3,3.4,8
	c0,0,0,0,0,0c0.6,1.3,1.2,2.5,1.8,3.8l0,0c0,0,0,0.1,0.1,0.1c0.2,0.3,0.3,0.6,0.5,0.9c0,0,0,0,0,0c0.9,1.8,1.9,3.6,3,5.4
	c0,0,0,0,0,0c0.2,0.3,0.3,0.6,0.5,0.8c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.4,0.7,0.8,1.3,1.2,2c0,0,0,0,0,0c1.2,2,2.5,4.1,3.9,6.1
	c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0.2,0.3,0.5,0.7,0.7,1c0,0,0,0,0,0c0.6,0.9,1.2,1.8,1.8,2.7c0,0,0,0,0,0c0.2,0.3,0.5,0.6,0.7,1
	c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c1.4,1.9,2.8,3.8,4.3,5.7c0,0,0,0,0.1,0c1.1,1.4,2.3,2.9,3.4,4.3c0,0,0,0,0,0
	c1.4,1.7,2.9,3.5,4.4,5.1c6.5,4.9,15,7.3,25.4,7.3c24.8,0,59.8-14.6,89.2-37.2c17.6-13.5,25.3-25.9,30.8-37.2
	c0.8-3.2,1.5-6.4,2.2-9.6c0,0,0-0.1,0-0.1c0.4-2,0.8-4,1.1-6.1c0,0,0,0,0,0c0.4-2.6,0.8-5.2,1.2-7.8c0,0,0,0,0,0
	c0.2-1.8,0.4-3.7,0.6-5.6c0,0,0,0,0,0c0.3-3.3,0.6-6.5,0.7-9.9c0,0,0,0,0,0C186.8,54.7,186.8,53.5,186.9,52.4
	C186.9,52.4,186.9,52.4,186.9,52.4"
      />
      <defs>
        <filter
          filterUnits="userSpaceOnUse"
          x="0.4"
          y="0.3"
          width="167.8"
          height="96.3"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="0.4"
        y="0.3"
        width="167.8"
        height="96.3"
      >
        <g>
          <polygon
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#FFFFFF",
            }}
            points="0.4,0.3 168.2,0.3 168.2,96.7 0.4,96.7 		"
          />
        </g>
      </mask>
      <path
        className="pebble-top"
        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
        d="M166.6,8.4c-0.1-0.1-0.2-0.2-0.2-0.3c-2.6-1.5-5.5-2.8-8.5-3.8c-0.1,0-0.1-0.1-0.2-0.1
	c-3.1-1.1-6.3-1.9-9.6-2.5c0,0,0,0,0,0c-4.2-0.8-8.6-1.2-13-1.3c-0.8,0-1.5-0.1-2.3-0.1c-5.3,0-10.7,0.3-16.2,0.9c0,0,0,0,0,0
	c-3.7,0.4-7.4,0.9-11.1,1.6c-0.1,0-0.1,0-0.2,0c-6.6,1.2-13.1,2.8-19.6,4.7c0,0,0,0-0.1,0c-17.9,5.3-35,13.4-49.2,23.6c0,0,0,0,0,0
	c-0.4,0.3-0.7,0.5-1.1,0.8c0,0,0,0-0.1,0.1c-4.5,3.3-8.8,6.8-12.6,10.5c0,0-0.1,0.1-0.1,0.1c-3,2.9-5.7,5.9-8.2,9.1c0,0,0,0,0,0
	c-0.3,0.4-0.7,0.9-1,1.3c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-1.6,2.2-3.2,4.4-4.5,6.7c0,0,0,0,0,0c-1.5,2.5-2.8,5-3.9,7.5c0,0,0,0,0,0
	c0,0,0,0,0,0c-1.2,2.6-2.1,5.3-2.9,8c0,0,0,0.1,0,0.1c-0.7,2.7-1.3,5.4-1.6,8.2c0.1,1.6,0.4,3.1,0.9,4.3c2.8,5.8,9.9,8.8,20.9,8.8
	c18.6,0,48-8.7,78.7-23.3c34.5-16.4,61.9-38.8,66.6-54.4C168.7,14.9,168.4,11.3,166.6,8.4"
      />
      <path
        className="pebble-top"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" }}
        d="M77.9,69.8c-0.6,0-1.1,0.1-1.6,0.4c-1,0.6-1.6,1.7-1.6,2.8v54.1c0,1.8,1.5,3.2,3.2,3.2
	c0.6,0,1.2-0.2,1.7-0.5h0.1l48.3-27.1c1.5-1,1.9-3,0.9-4.5c-0.2-0.4-0.6-0.7-0.9-0.9L79.6,70.3C79.1,70,78.5,69.8,77.9,69.8
	 M77.9,136.9c-1.6,0-3.3-0.4-4.7-1.2c-3.1-1.7-5.1-5-5-8.5V73.1c0-5.4,4.3-9.7,9.7-9.7c1.8,0,3.6,0.5,5.1,1.5l48.5,27.1
	c4.5,2.9,5.9,8.9,3,13.4c-0.8,1.2-1.8,2.2-3,3l-0.1,0.1l-48.3,27C81.5,136.4,79.7,136.9,77.9,136.9"
      />
    </svg>
  );
}
