import { PropTypes } from "prop-types";
import React from "react";
import { Accordion } from "react-bootstrap";
import { CmsPure } from "client/utils";
import ProvisionerAccordionRow from "./ProvisionerAccordionRow";

class ProvisionerAccordion extends CmsPure {
  state = { expandedId: null };

  onClick = (id) => {
    if (id !== this.state.expandedId) {
      this.setState({ expandedId: id });
    } else this.setState({ expandedId: null });
  };
  render() {
    const { selections, downloadPrefix, isMulti, fetchById } = this.props;
    const expandedId = this.state.expandedId;
    return (
      <Accordion activeKey={expandedId}>
        {selections.map((selection) => {
          const disabled = !selection;
          if (!selection) selection = {};
          return (
            <ProvisionerAccordionRow
              key={selection ? selection.id : "empty"}
              downloadPrefix={downloadPrefix}
              disabled={disabled}
              selection={selection}
              onClick={this.onClick}
              isExpanded={expandedId === selection.id}
              className={isMulti ? "flex-row border rounded my-1" : undefined}
              label={isMulti ? this.props.labelFunction(selection) : undefined}
              fetchById={fetchById}
            />
          );
        })}
      </Accordion>
    );
  }
}

ProvisionerAccordion.propTypes = {
  selections: PropTypes.array.isRequired,
  fetchById: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  labelFunction: PropTypes.func, // used if isMulti is true
};

export default ProvisionerAccordion;
