import React from "react";
import { Route } from "react-router-dom";
import LinearMetadataDb from "./LinearMetadataDb";

/*
 * This array has been put in a separate file to allow jest to figure out
 * that LinearMetadataDb tests don't depend on ProgramMatches or MergeContent.
 * Had it been placed in the same file as the Epg component, jest will run all
 * tests that import anything from the file containing the Epg component.
 */

export const linearMetadataDbRoutes = [
  <Route
    key="linear-metadata-db"
    path={"/epg/linear-metadata-db"}
    exact
    component={LinearMetadataDb}
  />,
  <Route
    key="linear-metadata-db-type-id"
    path={"/epg/linear-metadata-db/:type/:id"}
    exact
    component={LinearMetadataDb}
  />,
  <Route
    key="linear-metadata-db-type-from-to"
    path={"/epg/linear-metadata-db/:type/from/:fromId/to/:toId"}
    exact
    component={LinearMetadataDb}
  />,
];
