import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRequest, STATUS } from "client/hooks";
import { request } from "client/utils";
import ConfigurationRow from "./ConfigurationRow";

export function AmsConfigTab() {
  const [allConfigs, setAllConfigs] = useState([]);
  const [configFetchStatus] = useRequest("ams/configurations", {
    onSuccess: (res) => setAllConfigs(res.data),
  });

  const updateConfig = async (key, value) => {
    const endpoint = `ams/configurations/${key}`;

    try {
      await request(endpoint, {
        method: "PUT",
        body: JSON.stringify({ value: String(value) }),
      });
    } catch (err) {
      console.error(err);
      if (err.status === 422) {
        toast.error("Validation error, nothing saved");
      } else {
        toast.error(`Failed to update ${key}: ${err.message}`);
      }
      return false;
    }

    toast.info(`Successfully updated ${key}`);

    try {
      const newConfigResponse = await request(endpoint, {
        method: "GET",
      });

      if (newConfigResponse)
        setAllConfigs(
          allConfigs.map((c) =>
            c.key === newConfigResponse.key ? newConfigResponse : c
          )
        );
    } catch (err) {
      toast.error(`Failed to fetch ${key}`);
      console.error(err);
    }

    return true;
  };

  return (
    <Container fluid>
      <Row className="mt-2">
        <Col xs={2}>
          <h4 className="lead mb-0">Updated</h4>
        </Col>
        <Col xs={3}>
          <h4 className="lead mb-0">Key</h4>
        </Col>
        <Col xs={7}>
          <h4 className="lead mb-0">Value</h4>
        </Col>
      </Row>

      {configFetchStatus === STATUS.FETCHING ? (
        "Loading..."
      ) : (
        <>
          {allConfigs.map((config) => (
            <ConfigurationRow
              key={config.key}
              config={config}
              updateConfig={updateConfig}
            />
          ))}
        </>
      )}
    </Container>
  );
}
