import React from "react";
import { useProviders } from "client/hooks";
import { getValidDateString } from "client/utils";
import IMDbList from "./IMDbList";

const IgnoredIMDbList = () => {
  const { providerMap } = useProviders();

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Kind",
        accessor: "kind",
      },
      {
        Header: "Provider",
        accessor: "providerId",
        Cell: ({ value }) => providerMap[value],
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Production year",
        accessor: "productionYear",
        sortDescFirst: true,
      },
      {
        Header: "Created at",
        accessor: "createdAt",
        sortDescFirst: true,
        Cell: ({ value }) => getValidDateString(value),
      },
      {
        Header: "Updated at",
        accessor: "updatedAt",
        sortDescFirst: true,
        Cell: ({ value }) => getValidDateString(value),
      },
    ],
    [providerMap]
  );

  return <IMDbList endpoint="imdb/ignored" columns={columns} />;
};

export default IgnoredIMDbList;
