import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Alert, Row, Col, Form } from "react-bootstrap";
import { useRequest } from "client/hooks";
import TeamCard from "./TeamCard";
import { displayName } from "./utils";

const TeamsSidebar = ({ team, filter, readOnly, dispatch }) => {
  const [search, setSearch] = useState(filter.search);
  const [activeOnly, setActiveOnly] = useState(true);
  const toggleActiveOnly = useCallback(
    () => setActiveOnly((active) => !active),
    []
  );

  useEffect(() => {
    if (search !== filter.search) {
      const handle = setTimeout(() => {
        dispatch({ type: "filter.updated", payload: { search } });
      }, 250);
      return () => clearTimeout(handle);
    }
  }, [search, filter.search]);

  useEffect(() => setSearch(filter.search), [filter.search]);

  const endpoint = useMemo(
    () => `sports/seasons?activeOnly=${activeOnly}`,
    [activeOnly]
  );
  const [seasons, setSeasons] = useState([]);
  useRequest(endpoint, { onSuccess: setSeasons });

  const activeSeasons = useMemo(
    () => seasons.filter((s) => s.active),
    [seasons]
  );
  const inactiveSeasons = useMemo(
    () => seasons.filter((s) => !s.active),
    [seasons]
  );

  return (
    <>
      <Alert variant="light">
        <Row>
          <Form.Group as={Col}>
            <div className="d-flex justify-content-between">
              <Form.Label>League/season</Form.Label>
              <Form.Check
                type="checkbox"
                label="Active only"
                id="active-only"
                checked={activeOnly}
                onChange={toggleActiveOnly}
              />
            </div>
            <Form.Select
              onChange={(e) => {
                const id = e.target.value;
                const seasonId = id !== "all" ? id : undefined;
                dispatch({ type: "filter.updated", payload: { seasonId } });
              }}
              value={filter.seasonId || "all"}
            >
              <option value="all">All leagues</option>
              {inactiveSeasons.length === 0 ? (
                seasons.map((s) => (
                  <option value={s.id} key={s.id}>
                    {`${displayName(s.competition)} ${displayName(s)}`}
                  </option>
                ))
              ) : (
                <>
                  <optgroup label="Active">
                    {activeSeasons.map((s) => (
                      <option value={s.id} key={s.id}>
                        {`${displayName(s.competition)} ${displayName(s)}`}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Inactive">
                    {inactiveSeasons.map((s) => (
                      <option value={s.id} key={s.id}>
                        {`${displayName(s.competition)} ${displayName(s)}`}
                      </option>
                    ))}
                  </optgroup>
                </>
              )}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="my-3">
          <Col>
            <Form.Label>Filter teams by name</Form.Label>
            <Form.Control
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
            />
          </Col>
        </Row>
      </Alert>
      {team.ready && (
        <TeamCard
          team={team}
          readOnly={readOnly}
          onChange={(t) => dispatch({ type: "team.reloaded", payload: t })}
        />
      )}
    </>
  );
};

export default TeamsSidebar;
