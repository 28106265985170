import _ from "lodash";
import React from "react";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";

const renderInfo = (displayItem?: string, hiddenItems?: string[]) => {
  return (
    <Badge pill bg="dark" style={{ fontSize: "0.65rem" }}>
      {displayItem}
      {hiddenItems && !_.isEmpty(hiddenItems) && " +" + hiddenItems.length}
    </Badge>
  );
};

function CmsCardTooltipInfo({ items, ...rest }: { items: string[] }) {
  const displayItem = React.useMemo(() => items[0], [items]);
  const hiddenItems = React.useMemo(() => items.slice(1), [items]);

  return (
    <div {...rest}>
      {_.isEmpty(hiddenItems) ? (
        renderInfo(displayItem)
      ) : (
        <OverlayTrigger
          placement="right-start"
          overlay={
            <Tooltip id="cms-card-tooltip">
              {hiddenItems.map((item, index) => {
                return <div key={index}>{item}</div>;
              })}
            </Tooltip>
          }
        >
          {renderInfo(displayItem, hiddenItems)}
        </OverlayTrigger>
      )}
    </div>
  );
}

export default CmsCardTooltipInfo;
