import React from "react";
import { Form } from "react-bootstrap";
import { FieldProps } from "./types";

const OPTIONS = ["None", "Premium", "Standard", "Static", "N/A"];

export const SLAField = ({ state, applyPatch }: FieldProps) => {
  return (
    <>
      <Form.Label htmlFor="sla">SLA (Historic)</Form.Label>
      <Form.Select
        id="sla"
        value={state.sla}
        onChange={(e) => applyPatch({ sla: e.target.value })}
      >
        {OPTIONS.map((slaOption) => (
          <option key={slaOption} value={slaOption}>
            {slaOption}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
