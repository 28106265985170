import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";

interface Props {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

export const DynamicCollectionToggle = ({
  checked,
  onChange,
  disabled,
}: Props) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor="dynamic-collection">Dynamic Collection</Form.Label>
      <Form.Check
        type="switch"
        id="dynamic-collection"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    </Form.Group>
  );
};
