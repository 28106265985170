import { CollectionFilters } from "@telia-company/tv.no-play-cms-common/collection/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { request } from "client/utils";
import { Collection } from "../types";
import { getCollectionChangeHandlers, getEmptyCollection } from "../utils";

export function useCollection(id?: string | number) {
  const defaultCollectionValues = useMemo(() => getEmptyCollection(), []);

  // Load initial collection from API
  const url = id ? `collections/${id}` : null;
  const { data, error, isValidating } = useSWR<Collection>(url, {
    fallbackData: defaultCollectionValues,
    revalidateOnFocus: false,
  });

  // Local collection state for the collection editor. May include local
  // edits that have not yet been saved.
  const [collection, setCollection] = useState<Collection>(
    getInitialState(defaultCollectionValues, data)
  );

  // Event handlers for updating collection fields in local state.
  const onChangeHandlers = useMemo(
    () => getCollectionChangeHandlers(setCollection),
    [getCollectionChangeHandlers, setCollection]
  );

  // Local edits should be reset whenever new collection data is loaded
  // from the API.
  useEffect(
    () => setCollection({ ...defaultCollectionValues, ...data }),
    [defaultCollectionValues, data]
  );

  const save = useCallback(() => {
    const body = JSON.stringify(collection);
    const url = id ? `collections/${id}` : "collections/";
    const method = id ? "PUT" : "POST";

    return request(url, { method, body });
  }, [id, collection]);

  return {
    collection,
    save,
    error,
    isLoading: isValidating || (!data && !error),
    onChangeHandlers,
  };
}

// Exported for testing
export const getInitialState = (
  defaultCollectionValues: Collection,
  data?: Collection
) => {
  const filters: CollectionFilters = data
    ? { ...defaultCollectionValues.filters, ...data.filters }
    : defaultCollectionValues.filters;
  if (filters.parties) {
    filters.directors = filters.parties;
    filters.parties = undefined;
  }
  return {
    ...defaultCollectionValues,
    ...data,
    filters,
  };
};
