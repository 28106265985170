import _ from "lodash";
import { PropTypes } from "prop-types";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CLIENT_CONFIG } from "client/config";
import { CmsPure, humanize } from "client/utils";
import { parseDateToDatetimeString } from "client/utils/TableCommon";
import { GlyphButton } from "components/buttons";
import Table, {
  getManageColumn,
  getStatusColumn,
  OptionCell,
  OptionsFilter,
  TextFilter,
} from "components/tables";

const PROPERTIES = [
  { value: "offline", label: "Offline", variant: "info" },
  { value: "catchup", label: "Catchup", variant: "warning" },
  { value: "httpStream", label: "HTTP Stream", variant: "primary" },
  { value: "recordable", label: "Recordable", variant: "light" },
  { value: "externalCatchup", label: "External Catchup", variant: "secondary" },
];

const VOD_STATUS = [
  { value: "active", label: "Active", variant: "light" },
  { value: "disabled", label: "Inactive", variant: "secondary" },
  { value: "beta", label: "Beta", variant: "info" },
];

function generateColumnName(key) {
  const columnMap = {
    serviceType: "Service type",
    originalNetworkId: "ONID",
    transportStreamId: "TSID",
    serviceId: "Service ID",
    ipAddress: "IP address",
    ipAddressAes: "IP address (AES)",
    protocolAes: "Protocol (AES)",
    fccProtocol: "FCC protocol",
    fccIpAddress: "FCC IP address",
    fccPort: "FCC port",
    symbolRate: "Symbol rate",
    symbolRateAes: "Symbol rate (AES)",
    frequencyInKHz: "Frequency (KHz)",
    frequencyInKHzAes: "Frequency (KHz) (AES)",
    lineupName: "Lineup name",
    channelNumber: "Channel number",
    tvProviderName: "TV provider name",
    modulationAes: "Modulation (AES)",
    portAes: "Port (AES)",
    ssrcId: "SSRC ID",
    sortOrder: "Global sort order",
    caDescriptorPrivateData: "CA descriptor private data",
  };

  return columnMap[key] || humanize(key);
}

/**
 * Takes a key and generates a column for it. Handle special cases here.
 * @param {string} key
 * @param {function} modalPopup
 * @returns {ReactTable.Col}
 */
function generateColumn(key, modalPopup) {
  switch (key) {
    // dates
    case "created":
    case "updated":
    case "deployed":
      return {
        id: key,
        Header: generateColumnName(key),
        accessor: (record) => parseDateToDatetimeString(record[key]),
        Filter: TextFilter,
        filter: "text",
        minWidth: 50,
      };
    case "status":
      return getStatusColumn({ accessor: "active" });
    case "vodStatus":
      return {
        id: "status",
        Header: "Status",
        accessor: (record) => {
          if (record.active) {
            return "active";
          } else if (!record.active && record.beta) {
            return "beta";
          } else if (!record.active && !record.beta) {
            return "disabled";
          }
        },
        width: 85,
        Cell: ({ value }) => <OptionCell options={VOD_STATUS} value={value} />,
        Filter: ({ column }) => (
          <OptionsFilter options={VOD_STATUS} column={column} />
        ),
        filter: "text",
      };
    case "logo":
      return {
        id: key,
        Header: generateColumnName(key),
        accessor: (record) => {
          const path =
            record.logos &&
            (record.logos.fixedOnLight ||
              record.logos.fixedOnDark ||
              record.logos.normalOnDark ||
              record.logos.detailOnDark);
          if (!path) return null;
          return (
            <div style={{ textAlign: "center" }}>
              <img src={CLIENT_CONFIG.CDN_DOMAIN + path + "?h=30"} />
            </div>
          );
        },
        filterable: false,
        minWidth: 30,
      };
    case "properties":
      return {
        id: key,
        Header: "Properties",
        accessor: (record) =>
          [
            record.offlineEnabled && "offline",
            record.catchupEnabled && "catchup",
            record.httpStreamEnabled && "httpStream",
            record.recordableEnabled && "recordable",
            record.externalCatchup && "externalCatchup",
          ].filter((v) => !!v),
        Cell: ({ value }) =>
          value.map((val) => (
            <OptionCell key={val} options={PROPERTIES} value={val} />
          )),
        Filter: ({ column }) => (
          <OptionsFilter options={PROPERTIES} column={column} />
        ),
        filter: "text",
        minWidth: 50,
      };
    case "configDraftId":
      return {
        id: key,
        accessor: key,
        Header: "Config Draft Id",
        Cell: ({ value }) => (
          <Button
            variant="link"
            onClick={() => modalPopup(`configs/drafts/${value}`)}
          >
            {value}
          </Button>
        ),
      };
    // small columns are centered
    case "serviceId":
    case "serviceType":
    case "transportStreamId":
    case "originalNetworkId":
    case "protocol":
    case "ipAddress":
    case "port":
    case "frequencyInKHz":
    case "modulation":
    case "symbolRate":
    case "dvbBouquetId":
    case "ssrcId":
    case "fccIpAddress":
    case "fccPort":
    case "videoMetadataId":
      return {
        Header: generateColumnName(key),
        accessor: key,
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
        Filter: TextFilter,
        filter: "text",
        minWidth: 35,
      };
    case "fccProtocol":
      return {
        Header: generateColumnName(key),
        accessor: key,
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
        Filter: TextFilter,
        filter: "text",
        minWidth: 45,
      };
    case "channelNumber":
      return {
        Header: generateColumnName(key),
        accessor: key,
        filterMethod: (filter, row) => row.channelNumber == filter.value,
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
        Filter: TextFilter,
        filter: "text",
        minWidth: 35,
      };
    // large columns is default
    default:
      return {
        Header: generateColumnName(key),
        accessor: key,
        Filter: TextFilter,
        filter: "text",
        minWidth: 70,
      };
  }
}

/**
 * Push url, actions and more columns
 * @param {array} columns To push to
 * @param {object} props Props to access endpoint link and functions
 */
function pushAdditionalColumns(
  columns,
  { clientUrl, canDelete, canEdit, onDelete, onEdit }
) {
  if (canEdit || canDelete) {
    columns.push(getManageColumn(canEdit && onEdit, canDelete && onDelete));
  }

  const isConfigDrafts = clientUrl === "/provisioning/configs/drafts";

  columns.push({
    id: "json",
    Header: "JSON",
    accessor: "id",
    Cell: ({ value }) => {
      const diffUrl = `/provisioning/configs/drafts/${value}/diff`;
      return (
        <ButtonGroup className="mx-n1">
          <GlyphButton
            to={`${clientUrl}/raw/${value}`}
            glyph="code"
            className="p-1"
            variant="link"
            title="View JSON"
          />
          {isConfigDrafts && (
            <GlyphButton
              to={diffUrl}
              glyph="diff"
              className="p-1"
              variant="link"
              title="Diff against currently deployed config"
            />
          )}
        </ButtonGroup>
      );
    },
    canResize: false,
    style: { textAlign: "center" },
    width: isConfigDrafts ? 80 : 40,
  });
}

class ProvisionerTable extends CmsPure {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
    };
  }

  componentDidMount() {
    this.componentDidUpdate({});
  }

  propsChanged(prevProps) {
    const { clientUrl, columnKeys } = this.props;

    return (
      clientUrl !== prevProps.clientUrl ||
      !_.isEqual(columnKeys, prevProps.columnKeys)
    );
  }

  componentDidUpdate(prevProps) {
    const { columnKeys, modalPopup } = this.props;

    if (this.propsChanged(prevProps)) {
      const columns = columnKeys.map((key) => generateColumn(key, modalPopup));
      pushAdditionalColumns(columns, this.props);
      this.setState({ columns });
    }
  }

  render() {
    return (
      <>
        <div className="flex-row my-4">
          <h4 className="flex-fill">{this.props.title}</h4>
          {this.props.canEdit && (
            <Link to={this.props.clientUrl + "/create"}>
              <GlyphButton glyph="create" variant="primary" />
            </Link>
          )}
          <GlyphButton
            className="ms-2"
            glyph="refresh"
            variant="primary"
            onClick={this.props.fetchData}
          />
        </div>
        <Table
          data={this.props.data}
          columns={this.state.columns}
          initialState={{
            sortBy: [
              { id: "status", desc: true },
              { id: "updated", desc: true },
              { id: "created", desc: true },
              { id: "name", desc: false },
            ],
            filters: this.props.defaultFiltered || [],
            pageSize: 50,
          }}
          filter
          paginate
          flex
          loading={this.props.fetching}
        />
      </>
    );
  }
}

ProvisionerTable.propTypes = {
  columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  clientUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ProvisionerTable;
