import classNames from "classnames";
import React from "react";
import { CmsPure } from "client/utils";
import { ITEM_HEIGHT } from "../utils";

function getTransform(left) {
  const transform = `translate3d(${left < 0 ? -Math.round(left) : 0}px, 0, 0)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export default class Content extends CmsPure {
  render() {
    const { title, description, position, itemType, active, size, invalid } =
      this.props;
    const transform = getTransform(position.left);

    return (
      <div
        className={classNames("scheduler-event-content", itemType, size, {
          active,
          invalid,
        })}
        title={title}
        style={{ height: ITEM_HEIGHT }}
      >
        <div className="title" style={transform}>
          {title}
        </div>
        <div className="description" style={transform}>
          {description}
        </div>
      </div>
    );
  }
}
