import React from "react";
import { Link } from "react-router-dom";
import { Column, CellProps } from "react-table-v7";
import { ContentEditsRow } from "client/hooks/useContentEdits";
import { Truncate } from "components/Truncate";
import { KindBadge, StatusBadge } from "components/badges";
import { RelativeTime } from "components/time/RelativeTime";
import CollapsedList from "./CollapsedList";

interface IdValue<T> {
  id: T;
  value: T;
}

const humanizedFieldNames: Record<string, string> = {
  crid: "CRID",
  index: "Index",
  genres: "Genres",
  images: "Images",
  titles: "Titles",
  storylines: "Storylines",
  keywords: "Keywords",
  productionDate: "Production date",
  adultContent: "Adult content",
};

const getContentEditStatus = (timestamps: {
  originalUpdated: string;
  editorialUpdated: string;
}) => {
  const { originalUpdated, editorialUpdated } = timestamps;

  return new Date(editorialUpdated) < new Date(originalUpdated)
    ? "PROVIDER"
    : "EDITORIAL";
};

export const getColumns = (
  providers: IdValue<string>[],
  channels: IdValue<string>[]
): ReadonlyArray<Column<ContentEditsRow>> => [
  {
    accessor: "title",
    Header: "Title",
    Cell: ({ cell: { row } }: CellProps<ContentEditsRow>) => {
      const { id, title, topTitle } = row.original;
      const combinedTitle =
        title === topTitle || !topTitle ? title : `${topTitle}: ${title}`;
      return (
        <Link
          to={`/enrich/mediacontent/editor/${id}`}
          style={{ textDecoration: "underline" }}
        >
          {combinedTitle}
        </Link>
      );
    },
    disableSortBy: true,
    width: 100,
  },
  {
    accessor: "crid",
    Header: "CRID",
    Cell: ({ cell: { value } }: CellProps<ContentEditsRow>) => (
      <Truncate>{value}</Truncate>
    ),
    width: 60,
    disableSortBy: true,
  },
  {
    accessor: "kind",
    Header: "Kind",
    Cell: ({ cell: { value } }: CellProps<ContentEditsRow>) => (
      <KindBadge kind={value} />
    ),
    disableSortBy: true,
    width: 20,
  },
  {
    accessor: "channels",
    Header: "Channel/Provider",
    Cell: ({ cell: { row } }: CellProps<ContentEditsRow>) => {
      const channelProviderNames = [];
      for (const channel of row.original.channels) {
        const channelName =
          channels?.find((c) => c.id === channel)?.value || channel;
        channelProviderNames.push(channelName);
      }
      for (const provider of row.original.providers) {
        const providerName =
          providers?.find((p) => p.id === provider)?.value || provider;
        channelProviderNames.push(providerName);
      }
      return <CollapsedList listItems={channelProviderNames} />;
    },
    disableSortBy: true,
    width: 50,
  },
  {
    accessor: "changedFields",
    Header: "Changed fields",
    Cell: ({ cell: { value } }: CellProps<ContentEditsRow>) => {
      const changedFields = value.map(
        (field: string) => humanizedFieldNames[field] || field
      );
      return <CollapsedList listItems={changedFields} />;
    },
    disableSortBy: true,
    width: 50,
  },
  {
    accessor: "originalUpdated",
    Header: "Last provider update",
    Cell: ({ cell: { value } }: CellProps<ContentEditsRow>) => (
      <RelativeTime timestamp={value} />
    ),
    width: 50,
  },
  {
    accessor: "editorialUpdated",
    Header: "Last editorial update",
    Cell: ({ cell: { value } }: CellProps<ContentEditsRow>) => (
      <RelativeTime timestamp={value} />
    ),
    width: 50,
  },
  {
    id: "lastVersion",
    accessor: (originalRow) =>
      getContentEditStatus({
        originalUpdated: originalRow.originalUpdated,
        editorialUpdated: originalRow.editorialUpdated,
      }),
    Header: "Last version",
    Cell: ({ cell: { value } }: CellProps<ContentEditsRow>) => (
      <StatusBadge
        status={value}
        statusToVariant={getVariantFromStatus}
        showTitle
      />
    ),
    disableSortBy: true,
    width: 30,
  },
  {
    accessor: "editor",
    Header: "Updated by",
    Cell: ({ cell: { value } }: CellProps<ContentEditsRow>) => (
      <Truncate>{value}</Truncate>
    ),
    disableSortBy: true,
    width: 40,
  },
];

function getVariantFromStatus(status: string) {
  switch (status) {
    case "PROVIDER":
      return "success";
    case "EDITORIAL":
      return "light";

    default:
      return "light";
  }
}
