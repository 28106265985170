import _ from "lodash";
import React from "react";
import { Col, Row, Form } from "react-bootstrap";

export default function KeyValuePair({ id, value, labelWidth = 6, ...rest }) {
  return (
    <Row className="mb-2" {...rest}>
      <Col xs={labelWidth} className="text-end my-auto">
        {id}
      </Col>
      <Col className="text-secondary my-auto">{value}</Col>
    </Row>
  );
}

export function EditableKeyValuePair({
  id,
  value,
  disabled,
  onChange,
  maxLength = undefined,
  size = "sm",
  ...rest
}) {
  const renderedValue = !disabled ? (
    <Form.Control
      id={id}
      value={_.isNil(value) ? "" : value}
      onChange={(e) => onChange(e.target.value)}
      size={size}
      maxLength={maxLength}
    />
  ) : (
    value
  );
  return <KeyValuePair id={id} value={renderedValue} {...rest} />;
}
