import React from "react";
import { Alert } from "react-bootstrap";
import MatchMappingSummary from "./MatchMappingSummary";
import TeamInfo from "./TeamInfo";
import { displayName, formatMatchDate } from "./utils";

const MatchCard = ({ match, season, matchCard, readOnly, dispatch }) => {
  const { homeTeam, awayTeam, date, time } = match;
  return (
    <Alert variant="light" className="pb-4">
      <Alert.Heading>
        {homeTeam && awayTeam
          ? `${displayName(homeTeam)} - ${displayName(awayTeam)}`
          : "Unknown match"}
      </Alert.Heading>
      <div className="d-flex">
        <div className="w-100">
          <div>
            <b>Kickoff</b>
          </div>
          <div>{formatMatchDate(date, time)}</div>
        </div>
        <div className="w-100">
          <MatchMappingSummary
            match={match}
            season={season}
            readOnly={readOnly}
            dialog={matchCard.mappingDialog}
            dispatch={dispatch}
          />
        </div>
      </div>
      <div className="d-flex mt-2">
        <TeamInfo team={homeTeam} label="Home team" dispatch={dispatch} />
        <TeamInfo team={awayTeam} label="Away team" dispatch={dispatch} />
      </div>
    </Alert>
  );
};

export default MatchCard;
