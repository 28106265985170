import classNames from "classnames";
import React from "react";
import { useDrag, useDrop } from "react-dnd";

const itemType = "rearrangable";

/**
 * Wraps children in a draggable div. Passes two indices to the onMove
 * function when a rearrangable is dragged over of the same type.
 */
const Rearrangable = (props) => {
  const [collected, drag] = useDrag({
    type: itemType,
    item: () => ({
      index: props.index,
      type: props.type,
      initialIndex: props.index,
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) return;
      const { index, initialIndex } = monitor.getItem();
      if (index === initialIndex) return;
      props.onMove(index, initialIndex);
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop({
    accept: itemType,
    hover: (item, monitor) => {
      if (!monitor.canDrop() || !monitor.isOver({ shallow: true })) return;
      const { index, type } = item;
      const hoverIndex = props.index;

      if (type !== props.type || index === hoverIndex) return;

      props.onMove(index, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const {
    children,
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onMove,
    ...rest
  } = props;
  return drag(
    drop(
      <div
        className={classNames(className, "rearrangable", {
          isDragging: collected.isDragging,
        })}
        {...rest}
      >
        {children}
      </div>
    )
  );
};

export default Rearrangable;
