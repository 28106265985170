import { useEffect } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import { request } from "client/utils";

export const LocationChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    request("usagestats/search", {
      method: "POST",
      body: JSON.stringify({ path: location.pathname }),
    });
  }, [location.search]);

  useEffect(() => {
    request("usagestats/path", {
      method: "POST",
      body: JSON.stringify({ path: location.pathname }),
    });
  }, [location.pathname]);

  return null;
};
