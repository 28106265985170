import { PublicClientApplication } from "@azure/msal-browser";
import { sleep } from "@telia-company/tv.no-play-cms-common/api/util";
import _, { Dictionary } from "lodash";
import React, { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SWRConfig } from "swr";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { MsalProvider, UserProvider } from "client/contexts";
import { request } from "client/utils";
import Authenticated from "components/Authenticated";
import ReindexingNotice from "components/ReindexingNotice";
import Nav from "components/toolbar/Nav";
import EnvironmentToast from "./EnvironmentToast";
import { LocationChangeTracker } from "./LocationChangeTracker";
import { Channels, Genres, Kinds, Providers } from "./types";

export const ChannelsContext = React.createContext<Channels>([]);
export const KindsContext = React.createContext<Kinds>([]);
export const GenresContext = React.createContext<Genres>([]);
export const ProvidersContext = React.createContext<Providers>([]);

export { UserContext } from "client/contexts";

interface WithChannelsProps {
  channels: Channels;
  channelMap: Dictionary<string>;
  children?: React.ReactNode;
}

export const withChannels =
  (Component: React.ComponentType<WithChannelsProps>) =>
  ({ children, ...props }: WithChannelsProps) => (
    <ChannelsContext.Consumer>
      {(channels) => {
        const channelMap = _.fromPairs(channels.map((c) => [c.id, c.value]));
        const rest = _.omit(props, ["channel", "channelMap"]);
        return (
          <Component channels={channels} channelMap={channelMap} {...rest}>
            {children}
          </Component>
        );
      }}
    </ChannelsContext.Consumer>
  );

interface Props {
  pca: PublicClientApplication;
  children: React.ReactNode;
}

async function initApiContext<T>(
  endpoint: string,
  onSuccess: (value: T) => void
) {
  try {
    const result = await request(endpoint);
    console.debug(`Requesting ${endpoint}`);
    onSuccess(result);
    const refreshIn = 60 * 60 + 10 * Math.random();
    setTimeout(() => initApiContext(endpoint, onSuccess), refreshIn * 1000);
  } catch (error) {
    // most likely we weren't logged in, so just retry in a little while
    console.error(`Failed to load ${endpoint}: ${error}`);
    await sleep(30);
    initApiContext(endpoint, onSuccess);
  }
}

const App = ({ pca, children }: Props) => {
  const [kinds, setKinds] = useState<Kinds>([]);
  const [genres, setGenres] = useState<Genres>([]);
  const [providers, setProviders] = useState<Providers>([]);
  const [channels, setChannels] = useState<Channels>([]);

  const initContexts = useCallback(async () => {
    return Promise.all([
      initApiContext("kinds", setKinds),
      initApiContext("genres", setGenres),
      initApiContext("providers", setProviders),
      initApiContext("channels", setChannels),
    ]);
  }, [initApiContext, setKinds, setGenres, setProviders, setChannels]);

  return (
    <>
      <EnvironmentToast />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LocationChangeTracker />
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <MsalProvider instance={pca}>
          <UserProvider onLogin={initContexts}>
            <SWRConfig value={{ fetcher: request }}>
              <ChannelsContext.Provider value={channels}>
                <KindsContext.Provider value={kinds}>
                  <GenresContext.Provider value={genres}>
                    <ProvidersContext.Provider value={providers}>
                      <DndProvider backend={HTML5Backend}>
                        <ReindexingNotice />
                        <Nav />
                        <Authenticated>{children}</Authenticated>
                      </DndProvider>
                    </ProvidersContext.Provider>
                  </GenresContext.Provider>
                </KindsContext.Provider>
              </ChannelsContext.Provider>
            </SWRConfig>
          </UserProvider>
        </MsalProvider>
      </QueryParamProvider>
    </>
  );
};

export default App;
