import React, { useCallback } from "react";
import { Collapse, Form } from "react-bootstrap";
import { ContentCardWrapper } from "components/cards";
import { MediaCollectionCardContentType, MediaCollectionItem } from "./types";

interface Props {
  id: number;
  contentType: MediaCollectionCardContentType;
  onChange: (
    id: number,
    contentType: MediaCollectionCardContentType,
    fields: Omit<MediaCollectionItem, "id">
  ) => void;
  onClick: (id: number, isAsset: boolean, mcId: number) => void;
  readOnly: boolean;
  editContentMetadata: boolean;
  editorialTitle?: string;
  editorialStoryline?: string;
}

export function AnnotatedContentCard({
  id,
  contentType,
  onChange,
  onClick,
  readOnly,
  editContentMetadata,
  editorialTitle = "",
  editorialStoryline = "",
}: Props) {
  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onChange(id, contentType, { title: e.target.value }),
    [id, contentType, onChange]
  );
  const onChangeStoryline = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onChange(id, contentType, { storyline: e.target.value }),
    [id, contentType, onChange]
  );
  return (
    <div>
      <ContentCardWrapper
        id={id}
        isAsset={contentType === "asset"}
        onClick={onClick}
        canClick={!readOnly}
        zoomOnHover={false}
        showLink
      />
      <Collapse in={editContentMetadata}>
        <div>
          <Form.Control
            type="text"
            className="mt-1"
            placeholder="Editorial title (optional)"
            value={editorialTitle}
            onChange={onChangeTitle}
          />
          <Form.Control
            as="textarea"
            className="mt-1"
            placeholder="Editorial storyline (optional)"
            value={editorialStoryline}
            onChange={onChangeStoryline}
          />
        </div>
      </Collapse>
    </div>
  );
}
