import { PropTypes } from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FormLabel, FormControl, FormGroup } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { request, CmsPure } from "client/utils";

class AssetOperationTrigger extends CmsPure {
  constructor(props) {
    super(props);

    this.operations = [
      { value: "ingest", label: "Trigger a reingest" },
      { value: "transcode", label: "Trigger a transcode" },
    ];

    this.state = {
      selectedOption: null,
    };
  }

  onSubmit = () => {
    this.props.onSubmit();
    this.triggerOperation(this.props.assetid, this.state.selectedOption.value);
  };

  triggerOperation = async (assetId, operationType) => {
    await request("triggers/asset/operation", {
      method: "POST",
      body: JSON.stringify({ assetId, operationType }),
    }).then(
      (response) => {
        console.log(response);
        this.setState({ selectedOption: null });
      },
      (error) => toast.error(`Failed to trigger operation: ${error}`)
    );
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    return (
      <Modal
        animation={false}
        autoFocus
        show={this.props.show}
        onHide={this.props.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Trigger an asset operation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup variant={!this.props.assetid ? "error" : null}>
            <FormLabel>Asset ID</FormLabel>
            <FormControl
              type="text"
              placeholder={"Asset id... "}
              style={{ maxWidth: "485px" }}
              value={this.props.assetid}
              disabled
            />
          </FormGroup>
          <FormGroup variant={!this.state.selectedOption ? "error" : null}>
            <FormLabel>Operation Type</FormLabel>
            <Select
              autoFocus
              placeholder="Select operation..."
              options={this.operations}
              value={this.state.selectedOption}
              onChange={this.handleChange}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={this.onSubmit}
            disabled={this.props.assetid === "" || !this.state.selectedOption}
          >
            Trigger
          </Button>
          <div className="divider" />
          <Button variant="default" onClick={this.props.onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AssetOperationTrigger.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default AssetOperationTrigger;
