import React from "react";
import { useChannels as usePlayDbChannels } from "client/hooks";
import { useMetadataChannels } from "../hooks";
import { DatabaseName } from "../types";

interface Props {
  id: number | string;
  database?: DatabaseName;
  as?: React.ElementType;
}

export function ChannelName({ id, database = "METADB", as }: Props) {
  const metadataChannels = useMetadataChannels();
  const playDbChannels = usePlayDbChannels();
  const channelMap =
    database === "METADB"
      ? metadataChannels.channelNameMap
      : playDbChannels.channelMap;
  const channelName = channelMap[id];
  const Component: React.ElementType = as ? as : "span";

  if (channelName) {
    return <Component>{channelName}</Component>;
  } else if (metadataChannels.isLoading) {
    return <Component>...</Component>;
  } else if (metadataChannels.error) {
    return <Component className="text-danger">N/A</Component>;
  } else {
    return <Component className="text-warning">Unknown (id {id})</Component>;
  }
}
