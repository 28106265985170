import { Availability } from "@telia-company/tv.no-play-cms-common/collection/types";
import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { assertUnreachable } from "client/utils/assertUnreachable";

interface Props {
  selectedAvailabilities: Availability[];
  setNewAvailabilities: (availabilities: Availability[]) => void;
  readOnly: boolean;
}

interface AvailabilityOption {
  value: Availability;
  label: string;
}

export const AvailabilityPicker = ({
  selectedAvailabilities,
  setNewAvailabilities,
  readOnly,
}: Props) => {
  const selected = selectedAvailabilities.map((selected) => ({
    value: selected,
    label: getAvailabilityDisplayString(selected),
  }));
  const onChange = (options: AvailabilityOption[]) =>
    setNewAvailabilities(options.map((option) => option.value));

  return (
    <Form className="mb-3">
      <Form.Label htmlFor="availability-picker">
        Filter by availability
      </Form.Label>
      <Typeahead
        id="availability-picker"
        multiple={true}
        options={availabilityOptions}
        selected={selected}
        placeholder="Availability filter"
        onChange={onChange}
        labelKey={(option: AvailabilityOption) => option.label}
        disabled={readOnly}
      />
    </Form>
  );
};

const getAvailabilityDisplayString = (availability: Availability): string => {
  switch (availability) {
    case "catchUp":
      return "Catchup";
    case "subscription":
      return "Subscription";
    case "linear":
      return "Linear";
    case "rent":
      return "Rent";
    case "buy":
      return "Buy";
    case "external":
      return "External";
  }
  return assertUnreachable(availability);
};

const availabilities: Availability[] = [
  "catchUp",
  "subscription",
  "linear",
  "rent",
  "buy",
  "external",
];

const availabilityOptions: AvailabilityOption[] = availabilities.map(
  (availability: Availability) => ({
    value: availability,
    label: getAvailabilityDisplayString(availability),
  })
);
