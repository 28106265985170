import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { getGlyphClassName, GLYPHS } from "./glyphs";

interface Props extends ButtonProps {
  glyph?: keyof typeof GLYPHS | Lowercase<keyof typeof GLYPHS>;
  to?: string;
  children?: React.ReactNode;
  iconLast?: boolean;
  disabled?: boolean;
  showLoadingAnimation?: boolean;
}

export function GlyphButton({
  glyph,
  to,
  iconLast,
  disabled,
  showLoadingAnimation = false,
  children,
  ...rest
}: Props) {
  const className = getGlyphClassName(glyph);
  const marginAttr = iconLast ? "marginLeft" : "marginRight";
  const icon =
    disabled && showLoadingAnimation ? (
      <Spinner as="span" size="sm" className="me-2" />
    ) : className ? (
      <span
        className={className}
        style={children ? { [marginAttr]: "0.5rem" } : undefined}
      />
    ) : undefined;

  const button = (
    <Button disabled={disabled} {...rest}>
      {iconLast ? (
        <>
          {children}
          {icon}
        </>
      ) : (
        <>
          {icon}
          {children}
        </>
      )}
    </Button>
  );

  if (to) {
    return <Link to={to}>{button}</Link>;
  }

  return button;
}

export default GlyphButton;
