import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { AmsConfigTab } from "./AmsConfigTab";
import { AssetIndexingTab } from "./AssetIndexingTab";

export default function Configurations() {
  const { activeTab, setActiveTab } = useTabs();

  return (
    <Tabs id="ams-config-tabs" activeKey={activeTab} onSelect={setActiveTab}>
      <Tab eventKey="ams" title="AMS backend options">
        <AmsConfigTab />
      </Tab>
      <Tab eventKey="asset-indexing" title="Asset indexing status">
        <AssetIndexingTab />
      </Tab>
    </Tabs>
  );
}

function useTabs() {
  const navigate = useNavigate();
  const { tab: activeTab } = useParams();
  const setActiveTab = (tab: string | null) => {
    let path;
    if (tab) {
      path = `/ams/configurations/${tab}`;
    } else {
      path = "/ams/configurations";
    }
    navigate(path);
  };

  return { activeTab, setActiveTab };
}
