import React from "react";
import { useSortBy, useTable } from "react-table-v7";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { ChannelsContext, ProvidersContext } from "client/App";
import { useUser } from "client/hooks";
import { useContentEdits } from "client/hooks/useContentEdits";
import { useReactTableSort } from "client/hooks/useQueryParams/extras";
import { LoadingOverlayWrapper } from "client/utils";
import { ControlledReactTable, ReactTablePagination } from "components/tables";
import SearchBar from "./SearchBar";
import { getColumns } from "./Utils";

const INITIAL_SORT = [{ id: "editorialUpdated", desc: true }];

export function EditList() {
  const providers = React.useContext(ProvidersContext);
  const channels = React.useContext(ChannelsContext);

  const columns = React.useMemo(
    () => getColumns(providers, channels),
    [providers, channels]
  );
  const user = useUser();
  const [query, setQuery] = useQueryParams({
    editor: withDefault(StringParam, user.username),
    status: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 20),
    activeOnly: withDefault(BooleanParam, false),
  });
  const [sort, setSort] = useReactTableSort(INITIAL_SORT);
  const { data, totalHits, isLoading } = useContentEdits(query, sort);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: data,
      initialState: { sortBy: INITIAL_SORT },
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetFilters: false,
      disableMultiSort: true,
    },
    useSortBy
  );

  React.useEffect(() => {
    setSort(sortBy);
  }, [sortBy]);

  return (
    <LoadingOverlayWrapper show={isLoading}>
      <SearchBar params={query} setParams={setQuery} />
      <ControlledReactTable
        className="mt-3"
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        rows={rows}
        placeholderProps={{ colSpan: columns.length }}
      />
      <ReactTablePagination
        pageIndex={query.page}
        pageSize={query.limit}
        gotoPage={(page: number) => setQuery({ page })}
        setPageSize={(limit: number) => setQuery({ limit })}
        totalPages={Math.ceil(totalHits / query.limit)}
      />
    </LoadingOverlayWrapper>
  );
}

export default EditList;
