import JsZip from "jszip";
import { KeyboardEvent } from "react";

export const onEnterPress = (
  e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  statusCheck: string,
  successCallback: { (): void; (): void }
) => {
  if (e.key === "Enter" && e.shiftKey == false && !!statusCheck) {
    e.preventDefault();
    successCallback();
  }
};

export const JSONText = (object: unknown) => {
  if (!object) return "";
  return JSON.stringify(object, null, 4) || "";
};

export const clickOnDownloadLink = (
  object: Blob | MediaSource,
  fileName?: string
) => {
  const a = document.createElement("a");
  const objectUrl = URL.createObjectURL(object);
  a.href = objectUrl;
  a.download = fileName || "file";
  document.body.appendChild(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(objectUrl);
};

export const createZip = async (files: { blob: Blob; filename: string }[]) => {
  const zip = JsZip();
  files.forEach((file) => {
    zip.file(file.filename, file.blob);
  });
  return await zip.generateAsync({ type: "blob" });
};

export const downloadFromUrls = async (urls: string[]): Promise<Blob[]> => {
  return Promise.all(urls.map((url) => download(url))).then((blobs) => {
    return blobs;
  });
};
const download = async (url: RequestInfo) => {
  return await (await fetch(url)).blob();
};
