export function getEmptyPromo() {
  return {
    activeContent: true,
    enabled: true,
    title: "",
    linkPromoTo: "content",
    content: {},
    promotionType: "cover",
    pageSection: "home",
    overlayAlignment: "left",
    overlayColor: "dark",
  };
}

// validation
export function hasValidTopPromoLayout(item) {
  if (!item.topBannerLarge) return false;
  return true;
}
export function hasValidLinkPromoTo(item) {
  if (!item.linkPromoTo || !item[item.linkPromoTo]) return false;
  if (typeof item[item.linkPromoTo] === "object" && !item[item.linkPromoTo].id)
    return false;
  return true;
}
export function isValidPromotion(item) {
  if (!item || item.title === "") return false;
  if (!hasValidLinkPromoTo(item)) return false;

  if (item.promotionType === "cover" && !item.cover) return false;
  if (item.promotionType === "topBanner" && !hasValidTopPromoLayout(item)) {
    return false;
  }

  return true;
}

/**
 * @param {function} onChange callback, eg setState
 * @returns {{ [key]: function }} an object with key function pairs
 */
export function getPromotionChangeHandlers(onChange) {
  return {
    raw: (obj) => onChange(obj), // access the change handler directly

    enabled: (enabled) => onChange({ enabled }),
    title: (event) => onChange({ title: event.target.value }),

    promotionType: (promotionType) => onChange({ promotionType }),

    linkPromoTo: (linkPromoTo) => onChange({ linkPromoTo }),
    collection: (collection) => onChange({ collection }),
    content: (id, isAsset) => onChange({ content: { id, isAsset } }),
    // content is also handled by the asset dialog which calls onPromoContentChange
    channel: (channel) => onChange({ channel }),
    provider: (provider) => onChange({ provider }),
    pageSection: (pageSection) => onChange({ pageSection }),

    // promotion graphics
    topBannerLarge: (topBannerLarge) => onChange({ topBannerLarge }),
    topBannerMedium: (topBannerMedium) => onChange({ topBannerMedium }),
    topBannerSmall: (topBannerSmall) => onChange({ topBannerSmall }),
    overlayAlignment: (overlayAlignment) => onChange({ overlayAlignment }),
    overlayColor: (overlayColor) => onChange({ overlayColor }),

    cover: (cover) => onChange({ cover }),
  };
}
