import moment from "moment/moment";
import { StringParam, useQueryParam } from "use-query-params";

const dateFormat = "YYYY-MM-DD";

export const useStartAndEndDates = () => {
  const [startDateParam, setStartDateParam] = useQueryParam(
    "start",
    StringParam
  );
  const [endDateParam, setEndDateParam] = useQueryParam("end", StringParam);

  const startDate = startDateParam ? startDateParam : getStartFallback();
  const endDate = endDateParam ? endDateParam : getEndFallback();

  const setStartDate = (newDate: string) => {
    setStartDateParam(newDate);
    if (moment(endDate).isBefore(moment(newDate))) {
      setEndDateParam(newDate);
    } else if (!endDateParam) {
      setEndDateParam(endDate);
    }
  };

  const setEndDate = (newDate: string) => {
    setEndDateParam(newDate);
    if (moment(startDate).isAfter(moment(newDate))) {
      setStartDateParam(newDate);
    } else if (!startDateParam) {
      setStartDateParam(startDate);
    }
  };

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
};

const getStartFallback = () => moment().format(dateFormat);

const getEndFallback = getStartFallback;
