import React from "react";
import { Card } from "react-bootstrap";
import IMDbTitleCard from "./IMDbTitleCard";
import MatchCardActions from "./MatchCardActions";

const IgnoredTitleCard = ({ match, dispatch }) => {
  return (
    <IMDbTitleCard imdb={match} missingText="Not on IMDb">
      <Card.Text>
        Somebody has indicated that this content is not available on IMDb. If
        this is wrong and the content <strong>is</strong> indeed available, you
        can enter an IMDb ID here or pick the correct match among the suggested
        titles below.
      </Card.Text>
      <MatchCardActions match={match} dispatch={dispatch} />
    </IMDbTitleCard>
  );
};

export default IgnoredTitleCard;
