import React from "react";
import { Form } from "react-bootstrap";
import { BooleanParam, useQueryParam } from "use-query-params";

export const ShowDebugColumnsToggle = () => {
  const [showDebugColumns, setShowDebugColumns] = useQueryParam(
    "debug",
    BooleanParam
  );

  return (
    <>
      <Form.Label htmlFor="show-debug-columns">Show debug columns</Form.Label>
      <Form.Check
        className="ms-2"
        type="switch"
        id="show-debug-columns"
        checked={showDebugColumns || false}
        onChange={(e) => setShowDebugColumns(e.target.checked)}
      />
    </>
  );
};
