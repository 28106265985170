import { extractTitle } from "@telia-company/tv.no-play-cms-common/api/MediaContentUtils";
import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import useSWR from "swr";
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { ChannelsContext } from "client/App";
import { useDebouncedValue } from "client/hooks";
import { useKeptResult } from "client/hooks/useKeptResult";
import { IChannels } from "client/types";
import { MomentParam } from "client/types/MomentParam";
import { EnrichmentListCountResponse } from "client/views/Enrich/MediaContent/EnrichmentList/types";
import ResultList from "./ResultList";
import SearchFilter from "./SearchFilter";
import { ContentWarningRow, EnrichmentListResponse } from "./types";

const buildSelectOption = (object: IChannels) => ({
  value: Number(object.id),
  label: object.value,
});

export const EnrichmentList = () => {
  const searchParams = useURLSearchParams();

  const { data: rawData, mutate: mutateData } = useSWR<EnrichmentListResponse>(
    `enrichment?${searchParams}`,
    null,
    {
      dedupingInterval: 10000,
    }
  );

  // These are not used for the count endpoint
  ["page", "pageSize"].forEach((name) => searchParams.delete(name));

  const { data: rawCount, mutate: mutateCount } =
    useSWR<EnrichmentListCountResponse>(
      `enrichment/count?${searchParams}`,
      null,
      {
        dedupingInterval: 60000,
      }
    );

  const mutate = () => {
    mutateData();
    mutateCount();
  };

  const data = useKeptResult(rawData);

  const isLoading = data !== rawData;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const warnings = prepareWarnings(data);
  const totalWarnings = rawCount?.totalWarnings;
  return (
    <ChannelsContext.Consumer>
      {(channels) => {
        const channelOptions = channels.map(buildSelectOption);
        return (
          <>
            <SearchFilter />
            <TotalWarnings totalWarnings={totalWarnings} />
            <ResultList
              data={warnings}
              totalWarnings={totalWarnings}
              channelOptions={channelOptions}
              mutate={mutate}
              loading={isLoading}
            />
          </>
        );
      }}
    </ChannelsContext.Consumer>
  );
};

const useURLSearchParams = () => {
  const [params] = useQueryParams({
    pageSize: NumberParam,
    page: NumberParam,
    channels: NumericArrayParam,
    excludedChannels: NumericArrayParam,
    limit: NumberParam,
    fromDate: MomentParam,
    toDate: MomentParam,
    catchupEndFrom: MomentParam,
    catchupEndTo: MomentParam,
    type: StringParam,
    title: StringParam,
    warnings: ArrayParam,
    kind: ArrayParam,
    showMarkedOnly: BooleanParam,
    showReported: BooleanParam,
    showAccepted: BooleanParam,
    showSpam: BooleanParam,
  });
  const debouncedTitle = useDebouncedValue(params.title);
  const searchParams = new URLSearchParams();
  searchParams.append("page", params.page?.toString() || "0");
  searchParams.append("pageSize", params.pageSize?.toString() || "50");
  if (params.channels) {
    for (const channel of params.channels) {
      if (channel) {
        searchParams.append("channelId", channel.toString());
      }
    }
  }
  if (params.excludedChannels) {
    for (const excludedChannel of params.excludedChannels) {
      if (excludedChannel) {
        searchParams.append("excludedChannelId", excludedChannel.toString());
      }
    }
  }
  if (!params.type || params.type === "all" || params.type === "upcoming") {
    if (params.fromDate) {
      searchParams.append("fromDate", params.fromDate.toISOString());
    }
    if (params.toDate) {
      searchParams.append("toDate", params.toDate.toISOString());
    }
  }
  if (params.type === "catchup") {
    if (params.catchupEndFrom) {
      searchParams.append(
        "catchupEndFrom",
        params.catchupEndFrom.toISOString()
      );
    }
    if (params.catchupEndTo) {
      searchParams.append("catchupEndTo", params.catchupEndTo.toISOString());
    }
  }
  if (params.type) {
    searchParams.append("type", params.type);
  }
  if (debouncedTitle) {
    searchParams.append("title", debouncedTitle);
  }
  if (params.warnings) {
    for (const warning of params.warnings) {
      if (warning) {
        searchParams.append("warnings", warning);
      }
    }
  }
  if (params.kind) {
    for (const kind of params.kind) {
      if (kind) {
        searchParams.append("kind", kind);
      }
    }
  }
  if (params.showMarkedOnly) {
    searchParams.append("showMarkedOnly", "true");
  }
  if (params.showReported) {
    searchParams.append("showReported", "true");
  }
  if (params.showAccepted) {
    searchParams.append("showAccepted", "true");
  }
  if (params.showSpam) {
    searchParams.append("showSpam", "true");
  }
  return searchParams;
};

const prepareWarnings = (rawData?: EnrichmentListResponse) => {
  if (!rawData) {
    return [];
  }
  return rawData.warnings.map((row): ContentWarningRow => {
    try {
      const title = extractTitle(row.mediaContentData);
      const topParentTitle = extractTitle(row.topMediaContentData);
      return {
        id: row.enrichmentWarning.id,
        mediacontent_id: row.enrichmentWarning.mediacontent_id,
        topParentId: row.enrichmentWarning.top_mediacontent_id,
        asset_id: row.enrichmentWarning.asset_id,
        crid: row.enrichmentWarning.crid,
        type: row.enrichmentWarning.type,
        data: row.mediaContentData,
        title,
        topParentTitle,
        topParent: row.topMediaContentData,
        titleField: {
          urlParam: row.enrichmentWarning.mediacontent_id,
          label: title,
        },
        topParentTitleField: {
          urlParam: row.enrichmentWarning.top_mediacontent_id,
          label: topParentTitle,
        },
        description: row.enrichmentWarning.data.description,
        broadcast: row.enrichmentWarning.broadcast,
        catchupEnd: row.enrichmentWarning.catchup_end,
        channel: row.enrichmentWarning.channel,
        updated: row.enrichmentWarning.updated,
        CWSAStatus: row.enrichmentWarning.CWSAStatus || undefined,
        CWSMCStatus: row.enrichmentWarning.CWSMCStatus || undefined,
        className:
          row.enrichmentWarning.CWSAStatus || row.enrichmentWarning.CWSMCStatus
            ? "disabled-row"
            : undefined,
      };
    } catch (error) {
      console.error(error);
      console.error(row);
      throw error;
    }
  });
};

interface TotalWarningsProps {
  totalWarnings?: number;
}

const TotalWarnings = ({ totalWarnings }: TotalWarningsProps) => {
  return (
    <div>
      Total warnings:{" "}
      {totalWarnings !== undefined
        ? totalWarnings.toLocaleString("nb-NO")
        : "Loading..."}
    </div>
  );
};
