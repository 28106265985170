import { DateTime } from "luxon";
import React from "react";
import { Column } from "react-table-v7";
import { StringParam, useQueryParam } from "use-query-params";
import Table from "components/tables";
import { TableRow } from "../types";

const columns: Column<TableRow>[] = [
  {
    Header: "Start",
    accessor: (row) => {
      if (!row.oneApp) {
        return null;
      }
      return DateTime.fromISO(row.oneApp.startTime.isoString).toFormat("HH:mm");
    },
  },
  {
    Header: "End",
    accessor: (row) => {
      if (!row.oneApp) {
        return null;
      }
      return DateTime.fromISO(row.oneApp.endTime.isoString).toFormat("HH:mm");
    },
  },
  {
    Header: "Title",
    accessor: (row) => {
      if (!row.oneApp) {
        return <div>&nbsp;</div>;
      }
      return row.oneApp.title;
    },
  },
];

interface Props {
  combinedPrograms?: TableRow[];
}

export const ProgramList = ({ combinedPrograms }: Props) => {
  const [oneappChannel] = useQueryParam("oneappChannel", StringParam);
  if (!oneappChannel) {
    return null;
  }
  if (!combinedPrograms) {
    return <div>Loading...</div>;
  }
  return <Table columns={columns} data={combinedPrograms} />;
};
