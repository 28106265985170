import React, { ReactNode } from "react";
import { Alert } from "react-bootstrap";
import { LoadingOverlayWrapper } from "client/utils";
import SlackLink from "components/SlackLink";

interface Props {
  isLoading: boolean;
  error: unknown;
  children: ReactNode;
  alwaysShowChildren?: boolean;
}

const StatusBoundary = ({
  isLoading,
  error,
  children,
  alwaysShowChildren = false,
  ...rest
}: Props) => {
  return (
    <LoadingOverlayWrapper show={isLoading} {...rest}>
      {error && (
        <Alert variant="danger" className="m-4">
          <Alert.Heading>Something went wrong</Alert.Heading>
          <p>
            Please contact us in <SlackLink /> if the problem persists
          </p>
        </Alert>
      )}
      {(alwaysShowChildren || !error) && children}
    </LoadingOverlayWrapper>
  );
};

export default StatusBoundary;
