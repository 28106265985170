import classNames from "classnames";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Alert, Collapse, RowProps } from "react-bootstrap";
import { useKeptResult } from "client/hooks/useKeptResult";
import { parseDateToDateString } from "client/utils/TableCommon";
import KeyValuePair from "components/KeyValuePair";
import { Truncate } from "components/Truncate";
import { KindBadge } from "components/badges";
import { useProgram } from "../hooks";
import { DatabaseName, ProductionDate } from "../types";
import { getTitle, PRIORITIZED_LOCALES } from "../utils";
import { DatabaseBadge } from "./DatabaseBadge";

function padIndex(index: number | undefined, prefix: string) {
  if (index !== undefined) {
    return prefix + _.padStart(index.toString(), 2, "0");
  } else {
    return "";
  }
}

function getEpisodeLabel(index?: number, seasonIndex?: number) {
  if (index || seasonIndex) {
    const paddedIndex = padIndex(index, "E");
    const paddedSeasonIndex = padIndex(seasonIndex, "S");
    return `${paddedSeasonIndex}${paddedIndex}`;
  }

  return "Unknown episode";
}

interface MetadataFieldProps extends RowProps {
  label: string;
  value: React.ReactNode;
}

function MetadataField({ label, value, ...rest }: MetadataFieldProps) {
  return <KeyValuePair id={label} value={value} labelWidth={3} {...rest} />;
}

function formatProductionDate(productionDate?: ProductionDate) {
  if (productionDate) {
    const { year, month, day } = productionDate;
    const date = new Date(year, month - 1, day);
    return parseDateToDateString(date);
  }

  return <span className="text-danger">N/A</span>;
}

interface TitlesFieldProps {
  titles: Record<string, string>;
}

function TitlesField({ titles }: TitlesFieldProps) {
  const [showAllTitles, setShowAllTitles] = useState(false);
  const toggleAllTitles = useCallback(
    () => setShowAllTitles((showAll) => !showAll),
    [setShowAllTitles]
  );
  const sortedLocales = _.keys(titles).sort((a, b) => {
    const aPrioritized = PRIORITIZED_LOCALES.includes(a);
    const bPrioritized = PRIORITIZED_LOCALES.includes(b);

    if (aPrioritized && bPrioritized) {
      return PRIORITIZED_LOCALES.indexOf(a) - PRIORITIZED_LOCALES.indexOf(b);
    } else if (aPrioritized) {
      return -1;
    } else if (bPrioritized) {
      return 1;
    } else if (a < b) {
      return -1;
    } else if (b < a) {
      return 1;
    }

    return 0;
  });

  const [firstLocale, ...hiddenLocales] = sortedLocales;
  if (sortedLocales.length > 1) {
    return (
      <>
        <MetadataField
          label={`Title (${firstLocale})`}
          value={titles[firstLocale]}
          onClick={toggleAllTitles}
          className={classNames(
            "cursor-pointer text-primary",
            showAllTitles ? "mb-0" : "mb-2"
          )}
        />
        <Collapse in={showAllTitles}>
          <div>
            {hiddenLocales.map((locale) => (
              <MetadataField
                key={locale}
                label={locale}
                value={titles[locale]}
                className="my-0"
              />
            ))}
          </div>
        </Collapse>
      </>
    );
  }

  return (
    <MetadataField
      label={`Title (${firstLocale})`}
      value={titles[firstLocale]}
    />
  );
}

interface Props {
  id: string;
  database: DatabaseName;
}

export function ProgramDetails({ id, database }: Props) {
  const { program, error } = useProgram(id, database);

  const keptProgram = useKeptResult(program);

  if (error) {
    return <Alert variant="danger">Could not load program ID {id}</Alert>;
  }

  if (!keptProgram) {
    return <div>Loading...</div>;
  }

  const { data } = keptProgram;
  const title = getTitle(data);
  const episodeLabel = getEpisodeLabel(data.index, data.seasonIndex);

  return (
    <>
      <h3 className="lead mt-0 d-flex border-bottom justify-content-between">
        <Truncate>{title}</Truncate>
        {data.kind === "episode" && (
          <Truncate className="text-muted ms-1 me-auto">
            ({data.topTitle ? `${data.topTitle} ` : ""}
            {episodeLabel || "(Unknown episode)"})
          </Truncate>
        )}
        <DatabaseBadge db={database} className="d-block ms-2" />
      </h3>
      <MetadataField
        label={database === "METADB" ? "TMS id" : "MC id"}
        value={id}
      />
      <MetadataField label="Kind" value={<KindBadge kind={data.kind} />} />
      <MetadataField label="CRID" value={data.crid} />
      <MetadataField label="Top title" value={data.topTitle} />
      <TitlesField titles={data.titles} />

      <MetadataField
        label="Production date"
        value={formatProductionDate(data.productionDate)}
      />
      <MetadataField label="IMDb ID" value={data.imdbId} />
    </>
  );
}
