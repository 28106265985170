import { PropTypes } from "prop-types";
import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const TooltipWrapper = ({ value, placement, children }) => {
  if (!value) return children;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={({ show, ...props }) => {
        // show is a boolean passed to the DOM,
        // avoid the warning by parsing to a string
        return (
          <Tooltip show={show.toString()} {...props}>
            {value}
          </Tooltip>
        );
      }}
    >
      {children}
    </OverlayTrigger>
  );
};
TooltipWrapper.propTypes = {
  value: PropTypes.node,
  placement: PropTypes.string,
  // children must be a single react object
  children: PropTypes.object,
};

export default TooltipWrapper;
