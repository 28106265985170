export function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "teams.loaded": {
      const teams = payload;
      const teamIds = teams.map((t) => t.id);
      let { selectedTeam } = state;

      // Reset the selected team if it's no longer in the new list of
      // teams after a filter update
      if (!teamIds.includes(selectedTeam.id)) {
        selectedTeam = {};
      }

      return {
        ...state,
        needRefresh: false,
        teams,
        selectedTeam,
      };
    }
    case "team.selected":
      return { ...state, selectedTeam: { ...payload, ready: true } };
    case "team.reloaded":
      return {
        ...state,
        needRefresh: true,
        selectedTeam: { ...payload, ready: true },
      };
    case "team.reset":
      return {
        ...state,
        selectedTeam: {},
      };
    case "filter.updated": {
      let { selectedTeam } = state;

      if (payload.seasonId && state.filter.seasonId !== payload.seasonId) {
        selectedTeam = {};
      }

      return {
        ...state,
        selectedTeam,
        filter: {
          ...state.filter,
          ...payload,
        },
      };
    }
    case "filter.reset":
      return {
        ...state,
        filter: payload,
        selectedTeam: {},
      };
  }
}

const defaultState = {
  teams: [],
  selectedTeam: {},
  needRefresh: false,
  filter: {
    search: "",
  },
  readOnly: true,
};

export const init = (initialState) => ({
  ...defaultState,
  ...initialState,
  filter: {
    ...defaultState.filter,
    ...initialState.filter,
  },
});
