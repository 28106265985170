import { ReportTypes } from "@telia-company/tv.no-play-cms-common/api/ReportType";
import React from "react";
import { useContentReportStatsHistory } from "./useContentReportStatsHistory";

interface Props {
  from: string;
  to: string;
}

export const TableSection = ({ from, to }: Props) => {
  const data = useContentReportStatsHistory(from, to);

  if (!data) {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }

  const tableRows = [];

  for (let i = 0; i < data.NEW_CRID.length; i++) {
    const cells = [
      <td key="date" data-testid="date-cell">
        {data.NEW_CRID[i].date.toFormat("yyyy-MM-dd")}
      </td>,
    ];
    for (const reportType of ReportTypes) {
      const count = data[reportType][i].count;
      cells.push(
        <td key={reportType} style={{ textAlign: "end" }}>
          {count.toLocaleString("NO")}
        </td>
      );
    }
    tableRows.push(<tr key={i}>{cells}</tr>);
  }

  return <>{tableRows}</>;
};
