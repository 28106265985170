import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

const TOOLTIP = `TMS ID / Telia Play Video DB ID / GN CRID<br />
Note that it's only possible to search for GN CRIDs (starting with crid://gn/).
No other CRIDs are supported.<br />
Also note that multiple IDs can be entered by separating them with commas. Example: 1234, crid://gn/SH5678
`;

export const IdPicker = () => {
  const [id, setId] = useQueryParam("id", StringParam);
  return (
    <>
      <Form.Label htmlFor="id-picker" data-tip={TOOLTIP}>
        TMS ID / TP ID / GN CRID
      </Form.Label>
      <Form.Control
        type="text"
        id="id-picker"
        value={id || ""}
        onChange={(e) => {
          const newValue = e.target.value;
          setId(newValue.length > 0 ? newValue : null);
        }}
      />
    </>
  );
};
