import { Sorting } from "@telia-company/tv.no-play-cms-common/collection/types";
import React from "react";
import { Form } from "react-bootstrap";

interface SortingOption {
  name: string;
  value: Sorting;
}

interface Props {
  selected: Sorting;
  onChange: (newValue: Sorting) => void;
  readOnly: boolean;
}

export const SortingPicker = ({ selected, onChange, readOnly }: Props) => {
  const options: SortingOption[] = [
    { name: "First added", value: "added-asc" },
    { name: "Last added", value: "added-desc" },
    { name: "Alphabetical", value: "title-asc" },
    { name: "Reverse alphabetical (Å-A)", value: "title-desc" },
    { name: "Lowest IMDb rating", value: "imdb-rating-asc" },
    { name: "Highest IMDb rating", value: "imdb-rating-desc" },
    { name: "Least popular", value: "popularity-asc" },
    { name: "Most popular", value: "popularity-desc" },
    {
      name: "Production year, oldest first",
      value: "productionDate-asc",
    },
    {
      name: "Production year, newest first",
      value: "productionDate-desc",
    },
    { name: "Least popular (search)", value: "search-popularity-asc" },
    { name: "Most popular (search)", value: "search-popularity-desc" },
  ];
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor="collection-filters-sort-by-selector">
        Sort by
      </Form.Label>
      <Form.Select
        style={{ width: "auto" }}
        id="collection-filters-sort-by-selector"
        value={selected}
        onChange={(event) => onChange(event.target.value as Sorting)}
        disabled={readOnly}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};
