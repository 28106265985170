import PropTypes from "prop-types";
import React from "react";
import { Alert } from "react-bootstrap";
import { Spinner } from "components/Spinner";

const JsonViewer = ({ data }) => (
  <pre>{typeof data === "string" ? data : JSON.stringify(data, null, 2)}</pre>
);

const JsonLoader = ({ path, fetchData, Viewer, ...viewerProps }) => {
  const [data, setData] = React.useState();
  const [error, setError] = React.useState();
  const [fetching, setFetching] = React.useState();

  React.useEffect(() => {
    if (!path) {
      return;
    }

    setFetching(true);

    const callback = (data) => {
      setError(undefined);
      console.log({ data });
      setData(data);
      setFetching(false);
    };
    const errorCallback = () => {
      setData(undefined);
      setError("The data could not be loaded");
      setFetching(false);
    };

    const abortController = new AbortController();
    const args = { path, signal: abortController.signal };
    fetchData(args, callback, errorCallback);

    return () => abortController.abort();
  }, [path]);

  if (fetching) {
    return <Spinner className="m-auto" />;
  } else if (error) {
    return <Alert variant="danger">{error}</Alert>;
  } else if (data) {
    return <Viewer data={data} {...viewerProps} />;
  }

  return null;
};

JsonLoader.propTypes = {
  path: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  Viewer: PropTypes.elementType,
};

JsonLoader.defaultProps = {
  path: undefined,
  Viewer: JsonViewer,
};

export default JsonLoader;
