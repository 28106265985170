import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

const UPCOMING_OPTIONS: { value: string; label: string }[] = [
  { value: "all", label: "All" },
  { value: "upcoming", label: "Upcoming only" },
];
export const UpcomingFilter = () => {
  const [upcoming, setUpcoming] = useQueryParam("upcoming", StringParam);
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "all") {
      setUpcoming(null);
    } else {
      setUpcoming(e.target.value);
    }
  };
  return (
    <>
      <Form.Label htmlFor="upcoming">Upcoming?</Form.Label>
      <Form.Select id="upcoming" value={upcoming || "all"} onChange={onChange}>
        {UPCOMING_OPTIONS.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
