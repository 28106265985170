import _ from "lodash";
import React from "react";
import Select from "react-select";
import { SelectOption } from "./types";

function MultiSelect<T>({
  value,
  options,
  onChange,
  placeholder = "Please select",
  ...rest
}: {
  value?: T[];
  options: SelectOption<T>[];
  onChange?: (newValue: T[]) => void;
  placeholder?: string;
}) {
  const initialSelection = _.compact(
    options.map((option) =>
      value?.find((v) => _.isEqual(v, option.value)) ? option : null
    )
  );
  const [selection, setSelection] = React.useState<readonly SelectOption<T>[]>(
    initialSelection ? initialSelection : []
  );

  React.useEffect(() => {
    if (onChange) onChange(selection.map((selected) => selected.value));
  }, [selection]);

  return (
    <Select
      isMulti
      value={selection}
      options={options}
      placeholder={placeholder}
      onChange={(selections) => setSelection(selections)}
      {...rest}
    />
  );
}

export default MultiSelect;
