import React from "react";
import { Form } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

interface Option {
  label: string;
  value: string;
}

const TOP_LEVEL_OPTIONS: Option[] = [
  { label: "All", value: "all" },
  { label: "Program", value: "program" },
  { label: "Movie", value: "movie" },
  { label: "Series", value: "series" },
  { label: "Show", value: "show" },
];

const ALL_OPTIONS: Option[] = [
  ...TOP_LEVEL_OPTIONS,
  { label: "Season", value: "season" },
  { label: "Episode", value: "episode" },
];

interface Props {
  includeSubKinds?: boolean;
}

export const KindPicker = ({ includeSubKinds = false }: Props) => {
  const [kind, setKind] = useQueryParam("kind", StringParam);
  const changeKind = (newKind: string) => {
    if (newKind === "all") {
      setKind(null);
    } else {
      setKind(newKind);
    }
  };
  const optionsToShow = includeSubKinds ? ALL_OPTIONS : TOP_LEVEL_OPTIONS;
  return (
    <>
      <Form.Label htmlFor="kind-picker">Kind</Form.Label>
      <Form.Select
        id="kind-picker"
        value={kind || ""}
        onChange={(event) => changeKind(event.target.value)}
      >
        {optionsToShow.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </>
  );
};
