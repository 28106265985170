import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { CmsPure } from "client/utils";
import ToggleControl from "components/controls/ToggleControl";
import { getComposerTitle } from "../provisioningUtils";

const LABEL = "DVB Cable Stream";

class DVBCableStreamComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      symbolRate: 0,
      modulation: "256-QAM",
      frequencyInKHz: 0,
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) return;

    this.props.get(id, (state) => this.setState(state));
  }

  create = (event) => {
    event.preventDefault();
    const payload = this.state;
    this.props.put(payload, this.props.id, LABEL);
  };

  render() {
    const { symbolRate, modulation, frequencyInKHz } = this.state;
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{title}</h1>
        <Row>
          <Col as={Form.Group} xs={4}>
            <Form.Label>Symbol Rate</Form.Label>
            <Form.Control
              type="number"
              value={symbolRate}
              onChange={(e) =>
                this.setState({ symbolRate: Number(e.target.value) })
              }
            />
          </Col>
          <Col as={Form.Group} xs={4}>
            <Form.Label>QAM Modulation</Form.Label>
            <ToggleControl
              allowed={["256-QAM", "128-QAM", "64-QAM"]}
              value={modulation}
              onChange={(modulation) => this.setState({ modulation })}
            />
          </Col>
          <Col as={Form.Group} xs={4}>
            <Form.Label>Frequency In KHz</Form.Label>
            <Form.Control
              type="number"
              value={frequencyInKHz}
              onChange={(e) =>
                this.setState({ frequencyInKHz: Number(e.target.value) })
              }
            />
          </Col>
        </Row>
        <Button className="pull-right mt-4" variant="primary" type="submit">
          {this.props.mode}
        </Button>
      </Form>
    );
  }
}

export default DVBCableStreamComposer;
