import React from "react";
import { FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { ChromePicker } from "react-color";
import { ToggleControlWithLabels } from "components/controls/ToggleControl";
import CloudImageControl from "components/image/CloudImageControl";
import { Collection, CollectionOnChangeHandlers } from "./types";

interface Props {
  collection: Collection;
  onChangeHandlers: CollectionOnChangeHandlers;
  readOnly: boolean;
}

export function CollectionGraphicsComponent({
  collection,
  onChangeHandlers,
  readOnly,
}: Props) {
  return (
    <>
      <Row>
        <Col as={FormGroup} xs={{ offset: 1, span: 5 }}>
          <FormLabel className="lead">Page Header</FormLabel>
          <CloudImageControl
            disabled={readOnly}
            type="collection"
            width={1040}
            height={200}
            allowHigherResolution
            value={collection.pageHeader}
            onChange={onChangeHandlers.pageHeader}
          />
        </Col>
        <Col as={FormGroup} xs={5}>
          <FormLabel className="lead">Page Header (Mobile Only)</FormLabel>
          <CloudImageControl
            disabled={readOnly}
            type="collection"
            width={1280}
            height={600}
            value={collection.pageHeaderMobile}
            onChange={onChangeHandlers.pageHeaderMobile}
          />
        </Col>
        <Col as={FormGroup} xs={{ offset: 1, span: 5 }}>
          <FormLabel className="lead">Collection Card</FormLabel>
          <CloudImageControl
            disabled={readOnly}
            type="collection"
            width={1260}
            height={540}
            value={collection.collectionCard}
            onChange={onChangeHandlers.collectionCard}
          />
        </Col>
        <Col as={FormGroup} xs={5}>
          <FormLabel className="lead">Page Background</FormLabel>
          <CloudImageControl
            disabled={readOnly}
            type="collection"
            width={1920}
            height={1080}
            allowHigherResolution
            value={collection.pageBackground}
            onChange={onChangeHandlers.pageBackground}
          />
        </Col>
      </Row>
      <Row>
        <Col as={FormGroup} xs={{ offset: 1, span: "auto" }}>
          <FormLabel className="lead">Background color</FormLabel>
          <ChromePicker
            disableAlpha
            color={collection.backgroundColor}
            onChange={onChangeHandlers.backgroundColor}
          />
        </Col>
        <Col as={FormGroup}>
          <FormLabel className="lead">Background Gradient</FormLabel>
          <ToggleControlWithLabels
            value={collection.backgroundGradient}
            options={[
              { value: true, label: "Use Gradient" },
              { value: false, label: "No Gradient" },
            ]}
            onChange={onChangeHandlers.backgroundGradient}
          />
        </Col>
      </Row>
    </>
  );
}
