import _ from "lodash";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { CLIENT_CONFIG } from "client/config";
import { stringify } from "client/utils";
import { EditableKeyValuePair } from "components/KeyValuePair";
import { PlayLogo } from "components/PlayLogo";
import { GlyphButton } from "components/buttons";
import { useAsset } from "../hooks";

interface Props {
  id: number;
  disabled: boolean;
  onClose: () => void;
}

export function AssetEditForm({ id, disabled, onClose }: Props) {
  const { asset, updateAsset } = useAsset(id);
  const [providerAssetId, setProviderAssetId] = useState(
    asset?.provider_asset_id
  );
  const [yaafAssetId, setYaafAssetId] = useState(asset?.yaaf_asset_id);

  useEffect(() => {
    if (!providerAssetId || disabled) {
      setProviderAssetId(asset?.provider_asset_id || "");
    }

    if (!yaafAssetId || disabled) {
      setYaafAssetId(asset?.yaaf_asset_id || "");
    }
  }, [asset]);

  const save = useCallback(async () => {
    try {
      await updateAsset({
        provider_asset_id: providerAssetId,
        yaaf_asset_id: yaafAssetId,
      });
      toast.success("Updated asset metadata");
      onClose();
    } catch (e) {
      toast.error(`Could not save asset details: ${stringify(e)}`);
    }
  }, [providerAssetId, yaafAssetId, updateAsset]);

  const cancel = useCallback(() => {
    setProviderAssetId(asset?.provider_asset_id);
    setYaafAssetId(asset?.yaaf_asset_id);
    onClose();
  }, [onClose, asset]);

  const isDirty =
    providerAssetId !== (asset?.provider_asset_id || "") ||
    yaafAssetId !== (asset?.yaaf_asset_id || "");

  const isYaafIdValid = useMemo(
    () => !_.isNil(yaafAssetId) && yaafAssetId.trim() !== "",
    [yaafAssetId]
  );
  const copyYaafAssetIdToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(yaafAssetId);
    toast.success("Copied YAAF asset ID to clipboard");
  }, [yaafAssetId]);

  const metadataCollectorUrl = `${CLIENT_CONFIG.METADATA_COLLECTOR_ROOT}/crid/${asset.yaaf_asset_id}`;

  const searchTitle = React.useMemo(() => {
    const latestRevision = asset?.revisions?.reduce(
      (a: { updated: string }, b: { updated: string }) =>
        !a || new Date(a.updated) < new Date(b.updated) ? b : a,
      null
    );
    const seriesTitle =
      latestRevision?.yaafo?.series?.descriptiveMetadata?.title ||
      latestRevision?.yaafd?.series?.descriptiveMetadata?.title;
    const productionTitle =
      latestRevision?.yaafo?.production?.title ||
      latestRevision?.yaafd?.production?.title;
    return seriesTitle || productionTitle;
  }, [asset]);

  return (
    <div>
      <EditableKeyValuePair
        id="Provider Asset ID"
        value={providerAssetId}
        onChange={setProviderAssetId}
        disabled={disabled}
        labelWidth={4}
        size="md"
      />
      <EditableKeyValuePair
        id="YAAF ID"
        value={
          disabled ? (
            <div className="d-flex align-items-center">
              <div>{yaafAssetId}</div>
              <div>
                <GlyphButton
                  glyph="copy"
                  variant="link"
                  className="ms-1 p-0"
                  title="Copy YAAF asset ID to clipboard"
                  onClick={copyYaafAssetIdToClipboard}
                />
              </div>
              <div className="pe-2">
                <a
                  href={metadataCollectorUrl}
                  target="_blank"
                  rel="noreferrer"
                  title="Open in Metadata Collector"
                  className="text-secondary"
                >
                  <GlyphButton
                    glyph="external"
                    variant="link"
                    className="ms-1 p-0"
                    iconLast
                  />
                </a>
              </div>
              <div>
                <a
                  href={`${CLIENT_CONFIG.PLAY_DOMAIN}/sok?q=${searchTitle}`}
                  target="_blank"
                  rel="noreferrer"
                  title="Search for asset in Telia Play"
                >
                  <PlayLogo style={{ width: "1rem" }} />
                </a>
              </div>
            </div>
          ) : (
            yaafAssetId
          )
        }
        onChange={setYaafAssetId}
        disabled={disabled}
        labelWidth={4}
        size="md"
      />
      {!_.isNil(yaafAssetId) && !isYaafIdValid && !disabled && (
        <div className="text-end help-block text-danger mb-2">
          YAAF ID cannot be blank
        </div>
      )}
      {!disabled && (
        <div className="d-flex justify-content-end">
          <GlyphButton
            glyph="CLOSE"
            variant="danger"
            size="sm"
            className="me-2"
            onClick={cancel}
          >
            Cancel
          </GlyphButton>
          <GlyphButton
            glyph="CHECK"
            size="sm"
            variant="success"
            disabled={!isDirty || !isYaafIdValid}
            onClick={save}
          >
            Save
          </GlyphButton>
        </div>
      )}
    </div>
  );
}
