import PropTypes from "prop-types";
import React from "react";
import { Link, NavLink } from "react-router-dom";

export const ACLMenuItem = ({ to, exact, hasAccessTo, children }) =>
  hasAccessTo(to) ? (
    <NavLink
      to={to}
      exact={exact}
      activeClassName="active"
      className="publish-toolbar-item"
    >
      {children}
    </NavLink>
  ) : null;

ACLMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  hasAccessTo: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ACLMenuItem.defaultProps = {
  exact: false,
};

export const ACLMenuButton = ({ to, hasAccessTo, children }) =>
  hasAccessTo(to) ? (
    <Link className="btn btn-primary" to={to}>
      {children}
    </Link>
  ) : null;

ACLMenuButton.propTypes = {
  to: PropTypes.string.isRequired,
  hasAccessTo: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
