import {
  Availability,
  CollectionFilters,
  ContentType,
  DurationInterval,
  ProductionYearRange,
  SecondSorting,
  Sorting,
} from "@telia-company/tv.no-play-cms-common/collection/types";
import { ChangeEvent } from "react";
import { ColorResult } from "react-color";
import { Genres } from "client/types";
import { move } from "client/utils";
import {
  Collection,
  CollectionClientType,
  CollectionContentType,
  CollectionRowType,
  CollectionType,
  MediaCollectionItem,
} from "./types";

export function getEmptyCollection(): Collection {
  return {
    activeContent: true,
    enabled: true,
    title: "",
    description: "",
    notes: "",
    contentType: "media",
    collectionType: "simple",
    mediaContents: [],
    assets: [],
    collections: [],
    dynamic: false,
    filters: getEmptyCollectionFilters(),
    footballEvent: undefined,
    personalizeOrder: true,
    autoOrientation: true,
    rowType: "verticalCards",
    // default background color suggested by Aslak
    backgroundColor: "#1A1A1A",
    backgroundGradient: true,
  };
}

// Exported for testing only
export const getEmptyCollectionFilters = (): CollectionFilters => ({
  genre: { items: [], operator: "or" },
  excludedGenres: [],
  channels: [],
  providers: [],
  assetCount: 10,
  availability: [],
  sorting: "added-asc",
  contentTypes: [],
  hideUnavailableContent: false,
  availableContent: "SHOW_ALL",
});

const hasValidFilters = (filters: CollectionFilters) => {
  if (filters.productionYearRange) {
    const startYear = filters.productionYearRange.start;
    if (startYear && startYear.toString().length !== 4) {
      return false;
    }
    const endYear = filters.productionYearRange.end;
    if (endYear && endYear.toString().length !== 4) {
      return false;
    }
  }

  return true;
};

function hasValidCollectionContent(collection: Collection) {
  switch (collection.contentType) {
    case CollectionContentType.Media:
      if (collection.dynamic) {
        const filters = collection.filters;
        if (!filters) {
          return false;
        }
        return hasValidFilters(filters);
      }
      return collection.assets.length + collection.mediaContents.length > 0;
    case CollectionContentType.Collections:
      return collection.collections.length > 0;
    case CollectionContentType.Matches:
      return !!collection.footballEvent;
    default:
      return false;
  }
}

function isValidCollectionType(collection: Collection) {
  switch (collection.collectionType) {
    case "simple":
      return true;
    case "page":
      return collection.collectionCard || collection.pageBackground
        ? true
        : false;
    default:
      return false;
  }
}

export function isValidCollection(collection: Collection) {
  if (!collection || collection.title === "") return false;
  if (!isValidCollectionType(collection)) return false;
  if (!hasValidCollectionContent(collection)) return false;
  return true;
}

/**
 * @param {{ collectionType: "simple"|"page", contentType: "media"|"collections"|"matches" }} collection
 */
export function getClientType(
  collectionType: CollectionType,
  contentType: CollectionContentType
): CollectionClientType | undefined {
  if (!collectionType || !contentType) {
    return undefined;
  }

  if (contentType === "media" && collectionType === "simple") {
    return "row";
  }
  if (contentType === "media" && collectionType === "page") {
    return "rowWithGraphics";
  }
  if (contentType === "collections" && collectionType === "simple") {
    return "rowOfThemes";
  }
  if (contentType === "collections" && collectionType === "page") {
    return "hubWithGraphics";
  }
  if (contentType === "matches" && collectionType === "page") {
    return "footballEvent";
  }
}

export function getCollectionType(
  clientType: CollectionClientType
): CollectionType | undefined {
  switch (clientType) {
    case "row":
    case "rowOfThemes":
      return "simple";
    case "rowWithGraphics":
    case "hubWithGraphics":
    case "footballEvent":
      return "page";
    default:
      return undefined;
  }
}

export function getContentType(
  clientType: CollectionClientType
): CollectionContentType | undefined {
  switch (clientType) {
    case "row":
    case "rowWithGraphics":
      return "media";
    case "rowOfThemes":
    case "hubWithGraphics":
      return "collections";
    case "footballEvent":
      return "matches";
    default:
      return undefined;
  }
}
export const CLIENT_TYPE_OPTIONS = [
  // a regular row of media content/assets
  { value: "row", label: "Row" },
  // a row with graphics
  { value: "rowWithGraphics", label: "Theme" },
  // a row of themes
  { value: "rowOfThemes", label: "Theme Row", className: "ms-2" },
  // a row of rows/themes, with graphics
  { value: "hubWithGraphics", label: "Hub" },
  // a generated row of football content, with graphics
  { value: "footballEvent", label: "Football Event", className: "ms-2" },
];

export const CLIENT_TYPE_BADGES = [
  { value: "row", label: "Row", variant: "light" },
  { value: "rowWithGraphics", label: "Theme", variant: "light" },
  { value: "rowOfThemes", label: "Theme Row", variant: "info" },
  { value: "hubWithGraphics", label: "Hub", variant: "info" },
  { value: "footballEvent", label: "Football Event", variant: "secondary" },
];

type SetCollection = (
  value: ((prevState: Collection) => Collection) | Collection
) => void;

export const getCollectionChangeHandlers = (setCollection: SetCollection) => {
  const patchCollection = (patch: Partial<Collection>) =>
    setCollection((collection) => ({ ...collection, ...patch }));
  const patchCollectionFilters = (patch: Partial<CollectionFilters>) =>
    setCollection((collection) => {
      if (collection.filters) {
        return {
          ...collection,
          filters: { ...collection.filters, ...patch },
        };
      }
      // TODO(alvin): investigate if and when this happens
      throw new Error("collection.filters must exist");
    });

  return {
    // access the change handler directly
    raw: patchCollection,
    enabled: (enabled: boolean) => patchCollection({ enabled }),
    title: (event: ChangeEvent<HTMLInputElement>) =>
      patchCollection({ title: event.target.value }),
    description: (event: ChangeEvent<HTMLInputElement>) =>
      patchCollection({ description: event.target.value }),
    notes: (event: ChangeEvent<HTMLInputElement>) =>
      patchCollection({
        notes: event.target.value,
      }),
    clientType: (clientType: CollectionClientType) => {
      const collectionType = getCollectionType(clientType);
      const contentType = getContentType(clientType);
      patchCollection({ collectionType, contentType });
    },

    // collection graphics
    collectionCard: (collectionCard: string) =>
      patchCollection({ collectionCard }),
    pageHeader: (pageHeader: string) => patchCollection({ pageHeader }),
    pageHeaderMobile: (pageHeaderMobile: string) =>
      patchCollection({ pageHeaderMobile }),
    pageBackground: (pageBackground: string) =>
      patchCollection({ pageBackground }),

    backgroundColor: (color: ColorResult) =>
      patchCollection({ backgroundColor: color.hex }),
    backgroundGradient: (backgroundGradient: boolean) =>
      patchCollection({ backgroundGradient }),

    // adding content is handled by the asset dialog's onAdd function
    // reordering content calls the functions below
    assets: (assets: MediaCollectionItem[]) => patchCollection({ assets }),
    mediaContents: (mediaContents: MediaCollectionItem[]) =>
      patchCollection({ mediaContents }),
    collections: (collections: number[]) => patchCollection({ collections }),
    footballEvent: (footballEvent: number) =>
      patchCollection({ footballEvent }),
    dynamic: (event: ChangeEvent<HTMLInputElement>) =>
      patchCollection({ dynamic: event.target.checked }),
    autoOrientation: (autoOrientation: boolean) =>
      patchCollection({ autoOrientation }),
    personalizeOrder: (personalizeOrder: boolean) =>
      patchCollection({ personalizeOrder }),
    rowType: (rowType: CollectionRowType) => patchCollection({ rowType }),

    moveCollections: (index1: number, index2: number) => {
      setCollection((collection) => ({
        ...collection,
        collections: move(collection.collections, index1, index2),
      }));
    },
    moveMediaContents: (index1: number, index2: number) => {
      setCollection((collection) => ({
        ...collection,
        mediaContents: move(collection.mediaContents, index1, index2),
      }));
    },
    moveAssets: (index1: number, index2: number) => {
      setCollection((collection) => ({
        ...collection,
        assets: move(collection.assets, index1, index2),
      }));
    },

    // Dynamic collection filters
    assetCount: (event: ChangeEvent<HTMLInputElement>) =>
      patchCollectionFilters({
        assetCount: event.target.value
          ? Math.max(Math.min(parseInt(event.target.value), 100), 1) ||
            undefined
          : undefined,
      }),
    genre: (selected: Genres, operator: "or" | "and") => {
      const genreNames = selected.map((genre) => genre.value);
      patchCollectionFilters({ genre: { items: genreNames, operator } });
    },
    genreOperator: (
      event: ChangeEvent<HTMLSelectElement>,
      genres: string[]
    ) => {
      const operator = event.target.value;
      if (operator !== "or" && operator !== "and") {
        throw new Error("should never happen");
      }
      return patchCollectionFilters({ genre: { items: genres, operator } });
    },
    excludedGenres: (selected: Genres) => {
      const genreNames = selected.map((genre) => genre.value);
      patchCollectionFilters({ excludedGenres: genreNames });
    },
    channel: (channels: number[]) => {
      patchCollectionFilters({ channels });
    },
    provider: (providers: number[]) => {
      patchCollectionFilters({
        providers: providers.map((provider) => provider),
      });
    },
    availability: (availabilities: Availability[]) => {
      patchCollectionFilters({ availability: availabilities });
    },
    sorting: (sorting: Sorting) => {
      patchCollectionFilters({ sorting });
    },
    secondSorting: (secondSorting: SecondSorting | "none") => {
      const newValue = secondSorting !== "none" ? secondSorting : undefined;
      patchCollectionFilters({ secondSorting: newValue });
    },
    contentType: (contentTypes: ContentType[]) => {
      const patch: Partial<CollectionFilters> = { contentTypes: contentTypes };
      if (contentTypes.length !== 1 || contentTypes[0] !== "movie") {
        patch.productionYearRange = undefined;
      }
      patchCollectionFilters(patch);
    },
    productionYearRange: (productionYearRange?: ProductionYearRange) => {
      const newProductionYearRange =
        productionYearRange &&
        (productionYearRange.start || productionYearRange.end)
          ? productionYearRange
          : undefined;
      patchCollectionFilters({ productionYearRange: newProductionYearRange });
    },
    directors: (directors: string[]) => {
      patchCollectionFilters({
        directors: directors && directors.length > 0 ? directors : undefined,
      });
    },
    actors: (actors: string[]) => {
      patchCollectionFilters({
        actors: actors && actors.length > 0 ? actors : undefined,
      });
    },
    durationInterval: (durationInterval?: DurationInterval) => {
      if (
        durationInterval &&
        durationInterval.min === "0h" &&
        durationInterval.max === ">3h"
      ) {
        patchCollectionFilters({ durationInterval: undefined });
      } else {
        patchCollectionFilters({ durationInterval });
      }
    },
    availableContent: (event: ChangeEvent<HTMLSelectElement>) => {
      patchCollectionFilters({
        availableContent: event.target.value as
          | "SHOW_ALL"
          | "AVAILABLE_FIRST"
          | "AVAILABLE_ONLY"
          | "UNAVAILABLE_ONLY",
      });
    },
    manualPrio: (newManualPrio: number[]) => {
      patchCollectionFilters({
        manualPrio: newManualPrio,
      });
    },
    manualHide: (newManualHide: number[]) => {
      patchCollectionFilters({
        manualHide: newManualHide,
      });
    },
  };
};

export type CollectionOnChangeHandlers = ReturnType<
  typeof getCollectionChangeHandlers
>;
