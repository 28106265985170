import React from "react";
import { Badge } from "react-bootstrap";
import { LoadingOverlayWrapper } from "client/utils";
import SideMenu from "components/toolbar/SideMenu";

function ProviderStatusBadge({ status, bg, children }) {
  if (status === "ACTIVE") {
    return (
      <Badge bg={bg} className="ms-1">
        {children}
      </Badge>
    );
  }
  return null;
}

function ProviderMenuItem({ provider, selected, getLinkTo }) {
  const { id, name, play_status, uhe_status } = provider;
  const isInactive = play_status !== "ACTIVE" && uhe_status !== "ACTIVE";

  return (
    <SideMenu.Link to={getLinkTo(id)} selected={selected}>
      <div className="d-flex justify-content-between">
        <div className={isInactive ? "text-muted" : undefined}>{name}</div>
        <div className="ms-4">
          <ProviderStatusBadge bg="primary" status={play_status}>
            Play
          </ProviderStatusBadge>
          <ProviderStatusBadge bg="info" status={uhe_status}>
            UHE
          </ProviderStatusBadge>
        </div>
      </div>
    </SideMenu.Link>
  );
}

function ProviderSideMenu({
  isLoading,
  items,
  selectedId,
  getLinkTo,
  canEdit,
}) {
  return (
    <LoadingOverlayWrapper show={isLoading}>
      <SideMenu>
        <SideMenu.Header>Select a Provider</SideMenu.Header>
        {items.map((provider) => (
          <ProviderMenuItem
            key={provider.id}
            provider={provider}
            getLinkTo={getLinkTo}
            selected={provider.id === selectedId}
          />
        ))}
        {canEdit && (
          <SideMenu.NavLink
            to="/ams/providers/create"
            glyph="add"
            className="text-nowrap"
          >
            Create Provider
          </SideMenu.NavLink>
        )}
      </SideMenu>
    </LoadingOverlayWrapper>
  );
}

export default ProviderSideMenu;
