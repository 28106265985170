import { MediaContentData } from "@telia-company/tv.no-play-cms-common/db/definitions/MediaContent";
import React from "react";
import DiffView from "components/DiffView";

const dataToString = (data?: MediaContentData) => JSON.stringify(data, null, 2);

function DataViewer({
  leftValue,
  rightValue,
}: {
  leftValue?: MediaContentData;
  rightValue?: MediaContentData;
}) {
  return (
    <DiffView
      hideLineNumbers={rightValue ? false : true}
      oldValue={dataToString(leftValue) || ""}
      newValue={
        rightValue ? dataToString(rightValue) : dataToString(leftValue) || ""
      }
      showDiffOnly={rightValue ? true : false}
      splitView={rightValue ? true : false}
    />
  );
}

export default DataViewer;
