import React, { useCallback } from "react";
import { Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";
import { MomentParam } from "client/types/MomentParam";
import DateTimeControl from "components/controls/datetime/DateTimeControl";
import FilterSelect from "./FilterSelect";

export function TextInputFilter({ filterKey, ...rest }) {
  const [param, setParam] = useQueryParam(filterKey, StringParam);
  return (
    <FormControl
      type="text"
      placeholder="Search..."
      value={param}
      onChange={(e) => setParam(e.target.value)}
      {...rest}
    />
  );
}

export function SelectFilter({ filterKey, options, ...rest }) {
  const [param, setParam] = useQueryParam(filterKey, StringParam);

  const onChange = useCallback((e) => setParam(e.target.value), [setParam]);

  return (
    <Form.Select value={param} onChange={onChange} {...rest}>
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Form.Select>
  );
}

export function MultiSelectCheckboxFilter({
  filterKey,
  type,
  options,
  isLoading,
  ...rest
}) {
  const [param = [], setParam] = useQueryParam(filterKey, type);

  return (
    <FilterSelect
      isMulti
      isClearable={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isLoading={isLoading}
      options={options}
      onChange={(selected) => {
        setParam(selected.map((s) => s.value));
      }}
      value={param.map((value) => {
        return options.find((option) => option.value === value);
      })}
      styles={{
        menu: (base) => ({
          ...base,
          width: "max-content",
          minWidth: "100%",
        }),
        valueContainer: (base) => ({
          ...base,
          flexWrap: "none",
          whiteSpace: "nowrap",
          paddingRight: 0,
        }),
        multiValueLabel: (base) => ({
          ...base,
          paddingLeft: "3px",
        }),
      }}
      {...rest}
    />
  );
}

export function DateTimeRangeFilter() {
  const [from, setFrom] = useQueryParam("from", MomentParam);
  const [to, setTo] = useQueryParam("to", MomentParam);

  return (
    <Row>
      <Col xs={6} className="pe-0">
        <FormLabel hidden>From</FormLabel>
        <DateTimeControl
          placeholder="From..."
          acceptEmpty
          closeOnSelect
          value={from}
          onChange={setFrom}
        />
      </Col>
      <Col xs={6} className="ps-0">
        <FormLabel hidden>To</FormLabel>
        <DateTimeControl
          anchorRight
          placeholder="To..."
          acceptEmpty
          closeOnSelect
          value={to}
          onChange={setTo}
        />
      </Col>
    </Row>
  );
}
