import classNames from "classnames";
import React from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { CmsPure } from "client/utils";
import { ITEM_MIN_WIDTH, unixToDescriptionString } from "../utils";
import Content from "./Content";
import ResizableEdge from "./ResizableEdge";

function getStyles(left, top, right, size) {
  const transform = `translate3d(${Math.round(left)}px, ${Math.round(
    top
  )}px, 0)`;
  return {
    transform,
    WebkitTransform: transform,
    width: Math.max(Math.round(right - left), ITEM_MIN_WIDTH),
    zIndex: size === "sm" ? 2 : 1,
  };
}

class DraggableContent extends CmsPure {
  onClick = (event) => {
    const { schedule, item, itemType, onClickItem } = this.props;
    event.stopPropagation();
    onClickItem(schedule, item, itemType);
  };
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    const {
      item,
      schedule,
      active,
      position,
      connectDragSource,
      itemType,
      // note that this is a prop stored in the Schedule import
      // and not the react-dnd isDragging prop
      isDragged,
      updateItem,
      onMouseMove,
      canEdit,
    } = this.props;

    const duration = schedule.unixEnd - schedule.unixStart;
    const size =
      duration <= 5 * 3600 ? "sm" : duration <= 24 * 3600 ? "md" : "lg";

    const styles = getStyles(position.left, position.top, position.right, size);

    return connectDragSource(
      <div
        className={classNames("scheduler-event-draggable", {
          isDragging: isDragged,
          view: !canEdit,
        })}
        style={styles}
        onMouseMove={onMouseMove}
        onClick={this.onClick}
      >
        {canEdit && (
          <ResizableEdge
            direction="left"
            item={item}
            schedule={schedule}
            position={position}
            updateItem={updateItem}
            itemType={itemType}
          />
        )}
        <Content
          title={schedule.title || item.title}
          description={`${unixToDescriptionString(
            schedule.unixStart,
            schedule.unixEnd,
            size
          )}${item.description ? " | " + item.description : ""}`}
          pill={styles.width < 3 * ITEM_MIN_WIDTH}
          size={size}
          active={active}
          invalid={!item.active}
          position={position}
          itemType={itemType}
        />
        {canEdit && (
          /** This should probably not be passed item */
          <ResizableEdge
            direction="right"
            item={item}
            schedule={schedule}
            position={position}
            updateItem={updateItem}
            itemType={itemType}
          />
        )}
      </div>
    );
  }
}

const DraggableContentWithDrag = (props) => {
  const [, drag, dragPreview] = useDrag({
    type: props.itemType,
    item: () => ({
      direction: "both",
      item: props.item,
      schedule: props.schedule,
      position: props.position,
      itemType: props.itemType,
    }),
    canDrag: () => props.canEdit,
    end: (item, monitor) => {
      if (monitor.didDrop()) return;
      props.updateItem(monitor.getItem(), true, false);
    },
  });

  return (
    <DraggableContent
      {...props}
      connectDragSource={drag}
      connectDragPreview={dragPreview}
    />
  );
};

export default DraggableContentWithDrag;
