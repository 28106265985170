import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { CmsPure } from "client/utils";
import ToggleControl from "components/controls/ToggleControl";
import { getComposerTitle } from "../provisioningUtils";

const LABEL = "DVB Triplet";
class DVBTripletComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      originalNetworkId: "",
      transportStreamId: "",
      serviceId: "",
      serviceType: "tv",
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const { mode, id } = this.props;
    if (mode !== "edit" || (prevProps && prevProps.id === id)) return;

    this.props.get(id, (state) => this.setState(state));
  }

  createChannel = (event) => {
    event.preventDefault();
    const { originalNetworkId, transportStreamId, serviceId, serviceType } =
      this.state;
    const payload = {
      originalNetworkId: Number(originalNetworkId),
      transportStreamId: Number(transportStreamId),
      serviceId: Number(serviceId),
      serviceType,
    };
    this.props.put(payload, this.props.id, LABEL);
  };

  render() {
    const { originalNetworkId, transportStreamId, serviceId, serviceType } =
      this.state;
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.createChannel}>
        <h1>{title}</h1>
        <Row className="mb-3">
          <Col as={Form.Group} xs={6}>
            <Form.Label>Original Network Id</Form.Label>
            <Form.Control
              type="number"
              value={originalNetworkId}
              onChange={(e) =>
                this.setState({ originalNetworkId: e.target.value })
              }
            />
          </Col>
          <Col as={Form.Group} xs={6}>
            <Form.Label>Transport Stream Id</Form.Label>
            <Form.Control
              type="number"
              value={transportStreamId}
              onChange={(e) =>
                this.setState({ transportStreamId: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col as={Form.Group} xs={6}>
            <Form.Label>Service Id</Form.Label>
            <Form.Control
              type="number"
              value={serviceId}
              onChange={(e) => this.setState({ serviceId: e.target.value })}
            />
          </Col>
          <Col as={Form.Group} xs={6}>
            <Form.Label>Service Type</Form.Label>
            <ToggleControl
              allowed={["tv", "radio", "unknown"]}
              value={serviceType}
              onChange={(serviceType) => this.setState({ serviceType })}
            />
          </Col>
        </Row>

        <Button className="pull-right" variant="primary" type="submit">
          {this.props.mode}
        </Button>
      </Form>
    );
  }
}

export default DVBTripletComposer;
