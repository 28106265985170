import { capitalize } from "@telia-company/tv.no-play-cms-common/api/util";
import _ from "lodash";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CmsPure } from "client/utils";
import { getComposerTitle } from "../provisioningUtils";
import ProvisionerIdMultiPicker from "./ProvisionerIdMultiPicker";
import ProvisionerIdPicker from "./ProvisionerIdPicker";

const LABEL = "TV Provider Placement";

class TVProviderPlacementComposer extends CmsPure {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        tvProviderId: "",
        lineupId: "",
        channelNumber: "",
        dvbCableTuningPlanId: "",
        dvbIpTvTuningPlanId: "",
        dvbCableAesTuningPlanId: "",
        dvbIpTvAesTuningPlanId: "",
      },
      identicalLineupsOnly: true,
      allowedPlacements: [],
      lineupIds: [],
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    const { identicalLineupsOnly } = this.state;

    if (this.shouldReloadConfig(prevProps)) {
      this.reloadConfig();
    } else if (identicalLineupsOnly !== prevState?.identicalLineupsOnly) {
      this.reloadAllowedPlacements();
    }
  }

  reloadConfig() {
    const { id } = this.props;
    this.props.get(id, (config) => {
      this.setState({
        config,
        allowedPlacements: this.getAllowedPlacements(config),
        lineupIds: [config.lineupId],
      });
    });
  }

  reloadAllowedPlacements() {
    const { id } = this.props;
    this.props.get(id, (config) => {
      this.setState({
        allowedPlacements: this.getAllowedPlacements(config),
      });
    });
  }

  shouldReloadConfig(prevProps) {
    return (
      this.state.config.id !== this.props.id ||
      !_.isEqual(this.props.tableData, prevProps?.tableData)
    );
  }

  create = async (event) => {
    event.preventDefault();
    const { ...config } = { ...this.state.config };

    // remove optional properties if the user did not set them
    if (!config.dvbCableTuningPlanId) delete config.dvbCableTuningPlanId;
    if (!config.dvbIpTvTuningPlanId) delete config.dvbIpTvTuningPlanId;
    if (!config.dvbCableAesTuningPlanId) delete config.dvbCableAesTuningPlanId;
    if (!config.dvbIpTvAesTuningPlanId) delete config.dvbIpTvAesTuningPlanId;

    let numberOfSuccessfulPlacements = 0;
    let requestSucceeded;
    let id;
    let label;

    const confirmPrompt = this.getConfirmPrompt();

    for (let i = 0; i < this.state.lineupIds.length; i++) {
      const lineupId = this.state.lineupIds[i];

      const doNotRefreshTables = i !== this.state.lineupIds.length - 1;
      const hideConfirm = i > 0;
      const placement = this.getPlacement(lineupId);
      id = this.getPlacementId(lineupId);
      label = LABEL;
      const payload = { ...config, lineupId, id };

      if (placement) {
        label = `${placement.tvProviderName} - ${placement.lineupName}`;
      }

      requestSucceeded = await this.props.put(payload, id, label, {
        hideConfirm,
        confirmPrompt,
        doNotRefreshTables,
        hideSuccessFeedback: true,
      });

      if (requestSucceeded === undefined) {
        // User cancelled the confirm dialog
        return;
      }

      if (requestSucceeded) numberOfSuccessfulPlacements += 1;
    }

    if (numberOfSuccessfulPlacements > 0) {
      const infoString =
        numberOfSuccessfulPlacements === 1
          ? `Updated placement for ${label}`
          : `Updated ${numberOfSuccessfulPlacements} placements!`;

      toast.info(infoString);
    }
  };

  getConfirmPrompt = () => {
    if (this.props.mode === "create") {
      // The default prompt works fine for new entries
      return undefined;
    }

    const lineupId = this.state.lineupIds[0];
    const placement = this.getPlacement(lineupId);

    if (this.state.lineupIds.length === 1) {
      return `This will update ${placement.tvProviderName} in lineup ${placement.lineupName}`;
    } else {
      return `This will update ${placement.tvProviderName} in ${this.state.lineupIds.length} lineups`;
    }
  };

  toggleIdenticalLineupsOnly = () => {
    this.setState(({ identicalLineupsOnly }) => ({
      identicalLineupsOnly: !identicalLineupsOnly,
    }));
  };

  getAllowedPlacements = (configObject) => {
    const { identicalLineupsOnly, config } = this.state;
    if (identicalLineupsOnly) {
      return this.getIdenticalPlacements(configObject);
    } else {
      return this.props.tableData.filter(
        (placement) => placement.tvProviderId === config.tvProviderId
      );
    }
  };

  getIdenticalPlacements = (configObject) => {
    return _.filter(this.props.tableData, (placement) =>
      this.isMatchingPlacement(placement, configObject)
    );
  };

  isMatchingPlacement = (placement, configObject) => {
    const matchKeys = [
      "tvProviderId",
      "channelNumber",
      "dvbIpTvTuningPlanId",
      "dvbCableTuningPlanId",
    ];
    const source = _.pickBy(_.pick(configObject, matchKeys), _.identity);
    const emptyKeys = matchKeys.filter((k) => !configObject[k]);

    return (
      _.isMatch(placement, source) &&
      !emptyKeys.some((key) => _.has(placement, key))
    );
  };

  getPlacement = (lineupId) => {
    return this.state.allowedPlacements.find((p) => p.lineupId === lineupId);
  };

  getPlacementId = (lineupId) => {
    if (this.props.mode === "edit") {
      const placement = this.getPlacement(lineupId);
      if (placement) return placement.id;
    } else {
      return uuidv4();
    }
  };

  isValid = () => {
    const { tvProviderId, channelNumber } = this.state.config;
    const { lineupIds } = this.state;
    return tvProviderId && _.isNumber(channelNumber) && !_.isEmpty(lineupIds);
  };

  hasNonMatchingLineups() {
    const { id, tableData } = this.props;
    const config = tableData.find((config) => config.id === id);
    if (!config) return false;

    const matchingPlacements = this.getIdenticalPlacements(config);
    const matchingLineupIds = matchingPlacements.map((p) => p.lineupId);

    return this.state.lineupIds.some((id) => !matchingLineupIds.includes(id));
  }

  render() {
    const title = getComposerTitle(this.props.mode, this.props.id, LABEL);
    console.log(title, this.props, this.state);
    return (
      <Form onSubmit={this.create}>
        <h1>{title}</h1>
        <ProvisionerIdPicker
          contentType="TV Provider"
          selectedId={this.state.config.tvProviderId}
          onSelect={(val) =>
            this.setState({
              config: { ...this.state.config, tvProviderId: val },
            })
          }
        />
        <ProvisionerIdMultiPicker
          contentType="Lineup"
          selectedIds={this.state.lineupIds}
          onSelect={(lineupIds) => this.setState({ lineupIds })}
          allowedOptions={
            this.props.mode === "edit" &&
            _.map(this.state.allowedPlacements, "lineupId")
          }
          optionsFilterLabel="Identical lineups only"
          optionsFilterEnabled={this.state.identicalLineupsOnly}
          toggleOptionsFilter={this.toggleIdenticalLineupsOnly}
          isLoading={this.props.isFetchingTable}
        />
        {!this.props.isFetchingTable && this.hasNonMatchingLineups() && (
          <div className="text-danger">
            The configuration for the current provider is not identical for all
            selected lineups. Any custom configurations for the selected lineups
            will be overwritten when saving the changes. Proceed with caution!
          </div>
        )}
        <Form.Group>
          <Form.Label>Channel Number</Form.Label>
          <Form.Control
            type="number"
            value={this.state.config.channelNumber}
            onChange={(e) =>
              this.setState({
                config: {
                  ...this.state.config,
                  channelNumber: Number(e.target.value),
                },
              })
            }
          />
        </Form.Group>
        <ProvisionerIdPicker
          contentType="DVB Cable Tuning Plan"
          selectedId={this.state.config.dvbCableTuningPlanId}
          onSelect={(val) =>
            this.setState({
              config: { ...this.state.config, dvbCableTuningPlanId: val },
            })
          }
          isClearable
        />
        <ProvisionerIdPicker
          contentType="DVB IPTV Tuning Plan"
          selectedId={this.state.config.dvbIpTvTuningPlanId}
          onSelect={(val) =>
            this.setState({
              config: { ...this.state.config, dvbIpTvTuningPlanId: val },
            })
          }
          isClearable
        />
        <ProvisionerIdPicker
          contentType="DVB Cable AES Tuning Plan"
          selectedId={this.state.config.dvbCableAesTuningPlanId}
          onSelect={(val) =>
            this.setState({
              config: {
                ...this.state.config,
                dvbCableAesTuningPlanId: val,
              },
            })
          }
          isClearable
        />
        <ProvisionerIdPicker
          contentType="DVB IPTV AES Tuning Plan"
          selectedId={this.state.config.dvbIpTvAesTuningPlanId}
          onSelect={(val) =>
            this.setState({
              config: {
                ...this.state.config,
                dvbIpTvAesTuningPlanId: val,
              },
            })
          }
          isClearable
        />
        <Button
          className="pull-right mt-4"
          variant="primary"
          type="submit"
          disabled={!this.isValid()}
        >
          {capitalize(this.props.mode)}
        </Button>
      </Form>
    );
  }
}

export default TVProviderPlacementComposer;
