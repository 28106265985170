import React, { useCallback } from "react";
import { CmsCardContainer, CloudImageCard } from "components/cards";
import CloudImageControl from "components/image/CloudImageControl";
import SourceAnnotation from "./SourceAnnotation";
import { useDispatch } from "./context";

function getSizeByImageType(imageType) {
  switch (imageType) {
    case "cover":
      return { width: 576, height: 864 };
    case "poster":
    default:
      return { width: 576, height: 324 };
  }
}

const ImageListEntry = ({ image }) => {
  const dispatch = useDispatch();
  const onDelete = useCallback(() => {
    dispatch({ type: "mc.image.delete", payload: image });
  }, [image]);
  const onRestore = useCallback(() => {
    dispatch({ type: "mc.image.restore", payload: image });
  }, [image]);
  const { width, height } = getSizeByImageType(image.value.imageType);

  return (
    <div>
      <CloudImageCard
        value={image.value.path}
        width={width}
        height={height}
        download
        onDelete={image.source !== "deleted" ? onDelete : undefined}
      />
      <SourceAnnotation
        source={image.source}
        isModified={image.dirty}
        onRestore={onRestore}
      />
    </div>
  );
};

const ImageList = ({ images, type }) => {
  const dispatch = useDispatch();
  const addImage = useCallback((path, type) => {
    dispatch({ type: "mc.image.add", payload: { path, imageType: type } });
  }, []);
  const { width, height } = getSizeByImageType(type);

  return (
    <CmsCardContainer className="cover">
      {images.map((image) => (
        <ImageListEntry image={image} key={image.value.path} />
      ))}
      <CloudImageControl
        type={type}
        width={width}
        height={height}
        validateResolution={false}
        description={`Add ${type}`}
        onChange={addImage}
      />
    </CmsCardContainer>
  );
};

export default ImageList;
