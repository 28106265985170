import classNames from "classnames";
import PropTypes from "prop-types";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useCallback, useEffect, useReducer } from "react";
import { request, formatDuration } from "client/utils";
import { GlyphButton } from "components/buttons";
import { getRuntimeUrl, getFrameCaptureUrl } from "./framecapture";
import { reducer, initState } from "./reducer";

const SliderWithTooltip = createSliderWithTooltip(Slider);

const AssetImageGrabber = ({
  asset,
  offset,
  onChange,
  className,
  onClose,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, { asset, offset }, initState);

  // Load asset runtime from the frame capture API
  useEffect(() => {
    if (!asset) {
      return;
    }

    let cancelled = false;
    request(getRuntimeUrl(asset)).then((res) => {
      if (res && !cancelled) {
        dispatch({ type: "runtime.loaded", payload: res });
      }
    });

    return () => {
      cancelled = true;
    };
  }, [asset]);

  // Refresh the current frame when the component mounts or when the
  // scrubbing offset is changed.
  useEffect(() => {
    if (!asset) {
      return;
    }

    if (offset !== state.initialOffset && offset !== undefined) {
      // Scrubbing offset was changed from the outside through the
      // 'offset' prop.  Reset the image grabber to use the new offset
      // regardless of the component's previous state.
      dispatch({ type: "reset", payload: offset });
      return;
    }

    onChange({ offset: state.offset });

    const thumbnailEndpoint = getFrameCaptureUrl(asset, state.offset, "small");
    const imageEndpoint = getFrameCaptureUrl(asset, state.offset, "large");

    dispatch({
      type: "image.requested",
      payload: { thumbnail: thumbnailEndpoint, image: imageEndpoint },
    });
  }, [offset, state.offset, state.initialOffset, asset]);

  // Simultaneously request both a low-res and a high-res version of the
  // current frame from the frame capture API.  The low-res version will
  // usually load quickly and will be replaced with the high-res version
  // once both versions have been loaded.
  useEffect(() => {
    const { imageEndpointUrl, thumbnailEndpointUrl } = state;
    const frames = { thumbnail: undefined, image: undefined };
    let cancelled = false;

    const requestFrame = (endpointUrl, frameType) => {
      if (endpointUrl) {
        request(endpointUrl)
          .then((img) => {
            if (img && !cancelled) {
              frames[frameType] = img;
              const { thumbnail, image } = frames;
              dispatch({ type: "image.loaded", payload: { thumbnail, image } });
              onChange({ thumbnail, image, offset: state.offset });
            }
          })
          .catch((e) => {
            if (!cancelled) {
              dispatch({ type: "image.failed", payload: e.message });
            }
          });
      }
    };

    requestFrame(thumbnailEndpointUrl, "thumbnail");
    requestFrame(imageEndpointUrl, "image");

    return () => {
      cancelled = true;
    };
  }, [state.imageEndpointUrl, state.thumbnailEndpointUrl]);

  const endScrub = useCallback((pos) => {
    dispatch({ type: "progress.scrubEnd", payload: pos });
  }, []);

  const imageUrl = state.imageUrl || state.thumbnailUrl;
  const { runtime, initialOffset, failed, error } = state;
  const formattedOffset = formatDuration(state.offset);

  return (
    <div className={classNames("asset-image-grabber", className)}>
      {onClose && (
        <GlyphButton
          glyph="close"
          variant="link"
          className="pull-right"
          onClick={onClose}
        />
      )}
      {imageUrl ? (
        <>
          <div className="asset-image">
            <img src={imageUrl} />
          </div>
          <div className="progress-indicator">
            <div className="progress-timestamp">{formattedOffset}</div>
            {runtime && (
              <SliderWithTooltip
                included
                key={initialOffset}
                defaultValue={initialOffset}
                tipFormatter={formatDuration}
                onAfterChange={endScrub}
                max={runtime}
              />
            )}
            <div className="progress-timestamp">{formatDuration(runtime)}</div>
            {children && <div>{children}</div>}
          </div>
        </>
      ) : (
        failed && (
          <div className="asset-error">
            <h4 className="text-danger">Could not load asset preview</h4>
            {error && <div className="text-danger">{error}</div>}
          </div>
        )
      )}
    </div>
  );
};

AssetImageGrabber.propTypes = {
  className: PropTypes.string,
};

AssetImageGrabber.defaultProps = {
  asset: undefined,
  className: undefined,
};

export default AssetImageGrabber;
