import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useQueryParamString } from "./hooks/useOpenCloseProgramMatchCallback";

interface LinkToProgramMatchProps {
  content: ReactNode;
  urlPrefix: string;
  tmsId: string;
  mediaContentId: string | null;
  onClick?: () => void;
  "data-tip"?: string;
}

export const LinkToProgramMatch = ({
  content,
  urlPrefix,
  tmsId,
  mediaContentId,
  onClick,
  "data-tip": dataTip,
}: LinkToProgramMatchProps) => {
  const queryParamString = useQueryParamString();
  if (!mediaContentId) {
    return <>{content}</>;
  }
  return (
    <Link
      to={`${urlPrefix}from/${tmsId}/to/${mediaContentId}${queryParamString}`}
      // This link doesn't actually do anything when clicked; the click is
      // handled by the onClick callback for the Table. The link is added so it
      // can be right-clicked and opened in a new tab.
      onClick={(event) => {
        event.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
      data-tip={dataTip}
    >
      {content}
    </Link>
  );
};
