import {
  CollectionFilters,
  Genre,
} from "@telia-company/tv.no-play-cms-common/collection/types";
import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import useSWR from "swr";
import { useKeptResult } from "client/hooks/useKeptResult";
import { LayoutModeToggle } from "client/views/CollectionEditor/LayoutModeToggle";
import { LayoutOptionsExplainer } from "client/views/CollectionEditor/LayoutOptionsExplainer";
import { RowTypeToggle } from "client/views/CollectionEditor/RowTypeToggle";
import {
  Collection,
  CollectionOnChangeHandlers,
  CollectionPreview,
} from "../types";
import { AssetCountSelector } from "./AssetCountSelector";
import { AvailabilityPicker } from "./AvailabilityPicker";
import { AvailableContentPicker } from "./AvailableContentPicker";
import { ContentLengthPicker } from "./ContentLengthPicker";
import { ContentTypePicker } from "./ContentTypePicker";
import { ManualOverrides } from "./DynamicCollectionOverrides/ManualOverrides";
import { DynamicCollectionPreview } from "./DynamicCollectionPreview";
import { ExcludedGenresPicker } from "./ExcludedGenresPicker";
import { FilterChannelPicker } from "./FilterChannelPicker";
import { FilterGenreOperatorPicker } from "./FilterGenreOperatorPicker";
import { FilterGenrePicker } from "./FilterGenrePicker";
import { FilterProviderPicker } from "./FilterProviderPicker";
import { PartyPicker } from "./PartyPicker";
import { ProductionYearRangePicker } from "./ProductionYearRangePicker";
import { SecondSortPicker } from "./SecondSortPicker";
import { SortingPicker } from "./SortingPicker";

interface FilterColProps {
  children: ReactElement | ReactElement[];
}

const FilterCol = ({ children }: FilterColProps) => {
  return (
    <Col md={4} xs={12}>
      {children}
    </Col>
  );
};

const usePreview = (
  filters?: CollectionFilters
): CollectionPreview | undefined => {
  const validFilters = {
    ...getValidFilters(filters),
    hideUnavailableContent: false,
  };
  if (filters?.manualHide && validFilters.assetCount) {
    validFilters.assetCount += filters.manualHide.length;
  }
  const { data } = useSWR(
    "collectionpreview/preview?filters=" + JSON.stringify(validFilters)
  );

  return useKeptResult(data as CollectionPreview | undefined);
};

const getValidFilters = (filters?: CollectionFilters) => {
  if (!filters) {
    return filters;
  }
  const validFilters = { ...filters };
  if (filters.productionYearRange) {
    validFilters.productionYearRange = { ...filters.productionYearRange };
    if (validFilters.productionYearRange.start) {
      if (validFilters.productionYearRange.start.toString().length !== 4) {
        validFilters.productionYearRange.start = undefined;
      }
    }
    if (validFilters.productionYearRange.end) {
      if (validFilters.productionYearRange.end.toString().length !== 4) {
        validFilters.productionYearRange.end = undefined;
      }
    }
  }
  return validFilters;
};

interface Props {
  collection: Collection;
  onChangeHandlers: CollectionOnChangeHandlers;
  readOnly: boolean;
}

const CollectionFiltersComponent = ({
  collection,
  onChangeHandlers,
  readOnly,
}: Props) => {
  const filters = collection.filters;
  const preview = usePreview(filters);
  if (!filters) {
    return <div>collection filters are undefined</div>;
  }
  // Hardcoded to Norwegian for now; determines what genres are available to
  // select.
  const language = "no";
  const genreStrings = filters.genre.items.filter(
    (item: string | Genre): item is string => typeof item === "string"
  );
  return (
    <>
      <Row className="mb-1">
        <Col xs="auto">
          <LayoutModeToggle
            autoOrientation={collection.autoOrientation}
            onChange={onChangeHandlers.autoOrientation}
          />
        </Col>
        <Col>
          <RowTypeToggle
            rowType={collection.rowType}
            onChange={onChangeHandlers.rowType}
            disabled={collection.autoOrientation}
          />
        </Col>
      </Row>
      <LayoutOptionsExplainer autoOrientation={collection.autoOrientation} />
      <AvailableContentPicker
        value={filters.availableContent}
        onChange={onChangeHandlers.availableContent}
        readOnly={readOnly}
      />
      <Row>
        <FilterCol>
          <Row>
            <Col lg={8} md={7} xs={8}>
              <FilterGenrePicker
                genreValue={genreStrings}
                genreOperatorValue={filters.genre.operator}
                onChange={onChangeHandlers.genre}
                readOnly={readOnly}
                language={language}
                genreCounts={preview ? preview.genres : undefined}
              />
            </Col>
            <Col lg={4} md={5} xs={4}>
              <FilterGenreOperatorPicker
                genreValue={genreStrings}
                genreOperatorValue={filters.genre.operator}
                onChange={onChangeHandlers.genreOperator}
              />
            </Col>
          </Row>
        </FilterCol>
        <FilterCol>
          <FilterChannelPicker
            channelValue={filters.channels}
            onChange={onChangeHandlers.channel}
            readOnly={readOnly}
            channelCounts={preview ? preview.channels : undefined}
          />
        </FilterCol>
        <FilterCol>
          <AvailabilityPicker
            selectedAvailabilities={filters.availability}
            setNewAvailabilities={onChangeHandlers.availability}
            readOnly={readOnly}
          />
        </FilterCol>
      </Row>
      <Row>
        <FilterCol>
          <ExcludedGenresPicker
            excludedGenresValue={filters.excludedGenres || []}
            onChange={onChangeHandlers.excludedGenres}
            language={language}
            readOnly={readOnly}
            genreCounts={preview ? preview.genres : undefined}
          />
        </FilterCol>
        <FilterCol>
          <FilterProviderPicker
            onChange={onChangeHandlers.provider}
            providersValue={filters.providers}
            readOnly={readOnly}
            providerCounts={preview ? preview.providers : undefined}
          />
        </FilterCol>
        <FilterCol>
          <ContentTypePicker
            selectedContentTypes={filters.contentTypes}
            setNewContentTypes={onChangeHandlers.contentType}
            readOnly={readOnly}
          />
        </FilterCol>
      </Row>
      <Row>
        <FilterCol>
          <AssetCountSelector
            value={filters.assetCount}
            onChange={onChangeHandlers.assetCount}
            readOnly={readOnly}
          />
        </FilterCol>
        <FilterCol>
          <SortingPicker
            selected={filters.sorting}
            onChange={onChangeHandlers.sorting}
            readOnly={readOnly}
          />
        </FilterCol>
        <FilterCol>
          <SecondSortPicker
            selected={filters.secondSorting}
            onChange={onChangeHandlers.secondSorting}
            readOnly={readOnly}
          />
        </FilterCol>
      </Row>
      <Row>
        <FilterCol>
          <PartyPicker
            id="collection-director-picker"
            parties={filters.directors}
            onChange={onChangeHandlers.directors}
            readOnly={readOnly}
            label="Filter by director"
            placeholder="Director filter"
          />
        </FilterCol>
        <FilterCol>
          <PartyPicker
            id="collection-actor-picker"
            parties={filters.actors}
            onChange={onChangeHandlers.actors}
            readOnly={readOnly}
            label="Filter by actor"
            placeholder="Actor filter"
          />
        </FilterCol>
      </Row>
      <Row>
        <FilterCol>
          <ProductionYearRangePicker
            productionYearRange={filters.productionYearRange}
            selectedContentTypes={filters.contentTypes}
            onChange={onChangeHandlers.productionYearRange}
            readOnly={readOnly}
          />
        </FilterCol>
        <FilterCol>
          <ContentLengthPicker
            durationInterval={filters.durationInterval}
            selectedContentTypes={filters.contentTypes}
            onChange={onChangeHandlers.durationInterval}
            readOnly={readOnly}
          />
        </FilterCol>
      </Row>
      <ManualOverrides filters={filters} onChangeHandlers={onChangeHandlers} />
      <DynamicCollectionPreview
        preview={preview}
        showTooFewItemsWarning={
          filters.availableContent !== "SHOW_ALL" &&
          preview?.totalHits !== undefined &&
          preview?.totalHits < 5
        }
        filters={filters}
        onChangeHandlers={onChangeHandlers}
      />
    </>
  );
};

export default CollectionFiltersComponent;
