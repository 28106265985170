import { MediaContentData } from "@telia-company/tv.no-play-cms-common/db/definitions/MediaContent";
import React from "react";
import { ConfirmButton } from "components/buttons";
import { MediaContentHistory } from "./types";

function Rollback({
  data,
  rollback,
}: {
  data: MediaContentHistory;
  rollback: (data: MediaContentData) => void;
}) {
  return (
    <ConfirmButton
      onClick={() => rollback(data.data)}
      confirmVariant="primary"
      confirmChildren={`Rollback to: ${data.version}`}
      placement="left"
      size="sm"
    >
      Rollback
    </ConfirmButton>
  );
}

export default Rollback;
