import { isNumeric } from "@telia-company/tv.no-play-cms-common/api/util";
import autobind from "autobind-decorator";
import moment from "moment";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { CmsPure, request } from "client/utils";
import DateTimeControl from "components/controls/datetime/DateTimeControl";
import { MediaContentPicker } from "components/pickers";

class EditPriceOverrideDialog extends CmsPure {
  constructor(props) {
    super(props);
    const ovr = props.override;
    if (!ovr) {
      this.state = {
        fromDate: null,
        toDate: null,
        asset: null,
        showAssetDialog: false,
        price: 0,
        mediacontent: null,
      };
    } else {
      this.state = {
        fromDate: moment(ovr.override_start),
        toDate: moment(ovr.override_end),
        asset: ovr.asset_id ? Number(ovr.asset_id) : null,
        mediacontent: null,
        showAssetDialog: false,
        price: ovr.price,
      };
    }
  }

  @autobind
  async onAdd() {
    if (!this.validate()) {
      return;
    }
    const override = {
      asset_id: this.state.asset,
      content_id: this.state.mediacontent,
      price: this.state.price,
      media_form: "rent",
      override_start: this.state.fromDate,
      override_end: this.state.toDate,
    };
    if (this.props.override) {
      override.override_id = this.props.override.override_id;
    }
    const method = this.props.override ? "POST" : "PUT";
    console.log("PriceOverride payload sent", { override });
    request("tvod/price-overrides", {
      method,
      body: JSON.stringify({ override }),
    })
      .then(() => this.props.onClose())
      .catch((err) => toast.error("Override failed: " + err.message));
  }

  @autobind
  validate() {
    if (!this.state.asset) {
      toast.error("No asset chosen");
      return false;
    }
    if (!this.state.fromDate || !moment(this.state.fromDate).isValid()) {
      toast.error("No valid start date set");
      return false;
    }
    if (!this.state.toDate || !moment(this.state.toDate).isValid()) {
      toast.error("No valid end date set");
      return false;
    }
    if (this.state.fromDate.isSameOrAfter(this.state.toDate)) {
      toast.error("Start date must be before end date");
      return false;
    }
    if (!isNumeric(this.state.price) || this.state.price < 0) {
      toast.error("Invalid price");
      return false;
    }
    return true;
  }

  @autobind
  onChangeFromDate(d) {
    this.setState({ fromDate: d });
  }

  @autobind
  onChangeToDate(d) {
    this.setState({ toDate: d });
  }

  @autobind
  onOpenAssetDialog() {
    this.setState({ showAssetDialog: true });
  }

  @autobind
  onAssetAdd(mcId) {
    // really adds a mediacontent, in the next step we can select asset
    this.setState({ asset: null, mediacontent: mcId, showAssetDialog: false });
  }

  @autobind
  onAssetChange(id, isAsset) {
    if (!id) this.setState({ mediacontent: null, asset: null });
    else if (isAsset) this.setState({ asset: id });
    else this.setState({ mediacontent: id, asset: null });
  }

  @autobind
  onAssetDelete() {
    this.setState({ mediacontent: null, asset: null });
  }

  @autobind
  onHideAssetDialog() {
    this.setState({ showAssetDialog: false });
  }

  @autobind
  onPriceChange(e) {
    this.setState({ price: e.target.value });
  }

  render() {
    const update = this.props.override;
    const title = update ? "Edit Price Override" : "Create Price Override";
    const addUpdate = update ? "Update" : "Add";
    return (
      <>
        <Modal
          animation={true}
          autoFocus
          show={this.props.show}
          onHide={this.props.onClose}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={6}>
                <DateTimeControl
                  placeholder="Override start"
                  closeOnSelect
                  value={this.state.fromDate}
                  onChange={this.onChangeFromDate}
                  acceptEmpty
                />
              </Col>
              <Col xs={6}>
                <DateTimeControl
                  placeholder="Override end"
                  closeOnSelect
                  value={this.state.toDate}
                  onChange={this.onChangeToDate}
                  acceptEmpty
                />
              </Col>
            </Row>
            <Row className="my-4">
              <Col xs={12}>
                <MediaContentPicker
                  selectedId={this.state.asset || this.state.mediacontent}
                  isAsset={this.state.asset !== null}
                  onChange={this.onAssetChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <input
                  type="number"
                  value={this.state.price}
                  onChange={this.onPriceChange}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.onAdd}>
              {addUpdate}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default EditPriceOverrideDialog;
