import classNames from "classnames";
import { debounce } from "lodash";
import { PropTypes } from "prop-types";
import React from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Truncate } from "components/Truncate";

/**
 * A controlled react table for use with react-table hooks (v7)
 * @param {any} props
 */
export default function ControlledReactTable({
  className,
  tableProps,
  tableBodyProps,
  headerGroups,
  prepareRow,
  getRowProps,
  rows,
  placeholder,
  placeholderProps,
}) {
  return (
    <>
      <Table
        striped
        hover
        borderless
        size="sm"
        {...tableProps}
        className={classNames(className, tableProps.className)}
      >
        <TableHead headerGroups={headerGroups} />
        <TableBody
          tableBodyProps={tableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
          getRowProps={getRowProps}
        >
          {!rows.length ? (
            <Placeholder {...placeholderProps}>{placeholder}</Placeholder>
          ) : null}
        </TableBody>
      </Table>
    </>
  );
}

function TableHead({ headerGroups }) {
  // getHeaderGroupProps includes a key
  /* eslint-disable react/jsx-key */
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(HeaderColumn)}
        </tr>
      ))}
    </thead>
  );
  /* eslint-enable react/jsx-key */
}
TableHead.propTypes = {
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.any).isRequired,
      getHeaderGroupProps: PropTypes.func.isRequired,
    })
  ).isRequired,
};

function HeaderColumn(column) {
  let className;
  if (column.isSorted) {
    className = column.isSortedDesc ? "sorted-desc" : "sorted-asc";
  }

  let header;
  if (column.headerTooltip) {
    header = (
      <span data-tip={column.headerTooltip} style={{ whiteSpace: "nowrap" }}>
        {column.render("Header")}
      </span>
    );
  } else if (column.headerClassName === "slanted-header") {
    header = column.render("Header");
  } else {
    header = <Truncate>{column.render("Header")}</Truncate>;
  }
  return (
    <th
      {...column.getHeaderProps([
        {
          className: classNames(
            className,
            "sticky",
            column.className,
            column.headerClassName
          ),
          style: column.style,
        },
      ])}
    >
      <div>
        <div {...(column.canSort && column.getSortByToggleProps())}>
          {header}
        </div>
        {column.canFilter && column.Filter ? (
          <div>{column.render("Filter")}</div>
        ) : null}
      </div>
    </th>
  );
}
HeaderColumn.propTypes = {
  id: PropTypes.any.isRequired,
  Header: PropTypes.any.isRequired,
  render: PropTypes.func.isRequired,
  getHeaderProps: PropTypes.func.isRequired,
  canFilter: PropTypes.bool,
  canSort: PropTypes.bool,
};

function TableBody({
  children,
  tableBodyProps,
  rows,
  prepareRow,
  getRowProps,
}) {
  return (
    <tbody {...tableBodyProps}>
      {rows.map((row) => {
        prepareRow(row);
        return BodyRow(row, getRowProps);
      })}
      {children}
    </tbody>
  );
}
TableBody.propTypes = {
  tableBodyProps: PropTypes.object.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

function BodyRow(row, getRowProps) {
  // getCellProps includes a key
  /* eslint-disable react/jsx-key */
  let onClick = undefined;
  if (row.original.onClick) {
    onClick = () => row.original.onClick(row.original);
  }

  return (
    <tr
      onClick={onClick}
      className={classNames(
        row.isSelected && "selected",
        row.original.onClick && "link",
        row.original.className
      )}
      {...row.getRowProps(getRowProps && getRowProps(row))}
    >
      {row.cells.map((cell) => {
        const className = cell.column.className;
        return (
          <td {...cell.getCellProps({ className })}>{cell.render("Cell")}</td>
        );
      })}
    </tr>
  );
  /* eslint-enable react/jsx-key */
}
BodyRow.propTypes = {
  getRowProps: PropTypes.func.isRequired,
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.any.isRequired,
      render: PropTypes.func.isRequired,
      getCellProps: PropTypes.func.isRequired,
    })
  ).isRequired,
};

function Placeholder({ children, ...rest }) {
  return (
    <tr>
      <td className="text-center" {...rest}>
        {children || "There are no matching results"}
      </td>
    </tr>
  );
}

function useDebouncedPage(gotoPage, pageIndex, totalPages) {
  const ref = React.useRef();
  const gotoPageDebounced = React.useCallback(
    debounce((page) => gotoPage(page), 1000),
    [gotoPage]
  );
  const onChange = React.useCallback(() => {
    const value = Number(ref.current.value);
    const cappedValue = Math.min(Math.max(value, 1), totalPages);
    if (value !== cappedValue) ref.current.value = cappedValue;
    gotoPageDebounced(cappedValue - 1);
  }, [gotoPageDebounced, ref, totalPages]);
  React.useEffect(() => {
    ref.current.value = pageIndex + 1;
  }, [ref, pageIndex]);

  return [ref, onChange];
}

export function ReactTablePagination({
  pageIndex,
  gotoPage,
  totalPages,
  pageSize,
  setPageSize,
}) {
  const [pageRef, onChangePage] = useDebouncedPage(
    gotoPage,
    pageIndex,
    totalPages
  );

  return (
    <Row>
      <Col>
        <Button
          variant="outline-secondary"
          className="w-100"
          onClick={() => gotoPage(pageIndex - 1)}
          disabled={pageIndex <= 0}
        >
          Previous
        </Button>
      </Col>
      <Col md={{ span: "auto", offset: 1 }} className="px-0">
        <InputGroup>
          <InputGroup.Text>Page</InputGroup.Text>
          <Form.Control type="number" ref={pageRef} onChange={onChangePage} />
          <InputGroup.Text>of {totalPages > 0 && totalPages}</InputGroup.Text>
        </InputGroup>
      </Col>
      <Col md="auto" className="px-0">
        <Form.Select
          className="ms-2"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          data-testid="select-page-size"
        >
          {[10, 20, 50, 100, 1000].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col xs={{ offset: 1 }}>
        <Button
          variant="outline-secondary"
          className="w-100"
          onClick={() => gotoPage(pageIndex + 1)}
          disabled={pageIndex >= totalPages - 1}
        >
          Next
        </Button>
      </Col>
    </Row>
  );
}
ReactTablePagination.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
};
