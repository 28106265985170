import { DateTime } from "luxon";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table-v7";
import { KindBadge } from "components/badges";
import { MetadataStatusBadge } from "components/badges/MetadataStatusBadge";
import Table from "components/tables";
import { Pagination } from "components/tables/Pagination";
import { LinkToProgramMatch } from "../LinkToProgramMatch";
import { TeliaDBKindBadge } from "../TeliaDBKindBadge";
import { useChannelSelection } from "../hooks";
import { useLinear } from "../hooks/useLinear";
import {
  useOpenProgramMatchOrDetailsCallback,
  useQueryParamString,
} from "../hooks/useOpenCloseProgramMatchCallback";
import { ScheduleEventRow } from "../types/ScheduleEvent";
import { EpisodeSeriesTitleCell } from "./EpisodeSeriesTitleCell";
import { MediaContentCrid } from "./MediaContentCrid";
import { MetadataCollectorCridLink } from "./MetadataCollectorCridLink";

type LinearMetadataDbColumns = ReadonlyArray<Column<ScheduleEventRow>>;

interface LinkToProgramMatchOrDetailsProps {
  content: ReactNode;
  mediaContentId: string | null | undefined;
  tmsId: string;
}

const LinkToProgramMatchOrDetails = ({
  content,
  mediaContentId,
  tmsId,
}: LinkToProgramMatchOrDetailsProps) => {
  const queryParamString = useQueryParamString();
  if (!mediaContentId) {
    return (
      <Link
        to={`/epg/linear-metadata-db/details/${tmsId}` + queryParamString}
        // This link doesn't actually do anything when clicked; the click is
        // handled by the onClick callback for the Table. The link is added so it
        // can be right-clicked and opened in a new tab.
        onClick={(event) => event.preventDefault()}
      >
        {content}
      </Link>
    );
  }
  return (
    <LinkToLinearMetadataDbProgramMatch
      content={content}
      tmsId={tmsId}
      mediaContentId={mediaContentId}
    />
  );
};

interface LinkToLinearMetadataDbProgramMatchProps {
  content: ReactNode;
  tmsId: string;
  mediaContentId: string;
}

const LinkToLinearMetadataDbProgramMatch = ({
  content,
  tmsId,
  mediaContentId,
}: LinkToLinearMetadataDbProgramMatchProps) => {
  return (
    <LinkToProgramMatch
      content={content}
      tmsId={tmsId}
      mediaContentId={mediaContentId}
      urlPrefix={"/epg/linear-metadata-db/match/"}
    />
  );
};

const columns: LinearMetadataDbColumns = [
  {
    Header: "Date",
    accessor: (row) => (
      <LinkToProgramMatchOrDetails
        mediaContentId={row.mediaContentId}
        tmsId={row.tmsId}
        content={DateTime.fromISO(row.eventStart).toFormat("yyyy-MM-dd")}
      />
    ),
    width: 40,
  },
  {
    Header: "Start",
    accessor: (row) => (
      <LinkToProgramMatchOrDetails
        mediaContentId={row.mediaContentId}
        tmsId={row.tmsId}
        content={DateTime.fromISO(row.eventStart).toFormat("HH:mm")}
      />
    ),
    width: 40,
  },
  {
    Header: "Duration",
    accessor: (row) => (
      <LinkToProgramMatchOrDetails
        mediaContentId={row.mediaContentId}
        tmsId={row.tmsId}
        content={row.duration}
      />
    ),
    width: 40,
  },
  {
    Header: "Episode-Series-Title",
    accessor: (row) => (
      <LinkToProgramMatchOrDetails
        mediaContentId={row.mediaContentId}
        tmsId={row.tmsId}
        content={<EpisodeSeriesTitleCell row={row} />}
      />
    ),
    minWidth: 200,
  },
  {
    Header: "Channel name",
    accessor: (row) => (
      <LinkToProgramMatchOrDetails
        mediaContentId={row.mediaContentId}
        tmsId={row.tmsId}
        content={<div className="text-truncate">{row.channelName}</div>}
      />
    ),
    width: 100,
  },
  {
    Header: "GN TMSID",
    accessor: (row) => (
      <LinkToProgramMatchOrDetails
        mediaContentId={row.mediaContentId}
        tmsId={row.tmsId}
        content={row.tmsId}
      />
    ),
    width: 120,
  },
  {
    Header: "Original GN Type & Subtype",
    accessor: (row) => (
      <>
        <KindBadge
          kind={row.type.type}
          colorAs={row.type.mappedType}
          inline={true}
        />
        <KindBadge
          kind={row.type.subtype}
          colorAs={row.type.mappedType}
          inline={true}
        />
      </>
    ),
    width: 150,
  },
  {
    Header: "Mapped type",
    accessor: (row) => <KindBadge kind={row.type.mappedType} />,
    width: 70,
  },
  {
    Header: "TeliaDB kind",
    accessor: (row) => <TeliaDBKindBadge mediaContentId={row.mediaContentId} />,
    width: 70,
  },
  {
    Header: "Status",
    accessor: (row) => <MetadataStatusBadge status={row.status} />,
    width: 70,
  },
  {
    Header: "CRID",
    accessor: (row) => {
      const gracenoteCrid = `crid://gn/${row.tmsId}`;

      const mediaContentCrid =
        row.mediaContentId !== null ? (
          <MediaContentCrid mediaContentId={row.mediaContentId} />
        ) : (
          <span>null</span>
        );

      return (
        <>
          <div>
            GN: <MetadataCollectorCridLink crid={gracenoteCrid} />
          </div>
          <div>Telia: {mediaContentCrid}</div>
        </>
      );
    },
    width: 150,
  },
];

export const LinearContent = () => {
  const { selectedChannels } = useChannelSelection();

  return <LinearTable selectedChannels={selectedChannels} />;
};

interface LinearTableProps {
  selectedChannels: (number | null)[] | null;
}

const LinearTable = ({ selectedChannels }: LinearTableProps) => {
  const { data, isLoading } = useLinear(selectedChannels);
  const schedule: ScheduleEventRow[] = data?.results || [];
  const openProgramMatchOrDetails = useOpenProgramMatchOrDetailsCallback();

  return (
    <>
      <div className="mt-2">
        Total events:{" "}
        {data?.totalElements ? data?.totalElements.toLocaleString("nb-NO") : ""}
      </div>
      <Table
        data={schedule}
        columns={columns}
        loading={isLoading}
        onClick={openProgramMatchOrDetails}
      />
      <Pagination totalPages={data?.totalPages} />
    </>
  );
};
